<div class="modal-header">
    <h4
        class="modal-title"
        id="modal-title"
    >
        Confirm {{hasSchedule() ? 'Schedule' : launchType ? 'ReLaunch' : 'Launch'}}
    </h4>
    <div class="d-flex justify-content-end">
        <button
            type="button"
            aria-label="Close button"
            class="close"
            aria-describedby="modal-title"
            (click)="modal.close({action: 'cancel'})"
        >
            <span aria-hidden="true">&times;</span>
        </button>
    </div>
</div>

<div class="modal-body">
    <div *ngIf="loading">
        Loading..
    </div>

    <div *ngIf="!loading && code">
        <span *ngIf="hasSchedule()">The campaign will be scheduled to {{launchType ? 're-launched' :'launched'}} at <b>{{getScheduleDateTime()}}</b>
          <span *ngIf="schedule?.schedule_date && schedule?.schedule_time"><app-info-popover info="{{ getScheduleDateTime(true) }}"></app-info-popover></span>
        </span>
        <span *ngIf="!hasSchedule()">The campaign will be {{launchType ? 're-launched' :'launched'}} <b>now</b></span>
    </div>
</div>
<input
    type="hidden"
    ngbAutofocus
>
<div class="modal-footer">
  <!--<button
    class="btn btn-primary mr-auto"
    type="button"
    (click)="closeWithAction('launch-now')"
    *ngIf="hasSchedule()"
  >
    Launch now
  </button>
    <button
        class="btn"
        type="button"
        (click)="modal.dismiss('cancel')"
    >
        Cancel
    </button>-->

    <button
        class="btn"
        type="button"
        (click)="clearSchedule()"
        *ngIf="hasSchedule()"
    >
        Clear Schedule
    </button>

  <button
        class="btn"
        type="button"
        (click)="closeWithAction('edit')"
        *ngIf="hasSchedule()"
    >
        Edit Schedule
    </button>

<!--  <button-->
<!--        class="btn btn-primary"-->
<!--        type="button"-->
<!--        (click)="closeWithAction('schedule')"-->
<!--        *ngIf="!hasSchedule()"-->
<!--    >-->
<!--        Schedule-->
<!--    </button>-->

    <button
        class="btn btn-primary"
        type="button"
        (click)="closeWithAction(hasSchedule() ? 'schedule-launch' : 'launch')"
        [disabled]="loading || !code"
    >
        Confirm
    </button>
</div>
