import {Action, createReducer, on} from '@ngrx/store';

import {
  PersonalAccessTokenAdd,
  PersonalAccessTokenAddError,
  PersonalAccessTokenAddSuccess,
  PersonalAccessTokenListError,
  PersonalAccessTokenListSuccess,
  PersonalAccessTokenList,
  PersonalAccessTokenDelete,
  PersonalAccessTokenDeleteSuccess,
  PersonalAccessTokenUpdate,
  PersonalAccessTokenUpdateSuccess, PersonalAccessTokenUpdateError, ResetPersonalAccessTokenState, PersonalAccessTokenListPaginatedError,
  PersonalAccessTokenListPaginated, PersonalAccessTokenListPaginatedSuccess

} from "@app/stores/personal-access-token/personal-access-token.actions";
import {initialPersonalAccessTokenState} from "@app/stores/personal-access-token/personal-access-token.state";
import {PackagesListPaginated, PackagesListPaginatedError, PackagesListPaginatedSuccess} from "@app/stores";



const generateReducer = createReducer(initialPersonalAccessTokenState,
  on(PersonalAccessTokenAdd, (state) => ({
    ...state,
    token: null,
    success: '',
    error: ''
  })),
  on(PersonalAccessTokenAddSuccess, (state) => ({
    ...state,
    success: 'Personal access token added successfully',
    error: ''
  })),
  on(PersonalAccessTokenAddError, (state, {error}) => ({
    ...state,
    token: null,
    success: '',
    error
  })),

  on(PersonalAccessTokenList, (state) => ({
    ...state,
    token: null,
    success: '',
    error: ''
  })),

  on(PersonalAccessTokenListSuccess, (state, {token}) => ({
    ...state,
    token,
    success: '',
    error: ''
  })),

  on(PersonalAccessTokenListError, (state, {error}) => ({
    ...state,
    token: null,
    success: '',
    error
  })),

  on(PersonalAccessTokenDelete, (state) => ({
    ...state,
    token: null,
    success: '',
    error: ''
  })),

  on(PersonalAccessTokenDeleteSuccess, (state) => ({
    ...state,
    success: 'Personal access token deleted successfully',
    error: ''
  })),

  on(PersonalAccessTokenListError, (state, {error}) => ({
    ...state,
    success: '',
    error
  })),

  on(PersonalAccessTokenUpdate, (state) => ({
    ...state,
    success: '',
    error: ''
  })),

  on(PersonalAccessTokenUpdateSuccess, (state) => ({
    ...state,
    success: 'Personal access token updated successfully',
    error: ''
  })),

  on(PersonalAccessTokenUpdateError, (state, {error}) => ({
    ...state,
    success: '',
    error
  })),

  on(ResetPersonalAccessTokenState, (state, {params}) => ({
    ...state,
    ...params
  })),

  on(PersonalAccessTokenListPaginated, (state) => ({
    ...state,
    token: null,
    paginatedPersonalAccessToken: null,
    success: '',
    error: ''
  })),
  on(PersonalAccessTokenListPaginatedSuccess, (state, {paginatedPersonalAccessToken}) => ({
    ...state,
    token: null,
    paginatedPersonalAccessToken,
    success: '',
    error: ''
  })),
  on(PersonalAccessTokenListPaginatedError, (state, {error}) => ({
    ...state,
    token: null,
    paginatedPersonalAccessToken: null,
    success: '',
    error
  })),

);


export function personalAccessToken(state = initialPersonalAccessTokenState, action: Action) {
  return generateReducer(state, action);
}
