import {Action, createReducer, on} from '@ngrx/store';
import {
  FileUpload, FileUploadSuccess, FileUploadError,
  SignedUrl, SignedUrlSuccess, SignedUrlError,
  UploadToSignedUrl, UploadToSignedUrlSuccess, UploadToSignedUrlError,
  ResetFileState, UploadToSignedUrlProgress
} from '@app/stores/file-upload/file-upload.actions';
import {initialFileUploadState} from '@app/stores/file-upload/file-upload.state';

const generateReducer = createReducer(initialFileUploadState,
  on(FileUpload, (state) => ({
    ...state,
    file: null,
    files: [],
    success: '',
    error: '',
    progress: 0
  })),
  on(FileUploadSuccess, (state, {file}) => ({
    ...state,
    file,
    files: [],
    success: '',
    error: '',
    progress: 0
  })),
  on(FileUploadError, (state, {error}) => ({
    ...state,
    file: null,
    files: [],
    success: '',
    error,
    progress: 0
  })),

  on(SignedUrl, (state) => ({
    ...state,
    file: null,
    files: [],
    uploaded: undefined,
    url: null,
    success: '',
    error: '',
    progress: 0
  })),
  on(SignedUrlSuccess, (state, {url, file}) => ({
    ...state,
    url,
    file,
    uploaded: undefined,
    files: [],
    success: '',
    error: '',
    progress: 0
  })),
  on(SignedUrlError, (state, {error}) => ({
    ...state,
    url: null,
    file: null,
    uploaded: undefined,
    files: [],
    success: '',
    error,
    progress: 0
  })),

  on(UploadToSignedUrl, (state) => ({
    ...state,
    file: null,
    files: [],
    uploaded: undefined,
    url: null,
    success: '',
    error: '',
    progress: 0
  })),
  on(UploadToSignedUrlSuccess, (state, {uploaded}) => ({
    ...state,
    url: null,
    file: null,
    uploaded,
    files: [],
    success: '',
    error: '',
    progress: 100
  })),
  on(UploadToSignedUrlError, (state, {error}) => ({
    ...state,
    url: null,
    file: null,
    uploaded: undefined,
    files: [],
    success: '',
    error,
    progress: 0
  })),

  on(UploadToSignedUrlProgress, (state, {progress}) => ({
    ...state,
    progress
  })),

  on(ResetFileState, (state, {params}) => ({
    ...state,
    ...params
  })),
);

export function fileUploadReducer(state = initialFileUploadState, action: Action) {
  return generateReducer(state, action);
}
