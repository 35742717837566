import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';

import {GroupByPipe} from '@app/_pipes';
import {MeetingTimePipe} from '@app/_pipes';
import {SanitizeHtmlPipe} from '@app/_pipes';
import {GroupPipe} from './pipes/group.pipe';
import {TzDatePipe} from './pipes/tz-date.pipe';
import {RemoveSpacePipe} from './pipes/remove-space.pipe';
import {HoverPopupDirective} from './directives/hover-popup.directive';
import {SocialSharePreview} from './social-share-preview/social-share-preview.component';
import {CreatableSelectComponent} from '@app/_components/creatable-select';
import {ColorPickerComponent} from '@app/_components/color-picker';
import {NgSelectModule} from '@ng-select/ng-select';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {PageDesignerModule} from '@app/_components/page-designer/page-designer.module';
import {TemplateEditorComponent} from '@app/_components/template-editor/template-editor.component';
import {FontPickerModule} from '@app/modules/fontPicker/fontPicker.module';
import {ColorPickerModule} from 'ngx-color-picker';
import {DragDropModule} from '@angular/cdk/drag-drop';
import {DeviceToolbarComponent} from '@app/_components/device-toolbar/device-toolbar.component';
import {PaginatePipe} from '@app/modules/shared/pipes/segments-pipe';
import {VariablesComponent} from '@app/_components/variables/variables.component';
import {TokenRefreshComponent} from '@app/_components/token-refresh/token-refresh.component';
import {InfoPopoverComponent} from '@app/_components/info-popover/info-popover.component';
import {NgbPopoverModule} from '@ng-bootstrap/ng-bootstrap';
import {InfluencersImportComponent} from '@app/_components/influencers-import/influencers-import.component';
import {ImportMatcherComponent} from '@app/_components/import_matcher/import-matcher.component';
import {TaskLogsComponent} from '@app/_components/task-logs/task-logs.component';
import {DataTablesModule} from 'angular-datatables';
import {ReferralsListComponent} from '@app/_components/referrals-list/referrals-list.component';
import {FileBrowserComponent} from '@app/_components/file-browser/file-browser.component';
import {ColorPickerDirective} from '@app/modules/shared/directives/colorPicker.directive';
import {CampaignStatusComponent} from '@app/_components/campaign-status/campaign-status.component';
import {RouterModule} from '@angular/router';
import {TemplateRevisionsComponent} from '@app/_components/template-revisions/template-revisions.component';
import {VerificationComponent} from '@app/_components/verification/verification.component';
import {VerificationByComponent} from '@app/_components/verification-by/verification-by.component';
import {NgxCsvParserModule} from 'ngx-csv-parser';
import {AutoCompleteSelectComponent} from "@app/_components/auto-complete-select/auto-complete-select.component";
import {WarningPopoverComponent} from '@app/_components/warning-popover/warning-popover.component';

@NgModule({
  declarations: [
    GroupByPipe,
    MeetingTimePipe,
    GroupPipe,
    TzDatePipe,
    SanitizeHtmlPipe,
    PaginatePipe,
    RemoveSpacePipe,
    HoverPopupDirective,
    SocialSharePreview,
    CreatableSelectComponent,
    ColorPickerComponent,
    CampaignStatusComponent,
    TemplateEditorComponent,
    DeviceToolbarComponent,
    DeviceToolbarComponent,
    VariablesComponent,
    TokenRefreshComponent,
    InfoPopoverComponent,
    InfluencersImportComponent, ReferralsListComponent,
    TaskLogsComponent,
    ImportMatcherComponent,
    FileBrowserComponent,
    ColorPickerDirective, TemplateRevisionsComponent, VerificationComponent,
    VerificationByComponent, AutoCompleteSelectComponent,
    WarningPopoverComponent
  ],
  imports: [CommonModule, NgSelectModule, FormsModule, PageDesignerModule, FontPickerModule,
    ColorPickerModule, DragDropModule, NgbPopoverModule, ReactiveFormsModule, DataTablesModule, RouterModule, NgxCsvParserModule],
  exports: [
    GroupByPipe,
    MeetingTimePipe,
    GroupPipe,
    TzDatePipe,
    PaginatePipe,
    SanitizeHtmlPipe,
    RemoveSpacePipe,
    HoverPopupDirective,
    SocialSharePreview,
    CreatableSelectComponent,
    ColorPickerComponent,
    CampaignStatusComponent,
    TemplateEditorComponent,
    DeviceToolbarComponent,
    VariablesComponent, TokenRefreshComponent, InfoPopoverComponent, InfluencersImportComponent,
    TaskLogsComponent,
    ImportMatcherComponent,
    ReferralsListComponent,  FileBrowserComponent, ColorPickerDirective, TemplateRevisionsComponent, VerificationComponent,
    VerificationByComponent,
    AutoCompleteSelectComponent,
    WarningPopoverComponent
  ],
  providers: [TzDatePipe]
})
export class SharedModule {
}
