import {AfterViewInit, Component, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {Subject} from 'rxjs';
import {takeUntil} from 'rxjs/operators';
import {Package} from '@app/modules/shared/models';
import {DataTableDirective} from 'angular-datatables';
import {select, Store} from '@ngrx/store';
import Swal from 'sweetalert2';
import {ToastrService} from 'ngx-toastr';
import * as DataTables from 'datatables.net';

import {
  getPackagesPaginated,
  PackagesListPaginated,
  ResetPackageState,
  IPackageState, PackagesDelete, getPackageError, getPackageSuccess,
} from '@app/stores';

@Component({
  selector: 'app-package-list',
  templateUrl: './package-list.component.html',
  styleUrls: ['./package-list.component.css']
})
export class PackageListComponent implements OnInit, OnDestroy, AfterViewInit {
  @ViewChild(DataTableDirective)
  dtElement: DataTableDirective;
  dtOptions: any = {};
  unsubscriber = new Subject();
  dtTrigger: Subject<any> = new Subject();
  ajaxCallback: any;
  private datatableInstance: DataTables.Api<any>;
  private searchDelay;
  public packages: Package[] = [];
  deleteAction = false;


  constructor(
    private packageStore: Store<IPackageState>,
    private toastrService: ToastrService,
  ) {
    this.packageStore.dispatch(ResetPackageState({params: {}}));
    this.subscribeStores();
  }

  subscribeStores() {
    this.packageStore.pipe(select(getPackageError))
      .pipe(takeUntil(this.unsubscriber))
      .subscribe(error => {
        if (error) {
          this.toastrService.error(error);
        }
      });
    this.packageStore.pipe(select(getPackageSuccess))
      .pipe(takeUntil(this.unsubscriber))
      .subscribe(success => {
        if (success) {
          if (this.deleteAction) {
            this.deleteAction = false;
            this.loadAllPackages();
          }
        }
      });
    this.packageStore.pipe(select(getPackagesPaginated))
      .pipe(takeUntil(this.unsubscriber))
      .subscribe((paginatedPackages: any) => {
          if (paginatedPackages) {
              this.packages = paginatedPackages.list;
              if (this.ajaxCallback) {
                this.ajaxCallback({
                  recordsTotal: paginatedPackages.paging.total,
                  recordsFiltered: paginatedPackages.paging.total,
                  data: []
                });
                setTimeout(() => {
                  (this.datatableInstance as any).columns.adjust();
                }, 500);
              }
            }
        }
      );
  }

  ngOnInit(): void {
    this.datatableSettings();
    if (this.dtElement && this.dtElement.dtInstance) {
      this.dtElement.dtInstance.then((dtInstance: any) => {
        dtInstance.destroy();
        this.dtTrigger.next();
      });
    } else {
      this.dtTrigger.next();
    }
  }

  datatableSettings() {
    this.dtOptions = {
      scrollCollapse: true,
      pagingType: 'full_numbers',
      responsive: false,
      ordering: false,
      processing: true,
      pageLength: 10,
      paging: true,
      serverSide: true,
      scrollX: true,
      searching: true,
      // scrollY: "100vh",
      fixedHeader: {
        header: true,
        footer: true,
        headerOffset: 55 ,
      },
      fixedColumns: {
        right: 1,
        left: 0
      },
      drawCallback: function (setting) {
        const totalPages = this.api().page.info().pages;
        if (totalPages <= 1) {
          document.querySelector('.dataTables_paginate').classList.add('d-none');
        } else {
          document.querySelector('.dataTables_paginate').classList.remove('d-none');
        }
      },
      ajax: (dataTablesParameters: any, callback) => {
        this.dtElement.dtInstance.then((dtInstance: any) => {
          this.datatableInstance = dtInstance;
          this.ajaxCallback = callback;
          const pageLength = dataTablesParameters.length;
          const pageNumber = (dataTablesParameters.start / pageLength);
          (this.datatableInstance as any).page.len(pageLength);
          const searchBox = $('div.dataTables_filter input');
          searchBox.off('keyup.DT input.DT');
          searchBox.on('keyup', () => {
            const search: any = searchBox.val();
            clearTimeout(this.searchDelay);
            this.searchDelay = setTimeout(() => {
              if (search != null) {
                (this.datatableInstance as any).search(search).draw();
              }
            }, 1000);
          });
          this.loadAllPackages(pageNumber, pageLength, dataTablesParameters.search.value);
        });
      },
      columns: [
        {data: null}, {data: null}, {data: null}, {data: null}, {data: null}
      ]
    };
  }

  private loadAllPackages(page = 0, perPage = 10, search = '') {
    this.packageStore.dispatch(PackagesListPaginated({
      params: {
        options: JSON.stringify({includePagination: true}),
        page: page + 1,
        perPage,
        search
      }
    }));
  }

  ngAfterViewInit() {
    this.dtTrigger.next();
  }

  deletePackage(packageId: number) {
    Swal({
      title: 'Are you sure?',
      type: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Yes',
      cancelButtonText: 'No'
    }).then(result => {
      if (result.value) {
        this.deleteAction = true;
        this.packageStore.dispatch(PackagesDelete({packageId}));
      }
    });
  }

  ngOnDestroy() {
    this.unsubscriber.next();
    this.unsubscriber.complete();
  }

}
