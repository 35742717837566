import {Action, createReducer, on} from '@ngrx/store';
import {
  CampaignGet, CampaignGetSuccess, CampaignGetError,
  CampaignUpdate, CampaignUpdateSuccess, CampaignUpdateError,
  CampaignCreate, CampaignCreateSuccess, CampaignCreateError,
  CampaignGetAll, CampaignGetAllSuccess, CampaignGetAllError,
  CampaignLaunch, CampaignLaunchSuccess, CampaignLaunchError,
  CampaignGetAllPaginate, CampaignGetAllPaginateSuccess, CampaignGetAllPaginateError,
  CampaignDelete, CampaignDeleteSuccess, CampaignDeleteError, ResetCampaignState,
  CampaignCopy, CampaignCopySuccess, CampaignCopyError, CampaignLaunchScheduled, CampaignLaunchScheduledSuccess, CampaignLaunchScheduledError,
  CancelCampaignLaunch, CancelCampaignLaunchSuccess, CancelCampaignLaunchError,
  CampaignLaunchCode, CampaignLaunchCodeSuccess, CampaignLaunchCodeError,
  CampaignValidation, CampaignValidationSuccess, CampaignValidationError
} from '@app/stores/campaign/campaign.actions';
import {initialCampaignState} from '@app/stores/campaign/campaign.state';

const generateReducer = createReducer(initialCampaignState,
  on(CampaignGet, (state) => ({
    ...state,
    campaign: null,
    campaigns: [],
    success: '',
    error: ''
  })),
  on(CampaignGetSuccess, (state, {campaign}) => ({
    ...state,
    campaign,
    campaigns: [],
    success: '',
    error: ''
  })),
  on(CampaignGetError, (state, {error}) => ({
    ...state,
    campaign: null,
    campaigns: [],
    success: '',
    error
  })),

  on(CampaignUpdate, (state) => ({
    ...state,
    success: '',
    campaignUpdate: null,
    error: ''
  })),
  on(CampaignUpdateSuccess, (state, {campaign}) => ({
    ...state,
    campaignUpdate: campaign,
    success: 'Campaign updated successfully',
    error: ''
  })),
  on(CampaignUpdateError, (state, {error}) => ({
    ...state,
    success: '',
    campaignUpdate: null,
    error
  })),

  on(CampaignCreate, (state) => ({
    ...state,
    campaignAdd: null,
    success: '',
    error: ''
  })),
  on(CampaignCreateSuccess, (state, {campaign}) => ({
    ...state,
    campaignUpdate: campaign,
    success: 'Campaign created successfully',
    error: ''
  })),
  on(CampaignCreateError, (state, {error}) => ({
    ...state,
    campaignAdd: null,
    success: '',
    error
  })),

  on(CampaignGetAll, (state) => ({
    ...state,
    campaigns: [],
    success: '',
    error: ''
  })),
  on(CampaignGetAllSuccess, (state, {campaigns}) => ({
    ...state,
    campaigns,
    success: 'Campaigns found successfully',
    error: ''
  })),
  on(CampaignGetAllError, (state, {error}) => ({
    ...state,
    campaigns: [],
    success: '',
    error
  })),

  on(CampaignGetAllPaginate, (state) => ({
    ...state,
    paginatedCampaigns: null,
    success: '',
    error: ''
  })),
  on(CampaignGetAllPaginateSuccess, (state, {paginatedCampaigns}) => ({
    ...state,
    paginatedCampaigns,
    success: 'Campaigns found successfully',
    error: ''
  })),
  on(CampaignGetAllPaginateError, (state, {error}) => ({
    ...state,
    paginatedCampaigns: null,
    success: '',
    error
  })),

  on(CampaignLaunch, (state) => ({
    ...state,
    success: '',
    error: ''
  })),
  on(CampaignLaunchSuccess, (state, {campaignLaunch}) => ({
    ...state,
    success: 'Campaign launched successfully',
    campaignLaunch,
    error: ''
  })),
  on(CampaignLaunchError, (state, {error}) => ({
    ...state,
    success: '',
    error
  })),

  on(CampaignLaunchScheduled, (state) => ({
    ...state,
    success: '',
    error: ''
  })),
  on(CampaignLaunchScheduledSuccess, (state, {campaignLaunch}) => ({
    ...state,
    success: 'Campaign launch scheduled successfully',
    campaignLaunch,
    error: ''
  })),
  on(CampaignLaunchScheduledError, (state, {error}) => ({
    ...state,
    success: '',
    error
  })),

  on(CampaignDelete, (state) => ({
    ...state,
    success: '',
    error: ''
  })),
  on(CampaignDeleteSuccess, (state) => ({
    ...state,
    success: 'Campaign deleted successfully',
    error: ''
  })),
  on(CampaignDeleteError, (state, {error}) => ({
    ...state,
    success: '',
    error
  })),

  on(CampaignCopy, (state) => ({
    ...state,
    success: '',
    error: ''
  })),
  on(CampaignCopySuccess, (state) => ({
    ...state,
    success: 'Campaign copied successfully',
    error: ''
  })),
  on(CampaignCopyError, (state, {error}) => ({
    ...state,
    success: '',
    error
  })),

  on(CancelCampaignLaunch, (state) => ({
    ...state,
    success: '',
    error: ''
  })),
  on(CancelCampaignLaunchSuccess, (state, {campaign}) => ({
    ...state,
    success: 'Cancel campaign launch successfully',
    campaign,
    error: ''
  })),
  on(CancelCampaignLaunchError, (state, {error}) => ({
    ...state,
    success: '',
    error
  })),

  on(CampaignLaunchCode, (state) => ({
    ...state,
    success: '',
    error: ''
  })),
  on(CampaignLaunchCodeSuccess, (state, {campaignLaunchCode}) => ({
    ...state,
    success: 'Campaign launch code',
    campaignLaunchCode,
    error: ''
  })),
  on(CampaignLaunchCodeError, (state, {error}) => ({
    ...state,
    success: '',
    error
  })),

  on(CampaignValidation, (state) => ({
    ...state,
    success: '',
    campaignValidation: null,
    error: ''
  })),
  on(CampaignValidationSuccess, (state, {campaignValidation}) => ({
    ...state,
    success: '',
    campaignValidation,
    error: ''
  })),
  on(CampaignValidationError, (state, {error}) => ({
    ...state,
    success: '',
    campaignValidation: null,
    error
  })),

  on(ResetCampaignState, (state, {params}) => ({
    ...state,
    ...params
  })),
);

export function campaignReducer(state = initialCampaignState, action: Action) {
  return generateReducer(state, action);
}
