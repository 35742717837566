import {Injectable} from '@angular/core';
import {Actions, createEffect, ofType} from '@ngrx/effects';
import {Store} from '@ngrx/store';
import {catchError, map, switchMap} from 'rxjs/operators';
import {of} from 'rxjs';
import {LoaderService} from '@app/services/loader.service';
import {
  UsersList,
  UsersListSuccess,
  UsersListError,
  UsersListPaginated,
  UsersListPaginatedSuccess,
  UsersListPaginatedError,
  UsersAdd,
  UsersAddSuccess,
  UsersAddError,
  UsersUpdate,
  UsersUpdateSuccess,
  UsersUpdateError,
  UsersDelete,
  UsersDeleteSuccess,
  UsersDeleteError,
  UsersGet,
  UsersGetSuccess,
  UsersGetError, UsersRoles, UsersRolesSuccess, UsersRolesError,
  UserLoginInfoMail, UserLoginInfoMailSuccess, UserLoginInfoMailError
} from '@app/stores/users/users.actions';
import {IUsersState} from '@app/stores/users/users.state';
import {UserService} from '@app/services/user.service';

@Injectable()
export class UsersEffects {
  constructor(
    private _actions$: Actions,
    private _store: Store<IUsersState>,
    private userService: UserService,
    private loaderService: LoaderService
  ) {
  }

  userListing$ = createEffect(() => this._actions$.pipe(
    ofType(UsersList),
    switchMap((action) => {
      this.loaderService.show();
      return this.userService.getAll(action.params).pipe(
        map((resp: any) => {
          if (resp.success) {
            this.loaderService.hide();
            return UsersListSuccess({users: resp.data});
          }
          this.loaderService.hide(true);
          return UsersListError({error: this.loaderService.getErrorMessage(resp)});
        }),
        catchError(error => {
          this.loaderService.hide(true);
          return of(UsersListError({error: this.loaderService.getErrorMessage(error)}));
        })
      );
    })
  ));

  userListingPaginated$ = createEffect(() => this._actions$.pipe(
    ofType(UsersListPaginated),
    switchMap((action) => {
      this.loaderService.show();
      return this.userService.getAll(action.params).pipe(
        map((resp: any) => {
          if (resp.success) {
            this.loaderService.hide();
            return UsersListPaginatedSuccess({paginatedUsers: resp.data});
          }
          this.loaderService.hide(true);
          return UsersListPaginatedError({error: this.loaderService.getErrorMessage(resp)});
        }),
        catchError(error => {
          this.loaderService.hide(true);
          return of(UsersListPaginatedError({error: this.loaderService.getErrorMessage(error)}));
        })
      );
    })
  ));

  userAdd$ = createEffect(() => this._actions$.pipe(
    ofType(UsersAdd),
    switchMap((action) => {
      this.loaderService.show();
      return this.userService.addUser(action.body).pipe(
        map((resp: any) => {
          if (resp.success) {
            this.loaderService.hide();
            return UsersAddSuccess();
          }
          this.loaderService.hide(true);
          return UsersAddError({error: this.loaderService.getErrorMessage(resp)});
        }),
        catchError(error => {
          this.loaderService.hide(true);
          return of(UsersAddError({error: this.loaderService.getErrorMessage(error)}));
        })
      );
    })
  ));

  userUpdate$ = createEffect(() => this._actions$.pipe(
    ofType(UsersUpdate),
    switchMap((action) => {
      this.loaderService.show();
      return this.userService.editUser(action.body, action.userId).pipe(
        map((resp: any) => {
          if (resp.success) {
            this.loaderService.hide();
            return UsersUpdateSuccess({updateUser: resp.data});
          }
          this.loaderService.hide(true);
          return UsersUpdateError({error: this.loaderService.getErrorMessage(resp)});
        }),
        catchError(error => {
          this.loaderService.hide(true);
          return of(UsersUpdateError({error: this.loaderService.getErrorMessage(error)}));
        })
      );
    })
  ));

  userDelete$ = createEffect(() => this._actions$.pipe(
    ofType(UsersDelete),
    switchMap((action) => {
      this.loaderService.show();
      return this.userService.deleteUser(action.userId).pipe(
        map((resp: any) => {
          if (resp.success) {
            this.loaderService.hide();
            return UsersDeleteSuccess();
          }
          this.loaderService.hide(true);
          return UsersDeleteError({error: this.loaderService.getErrorMessage(resp)});
        }),
        catchError(error => {
          this.loaderService.hide(true);
          return of(UsersDeleteError({error: this.loaderService.getErrorMessage(error)}));
        })
      );
    })
  ));

  userGet$ = createEffect(() => this._actions$.pipe(
    ofType(UsersGet),
    switchMap((action) => {
      this.loaderService.show();
      return this.userService.getById(action.userId).pipe(
        map((resp: any) => {
          if (resp.success) {
            this.loaderService.hide();
            return UsersGetSuccess({user: resp.data});
          }
          this.loaderService.hide(true);
          return UsersGetError({error: this.loaderService.getErrorMessage(resp)});
        }),
        catchError(error => {
          this.loaderService.hide(true);
          return of(UsersGetError({error: this.loaderService.getErrorMessage(error)}));
        })
      );
    })
  ));

  userRoles$ = createEffect(() => this._actions$.pipe(
    ofType(UsersRoles),
    switchMap((action) => {
      this.loaderService.show();
      return this.userService.getRoles().pipe(
        map((resp: any) => {
          if (resp.success) {
            this.loaderService.hide();
            return UsersRolesSuccess({roles: resp.data});
          }
          this.loaderService.hide(true);
          return UsersRolesError({error: this.loaderService.getErrorMessage(resp)});
        }),
        catchError(error => {
          this.loaderService.hide(true);
          return of(UsersRolesError({error: this.loaderService.getErrorMessage(error)}));
        })
      );
    })
  ));

  UserLoginInfoMail$ = createEffect(() => this._actions$.pipe(
    ofType(UserLoginInfoMail),
    switchMap((action) => {
      this.loaderService.show();
      return this.userService.UserLoginInfoMail(action.userId).pipe(
        map((resp: any) => {
          if (resp.success) {
            this.loaderService.hide();
            return UserLoginInfoMailSuccess();
          }
          this.loaderService.hide(true);
          return UserLoginInfoMailError({error: this.loaderService.getErrorMessage(resp)});
        }),
        catchError(error => {
          this.loaderService.hide(true);
          return of(UserLoginInfoMailError({error: this.loaderService.getErrorMessage(error)}));
        })
      );
    })
  ));
}
