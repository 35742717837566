import {createFeatureSelector, createSelector} from '@ngrx/store';
import {IPackageState, IPersonalAccessTokenState} from '@app/stores';

const selectPackage = createFeatureSelector<IPersonalAccessTokenState>('personalAccessToken');

export const getPersonalAccessToken = createSelector(selectPackage, (state: IPersonalAccessTokenState) => state.token);

export const getPersonalAccessTokenError = createSelector(selectPackage, (state: IPersonalAccessTokenState) => state.error);

export const getPersonalAccessTokenSuccess = createSelector(selectPackage, (state: IPersonalAccessTokenState) => state.success);

export const getPersonalAccessTokenPaginated = createSelector(selectPackage, (state: IPersonalAccessTokenState) => state.paginatedPersonalAccessToken);

export const getPersonalAccessTokenListAll = createSelector(selectPackage, (state: IPersonalAccessTokenState) => state.tokens);
