import {Component, Input, OnInit, ViewEncapsulation} from '@angular/core';

@Component({
  selector: 'app-info-popover',
  templateUrl: './info-popover.component.html',
  styleUrls: ['./info-popover.component.css'],
  encapsulation: ViewEncapsulation.None
})
export class InfoPopoverComponent implements OnInit {

  @Input() info;
  @Input() container;

  constructor() {
  }

  ngOnInit(): void {
  }

}
