import {createAction, props} from '@ngrx/store';
import {Variable} from '@app/modules/shared/models';

enum EVariableActions {
  VariableList = '[Variable] Variable List',
  VariableListSuccess = '[Variable] Variable List Success',
  VariableListError = '[Variable] Variable List Error',

  ResetVariableState = '[Variable] Reset Variable State',
}

export const VariableList = createAction(EVariableActions.VariableList, props<{ params: any }>());
export const VariableListSuccess = createAction(EVariableActions.VariableListSuccess, props<{ variables: Variable[] }>());
export const VariableListError = createAction(EVariableActions.VariableListError, props<{ error: string }>());

export const ResetVariableState = createAction(EVariableActions.ResetVariableState, (params: any = {}) => params);

