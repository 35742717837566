import {Action, createReducer, on} from '@ngrx/store';
import {
  TemplateList,
  TemplateListSuccess,
  TemplateListError,
  TemplateUpdateStatus,
  TemplateUpdateStatusSuccess,
  TemplateUpdateStatusError,
  TemplateTouch,
  TemplateTouchSuccess,
  TemplateTouchError,
  TemplateGet,
  TemplateGetSuccess,
  TemplateGetError,
  TemplatePreview,
  TemplatePreviewSuccess,
  TemplatePreviewError,
  TemplateUpdate,
  TemplateUpdateSuccess,
  TemplateUpdateError,
  TemplateCreate,
  TemplateCreateSuccess,
  TemplateCreateError,
  TemplateCampaign,
  TemplateCampaignSuccess,
  TemplateCampaignError,
  TemplatePreviewURL,
  TemplatePreviewURLSuccess,
  TemplatePreviewURLError,
  ResetTemplateState,
  TemplateDelete,
  TemplateDeleteSuccess,
  TemplateDeleteError,
  WidgetIntegrationBody,
  WidgetIntegrationBodySuccess,
  WidgetIntegrationBodyError,
  WidgetCustomScriptBody,
  WidgetCustomScriptBodySuccess,
  WidgetCustomScriptBodyError,
  TemplateListAll,
  TemplateListAllSuccess,
  TemplateListAllError,
  TemplateUpdateThumbnail,
  TemplateUpdateThumbnailSuccess,
  TemplateUpdateThumbnailError,
  TemplateGetTypes,
  TemplateGetTypesSuccess,
  TemplateGetTypesError,
  TemplateWidgetPreviewURL,
  TemplateWidgetPreviewURLSuccess,
  TemplateWidgetPreviewURLError,
  TemplateGetRevisions,
  TemplateGetRevisionsSuccess,
  TemplateGetRevisionsError,
  WidgetAvatarTemplateList,
  WidgetAvatarTemplateListError,
  WidgetAvatarTemplateListSuccess,
  WidgetAvatarTemplateCreate,
  WidgetAvatarTemplateCreateError,
  WidgetAvatarTemplateUpdate,
  WidgetAvatarTemplateCreateSuccess,
  WidgetAvatarTemplateUpdateSuccess,
  WidgetAvatarTemplateUpdateError,
  WidgetAvatarTemplateListAll,
  WidgetAvatarTemplateListAllSuccess,
  WidgetAvatarTemplateListAllError,
  WidgetAvatarTemplateDelete,
  WidgetAvatarTemplateDeleteSuccess,
  WidgetAvatarTemplateDeleteError,
  WidgetAvatarTemplateGenerate,
  WidgetAvatarTemplateGenerateSuccess,
  WidgetAvatarTemplateGenerateError
} from '@app/stores/template/template.actions';
import {initialTemplateState} from '@app/stores/template/template.state';

const generateReducer = createReducer(initialTemplateState,
  on(TemplateListAll, (state) => ({
    ...state,
    template: null,
    templates: [],
    success: '',
    error: ''
  })),
  on(TemplateListAllSuccess, (state, {templates}) => ({
    ...state,
    template: null,
    templates,
    success: 'List found successfully',
    error: ''
  })),
  on(TemplateListAllError, (state, {error}) => ({
    ...state,
    template: null,
    templates: [],
    success: '',
    error
  })),
  on(TemplateList, (state) => ({
    ...state,
    template: null,
    paginatedTemplates: null,
    success: '',
    error: ''
  })),
  on(TemplateListSuccess, (state, {paginatedTemplates}) => ({
    ...state,
    template: null,
    paginatedTemplates,
    success: 'List found successfully',
    error: ''
  })),
  on(TemplateListError, (state, {error}) => ({
    ...state,
    template: null,
    paginatedTemplates: null,
    success: '',
    error
  })),

  on(TemplateUpdateStatus, (state) => ({
    ...state,
    template: null,
    success: '',
    error: ''
  })),
  on(TemplateUpdateStatusSuccess, (state, {template}) => ({
    ...state,
    template,
    success: '',
    error: ''
  })),
  on(TemplateUpdateStatusError, (state, {error}) => ({
    ...state,
    template: null,
    success: '',
    error
  })),

  on(TemplateTouch, (state) => ({
    ...state,
    template: null,
    success: '',
    error: ''
  })),
  on(TemplateTouchSuccess, (state, {template}) => ({
    ...state,
    template,
    success: '',
    error: ''
  })),
  on(TemplateTouchError, (state, {error}) => ({
    ...state,
    template: null,
    success: '',
    error
  })),

  on(TemplateGet, (state) => ({
    ...state,
    template: null,
    success: '',
    error: ''
  })),
  on(TemplateGetSuccess, (state, {template}) => ({
    ...state,
    template,
    success: '',
    error: ''
  })),
  on(TemplateGetError, (state, {error}) => ({
    ...state,
    template: null,
    success: '',
    error
  })),

  on(TemplatePreview, (state) => ({
    ...state,
    success: '',
    error: ''
  })),
  on(TemplatePreviewSuccess, (state) => ({
    ...state,
    success: 'Preview email has been sent, you may check your inbox.',
    error: ''
  })),
  on(TemplatePreviewError, (state, {error}) => ({
    ...state,
    success: '',
    error
  })),

  on(TemplateUpdate, (state) => ({
    ...state,
    template: null,
    success: '',
    error: ''
  })),
  on(TemplateUpdateSuccess, (state, {template}) => ({
    ...state,
    template,
    success: '',
    error: ''
  })),
  on(TemplateUpdateError, (state, {error}) => ({
    ...state,
    template: null,
    success: '',
    error
  })),

  on(TemplateCreate, (state) => ({
    ...state,
    template: null,
    success: '',
    error: ''
  })),
  on(TemplateCreateSuccess, (state, {template}) => ({
    ...state,
    template,
    success: '',
    error: ''
  })),
  on(TemplateCreateError, (state, {error}) => ({
    ...state,
    template: null,
    success: '',
    error
  })),

  on(TemplateCampaign, (state) => ({
    ...state,
    templateCampaign: null,
    success: '',
    error: ''
  })),
  on(TemplateCampaignSuccess, (state, {templateCampaign}) => ({
    ...state,
    templateCampaign,
    success: '',
    error: ''
  })),
  on(TemplateCampaignError, (state, {error}) => ({
    ...state,
    templateCampaign: null,
    success: '',
    error
  })),

  on(TemplatePreviewURL, (state) => ({
    ...state,
    previewUrl: null,
    success: '',
    error: ''
  })),
  on(TemplatePreviewURLSuccess, (state, {previewUrl}) => ({
    ...state,
    previewUrl,
    success: '',
    error: ''
  })),
  on(TemplatePreviewURLError, (state, {error}) => ({
    ...state,
    previewUrl: null,
    success: '',
    error
  })),

  on(TemplateWidgetPreviewURL, (state) => ({
    ...state,
    widgetPreviewUrl: null,
    success: '',
    error: ''
  })),
  on(TemplateWidgetPreviewURLSuccess, (state, {widgetPreviewUrl}) => ({
    ...state,
    widgetPreviewUrl,
    success: '',
    error: ''
  })),
  on(TemplateWidgetPreviewURLError, (state, {error}) => ({
    ...state,
    widgetPreviewUrl: null,
    success: '',
    error
  })),

  on(TemplateDelete, (state) => ({
    ...state,
    success: '',
    error: ''
  })),
  on(TemplateDeleteSuccess, (state) => ({
    ...state,
    success: 'Template Deleted Successfully',
    error: ''
  })),
  on(TemplateDeleteError, (state, {error}) => ({
    ...state,
    success: '',
    error
  })),

  on(TemplateUpdateThumbnail, (state) => ({
    ...state,
    success: '',
    error: ''
  })),
  on(TemplateUpdateThumbnailSuccess, (state, {success}) => ({
    ...state,
    success,
    error: ''
  })),
  on(TemplateUpdateThumbnailError, (state, {error}) => ({
    ...state,
    success: '',
    error
  })),

  on(TemplateGetTypes, (state) => ({
    ...state,
    templateTypes: [],
    success: '',
    error: ''
  })),
  on(TemplateGetTypesSuccess, (state, {templateTypes}) => ({
    ...state,
    templateTypes,
    success: '',
    error: ''
  })),
  on(TemplateGetTypesError, (state, {error}) => ({
    ...state,
    templateTypes: [],
    success: '',
    error
  })),

  on(WidgetIntegrationBody, (state) => ({
    ...state,
    widgetIntegrationBody: null,
    success: '',
    error: ''
  })),
  on(WidgetIntegrationBodySuccess, (state, {widgetIntegrationBody}) => ({
    ...state,
    widgetIntegrationBody,
    success: '',
    error: ''
  })),
  on(WidgetIntegrationBodyError, (state, {error}) => ({
    ...state,
    widgetIntegrationBody: null,
    success: '',
    error
  })),

  on(WidgetCustomScriptBody, (state) => ({
    ...state,
    widgetCustomScriptBody: null,
    success: '',
    error: ''
  })),
  on(WidgetCustomScriptBodySuccess, (state, {widgetCustomScriptBody}) => ({
    ...state,
    widgetCustomScriptBody,
    success: '',
    error: ''
  })),
  on(WidgetCustomScriptBodyError, (state, {error}) => ({
    ...state,
    widgetCustomScriptBody: null,
    success: '',
    error
  })),

  on(TemplateGetRevisions, (state) => ({
    ...state,
    templateRevisions: null,
    success: '',
    error: ''
  })),
  on(TemplateGetRevisionsSuccess, (state, {templateRevisions}) => ({
    ...state,
    templateRevisions,
    success: '',
    error: ''
  })),
  on(TemplateGetRevisionsError, (state, {error}) => ({
    ...state,
    templateRevisions: null,
    success: '',
    error
  })),

  on(WidgetAvatarTemplateList, (state) => ({
    ...state,
    widgetAvatarTemplate: null,
    paginatedWidgetAvatarTemplates: null,
    success: '',
    error: ''
  })),
  on(WidgetAvatarTemplateListSuccess, (state, {paginatedWidgetAvatarTemplates}) => ({
    ...state,
    widgetAvatarTemplate: null,
    paginatedWidgetAvatarTemplates,
    success: 'List found successfully',
    error: ''
  })),
  on(WidgetAvatarTemplateListError, (state, {error}) => ({
    ...state,
    widgetAvatarTemplate: null,
    paginatedWidgetAvatarTemplates: null,
    success: '',
    error
  })),

  on(WidgetAvatarTemplateListAll, (state) => ({
    ...state,
    widgetAvatarTemplate: null,
    widgetAvatarTemplates: [],
    success: '',
    error: ''
  })),
  on(WidgetAvatarTemplateListAllSuccess, (state, {widgetAvatarTemplates}) => ({
    ...state,
    widgetAvatarTemplate: null,
    widgetAvatarTemplates,
    success: 'List found successfully',
    error: ''
  })),
  on(WidgetAvatarTemplateListAllError, (state, {error}) => ({
    ...state,
    widgetAvatarTemplate: null,
    widgetAvatarTemplates: [],
    success: '',
    error
  })),

  on(WidgetAvatarTemplateCreate, (state) => ({
    ...state,
    widgetAvatarTemplate: null,
    success: '',
    error: ''
  })),
  on(WidgetAvatarTemplateCreateSuccess, (state, {widgetAvatarTemplate}) => ({
    ...state,
    widgetAvatarTemplate,
    success: 'Widget avatar template created successfully',
    error: ''
  })),
  on(WidgetAvatarTemplateCreateError, (state, {error}) => ({
    ...state,
    widgetAvatarTemplate: null,
    success: '',
    error
  })),

  on(WidgetAvatarTemplateUpdate, (state) => ({
    ...state,
    widgetAvatarTemplate: null,
    success: '',
    error: ''
  })),
  on(WidgetAvatarTemplateUpdateSuccess, (state, {widgetAvatarTemplate}) => ({
    ...state,
    widgetAvatarTemplate,
    success: 'Widget avatar template updated successfully',
    error: ''
  })),
  on(WidgetAvatarTemplateUpdateError, (state, {error}) => ({
    ...state,
    widgetAvatarTemplate: null,
    success: '',
    error
  })),

  on(WidgetAvatarTemplateDelete, (state) => ({
    ...state,
    success: '',
    error: ''
  })),
  on(WidgetAvatarTemplateDeleteSuccess, (state) => ({
    ...state,
    success: 'Widget avatar template deleted Successfully',
    error: ''
  })),
  on(WidgetAvatarTemplateDeleteError, (state, {error}) => ({
    ...state,
    success: '',
    error
  })),

  on(WidgetAvatarTemplateGenerate, (state) => ({
    ...state,
    widgetAvatars: [],
    success: '',
    error: ''
  })),
  on(WidgetAvatarTemplateGenerateSuccess, (state, {widgetAvatars}) => ({
    ...state,
    widgetAvatars,
    success: '',
    error: ''
  })),
  on(WidgetAvatarTemplateGenerateError, (state, {error}) => ({
    ...state,
    widgetAvatars: [],
    success: '',
    error
  })),

  on(ResetTemplateState, (state, {params}) => ({
    ...state,
    ...params
  })),
);

export function templateReducer(state = initialTemplateState, action: Action) {
  return generateReducer(state, action);
}
