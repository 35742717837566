import { Component, OnInit }                               from '@angular/core';
import { Subscription }                                    from 'rxjs';
import { User }                                            from '@app/modules/shared/models';
import { Router }                                          from '@angular/router';
import { UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { AlertService } from '@app/services/alert.service';
import { UserService }                                     from '@app/services/user.service';
import {select, Store} from '@ngrx/store';
import {getLoggedInUser, IAuthenticationState} from '@app/stores';

@Component ({
    templateUrl: './profile.component.html',
    styleUrls  : [ './profile.component.css' ]
})
export class ProfileComponent implements OnInit {
    id: number;
    currentUser: User;
    updatedUser: any;
    loading = false;
    currentUserSubscription: Subscription;
    editUserForm: UntypedFormGroup;
    submitted = false;
    editUserMessage: object;
    roles = [
        {
            id  : 1,
            name: 'Admin'
        }, {
            id  : 2,
            name: 'Client'
        }
    ];
    editInfo = false;
    oneononeFeature = false;
    videoFeature = false;

    constructor(
        private formBuilder: UntypedFormBuilder,
        private router: Router,
        private authenticationStore: Store<IAuthenticationState>,
        private userService: UserService,
        private alertService: AlertService
    ) {
      this.authenticationStore.pipe(select(getLoggedInUser)).subscribe(user => {
          this.currentUser = this.updatedUser = user;
          if (!user.token) {
            this.router.navigate(['/login']);
          }
        }
      );
    }

    get f() {
        return this.editUserForm.controls;
    }

    ngOnInit() {
        this.editUserForm = this.formBuilder.group ({
            first_name           : [ '', Validators.required ],
            last_name            : [ '', Validators.required ],
            company              : [ '', Validators.required ],
            job_title            : [ '', Validators.required ],
            email                : new UntypedFormControl ({
                value   : '',
                disabled: true
            }),
            phone                : [ '' ],
            website              : [ '' ],
            event                : [ '' ],
            role                 : [ '', [ Validators.required ] ],
            subscription_oneonone: [ '' ],
            subscription_video   : [ '' ]
        });

        this.loadUser ();
        this.editUserMessage = {
            Success: false,
            msg    : ''
        };
    }

    activeForm() {
        if (this.editInfo === false) {
            $ ('#basicinfo').addClass ('activeForm');
            $ ('.editView-btn1').show ();
            this.editInfo = true;
        } else {
            $ ('#basicinfo').removeClass ('activeForm');
            $ ('.editView-btn1').hide ();
            this.editInfo = false;
        }
    }


    /**
     * @name editUser
     * @desc Update client profile information.
     * @param
     * @returns
     * @memberOf ProfileComponent.editUser
     */
    public editUser() {
        this.submitted = true;
        this.updatedUser = this.currentUser;

        if (this.editUserForm.invalid) {
            return;
        }

        this.loading = true;
        this.userService.editUser (this.editUserForm.value, this.currentUser[ 'user' ].id).subscribe (
            data => {
                this.editUserMessage = data;
                if (this.editUserMessage[ 'Success' ]) {
                    this.updateUserDetails ();
                    this.alertService.success ('Account Updated successfully', true);
                    this.router.navigate ([ '/' ]);
                }
            },
            error => {
                this.alertService.error (error);
                this.loading = false;
            }
        );
    }

    /**
     * @name updateUserDetails
     * @desc Update the client registration details.
     * @param
     * @returns
     * @memberOf ProfileComponent.updateUserDetails
     */

    updateUserDetails() {
        this.updatedUser.user.first_name = this.editUserForm.get ('first_name').value;
        this.updatedUser.user.last_name = this.editUserForm.get ('last_name').value;
        this.updatedUser.user.company = this.editUserForm.get ('company').value;
        this.updatedUser.user.job_title = this.editUserForm.get ('job_title').value;
        this.updatedUser.user.email = this.editUserForm.get ('email').value;
        this.updatedUser.user.phone = this.editUserForm.get ('phone').value;
        this.updatedUser.user.website = this.editUserForm.get ('website').value;
        this.updatedUser.user.event = this.editUserForm.get ('event').value;
        this.updatedUser.user.role = this.editUserForm.get ('role').value;
        this.updatedUser.user.subscription_oneonone = this.editUserForm.get ('subscription_oneonone').value;
        this.updatedUser.user.subscription_video = this.editUserForm.get ('subscription_video').value;

        // this.authenticationService.updateUser (this.updatedUser);
    }

    /**
     * @name loadUser
     * @desc Get client data.
     * @param
     * @returns
     * @memberOf ProfileComponent.loadUser
     */
    private loadUser() {
        this.editUserForm.patchValue (this.updatedUser.user);
        this.oneononeFeature = this.updatedUser.user.subscription_oneonone;
        this.videoFeature = this.updatedUser.user.subscription_video;
        this.editUserForm.controls[ 'role' ].setValue (
            this.currentUser[ 'user' ].user_type,
            {onlySelf: true}
        );
    }

}
