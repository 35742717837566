<div class="container-fluid pt-3">
  <div class="rightBody">
    <div class="row">
      <div class="col-md-12">
        <div class="card">
          <div class="card-body innerpadding">
            <div class="searchWrap">
                <div class="row">
                    <div class="col-md-12">
                      <div class="searchPanel">
                        <div class="floating-label">
                          <input class="floating-input" type="text" placeholder=" ">
                          <span class="highlight"></span>
                          <label>Search snôball guidelines</label>
                        </div>
                      </div>
                    </div>
                  </div>

            <div class="row">
                <div class="col-md-12">
                  <div class="questionAnswer">
                      <h4>How to create Landing page?</h4>
                      <p>
                        Lorem ipsum dolor sit amet, consectetuer adipiscing elit. Aenean commodo ligula eget dolor. Aenean massa. Cum sociis natoque
                        penatibus et magnis dis parturient montes, nascetur ridiculus mus. Donec quam felis, ultricies nec, pellentesque
                        eu, pretium quis, sem.Nulla consequat massa quis enim. Donec pede justo, fringilla vel, aliquet nec, vulputate
                        eget, arcu. In enim justo, rhoncus ut, imperdiet a, venenatis vitae, justo. Nullam dictum felis eu pede
                        mollis pretium. Integer tincidunt. Cras dapibus.<a href="JavaScript:Void(0)">Read More...</a>
                        
                      </p>
                  </div>

                  <div class="questionAnswer">
                      <h4>How to create Launch Campaign?</h4>
                      <p>
                        Lorem ipsum dolor sit amet, consectetuer adipiscing elit. Aenean commodo ligula eget dolor. Aenean massa. Cum sociis natoque
                        penatibus et magnis dis parturient montes, nascetur ridiculus mus. Donec quam felis, ultricies nec, pellentesque
                        eu, pretium quis, sem.Nulla consequat massa quis enim. Donec pede justo, fringilla vel, aliquet nec, vulputate
                        eget, arcu. In enim justo, rhoncus ut, imperdiet a, venenatis vitae, justo. Nullam dictum felis eu pede
                        mollis pretium. Integer tincidunt. Cras dapibus.<a href="JavaScript:Void(0)">Read More...</a>
                        
                      </p>
                  </div>


                  <div class="questionAnswer">
                      <h4>Schedule one on one meeting</h4>
                      <p>
                        Lorem ipsum dolor sit amet, consectetuer adipiscing elit. Aenean commodo ligula eget dolor. Aenean massa. Cum sociis natoque
                        penatibus et magnis dis parturient montes, nascetur ridiculus mus. Donec quam felis, ultricies nec, pellentesque
                        eu, pretium quis, sem.Nulla consequat massa quis enim. Donec pede justo, fringilla vel, aliquet nec, vulputate
                        eget, arcu. In enim justo, rhoncus ut, imperdiet a, venenatis vitae, justo. Nullam dictum felis eu pede
                        mollis pretium. Integer tincidunt. Cras dapibus.<a href="JavaScript:Void(0)">Read More...</a>
                        
                      </p>
                  </div>
                  
                </div>
              </div>
            </div>
            

          </div>
        </div>
      </div>
    </div>


  </div>
</div>
<div class="chat-ic">
    <button type="button" class="icon-comments1">
     
    </button>
  </div>
