export const LANDING_PAGE_TEMPLATE_TYPE_ID = 1;
export const EMAIL_TEMPLATE_TYPE_ID = 2;
export const SOCIAL_MEDIA_TEMPLATE_TYPE_ID = 4;

import * as moment from 'moment-timezone';
const currentUserData = localStorage.getItem('currentUser');
const currentUser = JSON.parse(currentUserData);
export const smartDateFormat = (datetime, inputTimeZone = null, inputFormat = null, outPutFormatName = null, outPutTimeZone = null, returnObject = false) => {
  if (!datetime) { return ''; }
  if (typeof datetime === 'object') {
    datetime = datetime.format('YYYY-MM-DD[T]HH[:]mm[:]00[.000Z]');
  }
  let format;
  if (!outPutTimeZone) {
    if (currentUser && currentUser.timezone) {
      outPutTimeZone = currentUser.timezone;
  }}
  if (!outPutTimeZone) {
    outPutTimeZone = 'Etc/UTC';
  }
  if (!inputTimeZone) {
    inputTimeZone = 'Etc/UTC';
  }
  if (!outPutFormatName) {
    outPutFormatName = 'fullDateTime';
  }
  if (!inputFormat) {
    // inputFormat = 'MM-DD-YYYY HH:mm:ss';
  }
  let timezoneSuffix = false;
  if (outPutFormatName === 'fullDateTimeWithTimeZone') { format = 'MMM Do YYYY, h:mm A (0000 Z)'; timezoneSuffix = true; }
  if (outPutFormatName === 'fullDateTime') { format = 'MMM Do YYYY, h:mm A'; }
  if (outPutFormatName === 'fullTimeTimeZone') { format = 'h:mm (0000 Z)'; timezoneSuffix = true; }
  if (outPutFormatName === 'fullTime') { format = 'h:mm A'; }
  if (outPutFormatName === 'mysql') { format = 'YYYY-MM-DD HH:mm:ss'; }
  let myTime;
  if (inputFormat) {
    myTime = moment(datetime, inputTimeZone);
    myTime = moment.tz(myTime.format('YYYY-MM-DD HH:mm:ss'), inputTimeZone);
  } else {
    myTime = moment.tz(datetime, inputTimeZone);
  }
  let result = null;
  if (returnObject) {
    if (outPutTimeZone) {
      result = myTime.tz(outPutTimeZone);
    } else {
      result = myTime;
    }
  } else {
    result = myTime.tz(outPutTimeZone).format(format);
    if (timezoneSuffix) {
      result = result.replace('0000', outPutTimeZone);
    }
  }
  // console.log(datetime, inputTimeZone, inputFormat, outPutFormatName, outPutTimeZone, returnObject, format, myTime, result);
  return result;
};

export const TEMPLATE_TYPE_IDS = [
  LANDING_PAGE_TEMPLATE_TYPE_ID,
  EMAIL_TEMPLATE_TYPE_ID,
  EMAIL_TEMPLATE_TYPE_ID,
];

export const ATTENDEE_TYPE_ID = 3;
export const EXHIBITOR_TYPE_ID = 4;
export const SPEAKER_TYPE_ID = 5;
export const SPONSOR_TYPE_ID = 6;

const PROJECT_VARIABLES = {
  'Project Name': { text: '#ProjectName#', sample: (data) => data.replace(/#ProjectName#/g, 'Project Name Sample') },
  'Project Logo': { text: '#ProjectLogo#', sample: (data) => data.replace(/#ProjectLogo#/g, '<img class="event-logo" src="https://snoball-dev.s3.us-east-2.amazonaws.com/template-static-assets/logo3.png" style="width: 280px">') },
  'Registration Link': { text: 'Register Now', href: '#RegistrationLink#', sample: (data) => data.replace(/#RegistrationLink#/g, 'https://example.com/register') },
  'Project Date Time': { text: '#ProjectDateTime#', sample: (data) => data.replace(/#ProjectDateTime#/g, 'Dec 25, 2020') }
};

export const PERSON_GENERAL_VARIABLES = {
  'First Name': { text: '#Fname#', sample: (data) => data.replace(/#Fname#/g, 'Ron') },
  'Last Name': { text: '#Lname#', sample: (data) => data.replace(/#Lname#/g, 'Singler') },
  'Email': { text: '#Email#', href: 'mailto: #Email#', sample: (data) => data.replace(/#Email#/g, 'contact@example.com') }
};

const PERSON_VARIABLES = {
  ...PERSON_GENERAL_VARIABLES,
  'Headshot': { text: '#HeadShot#', sample: (data) => data.replace(/#HeadShot#/g, '<img class="headshot" src="https://snoball-dev.s3.us-east-2.amazonaws.com/template-static-assets/2.jpg">') },
  'Snoball Landing Page Url': { text: '#Fname# #Lname#\'s Snoball Page', href: '#LandingPageUrl#', sample: (data) => data.replace(/#Fname #Lname/g, 'Ron Singler') },
  'Facebook': { text: '#Facebook#', sample: (data) => data.replace(/#Facebook#/g, 'https://facebook.com/ronstephan') },
  'Twitter': { text: '#Twitter#', sample: (data) => data.replace(/#Twitter#/g, 'https://twitter.com/ronstephan') },
  'LinkedIn': { text: '#Linkedin#', sample: (data) => data.replace(/#Linkedin#/g, 'https://linkedin.com/ronstephan') },
  'Instagram': { text: '#Instagram#', sample: (data) => data.replace(/#Instagram#/g, 'https://instagram.com/ronstephan') }
};

const COMPANY_VARIABLES = {
  'Snoball Landing Page Url': { text: '#Fname #Lname\'s Snoball Page', href: '#LandingPageUrl#', sample: (data) => data.replace(/#Fname #Lname/g, 'Ron Singler') },
  'External Landing Page Url': { text: '#CompanyName#\'s Page', href: '#ExternalLandingPageUrl#', sample: (data) => data.replace(/#CompanyName/g, 'Snöball') },
  'Video': { text: '#Video#', sample: (data) => data.replace(/#Video#/g, '<img src="../../../../assets/images/video.jpg" >') },
  'Company Name': { text: '#CompanyName#', sample: (data) => data.replace(/#CompanyName#/g, 'Snöball') },
  'Company Logo': { text: '#CompanyLogo#', sample: (data) => data.replace(/#CompanyLogo#/g, '<img class="company-logo" src="https://snoball-dev.s3.us-east-2.amazonaws.com/template-static-assets/logo3.png" style="width: 280px">') },
  'Booth Number': { text: '#BoothNumber#', sample: (data) => data.replace(/#BoothNumber#/g, '123') },
  'Description': { text: '#Description#', sample: (data) => data.replace(/#Description#/g, '92% of people trust word-of-mouth') },
};

export const VARIABLES = {
  [ATTENDEE_TYPE_ID]: {
    ...PROJECT_VARIABLES,
    ...PERSON_VARIABLES
  },
  [SPEAKER_TYPE_ID]: {
    ...PROJECT_VARIABLES,
    ...PERSON_VARIABLES,
    'Presentation Schedule': {
      text: '#PresentationSchedule#', sample: (data) => {
        return data.replace(/#PresentationSchedule#/g, `
        <table class="schedule">
          <tr>
            <td class="icon"></td>
            <td class="title">My Talk 1</td>
            <td class="date">Dec 25, 2020</td>
            <td class="time">2:30 AM</td>
          </tr>
          <tr>
            <td class="icon"></td>
            <td class="title">My Talk 2</td>
            <td class="date">Dec 25, 2020</td>
            <td class="time">6:30 AM</td>
          </tr>
        </table>
      `);
      }
    }
  },

  [EXHIBITOR_TYPE_ID]: {
    ...PROJECT_VARIABLES,
    ...COMPANY_VARIABLES,
    'Team Members': { text: '#TeamMembers#', sample: (data) => data }
  },
  [SPONSOR_TYPE_ID]: {
    ...PROJECT_VARIABLES,
    ...COMPANY_VARIABLES
  }
};

export const IMPRESSIONS_TYPES = {
  ACTION_TYPES: { '0': '_visits', '1': '_clicks' },
  LINK_TYPES: { '0': 'landing_page', '1': 'registration_link' }
};

export const INFLUENCER_TYPES = {
  IDs: {
    'attendee': ATTENDEE_TYPE_ID,
    'exhibitor': EXHIBITOR_TYPE_ID,
    'speaker': SPEAKER_TYPE_ID,
    'sponsor': SPONSOR_TYPE_ID,
  },
  NAMES: {
    '3' : { 'singular': 'attendee', 'plural': 'attendees', 'title_case': 'Attendees' },
    '4': { 'singular': 'exhibitor', 'plural': 'exhibitors', 'title_case': 'Exhibitors' },
    '5': { 'singular': 'speaker', 'plural': 'speakers', 'title_case': 'Speakers' },
    '6': { 'singular': 'sponsor', 'plural': 'sponsors', 'title_case': 'Sponsors' },
  }
};

export class Constants {
  timezone = [
    {
      timeZoneId: '1',
      gmtAdjustment: 'GMT-12:00',
      useDaylightTime: '0',
      value: '-12',
      text: '(GMT-12:00) International Date Line West'
    },
    {
      timeZoneId: '2',
      gmtAdjustment: 'GMT-11:00',
      useDaylightTime: '0', value: '-11',
      text: '(GMT-11:00) Midway Island, Samoa'
    },
    {
      timeZoneId: '3',
      gmtAdjustment: 'GMT-10:00',
      useDaylightTime: '0', value: '-10',
      text: '(GMT-10:00) Hawaii'
    },
    {
      timeZoneId: '4',
      gmtAdjustment: 'GMT-09:00',
      useDaylightTime: '1', value: '-9',
      text: '(GMT-09:00) Alaska'
    },
    {
      timeZoneId: '5',
      gmtAdjustment: 'GMT-08:00',
      useDaylightTime: '1', value: '-8',
      text: '(GMT-08:00) Pacific Time (US & Canada)'
    },
    {
      timeZoneId: '6',
      gmtAdjustment: 'GMT-08:00',
      useDaylightTime: '1', value: '-8',
      text: '(GMT-08:00) Tijuana, Baja California'
    },
    {
      timeZoneId: '7',
      gmtAdjustment: 'GMT-07:00',
      useDaylightTime: '0', value: '-7',
      text: '(GMT-07:00) Arizona'
    },
    {
      timeZoneId: '8',
      gmtAdjustment: 'GMT-07:00',
      useDaylightTime: '1', value: '-7',
      text: '(GMT-07:00) Chihuahua, La Paz, Mazatlan'
    },
    {
      timeZoneId: '9',
      gmtAdjustment: 'GMT-07:00',
      useDaylightTime: '1', value: '-7',
      text: '(GMT-07:00) Mountain Time (US & Canada)'
    },
    {
      timeZoneId: '10',
      gmtAdjustment: 'GMT-06:00',
      useDaylightTime: '0', value: '-6',
      text: '(GMT-06:00) Central America'
    },
    {
      timeZoneId: '11',
      gmtAdjustment: 'GMT-06:00',
      useDaylightTime: '1', value: '-6',
      text: '(GMT-06:00) Central Time (US & Canada)'
    },
    {
      timeZoneId: '12',
      gmtAdjustment: 'GMT-06:00',
      useDaylightTime: '1', value: '-6',
      text: '(GMT-06:00) Guadalajara, Mexico City, Monterrey'
    },
    {
      timeZoneId: '13',
      gmtAdjustment: 'GMT-06:00',
      useDaylightTime: '0', value: '-6',
      text: '(GMT-06:00) Saskatchewan'
    },
    {
      timeZoneId: '14',
      gmtAdjustment: 'GMT-05:00',
      useDaylightTime: '0', value: '-5',
      text: '(GMT-05:00) Bogota, Lima, Quito, Rio Branco'
    },
    {
      timeZoneId: '15',
      gmtAdjustment: 'GMT-05:00',
      useDaylightTime: '1', value: '-5',
      text: '(GMT-05:00) Eastern Time (US & Canada)'
    },
    {
      timeZoneId: '16',
      gmtAdjustment: 'GMT-05:00',
      useDaylightTime: '1', value: '-5',
      text: '(GMT-05:00) Indiana (East)'
    },
    {
      timeZoneId: '17',
      gmtAdjustment: 'GMT-04:00',
      useDaylightTime: '1', value: '-4',
      text: '(GMT-04:00) Atlantic Time (Canada)'
    },
    {
      timeZoneId: '18',
      gmtAdjustment: 'GMT-04:00',
      useDaylightTime: '0', value: '-4',
      text: '(GMT-04:00) Caracas, La Paz'
    },
    {
      timeZoneId: '19',
      gmtAdjustment: 'GMT-04:00',
      useDaylightTime: '0', value: '-4',
      text: '(GMT-04:00) Manaus'
    },
    {
      timeZoneId: '20',
      gmtAdjustment: 'GMT-04:00',
      useDaylightTime: '1', value: '-4',
      text: '(GMT-04:00) Santiago'
    },
    {
      timeZoneId: '21',
      gmtAdjustment: 'GMT-03:30',
      useDaylightTime: '1', value: '-3.5',
      text: '(GMT-03:30) Newfoundland'
    },
    {
      timeZoneId: '22',
      gmtAdjustment: 'GMT-03:00',
      useDaylightTime: '1', value: '-3',
      text: '(GMT-03:00) Brasilia'
    },
    {
      timeZoneId: '23',
      gmtAdjustment: 'GMT-03:00',
      useDaylightTime: '0', value: '-3',
      text: '(GMT-03:00) Buenos Aires, Georgetown'
    },
    {
      timeZoneId: '24',
      gmtAdjustment: 'GMT-03:00',
      useDaylightTime: '1', value: '-3',
      text: '(GMT-03:00) Greenland'
    },
    {
      timeZoneId: '25',
      gmtAdjustment: 'GMT-03:00',
      useDaylightTime: '1', value: '-3',
      text: '(GMT-03:00) Montevideo'
    },
    {
      timeZoneId: '26',
      gmtAdjustment: 'GMT-02:00',
      useDaylightTime: '1', value: '-2',
      text: '(GMT-02:00) Mid-Atlantic'
    },
    {
      timeZoneId: '27',
      gmtAdjustment: 'GMT-01:00',
      useDaylightTime: '0', value: '-1',
      text: '(GMT-01:00) Cape Verde Is.'
    },
    {
      timeZoneId: '28',
      gmtAdjustment: 'GMT-01:00',
      useDaylightTime: '1', value: '-1',
      text: '(GMT-01:00) Azores'
    },
    {
      timeZoneId: '29',
      gmtAdjustment: 'GMT+00:00',
      useDaylightTime: '0', value: '0',
      text: '(GMT+00:00) Casablanca, Monrovia, Reykjavik'
    },
    {
      timeZoneId: '30',
      gmtAdjustment: 'GMT+00:00',
      useDaylightTime: '1', value: '0',
      text: '(GMT+00:00) Greenwich Mean Time : Dublin, Edinburgh, Lisbon, London'
    },
    {
      timeZoneId: '31',
      gmtAdjustment: 'GMT+01:00',
      useDaylightTime: '1', value: '1',
      text: '(GMT+01:00) Amsterdam, Berlin, Bern, Rome, Stockholm, Vienna'
    },
    {
      timeZoneId: '32',
      gmtAdjustment: 'GMT+01:00',
      useDaylightTime: '1', value: '1',
      text: '(GMT+01:00) Belgrade, Bratislava, Budapest, Ljubljana, Prague'
    },
    {
      timeZoneId: '33',
      gmtAdjustment: 'GMT+01:00',
      useDaylightTime: '1', value: '1',
      text: '(GMT+01:00) Brussels, Copenhagen, Madrid, Paris'
    },
    {
      timeZoneId: '34',
      gmtAdjustment: 'GMT+01:00',
      useDaylightTime: '1', value: '1',
      text: '(GMT+01:00) Sarajevo, Skopje, Warsaw, Zagreb'
    },
    {
      timeZoneId: '35',
      gmtAdjustment: 'GMT+01:00',
      useDaylightTime: '1', value: '1',
      text: '(GMT+01:00) West Central Africa'
    },
    {
      timeZoneId: '36',
      gmtAdjustment: 'GMT+02:00',
      useDaylightTime: '1', value: '2',
      text: '(GMT+02:00) Amman'
    },
    {
      timeZoneId: '37',
      gmtAdjustment: 'GMT+02:00',
      useDaylightTime: '1', value: '2',
      text: '(GMT+02:00) Athens, Bucharest, Istanbul'
    },
    {
      timeZoneId: '38',
      gmtAdjustment: 'GMT+02:00',
      useDaylightTime: '1', value: '2',
      text: '(GMT+02:00) Beirut'
    },
    {
      timeZoneId: '39',
      gmtAdjustment: 'GMT+02:00',
      useDaylightTime: '1', value: '2',
      text: '(GMT+02:00) Cairo'
    },
    {
      timeZoneId: '40',
      gmtAdjustment: 'GMT+02:00',
      useDaylightTime: '0', value: '2',
      text: '(GMT+02:00) Harare, Pretoria'
    },
    {
      timeZoneId: '41',
      gmtAdjustment: 'GMT+02:00',
      useDaylightTime: '1', value: '2',
      text: '(GMT+02:00) Helsinki, Kyiv, Riga, Sofia, Tallinn, Vilnius'
    },
    {
      timeZoneId: '42',
      gmtAdjustment: 'GMT+02:00',
      useDaylightTime: '1', value: '2',
      text: '(GMT+02:00) Jerusalem'
    },
    {
      timeZoneId: '43',
      gmtAdjustment: 'GMT+02:00',
      useDaylightTime: '1', value: '2',
      text: '(GMT+02:00) Minsk'
    },
    {
      timeZoneId: '44',
      gmtAdjustment: 'GMT+02:00',
      useDaylightTime: '1', value: '2',
      text: '(GMT+02:00) Windhoek'
    },
    {
      timeZoneId: '45',
      gmtAdjustment: 'GMT+03:00',
      useDaylightTime: '0', value: '3',
      text: '(GMT+03:00) Kuwait, Riyadh, Baghdad'
    },
    {
      timeZoneId: '46',
      gmtAdjustment: 'GMT+03:00',
      useDaylightTime: '1', value: '3',
      text: '(GMT+03:00) Moscow, St. Petersburg, Volgograd'
    },
    {
      timeZoneId: '47',
      gmtAdjustment: 'GMT+03:00',
      useDaylightTime: '0', value: '3',
      text: '(GMT+03:00) Nairobi'
    },
    {
      timeZoneId: '48',
      gmtAdjustment: 'GMT+03:00',
      useDaylightTime: '0', value: '3',
      text: '(GMT+03:00) Tbilisi'
    },
    {
      timeZoneId: '49',
      gmtAdjustment: 'GMT+03:30',
      useDaylightTime: '1', value: '3.5',
      text: '(GMT+03:30) Tehran'
    },
    {
      timeZoneId: '50',
      gmtAdjustment: 'GMT+04:00',
      useDaylightTime: '0', value: '4',
      text: '(GMT+04:00) Abu Dhabi, Muscat'
    },
    {
      timeZoneId: '51',
      gmtAdjustment: 'GMT+04:00',
      useDaylightTime: '1', value: '4',
      text: '(GMT+04:00) Baku'
    },
    {
      timeZoneId: '52',
      gmtAdjustment: 'GMT+04:00',
      useDaylightTime: '1', value: '4',
      text: '(GMT+04:00) Yerevan'
    },
    {
      timeZoneId: '53',
      gmtAdjustment: 'GMT+04:30',
      useDaylightTime: '0', value: '4.5',
      text: '(GMT+04:30) Kabul'
    },
    {
      timeZoneId: '54',
      gmtAdjustment: 'GMT+05:00',
      useDaylightTime: '1', value: '5',
      text: '(GMT+05:00) Yekaterinburg'
    },
    {
      timeZoneId: '55',
      gmtAdjustment: 'GMT+05:00',
      useDaylightTime: '0', value: '5',
      text: '(GMT+05:00) Islamabad, Karachi, Tashkent'
    },
    {
      timeZoneId: '56',
      gmtAdjustment: 'GMT+05:30',
      useDaylightTime: '0', value: '5.5',
      text: '(GMT+05:30) Sri Jayawardenapura'
    },
    {
      timeZoneId: '57',
      gmtAdjustment: 'GMT+05:30',
      useDaylightTime: '0', value: '5.5',
      text: '(GMT+05:30) Chennai, Kolkata, Mumbai, New Delhi'
    },
    {
      timeZoneId: '58',
      gmtAdjustment: 'GMT+05:45',
      useDaylightTime: '0', value: '5.75',
      text: '(GMT+05:45) Kathmandu'
    },
    {
      timeZoneId: '59',
      gmtAdjustment: 'GMT+06:00',
      useDaylightTime: '1', value: '6',
      text: '(GMT+06:00) Almaty, Novosibirsk'
    },
    {
      timeZoneId: '60',
      gmtAdjustment: 'GMT+06:00',
      useDaylightTime: '0', value: '6',
      text: '(GMT+06:00) Astana, Dhaka'
    },
    {
      timeZoneId: '61',
      gmtAdjustment: 'GMT+06:30',
      useDaylightTime: '0', value: '6.5',
      text: '(GMT+06:30) Yangon (Rangoon)'
    },
    {
      timeZoneId: '62',
      gmtAdjustment: 'GMT+07:00',
      useDaylightTime: '0', value: '7',
      text: '(GMT+07:00) Bangkok, Hanoi, Jakarta'
    },
    {
      timeZoneId: '63',
      gmtAdjustment: 'GMT+07:00',
      useDaylightTime: '1', value: '7',
      text: '(GMT+07:00) Krasnoyarsk'
    },
    {
      timeZoneId: '64',
      gmtAdjustment: 'GMT+08:00',
      useDaylightTime: '0', value: '8',
      text: '(GMT+08:00) Beijing, Chongqing, Hong Kong, Urumqi'
    },
    {
      timeZoneId: '65',
      gmtAdjustment: 'GMT+08:00',
      useDaylightTime: '0', value: '8',
      text: '(GMT+08:00) Kuala Lumpur, Singapore'
    },
    {
      timeZoneId: '66',
      gmtAdjustment: 'GMT+08:00',
      useDaylightTime: '0', value: '8',
      text: '(GMT+08:00) Irkutsk, Ulaan Bataar'
    },
    {
      timeZoneId: '67',
      gmtAdjustment: 'GMT+08:00',
      useDaylightTime: '0', value: '8',
      text: '(GMT+08:00) Perth'
    },
    {
      timeZoneId: '68',
      gmtAdjustment: 'GMT+08:00',
      useDaylightTime: '0', value: '8',
      text: '(GMT+08:00) Taipei'
    },
    {
      timeZoneId: '69',
      gmtAdjustment: 'GMT+09:00',
      useDaylightTime: '0', value: '9',
      text: '(GMT+09:00) Osaka, Sapporo, Tokyo'
    },
    {
      timeZoneId: '70',
      gmtAdjustment: 'GMT+09:00',
      useDaylightTime: '0', value: '9',
      text: '(GMT+09:00) Seoul'
    },
    {
      timeZoneId: '71',
      gmtAdjustment: 'GMT+09:00',
      useDaylightTime: '1', value: '9',
      text: '(GMT+09:00) Yakutsk'
    },
    {
      timeZoneId: '72',
      gmtAdjustment: 'GMT+09:30',
      useDaylightTime: '0', value: '9.5',
      text: '(GMT+09:30) Adelaide'
    },
    {
      timeZoneId: '73',
      gmtAdjustment: 'GMT+09:30',
      useDaylightTime: '0', value: '9.5',
      text: '(GMT+09:30) Darwin'
    },
    {
      timeZoneId: '74',
      gmtAdjustment: 'GMT+10:00',
      useDaylightTime: '0', value: '10',
      text: '(GMT+10:00) Brisbane'
    },
    {
      timeZoneId: '75',
      gmtAdjustment: 'GMT+10:00',
      useDaylightTime: '1', value: '10',
      text: '(GMT+10:00) Canberra, Melbourne, Sydney'
    },
    {
      timeZoneId: '76',
      gmtAdjustment: 'GMT+10:00',
      useDaylightTime: '1', value: '10',
      text: '(GMT+10:00) Hobart'
    },
    {
      timeZoneId: '77',
      gmtAdjustment: 'GMT+10:00',
      useDaylightTime: '0', value: '10',
      text: '(GMT+10:00) Guam, Port Moresby'
    },
    {
      timeZoneId: '78',
      gmtAdjustment: 'GMT+10:00',
      useDaylightTime: '1', value: '10',
      text: '(GMT+10:00) Vladivostok'
    },
    {
      timeZoneId: '79',
      gmtAdjustment: 'GMT+11:00',
      useDaylightTime: '1', value: '11',
      text: '(GMT+11:00) Magadan, Solomon Is., New Caledonia'
    },
    {
      timeZoneId: '80',
      gmtAdjustment: 'GMT+12:00',
      useDaylightTime: '1', value: '12',
      text: '(GMT+12:00) Auckland, Wellington'
    },
    {
      timeZoneId: '81',
      gmtAdjustment: 'GMT+12:00',
      useDaylightTime: '0', value: '12',
      text: '(GMT+12:00) Fiji, Kamchatka, Marshall Is.'
    },
    {
      timeZoneId: '82',
      gmtAdjustment: 'GMT+13:00',
      useDaylightTime: '0', value: '13',
      text: '(GMT+13:00) Nuku alofa'
    }
  ];
  urlRegex = '^(?:(?:http(?:s)?|ftp)://).*\..*';
  domainProviderInstruction = [
    {
      id: 1,
      title: 'Amazon Web Services',
      items: [
        {
          description: 'Configuring DNS',
          instructionUrl: 'https://docs.aws.amazon.com/Route53/latest/DeveloperGuide/ResourceRecordTypes.html'
        },
        {
          description: 'Resource Record Types',
          instructionUrl: 'https://docs.aws.amazon.com/Route53/latest/DeveloperGuide/dns-configuring.html'
        }
      ]
    },
    {
      id: 2,
      title: 'Bluehost Bluerock',
      items: [
        {
          description: 'Manage DNS Records',
          instructionUrl: 'https://www.bluehost.com/help/article/dns-management-add-edit-or-delete-dns-entries#add'
        }
      ]
    },
    {
      id: 3,
      title: 'Bluehost Legacy',
      items: [
        {
          description: 'DNS Management',
          instructionUrl: 'https://www.bluehost.com/help/article/dns-management-add-edit-or-delete-dns-entries#add'
        }
      ]
    },
    {
      id: 4,
      title: 'Dreamhost',
      items: [
        {
          description: 'DNS Overview',
          instructionUrl: 'https://help.dreamhost.com/hc/en-us/articles/215413857-DreamHost-DNS-overview'
        }
      ]
    },
    {
      id: 5,
      title: 'GoDaddy',
      items: [
        {
          description: 'Add a CNAME Record',
          instructionUrl: 'https://www.godaddy.com/help/add-a-cname-record-19236'
        }
      ]
    },
    {
      id: 6,
      title: 'Google Domains',
      items: [
        {
          description: 'DNS Basics',
          instructionUrl: 'https://support.google.com/a/answer/48090?hl=en'
        }
      ]
    },
    {
      id: 7,
      title: 'Hostgator',
      items: [
        {
          description: 'Manage DNS records',
          instructionUrl: 'https://support.hostgator.com/articles/hosting-guide/lets-get-started/dns-name-servers/manage-dns-records-with-hostgatorenom'
        }
      ]
    },
    {
      id: 8,
      title: 'Hover',
      items: [
        {
          description: 'Edit DNS Record',
          instructionUrl: 'https://help.hover.com/hc/en-us/articles/217282457-How-to-Edit-DNS-records-A-CNAME-MX-TXT-and-SRV-Updated-Aug-2015-'
        }
      ]
    },
    {
      id: 9,
      title: 'IONOS',
      items: [
        {
          description: 'Configuring CNAME Records',
          instructionUrl: 'https://www.ionos.com/help/domains/configuring-cname-records-for-subdomains/configuring-a-cname-record-for-a-subdomain/'
        }
      ]
    },
    {
      id: 10,
      title: 'Namecheap',
      items: [
        {
          description: 'SPF & DKIM',
          instructionUrl: 'https://www.namecheap.com/support/knowledgebase/article.aspx/9214/31/cpanel-email-deliverability-tool--spf-and-dkim-records'
        }
      ]
    },
    {
      id: 11,
      title: 'Squarespace',
      items: [
        {
          description: 'Advanced DNS Settings',
          instructionUrl: 'https://support.squarespace.com/hc/en-us/articles/205812348-Opening-Advanced-DNS-settings'
        }
      ]
    },
    {
      id: 12,
      title: 'Stablehost',
      items: [
        {
          description: 'How do I get to cpanel?',
          instructionUrl: 'https://billing.stablehost.com/index.php?rp=/knowledgebase/12/How-do-I-get-to-cPanel.html'
        }
      ]
    },
    {
      id: 13,
      title: 'Wordpress',
      items: [
        {
          description: 'Adding Custom DNS Records',
          instructionUrl: 'https://wordpress.com/support/domains/custom-dns/#adding-custom-dns-records'
        }
      ]
    },
    {
      id: 14,
      title: 'Siteground',
      items: [
        {
          description: 'Create CNAME Records',
          instructionUrl: 'https://www.siteground.com/kb/site-tools-vs-cpanel-comparison-create-cname-records/'
        }
      ]
    },
    {
      id: 15,
      title: 'Wix',
      items: [
        {
          description: 'Adding or updating CNAME Records',
          instructionUrl: 'https://support.wix.com/en/article/adding-or-updating-cname-records-in-your-wix-account'
        }
      ]
    }
  ];

  templateVisibleOptions = [
    {
      name: 'Template Visible',
      value: 'visible'
    },
    {
      name: 'Template Hidden',
      value: 'hidden'
    }
  ];

  skipValues = [
    {
      id:1,
      title:'Campaign Inclusion',
      value: 'campaign_inclusion'},
    {
      id:2,
      title: 'Campaign Email' ,
      value: 'campaign_email'
    }
    ];
}
