import {Component, OnDestroy, OnInit} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {ToastrService} from 'ngx-toastr';
import {UntypedFormBuilder, UntypedFormGroup, Validators} from '@angular/forms';
import validator2 from 'validator';

import * as routes from '@app/routes';
import {select, Store} from '@ngrx/store';
import {
  CampaignCreateError,
  CampaignCreateSuccess,
  CampaignGet,
  CampaignUpdateError,
  CampaignUpdateSuccess,
  getCampaign,
  getCampaignAdd,
  getCampaignError,
  getCampaignUpdate,
  getInfluencersTypes,
  getProject,
  getProjectError,
  getVariableError,
  getVariables,
  ICampaignState,
  IInfluencersState,
  InfluencersTypeList,
  IProjectState,
  IVariableState,
  ProjectGet,
  ResetCampaignState,
  ResetProjectState,
  VariableList
} from '@app/stores';
import {of, Subject} from 'rxjs';
import {catchError, map, takeUntil} from 'rxjs/operators';
import {Observable} from 'rxjs/Observable';
import {LoaderService} from '@app/services/loader.service';
import {InfluencerType} from '@app/modules/shared/models';
import {CampaignService} from '@app/services/campaign.service';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import validator from "validator";

@Component({
  selector: 'app-create-campaign',
  templateUrl: './create-campaign.component.html',
  styleUrls: ['./create-campaign.component.scss']
})
export class CreateCampaignComponent implements OnInit, OnDestroy {
  routes = routes;
  public projectId;
  public audienceType;
  public step;
  public campaign;
  public campaignId;
  public campaignForm: UntypedFormGroup;
  submitted = false;
  clientId: number;
  unsubscriber = new Subject();
  private modifiedCampaign: any = {};
  private addUpdatedClicked = false;
  loader$: Observable<boolean>;
  influencerTypes: InfluencerType[] = [];
  linkType: string = '';
  selectedCtaLinkType: string = '';
  customUrl = '';
  directURL = '';
  currentProject: any = null;
  creatableOptions = [];
  fields = [
    {id: '', name: ''},
    {id: 'custom1', name: 'custom1'},
    {id: 'custom2', name: 'custom2'},
    {id: 'custom3', name: 'custom3'},
    {id: 'custom4', name: 'custom4'},
    {id: 'custom5', name: 'custom5'},
    {id: 'custom6', name: 'custom6'},
    {id: 'custom7', name: 'custom7'},
    {id: 'custom8', name: 'custom8'},
    {id: 'custom9', name: 'custom9'},
    {id: 'custom10', name: 'custom10'},
  ];

  public variables = {};
  constructor(
    private router: ActivatedRoute,
    private navigator: Router,
    private formBuilder: UntypedFormBuilder,
    private campaignStore: Store<ICampaignState>,
    private projectStore: Store<IProjectState>,
    private loaderService: LoaderService,
    private variableStore: Store<IVariableState>,
    private influencersStore: Store<IInfluencersState>,
    private toastr: ToastrService,
    private _modalService: NgbModal,
    private campaignService: CampaignService) {
    this.campaignStore.dispatch(ResetCampaignState({params: {error: '', campaign: null}}));
    this.projectStore.dispatch(ResetProjectState({params: {error: ''}}));
  }

  subscribeStore() {
    this.campaignStoreSubscribe();
    this.projectStoreSubscribe();
    this.influencerStoreSubscribe();
    this.variableStoreSubscribe();
  }

  variableStoreSubscribe() {
    this.variableStore.pipe(select(getVariableError))
      .pipe(takeUntil(this.unsubscriber))
      .subscribe(error => {
        if (error) {
          this.toastr.error(error, 'Error');
        }
      });
    this.variableStore.pipe(select(getVariables))
      .pipe(takeUntil(this.unsubscriber))
      .subscribe(variables => {
        if (variables) {
          const result = [];
          Object.entries(variables).forEach(([key, value]) => {
            if (value.hasOwnProperty('group') && value['group']) {
              if (!result[value['group']]) {
                result[value['group']] = [];
              }
              result[value['group']].push({
                name: value['title'].replace(value['group'].toString().toLowerCase().trim(), ''),
                key: value['alias'] ? `#${value['alias']}#` : value['key'],
                valueType: value['valueType']
              });
            } else {
              if (!result['generic']) {
                result['generic'] = [];
              }
              result['generic'].push({
                name: value['title'],
                key: value['key'],
                valueType: value['valueType']
              });
            }
          });
          this.variables = result;
        }
      });
  }

  projectStoreSubscribe() {
    this.projectStore.pipe(select(getProjectError))
      .pipe(takeUntil(this.unsubscriber))
      .subscribe(error => {
        if (error) {
          this.toastr.error(error, 'Error');
        }
      });
    this.projectStore.pipe(select(getProject))
      .pipe(takeUntil(this.unsubscriber))
      .subscribe(project => {
        if (project) {
          this.currentProject = project;
          this.creatableOptions = [{id: 'project', name: this.currentProject.registration_url}];
          this.selectedCtaLinkType = 'project';
          this.linkType = 'project';
          this.directURL = this.currentProject.registration_url;
          this.step.params.projectTitle = project.title;
          this.step.params.projectId = project.id;
          this.clientId = project.client_id;
        }
      });
  }

  campaignStoreSubscribe() {
    this.campaignStore.pipe(select(getCampaign))
      .pipe(takeUntil(this.unsubscriber))
      .subscribe((campaign) => {
        if (campaign) {
          const {id, name, project_id, link_type, custom_cta_url, influencer_dynamic_cta} = campaign;
          if (!this.audienceType) {
            this.campaignForm.setValue({
              id, name, project_id: project_id, custom_cta_url,
              ...({influencer_dynamic_cta: influencer_dynamic_cta ? influencer_dynamic_cta : ''})/*, email, subject_line*/
            });
          }
          this.linkType = link_type;

          this.directURL = this.currentProject.registration_url;

          if ((this.linkType === 'custom' && !this.creatableOptions.find(x => x.id === this.linkType))) {
            this.creatableOptions = [...this.creatableOptions, {id: this.linkType, name: custom_cta_url}];
            // this.creatableOptions.push({id: this.linkType, name: custom_cta_url});
          } else if (this.linkType === 'direct') {
            this.directURL = custom_cta_url;
          }

          this.step.params = {...this.step.params, campaignId: id, campaignName: name};
        }
      });
    this.campaignStore.pipe(select(getCampaignError))
      .pipe(takeUntil(this.unsubscriber))
      .subscribe(error => {
        if (error) {
          this.toastr.error(error, 'Error');
        }
      });
    this.campaignStore.pipe(select(getCampaignUpdate))
      .pipe(takeUntil(this.unsubscriber))
      .subscribe(campaign => {
        if (campaign) {
          if (this.addUpdatedClicked) {
            this.addUpdatedClicked = false;
            const options = {queryParams: {campId: campaign.id, audienceType: this.audienceType}};
            if (this.modifiedCampaign.exit) {
              this.navigator.navigate([`/projects/${this.projectId}/campaigns`]);
            } else {
              this.navigator.navigate([`/project/${this.projectId}/add-influencer`], options);
            }
          }
        }
      });
    this.campaignStore.pipe(select(getCampaignAdd))
      .pipe(takeUntil(this.unsubscriber))
      .subscribe(campaign => {
        if (campaign) {
          if (this.addUpdatedClicked) {
            this.addUpdatedClicked = false;
            const options = {queryParams: {campId: campaign.id, 'audienceType': this.audienceType}};
            if (this.modifiedCampaign.exit) {
              this.navigator.navigate([`/projects/${this.projectId}/campaigns`]);
            } else {
              this.navigator.navigate([`/project/${this.projectId}/add-influencer`], options);
            }
          }
        }
      });
  }

  influencerStoreSubscribe() {
    this.influencersStore.pipe(select(getInfluencersTypes))
      .pipe(takeUntil(this.unsubscriber))
      .subscribe(influencerTypes => {
        if (influencerTypes && influencerTypes.length > 0) {
          this.influencerTypes = influencerTypes;
        }
      });
  }

  ngOnInit() {
    this.variableStore.dispatch(VariableList({params: {}}));
    this.influencersStore.dispatch(InfluencersTypeList());
    this.loader$ = this.loaderService.loader$;
    this.step = {title: 'Campaign', params: {campaignName: 'New Campaign'}};
    this.router.params.subscribe(
      (params) => {
        this.projectId = params['id'];
        this.audienceType = params['audienceType'];
        this.campaignForm = this.formBuilder.group({
          'id': [''],
          'project_id': [this.projectId],
          'name': ['', Validators.required],
          'custom_cta_url': ['', [this.urlValidator.bind(this)]],
          'influencer_dynamic_cta': [''],
          // 'cta_url': ['', Validators.required]
          // 'email' : ['', Validators.required ],
          // 'subject_line' : ['', Validators.required ]
        });
        this.subscribeStore();
        this.projectStore.dispatch(ProjectGet({projectId: this.projectId}));
      }
    );

    this.router.queryParams.subscribe(async (params) => {
      const {campId} = params;
      if (campId) {
        this.campaignId = campId;
        this.campaignStore.dispatch(CampaignGet(campId, {options: JSON.stringify({include_segments: true})}));
      }
    });
  }
  get f() {
    return this.campaignForm.controls;
  }

  urlValidator(control) {
    const url = control.value;
    if (url && url.trim() !== '' && !this.isValidURL(url)) {
      return { 'invalidUrl': true };
    }
    return null;
  }

  async onSubmit(exit) {
    this.submitted = true;
    const campaign = this.campaignForm.getRawValue();

    if (!this.campaignForm.invalid) {
      const {id} = campaign;
      campaign.client_id = this.clientId;
      campaign.link_type = this.linkType || null;

      campaign.landing_page_status = 'active';
      // campaign.custom_cta_url = this.directURL;

      if (campaign.link_type === 'direct') {
        campaign.landing_page_status = 'skipped';
      }

      if (this.selectedCtaLinkType === 'custom' && campaign.link_type !== 'direct') {
        campaign.link_type = this.selectedCtaLinkType;
      }
      campaign.email_body = '';
      id ? await this.updateCampaign(campaign, exit) : await this.createCampaign(campaign, exit);
    }
  }

  public async handleClickNext() {
    await this.onSubmit(false);
  }

  public onSaveButtonClick() {
    this.onSubmit(true);
  }

  updateValue(variable, textFor) {
    const value = this.campaignForm.get(textFor).value;
    const varValue = variable.key;
    this.campaignForm.controls[textFor].setValue(`${value} ${varValue}`);
  }

  async createCampaign(newCampaign, exit) {
    this.modifiedCampaign = {
      id: null,
      exit
    };
    delete newCampaign.id;
    this.addUpdatedClicked = true;
    // this.campaignStore.dispatch(CampaignCreate({campaign: newCampaign}));

    this.loaderService.show();
    return await this.campaignService.addCampaign(newCampaign).pipe(
      map((resp: any) => {
        if (resp.success) {
          this.loaderService.hide();
          return this.campaignStore.dispatch(CampaignCreateSuccess({campaign: resp.data}));
        }
        this.loaderService.hide(true);
        return this.campaignStore.dispatch(CampaignCreateError({error: this.loaderService.getErrorMessage(resp)}));
      }),
      catchError(error => {
        this.loaderService.hide(true);
        return of(this.campaignStore.dispatch(CampaignCreateError({error: this.loaderService.getErrorMessage(error)})));
      })
    ).toPromise();
  }

  async updateCampaign(modifiedCampaign, exit) {
    const {id} = modifiedCampaign;
    delete modifiedCampaign.id;
    this.modifiedCampaign = {
      id,
      exit
    };
    this.addUpdatedClicked = true;
    // this.campaignStore.dispatch(CampaignUpdate({campaign: modifiedCampaign, campaignId: id}));

    this.loaderService.show();
    return await this.campaignService.updateCampaign(modifiedCampaign, id).pipe(
      map((resp: any) => {
        if (resp.success) {
          this.loaderService.hide();
          return this.campaignStore.dispatch(CampaignUpdateSuccess({campaign: resp.data}));
        }
        this.loaderService.hide(true);
        return this.campaignStore.dispatch(CampaignUpdateError({error: this.loaderService.getErrorMessage(resp)}));
      }),
      catchError(error => {
        this.loaderService.hide(true);
        return of(this.campaignStore.dispatch(CampaignUpdateError({error: this.loaderService.getErrorMessage(error)})));
      })
    ).toPromise();
  }

  addCustomCTA(modal) {
    this.customUrl = '';
    this._modalService.open(modal, {
      centered: true,
      size: 'lg',
      keyboard: true
    });
  }

  isValidURL(str) {
    // tslint:disable-next-line:no-shadowed-variable
    return validator2.isURL(str);
    /*
    const pattern = new RegExp('^(https?:\\/\\/)?' +
        '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|' +
        '((\\d{1,3}\\.){3}\\d{1,3}))' +
        '(\\:\\d+)?(\\/[-a-z#\\d%_.~+]*)*' +
        '(\\?[;&a-z#\\d%_.~+=-]*)?' +
        '(\\#[-a-z#\\d_]*)?$','i');
    return !!pattern.test(str);
     */
  }

  // setCustomUrl(e) {
  //   this.selectedCtaLinkType = e.value;
  //   this.directURL = e.name;
  // }


  addCustomCTAOption(str) {
    this.creatableOptions = [
        {id: 'project', name: this.currentProject.registration_url},
        {id: 'custom', name: str}
    ];
    this.customUrl = '';
    // this.linkType = 'custom';
    this.directURL = str;
    this.selectedCtaLinkType = 'custom';
  }

  ngOnDestroy() {
    this.unsubscriber.next();
    this.unsubscriber.complete();
  }
}
