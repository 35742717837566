import {Component, OnDestroy, OnInit} from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';

import {select, Store} from '@ngrx/store';
import {getLoggedInUser, IAuthenticationState, LoginUser} from '@app/stores';
import {Subject} from 'rxjs';
import {takeUntil} from 'rxjs/operators';

@Component({
  selector: 'app-temporary-login',
  templateUrl: './temporary-login.component.html',
  styleUrls: ['./temporary-login.component.css']
})
export class TemporaryLoginComponent implements OnInit, OnDestroy {
  loginForm: UntypedFormGroup;
  loading = false;
  submitted = false;
  returnUrl: string;
  authMessage: object;
  unsubscriber = new Subject();

  constructor(
    private formBuilder: UntypedFormBuilder,
    private route: ActivatedRoute,
    private router: Router,
    private authenticationStore: Store<IAuthenticationState>,
  ) {
    // redirect to home if already logged in
    this.authenticationStore.pipe(select(getLoggedInUser))
      .pipe(takeUntil(this.unsubscriber))
      .subscribe(user => {
      if (user) {
        if (user.password_status === '1') {
          this.router.navigate(['/resetpassword']);
        } else {
          this.router.navigate([this.returnUrl || '/projects']);
        }
      }
    });
  }

  ngOnInit() {
    this.loginForm = this.formBuilder.group({
      email: ['', [Validators.required, Validators.email]],
      password: ['', [Validators.required, Validators.minLength(6)]]
    });

    // get return url from route parameters or default to '/'
    this.returnUrl = this.route.snapshot.queryParams['returnUrl'] || '/';
    this.authMessage = {
      Success: false,
      msg: ''
    };
  }

  // convenience getter for easy access to form fields
  get f() {
    return this.loginForm.controls;
  }

  /**
     * Returns Auth token object that will use for next navigations.
     *
     * @param  url  an absolute URL giving the reset Temporary login page of the Application.
     * @param  name the location of the request, relative to the url.
     * @return      success object with user data.
     */

  onSubmit() {
    this.submitted = true;

    // stop here if form is invalid
    if (this.loginForm.invalid) {
      return;
    }

    this.authenticationStore.dispatch(LoginUser({
      credentials: {
        email: this.f.email.value,
        password: this.f.password.value
      }
    }));
  }

  ngOnDestroy() {
    this.unsubscriber.next();
    this.unsubscriber.complete();
  }
}
