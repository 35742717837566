<div class="container-fluid pt-3 new-styles">
  <div class="rightBody">
    <div class="card card-box mb-5">
      <div class="card-header py-3">
        <div class="card-header--title font-size-lg">
          Manage Users
        </div>
        <div class="card-header--actions">
          <button class="btn btn-sm btn-primary" type="button" [routerLink]="['/users/add']" role="button">
            <span class="btn-wrapper--icon"><i class="fa fa-plus"></i></span>
            <span class="btn-wrapper--label">Add New User</span>
          </button>
        </div>
      </div>
      <div class="row m-0">
        <div class="d-flex flex-wrap px-4 py-3">
          <div class="client-dropdown">
            <app-auto-complete-select
              [hideLabel]="true"
              [isCreatable]="false"
              [onlyBottomBorder]="false"
              [placeholder]="'Select Client'"
              [selectOptions]="planners"
              [selectedValue]="selectedClient !== 'All' ? selectedClient : null"
              (optionSelected)="usersList($event)"
              (searchValue)="handleSearchValue($event)"
              (scrollToEnd)="handleScroll()"
            ></app-auto-complete-select>
          </div>
        </div>
      </div>
      <div class="sticky-dropdown-custom-table p-3">
        <table width="100%" class="sb-table table dataTable no-footer nowrap" [dtTrigger]="dtTrigger" datatable
               [dtOptions]="dtOptions"
               id="dt-table">
          <thead>
          <tr>
            <th>First Name</th>
            <th>Last Name</th>
            <th>Email</th>
            <th>Company</th>
            <th>Job Title</th>
            <th>Client</th>
            <th>Role</th>
            <th>Status</th>
            <th [hidden]="!isAdmin">Login As</th>
            <th></th>
          </tr>
          </thead>
          <tbody *ngIf="users.length > 0">
          <tr *ngFor="let user of users">
            <td>{{user.first_name}}</td>
            <td>{{user.last_name}}</td>
            <td>{{user.email}}</td>
            <td>{{user.company}}</td>
            <td>{{user.job_title}}</td>
            <td>{{user.client?.name}}</td>
            <td>
              <div *ngFor="let role of user.role_user">
                <span class="badge badge-default h-auto">{{role.role.name}}</span>
              </div>
            </td>
            <td>
                <span class="mr-2">
                  <a *ngIf="user.status == '0'" (click)="changeStatus(user.id, user.status)" title="Activate"
                     class="activate"><i class="fa fa-toggle-off" aria-hidden="true"></i></a>
                  <a *ngIf="user.status == '1'" (click)="changeStatus(user.id, user.status)" title="Deactivate"
                     class="deactivate"><i class="fa fa-toggle-on" aria-hidden="true"></i></a>
                </span>
              <ng-container>
                <button type="button" class="info-button" [ngbPopover]="popInfoTemplate" data-html="true"
                        triggers="mouseenter:mouseleave" placement="auto" [container]="'body'"
                        [popoverClass]="'info-popover'">
                  <i class="fa fa-question-circle"></i>
                </button>
                <ng-template #popInfoTemplate>
                  <p class="mb-0">{{getUserUpdateInfo(user, 'create')}}</p>
                  <p class="mb-0">{{getUserUpdateInfo(user, 'update')}}</p>
                </ng-template>
              </ng-container>
            </td>
            <td [hidden]="!isAdmin"><span class="badge badge-default h-auto cursor-pointer" (click)="loginToUser(user)">Login</span></td>
            <td>
              <button class="btn-icon float-right" mat-icon-button [matMenuTriggerFor]="menu"
                      aria-label="Example icon-button with a menu">
                <span class="fa fa-ellipsis-v"></span>
              </button>
              <mat-menu #menu="matMenu" class="campaign-popover">
                <a class="dropdown-item" [routerLink]="['/users/profile', user.id]">
                  <i class="fa fa-pencil mr-2"></i>Edit</a>
                <a class="dropdown-item" (click)="deleteUser(user.id)">
                  <i class="fa fa-trash mr-2"></i>Delete</a>
                <a class="dropdown-item" *ngIf="user.email" (click)="sendLoginInfoMail(user.id)" target="_blank">
                  <i class="fa fa-paper-plane mr-2"></i>
                  Send Email</a>
              </mat-menu>

            </td>
          </tr>

          </tbody>
          <tbody *ngIf="users?.length <= 0">
          <tr>
            <td colspan="8" class="no-data-available">No data available in table</td>
          </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</div>
