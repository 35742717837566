import {Injectable} from '@angular/core';
import {environment} from '@environments/environment';
import {HttpClient} from '@angular/common/http';

@Injectable({providedIn: 'root'})
export class MailVerifyService {
  private baseApiUrl = environment.apiUrl;
  private mailVerifySenderUrl = this.baseApiUrl + '/api/v1/mailer/verify/sender';
  private mailServiceProviderUrl = this.baseApiUrl + '/api/v1/mailer-service-provider';

  constructor(private http: HttpClient) {
  }

  verifySenderStatus(params) {
    return this.http.post(`${this.mailVerifySenderUrl}/status`, params);
  }

  verifySender(params) {
    return this.http.post(`${this.mailVerifySenderUrl}`, params);
  }

  getMailServiceProvider(params) {
    return this.http.get(`${this.mailServiceProviderUrl}`, params);
  }
}
