import {Injectable} from '@angular/core';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {environment} from '@environments/environment';

@Injectable({
  providedIn: 'root'
})

export class CampaignService {
  private baseApiUrl = environment.apiUrl;
  private campaignUrl = this.baseApiUrl + '/api/v1/campaign';

  constructor(
    private http: HttpClient,
  ) {
  }

  getCampaign(id: number, params: any = {}) {
    return this.http.get(`${this.campaignUrl}/${id}`, {params});
  }

  updateCampaign(campaign, id) {
    campaign = {...campaign};
    if (campaign.project_id) {
      delete campaign.project_id;
    }
    return this.http.put(`${this.campaignUrl}/${id}`, campaign);
  }

  addCampaign(campaign) {
    return this.http.post(`${this.campaignUrl}`, campaign);
  }

  getCampaigns(filters = {}) {
    return this.http.get(`${this.campaignUrl}`, {params: filters, headers: new HttpHeaders().set('Use-Temp-Token', 'true')});
  }

  getLaunchCode(campId) {
    return this.http.post(`${this.campaignUrl}/${campId}/launch/code`, {});
  }

  launch(campId, code, reLaunch = false) {
    return this.http.post(`${this.campaignUrl}/${campId}/launch`, { launch_code: code, ...(reLaunch ? {reLaunch} : {})});
  }

  launchValidation(params) {
    return this.http.get(`${this.campaignUrl}/${params.campaign_id}/launch/validation`, {params});
  }

  launchScheduled(campId, schedule: string, timezone: string = '0', code, reLaunch = false) {
    return this.http.post(`${this.campaignUrl}/${campId}/launch/scheduled`, { schedule: schedule, timezone: timezone, launch_code: code, ...(reLaunch ? {reLaunch} : {}) });
  }

  deleteCampaign(campId: number) {
    return this.http.delete(`${this.campaignUrl}/${campId}`);
  }

  copyCampaign(campId: number, body: any) {
    return this.http.post(`${this.campaignUrl}/${campId}/copy`, body);
  }

  cancelCampaignLaunch(campId: number) {
    return this.http.post(`${this.campaignUrl}/${campId}/launch/cancel`, {});
  }
}
