import {Action, createReducer, on} from '@ngrx/store';
import {
  InfluencersList,
  InfluencersListSuccess,
  InfluencersListError,
  InfluencersListByProject,
  InfluencersListByProjectSuccess,
  InfluencersListByProjectError,
  InfluencersProjectSegment,
  InfluencersProjectSegmentSuccess,
  InfluencersProjectSegmentError,
  InfluencersSegmentUpdate,
  InfluencersSegmentUpdateSuccess,
  InfluencersSegmentUpdateError,
  InfluencersValidateImport,
  InfluencersValidateImportSuccess,
  InfluencersValidateImportError,
  InfluencersCsvToValues,
  InfluencersCsvToValuesSuccess,
  InfluencersCsvToValuesError,
  InfluencersImportHeaders,
  InfluencersImportHeadersSuccess,
  InfluencersImportHeadersError,
  InfluencersImport,
  InfluencersImportSuccess,
  InfluencersImportError,
  InfluencersAdd,
  InfluencersAddSuccess,
  InfluencersAddError,
  InfluencersUpdate,
  InfluencersUpdateSuccess,
  InfluencersUpdateError,
  InfluencersGet,
  InfluencersGetSuccess,
  InfluencersGetError,
  InfluencersTypeList,
  InfluencersTypeListSuccess,
  InfluencersTypeListError,
  InfluencersDelete,
  InfluencersDeleteSuccess,
  InfluencersDeleteError,
  ResetInfluencerState,
  InfluencersImportStatus,
  InfluencersImportStatusSuccess,
  InfluencersImportStatusError,
  InfluencersExport,
  InfluencersExportSuccess,
  InfluencersExportError,
  InfluencersReferralsList,
  InfluencersReferralsListSuccess,
  InfluencersReferralsListError,
  InfluencersListByProjectPaginate,
  InfluencersListByProjectPaginateSuccess,
  InfluencersListByProjectPaginateError,
  InfluencersReferralsListPaginated,
  InfluencersReferralsListPaginatedSuccess,
  InfluencersReferralsListPaginatedError,
  InfluencersTaskLogs,
  InfluencersTaskLogsSuccess,
  InfluencersTaskLogsError,
  InfluencersBulk,
  InfluencersBulkSuccess,
  InfluencersBulkError,
  InfluencersBulkStatusError,
  InfluencersBulkStatusSuccess,
  InfluencersBulkStatus,
  InfluencersImportFields, InfluencersImportFieldsSuccess, InfluencersImportFieldsError
} from '@app/stores/influencers/influencers.actions';
import {initialInfluencersState} from '@app/stores/influencers/influencers.state';
import {InfluencersSync, InfluencersSyncError, InfluencersSyncSuccess} from '@app/stores';

const generateReducer = createReducer(initialInfluencersState,
  on(InfluencersList, (state) => ({
    ...state,
    influencer: null,
    influencers: [],
    success: '',
    error: ''
  })),
  on(InfluencersListSuccess, (state, {influencers}) => ({
    ...state,
    influencer: null,
    influencers,
    success: '',
    error: ''
  })),
  on(InfluencersListError, (state, {error}) => ({
    ...state,
    influencer: null,
    influencers: [],
    success: '',
    error
  })),

  on(InfluencersListByProject, (state) => ({
    ...state,
    influencer: null,
    influencers: [],
    success: '',
    error: ''
  })),
  on(InfluencersListByProjectSuccess, (state, {influencers}) => ({
    ...state,
    influencer: null,
    influencers,
    success: '',
    error: ''
  })),
  on(InfluencersListByProjectError, (state, {error}) => ({
    ...state,
    influencer: null,
    influencers: [],
    success: '',
    error
  })),

  on(InfluencersListByProjectPaginate, (state) => ({
    ...state,
    paginatedInfluencers: null,
    success: '',
    error: ''
  })),
  on(InfluencersListByProjectPaginateSuccess, (state, {paginatedInfluencers}) => ({
    ...state,
    paginatedInfluencers,
    success: '',
    error: ''
  })),
  on(InfluencersListByProjectPaginateError, (state, {error}) => ({
    ...state,
    paginatedInfluencers: null,
    success: '',
    error
  })),

  on(InfluencersProjectSegment, (state) => ({
    ...state,
    influencersProjectSegment: [],
    success: '',
    error: ''
  })),
  on(InfluencersProjectSegmentSuccess, (state, {segments}) => ({
    ...state,
    influencersProjectSegment: segments,
    success: '',
    error: ''
  })),
  on(InfluencersProjectSegmentError, (state, {error}) => ({
    ...state,
    influencersProjectSegment: [],
    success: '',
    error
  })),

  on(InfluencersSegmentUpdate, (state) => ({
    ...state,
    success: '',
    error: ''
  })),
  on(InfluencersSegmentUpdateSuccess, (state) => ({
    ...state,
    success: 'Segment Updated Successfully',
    error: ''
  })),
  on(InfluencersSegmentUpdateError, (state, {error}) => ({
    ...state,
    success: '',
    error
  })),

  on(InfluencersValidateImport, (state) => ({
    ...state,
    influencersImport: null,
    success: '',
    error: ''
  })),
  on(InfluencersValidateImportSuccess, (state, {influencersImport}) => ({
    ...state,
    influencersImport,
    success: '',
    error: ''
  })),
  on(InfluencersValidateImportError, (state, {error}) => ({
    ...state,
    influencersImport: null,
    success: '',
    error
  })),

  on(InfluencersCsvToValues, (state) => ({
    ...state,
    influencersCsvToValues: null,
    success: '',
    error: ''
  })),
  on(InfluencersCsvToValuesSuccess, (state, {influencersCsvToValues}) => ({
    ...state,
    influencersCsvToValues,
    success: '',
    error: ''
  })),
  on(InfluencersCsvToValuesError, (state, {error}) => ({
    ...state,
    influencersCsvToValues: null,
    success: '',
    error
  })),

  on(InfluencersImportHeaders, (state) => ({
    ...state,
    influencersImportHeaders: null,
    success: '',
    error: ''
  })),
  on(InfluencersImportHeadersSuccess, (state, {influencersImportHeaders}) => ({
    ...state,
    influencersImportHeaders,
    success: '',
    error: ''
  })),
  on(InfluencersImportHeadersError, (state, {error}) => ({
    ...state,
    influencersImportHeaders: null,
    success: '',
    error
  })),

  on(InfluencersImport, (state) => ({
    ...state,
    influencersImport: null,
    success: '',
    error: ''
  })),
  on(InfluencersImportSuccess, (state, {influencersImport}) => ({
    ...state,
    influencersImport,
    success: '',
    error: ''
  })),
  on(InfluencersImportError, (state, {error}) => ({
    ...state,
    influencersImport: null,
    success: '',
    error
  })),

  on(InfluencersAdd, (state) => ({
    ...state,
    success: '',
    error: ''
  })),
  on(InfluencersAddSuccess, (state) => ({
    ...state,
    success: 'Influencer added successfully',
    error: ''
  })),
  on(InfluencersAddError, (state, {error}) => ({
    ...state,
    success: '',
    error
  })),

  on(InfluencersUpdate, (state) => ({
    ...state,
    influencer: null,
    success: '',
    error: ''
  })),
  on(InfluencersUpdateSuccess, (state, {influencer}) => ({
    ...state,
    influencer,
    success: 'Influencer updated successfully',
    error: ''
  })),
  on(InfluencersUpdateError, (state, {error}) => ({
    ...state,
    influencer: null,
    success: '',
    error
  })),

  on(InfluencersGet, (state) => ({
    ...state,
    influencer: null,
    success: '',
    error: ''
  })),
  on(InfluencersGetSuccess, (state, {influencer}) => ({
    ...state,
    influencer,
    success: '',
    error: ''
  })),
  on(InfluencersGetError, (state, {error}) => ({
    ...state,
    influencer: null,
    success: '',
    error
  })),

  on(InfluencersTypeList, (state) => ({
    ...state,
    influencerTypes: [],
    success: '',
    error: ''
  })),
  on(InfluencersTypeListSuccess, (state, {influencerTypes}) => ({
    ...state,
    influencerTypes,
    success: '',
    error: ''
  })),
  on(InfluencersTypeListError, (state, {error}) => ({
    ...state,
    influencerTypes: [],
    success: '',
    error
  })),

  on(InfluencersDelete, (state) => ({
    ...state,
    success: '',
    error: ''
  })),
  on(InfluencersDeleteSuccess, (state) => ({
    ...state,
    success: 'Influencer deleted successfully',
    error: ''
  })),
  on(InfluencersDeleteError, (state, {error}) => ({
    ...state,
    success: '',
    error
  })),

  on(InfluencersImportStatus, (state) => ({
    ...state,
    influencersImport: null,
    success: '',
    error: ''
  })),
  on(InfluencersImportStatusSuccess, (state, {influencersImport}) => ({
    ...state,
    influencersImport,
    success: '',
    error: ''
  })),
  on(InfluencersImportStatusError, (state, {error}) => ({
    ...state,
    influencersImport: null,
    success: '',
    error
  })),
  on(InfluencersBulkStatus, (state) => ({
    ...state,
    influencerBulk: null,
    success: '',
    error: ''
  })),
  on(InfluencersBulkStatusSuccess, (state, {influencerBulk}) => ({
    ...state,
    influencerBulk,
    success: '',
    error: ''
  })),
  on(InfluencersBulkStatusError, (state, {error}) => ({
    ...state,
    influencerBulk: null,
    success: '',
    error
  })),
  on(InfluencersExport, (state) => ({
    ...state,
    success: '',
    error: ''
  })),
  on(InfluencersExportSuccess, (state) => ({
    ...state,
    success: '',
    error: ''
  })),
  on(InfluencersExportError, (state, {error}) => ({
    ...state,
    success: '',
    error
  })),

  on(InfluencersReferralsList, (state) => ({
    ...state,
    referralsList: [],
    success: '',
    error: ''
  })),
  on(InfluencersReferralsListSuccess, (state, {referralsList}) => ({
    ...state,
    referralsList,
    success: '',
    error: ''
  })),
  on(InfluencersReferralsListError, (state, {error}) => ({
    ...state,
    referralsList: [],
    success: '',
    error
  })),

  on(InfluencersReferralsListPaginated, (state) => ({
    ...state,
    paginatedReferralsList: null,
    success: '',
    error: ''
  })),
  on(InfluencersReferralsListPaginatedSuccess, (state, {paginatedReferralsList}) => ({
    ...state,
    paginatedReferralsList,
    success: '',
    error: ''
  })),
  on(InfluencersReferralsListPaginatedError, (state, {error}) => ({
    ...state,
    paginatedReferralsList: null,
    success: '',
    error
  })),

  on(InfluencersTaskLogs, (state) => ({
    ...state,
    influencersTaskLogs: null,
    success: '',
    error: ''
  })),
  on(InfluencersTaskLogsSuccess, (state, {influencersTaskLogs}) => ({
    ...state,
    influencersTaskLogs,
    success: '',
    error: ''
  })),
  on(InfluencersTaskLogsError, (state, {error}) => ({
    ...state,
    influencersTaskLogs: null,
    success: '',
    error
  })),
  on(InfluencersBulk, (state) => ({
    ...state,
    influencerBulk: null,
    success: '',
    error: ''
  })),
  on(InfluencersBulkSuccess, (state, {influencerBulk}) => ({
    ...state,
    influencerBulk,
    success: '',
    error: ''
  })),
  on(InfluencersBulkError, (state, {error}) => ({
    ...state,
    influencerBulk: null,
    success: '',
    error
  })),

  on(InfluencersImportFields, (state) => ({
    ...state,
    influencersImportFields: null,
    success: '',
    error: ''
  })),
  on(InfluencersImportFieldsSuccess, (state, {influencersImportFields}) => ({
    ...state,
    influencersImportFields,
    success: '',
    error: ''
  })),
  on(InfluencersImportFieldsError, (state, {error}) => ({
    ...state,
    influencersImportFields: null,
    success: '',
    error
  })),

  on(ResetInfluencerState, (state, {params}) => ({
    ...state,
    ...params
  })),
);

export function influencersReducer(state = initialInfluencersState, action: Action) {
  return generateReducer(state, action);
}
