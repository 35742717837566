import {Component, Input, OnInit, AfterViewInit, OnDestroy, OnChanges, SimpleChanges, Output, EventEmitter} from '@angular/core';
import {environment} from '@environments/environment';
import {select, Store} from '@ngrx/store';
import {getToken, IAuthenticationState} from '@app/stores';
import {takeUntil} from 'rxjs/operators';
import {Subject} from 'rxjs';

declare var FroalaEditor: any;

@Component({
  selector: 'app-editor',
  templateUrl: './editor.component.html',
  styleUrls: ['./editor.component.scss', '../../../../assets/css/editor/custom.scss']
})

export class EditorComponent implements OnInit, AfterViewInit, OnDestroy, OnChanges {
  @Input() template;
  @Input() customVariables: any = null;
  @Input() influencerTypeId;
  @Input() allowReordering;
  @Input() onChange;
  @Output() isEditorLoaded = new EventEmitter();
  @Input() templateButtonText;
  @Input() templateButtonTextColor;
  @Input() templateButtonLink;
  @Input() templateButtonSpacing;
  @Input() templateParagraphSpacing;
  @Input() templateListSpacing;
  @Input() templateButtonColor;
  @Input() templateLinkColor;
  @Input() templateLinkHoverColor;
  @Input() templateBackgroundColor;
  unsubscriber = new Subject();

  public HTMLTemplate;
  public editables = {};
  public imageEditables = {};
  token = null;

  editorLoaded = false;
  editors: any = [];
  imageEditors: any = [];
  timer: any;
  linkColor = '';

  constructor(
    private authenticationStore: Store<IAuthenticationState>
  ) {
    this.authenticationStore.pipe(select(getToken))
      .pipe(takeUntil(this.unsubscriber))
      .subscribe(newToken => {
        if (newToken) {
          this.token = newToken;
        }
      });
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.templateButtonText && changes.templateButtonText.currentValue) {
      this.replaceButtonText(changes.templateButtonText.currentValue);
    }
    if (changes.templateButtonTextColor && changes.templateButtonTextColor.currentValue) {
      this.replaceButtonTextColor(changes.templateButtonTextColor.currentValue);
    }
    if (changes.templateButtonLink) {
      this.replaceButtonLink(changes.templateButtonLink.currentValue || '');
    }
    if (changes.templateButtonColor) {
      this.replaceButtonColor(changes.templateButtonColor.currentValue || '');
    }
    if (changes.templateLinkColor) {
      this.replaceLinkColor(changes.templateLinkColor.currentValue || '');
    }
    if (changes.replaceLinkHoverColor) {
      this.replaceLinkHoverColor(changes.replaceLinkHoverColor.currentValue || '');
    }
    if (changes.templateBackgroundColor) {
      this.replaceBackGroundColor(changes.templateBackgroundColor.currentValue || '');
    }
    if (changes.templateButtonSpacing) {
      this.replaceButtonSpacing(changes.templateButtonSpacing.currentValue || '');
    }
    if (changes.templateParagraphSpacing) {
      this.replaceParagraphSpacing(changes.templateParagraphSpacing.currentValue || '');
    }
    // if (changes.template) {
    //   this.initEditor();
    // }
  }

  ngOnInit() {
    this.initEditables();
  }

  customFontFamily() {
    const that = this;
    FroalaEditor.RegisterCommand('fontFamily', {
      type: 'dropdown',
      displaySelection: function (editor) {
        return editor.opts.fontFamilySelection;
      },
      defaultSelection: function (editor) {
        return editor.opts.fontFamilyDefaultSelection;
      },
      displaySelectionWidth: 120,
      html: function () {
        let html = '<ul class="fr-dropdown-list" role="presentation">';
        const fontFamilies = this.opts.fontFamily;
        for (const family in fontFamilies) {
          if (fontFamilies.hasOwnProperty(family)) {
            html += '<li role="presentation"><a class="fr-command" tabIndex="-1" role="option" data-cmd="fontFamily" data-param1="'.concat(fontFamilies[family], '" \n        style="font-family: ').concat(family.replace('google-', ''), '" title="').concat(fontFamilies[family], '">').concat(fontFamilies[family], "</a></li>")
          }
        }
        return html += '</ul>'
      },
      title: 'Font Family',
      callback: function (cmd, font) {
        setTimeout(() => {
          this.fontFamily.apply(font);
        }, 500);
      },
      refresh: function (element) {
        this.fontFamily.refresh(element);
      },
      refreshOnShow: function (element, t) {
        if (t) t[0].style.overflow = 'hidden auto';
        setTimeout(() => {
          t = $(t);
          const option = t.find('li');
          let beforeFontFamily = '';
          let currentLi = null;
          if (FroalaEditor?.SELECTED_TARGET) {
            try {
              beforeFontFamily = window.getComputedStyle(FroalaEditor.SELECTED_TARGET, null).getPropertyValue('font-family');
              if (beforeFontFamily) {
                beforeFontFamily = beforeFontFamily.split('"').join('');
                let removeInx = -1;
                let counter = -1;
                option.each(function () {
                  counter++;
                  const a = $(this).find('a');
                  const font = $(a).attr('title');

                  $(a).get(0).style.removeProperty('color');
                  $(a).get(0).style.removeProperty('background');

                  $(a).attr('aria-selected', 'false');
                  $(a).removeClass('fr-active');

                  if (font === beforeFontFamily) {
                    currentLi = $(this);
                    removeInx = Number(counter);

                    $(a).attr('aria-selected', 'true');
                    $(a).addClass('fr-active');

                    $(a).get(0).style.color = '#4BB7E3';
                    $(a).get(0).style.background = 'unset';
                  }
                });
                if (removeInx === -1) {
                  const name = beforeFontFamily.charAt(0).toUpperCase() + beforeFontFamily.slice(1);
                  const li = document.createElement('li');
                  li.setAttribute('role', 'presentation');
                  const aCommand = document.createElement('a');
                  aCommand.setAttribute('class', 'fr-command fr-active');
                  aCommand.setAttribute('role', 'option');
                  aCommand.setAttribute('data-cmd', 'fontFamily');
                  aCommand.setAttribute('data-param1', beforeFontFamily);
                  aCommand.setAttribute('title', beforeFontFamily);
                  aCommand.setAttribute('aria-selected', 'true');
                  aCommand.appendChild(document.createTextNode(name));
                  aCommand.style.fontFamily = beforeFontFamily;
                  aCommand.style.color = '#4BB7E3';
                  aCommand.style.background = 'unset';
                  li.append(aCommand);
                  t.find('ul').prepend(li);
                }

                if (t.find('.fr-active')) {
                  t.animate({
                    scrollTop: (t.find('.fr-active').parent().index() * t.find('.fr-active').parent().height()) - t.find('.fr-active').parent().height()
                  });
                }
              }
            } catch (e) {
              console.log('Error in finding current font-family', e);
            }
          }
        }, 1000);
        this.fontFamily.refreshOnShow(element, t)

        /** code here */

      },
      plugin: 'fontFamily'
    });
  }

  customFontSize() {
    const that = this;
    // <li role="presentation"><a class="fr-command" tabindex="-1" role="option" data-cmd="fontSize" data-param1="8px" title="8" aria-selected="false">8</a></li>
    FroalaEditor.RegisterCommand('fontSize', {
      type: 'dropdown',
      title: 'Font Size',
      icon: 'fontSize',
      displaySelection: function (e) {
        return e.opts.fontSizeSelection;
      },
      displaySelectionWidth: 30,
      defaultSelection: function (e) {
        return e.opts.fontSizeDefaultSelection;
      },
      html: function () {
        let e = '<ul class="fr-dropdown-list" role="presentation">';
        let t = this.opts.fontSize;
        for (let n = 0; n < t.length; n++) {
          const r = t[n];
          e += '<li role="presentation"><a class="fr-command" tabIndex="-1" role="option" data-cmd="fontSize" data-param1="'.concat(r).concat(this.opts.fontSizeUnit, '" title="').concat(r, '">').concat(r, '</a></li>');
        }
        return e += '</ul>';
      },
      callback: function (e, t) {
        const element = FroalaEditor.SELECTED_TARGET;
        element.style.setProperty('font-size', t, 'important');
      },
      refresh: function (e) {
        this.fontSize.refresh(e);
      },
      refreshOnShow: function (e, t) {
        setTimeout(() => {
          t = $(t);
          const option = t.find('li');
          let beforeFontSize: any = 0;
          if (FroalaEditor?.SELECTED_TARGET) {
            try {
              beforeFontSize = window.getComputedStyle(FroalaEditor.SELECTED_TARGET, null).getPropertyValue('font-size');
              if (beforeFontSize) {
                beforeFontSize = parseInt(beforeFontSize);
                let counter = -1;
                let found = false;
                option.each(function () {
                  counter++;
                  const a = $(this).find('a');
                  $(a).attr('aria-selected', 'false');
                  $(a).removeClass('fr-active');

                  $(a).get(0).style.removeProperty('color');
                  $(a).get(0).style.removeProperty('background');

                  const font = parseInt($(a).attr('title'));
                  if (font === beforeFontSize) {
                    found = true;
                    $(a).attr('aria-selected', 'true');
                    $(a).addClass('fr-active');

                    $(a).get(0).style.color = '#4BB7E3';
                    $(a).get(0).style.background = 'unset';
                  }
                });
                if (!found) {
                  let arr = [...this.opts.fontSize];
                  let html = '';
                  arr.push(beforeFontSize.toString());
                  arr.sort();
                  for (let n = 0; n < arr.length; n++) {
                    const r = arr[n];
                    html += '<li role="presentation"><a class="fr-command' + (r == beforeFontSize ? ' fr-active' : '')
                        + '" ' + (r == beforeFontSize ? 'aria-selected="true" ' : '')
                        + '" ' + (r == beforeFontSize ? 'style="color: #4BB7E3; background: unset" ' : '')
                        + ' tabIndex="-1" role="option" data-cmd="fontSize" data-param1="'.concat(r).concat(this.opts.fontSizeUnit, '" title="').concat(r, '">').concat(r, '</a></li>');
                  }
                  t.find('ul').html(html);
                }

                if (t.find('.fr-active')) {
                  t.animate({
                    scrollTop: (t.find('.fr-active').parent().index() * t.find('.fr-active').parent().height()) - t.find('.fr-active').parent().height()
                  });
                }
              }
            } catch (e) {
              console.log('Error in finding current font-family', e);
            }
          }
        }, 500);
        this.fontSize.refreshOnShow(e, t);
      },
      plugin: 'fontSize'
    });
  }

  customPaste() {
    const that = this;
    const customPlugin = 'customPaste';
    const customPopupName = 'customPaste.popup';

    // Define popup template.
    Object.assign(FroalaEditor.POPUP_TEMPLATES, {
      [customPopupName]: '[_BUTTONS_][_CUSTOM_LAYER_]'
    });

    // Define popup buttons.
    Object.assign(FroalaEditor.DEFAULTS, {
      pastePopupButtons: ['pastePopupClose', '-']
    });

    FroalaEditor.PLUGINS[customPlugin] = function (editor) {

      let pasteContent = null;
      // Show the popup
      function showPopup (e) {
        pasteContent = e;
        let $popup = editor.popups.get(customPopupName);
        if (!$popup) { $popup = initPopup(); }

        const bodyRect = document.body.getBoundingClientRect();
        const targetElement = e.target.getBoundingClientRect();
        editor.popups.setContainer(customPopupName, editor.$sc);
        const left = targetElement.left;
        const top = (targetElement.top - bodyRect.top) + targetElement.height;
        editor.popups.show(customPopupName, left, top, targetElement.height);
      }

      // Hide the custom popup.
      function hidePopup () {
        editor.popups.hide(customPopupName);
      }

      // Create custom popup.
      function initPopup() {
        // Load popup template.
        let template = FroalaEditor.POPUP_TEMPLATES[customPopupName];
        if (typeof template === 'function') { template = template.apply(editor); }

        // Popup buttons.
        let popup_buttons = '';

        // Create the list of buttons.
        if (editor.opts.pastePopupButtons.length > 1) {
          popup_buttons += '<div class="fr-buttons fr-tabs">';
          popup_buttons += editor.button.buildList(editor.opts.pastePopupButtons);
          popup_buttons += '</div>';
        }

        // Define Popup Template
        let cleanPasteButton = '<button id="pasteCleanButton-' + editor.id + '" type="button" tabindex="-1" role="button" data-cmd="pasteCleanButton" data-blur-event-set="true" class="fr-command fr-btn pasteCleanButton" style="display: flex;align-items: center;margin-right: 10px;border: 1px solid #00000038; padding: 10px;" data-title="Clean formatting">';
        cleanPasteButton += 'Clear formatting';
        cleanPasteButton += '</button>';

        let pasteKeepFormatButton = '<button id="pasteKeepButton-' + editor.id + '" type="button" tabindex="-1" role="button" data-cmd="pasteKeepButton" data-blur-event-set="true" class="fr-command fr-btn pasteKeepButton" style="display: flex;align-items: center;border: 1px solid #00000038; padding: 10px;" data-title="Keep formatting">';
        pasteKeepFormatButton += 'Keep formatting';
        pasteKeepFormatButton += '</button>';

        const html = '<div class="fr-image-variable-layer fr-layer fr-active fr-buttons m-0 w-auto"><div class="d-flex align-items-center">'
          .concat(cleanPasteButton).concat(pasteKeepFormatButton).concat('</div>');

        // Load popup template.
        template = {
          buttons: popup_buttons,
          custom_layer: html,
        };

        FroalaEditor.RegisterCommand('pasteCleanButton', {
          title: 'Paste Clean',
          undo: true,
          focus: false,
          callback: function(cmd) {
            const pasteData = FroalaEditor.PASTE_DATA;
            const content = pasteData['text/plain'];
            this.html.insert(content.replace(/<[^>]*>?/g, ''), true);
            hidePopup();
          }
        });

        FroalaEditor.RegisterCommand('pasteKeepButton', {
          title: 'Paste With Formatting',
          undo: true,
          focus: false,
          callback: function(cmd) {
            const pasteData = FroalaEditor.PASTE_DATA;
            let content = '';
            if (pasteData['text/html']) {
              content = pasteData['text/html'];
            } else {
              content = pasteData['text/plain'];
            }
            this.html.insert(content, false);
            hidePopup();
          }
        });

        return editor.popups.create(customPopupName, template);
      }

      return {
        _init: function _init() {
          editor.events.on('paste.before', function(e) {
            return showPopup(e);
          });
        },
        showPopup,
        hidePopup,
        back: function back() {
          editor.events.disableBlur();
          editor.selection.restore();
          editor.popups.hide(customPopupName);
        }
      };
    };

    // Define custom popup close button icon and command.
    FroalaEditor.RegisterCommand('pastePopupClose', {
      title: 'Close',
      icon: 'imageBack',
      undo: false,
      focus: false,
      callback: function () {
        this.customPaste.back();
      }
    });
  }

  customImageVariableOptions() {
    const that = this;
    const customPopupName = 'imageVariable.popup';
    const customPlugin = 'imageVariable';

    // Define popup template.
    Object.assign(FroalaEditor.POPUP_TEMPLATES, {
      [customPopupName]: '[_BUTTONS_][_CUSTOM_LAYER_]'
    });

    // Define popup buttons.
    Object.assign(FroalaEditor.DEFAULTS, {
      popupButtons: ['popupClose', '-']
    });

    // The custom popup is defined inside a plugin (new or existing).
    FroalaEditor.PLUGINS[customPlugin] = function (editor) {
      let imageSrcOptions = {
        'none': [{name: 'None', key: '', valueType: ['text', 'all']}],
      };
      if (that.customVariables) {
        imageSrcOptions = {
          'none': [{name: 'None', key: '', valueType: ['text', 'all']}],
          ...that.customVariables
        };
      }
      // Create custom popup.
      function initPopup () {
        // Load popup template.
        let template = FroalaEditor.POPUP_TEMPLATES[customPopupName];
        if (typeof template === 'function') { template = template.apply(editor); }

        // Popup buttons.
        let popup_buttons = '';

        // Create the list of buttons.
        if (editor.opts.popupButtons.length > 1) {
          popup_buttons += '<div class="fr-buttons fr-tabs">';
          popup_buttons += editor.button.buildList(editor.opts.popupButtons);
          popup_buttons += '</div>';
        }

        // Define Popup Template
        let dropdownButton = '<button id="imageSrcVariable-' + editor.id + '" type="button" tabindex="-1" role="button" data-cmd="imageSrcVariable" aria-controls="dropdown-menu-imageSrcVariable-' + editor.id + '" data-blur-event-set="true" aria-expanded="false" aria-haspopup="true" class="fr-command fr-btn fr-dropdown imageSrcVariable" style="display: flex;align-items: center;" data-title="Image Source">';
        dropdownButton += editor.icon.create('insertImage');
        dropdownButton += '<span class="d-none" style="margin-left: -5px"></span>';
        dropdownButton += '</button>';

        let dropdownHtml = '<div id="dropdown-menu-imageSrcVariable-' + editor.id + '" class="fr-dropdown-menu" role="listbox" aria-labelledby="imageSrcVariable-' + editor.id + '" aria-hidden="true">';
        dropdownHtml += '<div class="fr-dropdown-wrapper" role="presentation"><div class="fr-dropdown-content" role="presentation">';
        dropdownHtml += '<ul class="fr-dropdown-list" role="presentation">';

        Object.keys(imageSrcOptions).forEach(groups => {
          const variableList = imageSrcOptions[groups].filter(item => (item.valueType.includes('all') || item.valueType.includes('image'))
            && !item.valueType.includes('ignore'));
          if (variableList.length > 0) {
            if (groups !== 'none') {
              dropdownHtml += '<li>';
              dropdownHtml += '<a style="font-weight: bolder;cursor: default;text-transform: capitalize;pointer-events: none"' +
                ' title="' + groups + '">' + groups + '</a>';
              dropdownHtml += '</li>';
            }
            const style = groups !== 'none' ? 'padding: 0 30px; text-transform: capitalize;' : 'text-transform: capitalize;';
            variableList.forEach(item => {
              let showName = item.name.toString().toLowerCase().trim();
              if (groups !== 'none') {
                showName = showName.replace(groups.toString().toLowerCase().trim(), '');
              }
              dropdownHtml += '<li role="presentation">';
              dropdownHtml += '<a style="' + style + '" class="fr-command" tabindex="-1" role="option" data-cmd="imageSrcVariable" data-param1="' + item.key + '" title="' + item.name + '">' + showName + '</a>';
              dropdownHtml += '</li>';
            });
          }
        });

        dropdownHtml += '</ul></div></div></div>';

        let checkBoxHtml = '<span style="text-align: left"><div class="fr-checkbox-line fr-autoplay-margin imageSrcVariableCheck">';
        checkBoxHtml += '<span class="fr-checkbox"><input id="imageSrcVariableCheck-' + editor.id + '" data-cmd="imageSrcVariableCheck" data-param1="true" class="fr-command" type="checkbox" dir="auto" value/><span><svg version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="10" height="10" viewBox="0 0 32 32"><path d="M27 4l-15 15-7-7-5 5 12 12 20-20z" fill="#FFF"></path></svg></span></span>';
        checkBoxHtml += '<label style="line-height: 1;white-space: break-spaces;word-break: break-word;"' +
          ' id="imageSrcVariableCheckLabel-' + editor.id + '" data-cmd="imageSrcVariableCheck" data-param1="true"' +
          ' class="fr-command">Show the uploaded image when the selected dynamic image is not available</label></div></span>';

        const html = '<div class="fr-image-variable-layer fr-layer fr-active fr-buttons m-0 w-auto">' +
          '<div class="d-flex align-items-center">'.concat(dropdownButton).concat(dropdownHtml).concat(checkBoxHtml, '</div>').concat('</div>');
        // Load popup template.
        template = {
          buttons: popup_buttons,
          custom_layer: html,
        };

        // Define custom popup template dropdown command
        FroalaEditor.RegisterCommand('imageSrcVariable', {
          undo: false,
          focus: false,
          callback: function (cmd, val) {
            let img = null;
            if (this.el instanceof HTMLImageElement) {
              img = this.el;
            } else {
              img = this.el.querySelector('img');
            }
            const button = this.$sc.find(`#imageSrcVariable-${this.id}`);
            if (button && button[0]) {
              const span = button[0].querySelector('span');
              if (val.trim()) {
                img.setAttribute('data-src-variable', val);
                let newOptions = [];
                Object.keys(imageSrcOptions).forEach(group => {
                  newOptions = [...newOptions, ...imageSrcOptions[group]];
                });
                const option = newOptions.find(item => item.key === val);
                if (option) {
                  span.innerHTML = option.name;
                  span.classList.remove('d-none');
                }
              } else {
                img.removeAttribute('data-src-variable');
                span.innerHTML = '';
                span.classList.add('d-none');
              }
            }
          }
        });

        // Define custom popup template checkbox command
        FroalaEditor.RegisterCommand('imageSrcVariableCheck', {
          undo: false,
          focus: false,
          callback: function (cmd, val) {
            let img = null;
            if (this.el instanceof HTMLImageElement) {
              img = this.el;
            } else {
              img = this.el.querySelector('img');
            }
            if (img.getAttribute('data-src-visibility')) {
              img.removeAttribute('data-src-visibility');
            } else {
              img.setAttribute('data-src-visibility', val);
            }
          }
        });

        // Create popup.
        return editor.popups.create(customPopupName, template);
      }

      // Set default value according to attributes
      function checkElementForData(instance, popup) {
        let img = null;
        if (instance.el instanceof HTMLImageElement) {
          img = instance.el;
        } else {
          img = instance.el.querySelector('img');
        }
        let value = img.getAttribute('data-src-visibility');
        const checkboxVariable = popup.find(`#imageSrcVariableCheck-${instance.id}`);
        if (checkboxVariable && checkboxVariable[0]) {
          checkboxVariable[0].checked = !!value;
        }

        value = img.getAttribute('data-src-variable');
        const button = popup.find(`#imageSrcVariable-${instance.id}`);
        if (button && button[0]) {
          const span = button[0].querySelector('span');
          if (value) {
            let newOptions = [];
            Object.keys(imageSrcOptions).forEach(group => {
              newOptions = [...newOptions, ...imageSrcOptions[group]];
            });
            const option = newOptions.find(item => item.key === value);
            if (option) {
              span.innerHTML = option.name;
              span.classList.remove('d-none');
            }
          } else {
            span.innerHTML = '';
            span.classList.add('d-none');
          }
        }
      }

      // Show the popup
      function showPopup () {
        let $popup = editor.popups.get(customPopupName);
        if (!$popup) { $popup = initPopup(); }

        editor.popups.onShow(customPopupName, function() {
          checkElementForData(this, $popup);
        });

        editor.popups.setContainer(customPopupName, editor.$sc);
        const $btn = editor.$sc.find('.fr-active .fr-command[data-cmd="imageVariableButton"]');
        const left = $btn.offset().left;
        const top = $btn.offset().top - (editor.opts.toolbarBottom ? 10 : ($btn.outerHeight() - 10));
        editor.popups.show(customPopupName, left, top, $btn.outerHeight());
      }

      // Hide the custom popup.
      function hidePopup () {
        editor.popups.hide(customPopupName);
      }

      // Methods visible outside the plugin.
      return {
        showPopup: showPopup,
        hidePopup: hidePopup
      };
    };

    // Define custom popup close button icon and command.
    FroalaEditor.RegisterCommand('popupClose', {
      title: 'Close',
      icon: 'imageBack',
      undo: false,
      focus: false,
      callback: function () {
        this.image.back();
      }
    });

    // Define an icon and command for the button that opens the custom popup.
    FroalaEditor.DefineIcon('imageVariableButton', { NAME: 'cog', template: 'font_awesome'});
    FroalaEditor.RegisterCommand('imageVariableButton', {
      title: 'Use Variable',
      icon: 'imageVariableButton',
      undo: false,
      focus: false,
      popup: true,
      plugin: 'imageVariable',
      callback: function () {
        if (!this.popups.isVisible(customPopupName)) {
          this[customPlugin].showPopup();
        } else {
          if (this.$el.find('.fr-marker')) {
            this.events.disableBlur();
            this.selection.restore();
          }
          this[customPlugin].hidePopup();
        }
      }
    });
  }

  addCustomVariables() {
    const that = this;
    FroalaEditor.DefineIcon('variable', {NAME: 'users', template: 'font_awesome'});
    FroalaEditor.RegisterCommand('variable', {
      title: 'Variables',
      icon: 'variable',
      type: 'dropdown',
      focus: false,
      undo: false,
      refreshAfterCallback: true,
      // options: variables,
      html: function () {
        return that.getVariableHtml('variable');
      },
      callback: function (cmd, val) {
        this.html.insert(val);
      },
      // Callback on refresh.
      refresh: function ($btn) {
        // console.log('do refresh');
      },
      // Callback on dropdown show.
      refreshOnShow: function ($btn, $dropdown) {
        // console.log('do refresh when show');
      }
    });

    FroalaEditor.RegisterCommand('linkUrlVariables', {
      title: 'URL Variables',
      icon: 'imageLink',
      type: 'dropdown',
      focus: false,
      undo: false,
      refreshAfterCallback: false,
      html: function () {
        return that.getVariableHtml('linkUrlVariables', 'url');
      },
      callback: function (cmd, val) {
        const $popup = this.popups.get('link.insert');
        const urlInput = $popup.find(`#fr-link-insert-layer-url-${this.id}`);
        if (urlInput && urlInput[0]) {
          // let value = urlInput[0].value;
          // console.log(value)
          // urlInput[0].value = `${value} ${val}`;
          urlInput[0].value = `${val}`;
          urlInput[0].focus();
        }
      },
      plugin: 'link'
    });

    FroalaEditor.RegisterCommand('linkTextVariables', {
      title: 'Text Variables',
      icon: 'moreText',
      type: 'dropdown',
      focus: false,
      undo: false,
      refreshAfterCallback: false,
      html: function () {
        return that.getVariableHtml('linkTextVariables', 'text');
      },
      callback: function (cmd, val) {
        const $popup = this.popups.get('link.insert');
        const textInput = $popup.find(`#fr-link-insert-layer-text-${this.id}`);
        if (textInput && textInput[0]) {
          // let value = textInput[0].value;
          // console.log(value)
          // textInput[0].value = `${value} ${val}`;
          textInput[0].value = `${val}`;
          textInput[0].focus();
        }
      },
      plugin: 'link'
    });

    this.customImageVariableOptions();
    this.customPaste();
    this.customFontFamily();
    this.customFontSize();
  }

  public ngAfterViewInit() {
    this.initEditor();
  }

  initEditor() {
    const that = this;
    this.addCustomVariables();

    const editorOptions = {
      key: environment.froalaEditorKey,
      toolbarInline: true,
      toolbarVisibleWithoutSelection: false,
      htmlUntouched: true,
      attribution: false,
      charCounterCount: false,
      imageInsertButtons: ['imageBack', '|', 'imageUpload', 'imageByURL'],
      imageUploadURL: environment.apiUrl + '/api/v1/file',
      fileUploadURL: environment.apiUrl + '/api/v1/file',
      requestHeaders: {
        'Authorization': `Bearer ${this.token}`
      },
      events: {
        'initialized': function () {
          that.editorLoaded = true;
          that.isEditorLoaded.emit(true);
        },
        'contentChanged': function () {
          that.onEditorChange();
        },
        'image.uploaded': function (response) {
          const jsonResponse = JSON.parse(response);
          this.image.insert(jsonResponse.data.url, false, null, this.image.get(), response);
          return false;
        },
        'file.uploaded': function (response) {
          const jsonResponse = JSON.parse(response);
          this.file.insert(jsonResponse.data.url, jsonResponse.data.file, null);
          return false;
        },
        'paste.before': function (e) {
          const pasteData = {};
          const types = e.clipboardData.types;
          for (let i = 0; i < types.length; i++) {
            if (types[i] !== 'text/html') {
              pasteData[types[i]] = e.clipboardData.getData(types[i]);
            } else {
              const pasteContent = e.clipboardData.getData(types[i]);
              if (this.helpers.isIOS() || this.helpers.isMac()) {
                pasteData[types[i]] = pasteContent;
              } else {
                pasteData[types[i]] = pasteContent.match(/(StartFragment-->)(.*?<!--EndFragment)/g);
                if (pasteData[types[i]] && pasteData[types[i]][0]) {
                  pasteData[types[i]] = pasteData[types[i]][0].replace('StartFragment-->', '').replace('<!--EndFragment', '');
                }
              }
            }
          }
          Object.assign(FroalaEditor, {
            PASTE_DATA: pasteData
          });
          return false;
        },
        'mousedown': function(mousedownEvent) {
          Object.assign(FroalaEditor, {
            SELECTED_TARGET: mousedownEvent.target
          });
        }
      },
      linkInsertButtons: ['linkBack', '|', 'linkList', 'linkUrlVariables', 'linkTextVariables'],
      pluginsEnabled: ['align', 'charCounter', 'codeBeautifier', 'codeView', 'colors', 'cryptoJSPlugin', 'draggable', 'emoticons', 'entities',
        'file', 'filesManager', 'fontAwesome', 'fontFamily', 'fontSize', 'forms', 'fullscreen', 'help', 'image', 'imageManager', 'imageVariable',
        'inlineClass', 'inlineStyle', 'lineBreaker', 'lineHeight', 'link', 'lists', 'markdown', 'paragraphFormat', 'paragraphStyle',
        'print', 'quickInsert', 'quote', 'save', 'specialCharacters', 'table', 'url', 'video', 'wordPaste', 'customPaste'],
      imageEditButtons: ['imageReplace', 'imageVariableButton', 'imageAlign', 'imageCaption', 'imageRemove', 'imageLink', 'linkOpen',
        'linkEdit', 'linkRemove', '-', 'imageDisplay', 'imageStyle', 'imageAlt', 'imageSize'],
      toolbarButtons: ['variable', '|', 'bold', 'italic', 'textColor', 'backgroundColor', 'paragraphFormat', 'align', 'emoticons', 'insertLink',
        'insertImage', 'undo', 'redo', '-', 'underline', 'subscript', 'superscript', 'fontSize', 'fontFamily', 'insertTable', 'formatOL', 'formatUL',
        'lineHeight', 'insertVideo', 'fontAwesome', 'inlineClass', 'inlineStyle', 'insertFile']
    };
    let editorsIds = Object.keys(this.editables).map(i => '#' + i);
    editorsIds.forEach(editor => {
      // eslint-disable-next-line no-unused-expressions,@typescript-eslint/no-unused-expressions
      const edit = new FroalaEditor(editor, editorOptions);
      this.editors.push(edit);
    });
    editorsIds = Object.keys(this.imageEditables).map(i => '#' + i);
    editorsIds.forEach(editor => {
      // eslint-disable-next-line no-unused-expressions, @typescript-eslint/no-unused-expressions
      const edit = new FroalaEditor(editor, {
        key: environment.froalaEditorKey,
        toolbarInline: true,
        toolbarVisibleWithoutSelection: false,
        attribution: false,
        htmlUntouched: true,
        charCounterCount: false,
        imageInsertButtons: ['imageBack', '|', 'imageUpload', 'imageByURL'],
        pluginsEnabled: ['align', 'charCounter', 'codeBeautifier', 'codeView', 'colors', 'cryptoJSPlugin', 'draggable', 'emoticons', 'entities',
          'file', 'filesManager', 'fontAwesome', 'fontFamily', 'fontSize', 'forms', 'fullscreen', 'help', 'image', 'imageManager', 'imageVariable',
          'inlineClass', 'inlineStyle', 'lineBreaker', 'lineHeight', 'link', 'lists', 'markdown', 'paragraphFormat', 'paragraphStyle', 'print',
          'quickInsert', 'quote', 'save', 'specialCharacters', 'table', 'url', 'video', 'wordPaste'],
        imageEditButtons: ['imageReplace', 'imageVariableButton', 'imageAlign', 'imageCaption', 'imageRemove', 'imageLink', 'linkOpen',
          'linkEdit', 'linkRemove', '-', 'imageDisplay', 'imageStyle', 'imageAlt', 'imageSize'],
        imageUploadURL: environment.apiUrl + '/api/v1/file',
        requestHeaders: {
          'Authorization': `Bearer ${this.token}`
        },
        events: {
          'initialized': function () {
            that.editorLoaded = true;
            that.isEditorLoaded.emit(true);
          },
          'image.uploaded': function (response) {
            const jsonResponse = JSON.parse(response);
            this.image.insert(jsonResponse.data.url, false, null, this.image.get(), response);
            return false;
          }
        }
      });
      this.imageEditors.push(edit);
    });
  }

  initEditables() {
    this.HTMLTemplate = document.createElement('div');
    this.HTMLTemplate.innerHTML = this.template['body'];

    let elements = this.HTMLTemplate.getElementsByClassName('editable');
    for (let i = 0; i < elements.length; i++) {
      const id = `editor${i + 1}`;
      elements[i].setAttribute('id', id);
      this.editables[id] = elements[i];
    }

    elements = this.HTMLTemplate.getElementsByClassName('mcnImage');
    for (let i = 0; i < elements.length; i++) {
      const id = `image-editor${i + 1}`;
      elements[i].setAttribute('id', id);
      this.imageEditables[id] = elements[i];
    }
  }

  onEditorChange() {
    if (this.template && this.editorLoaded) {
      let parser = new DOMParser();
      const templateDocument = parser.parseFromString(this.template.body, 'text/html');
      const templateEdit = document.getElementById('editor1');
      const elm: any = templateEdit.querySelectorAll('a:not(.mcnButton)');
      if (elm) {
        for (let i = 0; i < elm.length; i++) {
          elm[i].style.color = this.linkColor;
        }
      }
      this.template.body = templateEdit.innerHTML;
      clearTimeout(this.timer);
      this.timer = setTimeout(() => {
        const template = this.buildUpdatedTemplate();
        if (template) {
          this.onChange(template);
        }
      }, 1000);
      if (this.templateListSpacing) {
        this.replaceListSpacing(this.templateListSpacing);
      }
    }
  }

  buildUpdatedTemplate() {
    let html: any = document.getElementById('email-editor');
    if (html) {
      html = html.cloneNode(true);
      const editables = Object.keys(this.editables);
      for (let i = 0; i < editables.length; i++) {
        const getEditableContent = html.querySelector(`#${editables[i]}`);
        getEditableContent.innerHTML = this.editors[i].html.get(true);

        const removePoweredBy = html.querySelector(`p[data-f-id^="pbf"]`);
        if (removePoweredBy) {
          removePoweredBy.remove();
        }
      }
      return {body: html.innerHTML};
    }
    return null;
  }

  replaceButtonText(text) {
    if (this.template && this.editorLoaded) {
      const editor = document.getElementById('email-editor');
      const buttonElement: any = editor.querySelector('.main-button');
      if (buttonElement) {
        buttonElement.innerHTML = text;
        buttonElement.title = text;
        this.onEditorChange();
      }
    } else {
      setTimeout(() => {
        this.replaceButtonText(text);
      }, 1000);
    }
  }

  replaceButtonTextColor(color) {
    if (this.template && this.editorLoaded) {
      const editor = document.getElementById('email-editor');
      const buttonElement: any = editor.querySelector('.main-button');
      if (buttonElement) {
        buttonElement.style.color = color;
        this.onEditorChange();
      }
    } else {
      setTimeout(() => {
        this.replaceButtonTextColor(color);
      }, 1000);
    }
  }

  replaceButtonLink(link) {
    if (this.template && this.editorLoaded) {
      const editor = document.getElementById('email-editor');
      const buttonElement: any = editor.querySelector('.main-button');
      if (buttonElement) {
        buttonElement.href = link;
        this.onEditorChange();
      }
    } else {
      setTimeout(() => {
        this.replaceButtonLink(link);
      }, 1000);
    }
  }

  replaceButtonColor(color) {
    if (this.template && this.editorLoaded) {
      const editor = document.getElementById('email-editor');
      const buttonElement: any = editor.querySelector('.main-button');
      if (buttonElement) {
        buttonElement.parentNode.parentNode.parentNode.parentNode.style.backgroundColor = color;
        this.onEditorChange();
      }
    } else {
      setTimeout(() => {
        this.replaceButtonColor(color);
      }, 1000);
    }
  }

  addCSSRule(sheet, selector, rules, index) {
    if("insertRule" in sheet) {
      sheet.insertRule(selector + "{" + rules + "}", index);
    }
    else if("addRule" in sheet) {
      sheet.addRule(selector, rules, index);
    }
  }

  removeCSSRule(sheet, selector, rules, index) {
    if("deleteRule" in sheet) {
      sheet.deleteRule(index);
    }
    else if("removeRule" in sheet) {
      sheet.removeRule(index);
    }
  }

  CCSStylesheetRuleStyle(document, stylesheet, selectorText, style, value, important) {
    /* returns the value of the element style of the rule in the stylesheet
    *  If no value is given, reads the value
    *  If value is given, the value is changed and returned
    *  If '' (empty string) is given, erases the value.
    *  The browser will apply the default one
    *
    * string stylesheet: part of the .css name to be recognized, e.g. 'default'
    * string selectorText: css selector, e.g. '#myId', '.myClass', 'thead td'
    * string style: camelCase element style, e.g. 'fontSize'
    * string value optional : the new value
    */
    let sheet = null;
    let CSSstyle = undefined, rules;
    for (const m of Object.keys(document.styleSheets)) {
      if (document.styleSheets[m].ownerNode && document.styleSheets[m].ownerNode.getAttribute('id') === 'CCSStylesheetRuleStyle') {
        sheet = document.styleSheets[m];
        break;
      }
    }

    if (!sheet) {
      let style = document.createElement("style");
      style.setAttribute('id', 'CCSStylesheetRuleStyle');
      document.head.appendChild(style); // must append before you can access sheet property
      sheet = style.sheet;
      this.addCSSRule(sheet, "body", "", 0);
      // this.addCSSRule(sheet, "h1, h2, h3, h4, h5, h6", "", 0);
      this.addCSSRule(sheet, "h1", "", 0);
      this.addCSSRule(sheet, "h2", "", 0);
      this.addCSSRule(sheet, "h3", "", 0);
      this.addCSSRule(sheet, "h4", "", 0);
      this.addCSSRule(sheet, "h5", "", 0);
      this.addCSSRule(sheet, "h6", "", 0);
      this.addCSSRule(sheet, "a", "", 0);
      this.addCSSRule(sheet, "a.btn", "", 0);
      this.addCSSRule(sheet, "a.btn.btn-secondary", "", 0);
      this.addCSSRule(sheet, "a.btn:hover", "", 0);
      this.addCSSRule(sheet, "a.btn.btn-secondary:hover", "", 0);
      this.addCSSRule(sheet, "p", "", 0);
      this.addCSSRule(sheet, "button","", 0);
      this.addCSSRule(sheet, "button:hover", "", 0);
      this.addCSSRule(sheet, "section.fdb-block,header,footer","", 0);
    }
    const styleDashed = style.replace(/[A-Z]/g, m => "-" + m.toLowerCase());
    if (sheet) {
      rules = sheet[document.all ? 'rules' : 'cssRules'];
      for (const n of Object.keys(rules)) {
        if (rules[n].selectorText === selectorText) {
          CSSstyle = rules[n].style;
          break;
        }
      }
    }

    if (CSSstyle) {
      if (value === undefined || value === '' || value === null) {
        CSSstyle.removeProperty(styleDashed);
        CSSstyle.removeProperty(style);
      } else {
        if (important) {
          CSSstyle.setProperty(styleDashed, value, 'important');
          return CSSstyle.setProperty(style, value, 'important');
        } else {
          CSSstyle[styleDashed] = value;
          return CSSstyle[style] = value;
        }
      }
    }
  }

  replaceLinkColor(color) {
    this.linkColor = color;
    if (this.template && this.editorLoaded) {
      const editorBody = document.getElementById('templateBody')
      const elm: any = editorBody.querySelectorAll('a:not(.mcnButton)');
      if (elm) {
        for (let i=0; i<elm.length; i++){
          elm[i].style.color = this.linkColor;
          this.onEditorChange();
        }
      }
    } else {
      setTimeout(() => {
        this.replaceLinkColor(this.linkColor);
      }, 1000);
    }
  }

  replaceLinkHoverColor(color) {
    if (this.template && this.editorLoaded) {
      const editor = document.getElementById('email-editor');
      this.CCSStylesheetRuleStyle(editor, 'editor', 'a:hover', 'color', color, null);
    } else {
      setTimeout(() => {
        this.replaceLinkHoverColor(color);
      }, 1000);
    }
  }

  replaceBackGroundColor(color) {
    if (this.template && this.editorLoaded) {
      const editor = document.getElementById('email-editor');
      const bodyTable: any = editor.querySelector('#bodyTable');
      const editorHeader: any = editor.querySelector('#templatePreheader');
      const buttonEditable: any = editor.querySelector('.btn-editable');
      // const templateFooter: any = editor.querySelector('#templateFooter');
      if (bodyTable) {
        bodyTable.style.background = color;
        this.onEditorChange();
      }
      if (editorHeader) {
        editorHeader.style.background = color;
        this.onEditorChange();
      }
      if (buttonEditable) {
        buttonEditable.style.background = color;
        this.onEditorChange();
      }
      // if (templateFooter) {
      //   templateFooter.style.background = color;
      //   this.onEditorChange();
      // }
    } else {
      setTimeout(() => {
        this.replaceBackGroundColor(color);
      }, 1000);
    }
  }

  replaceButtonSpacing(space) {
    if (this.template && this.editorLoaded) {
      const editor = document.getElementById('email-editor');
      const buttonElement: any = editor.querySelector('.main-button');
      if (buttonElement && space !== '') {
        buttonElement.style.margin = `0 ${space || 0}px`;
        this.onEditorChange();
      }
    } else {
      setTimeout(() => {
        this.replaceButtonSpacing(space);
      }, 1000);
    }
  }

  replaceParagraphSpacing(space) {
    if (this.template && this.editorLoaded) {
      const editor = document.getElementById('email-editor');
      const paragraphElement: any = editor.querySelectorAll('p');
      if (paragraphElement && space !== '') {
        for (let i = 0; i < paragraphElement.length; i++) {
          paragraphElement[i].style.marginBottom = `${space || 0}px`;
        }
        this.onEditorChange();
      }
    } else {
      setTimeout(() => {
        this.replaceParagraphSpacing(space);
      }, 1000);
    }
  }

  replaceListSpacing(space) {
    if (this.template && this.editorLoaded) {
      const editor = document.getElementById('email-editor');
      const listElement: any = editor.querySelectorAll('li');
      if (listElement && space !== '') {
        for (let i = 0; i < listElement.length; i++) {
          listElement[i].style.marginBottom = `${space || 0}px`;
        }
      }
    } else {
      setTimeout(() => {
        this.replaceListSpacing(space);
      }, 1000);
    }
  }

  getVariableHtml(command, filter = '') {
    let html = '<ul class="fr-dropdown-list" role="presentation">';
    Object.keys(this.customVariables).forEach(groups => {
      let variableList = this.customVariables[groups].filter(item => !item.valueType.includes('ignore'));
      if (filter) {
        variableList = this.customVariables[groups].filter(item => (item.valueType.includes('all') || item.valueType.includes(filter))
          && !item.valueType.includes('ignore'));
      }
      if (variableList.length > 0) {
        html += '<li role="presentation">';
        html += '<a class="option-group" style="font-weight: bolder;cursor: default;text-transform: capitalize;pointer-events: none;" role="option-group" title="' + groups + '">' + groups + '</a>';
        html += '</li>';
        variableList.forEach(item => {
          let showName = item.name.toString().toLowerCase().trim();
          showName = showName.replace(groups.toString().toLowerCase().trim(), '');
          html += '<li role="presentation">';
          html += '<a style="padding: 0 30px; text-transform: capitalize;" class="fr-command" tabindex="-1" ' +
            'role="option" data-cmd="' + command + '" data-param1="' + item.key + '" title="' + item.name + '">' + showName + '</a>';
          html += '</li>';
        });
      }
    });
    return html += '</ul>';
  }

  ngOnDestroy() {
    this.unsubscriber.next();
    this.unsubscriber.complete();
    // this.editors.forEach(editor => {
    //   if (editor && editor.destroy) editor.destroy();
    // });
    // this.imageEditors.forEach(editor => {
    //   if (editor && editor.destroy) editor.destroy();
    // });
  }
}
