import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { AuthGuard } from '@app/_guards/auth.guard';
import { NonInfluencerGuard } from '@app/_guards/non-influencer.guard';
import { CampaignsComponent } from './campaigns/campaigns.component';
import { CreateCampaignComponent } from '@app/modules/campaign/create-campaign/create-campaign.component';

const routes: Routes = [
  {
    path: '',
    children: [
      {
        path: '',
        component: CampaignsComponent
      },
      {
        path: 'projects/:id/campaigns/create',
        component: CreateCampaignComponent
      },
      {
        path: 'projects/:id/campaigns/create/:audienceType',
        component: CreateCampaignComponent
      }
    ],
    canActivate: [AuthGuard, NonInfluencerGuard]
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class CampaignRoutingModule {
}
