import { RouterModule, Routes } from '@angular/router';

import { LoginComponent }          from './core/login';
import { ForgotPasswordComponent } from './core/forgot-password';
import { ResetPasswordComponent }  from './core/reset-password';
import { RegisterComponent }       from './core/register';
import { TemporaryLoginComponent } from './core/temporary-login';
import { PageNotFoundComponent }   from './core/page-not-found';
import { HelpComponent }           from './core/help';

import * as routes from './routes';

const appRoutes: Routes = [
  {
    path     : 'login',
    component: LoginComponent
  },
  {
    path     : 'help',
    component: HelpComponent
  },
  {
    path     : 'temporary-login',
    component: TemporaryLoginComponent
  },
  {
    path     : 'password',
    component: ForgotPasswordComponent
  },
  {
    path     : 'resetpassword',
    component: ResetPasswordComponent
  },
  {
    path     : 'register',
    component: RegisterComponent
  },
  {
    path     : 'register/:token',
    component: RegisterComponent
  },
  {
    path        : 'front',
    loadChildren: () => import('./routes/front-route/front-route.module').then(m => m.FrontRouteModule)
  },
  {
    path        : 'users',
    loadChildren: () => import('./modules/user/user.module').then(m => m.UserModule)
  },
  {
    path        : 'projects',
    loadChildren: () => import('./modules/project/project.module').then(m => m.ProjectModule)
  },
  {
    path        : 'project',
    loadChildren: () => import('./modules/project/project.module').then(m => m.ProjectModule)
  },
  {
    path        : 'projects/:projectId/influencers',
    loadChildren: () => import('./modules/influencer/influencer.module').then(m => m.InfluencerModule)
  },
  {
    path        : 'templates',
    loadChildren: () => import('./modules/template/template.module').then(m => m.TemplateModule)
  },
  {
    path        : 'sections',
    loadChildren: () => import('./modules/sections/sections.module').then(m => m.SectionsModule)
  },
  {
    path        : 'widget-monitoring',
    loadChildren: () => import('./modules/widget-monitoring/widget-monitoring.module').then(m => m.WidgetMonitoringModule)
  },
  {
    path        : 'projects/:id/campaigns',
    loadChildren: () => import('./modules/campaign/campaign.module').then(m => m.CampaignModule)
  },
  {
    // TODO: figure out why routes.campaignTemplates({projectId: ':projectId', campaignId: ':campaignId'})
    // doesn't work here
    path        : 'projects/:projectId/campaigns/:campaignId',
    loadChildren: () => import('./modules/template-generator/template-generator.module').then(m => m.TemplateGeneratorModule),
  },
  {
    path        : 'clients',
    loadChildren: () => import('./modules/client/client.module').then(m => m.ClientModule)
  },
  {
    path        : 'projects/:projectId/segments',
    loadChildren: () => import('./modules/segment/segment.module').then(m => m.SegmentModule)
  },
  {
    path        : 'settings',
    loadChildren: () => import('./modules/settings/settings.module').then(m => m.SettingsModule)
  },
  {
    path        : 'registration-platform',
    loadChildren: () => import('./modules/registration-platform/registration-platform.module').then(m => m.RegistrationPlatformModule)
  },
  {
    path        : 'packages',
    loadChildren: () => import('./modules/registration-platform/registration-platform.module').then(m => m.RegistrationPlatformModule)
  },
  {
    path     : '**',
    component: PageNotFoundComponent
  }
];

export const routing = RouterModule.forRoot (
  appRoutes,
  {
    useHash: false,
    scrollPositionRestoration: 'enabled'
  }
);
