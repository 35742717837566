import {createFeatureSelector, createSelector} from '@ngrx/store';
import {ITemplateState} from '@app/stores';

const selectTemplate = createFeatureSelector<ITemplateState>('template');

export const getTemplate = createSelector(selectTemplate, (state: ITemplateState) => state.template);

export const getTemplateSuccess = createSelector(selectTemplate, (state: ITemplateState) => state.success);

export const getTemplateError = createSelector(selectTemplate, (state: ITemplateState) => state.error);

export const getTemplates = createSelector(selectTemplate, (state: ITemplateState) => state.templates);

export const getPaginatedTemplates = createSelector(selectTemplate, (state: ITemplateState) => state.paginatedTemplates);

export const getTemplateCampaign = createSelector(selectTemplate, (state: ITemplateState) => state.templateCampaign);

export const getTemplatePreviewUrl = createSelector(selectTemplate, (state: ITemplateState) => state.previewUrl);

export const getWidgetIntegrationBody = createSelector(selectTemplate, (state: ITemplateState) => state.widgetIntegrationBody);

export const getWidgetIntegrationBodyError = createSelector(selectTemplate, (state: ITemplateState) => state.error);

export const getWidgetCustomScriptBody = createSelector(selectTemplate, (state: ITemplateState) => state.widgetCustomScriptBody);

export const getTemplateTypes = createSelector(selectTemplate, (state: ITemplateState) => state.templateTypes);

export const getWidgetPreviewUrl = createSelector(selectTemplate, (state: ITemplateState) => state.widgetPreviewUrl);

export const getTemplateRevisions = createSelector(selectTemplate, (state: ITemplateState) => state.templateRevisions);

export const getPaginatedWidgetAvatarTemplates = createSelector(selectTemplate, (state: ITemplateState) => state.paginatedWidgetAvatarTemplates);

export const getWidgetAvatarTemplates = createSelector(selectTemplate, (state: ITemplateState) => state.widgetAvatarTemplates);

export const getWidgetAvatars = createSelector(selectTemplate, (state: ITemplateState) => state.widgetAvatars);
