import {PaginatedProject, Project, ProjectIndustry} from '@app/modules/shared/models';

export interface IProjectState {
  project: Project;
  updateProject: Project;
  projects: Project[];
  paginatedProjects: PaginatedProject;
  industries: ProjectIndustry[];
  projectsReportExport: any;
  projectPlanners: any,
  slug: string;
  success: string;
  error: string;
  createOrCopySuccess: boolean;
}

export const initialProjectState: IProjectState = {
  project: null,
  updateProject: null,
  projects: [],
  paginatedProjects: null,
  projectsReportExport: null,
  industries: [],
  projectPlanners: [],
  slug: '',
  success: '',
  error: '',
  createOrCopySuccess: false
};
