import {AfterViewInit, Component, Input, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {NgbActiveModal} from '@ng-bootstrap/ng-bootstrap';
import {select, Store} from '@ngrx/store';
import {
  getInfluencerError,
  getInfluencersReferralsListPaginated,
  IInfluencersState,
  InfluencersReferralsListPaginated,
  ResetInfluencerState
} from '@app/stores';
import {ToastrService} from 'ngx-toastr';
import {takeUntil} from 'rxjs/operators';
import {Subject} from 'rxjs';
import {Influencer} from '@app/modules/shared/models';
import {DataTableDirective} from 'angular-datatables';
import * as DataTables from 'datatables.net';

@Component({
  selector: 'app-referrals-list',
  templateUrl: './referrals-list.component.html',
  styleUrls: ['./referrals-list.component.scss']
})
export class ReferralsListComponent implements OnInit, OnDestroy, AfterViewInit {
  @ViewChild(DataTableDirective)
  dtElement: DataTableDirective;
  dtOptions: any = {};
  dtTrigger: Subject<any> = new Subject();
  @Input() influencerId: number;
  @Input() influencerName: number;
  @Input() influencerTypes = [];
  @Input() segmentsGlobal = [];
  private unsubscriber = new Subject();
  influencersList: Influencer[] = [];
  showAll: boolean;
  private searchDelay;
  private datatableInstance: DataTables.Api<any>;
  ajaxCallback: any;

  constructor(
    public modal: NgbActiveModal,
    private influencerStore: Store<IInfluencersState>,
    private toastr: ToastrService,
  ) {
  }

  subscribeStores() {
    this.influencerStore.pipe(select(getInfluencerError))
      .pipe(takeUntil(this.unsubscriber))
      .subscribe(error => {
        if (error) {
          this.toastr.error(error, 'Error');
        }
      });

    this.influencerStore.pipe(select(getInfluencersReferralsListPaginated))
      .pipe(takeUntil(this.unsubscriber))
      .subscribe(paginatedReferralsList => {
        if (paginatedReferralsList) {
          this.influencersList = paginatedReferralsList.list;
          if (this.ajaxCallback) {
            this.ajaxCallback({
              recordsTotal: paginatedReferralsList.paging.total,
              recordsFiltered: paginatedReferralsList.paging.total,
              data: []
            });
            setTimeout(() => {
              (this.datatableInstance as any).columns.adjust();
            }, 500);
          }
        }
      });
  }

  ngOnInit(): void {
    this.datatableSettings();
    if (this.dtElement && this.dtElement.dtInstance) {
      this.dtElement.dtInstance.then((dtInstance: any) => {
        dtInstance.destroy();
        this.dtTrigger.next();
      });
    } else {
      this.dtTrigger.next();
    }
    this.influencerStore.dispatch(ResetInfluencerState({params: {error: '', paginatedReferralsList: null}}));
    this.subscribeStores();
  }

  ngAfterViewInit() {
    this.dtTrigger.next();
  }

  datatableSettings() {
    this.dtOptions = {
      scrollCollapse: true,
      pagingType: 'full_numbers',
      responsive: false,
      ordering: false,
      processing: true,
      pageLength: 10,
      paging: true,
      serverSide: true,
      scrollX: true,
      scrollY: "60vh",
      fixedHeader: {
        header: true,
        footer: true
      },
      searching: true,
      drawCallback: function (setting) {
        const totalPages = this.api().page.info().pages;
        if (totalPages <= 1) {
          document.querySelector('.modal-dialog .dataTables_paginate').classList.add('d-none');
        } else {
          document.querySelector('.modal-dialog .dataTables_paginate').classList.remove('d-none');
        }
      },
      ajax: (dataTablesParameters: any, callback) => {
        this.dtElement.dtInstance.then((dtInstance: any) => {
          this.datatableInstance = dtInstance;
          this.ajaxCallback = callback;
          const pageLength = dataTablesParameters.length;
          const pageNumber = (dataTablesParameters.start / pageLength);
          (this.datatableInstance as any).page.len(pageLength);
          const searchBox = $('.modal-dialog div.dataTables_filter input');
          searchBox.off('keyup.DT input.DT');
          searchBox.on('keyup', () => {
            const search: any = searchBox.val();
            clearTimeout(this.searchDelay);
            this.searchDelay = setTimeout(() => {
              if (search != null) {
                (this.datatableInstance as any).search(search).draw();
              }
            }, 1000);
          });
          this.influencerStore.dispatch(InfluencersReferralsListPaginated({influencerId: this.influencerId, params: {
              options: JSON.stringify({includePagination: true}),
              page: pageNumber + 1,
              perPage: pageLength,
              search: dataTablesParameters.search.value
            }}));
        });
      },
      columns: [
        {data: null}, {data: null}, {data: null}, {data: null}, {data: null}, {data: null},
        {data: null}, {data: null}, {data: null}, {data: null}, {data: null}, {data: null},
        {data: null}, {data: null}, {data: null}, {data: null}, {data: null}, {data: null},
        {data: null}, {data: null}, {data: null}, {data: null}, {data: null}
      ]
    };
  }

  showAllColumns(event) {
    this.showAll = event.target.checked;
    setTimeout(() => {
      (this.datatableInstance as any).columns.adjust();
    }, 200);
  }

  getInfluencerType(influencerTypeId) {
    const type = this.influencerTypes.find(item => item.id === influencerTypeId);
    return type ? type.name : '';
  }

  getInfluencerSegment(segmentId) {
    const type = this.segmentsGlobal.find(item => item.id === segmentId);
    return type ? type.name : '';
  }

  ngOnDestroy() {
    this.unsubscriber.next();
    this.unsubscriber.complete();
  }
}
