<form
    [formGroup]="form"
    (ngSubmit)="submit()"
>
    <div class="modal-header">
        <h4
            class="modal-title"
            id="modal-title"
        >
            Rename {{title}}
        </h4>
        <div class="d-flex justify-content-end">
            <button
                type="button"
                aria-label="Close button"
                class="close"
                aria-describedby="modal-title"
                (click)="modal.dismiss('cancel')"
            >
                <span aria-hidden="true">&times;</span>
            </button>
        </div>
    </div>

    <div class="modal-body">
        <div class="row">
            <div class="col-md-12">
                <div class='input-group input-group-sm'>
                    <input
                        class="form-control"
                        placeholder="Title"
                        type="text"
                        [ngClass]="{ 'is-invalid': form.get('value')?.required === true }"
                        formControlName="value"
                    >
                </div>
                <div
                    class="bar error-brd"
                    *ngIf="form.get('value')?.required === true"
                >
                    <div class="invalid-text">Required field</div>
                </div>
            </div>
        </div>
    </div>
    <input
        type="hidden"
        ngbAutofocus
    >
    <div class="modal-footer">
        <button
            class="btn"
            type="button"
            (click)="close()"
        >
            Cancel
        </button>
        <button
            class="btn btn-primary"
            type="submit"
        >
            Save
        </button>
    </div>
</form>