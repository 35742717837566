import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {LandingComponent} from './landing/landing.component';
import {TemplateGeneratorRoutingModule} from '@app/modules/template-generator/template-generator.routing.module';
import {TemplateGeneratorComponent} from '@app/modules/template-generator/template-generator.component';
import {DragDropModule} from '@angular/cdk/drag-drop';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {EditTemplateComponent} from '@app/modules/template-generator/edit-template/edit-template.component';
import {PreviewComponent} from '@app/modules/template-generator/preview/preview.component';
import {ShareCampaignComponent} from '@app/modules/template-generator/share-campaign/share-campaign.component';
import {EditorComponent} from '@app/modules/template-generator/editor/editor.component';
import {SelectTemplateSidebarComponent} from '@app/modules/template-generator/select-template-sidebar/select-template-sidebar.component';
import {SharedModule} from '@app/modules/shared/shared.module';
import {NgbModalModule, NgbPopoverModule} from '@ng-bootstrap/ng-bootstrap';
import {ColorPickerModule} from 'ngx-color-picker';
import {BannerTemplateComponent} from '@app/modules/template-generator/banner-template/banner-template.component';
import {EmailTemplateComponent} from '@app/modules/template-generator/email-template/email-template.component';
import {StatusBarModule} from '@app/modules/status-bar/status-bar.module';
import {LaunchCampaignComponent} from '@app/modules/template-generator/launch-campaign/launch-campaign.component';
import {SelectTemplateComponent} from '@app/modules/template-generator/select-template/select-template.component';
import { MomentTimezonePickerModule } from 'moment-timezone-picker';

import {FontAwesomeModule} from '@fortawesome/angular-fontawesome';
import {ToastContainerModule} from 'ngx-toastr';
import {FontPickerModule} from '@app/modules/fontPicker/fontPicker.module';
import {PageDesignerModule} from '@app/_components/page-designer/page-designer.module';
import {SocialShareTemplateComponent} from '@app/modules/template-generator/social-share-template/social-share-template.component';
import { LazyLoadImageModule } from 'ng-lazyload-image';
import { LaunchScheduleModalComponent } from './launch-schedule-modal/launch-schedule-modal.component';
import { NgxMaterialTimepickerModule } from 'ngx-material-timepicker';
import { BsDatepickerModule } from 'ngx-bootstrap/datepicker';
import { ImageCropperDialogModule } from '../image-cropper-dialog/image-cropper-dialog.module';
import { MatLegacyDialogModule as MatDialogModule } from '@angular/material/legacy-dialog';
import { MatIconModule } from '@angular/material/icon';
import { RenameTitleModalModule } from '../rename-title-modal/rename-title-modal.module';
import { RouterModule } from '@angular/router';
import { MailerDnsInfoModalModule } from '../mailer-dns-info-modal/mailer-dns-info-modal.module';
import { LaunchConfirmationModalModule } from '../launch-confirmation-modal/launch-confirmation-modal.module';
import { MatLegacyMenuModule as MatMenuModule } from '@angular/material/legacy-menu';


@NgModule({
  declarations: [
    TemplateGeneratorComponent,
    LandingComponent,
    EditTemplateComponent,
    SelectTemplateSidebarComponent,
    EmailTemplateComponent,
    BannerTemplateComponent,
    PreviewComponent,
    ShareCampaignComponent,
    EditorComponent,
    LaunchCampaignComponent,
    SelectTemplateComponent,
    SocialShareTemplateComponent,
    LaunchScheduleModalComponent,
  ],
  imports: [
    CommonModule,
    TemplateGeneratorRoutingModule,
    DragDropModule,
    ReactiveFormsModule,
    FormsModule,
    SharedModule,
    NgbModalModule,
    ColorPickerModule,
    StatusBarModule,
    PageDesignerModule,
    FontAwesomeModule,
    ToastContainerModule,
    FontPickerModule,
    NgbPopoverModule,
    NgxMaterialTimepickerModule,
    BsDatepickerModule,
    LazyLoadImageModule,
    ImageCropperDialogModule,
    MatDialogModule,
    MatIconModule,
    RenameTitleModalModule,
    RouterModule,
    MailerDnsInfoModalModule,
    LaunchConfirmationModalModule,
    MomentTimezonePickerModule,
    MatMenuModule
  ]
})
export class TemplateGeneratorModule {
}
