import {Injectable} from '@angular/core';
import {IAuthenticationState} from '@app/stores/authentication/authentication.state';
import {getToken} from '@app/stores/authentication/authentication.selectors';
import {select, Store} from '@ngrx/store';
import {BehaviorSubject, Observable} from 'rxjs';
import {delay, retryWhen, tap} from 'rxjs/operators';
import {WebSocketSubject} from 'rxjs/webSocket';
import {environment} from '@environments/environment';

export class Message {
  constructor(
    public event: string,
    public data: {
      type: string,
      id: number,
      status: string,
      progress: number,
      requesterId?: string,
      requesterType?: string,
      result?: any,
    }
  ) {
  }
}

@Injectable({
  providedIn: 'root'
})
export class SocketService {
  private socketDataSource = new BehaviorSubject<Object>(null);
  sourceData = this.socketDataSource.asObservable();
  public socket$: WebSocketSubject<Message>;
  token = null;

  constructor(private authenticationStore: Store<IAuthenticationState>) {
    this.authenticationStore.pipe(select(getToken))
      .subscribe(newToken => {
        if (newToken) {
          this.token = newToken;
          this.connect();
        }
      });
  }

  resetSocketData() {
    this.socketDataSource.next(null);
  }

  createWebSocket(uri) {
    return new Observable(observer => {
      try {
        this.socket$ = new WebSocketSubject(uri);

        const subscription = this.socket$.asObservable()
          .subscribe(message => {
              observer.next(message);
              if (message?.data?.id) {
                this.socketDataSource.next(message);
              }
            }, error => observer.error(error),
            () => observer.complete());

        return () => {
          if (!subscription.closed) {
            subscription.unsubscribe();
          }
        };
      } catch (error) {
        observer.error(error);
      }
    });
  }

  connect() {
    this.createWebSocket(environment.websocket + '/' + this.token)
      .pipe(retryWhen(errors => errors.pipe(tap(), delay(1000))))
      .subscribe(data => {}, err => console.error(err));
  }

  trigger(data) {
    if (!this.socket$) {
      this.connect();
    }
    const message = new Message(data.id, data.thumbnail);
    this.socket$.next(message);
  }
}
