import {Component, EventEmitter, OnInit} from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { isEmpty } from 'lodash';
import { LoaderService } from '@app/services/loader.service';
import { CampaignService } from '@app/services/campaign.service';
import { ToastrService } from 'ngx-toastr';
import {smartDateFormat} from '@app/consts';

//
// @TODO: add this in a separate file to reuse
interface ScheduleDate {
  schedule_date?: string;
  schedule_time?: string;
  timezone?: any;
  timezoneText?: any;
  timezoneValue?: any;
}

@Component({
  selector: 'app-launch-confirmation-modal',
  templateUrl: './launch-confirmation-modal.component.html',
  styleUrls: ['./launch-confirmation-modal.component.css'],
})
export class LaunchConfirmationModalComponent implements OnInit {
  public schedule: ScheduleDate = {};
  public onClearSchedule: EventEmitter<any> = new EventEmitter();
  public campaignID!: string;
  public code: string;
  public loading = false;
  public launchType = '';
  currentUser: any = {};
  timeZoneValues: any[] = [];

  constructor(
    public modal: NgbActiveModal,
    private readonly loaderService: LoaderService,
    private readonly campaignService: CampaignService,
    private toastr: ToastrService,
  ) {
  }

  async ngOnInit() {
    await this.getConfirmationCode();
  }

  close() {
    this.modal.close({action: 'cancel'});
  }

  closeWithAction(action: 'edit' | 'schedule-launch' | 'launch' | 'clear' | 'schedule' | 'launch-now') {
    //
    // Close modal with action
    // action = edit: should open edit modal where this was opened
    // action = launch: should launch the campaign where this was opened
    this.modal.close({
      action: action,
      code: this.code,
    });
  }

  getScheduleDateTime(actual = false) {
    return smartDateFormat(this.schedule?.schedule_date + ' ' + this.schedule?.schedule_time, this.schedule?.timezone, null, actual ? 'fullDateTimeWithTimeZone' : 'fullDateTime', actual ? this.schedule?.timezone : null);
  }

  hasSchedule() {
    return !isEmpty(this.schedule);
  }

  clearSchedule() {
    this.schedule = {};
    this.onClearSchedule.emit(this.schedule);
  }

  async getConfirmationCode() {
    //
    // Set loading
    this.loaderService.show();
    this.loading = true;

    try {
      //
      // Get confirmation code
      const campaignLaunchCode: any = await this.campaignService.getLaunchCode(this.campaignID).toPromise();
      // Validate
      if (!campaignLaunchCode?.data?.launch_code) {
        //
        // Unset loading
        this.loaderService.hide(true);
        this.loading = false;

        //
        // Close modal
        this.modal.dismiss('cancel');

        return this.toastr.error(
          this.loaderService.getErrorMessage({ error: { msg: 'Could not get launch confirmation, try again' } }),
          'Error'
        );
      }

      //
      // Set launch code
      this.code = campaignLaunchCode.data.launch_code;

      //
      // Unset loading
      this.loaderService.hide(false);
      this.loading = false;
    } catch (error) {
      //
      // Unset loading
      this.loaderService.hide(true);
      this.loading = false;

      //
      // Close modal
      this.modal.dismiss('cancel');

      return this.toastr.error(this.loaderService.getErrorMessage(error), 'Error');
    }
  }
}
