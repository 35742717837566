<div class="container-fluid pt-3 new-styles">
  <div class="rightBody">
    <div class="card card-box package-list mb-5">
      <div class="card-header py-3">
        <div class="card-header--title font-size-lg">
          Manage Packages
        </div>
        <div class="card-header--actions">
          <button class="btn btn-sm btn-primary" type="button" [routerLink]="['add']" role="button">
            <span class="btn-wrapper--icon"><i class="fa fa-plus"></i></span>
            <span class="btn-wrapper--label">Add New Package</span>
          </button>
        </div>
      </div>
      <div class="sticky-dropdown-custom-table p-3">
        <table width="100%" class="sb-table table dataTable no-footer nowrap" [dtTrigger]="dtTrigger" datatable
               [dtOptions]="dtOptions"
               id="dt-table">
          <thead>
          <tr>
            <th>Name</th>
            <th>Project Limit</th>
            <th>Campaign Limit</th>
            <th>Influencer Limit</th>
            <th></th>
          </tr>
          </thead>
          <tbody *ngIf="packages?.length > 0">
          <tr *ngFor="let pkg of packages">
            <td>{{pkg.name}}</td>
            <td>{{pkg.project_count_limit}}</td>
            <td>{{pkg.project_campaign_count_limit}}</td>
            <td>{{pkg.project_influencer_count_limit}}</td>
            <td>
              <button class="btn-icon float-right" mat-icon-button [matMenuTriggerFor]="menu"
                      aria-label="Example icon-button with a menu">
                <span class="fa fa-ellipsis-v"></span>
              </button>
              <mat-menu #menu="matMenu" class="campaign-popover">
                <a class="dropdown-item" [routerLink]="['edit', pkg.id]">
                  <i class="fa fa-pencil mr-2"></i>Edit</a>
                <a class="dropdown-item" (click)="deletePackage(pkg.id)">
                  <i class="fa fa-trash mr-2"></i>Delete</a>
              </mat-menu>
            </td>
          </tr>

          </tbody>
          <tbody *ngIf="packages?.length <= 0">
          <tr>
            <td colspan="8" class="no-data-available">No data available in table</td>
          </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</div>
