import {Component, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {TemplateEditorComponent} from '@app/_components/template-editor/template-editor.component';
import { UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import {Subject} from 'rxjs';
import {InfluencerType, User} from '@app/modules/shared/models';
import {select, Store} from '@ngrx/store';
import {Observable} from 'rxjs/Observable';
import {
  CampaignUpdate,
  getInfluencerError,
  getInfluencersTypes, getLoggedInUser,
  getTemplate,
  getTemplateError,
  getTemplatePreviewUrl, getTemplates,
  getTemplateSectionError,
  getTemplateSections,
  getTemplateSectionTypes, getTemplateSuccess, getTemplateTypes,
  getVariableError,
  getVariables, IAuthenticationState, ICampaignState,
  IInfluencersState,
  InfluencersTypeList,
  ITemplateSectionState,
  ITemplateState,
  IVariableState,
  ResetInfluencerState, ResetTemplateSectionState,
  ResetTemplateState,
  TemplateCreate,
  TemplateGet, TemplateGetTypes, TemplateListAll,
  TemplateSectionListAll,
  TemplateSectionTypeList,
  TemplateUpdate,
  VariableList
} from '@app/stores';
import {ToastrService} from 'ngx-toastr';
import {ActivatedRoute, Router} from '@angular/router';
import {SocketService} from '@app/services/socket.service';
import {takeUntil} from 'rxjs/operators';
import {LANDING_PAGE_TEMPLATE_TYPE_ID, SOCIAL_MEDIA_TEMPLATE_TYPE_ID} from '@app/consts';
import * as _ from 'lodash';
import * as routes from '@app/routes';
import {TemplateGeneratorService} from '@app/services/template-generator.service';
import {TemplateRevisionsComponent} from '@app/_components/template-revisions/template-revisions.component';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {LoaderService} from '@app/services/loader.service';

@Component({
  selector: 'app-social-share-template',
  templateUrl: './social-share-template.component.html',
  styleUrls: ['./social-share-template.component.css']
})
export class SocialShareTemplateComponent implements OnInit, OnDestroy {

  @ViewChild('templateEditor', {static: true}) templateEditor: TemplateEditorComponent;
  public step;
  public viewportSize = 'desktop';
  public template: any;
  public isTemplateLoaded = false;
  public rawTemplatesTypes = [];
  public templates = [];
  public stylesForm: UntypedFormGroup;
  unsubscriber = new Subject();
  public variables = {};
  public templateId: number;
  private saveClick = false;
  private saveExitClick = false;
  submitted = false;
  influencerTypes: InfluencerType[] = [];
  subscription: any;
  projectId: number;
  campaignId: number;
  campaignMeta: any;
  campaign: any;
  saveAsTemplateName = '';
  saveAsTemplateNameSubmit = false;
  loader$: Observable<boolean>;
  isAdmin = false;
  currentUser: User;
  templateType = 'public';
  isSaveNewTemplate = false;
  newDummyInfluencerType: any = {};
  influencerTypeId: any;
  public templateForm: UntypedFormGroup;
  metaValues: any = {};

  constructor(
    private formBuilder: UntypedFormBuilder,
    private templateSectionStore: Store<ITemplateSectionState>,
    private templateStore: Store<ITemplateState>,
    private variableStore: Store<IVariableState>,
    private influencersStore: Store<IInfluencersState>,
    private toastr: ToastrService,
    private activatedRoute: ActivatedRoute,
    private campaignStore: Store<ICampaignState>,
    private router: Router,
    private socketService: SocketService,
    private loaderService: LoaderService,
    private templateGeneratorService: TemplateGeneratorService,
    private _modalService: NgbModal,
    private authenticationStore: Store<IAuthenticationState>,
  ) {
    this.templateStore.dispatch(ResetTemplateState({params: {previewUrl: null, error: '', success: ''}}));
    this.templateStore.dispatch(ResetTemplateSectionState({params: {error: '', templateSectionTypes: [], templateSections: []}}));
    this.subscribeStore();
    this.initializeForms();
    this.subscription = this.socketService.sourceData
      .subscribe((message: any) => {
          if (message?.data?.requesterId) {
            if (message.data?.requesterType === 'section') {
              const temp = _.cloneDeep(this.templates);
              temp.map(element => {
                if (element.id === message.data.requesterId) {
                  element.thumbnail = message.data.result.thumbnail;
                }
                return element;
              });
              this.templates = [...temp];
            }
          }
        },
        (err) => console.error(err),
        () => console.warn('Completed!')
      );
  }

  initializeForms() {
    this.stylesForm = this.formBuilder.group({
      // max_width: new FormControl(''),
      max_width: new UntypedFormControl('1200'),
      margin_top: new UntypedFormControl(''),
      background_color: new UntypedFormControl(''),
      font_color: new UntypedFormControl(''),
      header_font_color: new UntypedFormControl(''),
      link_color: new UntypedFormControl(''),
      button_color: new UntypedFormControl(''),
      button_font_color: new UntypedFormControl(''),
      button_border_color: new UntypedFormControl(''),
      button_hover_color: new UntypedFormControl(''),
      font_family: new UntypedFormControl(''),
      header_font_family: new UntypedFormControl(''),
      custom_css: new UntypedFormControl(''),
    });
  }

  subscribeStore() {
    this.influencersStore.dispatch(ResetInfluencerState({params: {error: '', influencerTypes: []}}));
    this.authenticationStore.pipe(select(getLoggedInUser))
      .pipe(takeUntil(this.unsubscriber))
      .subscribe(user => {
          if (user) {
            this.currentUser = user;
            this.isAdmin = this.currentUser.role_user[0].role_id === 1;
          }
        }
      );
    // this.templateSectionStoreSubscribe();
    this.templateStoreSubscribe();
    this.variableStoreSubscribe();
    this.influencerStoreSubscribe();
  }

  templateSectionStoreSubscribe() {
    this.templateSectionStore.dispatch(TemplateSectionTypeList({params: {}}));
    this.templateSectionStore.pipe(select(getTemplateSections))
      .pipe(takeUntil(this.unsubscriber))
      .subscribe(templateSections => {
        if (templateSections && templateSections.length > 0) {
          this.templates = templateSections;
        }
      });
    this.templateSectionStore.pipe(select(getTemplateSectionTypes))
      .pipe(takeUntil(this.unsubscriber))
      .subscribe(templateSectionTypes => {
        if (templateSectionTypes && templateSectionTypes.length > 0) {
          this.rawTemplatesTypes = templateSectionTypes;
          this.fetchRawTemplatesById(this.rawTemplatesTypes.filter(x => x.name === 'social images')[0].id);
        }
      });
    this.templateSectionStore.pipe(select(getTemplateSectionError))
      .pipe(takeUntil(this.unsubscriber))
      .subscribe(error => {
        if (error) {
          this.toastr.error(error, 'Error');
        }
      });
    this.templateStore.pipe(select(getTemplatePreviewUrl))
      .pipe(takeUntil(this.unsubscriber))
      .subscribe(previewUrl => {
        if (previewUrl && previewUrl.url) {
          window.open(previewUrl.url, '_blank');
        }
      });
  }

  templateStoreSubscribe() {
    this.templateSectionStore.dispatch(TemplateGetTypes());
    this.templateStore.pipe(select(getTemplates))
        .pipe(takeUntil(this.unsubscriber))
        .subscribe(templates => {
          if (templates && templates.length > 0) {
            this.templates = templates;
          }
        });
    this.templateStore.pipe(select(getTemplateTypes))
        .pipe(takeUntil(this.unsubscriber))
        .subscribe(templateTypes => {
          if (templateTypes && templateTypes.length > 0) {
            this.rawTemplatesTypes = templateTypes;
            this.fetchRawTemplates();
          }
        });
    this.templateStore.pipe(select(getTemplateError))
      .pipe(takeUntil(this.unsubscriber))
      .subscribe(error => {
        if (error) {
          this.toastr.error(error, 'Error');
        }
      });
    this.templateStore.pipe(select(getTemplate))
      .pipe(takeUntil(this.unsubscriber))
      .subscribe(template => {
        if (template && !this.isSaveNewTemplate) {
          this.templateId = template.id;
          if (!this.saveClick) {
            this.replaceTemplate(template);
          }
          if (this.saveClick || this.saveExitClick) {
            const campaignUpdatePayload = { campaign: { meta: this.metaValues }, campaignId: this.campaign.id };
            this.campaignStore.dispatch(CampaignUpdate(campaignUpdatePayload));
            if (this.saveExitClick) {
              this.router.navigateByUrl('/' + routes.campaigns({ projectId: this.campaign.project_id }));
            } else {
              this.onBackButtonClick();
            }
          }
          this.saveExitClick = false;
          this.saveClick = false;
          this.submitted = false;
        } else if (template && this.isSaveNewTemplate) {
          this.fetchRawTemplates();
          this.isSaveNewTemplate = false;
        }
      });
  }

  fetchRawTemplates() {
    const orderBy = {'column': 'updated_at', 'dir': 'desc'};
    let options = {};
    if (!this.isAdmin) {
      options['includeOnlyVisible'] = true;
    }
    setTimeout(() => {
      options = {...options, includeClientTemplates: this.campaign.client_id, includeInfluencerTemplates: this.campaign.segments[0].influencer_type_id, orderBy};
      this.templateStore.dispatch(TemplateListAll({
        params: {
          options: JSON.stringify(options),
          template_type_id: SOCIAL_MEDIA_TEMPLATE_TYPE_ID,
          // influencer_type_id: this.campaign.segments[0].influencer_type_id,
          project_id: null,
        }
      }));
    }, 500);
  }

  influencerStoreSubscribe() {
    this.influencersStore.pipe(select(getInfluencerError))
      .pipe(takeUntil(this.unsubscriber))
      .subscribe(error => {
        if (error) {
          this.toastr.error(error, 'Error');
        }
      });

    this.influencersStore.pipe(select(getInfluencersTypes))
      .pipe(takeUntil(this.unsubscriber))
      .subscribe(influencerTypes => {
        if (influencerTypes && influencerTypes.length > 0) {
          this.newDummyInfluencerType.id = null;
          this.newDummyInfluencerType.name = 'All';
          this.influencerTypes = [
            this.newDummyInfluencerType,
            ...influencerTypes
          ];
        }
      });
  }

  variableStoreSubscribe() {
    this.variableStore.pipe(select(getVariableError))
      .pipe(takeUntil(this.unsubscriber))
      .subscribe(error => {
        if (error) {
          this.toastr.error(error, 'Error');
        }
      });
    this.variableStore.pipe(select(getVariables))
      .pipe(takeUntil(this.unsubscriber))
      .subscribe(variables => {
        if (variables) {
          this.variables = variables;
        }
      });
  }

  replaceTemplate(template) {
    if (template) {
      template = {...template};
      if (!template.style) {
        template.style = '<style></style>';
      }
      if (!template.body) {
        template.body = `<header data-block-type="social_images" data-id="2" class="fdb-block fp-active" draggable="false">
                    <div class="container">
                    <div class="row">
                    <div class="col-md-12">Edit here...</div>
                    </div>
                    </div>
                </header>`;
      }
      this.template = template;
      const t = template || {};

      const formFields = {};
      let properties = t.properties;
      if (properties) {
        while (typeof properties === 'string') {
          properties = JSON.parse(properties);
        }
        Object.entries(this.stylesForm.controls).forEach(([key, val]) => {
          formFields[key] = properties.hasOwnProperty(key) ? properties[key] : '';
        });
        formFields['max_width'] = 1200;
        this.stylesForm.patchValue(formFields);
      }
    }
  }

  ngOnInit(): void {
    this.loader$ = this.loaderService.loader$;
    this.templateForm = this.formBuilder.group({
      saveAsTemplateName: new UntypedFormControl('', [Validators.required]),
      influencer_type_id: new UntypedFormControl(null),
    });
    this.variableStore.dispatch(VariableList({params: {}}));
    this.influencersStore.dispatch(InfluencersTypeList());
    this.templateGeneratorService.forceReloadCampaign();
    this.templateGeneratorService.subscribeStore();
    this.templateGeneratorService.onCampaignLoaded
      .pipe(takeUntil(this.unsubscriber))
      .subscribe(async campaign => {
        if (!campaign) {
          return;
        }
        this.campaign = campaign;
        this.initStep();
        if (campaign.meta) {
          this.campaignMeta = (typeof campaign.meta === 'string') ? {...JSON.parse(campaign.meta)} : {...campaign.meta};
        }
        this.projectId = campaign.project_id;
        this.campaignId = campaign.id;
        if (this.campaign.social_template) {
          this.templateId = this.campaign.social_template.id;
          this.replaceTemplate(this.campaign.social_template);
        } else {
          this.template = {};
          this.replaceTemplate(this.template);
        }
      });
  }

  loadTemplate(templateId) {
    this.templateStore.dispatch(TemplateGet({templateId}));
  }

  templateLoaded(event) {
    this.isTemplateLoaded = event;
  }

  onStylesCustomizationChange(params) {
    this.stylesForm.patchValue({...params});
  }

  onPageSizeSelect(event) {
    this.viewportSize = event;
  }

  onEditHtml() {
    this.templateEditor.onEditHtml();
  }

  onBackButtonClick() {
    const route = routes.campaign({projectId: this.projectId, campaignId: this.campaignId});
    this.router.navigateByUrl(`/${route}/share`);
  }

  async buildTemplateForSave() {
    const htmlParts = await this.templateEditor.buildTemplateForSave();
    const {value} = this.stylesForm;
    let template: any = {};
    if (this.template) {
      template = {...this.template};
    }

    let htmlBody: any = htmlParts.body;
    htmlBody = htmlBody.replaceAll(' fp-active', '');
    htmlBody = htmlBody.replaceAll('fp-active', '');
    template.body = htmlBody.replaceAll(/data-id="[^"]*"/g, '');
    template.style = htmlParts.style;
    template.name = `${this.campaign.id}-Personalized Share Image`;
    template.template_type_id = SOCIAL_MEDIA_TEMPLATE_TYPE_ID;
    template.influencer_type_id = this.campaign.segments[0].influencer_type_id;
    template.client_id = this.campaign.client_id;
    template.project_id = this.campaign.project_id;
    template.campaign_id = this.campaign.id;
    template.properties = {};
    Object.entries(value).forEach(([key, val]) => {
      template.properties[key] = val;
    });
    delete template.content_hash;
    return template;
  }

  showTemplateRevisions() {
    const modelRef: any = this._modalService.open(TemplateRevisionsComponent, {centered: true, keyboard: true, size: 'xl'});
    modelRef.componentInstance.templateId = this.template?.id;
    modelRef.componentInstance.canReset = false;
    modelRef.result.then((result) => {
      if (result.data) {
        this.templateEditor.disableEditor();
        this.replaceTemplate(result.data);
      }
    }, (reason) => {
    });
  }

  async saveClientTemplate() {
    this.isSaveNewTemplate = false;
    this.submitted = true;
    this.saveClick = true;
    this.metaValues = {
      ...this.campaignMeta,
      image_template: true,
      image: null
    };
    // this.campaignStore.dispatch(CampaignUpdate({ campaign: { meta: this.metaValues }, campaignId: this.campaign.id }));
    if (this.templateId) {
      this.templateStore.dispatch(TemplateUpdate({
        id: this.templateId,
        body: await this.buildTemplateForSave()
      }));
    } else {
      this.templateStore.dispatch(TemplateCreate({
        body: await this.buildTemplateForSave()
      }));
    }
  }

  fetchRawTemplatesById(id) {
    this.templateSectionStore.dispatch(TemplateSectionListAll({
      params: {
        options: JSON.stringify({includeOnlyVisible: true}),
        template_section_type_id: id,
        template_type_id: LANDING_PAGE_TEMPLATE_TYPE_ID
      }
    }));
  }

  async saveAsNewTemplateModel(template, type) {
    this.templateForm.patchValue({'influencer_type_id': this.campaign.segments[0].influencer_type_id});
    this.templateType = type;
    const modelRef = this._modalService.open(template, {
      centered: false,
      size: 'sm',
      keyboard: true
    });
    modelRef.result.then((result) => {
    }, (reason) => {
    });
  }

  async saveAsNewTemplate(modal: any) {
    this.isSaveNewTemplate = true;
    this.saveAsTemplateNameSubmit = true;
    this.saveAsTemplateName = this.templateForm.get('saveAsTemplateName').value;
    if(this.templateForm.get('influencer_type_id').value === "null") {
      this.templateForm.patchValue({'influencer_type_id': null});
    }
    this.influencerTypeId = this.templateForm.get('influencer_type_id').value;
    if (this.saveAsTemplateName) {
      const template = await this.buildTemplateForSave();
      modal.close();
      let extraParams;
      if (this.templateType === 'client') {
        extraParams = {
          name: this.saveAsTemplateName,
            client_id: this.campaign.client_id,
            project_id: null,
            campaign_id: null,
            influencer_type_id: this.influencerTypeId
        };
      } else {
        extraParams = {
          name: this.saveAsTemplateName,
          client_id: null,
          project_id: null,
          campaign_id: null,
          influencer_type_id: this.influencerTypeId
        };
      }
      this.templateGeneratorService.touchTemplate({
        templateTypeId: SOCIAL_MEDIA_TEMPLATE_TYPE_ID,
        sourceTemplateId: template.id,
        extraParams
      });
    }
  }

  changingInfluencerType(e) {
    this.templateForm.patchValue({'influencer_type_id': e});
  }

  onSaveButtonClick(saveExitClick = true) {
    this.saveExitClick = saveExitClick
   this.saveClientTemplate();
  }

  initStep() {
    this.step = {
      title: 'Share',
      params: {
        projectId: this.campaign?.project_id,
        campaignId: this.campaign?.id,
        campaignName: this.campaign?.name
      }
    };
  }

  ngOnDestroy() {
    this.unsubscriber.next();
    this.unsubscriber.complete();
    this.subscription.unsubscribe();
  }

}
