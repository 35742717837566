import { Injectable } from '@angular/core';
import { Subject, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class TitleService {

  private subject = new Subject<any>();

  set(title: string) {
    this.subject.next(title);
  }

  get(): Observable<any> {
    return this.subject.asObservable();
  }

}
