import {AfterViewInit, Component, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {Subject} from 'rxjs';
import {takeUntil} from 'rxjs/operators';
import {PersonalAccessToken} from '@app/modules/shared/models';
import {DataTableDirective} from 'angular-datatables';
import {select, Store} from '@ngrx/store';
import Swal from 'sweetalert2';
import {ToastrService} from 'ngx-toastr';
import * as DataTables from 'datatables.net';

import {
  getPersonalAccessTokenError,
  getPersonalAccessTokenSuccess,
  getPersonalAccessToken,
  ResetPersonalAccessTokenState,
  IPersonalAccessTokenState,
  PersonalAccessTokenAdd,
  PersonalAccessTokenDelete,
  getPersonalAccessTokenPaginated,
  PersonalAccessTokenListPaginated, PersonalAccessTokenUpdate,
} from '@app/stores';
import { NgbModal} from "@ng-bootstrap/ng-bootstrap";
import {UntypedFormBuilder, UntypedFormGroup, Validators} from "@angular/forms";
import * as moment from "moment-timezone";

@Component({
  templateUrl: './personal-access-token.component.html',
  styleUrls: ['./personal-access-token.component.scss']
})
export class PersonalAccessTokenComponent implements OnInit, OnDestroy, AfterViewInit {
  @ViewChild(DataTableDirective)
  dtElement: DataTableDirective;
  addPersonalAccessTokenForm: UntypedFormGroup;
  submitted = false;
  dtOptions: any = {};
  unsubscriber = new Subject();
  dtTrigger: Subject<any> = new Subject();
  ajaxCallback: any;
  private datatableInstance: DataTables.Api<any>;
  private searchDelay;
  public personalAccessToken: PersonalAccessToken[] = [];
  Action = false;
  currentUser: any = {};
  modalTitle:string="Create New Token";
  modalBtnName:string ="Create";
  editTokenId: any;
  constructor(
    private personalAccessTokenStore: Store<IPersonalAccessTokenState>,
    private toastrService: ToastrService,
    private _modalService: NgbModal,
    private formBuilder: UntypedFormBuilder,
    private toastr: ToastrService,
  ) {
    this.personalAccessTokenStore.dispatch(ResetPersonalAccessTokenState({params: {}}));
    this.subscribeStores();
  }

  subscribeStores() {

    this.personalAccessTokenStore.pipe(select(getPersonalAccessTokenError))
      .pipe(takeUntil(this.unsubscriber))
      .subscribe(error => {
        if (error) {
          this.toastrService.error(error);
        }
      });
    this.personalAccessTokenStore.pipe(select(getPersonalAccessTokenSuccess))
      .pipe(takeUntil(this.unsubscriber))
      .subscribe(success => {
        if (success) {
          if (this.submitted) {
            this.submitted = false;
            this._modalService.dismissAll();
            this.loadAllPersonalAccessToken();
          }
          if (this.Action) {
            this.Action = false;
            this.loadAllPersonalAccessToken();
          }
        }
      });

    this.personalAccessTokenStore.pipe(select(getPersonalAccessTokenPaginated))
      .pipe(takeUntil(this.unsubscriber))
      .subscribe((paginatedtoken: any) => {
          if (paginatedtoken) {
            this.personalAccessToken = paginatedtoken.list;
            if (this.ajaxCallback) {
              this.ajaxCallback({
                recordsTotal: paginatedtoken.paging.total,
                recordsFiltered: paginatedtoken.paging.total,
                data: []
              });
              setTimeout(() => {
                (this.datatableInstance as any).columns.adjust();
              }, 500);
            }
          }
        }
      );
    this.personalAccessTokenStore.pipe(select(getPersonalAccessToken))
      .pipe(takeUntil(this.unsubscriber))
      .subscribe(pkg => {
        if (pkg) {
          this.addPersonalAccessTokenForm.patchValue({...pkg});
        }
      });
  }

  ngOnInit(): void {
    this.currentUser = JSON.parse(localStorage.getItem('currentUser')) ;
    this.addPersonalAccessTokenForm = this.formBuilder.group({
      tokenName: ['', Validators.required],
    });

    this.datatableSettings();
    if (this.dtElement && this.dtElement.dtInstance) {
      this.dtElement.dtInstance.then((dtInstance: any) => {
        dtInstance.destroy();
        this.dtTrigger.next();
      });
    } else {
      this.dtTrigger.next();
    }
  }

  ngAfterViewInit() {
    this.dtTrigger.next();
  }

  datatableSettings() {
    const columns = ['name', 'token','created_at'];
    this.dtOptions = {
      scrollCollapse: true,
      pagingType: 'full_numbers',
      columnDefs: [{orderable: false, targets: 'no-sort'},
        { width: '430px', targets: 0 },
        { width: '830px', targets: 1 },
        { width: '430px', targets: 2 },
      ],
      responsive: false,
      ordering: true,
      processing: true,
      pageLength: 10,
      paging: true,
      serverSide: true,
      scrollX: true,
      searching: true,
      // scrollY: "100vh",
      fixedHeader: {
        header: true,
        footer: true,
        headerOffset: 55 ,
      },
      fixedColumns: {
        right: 1,
        left: 0
      },
      drawCallback: function (setting) {
        const totalPages = this.api().page.info().pages;
        if (totalPages <= 1) {
          document.querySelector('.dataTables_paginate').classList.add('d-none');
        } else {
          document.querySelector('.dataTables_paginate').classList.remove('d-none');
        }
      },
      ajax: (dataTablesParameters: any, callback) => {
        this.dtElement.dtInstance.then((dtInstance: any) => {
          this.datatableInstance = dtInstance;
          this.ajaxCallback = callback;
          const pageLength = dataTablesParameters.length;
          const pageNumber = (dataTablesParameters.start / pageLength);
          (this.datatableInstance as any).page.len(pageLength);
          const searchBox = $('div.dataTables_filter input');
          searchBox.off('keyup.DT input.DT');
          searchBox.on('keyup', () => {
            const search: any = searchBox.val();
            clearTimeout(this.searchDelay);
            this.searchDelay = setTimeout(() => {
              if (search != null) {
                (this.datatableInstance as any).search(search).draw();
              }
            }, 1000);
          });
          const orderBy = {
            ...dataTablesParameters.order[0],
            column: columns[dataTablesParameters.order[0].column]
          };
          this.loadAllPersonalAccessToken(pageNumber, pageLength, dataTablesParameters.search.value,orderBy);
        });
      },
      columns: [
        {data: null}, {data: null}, {data: null}, {data: null}
      ],
    };
  }

  private loadAllPersonalAccessToken(page = 0, perPage = 10, search = '', orderBy= {'column': 'updated_at', 'dir': 'desc'}) {
    this.personalAccessTokenStore.dispatch(PersonalAccessTokenListPaginated({
      params: {
        options: JSON.stringify({includePagination: true, orderBy}),
        page: page + 1,
        perPage,
        search,
        "user_id": JSON.parse(this.currentUser.id)
      }
    }));
  }

  deletePersonalAccessToken(tokenId: number) {
    Swal({
      title: 'Are you sure?',
      type: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Yes',
      cancelButtonText: 'No'
    }).then(result => {
      if (result.value) {
        this.Action = true;
        this.personalAccessTokenStore.dispatch(PersonalAccessTokenDelete({tokenId}));
      }
    });
  }

  ResetPersonalAccessToken(SelectedTokenData: any) {
    const updateBodyParams = {
      "name":SelectedTokenData.name,
      "isTokenUpdate":true,
    }
    Swal({
      title: 'Are you sure?',
      type: 'warning',
      text: 'This will invalidate your current token and may cause API calls from your application to fail until you switch over to the new token. Any external data associated with the current token will carry over to the new token.',
      showCancelButton: true,
      confirmButtonText: 'Reset',
      cancelButtonText: 'Cancel',
    }).then(result => {
      if (result.value) {
        this.Action = true;
        this.personalAccessTokenStore.dispatch(PersonalAccessTokenUpdate({tokenId:SelectedTokenData.id,body:updateBodyParams}));
      }
    });
  }

  get f() {
    return this.addPersonalAccessTokenForm.controls;
  }
  showAddPlatformPopup(entry: any) {
    this.modalBtnName = "Create";
    this.modalTitle = "Create New Token";
    this.addPersonalAccessTokenForm.reset();
    this.editTokenId = null;

    const modelRef = this._modalService.open(entry, {
      centered: false,
      size: 'LG',
      keyboard: true,
    });
    modelRef.result.then((result) => {
    }, (reason) => {
    });
  }

  showEditTokenPopup(entry: any,SelectedTokenData:any){
    this.modalBtnName = "Update";
    this.modalTitle = "Update Name";
    const modelRef = this._modalService.open(entry, {
      centered: false,
      size: 'LG',
      keyboard: true,
    });
    modelRef.result.then((result) => {
    }, (reason) => {
    });
    this.addPersonalAccessTokenForm.patchValue({
      tokenName: SelectedTokenData.name
    });
    this.editTokenId = SelectedTokenData.id;

  }

  saveAccessTokenFormData(){
    this.submitted = true;
    if (this.addPersonalAccessTokenForm.invalid) {
      return;
    }

    const params = {
      name: this.addPersonalAccessTokenForm.value.tokenName,
      user_id: JSON.parse(this.currentUser.id)
    }

    if (this.editTokenId) {
      this.personalAccessTokenStore.dispatch(PersonalAccessTokenUpdate({tokenId:this.editTokenId,body:params}));
    } else{
      this.personalAccessTokenStore.dispatch(PersonalAccessTokenAdd({body:params}))
    }
  }

  copyProjectCode(code) {
    const input = document.createElement('textarea');
    input.value = code;
    document.body.appendChild(input);
    input.select();
    document.execCommand('Copy');
    this.toastr.success('Token is Copied');
    input.remove();
  }

  formatMoment(insertTime) {
    insertTime = moment(insertTime, 'YYYY-MM-DD[T]HH[:]mm[:]00[.000Z]');
    if (insertTime.isValid()) {
      return insertTime.format('DD-MM-YYYY hh:mm a');
    }
    return null;
  }

  ngOnDestroy() {
    this.unsubscriber.next();
    this.unsubscriber.complete();
  }
}
