import {Component, OnDestroy, OnInit} from '@angular/core';
import {Router, ActivatedRoute} from '@angular/router';
import {UntypedFormBuilder, UntypedFormGroup, Validators} from '@angular/forms';
import {select, Store} from '@ngrx/store';
import {getError, getLoggedInUser, IAuthenticationState, LoginUser, LogoutUser, ResetAuthState, TokenLogin} from '@app/stores';
import {ToastrService} from 'ngx-toastr';
import {Subject} from 'rxjs';
import {takeUntil} from 'rxjs/operators';

@Component({
  templateUrl: 'login.component.html',
  styleUrls: ['login.component.css']
})


export class LoginComponent implements OnInit, OnDestroy {
  loginForm: UntypedFormGroup;
  submitted = false;
  returnUrl: string;
  authMessage: object;
  token: string;
  unsubscriber = new Subject();
  successTokenUrl: any;

  constructor(
    private formBuilder: UntypedFormBuilder,
    private route: ActivatedRoute,
    private router: Router,
    private toastrService: ToastrService,
    private authenticationStore: Store<IAuthenticationState>
  ) {
    this.authenticationStore.dispatch(ResetAuthState({params: {error: ''}}));
    this.subscribeStores();
  }

  subscribeStores() {
    // redirect to home if already logged in
    this.authenticationStore.pipe(select(getLoggedInUser))
      .pipe(takeUntil(this.unsubscriber))
      .subscribe(user => {
        if (user) {
          if (user.password_status === '0') {
            if (!this.returnUrl || this.returnUrl === '' || this.returnUrl === '/') { this.returnUrl = '/projects'; }
            const decodedUri = decodeURI(this.returnUrl);
            const qp = decodedUri.split('?')[1];
            this.router.navigate([(decodedUri.split('?')[0]) || '/projects'], {queryParams: this.getQueryParams(qp)});
          } else {
            this.router.navigate(['/resetpassword']);
          }
        }
      });

    this.authenticationStore.pipe(select(getError))
      .pipe(takeUntil(this.unsubscriber))
      .subscribe(error => {
        if (error) {
          this.toastrService.error(error);
        }
      });
  }

  ngOnInit() {
    this.loginForm = this.formBuilder.group({
      email: ['', [Validators.required, Validators.email]],
      remember: [false],
      password: ['', [Validators.required, Validators.minLength(6)]]
    });

    // get return url from route parameters or default to '/'
    this.returnUrl = this.route.snapshot.queryParams['returnUrl'] || '/projects';
    this.token = this.route.snapshot.queryParams['token'];
    if (this.token) {
      this.tokenLogin();
    }
    this.successTokenUrl = this.route.snapshot.queryParams['status'];
    if(this.successTokenUrl) {

      this.router.navigate(
        [], {
          relativeTo: this.route,
          queryParams: { status: null },
          queryParamsHandling: 'merge',
        });

    }
    this.authMessage = {
      Success: false,
      msg: ''
    };
  }

  tokenLogin() {
    this.authenticationStore.dispatch(LogoutUser());
    this.authenticationStore.dispatch(TokenLogin({token: this.token}));
  }

  // convenience getter for easy access to form fields
  get f() {
    return this.loginForm.controls;
  }

  /**
   * Returns Auth token object that will use for next navigation.
   *
   * @param  url  an absolute URL giving the base location of the Application.
   * @param  name the location of the request, relative to the url.
   * @return      Auth token object with user data.
   */

  onSubmit() {
    this.submitted = true;

    // stop here if form is invalid
    if (this.loginForm.invalid) {
      return;
    }
    this.authenticationStore.dispatch(LogoutUser());
    this.authenticationStore.dispatch(LoginUser({
      credentials: {
        email: this.f.email.value,
        password: this.f.password.value,
        remember: this.f.remember.value
      }
    }));
  }

  getQueryParams(qs = '') {
    qs = qs ? qs.split('+').join(' ') : qs;
    const params = {};
    let tokens;
    const re = /[?&]?([^=]+)=([^&]*)/g;

    while (tokens = re.exec(qs)) {
      params[decodeURIComponent(tokens[1])] = decodeURIComponent(tokens[2]);
    }

    return params;
  }

  ngOnDestroy() {
    this.unsubscriber.next();
    this.unsubscriber.complete();
  }
}
