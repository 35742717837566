import {Action, createReducer, on} from '@ngrx/store';
import {
  TemplateSectionList,
  TemplateSectionListSuccess,
  TemplateSectionListError,
  TemplateSectionTypeList,
  TemplateSectionTypeListSuccess,
  TemplateSectionTypeListError,
  ResetTemplateSectionState,
  TemplateSectionDelete,
  TemplateSectionDeleteSuccess,
  TemplateSectionDeleteError,
  TemplateSectionGet,
  TemplateSectionGetSuccess,
  TemplateSectionGetError,
  TemplateSectionCreateError,
  TemplateSectionUpdate,
  TemplateSectionUpdateSuccess,
  TemplateSectionUpdateError,
  TemplateSectionCreate,
  TemplateSectionCreateSuccess,
  TemplateSectionListAll,
  TemplateSectionListAllSuccess,
  TemplateSectionListAllError,
  TemplateSectionUpdateThumbnail,
  TemplateSectionUpdateThumbnailSuccess,
  TemplateSectionUpdateThumbnailError,
  TemplateSectionGetAssets, TemplateSectionGetAssetsSuccess, TemplateSectionGetAssetsError
} from '@app/stores/template-section/template-section.actions';
import {initialTemplateSectionState} from '@app/stores/template-section/template-section.state';

const generateReducer = createReducer(initialTemplateSectionState,
  on(TemplateSectionListAll, (state) => ({
    ...state,
    templateSection: null,
    templateSections: [],
    success: '',
    error: ''
  })),
  on(TemplateSectionListAllSuccess, (state, {templateSections}) => ({
    ...state,
    templateSection: null,
    templateSections,
    success: '',
    error: ''
  })),
  on(TemplateSectionListAllError, (state, {error}) => ({
    ...state,
    templateSection: null,
    templateSections: [],
    success: '',
    error
  })),
  on(TemplateSectionList, (state) => ({
    ...state,
    templateSection: null,
    paginatedTemplateSection: null,
    success: '',
    error: ''
  })),
  on(TemplateSectionListSuccess, (state, {paginatedTemplateSection}) => ({
    ...state,
    templateSection: null,
    paginatedTemplateSection,
    success: '',
    error: ''
  })),
  on(TemplateSectionListError, (state, {error}) => ({
    ...state,
    templateSection: null,
    paginatedTemplateSection: null,
    success: '',
    error
  })),

  on(TemplateSectionTypeList, (state) => ({
    ...state,
    templateSectionTypes: [],
    success: '',
    error: ''
  })),
  on(TemplateSectionTypeListSuccess, (state, {templateSectionTypes}) => ({
    ...state,
    templateSectionTypes,
    success: '',
    error: ''
  })),
  on(TemplateSectionTypeListError, (state, {error}) => ({
    ...state,
    templateSectionTypes: [],
    success: '',
    error
  })),

  on(TemplateSectionDelete, (state) => ({
    ...state,
    success: '',
    error: ''
  })),
  on(TemplateSectionDeleteSuccess, (state) => ({
    ...state,
    success: 'Template Section Deleted Successfully',
    error: ''
  })),
  on(TemplateSectionDeleteError, (state, {error}) => ({
    ...state,
    success: '',
    error
  })),

  on(TemplateSectionGet, (state) => ({
    ...state,
    templateSection: null,
    success: '',
    error: ''
  })),
  on(TemplateSectionGetSuccess, (state, {templateSection}) => ({
    ...state,
    templateSection,
    success: '',
    error: ''
  })),
  on(TemplateSectionGetError, (state, {error}) => ({
    ...state,
    templateSection: null,
    success: '',
    error
  })),

  on(TemplateSectionCreate, (state) => ({
    ...state,
    templateSection: null,
    success: '',
    error: ''
  })),
  on(TemplateSectionCreateSuccess, (state, {templateSection}) => ({
    ...state,
    templateSection,
    success: '',
    error: ''
  })),
  on(TemplateSectionCreateError, (state, {error}) => ({
    ...state,
    templateSection: null,
    success: '',
    error
  })),

  on(TemplateSectionUpdate, (state) => ({
    ...state,
    templateSection: null,
    success: '',
    error: ''
  })),
  on(TemplateSectionUpdateSuccess, (state, {templateSection}) => ({
    ...state,
    templateSection,
    success: '',
    error: ''
  })),
  on(TemplateSectionUpdateError, (state, {error}) => ({
    ...state,
    templateSection: null,
    success: '',
    error
  })),

  on(TemplateSectionUpdateThumbnail, (state) => ({
    ...state,
    success: '',
    error: ''
  })),
  on(TemplateSectionUpdateThumbnailSuccess, (state, {success}) => ({
    ...state,
    success,
    error: ''
  })),
  on(TemplateSectionUpdateThumbnailError, (state, {error}) => ({
    ...state,
    success: '',
    error
  })),

  on(TemplateSectionGetAssets, (state) => ({
    ...state,
    templateSectionAssets: null,
    success: '',
    error: ''
  })),
  on(TemplateSectionGetAssetsSuccess, (state, {templateSectionAssets}) => ({
    ...state,
    templateSectionAssets,
    success: '',
    error: ''
  })),
  on(TemplateSectionGetAssetsError, (state, {error}) => ({
    ...state,
    templateSectionAssets: null,
    success: '',
    error
  })),

  on(ResetTemplateSectionState, (state, {params}) => ({
    ...state,
    ...params
  })),
);

export function templateSectionReducer(state = initialTemplateSectionState, action: Action) {
  return generateReducer(state, action);
}
