<div class="container-fluid pt-3 new-styles">
  <div class="rightBody">
    <div class="card card-box mb-5">
      <div class="card-header py-3">
        <div class="card-header--title font-size-lg">
          Manage Templates
        </div>
        <div class="card-header--actions">
          <button class="btn btn-sm btn-primary" type="button" (click)="onAddEditTemplate('add')" role="button">
            <span class="btn-wrapper--icon"><i class="fa fa-plus"></i></span>
            <span class="btn-wrapper--label">Add New Template</span>
          </button>
        </div>
      </div>
      <div class="d-flex flex-wrap px-4 py-3">
        <div class="client-dropdown">
          <app-creatable-select
            [selectedValue]="selectedTemplateType"
            [hideLabel]="true" [isCreatable]="false" [onlyBottomBorder]="false"
            [placeholder]="'Select template type'" [selectOptions]="templateTypes"
            (optionSelected)="selectedTemplateType = $event ? $event.value : null; loadAllTempaltes()"></app-creatable-select>
        </div>
        <div class="client-dropdown">
          <app-creatable-select
            [selectedValue]="selectedInfluencerType"
            [hideLabel]="true" [isCreatable]="false" [onlyBottomBorder]="false"
            [placeholder]="'Select influencer type'" [selectOptions]="influencerTypes"
            (optionSelected)="selectedInfluencerType = $event ? $event.value : null; loadAllTempaltes()"></app-creatable-select>
        </div>
        <div class="client-dropdown">
          <app-creatable-select
            [selectedValue]="selectedTemplateVisibility"
            [hideLabel]="true" [isCreatable]="false" [onlyBottomBorder]="false"  [valueKey]="'value'"
            [placeholder]="'Select Template Visibility'" [selectOptions]="constants.templateVisibleOptions"
            (optionSelected)="selectedTemplateVisibility = $event ? $event.value : null; loadAllTempaltes()"></app-creatable-select>
        </div>
        <div class='d-flex align-items-center ml-auto'>
          <button class="btn btn-primary btn-sm float-right" type="button" role="button" (click)="updateAllThumbnails()">
            <span class="btn-wrapper--label">Update all thumbnails</span>
          </button>
        </div>
      </div>
      <div class="sticky-dropdown-custom-table p-3">
        <table width="100%" class="sb-table table dataTable no-footer nowrap" [dtTrigger]="dtTrigger" datatable
               [dtOptions]="dtOptions"
               id="dt-table">
          <thead>
          <tr>
            <th>Visibility</th>
            <th>Template Name</th>
            <th>Template Type</th>
            <th>Thumbnail</th>
            <th></th>
          </tr>
          </thead>
          <tbody *ngIf="templateData.length > 0">
          <tr *ngFor="let template of templateData">
            <td><input type="checkbox" [value]="template.id"  [checked]="template.visibility" (change)="changeVisibility(template)"/></td>
            <td>{{ template.name}}</td>
            <td>{{ template.template_type.name}}</td>
            <td>
              <img src="{{template.thumbnail}}" alt="Template Thumbnail" class="image-thumbnail">
            </td>
            <td>
              <button class="btn-icon float-right" mat-icon-button [matMenuTriggerFor]="menu"
                      aria-label="Example icon-button with a menu">
                <span class="fa fa-ellipsis-v"></span>
              </button>
              <mat-menu #menu="matMenu" class="campaign-popover">
                <a class="dropdown-item" (click)="onAddEditTemplate('edit', template.id)">
                  <i class="fa fa-pencil mr-2"></i>Edit</a>
                <a class="dropdown-item" (click)="deleteTemplate(template.id)">
                  <i class="fa fa-trash mr-2"></i>Delete</a>
              </mat-menu>
            </td>
          </tr>
          </tbody>
          <tbody *ngIf="templateData?.length <= 0">
          <tr>
            <td colspan="4" class="no-data-available">No data available in table</td>
          </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</div>
