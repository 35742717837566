import {createFeatureSelector, createSelector} from '@ngrx/store';
import {IFileUploadState} from '@app/stores';

const selectFile = createFeatureSelector<IFileUploadState>('files');

export const getFile = createSelector(selectFile, (state: IFileUploadState) => state.file);

export const getFileSuccess = createSelector(selectFile, (state: IFileUploadState) => state.success);

export const getFileError = createSelector(selectFile, (state: IFileUploadState) => state.error);

export const getSignedUrl = createSelector(selectFile, (state: IFileUploadState) => ({url: state.url, file: state.file}));

export const getSignedUrlSuccess = createSelector(selectFile, (state: IFileUploadState) => state.success);

export const getSignedUrlError = createSelector(selectFile, (state: IFileUploadState) => state.error);

export const getUploadToSignedUrl = createSelector(selectFile, (state: IFileUploadState) => state.uploaded);

export const getUploadToSignedUrlSuccess = createSelector(selectFile, (state: IFileUploadState) => state.success);

export const getUploadToSignedUrlError = createSelector(selectFile, (state: IFileUploadState) => state.error);

export const getFiles = createSelector(selectFile, (state: IFileUploadState) => state.files);

export const getFileUploadProgress = createSelector(selectFile, (state: IFileUploadState) => state.progress);
