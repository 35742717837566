import {createAction, props} from '@ngrx/store';
import {Settings, PaginatedSettings} from '@app/modules/shared/models';

enum ESettingActions {
  SettingsListAll = '[Settings] Settings List All',
  SettingsListAllSuccess = '[Settings] Settings List All Success',
  SettingsListAllError = '[Settings] Settings List All Error',
  SettingsList = '[Settings] Settings List',
  SettingsListSuccess = '[Settings] Settings List Success',
  SettingsListError = '[Settings] Settings List Error',
  SettingDelete = '[Settings] Setting Delete',
  SettingDeleteSuccess = '[Settings] Setting Delete Success',
  SettingDeleteError = '[Settings] Setting Delete Error',
  SettingUpdate = '[Settings] Setting Update',
  SettingUpdateSuccess = '[Settings] Setting Update Success',
  SettingUpdateError = '[Settings] Setting Update Error',
  SettingAdd = '[Settings] Setting Add',
  SettingAddSuccess = '[Settings] Setting Add Success',
  SettingAddError = '[Settings] Setting Add Error',
  SettingGet = '[Settings] Setting Get',
  SettingGetSuccess = '[Settings] Setting Get Success',
  SettingGetError = '[Settings] Setting Get Error',
  SettingCacheClear = '[Settings] Setting Cache Clear',
  SettingCacheClearSuccess = '[Settings] Setting Cache Clear Success',
  SettingCacheClearError = '[Settings] Setting Cache Clear Error',

  ResetSettingState = '[Settings] Reset Setting State',
}

export const SettingsListAll = createAction(ESettingActions.SettingsListAll, (params: any = {}) => params);
export const SettingsListAllSuccess = createAction(ESettingActions.SettingsListAllSuccess, props<{ settings: Settings[] }>());
export const SettingsListAllError = createAction(ESettingActions.SettingsListAllError, props<{ error: string }>());

export const SettingsList = createAction(ESettingActions.SettingsList, (params: any = {}) => params);
export const SettingsListSuccess = createAction(ESettingActions.SettingsListSuccess, props<{ paginatedSettings: PaginatedSettings }>());
export const SettingsListError = createAction(ESettingActions.SettingsListError, props<{ error: string }>());

export const SettingDelete = createAction(ESettingActions.SettingDelete, props<{ settingId: number }>());
export const SettingDeleteSuccess = createAction(ESettingActions.SettingDeleteSuccess);
export const SettingDeleteError = createAction(ESettingActions.SettingDeleteError, props<{ error: string }>());

export const SettingUpdate = createAction(ESettingActions.SettingUpdate, props<{ settingId: number, setting: Settings }>());
export const SettingUpdateSuccess = createAction(ESettingActions.SettingUpdateSuccess);
export const SettingUpdateError = createAction(ESettingActions.SettingUpdateError, props<{ error: string }>());

export const SettingAdd = createAction(ESettingActions.SettingAdd, props<{ setting: Settings }>());
export const SettingAddSuccess = createAction(ESettingActions.SettingAddSuccess);
export const SettingAddError = createAction(ESettingActions.SettingAddError, props<{ error: string }>());

export const SettingGet = createAction(ESettingActions.SettingGet, props<{ settingId: number }>());
export const SettingGetSuccess = createAction(ESettingActions.SettingGetSuccess, props<{ setting: Settings }>());
export const SettingGetError = createAction(ESettingActions.SettingGetError, props<{ error: string }>());

export const SettingCacheClear = createAction(ESettingActions.SettingCacheClear, props<{ pattern: string }>());
export const SettingCacheClearSuccess = createAction(ESettingActions.SettingCacheClearSuccess);
export const SettingCacheClearError = createAction(ESettingActions.SettingCacheClearError, props<{ error: string }>());

export const ResetSettingState = createAction(ESettingActions.ResetSettingState, (params: any = {}) => params);

