import { Injectable } from '@angular/core';
import {
  Router,
  CanActivate,
  ActivatedRouteSnapshot,
  RouterStateSnapshot
} from '@angular/router';

import {select, Store} from '@ngrx/store';
import {getLoggedInUser, IAuthenticationState} from '@app/stores';
import {map} from 'rxjs/operators';

@Injectable({ providedIn: 'root' })
export class NonInfluencerGuard implements CanActivate {
  constructor(
    private router: Router,
    private authenticationStore: Store<IAuthenticationState>
  ) { }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    return this.authenticationStore.pipe(select(getLoggedInUser)).pipe(
      map(currentUser => {
        // redirect if influencer
        if (!currentUser || currentUser.user_type == '2') {
          return this.router.parseUrl('/');
        }
        return true;
      })
    );
  }
}
