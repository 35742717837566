import {FontPickerDirective} from '@app/modules/fontPicker/fontPicker.directive';
import {NgModule} from '@angular/core';

@NgModule({
  declarations: [FontPickerDirective],
  exports: [FontPickerDirective]
})
export class FontPickerModule {

}
