import { Component, Input, Output, OnInit, EventEmitter } from '@angular/core';
import * as routes from '@app/routes';

@Component({
  selector: 'app-status-bar',
  templateUrl: './status-bar.component.html',
  styleUrls: ['./status-bar.component.scss']
})
export class StatusBarComponent implements OnInit {
  @Output() public SaveButtonClick: EventEmitter<any> = new EventEmitter();
  @Output() public UndoButtonClick: EventEmitter<any> = new EventEmitter();
  @Input() showUndoButton = false;
  @Input() disableUndoButton = false;
  @Input() Status;
  @Input() isShowStatusBar = true;
  @Input() loading;
  @Input() step;
  @Input() customStyle = {};
  @Input() campaign = null;
  @Input() skipLanding = false;
  @Input() reLaunch = false;

  public bar = [];
  public isLaunched: boolean;
  constructor() { }

  runSaveButtonClick(): void {
    this.SaveButtonClick.emit(this.SaveButtonClick);
  }

  runUndoButtonClick(): void {
    this.UndoButtonClick.emit(this.UndoButtonClick);
  }

  ngOnInit() {
    this.assureStep();
    this.bar = [
      {
        title: 'Campaign',
        link: ({ projectId }) => `/${routes.createCampaign({ projectId })}`,
        query: ({ campaignId }) => ({ campId: campaignId })
      },
      {
        title: 'Influencers',
        link: ({ projectId }) => `/project/${projectId}/add-influencer`,
        query: ({ campaignId }) => ({ campId: campaignId })
      },
      {
        // title: 'Template',
        title: 'Content',
        link: ({ projectId, campaignId }) => `/${routes.campaignTemplate({ projectId, campaignId })}`,
        query: () => ({})
      },
      {
        title: 'Share',
        link: ({ projectId, campaignId }) => `/${routes.campaignShare({ projectId, campaignId })}`,
        query: () => ({})
      },
      {
        title: 'Preview',
        link: ({ projectId, campaignId }) => `/${routes.campaignPreview({ projectId, campaignId })}`,
        query: () => ({})
      },
      {
        title: this.reLaunch ? 'ReLaunch' : 'Launch',
        link: ({ projectId, campaignId }) => `/${routes.launchCampaign({ projectId, campaignId })}`,
        query: () => ({})
      }
    ];
  }

  mapClassNames() {
    const step = this.step || { params: {} };
    this.isLaunched = step.params.isLaunched;

    let customBar = Object.assign([], this.bar);
    if (this.skipLanding) {
      customBar = customBar.filter(item => (!this.skipLanding || (this.skipLanding && item.title !== 'Content')));
    }

    const index = customBar.findIndex(e => e.title === step.title);

    return customBar.map((step, stepNumber) => {
      if (stepNumber < index) { step.class = 'enabled'; } else if (stepNumber > index) { step.class = 'disabled'; } else { step.class = this.isLaunched ? 'enabled' : 'current'; }
      return step;
    });
  }

  statusBarHeader() {
    const { params: { eventTitle, campaignName } } = this.step;

    if (campaignName) { return campaignName; }
    if (eventTitle) { return eventTitle; }
    return 'New Project';
  }

  assureStep() {
    this.step = this.step || { params: {} };
  }
}
