import {Action, createReducer, on} from '@ngrx/store';
import {
  VariableList, VariableListSuccess, VariableListError, ResetVariableState
} from '@app/stores/variable/variable.actions';
import {initialVariableState} from '@app/stores/variable/variable.state';

const generateReducer = createReducer(initialVariableState,
  on(VariableList, (state) => ({
    ...state,
    variable: null,
    variables: [],
    success: '',
    error: ''
  })),
  on(VariableListSuccess, (state, {variables}) => ({
    ...state,
    variable: null,
    variables,
    success: '',
    error: ''
  })),
  on(VariableListError, (state, {error}) => ({
    ...state,
    variable: null,
    variables: [],
    success: '',
    error
  })),

  on(ResetVariableState, (state, {params}) => ({
    ...state,
    ...params
  })),
);

export function variableReducer(state = initialVariableState, action: Action) {
  return generateReducer(state, action);
}
