import {Injectable} from '@angular/core';
import {Actions, createEffect, ofType} from '@ngrx/effects';
import {Store} from '@ngrx/store';
import {catchError, map, switchMap} from 'rxjs/operators';
import {of} from 'rxjs';
import {LoaderService} from '@app/services/loader.service';
import {
  SegmentsAdd,
  SegmentsAddError,
  SegmentsAddSuccess,
  SegmentsDeleteError,
  SegmentsDeleteSuccess,
  SegmentsDelete,
  SegmentsList,
  SegmentsListError,
  SegmentsListPaginated, SegmentsListPaginatedError,
  SegmentsListPaginatedSuccess,
  SegmentsListSuccess,
  SegmentsUpdate,
  SegmentsUpdateError,
  SegmentsUpdateSuccess
} from '@app/stores/segments/segments.actions';
import {ISegmentsState} from '@app/stores/segments/segments.state';
import {SegmentService} from '@app/services/segment.service';

@Injectable()
export class SegmentsEffects {
  constructor(
    private _actions$: Actions,
    private _store: Store<ISegmentsState>,
    private segmentService: SegmentService,
    private loaderService: LoaderService
  ) {
  }

  segmentsList$ = createEffect(() => this._actions$.pipe(
    ofType(SegmentsList),
    switchMap((action) => {
      this.loaderService.show();
      return this.segmentService.getSegments(action.params).pipe(
        map((resp: any) => {
          if (resp.success) {
            this.loaderService.hide();
            return SegmentsListSuccess({segments: resp.data});
          }
          this.loaderService.hide(true);
          return SegmentsListError({error: this.loaderService.getErrorMessage(resp)});
        }),
        catchError(error => {
          this.loaderService.hide(true);
          return of(SegmentsListError({error: this.loaderService.getErrorMessage(error)}));
        })
      );
    })
  ));

  segmentsListPaginated$ = createEffect(() => this._actions$.pipe(
    ofType(SegmentsListPaginated),
    switchMap((action) => {
      this.loaderService.show();
      return this.segmentService.getSegments(action.params).pipe(
        map((resp: any) => {
          if (resp.success) {
            this.loaderService.hide();
            return SegmentsListPaginatedSuccess({paginatedSegments: resp.data});
          }
          this.loaderService.hide(true);
          return SegmentsListPaginatedError({error: this.loaderService.getErrorMessage(resp)});
        }),
        catchError(error => {
          this.loaderService.hide(true);
          return of(SegmentsListPaginatedError({error: this.loaderService.getErrorMessage(error)}));
        })
      );
    })
  ));

  segmentDelete$ = createEffect(() => this._actions$.pipe(
    ofType(SegmentsDelete),
    switchMap((action) => {
      this.loaderService.show();
      return this.segmentService.deleteSegment(action.segmentId).pipe(
        map((resp: any) => {
          if (resp.success) {
            this.loaderService.hide();
            return SegmentsDeleteSuccess();
          }
          this.loaderService.hide(true);
          return SegmentsDeleteError({error: this.loaderService.getErrorMessage(resp)});
        }),
        catchError(error => {
          this.loaderService.hide(true);
          return of(SegmentsDeleteError({error: this.loaderService.getErrorMessage(error)}));
        })
      );
    })
  ));

  segmentUpdate$ = createEffect(() => this._actions$.pipe(
    ofType(SegmentsUpdate),
    switchMap((action) => {
      this.loaderService.show();
      return this.segmentService.updateSegment(action.segmentId, action.segment).pipe(
        map((resp: any) => {
          if (resp.success) {
            this.loaderService.hide();
            return SegmentsUpdateSuccess();
          }
          this.loaderService.hide(true);
          return SegmentsUpdateError({error: this.loaderService.getErrorMessage(resp)});
        }),
        catchError(error => {
          this.loaderService.hide(true);
          return of(SegmentsUpdateError({error: this.loaderService.getErrorMessage(error)}));
        })
      );
    })
  ));

  segmentAdd$ = createEffect(() => this._actions$.pipe(
    ofType(SegmentsAdd),
    switchMap((action) => {
      this.loaderService.show();
      return this.segmentService.addSegment(action.segment).pipe(
        map((resp: any) => {
          if (resp.success) {
            this.loaderService.hide();
            return SegmentsAddSuccess({segment: resp.data});
          }
          this.loaderService.hide(true);
          return SegmentsAddError({error: this.loaderService.getErrorMessage(resp)});
        }),
        catchError(error => {
          this.loaderService.hide(true);
          return of(SegmentsAddError({error: this.loaderService.getErrorMessage(error)}));
        })
      );
    })
  ));
}
