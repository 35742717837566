<div class="container-fluid bg-white">
  <div class="row">
    <!-- LogIn Left Section  Start -->
    <div class="col-lg-7 col-md-12 d-flex justify-content-center left-col-padding">
      <div class="align-self-center w-100-small-device">
        <div class="logo text-center mt-2">
          <img src="../../../assets/images/blue-logo.png"/>
        </div>
        <div class="login-heading">
          <h1>Request an account
          </h1>
          <span class="d-block">
            Activate your influencers. Tap into your speakers, exhibitors and attendees network.</span>

          <span class="d-block"> Start snöballing your project with influencer marketing.</span>

          <span> Already have an account? <a routerLink="/login"> Log in</a></span>

        </div>

        <div *ngIf="submitted && enquiryMessage && !enquiryMessage['success']" class="alert alert-danger">
          <p> {{enquiryMessage['msg']}} </p>
        </div>

        <div *ngIf="submitted && enquiryMessage && enquiryMessage['success']" class="alert alert-primary-custom mt-4">
          <p> {{enquiryMessage['msg']}} </p>
        </div>

        <div *ngIf="submitted && enquiryMessage && enquiryMessage['success'] && !token" class="card custom-card my-4">
          <p><b>First Name: </b> {{enquiryMessage.info.first_name}} </p>
          <p><b>last Name: </b> {{enquiryMessage.info.last_name}} </p>
          <p><b>Email: </b> {{enquiryMessage.info.email}} </p>
          <p><b>Phone: </b> {{enquiryMessage.info.phone}} </p>
          <p><b>Company: </b> {{enquiryMessage.info.company}} </p>
          <p><b>Website: </b> {{enquiryMessage.info.website}} </p>
          <p><b>Job Title: </b> {{enquiryMessage.info.job_title}} </p>
          <p><b>Comment: </b> {{enquiryMessage.info.comment}} </p>
        </div>

        <form (ngSubmit)="onSubmit()" [formGroup]="registerForm" [hidden]="!visible">
          <!-- login form column start -->
          <div class="card custom-card mt-4">

            <div class="row">
              <div class="col-md-6">

                <div class="floating-label">
                  <input [ngClass]="{ 'is-invalid': submitted && f.first_name.errors }"
                         class="floating-input"
                         formControlName="first_name"
                         placeholder=" "
                         type="text">
                  <span class="highlight"></span>
                  <label>First Name <span class="red">*</span></label>
                  <div *ngIf="submitted && f.first_name.errors" class="bar error-brd">
                    <div *ngIf="f.first_name.errors.required" class="invalid-text">First Name is
                      required
                    </div>
                  </div>
                </div>


              </div>
              <div class="col-md-6">

                <div class="floating-label">
                  <input [ngClass]="{ 'is-invalid': submitted && f.last_name.errors }"
                         class="floating-input"
                         formControlName="last_name"
                         placeholder=" "
                         type="text">
                  <span class="highlight"></span>
                  <label>Last Name <span class="red">*</span></label>
                  <div *ngIf="submitted && f.last_name.errors" class="bar error-brd">
                    <div *ngIf="f.last_name.errors.required" class="invalid-text">Last Name is
                      required
                    </div>
                  </div>
                </div>


              </div>
            </div>


            <div class="row">
              <div class="col-md-6">

                <div class="floating-label">
                  <input [ngClass]="{ 'is-invalid': submitted && f.job_title.errors }"
                         class="floating-input"
                         formControlName="job_title"
                         placeholder=" "
                         type="text">
                  <span class="highlight"></span>
                  <label>Job Title <span class="red">*</span></label>
                  <div *ngIf="submitted && f.job_title.errors" class="bar error-brd">
                    <div *ngIf="f.job_title.errors.required" class="invalid-text">Job Title is
                      required
                    </div>
                  </div>
                </div>


              </div>
              <div class="col-md-6">

                <div class="floating-label">
                  <input [ngClass]="{ 'is-invalid': submitted && f.email.errors }"
                         class="floating-input"
                         formControlName="email" placeholder=" "
                         type="text">
                  <span class="highlight"></span>
                  <label>Email <span class="red">*</span></label>
                  <div *ngIf="submitted && f.email.errors" class="bar error-brd">
                    <div *ngIf="f.email.errors.required" class="invalid-text">Email is required
                    </div>
                    <div *ngIf="f.email.errors.email" class="invalid-text">Username must be a valid
                      email address
                    </div>
                  </div>
                </div>

              </div>

            </div>


            <div class="row">
              <div class="col-md-6">

                <div class="floating-label">
                  <input [ngClass]="{ 'is-invalid': submitted && f.company.errors }"
                         class="floating-input"
                         formControlName="company" placeholder=" "
                         type="text">
                  <span class="highlight"></span>
                  <label>Company <span class="red">*</span></label>
                  <div *ngIf="submitted && f.company.errors" class="bar error-brd">
                    <div *ngIf="f.company.errors.required" class="invalid-text">Company is
                      required
                    </div>
                  </div>
                </div>

              </div>

              <div class="col-md-6">
                <div class="floating-label">
                  <input [ngClass]="{ 'is-invalid': submitted && f.phone.errors }"
                         class="floating-input"
                         formControlName="phone"
                         placeholder=" " type="text">
                  <span class="highlight"></span>
                  <label>Phone</label>
                </div>
              </div>
            </div>

            <div class="row">
              <div class="col-md-6">

                <div class="floating-label">
                  <input [ngClass]="{ 'is-invalid': submitted && f.website.errors }"
                         class="floating-input"
                         formControlName="website" placeholder=" "
                         type="text">
                  <span class="highlight"></span>
                  <label>Website</label>
                  <div *ngIf="submitted && f.website.errors" class="bar error-brd">
                    <div *ngIf="f.website.errors" class="invalid-text">Enter valid url
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div class="row">
              <div class="col-md-12">
                <div class="floating-label">
                  <textarea [ngClass]="{ 'is-invalid': submitted && f.comment.errors }"
                         class="floating-input"
                         formControlName="comment" id="comment" style="height: 150px"></textarea>
                  <span class="highlight"></span>
                  <label>Comment</label>
                </div>
              </div>
            </div>

            <div class="row">
              <div class="col-lg-6 col-md-6 col-ms-12">
                <div class="floating-label input-group input-group-sm variable-input">
                  <input [ngClass]=" { 'is-invalid': submitted && f.password.errors }"
                         formControlName="password" id="password" placeholder=" "
                         class="floating-input"
                         [type]="showPasswords.password ? 'text' : 'password'"/>
                  <label for="password">Password</label>
                  <div *ngIf="submitted && f.password.errors" class="bar error-brd confirm-password-error">
                    <div *ngIf="f.password.errors?.required" class="invalid-text">Password is required</div>
                    <div *ngIf="f.password.errors?.passwordRegex" class="invalid-text">Invalid Password</div>
                  </div>
                  <div class="inputs">
                    <button type="button" class="btn btn-default btn-sm ml-1 show-password" (click)="showPasswords.password = !showPasswords.password">
                      <i class="fa" [ngClass]="{'fa-eye': !showPasswords.password, 'fa-eye-slash': showPasswords.password}"></i>
                    </button>
                  </div>
                </div>
                <div class="mt-4">
                  <mat-password-strength #passwordComponent (onStrengthChanged)="updatePasswordStrength($event)"
                                         [password]="f.password.value">
                  </mat-password-strength>
                  <mat-password-strength-info
                    [passwordComponent]="passwordComponent">
                  </mat-password-strength-info>
                </div>
              </div>
              <div class="col-lg-6 col-md-6 col-ms-12">
                <div class="floating-label input-group input-group-sm variable-input">
                  <input [ngClass]=" { 'is-invalid': submitted && f.confirm_password.errors }"
                         formControlName="confirm_password" id="confirm_password" placeholder=" "
                         class="floating-input"
                         [type]="showPasswords.confirm_password ? 'text' : 'password'"/>
                  <label for="confirm_password">Confirm password</label>
                  <div *ngIf="submitted && f.confirm_password.errors" class="bar error-brd confirm-password-error">
                    <div *ngIf="f.confirm_password.errors.required" class="invalid-text">Confirm password is required</div>
                    <div *ngIf="f.confirm_password.errors.confirmPasswordValidator" class="invalid-text">Password does not match</div>
                  </div>
                  <div class="inputs">
                    <button type="button" class="btn btn-default btn-sm ml-1 show-password" (click)="showPasswords.confirm_password = !showPasswords.confirm_password">
                      <i class="fa" [ngClass]="{'fa-eye': !showPasswords.confirm_password, 'fa-eye-slash': showPasswords.confirm_password}"></i>
                    </button>
                  </div>
                </div>
              </div>
            </div>

            <!-- <div class="row">
                <div class="col-md-6">
                  <div class="floating-label">
                      <input type="checkbox" [checked]="oneononeFeature" (change)="oneononeFeature = !oneononeFeature"/>
                    <span class="highlight"></span>
                    <label>Subscription for One On One Meeting Feature</label>
                  </div>
                </div>
                <div class="col-md-6">
                    <div class="floating-label">
                        <input type="checkbox" [checked]="videoFeature" (change)="videoFeature = !videoFeature"/>
                      <span class="highlight"></span>
                      <label>Subscriptiopn for Video Feature</label>
                    </div>
                </div>
            </div> -->

          </div>
          <!-- login form column end -->

          <div class="m-auto text-center">
            <button class="btn btn-primary blue-color login-btn mt-3 mb-5" type="submit">Get started
            </button>
          </div>
        </form>
      </div>
    </div>
    <!-- LogIn Left Section End -->
    <!-- LogIn right Section  Start -->
    <div class="col-lg-5 col-md-12 blue-bg login-right-container d-flex justify-content-center">
      <div class="align-self-center  w-100-small-device">
        <div class="col-md-12">
          <h1 class="right-panel-heading m-B-64 small-divice-text-center">92% Trust
            <br/> Word-of-Mouth
          </h1>
        </div>
        <div class="col-md-12">
          <p class="sub-info small-divice-text-center ">
            Your speakers, exhibitors,
            <br/> and attendees can help
            <br/> promote your project.
          </p>
        </div>
        <div class="small-divice-text-center m-B-160 mt-5">
          <a class="btn outline-white-brd-btn" type="submit" href="https://snoball.events/book-demo3/" target="_blank" style="text-decoration: none;">Tell me more</a>
        </div>
      </div>
    </div>
    <!-- LogIn right Section  End -->
  </div>
</div>
