<div class="card card-box editor-card">
  <div class="card-header py-3 d-none">
    <div class="card-header--title font-size-lg">
      Template
    </div>
    <div class="card-header--actions pull-right" *ngIf="showSizeToolbar">
      <app-device-toolbar
        (onPageSizeChange)="onPageSizeSelect($event)"
        (openCodeEditor)="onEditHtml()"></app-device-toolbar>
    </div>
  </div>
  <div class="card-body p-0">
    <div class="row editor-row">
      <div class="col-md-3 pr-0">
        <div class="custom-sidebar" [style]="sideBarCustomStyle">
          <div class="side-top-bar">
            <div class='mb-3 bg-light w-100 d-flex justify-content-center'>
              <div class='col-md-6 d-flex justify-content-center'>
                <button (click)='onListIconClick()' class='btn mx-auto' style='outline: none; box-shadow: none;'>
                  <i class='fa fa-list {{ currentMode === modes.RAW_TEMPLATE_LISTING ? "active" : "" }}'
                     aria-hidden='true'></i>
                </button>
              </div>
              <div class='col-md-6 d-flex justify-content-center'>
                <button (click)='onBrushIconClick()' class='btn' style='outline: none; box-shadow: none;'>
                  <i class='fa fa-paint-brush {{ currentMode === modes.TOOL_BAR ? "active" : "" }}'
                     aria-hidden='true'></i>
                </button>
              </div>
            </div>
            <div *ngIf="currentMode === modes.RAW_TEMPLATE_LISTING && showBlockSelector" class='w-100'>
              <div class='mb-3 pl-3 pr-3' [ngClass]="{'d-none': hideTemplateSelection}">
                <select class='custom-select w-100' [(ngModel)]="this.selectedRawType"
                        (change)="onRawTemplateTypeChange($event)">
                  <ng-container *ngFor='let item of rawTemplatesTypes'>
                    <option [value]='item.id'
                            style="text-transform:capitalize;">{{item.name}}</option>
                  </ng-container>
                </select>
              </div>
            </div>
          </div>
          <div class="page-section-design"
               [ngClass]="{'page-properties': currentMode === modes.TOOL_BAR}">
            <div class="page-designer-toolbar-container" cdkDropListGroup>
              <div class='p-0 bg-white container-container'>
                <div *ngIf="currentMode === modes.RAW_TEMPLATE_LISTING" class='w-100'>
                  <div class='container side-bar pl-3 pr-3' cdkDropList [cdkDropListData]="templates">
                    <ng-container *ngIf="templates.length > 0; else noSection">
                      <div *ngFor='let item of templates' class='card mb-3 block' (click)='addBlock(item)'>
                        <a><img src="{{item['thumbnail']?item['thumbnail']:'/assets/images/block_placeholder.png'}}"
                                class='card-img'/></a>
                      </div>
                    </ng-container>
                    <ng-template #noSection>
                      <div class='d-flex justify-content-center mb-3 block'>
                        No {{templateType === "socialShare" ? 'Templates' : 'Sections'}} available
                      </div>
                    </ng-template>
                  </div>
                </div>

                <div *ngIf="currentMode === modes.TOOL_BAR" class="w-100 h-100">
                  <div class='d-flex align-items-center pl-3 py-2' *ngIf="showLandingPageEnable">
                    <input id="landing_page_active" placeholder="" type="checkbox" class="mr-1"
                           [checked]="landingPageEnabled"
                           (change)="landingPageEnabledChange.emit($event.target.checked)">
                    <label for="landing_page_active" class="m-0">Landing Page Enabled</label>
                  </div>
                  <div class='mb-3 pl-3'>
                    <small>PAGE SETTINGS</small>
                  </div>
                  <div class='accordion' id='bodyPropertiesAccordion'>
                    <div class='card card-box'>
                      <div class='card-header p-0 pl-3 header-color' id='bodyProperties'>
                        <small>Body Properties</small>
                        <button class='btn btn-link ml-auto' type='button' data-toggle='collapse'
                                data-target='#bodySettingsContainer'
                                aria-expanded='true' aria-controls='collapseOne'
                                (click)="currentToolBar['bodyProperties'] = !currentToolBar['bodyProperties']">
                          <i class='fa fa-{{ !currentToolBar["bodyProperties"] ? "plus" : "minus" }}'></i>
                        </button>
                      </div>

                      <div class='pt-3 collapse show' id='bodySettingsContainer' aria-labelledby='bodyProperties'
                           data-parent='#bodyPropertiesAccordion'>
                        <div class='form-group'>
                          <div class='row'>
                            <div class='col-md-9 mb-3 mx-auto'>
                              <label for="max_width"><small>Width</small></label>
                              <div class='input-group input-group-sm'>
                                <input id="max_width" style="height: 36px;" class='form-control' type='number' [disabled]='templateType === "socialShare"'
                                       data-field='max_width' data-unit="px"
                                       (change)="onStylesCustomizationChange($event.target.value, 'max_width')"
                                       value="{{pageSetting.max_width || ''}}"/>
                                <div class='input-group-append'>
                                  <span class='input-group-text'>px</span>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div class='row'>
                            <div class='col-md-9 mb-3 mx-auto'>
                              <label for="margin_top"><small>Margin Top</small></label>
                              <div class='input-group input-group-sm'>
                                <input id="margin_top" style="height: 36px;" class='form-control' type='number'
                                       data-field='margin_top' data-unit="px"
                                       (change)="onStylesCustomizationChange($event.target.value, 'margin_top')"
                                       value="{{pageSetting.margin_top || ''}}"/>
                                <div class='input-group-append'>
                                  <span class='input-group-text'>px</span>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div class='accordion' id='blockPropertiesAccordion'>
                    <div class='card card-box'>
                      <div class='card-header p-0 pl-3 header-color' id='blockProperties'>
                        <small>Blocks Properties</small>
                        <button class='btn btn-link ml-auto' type='button' data-toggle='collapse'
                                data-target='#blockSettingsContainer'
                                aria-expanded='true' aria-controls='collapseOne'
                                (click)="currentToolBar['blockProperties'] = !currentToolBar['blockProperties']">
                          <i class='fa fa-{{ !currentToolBar["blockProperties"] ? "plus" : "minus" }}'></i>
                        </button>
                      </div>

                      <div class='pt-3 collapse show' id='blockSettingsContainer' aria-labelledby='blockProperties'
                           data-parent='#blockPropertiesAccordion'>
                        <div class='form-group'>
                          <div class='row'>
                            <div class='col-md-9 mb-3 mx-auto'>
                              <label for="block_margin"><small>Margin</small></label>
                              <div class='input-group input-group-sm'>
                                <input id="block_margin" style="height: 36px;" class='form-control' type='number'
                                       data-field='block_margin' data-unit="px"
                                       (change)="onStylesCustomizationChange($event.target.value, 'block_margin')"
                                       value="{{pageSetting.block_margin || ''}}"/>
                                <div class='input-group-append'>
                                  <span class='input-group-text'>px</span>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div class='row'>
                            <div class='col-md-9 mb-3 mx-auto'>
                              <label for="block_padding"><small>Padding</small></label>
                              <div class='input-group input-group-sm'>
                                <input id="block_padding" style="height: 36px;" class='form-control' type='number'
                                       data-field='block_padding' data-unit="px"
                                       (change)="onStylesCustomizationChange($event.target.value, 'block_padding')"
                                       value="{{pageSetting.block_padding || ''}}"/>
                                <div class='input-group-append'>
                                  <span class='input-group-text'>px</span>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div class='accordion' id='colorSettingsAccordion'>
                    <div class='card card-box'>
                      <div class='card-header p-0 pl-3 header-color' id='colorSettings'>
                        <small>Colors</small>
                        <button class='btn btn-link ml-auto' type='button' data-toggle='collapse'
                                data-target='#colorSettingsContainer' aria-expanded='true' aria-controls='collapseOne'
                                (click)="currentToolBar['colorSettings'] = !currentToolBar['colorSettings']">
                          <i class='fa fa-{{ !currentToolBar["colorSettings"] ? "plus" : "minus" }}'></i>
                        </button>
                      </div>

                      <div class='pt-3 collapse show' id='colorSettingsContainer' aria-labelledby='colorSettings'
                           data-parent='#colorSettingsAccordion'>
                        <div class='form-group'>
                          <div class='row'>
                            <div class='col-md-9 mb-3 mx-auto'>
                              <label for='background_color'><small>Background</small></label>
                              <div class='input-group input-group-sm color-input'>
                                <app-color-picker id="background_color"
                                        [value]="pageSetting.background_color"
                                        [class]="'form-control'"
                                        [extraColors]="extraColors"
                                        (change)="onStylesCustomizationChange($event.target.value, 'background_color')">
                                </app-color-picker>
                              </div>
                            </div>
                          </div>
                          <div class='row'>
                            <div class='col-md-9 mb-3 mx-auto'>
                              <label for="block_background"><small>Block Background</small></label>
                              <div class='input-group input-group-sm color-input'>
                                <app-color-picker id="block_background"
                                                  [value]="pageSetting.block_background"
                                                  [class]="'form-control'"
                                                  [extraColors]="extraColors"
                                                  (change)="onStylesCustomizationChange($event.target.value, 'block_background')">
                                </app-color-picker>
                              </div>
                            </div>
                          </div>
                          <div class='row'>
                            <div class='col-md-9 mb-3 mx-auto'>
                              <label for='font_color'><small>Text</small></label>
                              <div class='input-group input-group-sm color-input'>
                                <app-color-picker id="font_color"
                                                  [value]="pageSetting.font_color"
                                                  [class]="'form-control'"
                                                  [extraColors]="extraColors"
                                                  (change)="onStylesCustomizationChange($event.target.value, 'font_color')">
                                </app-color-picker>
                              </div>
                            </div>
                          </div>
                          <div class='row'>
                            <div class='col-md-9 mb-3 mx-auto'>
                              <label for='header_font_color'><small>Headings</small></label>
                              <div class='input-group input-group-sm color-input'>
                                <app-color-picker id="header_font_color"
                                                  [value]="pageSetting.header_font_color"
                                                  [class]="'form-control'"
                                                  [extraColors]="extraColors"
                                                  (change)="onStylesCustomizationChange($event.target.value, 'header_font_color')">
                                </app-color-picker>
                              </div>
                            </div>
                          </div>
                          <div class='row'>
                            <div class='col-md-9 mb-3 mx-auto'>
                              <label for='link_color'><small>Link</small></label>
                              <div class='input-group input-group-sm color-input'>
                                <app-color-picker id="link_color"
                                                  [value]="pageSetting.link_color"
                                                  [class]="'form-control'"
                                                  [extraColors]="extraColors"
                                                  (change)="onStylesCustomizationChange($event.target.value, 'link_color')">
                                </app-color-picker>
                              </div>
                            </div>
                          </div>
                          <div class='row'>
                            <div class='col-md-9 mb-3 mx-auto'>
                              <label for='button_color'><small>Button</small></label>
                              <div class='input-group input-group-sm color-input'>
                                <app-color-picker id="button_color"
                                                  [value]="pageSetting.button_color"
                                                  [class]="'form-control'"
                                                  [extraColors]="extraColors"
                                                  (change)="onStylesCustomizationChange($event.target.value, 'button_color')">
                                </app-color-picker>
                              </div>
                            </div>
                          </div>
                          <div class='row'>
                            <div class='col-md-9 mb-3 mx-auto'>
                              <label for='button_font_color'><small>Button Text</small></label>
                              <div class='input-group input-group-sm color-input'>
                                <app-color-picker id="button_font_color"
                                                  [value]="pageSetting.button_font_color"
                                                  [class]="'form-control'"
                                                  [extraColors]="extraColors"
                                                  (change)="onStylesCustomizationChange($event.target.value, 'button_font_color')">
                                </app-color-picker>
                              </div>
                            </div>
                          </div>
                          <div class='row'>
                            <div class='col-md-9 mb-3 mx-auto'>
                              <label for="button_border_color"><small>Button Border</small></label>
                              <div class='input-group input-group-sm color-input'>
                                <app-color-picker id="button_border_color"
                                                  [value]="pageSetting.button_border_color"
                                                  [class]="'form-control'"
                                                  [extraColors]="extraColors"
                                                  (change)="onStylesCustomizationChange($event.target.value, 'button_border_color')">
                                </app-color-picker>
                              </div>
                            </div>
                          </div>
                          <div class='row'>
                            <div class='col-md-9 mb-3 mx-auto'>
                              <label for="button_hover_color"><small>Button Hover</small></label>
                              <div class='input-group input-group-sm color-input'>
                                <app-color-picker id="button_hover_color"
                                                  [value]="pageSetting.button_hover_color"
                                                  [class]="'form-control'"
                                                  [extraColors]="extraColors"
                                                  (change)="onStylesCustomizationChange($event.target.value, 'button_hover_color')">
                                </app-color-picker>
                              </div>
                            </div>
                          </div>
                        </div>

                      </div>
                    </div>
                  </div>

                  <div class='accordion' id='fontStylesAccordion'>
                    <div class='card card-box'>
                      <div class='card-header p-0 pl-3 header-color' id='fontStyles'>
                        <small>Fonts</small>
                        <button class='btn btn-link ml-auto' type='button' data-toggle='collapse'
                                data-target='#fontStylesContainer'
                                aria-expanded='true' aria-controls='collapseOne'
                                (click)="currentToolBar['fontStyles'] = !currentToolBar['fontStyles']">
                          <i class='fa fa-{{ !currentToolBar["fontStyles"] ? "plus" : "minus" }}'></i>
                        </button>
                      </div>

                      <div class='pt-3 collapse show' id='fontStylesContainer' aria-labelledby='fontStyles'
                           data-parent='#fontStylesAccordion'>
                        <div class='form-group'>
                          <div class='row'>
                            <div class='col-md-9 mb-3 mx-auto'>
                              <label for='font_family'><small>Text</small></label>
                              <div class='input-group input-group-sm'>
                                <input value="{{pageSetting.font_family || ''}}" id="font_family" appFontPicker
                                       class='form-control'
                                       type='text'
                                       (change)='onStylesCustomizationChange($event.target.value, "font_family")'/>
                              </div>
                            </div>
                          </div>
                          <div class='row'>
<!--                            <div class='col-md-9 mb-3 mx-auto'>-->
<!--                              <label for='header_font_family'><small>Headings</small></label>-->
<!--                              <div class='input-group input-group-sm'>-->
<!--                                <input value="{{pageSetting.header_font_family || ''}}" id="header_font_family"-->
<!--                                       appFontPicker-->
<!--                                       class='form-control' type='text'-->
<!--                                       (change)='onStylesCustomizationChange($event.target.value, "header_font_family")'/>-->
<!--                              </div>-->
<!--                            </div>-->
                            <div class='col-md-9 mb-3 mx-auto'>
                              <label for='header1_font_family'><small>Heading 1</small></label>
                              <div class='input-group input-group-sm'>
                                <input value="{{pageSetting.header1_font_family || ''}}" id="header1_font_family"
                                       appFontPicker
                                       class='form-control' type='text'
                                       (change)='onStylesCustomizationChange($event.target.value, "header1_font_family")'/>
                              </div>
                            </div>
                            <div class='col-md-9 mb-3 mx-auto'>
                              <label for='header1_font_family'><small>Heading 2</small></label>
                              <div class='input-group input-group-sm'>
                                <input value="{{pageSetting.header2_font_family || ''}}" id="header2_font_family"
                                       appFontPicker
                                       class='form-control' type='text'
                                       (change)='onStylesCustomizationChange($event.target.value, "header2_font_family")'/>
                              </div>
                            </div>
                            <div class='col-md-9 mb-3 mx-auto'>
                              <label for='header1_font_family'><small>Heading 3</small></label>
                              <div class='input-group input-group-sm'>
                                <input value="{{pageSetting.header3_font_family || ''}}" id="header3_font_family"
                                       appFontPicker
                                       class='form-control' type='text'
                                       (change)='onStylesCustomizationChange($event.target.value, "header3_font_family")'/>
                              </div>
                            </div>
                            <div class='col-md-9 mb-3 mx-auto'>
                              <label for='header1_font_family'><small>Heading 4</small></label>
                              <div class='input-group input-group-sm'>
                                <input value="{{pageSetting.header4_font_family || ''}}" id="header4_font_family"
                                       appFontPicker
                                       class='form-control' type='text'
                                       (change)='onStylesCustomizationChange($event.target.value, "header4_font_family")'/>
                              </div>
                            </div>
                            <div class='col-md-9 mb-3 mx-auto'>
                              <label for='header1_font_family'><small>Heading 5</small></label>
                              <div class='input-group input-group-sm'>
                                <input value="{{pageSetting.header5_font_family || ''}}" id="header5_font_family"
                                       appFontPicker
                                       class='form-control' type='text'
                                       (change)='onStylesCustomizationChange($event.target.value, "header5_font_family")'/>
                              </div>
                            </div>
                            <div class='col-md-9 mb-3 mx-auto'>
                              <label for='header1_font_family'><small>Heading 6</small></label>
                              <div class='input-group input-group-sm'>
                                <input value="{{pageSetting.header6_font_family || ''}}" id="header6_font_family"
                                       appFontPicker
                                       class='form-control' type='text'
                                       (change)='onStylesCustomizationChange($event.target.value, "header6_font_family")'/>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div class='accordion' id='customCSSAccordion'>
                    <div class='card card-box'>
                      <div class='card-header p-0 pl-3 header-color' id='customCssSettings'>
                        <small>Custom Css</small>
                        <button class='btn btn-link ml-auto' type='button' data-toggle='collapse'
                                data-target='#customCSSContainer' aria-expanded='true' aria-controls='collapseOne'
                                (click)="currentToolBar['customCssSettings'] = !currentToolBar['customCssSettings']">
                          <i class='fa fa-{{ !currentToolBar["customCssSettings"] ? "plus" : "minus" }}'></i>
                        </button>
                      </div>

                      <div class='pt-3 collapse show' id='customCSSContainer'
                           data-parent='#customCSSAccordion'>
                        <div class='form-group'>
                          <div class='row'>
                            <div class='col-md-9 mb-3 mx-auto'>
                              <label for='background_color'><small>Custom Css</small></label>
                              <div class='input-group input-group-sm'>
                    <textarea class="form-control custom-css" [value]="pageSetting.custom_css || ''"
                              (keyup)="onStylesCustomizationChange($event.target.value, 'custom_css')">
                    </textarea>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="container-fluid {{customClasses}}" [ngClass]="{'d-none': !templateLoaded}">
        <div class="col-md-12 p-0">
          <app-page-designer
            [token]="token"
            #pageDesigner [ViewportSize]="viewportSize"
            [Variables]="getVariablesForPageDesigner()"></app-page-designer>
        </div>
      </div>
      <div class="container-fluid loader-div" [ngClass]="{'d-none': templateLoaded}">
        <div class="col-md-12 p-0 loader">
          <i class="fa fa-spinner fa-spin"></i>
        </div>
      </div>
    </div>
  </div>
  <ng-template #codeEditor let-modal>
    <div class="modal-header">
      <h4 class="modal-title" id="modal-basic-title">Template HTML</h4>
      <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click');">
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
    <div class="modal-body">
      <textarea class="code-view" (blur)="updateHtmlBody($event)"
                autocomplete="off" autocapitalize="off"
                spellcheck="false">{{editHtml.htmlBody}}</textarea>
    </div>
    <div class="modal-footer">
      <button (click)="modal.close(true)" class="btn btn-primary mr-2">
        <span>Apply</span>
      </button>
      <button (click)="modal.dismiss('Close clicked');" class="btn btn-default mr-2">
        <span>Cancel</span>
      </button>
    </div>
  </ng-template>
</div>
