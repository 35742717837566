<div class="container-fluid pt-3">
  <div class="rightBody widget-setting">
    <div class="card card-box card-margin">
      <div class="card-header py-3">
        <div class="card-header--title font-size-lg">
          Widget settings
        </div>
        <div class="card-header--actions pull-right">
        </div>
      </div>
      <div class="card-body">
        <div class='row'>
          <div class='col-md-12 mb-3 mx-auto'>
            <div class="text-info">To encourage new registrants to quickly engage with the campaign and start promoting it right after they register, we offer a special widget to be displayed on the registration confirmation page (thank you page). The widget automatically registers the new user on Snöball and generates his/her landing page.
              <br />
              <a href="https://i.snoball.local/share/c/V5p" target="_blank" rel="noopener">View an example</a>
            </div>
          </div>
        </div>
        <div class="row">
          <div class='col-md-11 mb-3 mx-auto'>
            <div class="d-flex align-items-center">
              <small class="text-capitalize">Third-party Event/Project ID(This will be used to find the related project on Snöball)</small>
            </div>
            <div class='input-group input-group-sm variable-input'>
              <input class="form-control" type='text' id="third_party_project_id" [(ngModel)]="third_party_project_id" [value]="project?.third_party_project_id" />
            </div>
          </div>
        </div>
        <fieldset>
          <legend>Activate</legend>
          <div class='row'>
            <div class='col-md-11 mx-auto customize-email'>
              <input id="customizePage" placeholder="" type="checkbox"
                     [(ngModel)]="projectMeta['active']">
              <label for="customizePage">Widget Active</label>
            </div>
          </div>
          <div class='row'>
            <div class='col-md-11 mb-3 mx-auto'>
              <div class="d-flex align-items-center">
                <small class="text-capitalize">Linked Campaign: New registrations will be added to the following campaign (Mandatory)</small>
              </div>
              <div class="client-dropdown">
                <app-creatable-select
                  [isDisabled]="!projectMeta['active']"
                  [selectedValue]="projectMeta['default_new_register_campaign_id']"
                  [hideLabel]="true" [isCreatable]="false" [onlyBottomBorder]="false"
                  [placeholder]="'Select Default Campaign'" [selectOptions]="campaigns"
                  (optionSelected)="projectMeta['default_new_register_campaign_id'] = ($event ? $event.value: null)"></app-creatable-select>
              </div>
            </div>
          </div>
          <div class='row'>
            <div class='col-md-11 mb-3 mx-auto'>
              <div class="d-flex align-items-center">
                <small class="text-capitalize">Linked Conditional Campaign: This can be used for new registrations in special cases defined in custom script section (Ignore if you don't know what this is)</small>
              </div>
              <div class="client-dropdown">
                <app-creatable-select
                  [isDisabled]="!projectMeta['active']"
                  [selectedValue]="projectMeta['conditional_new_register_campaign_id']"
                  [hideLabel]="true" [isCreatable]="false" [onlyBottomBorder]="false"
                  [placeholder]="'Select Conditional Campaign'" [selectOptions]="campaigns"
                  (optionSelected)="projectMeta['conditional_new_register_campaign_id'] = ($event ? $event.value: null)"></app-creatable-select>
              </div>
            </div>
          </div>
          <div class='row'>
            <div class='col-md-11 mb-3 mx-auto'>
              <div class="row">
                <div class="client-dropdown" [ngClass]="isAdmin ? 'col-ld-10 col-md-10' : 'col-ld-12 col-md-12'">
                  <small class="text-capitalize">Registration Platform (We already support several different registration platforms, if you find yours and select it here it makes the integration much easier)</small>
                  <app-creatable-select
                    [isDisabled]="!projectMeta['active']"
                    [labelKey]="'platform_name_with_support'"
                    [selectedValue]="projectMeta['registration_platform_id']"
                    [hideLabel]="true" [isCreatable]="false" [onlyBottomBorder]="false"
                    [placeholder]="'Select Registration Platforms'" [selectOptions]="registrationPlatforms"
                    (optionSelected)=onSelectRegistrationPlatform($event)
                  ></app-creatable-select>
                </div>
                <div *ngIf="this.isAdmin" class="col-lg-2 col-md-2">
                  <button class="btn btn-sm btn-primary" type="button" (click)="showAddPlatformPopup(addNewPlatform)" role="button">
                    <span class="btn-wrapper--icon"><i class="fa fa-plus"></i></span>
                    <span class="btn-wrapper--label">Add New Platform</span>
                  </button>
                </div>
              </div>
            </div>
          </div>
          <div class="row">
            <div class='col-md-11 mb-3 mx-auto'>
              <div class="d-flex align-items-center">
                <small class="text-capitalize">Note (Can include info about special consideration regarding the widget or its integration like for example PromoCodes for testing, where it might have been added in your site, etc)</small>
              </div>
              <div class='input-group input-group-sm variable-textarea'>
                <div [attr.data-replicated-value]="projectMeta['note']" class="grow-wrap" style="width: 100%">
                <textarea onInput="this.parentNode.dataset.replicatedValue = this.value" class="form-control" rows="6" [(ngModel)]="projectMeta['note']"
                          [disabled]="!projectMeta['active']">{{projectMeta['note']}}</textarea>
                </div>
              </div>
            </div>
          </div>
          <div class="row">
            <div class='col-md-11 mb-3 mx-auto'>
              <div class="d-flex align-items-center">
                <small class="text-capitalize">Project Code (This is mostly useful when the widget is already integrated in your site in a generic way and all you need is to just add the project code somewhere in your platform's setting)</small>
              </div>
              <div class='input-group input-group-sm project-code'>
                <input class="form-control code" id="project_code" [value]="project?.code" [readOnly]="true"/>
                <span class="copy-clipboard"><i (click)="copyProjectCode(project?.code)" class="fa fa-clone"></i></span>
              </div>
            </div>
          </div>
          <div class='row mb-2'>
            <div class='col-md-11 mx-auto'>
              <div class="d-flex align-items-center">
                <small class="text-capitalize">Now that you've set the settings, have a look at the detailed generated instruction on how to add the widget.</small>
              </div>
              <a class="" style="color: #4BB7E3; cursor: pointer"
                 (click)="showWidgetInstructions(widgetInstructions)"><i class="fa fa-question-circle"></i> Widget
                integration instructions</a>
            </div>
          </div>
        </fieldset>

        <fieldset>
          <legend>Influencer Widget Avatar</legend>
          <div class='row'>
            <div class='col-md-11 mb-3 mx-auto'>
              <div class="d-flex align-items-center">
                <small class="text-capitalize">A picture button will be displayed on the share widget for the campaign that's linked to the widget. Influencer will be able to update their picture through a popup and see the result on the share image.</small>
              </div>
              <div class='input-group input-group-sm customize-email'>
                <input id="allow_influencers_to_update_their_picture_in_widget" placeholder="" type="checkbox" value="true"
                       [(ngModel)]="projectMeta['allow_influencers_to_update_their_picture_in_widget']" [disabled]="!projectMeta['active']">
                <label for="allow_influencers_to_update_their_picture_in_widget" style="margin: 0 0 0 5px;">Allow influencers to update their picture</label>
              </div>
            </div>
            <div class='col-md-11 mb-3 mx-auto'>
              <div class="d-flex align-items-center">
                <small class="text-capitalize">When uploading their picture through widget upload picture, users will also have the option to use Avatar AI to style their picture and use that as their picture instead</small>
              </div>
              <div class='input-group input-group-sm customize-email'>
                <input id="allow_influencers_to_use_ai_avatar_in_widget" placeholder="" type="checkbox" value="true"
                       [(ngModel)]="projectMeta['allow_influencers_to_use_ai_avatar_in_widget']" [disabled]="!projectMeta['allow_influencers_to_update_their_picture_in_widget'] || !projectMeta['active']">
                <label for="allow_influencers_to_use_ai_avatar_in_widget" style="margin: 0 0 0 5px;">Allow influencers to use AI to generate their Picture/Avatar</label>
              </div>
            </div>
            <div class='col-md-11 mb-3 mx-auto'>
              <div class="col-md-6 col-sm-12 p-0">
                <ul class="avatar-template-menu-items p-0">
                  <li class="dropdown avatar-template-dropdown d-block text-decoration-none"
                      [ngClass]="{'disable-dropdown': !projectMeta['allow_influencers_to_use_ai_avatar_in_widget'] || (widgetAIAvatarTemplates.length === 0) || !projectMeta['active']}">
                    <div class="dropdown-toggle d-flex align-items-center p-3" data-toggle="dropdown" role="button" aria-haspopup="true" aria-expanded="false">
                      <div class="d-flex align-items-center w-100" *ngIf="projectMeta['widget_ai_avatar_template'] && selectedWidgetAIAvatarTemplate; else noWidgetAITemplate">
                        <div class="avatar-image mr-2">
                          <img class="h-100 img-fluid" [src]="selectedWidgetAIAvatarTemplate?.selectedImageVariation?.url" />
                        </div>
                        <span class="text-wrap">{{selectedWidgetAIAvatarTemplate?.name}}</span>
                      </div>
                      <ng-template #noWidgetAITemplate>
                        <span>Select Widget AI Avatar Template</span>
                      </ng-template>
                    </div>
                    <div class="dropdown-menu avatar-template-list-dropdown w-100">
                      <div class="avatar-template-list-option">
                        <div *ngFor="let template of widgetAIAvatarTemplates" class="dropdown-item">
                          <div class="image-wrapper d-flex align-items-center pointer" (click)="selectWidgetAIAvatarTemplate(template)">
                            <div class="avatar-image mr-2">
                              <img class="h-100 img-fluid" [src]="template?.selectedImageVariation?.url" alt="{{template?.name}}" title="{{template?.name}}" />
                            </div>
                            <span class="text-wrap">{{template?.name}}</span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </li>
                </ul>
              </div>
              <button class="btn customize-btn" type="button" role="button" (click)="showCustomizeAvatarTemplatePopup()" [disabled]="!projectMeta['active']">
                <span class="btn-wrapper--icon"><i class="fa fa-picture-o" aria-hidden="true"></i></span>
                <span class="btn-wrapper--label">Customize</span>
              </button>
            </div>
          </div>
        </fieldset>
        <fieldset>
          <legend>Test</legend>
          <div class='row'>
            <div class='col-md-11 mb-3 mx-auto'>
              <div class="text-info">While you're adding the widget to your site, you may want to hide it from your users.</div>
            </div>
          </div>
          <div class="row">
            <div class='col-md-11 mb-3 mx-auto'>
              <div class='input-group input-group-sm'>
                <input id="limit_access" placeholder="" type="checkbox"
                       [(ngModel)]="projectMeta['whitelist_access']" [disabled]="!projectMeta['active']" (change)="changeCheckBox($event)">
                <label for="limit_access" style="margin-left: 5px; margin-top: 0; margin-right: 8px; margin-bottom: 0">Limit access for testing purpose</label>
              </div>
            </div>
          </div>
          <div class="row">
            <div class='col-md-11 mb-3 mx-auto'>
              <div class="d-flex align-items-center">
                <small class="text-capitalize">Opening the following URL adds your IP to the whitelist, this way the widget will only show up on your site when you're visiting</small>
              </div>
              <div class='input-group input-group-sm'>
                <input id="autoAddId" placeholder="" type="checkbox"
                       [(ngModel)]="projectMeta['auto_add_id']" [disabled]="!projectMeta['active'] || !projectMeta['whitelist_access']">
                <label for="autoAddId" style="margin-left: 5px;margin-top: 8px;margin-right: 8px;">Enable Adding IP By Opening This URL: </label>
                <input class="form-control" [value]="ipLimitUrl" [readOnly]="true" style="margin-top: 5px;"/>
              </div>
            </div>
          </div>

          <div class="row">
            <div class='col-md-11 mb-3 mx-auto'>
              <div class="d-flex align-items-center">
                <small class="text-capitalize">Whitelisted IPs, only people coming from the following IPs can see the widget. clear it to let everyone see the widget (Separate by enter)</small>
              </div>
              <div class='input-group input-group-sm variable-textarea'>
                <textarea class="form-control" placeholder="34.54.54.66&#10;44.33.123.45" rows="5" [(ngModel)]="projectMeta['limit_access_ips']"
                          [disabled]="!projectMeta['active'] || !projectMeta['whitelist_access']">{{projectMeta['limit_access_ips']}}</textarea>
              </div>
            </div>
          </div>
        </fieldset>
        <fieldset>
          <legend>Visual</legend>
          <div class='row'>
            <div class='col-md-11 mb-3 mx-auto'>
              <div class="text-info">Customize widget's look and feel. If you want to see the result you can just click on the Save button at the buttom and then click on Preview</div>
            </div>
          </div>
          <div class='row' style="display: none">
            <div class='col-md-11 mx-auto customize-email'>
              <input id="enable_linkedin_integration_before_registration" placeholder="" type="checkbox"
                     [disabled]="!projectMeta['active']"
                     [(ngModel)]="projectMeta['enable_linkedin_integration_before_registration']">
              <label for="enable_linkedin_integration_before_registration">Enable Linkedin Integration before registration</label>
            </div>
          </div>
          <div class="row">
            <div class='col-md-11 mb-3 mx-auto'>
              <div class="d-flex align-items-center">
                <small class="text-capitalize">Language</small>
              </div>
              <div class='input-group input-group-sm variable-input'>
                <select id="language" [(ngModel)]="projectMeta['language']" [disabled]="!projectMeta['active']" [value]="projectMeta['language']">
                  <option value="en-us" >English - United States</option>
                  <option value="en-gb" >English - Great Britain</option>
                  <!--<option value="fr-fr" >French - France</option>-->
                  <option value="th" >Thai</option>
                  <option value="vi" >Vietnamese</option>
                  <option value="zh-cn" >Chinese - Simplified</option>
                  <!--<option value="ar-sa" >Arabic - Saudi Arabia</option>-->
                </select>
              </div>
            </div>
          </div>
          <div class="row">
            <div class='col-md-11 mb-3 mx-auto'>
              <div class="d-flex align-items-center">
                <small class="text-capitalize">Header 1</small>
              </div>
              <div class='input-group input-group-sm variable-input'>
                <input class="form-control" type='text' [disabled]="!projectMeta['active']"
                       [(ngModel)]="projectMeta['header_1']" />
                <div class="inputs">
                  <app-variables
                    [isDisabled]="!projectMeta['active']"
                    [variables]="variables"
                    (onVariableClick)="updateValue($event, 'header_1')"
                  ></app-variables>
                  <app-file-browser [clientId]="project?.client_id" [projectId]="project?.id"></app-file-browser>
                </div>
              </div>
            </div>
          </div>
          <div class="row">
            <div class='col-md-11 mb-3 mx-auto'>
              <div class="d-flex align-items-center">
                <small class="text-capitalize">Header 2</small>
              </div>
              <div class='input-group input-group-sm variable-input'>
                <input class="form-control" type='text' [disabled]="!projectMeta['active']"
                       [(ngModel)]="projectMeta['header_2']"  placeholder="Invite someone!"/>
                <div class="inputs">
                  <app-variables
                    [isDisabled]="!projectMeta['active']"
                    [variables]="variables"
                    (onVariableClick)="updateValue($event, 'header_2')"
                  ></app-variables>
                  <app-file-browser [clientId]="project?.client_id" [projectId]="project?.id"></app-file-browser>
                </div>
              </div>
            </div>
          </div>
          <div class="row">
            <div class='col-md-11 mb-3 mx-auto'>
              <div class="d-flex align-items-center">
                <small class="text-capitalize">Header 3</small>
              </div>
              <div class='input-group input-group-sm variable-input'>
                <input class="form-control" type='text' [disabled]="!projectMeta['active']"
                       [(ngModel)]="projectMeta['header_3']"  placeholder="Choose where to share with your community"/>
                <div class="inputs">
                  <app-variables
                    [isDisabled]="!projectMeta['active']"
                    [variables]="variables"
                    (onVariableClick)="updateValue($event, 'header_3')"
                  ></app-variables>
                  <app-file-browser [clientId]="project?.client_id" [projectId]="project?.id"></app-file-browser>
                </div>
              </div>
            </div>
          </div>
          <div class='row'>
            <div class='col-md-11 mx-auto customize-email'>
              <input id="minimized_notification_message_disabled" placeholder="" type="checkbox"
                     [(ngModel)]="projectMeta['minimized_notification_message_disabled']" [disabled]="!projectMeta['active']">
              <label for="minimized_notification_message_disabled">Disable Minimized Notification Message</label>
            </div>
          </div>
          <div class='row'>
            <div class='col-md-11 mx-auto customize-email'>
              <input id="hide_when_closed" placeholder="" type="checkbox"
                     [(ngModel)]="projectMeta['hide_when_closed']" [disabled]="!projectMeta['active']">
              <label for="hide_when_closed">Hide when closed</label>
            </div>
          </div>
          <div class="row">
            <div class='col-md-11 mb-3 mx-auto'>
              <div class="d-flex align-items-center">
                <small class="text-capitalize">Custom Minimized Notification Message</small>
              </div>
              <div class='input-group input-group-sm variable-input'>
                <input class="form-control" type='text' [disabled]="!projectMeta['active']"
                       [(ngModel)]="projectMeta['minimized_notification_message']" placeholder="Click/Touch to easily invite others"/>
              </div>
            </div>
          </div>
          <div class='row'>
            <div class='col-md-11 mx-auto customize-email'>
              <input id="user_agreement_checkbox_disabled" placeholder="" type="checkbox" value="true"
                     [(ngModel)]="projectMeta['user_agreement_checkbox_disabled']" [disabled]="!projectMeta['active']">
              <label for="user_agreement_checkbox_disabled">Disable User Agreement Checkbox</label>
            </div>
          </div>
          <div class='row'>
            <div class='col-md-11 mx-auto customize-email'>
              <input id="show_preload" placeholder="" type="checkbox" value="true"
                     [(ngModel)]="projectMeta['show_preload']" [disabled]="!projectMeta['active']">
              <label for="show_preload">Show Preload</label>
            </div>
          </div>
          <div class="row">
            <div class='col-md-11 mb-3 mx-auto'>
              <div class="d-flex align-items-center">
                <small class="text-capitalize">Display Mode</small>
              </div>
              <div class='input-group input-group-sm variable-input'>
                <select id="display_mode" [(ngModel)]="projectMeta['display_mode']" [disabled]="!projectMeta['active']" [value]="projectMeta['display_mode']">
                  <option value="popup">Popup</option>
                  <option value="popup_minimized">Minimized Popup</option>
                  <option value="embedded">Embedded</option>
                  <option value="hidden">Hidden</option>
                </select>
              </div>
            </div>
          </div>
          <div class="row">
            <div class='col-md-11 mb-3 mx-auto'>
              <div class="d-flex align-items-center">
                <small class="text-capitalize">Linkedin Share Method</small>
              </div>
              <div class='input-group input-group-sm variable-input'>
                <select id="linkedin_share_method" [(ngModel)]="projectMeta['linkedin_share_method']" [disabled]="!projectMeta['active']" [value]="projectMeta['linkedin_share_method'] || 'basic'">
                  <option value="basic">Basic</option>
                  <option value="authenticated">Authenticated</option>
                </select>
              </div>
            </div>
          </div>
          <div class="row">
            <div class='col-md-11 mb-3 mx-auto'>
              <div class="d-flex align-items-center">
                <small class="text-capitalize">Default Texts Color</small>
              </div>
              <div class='input-group input-group-sm variable-input custom-color-picker'>
                <input class="form-control" type='text' [disabled]="!projectMeta['active']" [value]="projectMeta['theme_color_1']"
                       [(ngModel)]="projectMeta['theme_color_1']" (change)="projectMeta['theme_color_1'] = $event.target.value" placeholder="#333399" appColorPicker/>
              </div>
            </div>
          </div>
          <div class="row">
            <div class='col-md-11 mb-3 mx-auto'>
              <div class="d-flex align-items-center">
                <small class="text-capitalize">Background Color</small>
              </div>
              <div class='input-group input-group-sm variable-input custom-color-picker'>
                <input class="form-control" type='text' [disabled]="!projectMeta['active']" [value]="projectMeta['theme_color_2']"
                       [(ngModel)]="projectMeta['theme_color_2']" (change)="projectMeta['theme_color_2'] = $event.target.value" placeholder="#FFFFFF" appColorPicker/>
              </div>
            </div>
          </div>
          <div class="row">
            <div class='col-md-11 mb-3 mx-auto'>
              <div class="d-flex align-items-center">
                <small class="text-capitalize">Icons Color</small>
              </div>
              <div class='input-group input-group-sm variable-input custom-color-picker'>
                <input class="form-control" type='text' [disabled]="!projectMeta['active']" [value]="projectMeta['theme_color_3']"
                       [(ngModel)]="projectMeta['theme_color_3']" (change)="projectMeta['theme_color_3'] = $event.target.value" placeholder="#333399" appColorPicker/>
              </div>
            </div>
          </div>
          <div class="row">
            <div class='col-md-11 mb-3 mx-auto'>
              <div class="d-flex align-items-center">
                <small class="text-capitalize">Icons background color</small>
              </div>
              <div class='input-group input-group-sm variable-input custom-color-picker'>
                <input class="form-control" type='text' [disabled]="!projectMeta['active']" [value]="projectMeta['theme_color_4']"
                       [(ngModel)]="projectMeta['theme_color_4']" (change)="projectMeta['theme_color_4'] = $event.target.value" placeholder="#e1e2e7" appColorPicker/>
              </div>
            </div>
          </div>
          <div class="row">
            <div class='col-md-11 mb-3 mx-auto'>
              <div class="d-flex align-items-center">
                <small class="text-capitalize">Close Window Icon Color</small>
              </div>
              <div class='input-group input-group-sm variable-input custom-color-picker'>
                <input class="form-control" type='text' [disabled]="!projectMeta['active']" [value]="projectMeta['theme_color_5']"
                       [(ngModel)]="projectMeta['theme_color_5']" (change)="projectMeta['theme_color_5'] = $event.target.value" placeholder="#8a8a8a" appColorPicker/>
              </div>
            </div>
          </div>
          <div class="row">
            <div class='col-md-11 mb-3 mx-auto'>
              <div class="d-flex align-items-center">
                <small class="text-capitalize">Textarea Border color</small>
              </div>
              <div class='input-group input-group-sm variable-input custom-color-picker'>
                <input class="form-control" type='text' [disabled]="!projectMeta['active']" [value]="projectMeta['theme_color_6']"
                       [(ngModel)]="projectMeta['theme_color_6']" (change)="projectMeta['theme_color_6'] = $event.target.value" placeholder="#cacaca" appColorPicker/>
              </div>
            </div>
          </div>
          <div class="row">
            <div class='col-md-11 mb-3 mx-auto'>
              <div class="d-flex align-items-center">
                <small class="text-capitalize">Textarea text color</small>
              </div>
              <div class='input-group input-group-sm variable-input custom-color-picker'>
                <input class="form-control" type='text' [disabled]="!projectMeta['active']" [value]="projectMeta['theme_color_7']"
                       [(ngModel)]="projectMeta['theme_color_7']" (change)="projectMeta['theme_color_7'] = $event.target.value" placeholder="#000000" appColorPicker/>
              </div>
            </div>
          </div>
          <div class="row">
            <div class='col-md-11 mb-3 mx-auto'>
              <div class="d-flex align-items-center">
                <small class="text-capitalize">Textarea Background Color</small>
              </div>
              <div class='input-group input-group-sm variable-input custom-color-picker'>
                <input class="form-control" type='text' [disabled]="!projectMeta['active']" [value]="projectMeta['theme_color_8']"
                       [(ngModel)]="projectMeta['theme_color_8']" (change)="projectMeta['theme_color_8'] = $event.target.value" placeholder="#ffffff" appColorPicker/>
              </div>
            </div>
            <div class='col-md-11 mb-3 mx-auto'>
              <div class="d-flex align-items-center">
                <small class="text-capitalize">Minimized Text Color</small>
              </div>
              <div class='input-group input-group-sm variable-input custom-color-picker'>
                <input class="form-control" type='text' [disabled]="!projectMeta['active']" [value]="projectMeta['theme_color_9']"
                       [(ngModel)]="projectMeta['theme_color_9']" (change)="projectMeta['theme_color_9'] = $event.target.value" placeholder="#ffffff" appColorPicker/>
              </div>
            </div>
            <div class='col-md-11 mb-3 mx-auto'>
              <div class="d-flex align-items-center">
                <small class="text-capitalize">Minimized Background Color</small>
              </div>
              <div class='input-group input-group-sm variable-input custom-color-picker'>
                <input class="form-control" type='text' [disabled]="!projectMeta['active']" [value]="projectMeta['theme_color_10']"
                       [(ngModel)]="projectMeta['theme_color_10']" (change)="projectMeta['theme_color_10'] = $event.target.value" placeholder="#333399" appColorPicker/>
              </div>
            </div>
            <div class='col-md-11 mb-3 mx-auto'>
              <div class="d-flex align-items-center">
                <small class="text-capitalize">Minimized Border Color</small>
              </div>
              <div class='input-group input-group-sm variable-input custom-color-picker'>
                <input class="form-control" type='text' [disabled]="!projectMeta['active']" [value]="projectMeta['theme_color_11']"
                       [(ngModel)]="projectMeta['theme_color_11']" (change)="projectMeta['theme_color_11'] = $event.target.value" placeholder="#e1e2e7" appColorPicker/>
              </div>
            </div>
          </div>
          <!--
          <div class='row'>
            <div class='col-md-11 mb-3 mx-auto'>
              <label for='font_family'><small>Font</small></label>
              <div class='input-group input-group-sm'>
                <input value="{{projectMeta['font_family'] || ''}}" id="font_family" appFontPicker
                       class='form-control' (change)="projectMeta['font_family'] = $event.target.value"
                       type='text' />
              </div>
            </div>
          </div>
          -->
          <div class='row'>
            <div class='col-md-11 mb-3 mx-auto'>
              <label for="fontface"><small>Custom FontFace</small></label>
              <div class='input-group input-group-sm'>
                <textarea id="fontface" class='form-control' placeholder="@font-face {
  font-family: myFirstFont;
  src: url(https://example.com/sansation_light.woff);
}" (change)="projectMeta['fontface'] = $event.target.value"
                       data-field='fontface' data-unit="px"
                          value="{{projectMeta['fontface'] || ''}}" rows="8"></textarea>
              </div>
            </div>
          </div>
          <div class='row'>
            <div class='col-md-11 mb-3 mx-auto'>
              <label for="font_family"><small>Font Family</small></label>
              <div class='input-group input-group-sm'>
                <input id="font_family" style="height: 36px;" class='form-control' (change)="projectMeta['font_family'] = $event.target.value"
                       data-field='font_size' data-unit="px" placeholder="'myFirstFont', 'omnes', Arial, 'Helvetica Neue', Helvetica, sans-serif"
                       value="{{projectMeta['font_family'] || ''}}"/>
              </div>
            </div>
          </div>
          <div class='row'>
            <div class='col-md-11 mb-3 mx-auto'>
              <label for="font_size"><small>Font Size</small></label>
              <div class='input-group input-group-sm'>
                <input id="font_size" style="height: 36px;" class='form-control' type='number' placeholder="1" (change)="projectMeta['font_size'] = $event.target.value"
                       data-field='font_size' data-unit="px"
                       value="{{projectMeta['font_size'] || ''}}"/>
                <div class='input-group-append'>
                  <span class='input-group-text'>px</span>
                </div>
              </div>
            </div>
          </div>
          <div class='row'>
            <div class='col-md-11 mx-auto customize-email'>
              <input id="show_widget_ui_customization_on_all_widgets" placeholder="" type="checkbox" value="true"
                     [(ngModel)]="projectMeta['show_widget_ui_customization_on_all_widgets']" [disabled]="!projectMeta['active']">
              <label for="show_preload">Show widget UI customization on all widgets</label>
            </div>
          </div>
        </fieldset>
        <fieldset>
          <legend>Advanced</legend>
          <div class='row'>
            <div class='col-md-11 mb-3 mx-auto'>
              <div class="text-info">This is for more advanced customization and integration. Such as when the widget doesn't work or your site needs special customization for the widget to work</div>
            </div>
          </div>
          <div class='row'>
            <div class='col-md-11 mx-auto customize-email'>
              <input id="remoteLog" placeholder="" type="checkbox"
                     [(ngModel)]="projectMeta['remote_log_disabled']" [disabled]="!projectMeta['active']">
              <label for="remoteLog">Disable Remote Log</label>
            </div>
          </div>
          <div class='row'>
            <div class='col-md-11 mx-auto customize-email'>
              <input id="debug" placeholder="" type="checkbox"
                     [(ngModel)]="projectMeta['debug']" [disabled]="!projectMeta['active']">
              <label for="debug">Debug</label>
            </div>
          </div>
          <div class="row">
            <div *ngIf="isAdmin && widgetCustomizationCode && this.projectMeta['registration_platform_id']" class='col-md-11 mb-3 mx-auto'>
              <div class="d-flex align-items-center">
                <small class="text-capitalize">Platform's Default Script</small>
              </div>
              <div class='input-group input-group-sm variable-input'>
                <textarea class="form-control auto-grow-textarea"
                          id="widget_code" placeholder="" type="text" readonly="readonly" rows="10">{{widgetCustomizationCode}}
                </textarea>
              </div>
            </div>
          </div>
          <div class='row'>
            <div class='col-md-11 mx-auto customize-email'>
              <input id="registration_platform_default_custom_js" placeholder="" type="checkbox"
                     [(ngModel)]="projectMeta['registration_platform_default_custom_js']" [disabled]="!projectMeta['active']">
              <label for="registration_platform_default_custom_js">Use registration platform's default script</label>
            </div>
          </div>
          <div class="row">
            <div class='col-md-11 mb-3 mx-auto'>
              <div class="d-flex align-items-center">
                <small class="text-capitalize">Custom Script</small>

                <a class="email-verification-button ml-1" style="color: #4BB7E3; cursor: pointer"
                 (click)="showCustomScriptInstructions(widgetCustomScriptInstructions)">
                  <i class="fa fa-question-circle"></i>
                  View Guide
                </a>
              </div>
              <div class='input-group input-group-sm'>
                <ace-editor #editor style="min-height: 200px;"
                            [autoUpdateContent]="true"
                            [mode]="'javascript'"
                            [options]="aceEditorOptions"
                            [(text)]="projectMeta['custom_script']"
                            [readOnly]="!projectMeta['active']">
                </ace-editor>
              </div>
            </div>
          </div>
          <div class="row">
            <div *ngIf="isAdmin && widgetCustomizationCss && this.projectMeta['registration_platform_id']" class='col-md-11 mb-3 mx-auto'>
              <div class="d-flex align-items-center">
                <small class="text-capitalize">Platform's Default Css</small>
              </div>
              <div class='input-group input-group-sm variable-input'>
                <textarea class="form-control auto-grow-textarea"
                          id="widget_css" placeholder="" type="text" readonly="readonly" rows="10">{{widgetCustomizationCss}}
                </textarea>
              </div>
            </div>
          </div>
          <div class='row'>
            <div class='col-md-11 mx-auto customize-email'>
              <input id="registration_platform_default_custom_css" placeholder="" type="checkbox"
                     [(ngModel)]="projectMeta['registration_platform_default_custom_css']" [disabled]="!projectMeta['active']">
              <label for="registration_platform_default_custom_css">Use registration platform's default CSS</label>
            </div>
          </div>
          <div class="row">
            <div class='col-md-11 mb-3 mx-auto'>
              <div class="d-flex align-items-center">
                <small class="text-capitalize">Custom CSS</small>
              </div>
              <div class='input-group input-group-sm variable-textarea'>
                <div [attr.data-replicated-value]="projectMeta['custom_css']" class="grow-wrap" style="width: 100%">
                <textarea onInput="this.parentNode.dataset.replicatedValue = this.value" class="form-control" rows="10" [(ngModel)]="projectMeta['custom_css']"
                          [disabled]="!projectMeta['active']">{{projectMeta['custom_css']}}</textarea>
                </div>
              </div>
            </div>
          </div>
        </fieldset>
        <div class='row justify-content-center'>
          <div class='customize-email text-center mr-2'>
            <button class="btn btn-primary" type="button" (click)="shareWidgetPreview()">
              Save
            </button>
          </div>
          <div class='customize-email text-center'>
            <button class="btn btn-default" [disabled]="!projectMeta['active']" type="button" (click)="previewWidget()">
              Preview
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<ng-template #widgetInstructions let-modal>
  <div class="modal-header">
    <h4 class="modal-title d-flex align-items-center" id="modal-title">
      Widget integration instructions
      <span class="share-link ml-2">
    <a [href]="shareWidgetLinkUrl" target="_blank"><i class="fa fa-share"></i></a>
  </span>
    </h4>
    <button type="button" class="close" aria-label="Close button" aria-describedby="modal-title"
            (click)="modal.dismiss('cancel')">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
<!--    <div *ngIf="widgetIntegrationBody" [innerHTML]="widgetIntegrationBody">-->
<!--    </div>-->
    <iframe class="widget-integration-frame"
      frameborder="0" width="100%" height="auto"
      *ngIf="widgetIntegrationUrl"
      [src]="widgetIntegrationUrl">
    </iframe>
  </div>
  <input type="hidden" ngbAutofocus>
  <div class="modal-footer">
    <button type="button" class="btn btn-default" (click)="modal.close()">Close</button>
  </div>
</ng-template>

<ng-template #widgetCustomScriptInstructions let-modal>
  <div class="modal-header">
    <h4 class="modal-title" id="modal-title">Custom Script instructions</h4>
    <button type="button" class="close" aria-label="Close button" aria-describedby="modal-title"
            (click)="modal.dismiss('cancel')">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <div *ngIf="widgetCustomScriptBody" [innerHTML]="widgetCustomScriptBody">
    </div>
  </div>
  <input type="hidden" ngbAutofocus>
  <div class="modal-footer">
    <button type="button" class="btn btn-default" (click)="modal.close()">Close</button>
  </div>
</ng-template>
<ng-template #addNewPlatform let-modal>
  <div class="modal-header">
    <h4 class="modal-title d-flex align-items-center">
      Add Registration Platform
      <span class="share-link ml-2">
  </span>
    </h4>
    <button type="button" class="close" aria-label="Close button" aria-describedby="modal-title"
            (click)="modal.dismiss('cancel')">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <div class="form-wraper">
      <form (ngSubmit)="saveRegistrationPlatform()" [formGroup]="addRegistrationPlatformForm" autocomplete="off">
        <div class="row  align-items-center">
          <div class="col-lg-4 ">
          <div class="floating-label floating-code-label registration-img">
            <img [src]="imageBinary" *ngIf="imageBinary" class="img-fluid d-block m-auto"
                 id="event-logo-preview"/>
            <div class='d-flex justify-content-center align-items-center'>
              <button (click)="uploadLogo(fileInput)" [disabled]="submitted && fileUploadProgress > 0 && fileUploadProgress < 100" class="btn btn-default" id="logoUploaderBtn" type="button">
                <li class="fa fa-upload"></li>
                {{fileUploadProgress ? fileUploadProgress + '%' : ''}} {{imageBinary ? 'Replace' : 'Upload'}} Logo
              </button>
              <app-info-popover
                info="Acceptable formats are jpeg and png. (Min  800x800px, max 2048x2048px)">
              </app-info-popover>
              <input #fileInput (change)="onEventImageChanged($event);" accept="image/*"
                     class="file-input" id="projectLogo" type="file">
            </div>
          </div>
        </div>
          <div class="col-lg-8 col-md-12 col-ms-12">
            <div class="row">
              <div class="col-md-6">
                <div class="floating-label">
                  <input formControlName="name" placeholder=" "
                         required="required" class="floating-input"
                         type="text" autocomplete="off"/>
                  <label>Name <span class="red">*</span>
                  </label>
                  <div *ngIf="submitted && f.name.errors" class="bar error-brd">
                    <div *ngIf="f.name.errors.required" class="invalid-text">Platform name is required</div>
                  </div>

                </div>
              </div>
              <div class="col-md-6">
                <div class="floating-label">
                  <input formControlName="website" placeholder=" "
                         required="required" class="floating-input"
                         type="text" autocomplete="off"/>
                  <label>Website <span class="red">*</span>
                  </label>
                  <div *ngIf="submitted && f.website.errors" class="bar error-brd">
                    <div *ngIf="f.website.errors.required" class="invalid-text">website name is required</div>
                    <div *ngIf="f.website.errors !== null && !f.website.errors.required" class="invalid-text">Please enter valid Website name</div>
                  </div>
                </div>
              </div>
              <div class="col-md-6">
                <app-creatable-select
                  [hideLabel]="true" [isCreatable]="false" [onlyBottomBorder]="false"
                  [placeholder]="'Select Widget Support'" [selectOptions]="widgetSupportValues"
                  [selectedValue]="selectedWidgetSupport"
                  (optionSelected)="setWidgetSupport($event)"
                ></app-creatable-select>
                <!--                    <label class="widget-integration-css">Widget Support</label>-->
              </div>
              <div class="col-md-6">
                <app-creatable-select
                  [hideLabel]="true" [isCreatable]="false" [onlyBottomBorder]="false"
                  [placeholder]="'Select Widget Support'" [selectOptions]="syncSupportValues"
                  [selectedValue]="selectedSyncSupport"
                  (optionSelected)="setSyncSupport($event)"
                ></app-creatable-select>
                <!--                    <label class="widget-integration-css">Sync Support</label>-->
              </div>
            </div>
          </div>

          <div *ngIf="!isLoadEditor" class="col-lg-12 col-md-12 col-ms-12 spinner-container">
            <i class="fa fa-spinner fa-spin"></i>
          </div>
          <div [ngClass]="{'d-none': !isLoadEditor}" class="col-lg-12 col-md-12 col-ms-12">
            <label class="widget-integration-css">Widget Integration Guide</label>
            <textarea  cols="80" id="editor1" name="froalaEditor" placeholder=" "></textarea>
          </div>
          <div [ngClass]="{'d-none': !isLoadEditor}" class="col-lg-12 col-md-12 col-ms-12 mt-3">
            <input placeholder="" type="checkbox" class='mr-2' (change)="onChangeCheckbox($event, 'hide_default_guide')" name='hideGuide'>
            <label class="widget-integration-css">Hide Default Guide</label>
          </div>
          <div class="col-lg-12">
            <div class="floating-label floating-code-label">
                  <textarea formControlName="widget_customization_code" class="form-control floating-input auto-grow-textarea"
                            placeholder=" " type="text" rows="8">
                  </textarea>
              <label>Widget Customization Code</label>
            </div>
          </div>

          <div class="col-lg-12">
            <div class="floating-label floating-code-label">
                  <textarea formControlName="widget_customization_css" class="form-control floating-input auto-grow-textarea"
                            placeholder=" " type="text" rows="8">
                  </textarea>
              <label>Widget Customization Css</label>
            </div>
          </div>
        </div>
        <div _ngcontent-c4="" class="text-right mt-3">
          <button _ngcontent-c4="" class="btn btn-default mr-2" type="button" (click)="modal.close()">Cancel</button>
          <button _ngcontent-c4="" class="btn btn-primary mr-2" type="submit">Save</button>
        </div>
      </form>
    </div>
  </div>
  <input type="hidden" ngbAutofocus>
</ng-template>
