import { Component, Input, OnInit } from '@angular/core';
import { UntypedFormGroup, UntypedFormBuilder, UntypedFormControl, Validators } from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-rename-title-modal',
  templateUrl: './rename-title-modal.component.html',
  styleUrls: ['./rename-title-modal.component.scss'],
})
export class RenameTitleModalComponent implements OnInit {
  @Input() public title: string = '';
  @Input() public value: string = '';

  public form!: UntypedFormGroup;

  constructor(private readonly fb: UntypedFormBuilder, public modal: NgbActiveModal) {}

  ngOnInit(): void {
    //
    // Create form
    this.createForm();
  }

  close() {
    this.modal.dismiss('cancel');
  }

  createForm() {
    //
    // Hold fields
    const fields: { [propName: string]: UntypedFormControl | UntypedFormGroup | any } = {};

    //
    // Set fields
    fields.value = this.fb.control(this.value, [Validators.required]);
    // set form
    this.form = this.fb.group(fields);
  }

  async submit() {
    this.modal.close({
      value: this.form.get('value').value,
    });
  }
}
