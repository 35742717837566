import {AfterViewInit, Component, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {DataTableDirective} from 'angular-datatables';
import {Subject} from 'rxjs';
import * as routes from '@app/routes';
import {select, Store} from '@ngrx/store';
import {
  getRegistrationPlatformError,
  getRegistrationPlatformPaginated,
  getRegistrationPlatformSuccess,
  IAuthenticationState,
  IRegistrationPlatformState,
  RegistrationPlatformDelete,
  RegistrationPlatformListPaginated,
  ResetAuthState,
  ResetRegistrationPlatformState,
} from '@app/stores';
import {Router} from '@angular/router';
import {ToastrService} from 'ngx-toastr';
import {takeUntil} from 'rxjs/operators';
import Swal from 'sweetalert2';
import {Constants} from '@app/consts';
import * as DataTables from 'datatables.net';

@Component({
  selector: 'app-registration-platform-list',
  templateUrl: './registration-platform-list.component.html',
  styleUrls: ['./registration-platform-list.component.css'],
  providers: [Constants]
})
export class RegistrationPlatformListComponent implements OnInit, OnDestroy, AfterViewInit {

  @ViewChild(DataTableDirective)
  dtElement: DataTableDirective;
  dtOptions: any = {};
  unsubscriber = new Subject();
  dtTrigger: Subject<any> = new Subject();
  ajaxCallback: any;
  private datatableInstance: DataTables.Api<any>;
  private searchDelay;
  routes = routes;
  public registrationPlatformList = [];
  deleteAction = false;
  updateAction = false;
  loginWithUser = false;
  supportType = null;
  valuesType = null;
  widgetFilterValues = null;
  syncFilterValues = null;
  supportTypes = [
      {name: 'True', value: true},
      {name: 'False', value: false}
      ];
  supportTypeFilterValue = {};

  widgetSupportValues = [
      {name: 'Minimum (Clients might need help and it may not always work)', value: 'basic'},
      {name: 'Full (Clients can easily add without any help and works but there are workarounds)', value: 'full'},
      {name: 'Ultimate (Clients can easily add without any help and works reliably)', value: 'ultimate'}
    ];

  syncSupportValues = [
    {name: 'Basic (Limited Categories)', value: 'basic'},
    {name: 'Full (All Categories)', value: 'full'}
  ];

  constructor(
    private registrationPlatformStore: Store<IRegistrationPlatformState>,
    private authStore: Store<IAuthenticationState>,
    private router: Router,
    public constants: Constants,
    private toastrService: ToastrService,
  ) {
    this.registrationPlatformStore.dispatch(ResetRegistrationPlatformState({params: {error: '', paginatedRegistrationPlatform: null}}));
    this.authStore.dispatch(ResetAuthState({params: {error: '', success: ''}}));
    this.subscribeStores();
  }

  subscribeStores() {
    this.registrationPlatformStore.pipe(select(getRegistrationPlatformError))
      .pipe(takeUntil(this.unsubscriber))
      .subscribe(error => {
        if (error) {
          this.toastrService.error(error, 'Error');
        }
      });

    this.registrationPlatformStore.pipe(select(getRegistrationPlatformSuccess))
      .pipe(takeUntil(this.unsubscriber))
      .subscribe(success => {
        if (success) {
          if (this.deleteAction) {
            this.deleteAction = false;
            this.loadAllPlatforms();
          }
        }
      });

    this.registrationPlatformStore.pipe(select(getRegistrationPlatformPaginated))
      .pipe(takeUntil(this.unsubscriber))
      .subscribe((paginatedRegistrationPlatform: any) => {
          if (paginatedRegistrationPlatform) {
            this.registrationPlatformList = paginatedRegistrationPlatform.list;
            if (this.ajaxCallback) {
              this.ajaxCallback({
                recordsTotal: paginatedRegistrationPlatform.paging.total,
                recordsFiltered: paginatedRegistrationPlatform.paging.total,
                data: []
              });
              setTimeout(() => {
                (this.datatableInstance as any).columns.adjust();
              }, 500);
            }
          }
        }
      );
  }

  ngOnInit() {
    this.datatableSettings();
    if (this.dtElement && this.dtElement.dtInstance) {
      this.dtElement.dtInstance.then((dtInstance: any) => {
        dtInstance.destroy();
        this.dtTrigger.next();
      });
    } else {
      this.dtTrigger.next();
    }
  }

  ngAfterViewInit() {
    this.dtTrigger.next();
  }

  datatableSettings() {
    this.dtOptions = {
      scrollCollapse: true,
      pagingType: 'full_numbers',
      responsive: false,
      ordering: false,
      columnDefs: [{orderable: false, targets: 'no-sort'}],
      processing: true,
      pageLength: 10,
      paging: true,
      serverSide: true,
      scrollX: true,
      searching: true,
      // scrollY: "100vh",
      fixedHeader: {
        header: true,
        footer: true,
        headerOffset: 55 ,
      },
      fixedColumns: {
        right: 1,
        left: 0
      },
      drawCallback: function (setting) {
        const table = this.api().table().node();
        const totalPages = this.api().page.info().pages;
        if (totalPages <= 1) {
          document.querySelector('.dataTables_paginate').classList.add('d-none');
        } else {
          document.querySelector('.dataTables_paginate').classList.remove('d-none');
        }
      },
      ajax: (dataTablesParameters: any, callback) => {
        this.dtElement.dtInstance.then((dtInstance: any) => {
          this.datatableInstance = dtInstance;
          this.ajaxCallback = callback;
          const pageLength = dataTablesParameters.length;
          const pageNumber = (dataTablesParameters.start / pageLength);
          (this.datatableInstance as any).page.len(pageLength);
          const searchBox = $('div.dataTables_filter input');
          searchBox.off('keyup.DT input.DT');
          searchBox.on('keyup', () => {
            const search: any = searchBox.val();
            clearTimeout(this.searchDelay);
            this.searchDelay = setTimeout(() => {
              if (search != null) {
                (this.datatableInstance as any).search(search).draw();
              }
            }, 1000);
          });
          this.loadAllPlatforms(pageNumber, pageLength, dataTablesParameters.search.value);
        });
      },
      columns: [
        {data: null}, {data: null}, {data: null}, {data: null}, {data: null}, {data: null}, {data: null}, {data: null}
      ]
    };
  }

  private loadAllPlatforms(page = 0, perPage = 10, search = '', support_type = {}) {
    const options = {includePagination: true, includeProjects: true};
   if (this.supportTypeFilterValue && Object.keys(this.supportTypeFilterValue)?.length) {
      Object.keys(this.supportTypeFilterValue).forEach(key => {
          support_type[key] = this.supportTypeFilterValue[key];
      });
   }
   if (this.syncFilterValues) { support_type['sync_support'] = this.syncFilterValues; }
   if (this.widgetFilterValues) { support_type['widget_support'] = this.widgetFilterValues; }
    Object.assign(options, support_type);
    console.log(options);
    this.registrationPlatformStore.dispatch(RegistrationPlatformListPaginated({
      params: {
        options: JSON.stringify(options),
        page: page + 1,
        perPage,
        search
      }
    }));
  }

  onSupportSelect(event = null, key) {
    if (event) {
      this.supportTypeFilterValue[key] = event.value;
    } else {
      delete this.supportTypeFilterValue[key];
    }
    this.loadAllPlatforms(0, 10);
  }

  onWidgetValuesSelect(event = null) {
    this.widgetFilterValues = null;
    if (event) {
      this.widgetFilterValues = event.value;
    }
    this.loadAllPlatforms(0, 10);
  }
  onSyncValuesSelect(event = null) {
    this.syncFilterValues = null;
    if (event) {
      this.syncFilterValues = event.value;
    }
    this.loadAllPlatforms(0, 10);
  }
  navigateAssociates(projectId) {
    const url = this.router.serializeUrl(
      this.router.createUrlTree([`/${routes.campaigns({projectId})}`])
    );
    window.open(url, '_blank');
  }

  deleteRegistrationPlatform(registrationPlatformId: number) {
    Swal({
      title: 'Are you sure?',
      type: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Yes',
      cancelButtonText: 'No'
    }).then(result => {
      if (result.value) {
        this.deleteAction = true;
        this.registrationPlatformStore.dispatch(RegistrationPlatformDelete({registrationPlatformId}));
      }
    });
  }

  ngOnDestroy() {
    this.unsubscriber.next();
    this.unsubscriber.complete();
  }

}
