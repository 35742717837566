<div [title]="campaign.launch_user ? 'Launched By ' + campaign.launch_user.first_name + ' ' + campaign.launch_user.last_name + '('+ campaign.launch_user.id +')': ''">
  <ng-container>
    <ng-container *ngIf="(campaign.progress || campaign.progress >= 0) && !['launching', 'launch_queued', 'draft'].includes(campaign.status)">
      <span class="badge text-primary">Launching Campaign {{campaign.progress}}%</span>
      <ng-container *ngIf="currentUser && currentUser.user_type === '1'">
        <br/>
        <app-info-popover *ngIf="campaign.attemptsMade>1" info="{{getStatusMessage(campaign)}}"></app-info-popover>
      </ng-container>
    </ng-container>
    <ng-container *ngIf="!campaign.progress || campaign.progress < 0">
      <span *ngIf="campaign.status==='launched'" class="badge view-report" [ngClass]="{'text-left': layoutType=='list'}">
        <span class="fa fa-check-circle-o check"></span>
        <a [routerLink]="'/'+routes.statisticsForCampaign({projectId: projectId}, {campaignId: campaign.id})"
          [queryParams]="{campaignId: campaign.id}"> View Report</a>
        <br/>
      </span>
      <span *ngIf="campaign.status==='draft'" class="badge text-primary"
            [routerLink]="'/'+routes.addInfluencers({projectId: projectId})"
            [queryParams]="{campId: campaign.id}">
        Draft
      </span>
      <span *ngIf="campaign.status==='launch_queued'" class="badge text-primary">Queued</span>
      <span *ngIf="campaign.status==='launch_scheduled'" class="badge text-primary" [ngClass]="{'text-left': layoutType=='list'}">
        Scheduled to Launch at<br/>{{campaign.launch_scheduled_at | tzDate : false : 'DD MMM h:mm A'}}
        <app-info-popover *ngIf="campaign.timezone" info="{{getCampaignTZDate(campaign.launch_scheduled_at)}}"></app-info-popover>
      </span>
      <span *ngIf="campaign.status==='launch_failed'" class="badge text-primary"
            [ngClass]="{'text-left': layoutType=='list'}">
        Launch Failed
        <!--        <app-info-popover info="{{getStatusMessage(campaign)}}"></app-info-popover>-->
        At {{campaign?.status_progress >= 0 ? campaign?.status_progress + '%' : ''}}
        <br/> On {{campaign.launched_at | tzDate : false : 'DD MMM h:mm A'}} {{campaign.timezone ? '(' + campaign.timezone + ')' : ''}}
      </span>
      <span *ngIf="campaign.status==='launching'" class="badge text-primary" [ngClass]="{'text-left': layoutType=='list'}">
        Launching {{campaign?.status_progress >= 0 ?  '%' + campaign?.status_progress : ''}}
      </span>
      <span *ngIf="campaign.status==='launch_in_progress'" class="badge text-primary" [ngClass]="{'text-left': layoutType=='list'}">
        Launch In Progress
      </span>
    </ng-container>
  </ng-container>
</div>
