import { Injectable } from '@angular/core';

import { HttpService } from './http.service';

const BASE_PATH = '/api/v1/segment';

@Injectable ({providedIn: 'root'})
export class SegmentApiService {

  constructor(private httpService: HttpService) {}

  update(id, body, params?) {
    return this.httpService.put(BASE_PATH + `/${id}`, body, params);
  }
}
