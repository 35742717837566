<div class="creatable-select {{containerClass}}" [ngClass]="{'borders': !onlyBottomBorder}">
  <label [ngClass]="{'d-none': hideLabel}">
    {{labelText}}
    <app-info-popover *ngIf="!hideInfo"
      info="Segments are basically groups, which is useful for sending targeted campaigns">
    </app-info-popover>
  </label>
  <ng-select [ngModel]="selectedValue" #select [placeholder]="placeholder" [multiple]="isMultiple" [items]="selectOptions"
             [compareWith]="compareWith"
             (change)="onSelected($event)"
             [disabled]="isDisabled"
             [addTagText]="addTagText"
             [clearable]="clearable"
             [addTag]="isCreatable ? addTagFn : null" [bindLabel]="labelKey"
             [bindValue]="valueKey">
    <ng-template ng-header-tmp [ngIf]="customTemplate">
      <ng-container [ngTemplateOutlet]="customTemplate"></ng-container>
    </ng-template>
<!--    <ng-template ng-footer-tmp>-->
<!--      <p class="create-new">Create New 2 </p>-->
<!--    </ng-template>-->
  </ng-select>
</div>
