import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import {AuthGuard} from '@app/_guards';
import {NonInfluencerGuard} from '@app/_guards/non-influencer.guard';
import {PackageListComponent} from '@app/modules/packages/package-list/package-list.component';
import {PackageAddComponent} from '@app/modules/packages/package-add/package-add.component';
import {RegistrationPlatformAddComponent} from '@app/modules/registration-platform/registration-platform-add/registration-platform-add.component';

const routes: Routes = [
  {
    path: '',
    children: [
      {
        path: 'settings/packages',
        component: PackageListComponent,
        canActivate: [AuthGuard, NonInfluencerGuard]
      },
      {
        path: 'settings/packages/add',
        component: PackageAddComponent,
        canActivate: [AuthGuard, NonInfluencerGuard]
      },
      {
        path: 'settings/packages/edit/:id',
        component: PackageAddComponent,
        canActivate: [AuthGuard, NonInfluencerGuard]
      },
    ]
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class PackagesRoutingModule { }
