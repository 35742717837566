import {createAction, props} from '@ngrx/store';

import {PaginatedPackage, PaginatedPersonalAccessToken, PersonalAccessToken} from '@app/modules/shared/models';

enum EPersonalAccessTokenActions {
  PersonalAccessTokenAdd = '[Token] Personal Access Token Add',
  PersonalAccessTokenAddSuccess = '[Token] Personal Access Token Add Success',
  PersonalAccessTokenAddError = '[Token] Personal Access Token Add Error',

  PersonalAccessTokenList = '[Token] Personal Access Token List',
  PersonalAccessTokenListSuccess = '[Token] Personal Access Token List Success',
  PersonalAccessTokenListError = '[Token] Personal Access Token List Error',

  PersonalAccessTokenListPaginated = '[Token] PPersonal Access Token Paginated',
  PersonalAccessTokenListPaginatedSuccess = '[Token] Personal Access Token Paginated Success',
  PersonalAccessTokenListPaginatedError = '[Token] Personal Access Token Paginated Error',

  PersonalAccessTokenDelete = '[Token] Personal Access Token Delete',
  PersonalAccessTokenDeleteSuccess = '[Token] Personal Access Token Delete Success',
  PersonalAccessTokenDeleteError = '[Token] Personal Access Token Delete Error',

  PersonalAccessTokenUpdate = '[Token] Personal Access Token Update',
  PersonalAccessTokenUpdateSuccess = '[Token] Personal Access Token Update Success',
  PersonalAccessTokenUpdateError = '[Token] Personal Access Token Update Error',

  ResetPersonalAccessTokenState = '[Token] Reset Personal Access Token',

}


export const PersonalAccessTokenAdd = createAction(EPersonalAccessTokenActions.PersonalAccessTokenAdd, props<{ body: any }>());
export const PersonalAccessTokenAddSuccess = createAction(EPersonalAccessTokenActions.PersonalAccessTokenAddSuccess);
export const PersonalAccessTokenAddError = createAction(EPersonalAccessTokenActions.PersonalAccessTokenAddError, props<{ error: string }>());

export const PersonalAccessTokenList = createAction(EPersonalAccessTokenActions.PersonalAccessTokenList, (params: any = {}) => params);
export const PersonalAccessTokenListSuccess = createAction(EPersonalAccessTokenActions.PersonalAccessTokenListSuccess, props<{ token: PersonalAccessToken }>());
export const PersonalAccessTokenListError = createAction(EPersonalAccessTokenActions.PersonalAccessTokenListError, props<{ error: string }>());

export const PersonalAccessTokenListPaginated = createAction(EPersonalAccessTokenActions.PersonalAccessTokenListPaginated, (params: any = {}) => params);
export const PersonalAccessTokenListPaginatedSuccess = createAction(EPersonalAccessTokenActions.PersonalAccessTokenListPaginatedSuccess, props<{ paginatedPersonalAccessToken: PaginatedPersonalAccessToken }>());
export const PersonalAccessTokenListPaginatedError = createAction(EPersonalAccessTokenActions.PersonalAccessTokenListPaginatedError, props<{ error: string }>());

export const PersonalAccessTokenDelete = createAction(EPersonalAccessTokenActions.PersonalAccessTokenDelete, props<{ tokenId: number }>());
export const PersonalAccessTokenDeleteSuccess = createAction(EPersonalAccessTokenActions.PersonalAccessTokenDeleteSuccess);
export const PersonalAccessTokenDeleteError = createAction(EPersonalAccessTokenActions.PersonalAccessTokenDeleteError, props<{ error: string }>());

export const PersonalAccessTokenUpdate = createAction(EPersonalAccessTokenActions.PersonalAccessTokenUpdate, props<{ tokenId: number, body: any }>());
export const PersonalAccessTokenUpdateSuccess = createAction(EPersonalAccessTokenActions.PersonalAccessTokenUpdateSuccess,props<{ token: PersonalAccessToken }>());
export const PersonalAccessTokenUpdateError = createAction(EPersonalAccessTokenActions.PersonalAccessTokenUpdateError, props<{ error: string }>());

export const ResetPersonalAccessTokenState = createAction(EPersonalAccessTokenActions.ResetPersonalAccessTokenState, (params: any = {}) => params);
