<div class="container-fluid bg-white">
  <div class="row">
    <!-- LogIn Left Section  Start -->
    <div class="col-lg-5 col-md-12 d-flex justify-content-center">
      <div class="align-self-center w-100-small-device">
        <div class="logo text-center mt-2">
          <img src="../../assets/images/blue-logo.png" />
        </div>
        <div class="login-heading">
          <h1>Start a snöball
          </h1>
        </div>
        <div class="col-12 tittle text-center line-h-120" style="display: none">
          <span class="d-block" > Temporary Login</span>
          <a routerLink="/register" hidden>Create an account</a>
        </div>
        <!-- login form column start -->
        <div *ngIf="submitted && !authMessage['Success']" class="error-msg-fail">
          <p> {{authMessage['msg']}} </p>
        </div>
        <form [formGroup]="loginForm" (ngSubmit)="onSubmit()">
          <div class="card custom-card">
            <div class="input-container">
              <input type="text" formControlName="email" [ngClass]="{ 'is-invalid': f.email.errors }" required="required" />
              <label for="email">Email</label>
              <div class="bar">
              </div>
              <div *ngIf="submitted && f.email.errors" class="error-msg">
                <div *ngIf="f.email.errors.required">Email is required</div>
                <div *ngIf="f.email.errors.email">Username must be a valid email address</div>
              </div>

            </div>

            <div class="input-container">
              <input type="password" formControlName="password" [ngClass]="{ 'is-invalid': f.password.errors }"
                required="required" />
              <label for="password">Temporary Password</label>
              <div class="bar">
              </div>
              <div *ngIf="submitted && f.password.errors" class="error-msg">
                <div *ngIf="f.password.errors.required">Password is required</div>
                <div *ngIf="f.password.errors.minlength">Password must be at least 6 characters</div>
              </div>


            </div>


          </div>

          <!-- login form column end -->
          <div class="row" hidden>
            <div class="col-lg-6 col-md-6 col-sm-6 col-6 check-box-txt">
              <div class="custom-control custom-checkbox  checkbox-login">
                <input type="checkbox" class="custom-control-input" id="customControlAutosizing">
                <label class="custom-control-label" for="customControlAutosizing">Remember Me
                </label>
              </div>
            </div>
            <div class="col-lg-6 col-md-6 col-sm-6 col-6 forget-box-txt">
              <a routerLink="/password" class="mr-3 forget-link">Forgot Password?
              </a>
            </div>
          </div>
          <div class="m-auto text-center">
            <button type="submit" class="btn btn-primary blue-color login-btn mt-5 mb-2">Log in</button>
          </div>
        </form>
      </div>

    </div>
    <!-- LogIn Left Section End -->
    <!-- LogIn right Section  Start -->
    <div class="col-lg-7 col-md-12 blue-bg login-right-container d-flex justify-content-center">
      <div class="align-self-center  w-100-small-device">
        <div class="col-md-12">
          <h1 class="right-panel-heading m-B-64">92% Trust
            <br /> Word-of-Mouth
          </h1>
        </div>
        <div class="col-md-12">
          <p class="sub-info">
            Your speakers, exhibitors,
            <br /> and attendees can help
            <br /> promote your event.
          </p>
        </div>
        <div class="small-divice-text-center m-T116 m-B116">
          <a class="btn outline-white-brd-btn" type="submit" href="https://snoball.events/book-demo3/" target="_blank" style="text-decoration: none;">Tell me more</a>
        </div>
      </div>
    </div>
    <!-- LogIn right Section  End -->
  </div>
</div>
