import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {group} from '@angular/animations';

@Component({
  selector: 'app-variables',
  templateUrl: './variables.component.html',
  styleUrls: ['./variables.component.scss']
})
export class VariablesComponent implements OnInit {

  @Output() public onVariableForClick: EventEmitter<any> = new EventEmitter();
  @Output() public onVariableClick: EventEmitter<any> = new EventEmitter();
  @Input() variableClickFor;
  @Input() isDisabled = false;
  @Input() hideText = true;
  @Input() variables;

  constructor() {
  }

  ngOnInit(): void {
  }

  onAddVariablesButtonClick() {
    this.onVariableForClick.emit(this.variableClickFor);
  }

  onClickVariable(variable) {
    this.onVariableClick.emit(variable);
  }

  getFilteredVariables(grp) {
    return this.variables[grp].filter(item => !item.valueType.includes('ignore'));
  }

  getVariableTitle(groupName, variableTitle) {
    variableTitle = variableTitle.toString().toLowerCase().trim();
    variableTitle = variableTitle.replace(groupName.toString().toLowerCase().trim(), '');
    return variableTitle;
  }

}
