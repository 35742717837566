import {Injectable} from '@angular/core';
import {Actions, createEffect, ofType} from '@ngrx/effects';
import {Store} from '@ngrx/store';
import {
  ForgetPassword,
  ForgetPasswordError,
  ForgetPasswordSuccess,
  LoginError,
  LoginSuccess,
  LoginUser,
  LoginUserWithId, LoginUserWithIdError, LoginUserWithIdSuccess,
  Register,
  RegisterError,
  RegisterSuccess,
  ResetPassword,
  ResetPasswordError,
  ResetPasswordSuccess, ResetPasswordToken,
  TokenLogin
} from '@app/stores/authentication/authentication.actions';
import {IAuthenticationState} from '@app/stores/authentication/authentication.state';
import {catchError, map, switchMap} from 'rxjs/operators';
import {of} from 'rxjs';
import {AuthenticationService} from '@app/services/authentication.service';
import {LoaderService} from '@app/services/loader.service';
import * as moment from 'moment-timezone';

@Injectable()
export class AuthenticationEffects {
  constructor(
    private _actions$: Actions,
    private _store: Store<IAuthenticationState>,
    private authenticationService: AuthenticationService,
    private loaderService: LoaderService
  ) {
  }

  getLogin$ = createEffect(() => this._actions$.pipe(
    ofType(LoginUser),
    switchMap((action) => {
      this.loaderService.show();
      return this.authenticationService.login(action.credentials.email, action.credentials.password, action.credentials.remember).pipe(
        map((user: any) => {
          if (user.success) {
            user = {
              ...user.user,
              token: user.token
            };
            if (user && user.token && user.password_status === '0') {
              localStorage.removeItem('reportPassword');
              localStorage.setItem('currentUser', JSON.stringify(user));
              localStorage.setItem('loginInTime', moment().toISOString());
              localStorage.removeItem('resetUser');
              this.loaderService.startTimer();
            } else {
              localStorage.setItem('resetUser', JSON.stringify(user));
            }
            this.loaderService.hide();
            return LoginSuccess({user});
          }
          this.loaderService.hide(true);
          return LoginError({error: this.loaderService.getErrorMessage(user, 'Invalid credentials')});
        }),
        catchError((error) => {
          this.loaderService.hide(true);
          return of(LoginError({error: this.loaderService.getErrorMessage(error)}));
        })
      );
    })
  ));

  tokenLogin$ = createEffect(() => this._actions$.pipe(
    ofType(TokenLogin),
    switchMap((action) => {
      this.loaderService.show();
      return this.authenticationService.tokenLogin(action.token).pipe(
        map((user: any) => {
          if (user.success) {
            user = {
              ...user.user,
              token: user.token
            };
            if (user && user.token && user.password_status === '0') {
              localStorage.removeItem('reportPassword');
              localStorage.setItem('currentUser', JSON.stringify(user));
              localStorage.setItem('loginInTime', moment().toISOString());
              this.loaderService.startTimer();
            } else {
              localStorage.setItem('resetUser', JSON.stringify(user));
            }
            this.loaderService.hide();
            return LoginSuccess({user});
          }
          this.loaderService.hide(true);
          return LoginError({error: this.loaderService.getErrorMessage(user, 'Invalid credentials')});
        }),
        catchError((error) => {
          this.loaderService.hide(true);
          return of(LoginError({error: this.loaderService.getErrorMessage(error)}));
        })
      );
    })
  ));

  forgetPassword$ = createEffect(() => this._actions$.pipe(
    ofType(ForgetPassword),
    switchMap((action) => {
      this.loaderService.show();
      return this.authenticationService.password({
        email: action.email
      }).pipe(
        map((resp: any) => {
          if (resp.success) {
            this.loaderService.hide();
            return ForgetPasswordSuccess({forgetPassword: resp});
          }
          this.loaderService.hide(true);
          return ForgetPasswordError({error: this.loaderService.getErrorMessage(resp)});
        }),
        catchError(error => {
          this.loaderService.hide(true);
          return of(ForgetPasswordError({error: this.loaderService.getErrorMessage(error)}));
        })
      );
    })
  ));

  resetPassword$ = createEffect(() => this._actions$.pipe(
    ofType(ResetPassword),
    switchMap((action) => {
      this.loaderService.show();
      return this.authenticationService.resetPassword(action.password, action.userId).pipe(
        map((resp: any) => {
          if (resp.success) {
            this.loaderService.hide();
            localStorage.removeItem('resetUser');
            return ResetPasswordSuccess({resetPassword: resp});
          }
          this.loaderService.hide(true);
          return ResetPasswordError({error: this.loaderService.getErrorMessage(resp)});
        }),
        catchError(error => {
          this.loaderService.hide(true);
          return of(ResetPasswordError({error: this.loaderService.getErrorMessage(error)}));
        })
      );
    })
  ));

  register$ = createEffect(() => this._actions$.pipe(
    ofType(Register),
    switchMap((action) => {
      this.loaderService.show();
      return this.authenticationService.register(action.user).pipe(
        map((resp: any) => {
          if (resp.success) {
            this.loaderService.hide();
            return RegisterSuccess({register: resp.data});
          }
          this.loaderService.hide(true);
          return RegisterError({error: this.loaderService.getErrorMessage(resp)});
        }),
        catchError(error => {
          this.loaderService.hide(true);
          return of(RegisterError({error: this.loaderService.getErrorMessage(error)}));
        })
      );
    })
  ));

  loginWithToken$ = createEffect(() => this._actions$.pipe(
    ofType(LoginUserWithId),
    switchMap((action) => {
      this.loaderService.show();
      return this.authenticationService.loginWithId(action.userId).pipe(
        map((user: any) => {
          if (user.success) {
            user = {
              ...user.user,
              token: user.token
            };
            if (user && user.token) {

              this.loaderService.unsubscribe();
              localStorage.removeItem('reportPassword');

              localStorage.setItem('admin_currentUser', localStorage.getItem('currentUser'));
              localStorage.setItem('admin_loginInTime', localStorage.getItem('loginInTime'));

              localStorage.setItem('currentUser', JSON.stringify(user));
              localStorage.setItem('loginInTime', moment().toISOString());
              this.loaderService.startTimer();
            } else {
              localStorage.setItem('resetUser', JSON.stringify(user));
            }
            this.loaderService.hide();
            return LoginUserWithIdSuccess({user});
          }
          this.loaderService.hide(true);
          return LoginUserWithIdError({error: this.loaderService.getErrorMessage(user, 'Invalid credentials')});
        }),
        catchError(error => {
          this.loaderService.hide(true);
          return of(LoginUserWithIdError({error: this.loaderService.getErrorMessage(error)}));
        })
      );
    })
  ));

  resetPasswordToken$ = createEffect(() => this._actions$.pipe(
    ofType(ResetPasswordToken),
    switchMap((action) => {
      this.loaderService.show();
      return this.authenticationService.resetPasswordToken(action.token).pipe(
        map((user: any) => {
          if (user.success) {
            user = {
              ...user.user,
              token: user.token
            };
            this.loaderService.hide();
            localStorage.setItem('resetUser', JSON.stringify(user));
            return LoginSuccess({user});
          }
          this.loaderService.hide(true);
          return LoginError({error: this.loaderService.getErrorMessage(user, 'Invalid link')});
        }),
        catchError((error) => {
          this.loaderService.hide(true);
          return of(LoginError({error: this.loaderService.getErrorMessage(error)}));
        })
      );
    })
  ));
}
