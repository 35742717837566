import {
  authenticationReducer,
  campaignReducer,
  clientReducer,
  fileUploadReducer,
  influencersReducer,
  LogoutUser,
  mailVerifyReducer,
  projectReducer, reportReducer, segmentReducer,
  templateReducer,
  templateSectionReducer,
  usersReducer,
  variableReducer,
  settingsReducer,
  registrationPlatformReducer,
  packageReducer,
  personalAccessToken,
  trackingTypeReducer
} from '@app/stores';
import {ActionReducer, MetaReducer} from '@ngrx/store';

export const appReducers = {
  authentication: authenticationReducer,
  project: projectReducer,
  users: usersReducer,
  influencer: influencersReducer,
  campaign: campaignReducer,
  template: templateReducer,
  variable: variableReducer,
  templateSection: templateSectionReducer,
  files: fileUploadReducer,
  clients: clientReducer,
  mailVerify: mailVerifyReducer,
  report: reportReducer,
  segments: segmentReducer,
  settings: settingsReducer,
  registrationPlatform: registrationPlatformReducer,
  packages: packageReducer,
  personalAccessToken: personalAccessToken,
  trackingType: trackingTypeReducer
};

export function logout(reducer: ActionReducer<any>): ActionReducer<any> {
  return (state, action) => {
    if (action !== null && action.type === LogoutUser.type) {
      state = undefined;
    } else {
      if (state) {
        state = getResetState(state);
      }
    }
    return reducer(state, action);
  };
}

function getResetState(state) {
  return {
    authentication: {
      ...state.authentication,
      success: '',
      error: ''
    },
    project: {
      ...state.project,
      success: '',
      error: ''
    },
    users: {
      ...state.users,
      success: '',
      error: ''
    },
    influencer: {
      ...state.influencer,
      success: '',
      error: ''
    },
    campaign: {
      ...state.campaign,
      success: '',
      error: ''
    },
    template: {
      ...state.template,
      success: '',
      error: ''
    },
    variable: {
      ...state.variable,
      success: '',
      error: ''
    },
    templateSection: {
      ...state.templateSection,
      success: '',
      error: ''
    },
    files: {
      ...state.files,
      success: '',
      error: ''
    },
    clients: {
      ...state.clients,
      success: '',
      error: ''
    },
    packages: {
      ...state.packages,
      success: '',
      error: ''
    },
    mailVerify: {
      ...state.mailVerify,
      success: '',
      error: ''
    },
    report: {
      ...state.report,
      success: '',
      error: ''
    },
    segments: {
      ...state.segments,
      success: '',
      error: ''
    },
    settings: {
      ...state.settings,
      success: '',
      error: ''
    },
    registrationPlatform: {
      ...state.registrationPlatform,
      success: '',
      error: ''
    },
    token: {
      ...state.token,
      success: '',
      error: ''
    },
  };
}

export const metaReducers: MetaReducer[] = [logout];
