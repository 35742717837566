import {Component, OnInit, Input, OnDestroy} from '@angular/core';
import {environment} from '@environments/environment';
import SnoballPageDesigner from '../../../assets/snoball_page_designer/js/script.js';

@Component({
  selector: 'app-page-designer',
  templateUrl: './page-designer.component.html',
  styleUrls: [
    './page-designer.component.scss',
  ]
})
export class PageDesignerComponent implements OnInit, OnDestroy {
  @Input() set ViewportSize(val: string) {
    switch (val) {
      case 'desktop':
        val = 'desktop';
        break;
      case 'tablet':
        val = 'tablet_portrait';
        break;
      case 'mobile':
        val = 'phone';
        break;
    }
    if (val && this.snoballPageDesigner) {
      this.snoballPageDesigner.changeViewportSize(val);
    }
  }

  @Input() set token(val: string) {
    this.authToken = val;
  }

  @Input() set Variables(val: Object) {
    this.variables = val;
    if (this.snoballPageDesigner) {
      this.snoballPageDesigner.setVariables(val);
    }
  }

  private variables;
  private authToken;
  private loadHtmlQueue;
  private callBack;
  private changeStyleQueue = [];
  private customCssStyleQueue = '';
  private snoballPageDesigner;

  constructor() {
  }

  ngOnInit(): void {
    this.snoballPageDesigner = new SnoballPageDesigner('froala-editor', {
      froalaLicenseKey: environment.froalaLicenseKey,
      variables: this.variables,
      authToken: this.authToken,
      imageUploadUrl: environment.apiUrl + '/api/v1/file',
      cropImageUploadUrl: environment.apiUrl + '/api/v1/image-process',
      styles: [
        environment.apiUrl + '/public/assets/templates/v1/blocks/custom.css?v1=' + (new Date()).getTime() // V1 For firefox to load css
      ],
      customJs: [
        environment.apiUrl + '/public/assets/js/font-size-for-editor.js?v1=' + (new Date()).getTime() // V1 For firefox to load
      ],
      fontFamily: {},
    });
    if (this.loadHtmlQueue) {
      this.snoballPageDesigner.setHtml(this.loadHtmlQueue, this.callBack);
      this.loadHtmlQueue = null;
    }
    if (this.changeStyleQueue) {
      Object.entries(this.changeStyleQueue).forEach(([key, val]) => {
        this.changeStyle(val['selector'], val['name'], val['value']);
      });
    }

    if (this.customCssStyleQueue) {
      this.customCSSStyles(this.customCssStyleQueue);
    }
  }

  ngOnDestroy(): void {
    this.snoballPageDesigner.destroy();
    this.snoballPageDesigner = null;
  }

  setHtml(val, callBack): void {
    if (this.snoballPageDesigner) {
      this.snoballPageDesigner.setHtml(val, callBack);
    } else {
      this.loadHtmlQueue = val;
      this.callBack = callBack;
    }
  }

  getHtml() {
    return this.snoballPageDesigner.getHtml();
  }

  hideToolbars() {
    return this.snoballPageDesigner.hideBlockToolbars();
  }

  async getHtmlParts() {
    return await this.snoballPageDesigner.getHtmlParts();
  }

  appendBlock(val, type): void {
    this.snoballPageDesigner.appendBlock(val, type);
  }

  customCSSStyles(value): void {
    if (!this.snoballPageDesigner) {
      this.customCssStyleQueue = value;
    } else {
      this.snoballPageDesigner.customCSSStyles(value);
    }
  }

  changeStyle(selector, name, value, important = false): void {
    if (!this.snoballPageDesigner) {
      this.changeStyleQueue.push({selector, name, value, important});
    } else {
      if (name === 'fontFamily') {
        this.snoballPageDesigner.loadGoogleFont(value);
      }
      this.snoballPageDesigner.changeStyle(selector, name, value, important);
    }
  }

  destroy() {
    if (this.snoballPageDesigner) {
      this.snoballPageDesigner.destroy();
    }
  }

  disableEditor() {
    if (this.snoballPageDesigner) {
      this.snoballPageDesigner.disableEditor();
    }
  }

  enableEditor() {
    if (this.snoballPageDesigner) {
      this.snoballPageDesigner.enableEditor();
    }
  }
}
