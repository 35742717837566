import {AfterViewInit, Component, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {DataTableDirective} from 'angular-datatables';
import {Subject} from 'rxjs';
import {Segment} from '@app/modules/shared/models/segment';
import {
  getInfluencersTypes,
  getSegmentError,
  getSegmentsPaginated, getSegmentSuccess, IInfluencersState, InfluencersTypeList,
  ISegmentsState, ResetSegmentState, SegmentsAdd, SegmentsDelete, SegmentsListPaginated, SegmentsUpdate
} from '@app/stores';
import {select, Store} from '@ngrx/store';
import {ToastrService} from 'ngx-toastr';
import {NgbActiveModal, NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {takeUntil} from 'rxjs/operators';
import Swal from 'sweetalert2';
import {SegmentAddComponent} from '@app/modules/segment/segment-add/segment-add.component';
import {ActivatedRoute} from '@angular/router';
import {InfluencerType} from '@app/modules/shared/models';
import { InfluencersImportComponent } from '@app/_components/influencers-import/influencers-import.component';
import { RenameTitleModalComponent } from '@app/modules/rename-title-modal/rename-title-modal.component';
import { SegmentService } from '@app/services/segment.service';
import * as DataTables from 'datatables.net';

@Component({
  selector: 'app-segment-list',
  templateUrl: './segment-list.component.html',
  styleUrls: ['./segment-list.component.css']
})
export class SegmentListComponent implements OnInit, OnDestroy, AfterViewInit {

  @ViewChild(DataTableDirective)
  dtElement: DataTableDirective;
  dtOptions: any = {};
  unsubscriber = new Subject();
  segments: Segment[] = [];
  dtTrigger: Subject<any> = new Subject();
  projectId: number;
  campaignId: number;
  selectedInfluencer: number;
  influencerTypes: InfluencerType[] = [];
  private searchDelay;
  private datatableInstance: DataTables.Api<any>;
  ajaxCallback: any;

  constructor(
    private segmentStore: Store<ISegmentsState>,
    private toastr: ToastrService,
    private _modalService: NgbModal,
    private route: ActivatedRoute,
    private influencersStore: Store<IInfluencersState>,
    public modal: NgbActiveModal,
    private readonly segmentService: SegmentService
  ) {
    this.route.params.subscribe(params => {
      if (params.projectId) {
        this.projectId = +params.projectId;
      }
    });
  }

  subscribeStore() {
    this.segmentStore.pipe(select(getSegmentError))
      .pipe(takeUntil(this.unsubscriber))
      .subscribe(error => {
        if (error) {
          this.toastr.error(error, 'Error');
        }
      });
    this.segmentStore.pipe(select(getSegmentSuccess))
      .pipe(takeUntil(this.unsubscriber))
      .subscribe(success => {
        if (success) {
          this.getAllSegments(this.selectedInfluencer);
        }
      });
    this.segmentStore.pipe(select(getSegmentsPaginated))
      .pipe(takeUntil(this.unsubscriber))
      .subscribe(paginatedSegments => {
        if (paginatedSegments) {
          this.segments = paginatedSegments.list;
          if (this.ajaxCallback) {
            this.ajaxCallback({
              recordsTotal: paginatedSegments.paging.total,
              recordsFiltered: paginatedSegments.paging.total,
              data: []
            });
            setTimeout(() => {
              (this.datatableInstance as any).columns.adjust();
            }, 500);
          }
        }
      });
    this.influencersStore.pipe(select(getInfluencersTypes))
      .pipe(takeUntil(this.unsubscriber))
      .subscribe(influencerTypes => {
        if (influencerTypes && influencerTypes.length > 0) {
          this.influencerTypes = influencerTypes;
        }
      });
  }

  ngOnInit(): void {
    this.datatableSettings();
    if (this.dtElement && this.dtElement.dtInstance) {
      this.dtElement.dtInstance.then((dtInstance: any) => {
        dtInstance.destroy();
        this.dtTrigger.next();
      });
    } else {
      this.dtTrigger.next();
    }
    this.influencersStore.dispatch(InfluencersTypeList({params: {influencer_fields: true}}));
    this.segmentStore.dispatch(ResetSegmentState({params: {error: '', paginatedSegments: null}}));
    this.subscribeStore();
  }

  ngAfterViewInit() {
    this.dtTrigger.next();
  }

  datatableSettings() {
    this.dtOptions = {
      scrollCollapse: true,
      pagingType: 'full_numbers',
      responsive: false,
      ordering: false,
      processing: true,
      pageLength: 10,
      paging: true,
      serverSide: true,
      scrollX: true,
      scrollY: "60vh",
      fixedHeader: {
        header: true,
        footer: true
      },
      searching: true,
      drawCallback: function (setting) {
        const totalPages = this.api().page.info().pages;
        if (totalPages <= 1) {
          document.querySelector('.modal-dialog .dataTables_paginate').classList.add('d-none');
        } else {
          document.querySelector('.modal-dialog .dataTables_paginate').classList.remove('d-none');
        }
      },
      ajax: (dataTablesParameters: any, callback) => {
        this.dtElement.dtInstance.then((dtInstance: any) => {
          this.datatableInstance = dtInstance;
          this.ajaxCallback = callback;
          const pageLength = dataTablesParameters.length;
          const pageNumber = (dataTablesParameters.start / pageLength);
          (this.datatableInstance as any).page.len(pageLength);
          const searchBox = $('.modal-dialog div.dataTables_filter input');
          searchBox.off('keyup.DT input.DT');
          searchBox.on('keyup', () => {
            const search: any = searchBox.val();
            clearTimeout(this.searchDelay);
            this.searchDelay = setTimeout(() => {
              if (search != null) {
                (this.datatableInstance as any).search(search).draw();
              }
            }, 1000);
          });
          this.getAllSegments(this.selectedInfluencer, pageNumber, pageLength, dataTablesParameters.search.value);
        });
      },
      columns: [
        {data: null}, {data: null}, {data: null}
      ]
    };
  }

  filterSegmentByInfluencerType(event) {
    this.selectedInfluencer = event === 'all' ? undefined : event;
    this.getAllSegments(event === 'all' ? undefined : event);
  }

  getAllSegments(influencerType: number = null, page = 0, perPage = 10, search = '') {
    this.segmentStore.dispatch(SegmentsListPaginated({
      params: {
        project_id: this.projectId,
        ...(influencerType ? {influencer_type_id: influencerType} : {}),
        options: JSON.stringify({include_influencer_type: true, includePagination: true}),
        page: page + 1,
        perPage,
        search
      }
    }));
  }

  ngOnDestroy() {
    this.unsubscriber.next();
    this.unsubscriber.complete();
  }

  editSegment(segment: Segment) {
    const modelRef: any = this._modalService.open(SegmentAddComponent, {centered: true});
    modelRef.componentInstance.segment = {...segment};
    modelRef.componentInstance.influencerTypes = this.influencerTypes;
    modelRef.componentInstance.isUpdate = true;
    modelRef.result.then((result) => {
      this.segmentStore.dispatch(SegmentsUpdate({segmentId: segment.id, segment: {...result, project_id: this.projectId}}));
    }, (reason) => {
    });
  }

  addSegment() {
    const modelRef: any = this._modalService.open(SegmentAddComponent, {centered: true});
    const segment = new Segment();
    segment.name = ' ';
    modelRef.componentInstance.segment = segment;
    modelRef.componentInstance.influencerTypes = this.influencerTypes;
    modelRef.componentInstance.selectedInfluencer = this.selectedInfluencer;
    modelRef.result.then((result) => {
      this.segmentStore.dispatch(SegmentsAdd({segment: {...result, project_id: this.projectId}}));
    }, (reason) => {
    });
  }

  deleteSegment(segment: Segment) {
    Swal({
      title: 'Are you sure?',
      type: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Yes',
      cancelButtonText: 'No'
    }).then(result => {
      if (result.value) {
        this.segmentStore.dispatch(SegmentsDelete({segmentId: segment.id}));
      }
    });
  }

  selectSegment(segment: Segment) {
    Swal({
      title: 'Select this segment?',
      type: 'info',
      showCancelButton: true,
      confirmButtonText: 'Yes',
      cancelButtonText: 'No'
    }).then(result => {
      if (result.value) {
        this.modal.close({
          segment: segment
        });
      }
    });
  }

  openImportInfluencersModal(segment: Segment) {
    const modelRef = this._modalService.open(InfluencersImportComponent, {
      centered: false,
      size: 'xl',
      keyboard: true
    });

    modelRef.componentInstance.campId = this.campaignId;
    modelRef.componentInstance.influencerTypes = this.influencerTypes;
    modelRef.componentInstance.segments = [segment];
    modelRef.componentInstance.preSelectedSegmentId = segment.id;
    modelRef.componentInstance.hasFixedSegmentId = true;
    modelRef.componentInstance.projectId = this.projectId;
    modelRef.componentInstance.selectedInfluencer = this.selectedInfluencer;
    modelRef.result.then((result) => {
      // this.loadInfluencers();
    }, (reason) => {
    });
  }

  openRenameModal(entry: any) {
    //
    // Validate entry
    if (!entry?.id) return;

    //
    // Open modal
    const modelRef = this._modalService.open(RenameTitleModalComponent, {
      centered: false,
      size: 'sm',
      keyboard: true,
    });

    //
    // Add data to modal
    modelRef.componentInstance.title = 'Segment';
    modelRef.componentInstance.value = entry.name;

    //
    // On close modal event
    // should validate and update the entry
    modelRef.result.then(async (result: any = {}) => {
      //
      // Validate result
      if (!result?.value) return;
      // update database and reload
      await this.segmentService.updateSegment(entry.id, { ...entry, name: result.value }).toPromise();
      this.getAllSegments(this.selectedInfluencer);
    });
  }
}
