import {createAction, props} from '@ngrx/store';
import {Package, PaginatedPackage} from '@app/modules/shared/models';

enum EPackageActions {
  PackagesListAll = '[Packages] Packages List All',
  PackagesListAllSuccess = '[Packages] Packages List All Success',
  PackagesListAllError = '[Packages] Packages List All Error',
  PackagesListPaginated = '[Packages] Packages List Paginated',
  PackagesListPaginatedSuccess = '[Packages] Packages List Paginated Success',
  PackagesListPaginatedError = '[Packages] Packages List Paginated Error',
  PackagesAdd = '[Packages] Packages Add',
  PackagesAddSuccess = '[Packages] Packages Add Success',
  PackagesAddError = '[Packages] Packages Add Error',
  PackagesUpdate = '[Packages] Packages Update',
  PackagesUpdateSuccess = '[Packages] Packages Update Success',
  PackagesUpdateError = '[Packages] Packages Update Error',
  PackagesDelete = '[Packages] Packages Delete',
  PackagesDeleteSuccess = '[Packages] Packages Delete Success',
  PackagesDeleteError = '[Packages] Packages Delete Error',
  PackagesGet = '[Packages] Packages Get',
  PackagesGetSuccess = '[Packages] Packages Get Success',
  PackagesGetError = '[Packages] Packages Get Error',

  ResetPackageState = '[Packages] Reset Package State',
}

export const PackagesListAll = createAction(EPackageActions.PackagesListAll, (params: any = {}) => params);
export const PackagesListAllSuccess = createAction(EPackageActions.PackagesListAllSuccess, props<{ packages: Package[] }>());
export const PackagesListAllError = createAction(EPackageActions.PackagesListAllError, props<{ error: string }>());

export const PackagesListPaginated = createAction(EPackageActions.PackagesListPaginated, (params: any = {}) => params);
export const PackagesListPaginatedSuccess = createAction(EPackageActions.PackagesListPaginatedSuccess, props<{ paginatedPackages: PaginatedPackage }>());
export const PackagesListPaginatedError = createAction(EPackageActions.PackagesListPaginatedError, props<{ error: string }>());

export const PackagesAdd = createAction(EPackageActions.PackagesAdd, props<{ body: any }>());
export const PackagesAddSuccess = createAction(EPackageActions.PackagesAddSuccess);
export const PackagesAddError = createAction(EPackageActions.PackagesAddError, props<{ error: string }>());

export const PackagesUpdate = createAction(EPackageActions.PackagesUpdate, props<{ packageId: number, body: any }>());
export const PackagesUpdateSuccess = createAction(EPackageActions.PackagesUpdateSuccess, props<{ updatePackage: Package }>());
export const PackagesUpdateError = createAction(EPackageActions.PackagesUpdateError, props<{ error: string }>());

export const PackagesDelete = createAction(EPackageActions.PackagesDelete, props<{ packageId: number }>());
export const PackagesDeleteSuccess = createAction(EPackageActions.PackagesDeleteSuccess);
export const PackagesDeleteError = createAction(EPackageActions.PackagesDeleteError, props<{ error: string }>());

export const PackagesGet = createAction(EPackageActions.PackagesGet, props<{ packageId: number }>());
export const PackagesGetSuccess = createAction(EPackageActions.PackagesGetSuccess, props<{ pkg: Package }>());
export const PackagesGetError = createAction(EPackageActions.PackagesGetError, props<{ error: string }>());

export const ResetPackageState = createAction(EPackageActions.ResetPackageState, (params: any = {}) => params);

