import {
  AuthenticationEffects,
  InfluencersEffects,
  ProjectsEffects,
  UsersEffects,
  CampaignEffects,
  TemplateEffects,
  VariableEffects, TemplateSectionEffects, FileUploadEffects, ClientEffects, MailVerifyEffects, ReportEffects,
  SegmentsEffects, SettingsEffects, RegistrationPlatformEffects, PackageEffect, PersonalAccessTokenEffects, TrackingTypeEffects
} from '@app/stores';

export const appEffects = [AuthenticationEffects, ProjectsEffects, UsersEffects, InfluencersEffects, MailVerifyEffects,
  CampaignEffects, TemplateEffects, VariableEffects, TemplateSectionEffects, FileUploadEffects, ClientEffects, ReportEffects,
  SegmentsEffects, SettingsEffects, RegistrationPlatformEffects, PackageEffect, PersonalAccessTokenEffects, TrackingTypeEffects];
