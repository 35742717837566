import {PaginatedPersonalAccessToken, PersonalAccessToken} from '@app/modules/shared/models';

export interface IPersonalAccessTokenState {
  token: PersonalAccessToken;
  tokens: PersonalAccessToken[];
  paginatedPersonalAccessToken: PaginatedPersonalAccessToken;
  success: string;
  error: string;
}

export const initialPersonalAccessTokenState: IPersonalAccessTokenState = {
  token: null,
  tokens: null,
  paginatedPersonalAccessToken:null,
  success: '',
  error: ''
};
