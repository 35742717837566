<div class="container-fluid bg-white">
    <div class="row">
        <!-- LogIn Left Section  Start -->
        <div class="col-lg-5 col-md-12 d-flex justify-content-center">
            <div class="align-self-center w-100-small-device">
                <div class="logo text-center mt-2">
                    <img src="../../../assets/images/blue-logo.png"/>
                </div>
                <div class="login-heading">
                    <h1>{{ 'Loginpage.login_heading' | translate }}
                    </h1>
                </div>
                <div class="col-12 tittle text-center line-h-120" style="display: none">
                    <span class="d-block"> {{ 'Loginpage.tittle' | translate }} </span>
                    <a routerLink="/register">{{ 'Loginpage.register_text' | translate }}</a>
                </div>
                <!-- login form column start -->
                <div *ngIf="submitted && !authMessage['Success']" class="error-msg-fail">
                    <p> {{authMessage[ 'msg' ]}} </p>
                </div>
              <div *ngIf="successTokenUrl" class="alert alert-primary-custom mt-4">
                <p> Your account is verified. </p>
              </div>
                <form (ngSubmit)="onSubmit()" [formGroup]="loginForm">
                    <div class="card custom-card">

                        <div class="floating-label">
                            <input [ngClass]="{ 'is-invalid': f.email.errors }" class="floating-input" formControlName="email" placeholder=" "
                                   required="required"
                                   type="text"/>
                            <span class="highlight"></span>
                            <label>{{ 'Loginpage.email' | translate }} <span class="red">*</span></label>

                            <div *ngIf="submitted && f.email.errors" class="bar error-brd">
                                <div *ngIf="f.email.errors.required"
                                     class="invalid-text">{{ 'Loginpage.email_require' | translate }}</div>
                                <div *ngIf="f.email.errors.email"
                                     class="invalid-text">{{ 'Loginpage.valid_email' | translate }}</div>
                            </div>
                        </div>


                        <div class="floating-label">
                            <input [ngClass]="{ 'is-invalid': f.password.errors }" class="floating-input" formControlName="password" placeholder=" "
                                   required="required"
                                   type="password"/>
                            <span class="highlight"></span>
                            <label>{{ 'Loginpage.password' | translate }} <span class="red">*</span></label>

                            <div *ngIf="submitted && f.password.errors" class="bar error-brd">
                                <div *ngIf="f.password.errors.required"
                                     class="invalid-text">{{ 'Loginpage.password_require' | translate }}</div>
                                <div *ngIf="f.password.errors.minlength"
                                     class="invalid-text">{{ 'Loginpage.valid_password' | translate }}</div>
                            </div>
                        </div>


                    </div>

                    <!-- login form column end -->
                    <div class="row mt-3 ml-1 mr-1">
                        <div class="col-lg-6 col-md-6 col-sm-6 col-6 check-box-txt">
                            <div class="custom-control custom-checkbox  checkbox-login">
                                <input class="custom-control-input" id="customControlAutosizing" formControlName="remember" type="checkbox">
                                <label class="custom-control-label"
                                       for="customControlAutosizing">{{ 'Loginpage.remember' | translate }}
                                </label>
                            </div>
                        </div>
                        <div class="col-lg-6 col-md-6 col-sm-6 col-6 forget-box-txt">
                            <a class="mr-3 forget-link" routerLink="/password">{{ 'Loginpage.forgot' | translate }}
                            </a>
                        </div>
                    </div>
                    <div class="m-auto text-center">
                        <button class="btn btn-primary blue-color login-btn mt-5 mb-2"
                                type="submit">{{ 'Loginpage.login' | translate }}</button>
                    </div>
                </form>
            </div>

        </div>
        <!-- LogIn Left Section End -->
        <!-- LogIn right Section  Start -->
        <div class="col-lg-7 col-md-12 blue-bg login-right-container d-flex justify-content-center">
            <div class="align-self-center  w-100-small-device">
                <div class="col-md-12">
                    <h1 class="right-panel-heading m-B-64">92% Trust
                        <br/> Word-of-Mouth
                    </h1>
                </div>
                <div class="col-md-12">
                    <p class="sub-info">
                        Your speakers, exhibitors,
                        <br/> and attendees can help
                        <br/> promote your event.
                    </p>
                </div>
                <div class="small-divice-text-center m-T116 m-B116">
                    <a class="btn outline-white-brd-btn" type="submit" href="https://snoball.events/book-demo3/" target="_blank" style="text-decoration: none;">Tell me more</a>
                </div>
            </div>
        </div>
        <!-- LogIn right Section  End -->
    </div>
</div>
