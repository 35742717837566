<div class="dropdown custom-right-dropdown dropright">
  <button type="button" (click)="onAddVariablesButtonClick()" aria-haspopup="true"
          aria-expanded="false" class="btn btn-default variables-button ml-1"
          id="emailTitleDropDown"
          [disabled]="isDisabled"
          data-toggle="dropdown">
    {{!hideText ? 'Variables': ''}}
  </button>
  <div class="dropdown-menu dropdown-menu-right" aria-labelledby="emailTitleDropDown">
    <ng-container *ngFor="let group of variables | keyvalue">
      <ng-container *ngIf="getFilteredVariables(group.key).length > 0">
        <h2 class="dropdown-header">{{group.key}}</h2>
        <a class="dropdown-item ml-2" (click)="onClickVariable(variable)"
           *ngFor="let variable of getFilteredVariables(group.key)"
           href="javascript:void(0)">{{getVariableTitle(group.key, variable.name)}}</a>
      </ng-container>
    </ng-container>
  </div>
</div>
