import { NgModule }                         from '@angular/core';
import { CommonModule }                     from '@angular/common';
import { ReportComponent }                  from './report/report.component';
import { StatisticsRoutingModule }          from '@app/modules/statistics/statistics-routing.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { DataTablesModule }    from 'angular-datatables';
import {NgxGaugeModule} from "ngx-gauge";
import {FontAwesomeModule} from "@fortawesome/angular-fontawesome";
import {NgApexchartsModule} from "ng-apexcharts";
import {BsDatepickerModule} from 'ngx-bootstrap/datepicker';
import {SharedModule} from '@app/modules/shared/shared.module';
import { ShareReportModalComponent } from './share-report-modal/share-report-modal.component';
import { ClipboardModule } from 'ngx-clipboard';
import { defineLocale } from 'ngx-bootstrap/chronos';
import { enGbLocale } from 'ngx-bootstrap/chronos';
import {MomentTimezonePickerModule} from "moment-timezone-picker";
import { TrackingAccuracyModalComponent } from "./tracking-accuracy-modal/tracking-accuracy-modal.component";
import { MatLegacyMenuModule as MatMenuModule } from '@angular/material/legacy-menu';

defineLocale('en-gb', enGbLocale);

@NgModule ({
    declarations: [
        ReportComponent,
        ShareReportModalComponent,
        TrackingAccuracyModalComponent
    ],
    imports: [
        CommonModule,
        StatisticsRoutingModule,
        FormsModule,
        ReactiveFormsModule,
        NgxGaugeModule,
        FontAwesomeModule,
        NgApexchartsModule,
        DataTablesModule,
        BsDatepickerModule,
        SharedModule,
        ClipboardModule,
        MomentTimezonePickerModule,
        MatMenuModule
    ]
})
export class StatisticsModule {
}
