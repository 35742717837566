<input [id]="id" [value]="value"
       type="text" class="{{class}} - {{value}}"
       (change)="change.emit($event)"
       (click)="openColorPicker()"
       autocomplete="off"
       [(ngModel)]="ngModel" (ngModelChange)="ngModelChange.emit(ngModel)"
       [ngStyle]="(value ? {backgroundColor: value, color: getFontColor()} : {color: getFontColor()})"
/>

<div class="custom-popup custom-desktop custom-inline custom-active l--30x {{pickerClass}} openPicker-{{openPicker}}" *ngIf="openPicker" (mouseenter)="setPreventClick(true)" (mouseleave)="setPreventClick(false)">
  <div class="custom-buttons custom-colors-buttons custom-tabs">
    <button id="colorsBack-1" type="button" tabindex="-1" role="button" title="Back" class="custom-command custom-btn custom-back"
            (click)="openPicker = false">
      <i class="fa fa-arrow-left"></i>
    </button>
      <div class="custom-separator custom-vs" role="separator" aria-orientation="vertical"></div>
      <div class="custom-separator custom-hs" role="separator" aria-orientation="horizontal"></div>
  </div>
  <div class="custom-color-set custom-text-color custom-selected-set">
    <ng-container *ngFor="let color of defaultColors; let i = index">
                <span (click)="setColor(color)"
                      [ngStyle]="{background: color}" [title]="color" role="button">
                </span>
      <br *ngIf="(i + 1) % 7 === 0"/>
    </ng-container>
    <br>
    <hr>
    <ng-container *ngIf="extraColors?.length">
      <ng-container *ngFor="let color of extraColors; let i = index">
                  <span (click)="setColor(color)"
                        [ngStyle]="{background: color}" [title]="color" role="button">
                  </span>
        <br *ngIf="(i + 1) % 7 === 0"/>
      </ng-container>
    </ng-container>
  </div>
  <div class="custom-color-hex-layer custom-active custom-layer" id="custom-color-hex-layer-
  1" style="width: 264px;">
    <div class="custom-input-line"><input maxlength="7" [(ngModel)]="inputHex" id="custom-color-hex-layer-text-1" type="text" placeholder=""
                                      tabindex="1" aria-required="true" dir="auto" class="custom-not-empty"><label
      for="custom-color-hex-layer-text-1">HEX Color</label></div>
    <div class="custom-action-buttons">
      <button type="button" class="custom-command custom-submit" tabindex="2" role="button" (click)="customColor()">
        OK
      </button>
    </div>
  </div>
</div>
