import {AfterViewInit, Component, Input, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {NgbActiveModal, NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {Subject} from 'rxjs';
import {ToastrService} from 'ngx-toastr';
import {select, Store} from '@ngrx/store';
import {getTemplateRevisions, ITemplateState, ResetTemplateState, TemplateGetRevisions} from '@app/stores';
import {takeUntil} from 'rxjs/operators';
import {TemplateRevisions} from '@app/modules/shared/models/template-revision';
import {DataTableDirective} from 'angular-datatables';
import * as moment from 'moment-timezone';
import * as DataTables from 'datatables.net';

@Component({
  selector: 'app-template-revisions',
  templateUrl: './template-revisions.component.html',
  styleUrls: ['./template-revisions.component.css']
})
export class TemplateRevisionsComponent implements OnInit, OnDestroy, AfterViewInit {
  @ViewChild(DataTableDirective)
  dtElement: DataTableDirective;
  dtOptions: any = {};
  dtTrigger: Subject<any> = new Subject();
  @Input() templateId;
  @Input() canReset = true;
  @Input() category = 'template';
  private unsubscriber = new Subject();
  templateRevisions: TemplateRevisions[] = [];
  restoreTemplate: TemplateRevisions;
  ajaxCallback: any;
  private datatableInstance: DataTables.Api<any>;
  pageNumber = 0;
  _moment = moment;

  constructor(
    public modal: NgbActiveModal,
    private toastr: ToastrService,
    private templateStore: Store<ITemplateState>,
    private _modalService: NgbModal
  ) {
    this.templateStore.dispatch(ResetTemplateState({params: {templateRevisions: null}}));
    this.subscribeStores();
  }

  subscribeStores() {
    this.templateStore.pipe(select(getTemplateRevisions))
      .pipe(takeUntil(this.unsubscriber))
      .subscribe(templateRevisions => {
        if (templateRevisions) {
          this.templateRevisions = templateRevisions.list;
          if (this.ajaxCallback) {
            this.ajaxCallback({
              recordsTotal: templateRevisions.paging.total,
              recordsFiltered: templateRevisions.paging.total,
              data: []
            });
            setTimeout(() => {
              (this.datatableInstance as any).columns.adjust();
            }, 500);
          }
        }
      });
  }

  ngOnInit(): void {
    this.datatableSettings();
    if (this.dtElement && this.dtElement.dtInstance) {
      this.dtElement.dtInstance.then((dtInstance: any) => {
        dtInstance.destroy();
        this.dtTrigger.next();
      });
    } else {
      this.dtTrigger.next();
    }
  }

  restoreVersion(revision) {
    let templateData = revision.data;
    if (typeof revision.data === 'string') {
      templateData = JSON.parse(revision.data);
    }
    this.modal.close({reset: false, data: templateData});
  }

  resetTemplate() {
    this.modal.close({reset: true});
  }

  ngAfterViewInit() {
    this.dtTrigger.next();
  }

  getTemplateRevisions(perPage = 10, orderBy = {'column': 'created_at', 'dir': 'desc'}) {
    this.templateStore.dispatch(TemplateGetRevisions({templateId: this.templateId, params: {
        options: JSON.stringify({includePagination: true, orderBy}),
        page: this.pageNumber + 1,
        perPage,
        category: this.category
      }}));
  }

  datatableSettings() {
    this.dtOptions = {
      scrollCollapse: true,
      pagingType: 'full_numbers',
      responsive: false,
      ordering: false,
      processing: true,
      pageLength: 50,
      paging: true,
      serverSide: true,
      scrollX: true,
      scrollY: "60vh",
      fixedHeader: {
        header: true,
        footer: true
      },
      searching: false,
      drawCallback: function (setting) {
        const totalPages = this.api().page?.info()?.pages || 0;
        if (totalPages <= 1) {
          document.querySelector('.dataTables_paginate').classList.add('d-none');
        } else {
          document.querySelector('.dataTables_paginate').classList.remove('d-none');
        }
      },
      ajax: (dataTablesParameters: any, callback) => {
        this.dtElement.dtInstance.then((dtInstance: any) => {
          this.datatableInstance = dtInstance;
          this.ajaxCallback = callback;
          const pageLength = dataTablesParameters.length;
          this.pageNumber = (dataTablesParameters.start / pageLength);
          (this.datatableInstance as any).page.len(pageLength);
          const orderBy = {
            ...dataTablesParameters.order[0],
            column: 'created_at'
          };
          this.getTemplateRevisions(pageLength, orderBy);
        });
      },
      columns: [
        {data: null}, {data: null}, {data: null}
      ],
      order: [[1, 'desc']],
    };
  }

  ngOnDestroy() {
    this.unsubscriber.next();
    this.unsubscriber.complete();
  }

}
