import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {DataTablesModule} from 'angular-datatables';
import { PopoverModule } from 'ngx-bootstrap/popover';

import {SharedModule} from '../shared/shared.module';

import {InfluencerRoutingModule} from './influencer-routing.module';
import {InfluencerAddComponent} from './influencer-add';
import {InfluencerListComponent} from './influencer-list';
import {InfluencerViewComponent} from './influencer-view';
import {ImportInfluencerComponent} from './import-influencer';
import {PaginationModule} from 'ngx-bootstrap/pagination';
import {NgbPopoverModule} from '@ng-bootstrap/ng-bootstrap';
import { MatLegacyMenuModule as MatMenuModule } from '@angular/material/legacy-menu';

@NgModule({
  declarations: [InfluencerAddComponent, InfluencerListComponent, InfluencerViewComponent,
    ImportInfluencerComponent],
  exports: [
    InfluencerViewComponent,
    InfluencerAddComponent,
    InfluencerListComponent
  ],
    imports: [
        CommonModule,
        ReactiveFormsModule,
        InfluencerRoutingModule,
        DataTablesModule,
        SharedModule,
        FormsModule,
        PaginationModule.forRoot(),
        PopoverModule.forRoot(),
        NgbPopoverModule,
        MatMenuModule
    ]
})
export class InfluencerModule {
}
