import {
  PaginatedTemplate,
  PaginatedWidgetAvatarTemplate,
  Template,
  TemplateType,
  WidgetAvatarTemplate
} from '@app/modules/shared/models';
import {PaginatedTemplateRevisions} from '@app/modules/shared/models/template-revision';

export interface ITemplateState {
  template: Template;
  templates: Template[];
  paginatedTemplates: PaginatedTemplate;
  templateCampaign: any;
  templateTypes: TemplateType[];
  templateRevisions: PaginatedTemplateRevisions;
  previewUrl: any;
  widgetPreviewUrl: any;
  widgetIntegrationBody: any;
  widgetCustomScriptBody: any;
  widgetAvatarTemplate: WidgetAvatarTemplate;
  widgetAvatarTemplates: WidgetAvatarTemplate[];
  paginatedWidgetAvatarTemplates: PaginatedWidgetAvatarTemplate;
  widgetAvatars: any;
  success: string;
  error: string;
}

export const initialTemplateState: ITemplateState = {
  template: null,
  templates: [],
  templateTypes: [],
  paginatedTemplates: null,
  templateCampaign: null,
  templateRevisions: null,
  previewUrl: null,
  widgetPreviewUrl: null,
  widgetIntegrationBody: null,
  widgetCustomScriptBody: null,
  widgetAvatarTemplate: null,
  widgetAvatarTemplates: null,
  paginatedWidgetAvatarTemplates: null,
  widgetAvatars: [],
  success: '',
  error: ''
};
