import { TrackingTypeModel } from '@app/modules/shared/models';

export interface ITrackingTypeState {
  trackingType: TrackingTypeModel[];
  success: string;
  error: string;
}

export const initialTrackingTypeState: ITrackingTypeState = {
  trackingType: [],
  success: '',
  error: '',
};
