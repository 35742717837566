<div class="container-fluid pt-3">
  <div class="rightBody">
    <!-- Add User Section  Start -->
    <div class="card">
      <div class="card-header py-3">
        <div class="card-header--title font-size-lg">
          {{packageId ? 'Edit' : 'Add'}} Package
        </div>
      </div>
      <div class="card-body">
        <div class="form-wraper">
          <form (ngSubmit)="savePackage()" [formGroup]="addPackageForm">
            <div class="row">
              <div class="col-lg-12 col-md-12 col-ms-12">
                <div class="floating-label">
                  <input [ngClass]="{ 'is-invalid': submitted && f.name.errors }"
                         formControlName="name" id="name" placeholder=" "
                         required="required" class="floating-input" min="0"
                         type="text"/>
                  <label for="name">Name <span class="red">*</span>
                  </label>
                  <div *ngIf="submitted && f.name.errors" class="bar error-brd">
                    <div *ngIf="f.name.errors.required" class="invalid-text">Name is required</div>
                  </div>

                </div>
              </div>
              <div class="col-lg-4 col-md-4 col-ms-12">
                <div class="floating-label">
                  <input [ngClass]="{ 'is-invalid': submitted}"
                         formControlName="project_count_limit" id="project_count_limit" placeholder=" "
                         required="required" class="floating-input" min="0"
                         type="number"/>
                  <label for="project_count_limit">Project Limit
                  </label>
                </div>
              </div>
              <div class="col-lg-4 col-md-4 col-ms-12">
                <div class="floating-label">
                  <input [ngClass]="{ 'is-invalid': submitted }"
                         formControlName="project_campaign_count_limit" id="project_campaign_count_limit"
                         placeholder=" "
                         required="required" class="floating-input" min="0"
                         type="number"/>
                  <label for="project_campaign_count_limit">Project Campaign Limit
                  </label>
                </div>
              </div>
              <div class="col-lg-4 col-md-4 col-ms-12">
                <div class="floating-label">
                  <input [ngClass]="{ 'is-invalid': submitted }"
                         formControlName="project_influencer_count_limit" id="project_influencer_count_limit"
                         placeholder=" "
                         required="required" class="floating-input" min="0"
                         type="number"/>
                  <label for="project_influencer_count_limit">Project Influencer Limit
                  </label>
                </div>
              </div>
            </div>
            <div _ngcontent-c4="" class="text-right mt-3">
              <button _ngcontent-c4="" class="btn btn-default mr-2" (click)="cancelClick()">Cancel</button>
              <button _ngcontent-c4="" class="btn btn-primary mr-2" type="submit">Save</button>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</div>
