import {Action, createReducer, on} from '@ngrx/store';
import {
  MailVerifySender, MailVerifySenderSuccess, MailVerifySenderError,
  MailVerifySenderStatus, MailVerifySenderStatusSuccess, MailVerifySenderStatusError, ResetMailVerifyState,
  MailServiceProvider, MailServiceProviderSuccess, MailServiceProviderError
} from '@app/stores/mail-verify/mail-verify.actions';
import {initialMailVerifyState} from '@app/stores/mail-verify/mail-verify.state';

const generateReducer = createReducer(initialMailVerifyState,
  on(MailVerifySender, (state) => ({
    ...state,
    status: '',
    success: '',
    error: '',
    serviceProviders: []
  })),
  on(MailVerifySenderSuccess, (state, {status}) => ({
    ...state,
    status,
    success: '',
    error: '',
    serviceProviders: []
  })),
  on(MailVerifySenderError, (state, {error}) => ({
    ...state,
    status: '',
    success: '',
    error,
    serviceProviders: []
  })),

  on(MailVerifySenderStatus, (state) => ({
    ...state,
    status: '',
    success: '',
    error: '',
    serviceProviders: []
  })),
  on(MailVerifySenderStatusSuccess, (state, {status}) => ({
    ...state,
    status,
    success: '',
    error: '',
    serviceProviders: []
  })),
  on(MailVerifySenderStatusError, (state, {error}) => ({
    ...state,
    status: '',
    success: '',
    error,
    serviceProviders: []
  })),

  on(MailServiceProvider, (state) => ({
    ...state,
    status: '',
    success: '',
    error: '',
    serviceProviders: []
  })),
  on(MailServiceProviderSuccess, (state, {serviceProviders}) => ({
    ...state,
    status: '',
    success: '',
    error: '',
    serviceProviders
  })),
  on(MailServiceProviderError, (state, {error}) => ({
    ...state,
    status: '',
    success: '',
    error,
    serviceProviders: []
  })),

  on(ResetMailVerifyState, (state, {params}) => ({
    ...state,
    ...params
  })),
);

export function mailVerifyReducer(state = initialMailVerifyState, action: Action) {
  return generateReducer(state, action);
}
