import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { InfluencerAddComponent } from './influencer-add';
import { InfluencerListComponent } from './influencer-list';
import { InfluencerViewComponent } from './influencer-view';

import { AuthGuard }                  from '@app/_guards';
import { NonInfluencerGuard } from '@app/_guards/non-influencer.guard';

const routes: Routes = [
  {
    path: '',
    children: [
      {
        path: 'add',
        component: InfluencerAddComponent
      },
      {
        path: '',
        component: InfluencerListComponent
      },
      {
        path: 'view/:influencerId',
        component: InfluencerViewComponent
      },
      {
        path: 'edit/:influencerId',
        component: InfluencerViewComponent
      }
    ],
    canActivate: [AuthGuard, NonInfluencerGuard]
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class InfluencerRoutingModule { }
