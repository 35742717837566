import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {DataTablesModule} from 'angular-datatables';

import {TemplateRoutingModule} from './template-routing.module';
import {TemplatesComponent} from './templates/templates.component';
import {CreateTemplateComponent} from './create-template/create-template.component';
import {SharedModule} from '@app/modules/shared/shared.module';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {NgbPopoverModule} from '@ng-bootstrap/ng-bootstrap';
import { MatLegacyMenuModule as MatMenuModule } from '@angular/material/legacy-menu';
import { PopoverModule } from 'ngx-bootstrap/popover';

@NgModule({
  declarations: [TemplatesComponent, CreateTemplateComponent],
    imports: [
        CommonModule,
        TemplateRoutingModule,
        DataTablesModule,
        SharedModule,
        ReactiveFormsModule,
        FormsModule,
        NgbPopoverModule,
        MatMenuModule,
        PopoverModule.forRoot(),

    ]
})
export class TemplateModule {
}
