import {createAction, props} from '@ngrx/store';

enum EMailVerifyActions {
  MailVerifySenderStatus = '[Mail Verify] Mail Verify Sender Status',
  MailVerifySenderStatusSuccess = '[Mail Verify] Mail Verify Sender Status Success',
  MailVerifySenderStatusError = '[Mail Verify] Mail Verify Sender Status Error',
  MailVerifySender = '[Mail Verify] Mail Verify Sender',
  MailVerifySenderSuccess = '[Mail Verify] Mail Verify Sender Success',
  MailVerifySenderError = '[Mail Verify] Mail Verify Sender Error',
  MailServiceProvider = '[Mail Verify] Mail Service Provider',
  MailServiceProviderSuccess = '[Mail Verify] Mail Service Provider Success',
  MailServiceProviderError = '[Mail Verify] Mail Service Provider Error',

  ResetMailVerifyState = '[Mail Verify] Reset Mail Verify',
}

export const MailVerifySenderStatus = createAction(EMailVerifyActions.MailVerifySenderStatus, props<{ params: any }>());
export const MailVerifySenderStatusSuccess = createAction(EMailVerifyActions.MailVerifySenderStatusSuccess, props<{ status: string }>());
export const MailVerifySenderStatusError = createAction(EMailVerifyActions.MailVerifySenderStatusError, props<{ error: string }>());

export const MailVerifySender = createAction(EMailVerifyActions.MailVerifySender, props<{ params: any }>());
export const MailVerifySenderSuccess = createAction(EMailVerifyActions.MailVerifySenderSuccess, props<{ status: string }>());
export const MailVerifySenderError = createAction(EMailVerifyActions.MailVerifySenderError, props<{ error: string }>());

export const MailServiceProvider = createAction(EMailVerifyActions.MailServiceProvider, props<{ params: any }>());
export const MailServiceProviderSuccess = createAction(EMailVerifyActions.MailServiceProviderSuccess, props<{ serviceProviders: any[] }>());
export const MailServiceProviderError = createAction(EMailVerifyActions.MailServiceProviderError, props<{ error: string }>());

export const ResetMailVerifyState = createAction(EMailVerifyActions.ResetMailVerifyState, (params: any = {}) => params);

