import {Injectable} from '@angular/core';
import {HttpClient, HttpHeaders} from '@angular/common/http';

import {environment} from '@environments/environment';

@Injectable({
  providedIn: 'root'
})
export class InfluencerService {
  private baseApiUrl = environment.apiUrl;
  private influencerUrl = this.baseApiUrl + '/api/v1/influencer';
  private influencerSegmentUrl = this.baseApiUrl + '/api/v1/segment';

  constructor(
    private http: HttpClient
  ) {
  }

  getAll(params: any = {}) {
    return this.http.get(`${this.influencerUrl}`, {params});
  }

  getById(id: number) {
    return this.http.get(`${this.influencerUrl}/${id}`);
  }

  getByCode(code: string, params: any = {}) {
    return this.http.get(`${this.influencerUrl}/update/${code}`, { params });
  }

  getSegments(params: any = {}) {
    return this.http.get(this.influencerSegmentUrl, {params, headers: new HttpHeaders().set('Use-Temp-Token', 'true')});
  }

  // TODO:- BACKEND API NOT AVAILABLE
  updateSegment(oldGroup, oldName, newName) {
    const segmentEdit = {
      oldGroup: oldGroup,
      oldName: oldName,
      newName: newName
    };
    return this.http.post(`${this.baseApiUrl}/private/updatesegment`, segmentEdit);
  }

  // TODO:- BACKEND API NOT AVAILABLE
  validateInfluencers(params) {
    let headers = new HttpHeaders();
    headers = headers.append('Content-Type', 'multipart/form-data');
    return this.http.post(`${this.influencerUrl}/import`, params, {headers});
  }

  csvToValuesInfluencers(params) {
    let headers = new HttpHeaders();
    headers = headers.append('Content-Type', 'multipart/form-data');
    return this.http.post(`${this.influencerUrl}/csv_to_values`, params, {headers});
  }

  importHeadersInfluencers(params) {
    let headers = new HttpHeaders();
    headers = headers.append('Content-Type', 'multipart/form-data');
    return this.http.post(`${this.influencerUrl}/importHeaders`, params, {headers});
  }

  importInfluencers(params) {
    let headers = new HttpHeaders();
    headers = headers.append('Content-Type', 'multipart/form-data');
    return this.http.post(`${this.influencerUrl}/import`, params, {headers});
  }

  deleteInfluencer(id: number) {
    return this.http.delete(`${this.influencerUrl}/${id}`);
  }

  importInfluencersStatus(influencerJobId: number) {
    return this.http.get(`${this.influencerUrl}/import/status/${influencerJobId}`);
  }

  bulkInfluencersStatus(influencerJobId: number) {
    return this.http.get(`${this.influencerUrl}/bulk/status/${influencerJobId}`);
  }

  exportInfluencers(params: any = {}) {
    // return this.http.get(`${this.influencerUrl}/export`, {params, headers: new HttpHeaders().set('Use-Temp-Token', 'true')});
    return this.http.post(`${this.influencerUrl}/export`, params);
  }

  addInfluencer(influencer) {
    return this.http.post(`${this.influencerUrl}`, influencer);
  }

  updateInfluencer(influencer, influencerId: number) {
    return this.http.put(`${this.influencerUrl}/${influencerId}`, influencer);
  }

  updateInfluencerByCode(influencer, code: string) {
      return this.http.put(`${this.influencerUrl}/update/${code}`, influencer);
  }

  getInfluencerType(params: any) {
    return this.http.get(`${this.influencerUrl}/type`, {params, headers: new HttpHeaders().set('Use-Temp-Token', 'true')});
  }

  getInfluencerReferralsList(influencerId: number, params?: any) {
    return this.http.get(`${this.influencerUrl}/${influencerId}/referral_list`, {
      params
    });
  }

  taskInfluencersLogs(params: any) {
    return this.http.get(`${this.influencerUrl}/task/logs`, {params});
  }

  bulkInfluencerOperations(params: any) {
    return this.http.post(`${this.influencerUrl}s/bulk`, params);
  }



  importInfluencersFields(influencerTypeId: number) {
    console.log('--importInfluencersFields: ', influencerTypeId);
    return this.http.get(`${this.influencerUrl}/import/template_fields/${influencerTypeId}`);
  }

  // uploadInfluencerImage(image) {
  //   return new Promise(async (res, rej) => {
  //     const isInstanceOfFile = image instanceof File;
  //
  //     if (!isInstanceOfFile) {
  //       res(image);
  //       return;
  //     }
  //
  //     const upload = await this.awsService.uploadFile(image);
  //     if (!upload) {
  //       rej('An error occurred while uploading the influencer image');
  //       return;
  //     }
  //
  //     const {origin, pathname} = new URL(upload['url']);
  //
  //     res(`${origin}${pathname}`);
  //   });
  // }

  // uploadTeamImages(team, atrribute) {
  //   if (!team || !team.length) {
  //     return team;
  //   }
  //
  //   return new Promise(async (res) => {
  //     const promises = team.map((member) => {
  //       return new Promise(async (resolve) => {
  //         if (!member[atrribute]) {
  //           resolve(member);
  //         }
  //
  //         member[atrribute] = await this.uploadInfluencerImage(member[atrribute]);
  //         resolve(member);
  //       });
  //     });
  //     res(await Promise.all(promises));
  //   });
  // }
}
