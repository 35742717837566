import {createFeatureSelector, createSelector} from '@ngrx/store';
import {IVariableState} from '@app/stores';

const selectVariable = createFeatureSelector<IVariableState>('variable');

export const getVariable = createSelector(selectVariable, (state: IVariableState) => state.variable);

export const getVariableSuccess = createSelector(selectVariable, (state: IVariableState) => state.success);

export const getVariableError = createSelector(selectVariable, (state: IVariableState) => state.error);

export const getVariables = createSelector(selectVariable, (state: IVariableState) => state.variables);
