import {Injectable} from '@angular/core';
import {environment} from '@environments/environment';
import {HttpClient} from '@angular/common/http';

@Injectable({providedIn: 'root'})
export class PackageService {
  private baseApiUrl = environment.apiUrl;
  private packageUrl = this.baseApiUrl + '/api/v1/package';

  constructor(private http: HttpClient) {
  }

  getById(id: number) {
    return this.http.get(`${this.packageUrl}/${id}`);
  }

  getPackages(params: any = {}) {
    return this.http.get(`${this.packageUrl}`, {params});
  }

  update(item, id: number) {
    return this.http.put(`${this.packageUrl}/${id}`, item);
  }

  create(item) {
    return this.http.post(`${this.packageUrl}`, item);
  }

  deletePackage(packageId) {
    return this.http.delete(`${this.packageUrl}/${packageId}`);
  }
}
