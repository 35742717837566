<div class="container-fluid mobile-view pt-3 new-styles">
  <div class="rightBody"  [ngStyle]="{'padding': (campId != null) ? '0' : ''}">
    <div class="card card-box mb-5">
      <div class="card-header py-3">
        <div class="card-header--title font-size-lg">
           {{ getInfluencerType(selectedInfluencer) || 'Influencers'}}
        </div>
        <div class="card-header--actions">
            <button
              class="btn btn-sm btn-primary pull-right"
              type="button"
              [routerLink]="['/projects', id, 'influencers', 'add']"
              [queryParams]="{selectedInfluencerType: selectedInfluencer, selectedSegment: selectedSegment, campaignId: campId}"
              role="button"
            >
              <span class="btn-wrapper--icon"><i class="fa fa-plus"></i></span>
              <span class="btn-wrapper--label">Add Influencer</span>
            </button>
          <a  class="pull-right" title="Import">
            <img class="ml-3 mr-3 cursor-pointer " (click)="importInfluencers()"
                 src="../../assets/images/export-normal.png">
          </a>
          <a  class="pull-right" title="export">
            <img class="cursor-pointer ml-3 mr-2" mat-icon-button [matMenuTriggerFor]="exportMenu"
                 src="../../assets/images/import-normal.png">
          </a>
          <mat-menu #exportMenu="matMenu" class="campaign-popover">
            <a class="dropdown-item" (click)="exportInfluencers('csv')">
              <i class="fa fa-file-o mr-2"></i>CSV</a>
            <a class="dropdown-item" (click)="exportInfluencers('xlsx')">
              <i class="fa fa-file-excel-o mr-2"></i>Excel (xlsx)</a>
          </mat-menu>
        </div>
      </div>
      <div class="d-flex flex-wrap align-items-center px-4 py-3">
        <div class="client-dropdown">
          <app-creatable-select
            [hideLabel]="true" [isCreatable]="false" [onlyBottomBorder]="false" [selectedValue]="selectedInfluencer"
            [placeholder]="'Select influencer type'" [selectOptions]="influencerTypes"
            (optionSelected)="filterInfluencers($event)"></app-creatable-select>
        </div>
        <div class="client-dropdown" >
          <app-creatable-select #influencerSegment  [selectedValue]="selectedSegment" [hideLabel]="true"
                                [isCreatable]="false" [onlyBottomBorder]="false" [placeholder]="'Select Segment'" [customTemplate]="manageSegment"
                                [selectOptions]="filteredSegments" (optionSelected)="filterSegments($event)">
          </app-creatable-select>
        </div>
        <div class="client-dropdown">
          <app-creatable-select
            [hideLabel]="true" [isCreatable]="false" [onlyBottomBorder]="false" [selectedValue]="selectedSourceType"
            [placeholder]="'Filter by source'" [selectOptions]="sourceTypes"
            (optionSelected)="filterInfluencerSourceType($event)" valueKey="value"></app-creatable-select>
        </div>
        <div class="client-dropdown">
          <app-creatable-select
            [hideLabel]="true" [isCreatable]="false" [onlyBottomBorder]="false" [selectedValue]="selectedSkipped"
            [placeholder]="'Filter by Skipped'" [selectOptions]="skippedType"
            (optionSelected)="filterSkipped($event)" valueKey="value"></app-creatable-select>
        </div>
        <div class="client-dropdown">
          <app-creatable-select
            [hideLabel]="true" [isCreatable]="false" [onlyBottomBorder]="false" [selectedValue]="selectedEmailType"
            [placeholder]="'Select email type'" [selectOptions]="emailType"
            (optionSelected)="filterEmailType($event)"></app-creatable-select>
        </div>
        <div class="referred-msg" *ngIf="referredByInfluencer?.id">
          <span>Showing referrals for <b>{{referredByInfluencer.name}}</b> ({{referredByInfluencer.id}})</span>
          <i class="clear-referral fa fa-times ml-2" (click)="clearReferredByInfluencer($event)" aria-hidden="true"></i>
        </div>
        <ng-template #manageSegment>
          <button (click)="manageSegments()" class="btn btn-sm w-100" type="button" role="button">
            <!-- <span class="btn-wrapper--icon"><i class="fa fa-list"></i></span> -->
            <span class="btn-wrapper--label">Manage Segments</span>
          </button>
        </ng-template>
        <div class='customize-email d-flex align-items-center ml-auto'>
          <div class="mr-2">
            <input id="customize_columns" placeholder="" [checked]="showAll"
                   (change)="showAllColumns($event)" type="checkbox">
            <label for="customize_columns">Display all columns</label>
          </div>
        </div>
      </div>
      <div  *ngIf="selectedSegment && selectedInfluencer && !(filteredUpdateSegments.length > 0) && (selectAllInfluencer || includedIdList.length > 0)"  class="d-flex flex-wrap align-items-center px-4 justify-content-center select-all">
        <div [hidden]="selectAllInfluencer">Segment can only be changed in the same influencer type and when there is more than one segment.</div>
      </div>

      <div *ngIf="selectAllInfluencer || includedIdList.length > 0" class="d-flex flex-wrap align-items-center px-4 justify-content-center select-all mt-1">
        <div [hidden]="selectAllInfluencer">All <b> {{includedIdList.length}} </b> influencers on this page are selected. <span class="cursor-pointer select-all-influencer" *ngIf="influencerCount > pageLength" (click)="checkAllInfluencer()">Select all {{influencerCount}} influencer in list</span></div>
        <div *ngIf="selectAllInfluencer">All <b>{{influencerCount}}</b> influencers in the list are selected. <span class="cursor-pointer select-all-influencer" (click)="uncheckAllInfluencer()">Clear selection</span></div>
      </div>
      <div *ngIf="selectAllInfluencer || includedIdList.length > 0"
           class="d-flex flex-wrap align-items-center px-4 py-2 justify-content-center select-all mt-1">
        <button *ngIf="!((loader$ | async) || (loaderImport$ | async)) && !this.multipleDelete" class="delete-selected" (click)="deleteSelectedInfluencer()">Delete Selected</button>
        <button *ngIf="((loader$ | async) || (loaderImport$ | async)) && this.multipleDelete"  class="delete-selected" type="button" [disabled]="(loader$ | async) || (loaderImport$ | async)">
          <span class="btn-wrapper--icon spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
          <span class="btn-wrapper--label ml-2">
      {{'Deleting ' + importProgress.status}} {{importProgress.progress + '%'}}
    </span>
        </button>

        <ng-container  *ngIf="selectedSegment && selectedInfluencer && filteredUpdateSegments.length > 0">
          <div class="client-dropdown" [hidden]="!selectedInfluencer || multipleDelete || multipleSkip">
            <app-creatable-select #changeSegment
                                  [hideLabel]="true" [isCreatable]="false" [onlyBottomBorder]="false" [selectedValue]="selectedInfluencerForUpdate"
                                  [placeholder]="'Change Segment'"  (optionSelected)="segmentForUpdate($event)" [selectOptions]="filteredUpdateSegments"
            ></app-creatable-select>
          </div>
          <button *ngIf="!((loader$ | async) || (loaderImport$ | async)) && !this.multipleUpdate" class="apply-selected" [ngClass]="{'btn-disable': !selectedInfluencerForUpdate}" [disabled]="!selectedInfluencerForUpdate" (click)="updateSelectedInfluencer()">Apply Selected</button>
          <button *ngIf="((loader$ | async) || (loaderImport$ | async)) && this.multipleUpdate"  class="apply-selected" type="button" [disabled]="(loader$ | async) || (loaderImport$ | async)">
            <span class="btn-wrapper--icon spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
            <span class="btn-wrapper--label ml-2">
      {{'Updating ' + importProgress.status}} {{importProgress.progress + '%'}}
    </span>
          </button>
        </ng-container>

        <button *ngIf="!((loader$ | async) || (loaderImport$ | async)) && !this.multipleUnsubscribe" class="unsubscribe-selected" (click)="unsubscribeSelectedInfluencer()">Unsubscribe Selected</button>
        <button *ngIf="((loader$ | async) || (loaderImport$ | async)) && this.multipleUnsubscribe" class="unsubscribe-selected" type="button" [disabled]="(loader$ | async) || (loaderImport$ | async)">
          <span class="btn-wrapper--icon spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
          <span class="btn-wrapper--label ml-2">
      {{'Unsubscribing ' + importProgress.status}} {{importProgress.progress + '%'}}
    </span>
        </button>
        <ng-container>
          <div class="client-dropdown" [hidden]="!selectedInfluencer || multipleDelete || multipleUpdate">
            <app-creatable-select
              [hideLabel]="true" [isCreatable]="false" [onlyBottomBorder]="false" [selectedValue]="selectedSkipped"
              [placeholder]="'Update skipped type'" [selectOptions]="updateSkippedType"
              (optionSelected)="getInfluncerSkippedType($event)" valueKey="value"></app-creatable-select>
          </div>
          <button *ngIf="!((loader$ | async) || (loaderImport$ | async)) && !this.multipleSkip" class="unsubscribe-selected" (click)="skipSelectedInfluencer()" [ngClass]="{'btn-disable': !updateSkipped}" [disabled]="!updateSkipped">Skip For Selected</button>
          <button *ngIf="((loader$ | async) || (loaderImport$ | async)) && this.multipleSkip" class="unsubscribe-selected" type="button" [disabled]="(loader$ | async) || (loaderImport$ | async)">
            <span class="btn-wrapper--icon spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
            <span class="btn-wrapper--label ml-2">
              {{'Updating   ' + importProgress.status}} {{importProgress.progress + '%'}}
            </span>
          </button>
        </ng-container>
      </div>
      <div class="sticky-dropdown-custom-table p-3">
        <table width="100%" class="sb-table table dataTable no-footer nowrap" datatable
               [dtTrigger]="dtTrigger"
               [dtOptions]="dtOptions"
               id="dt-table">
          <thead>
          <tr>
            <th class="no-sort"><input [hidden]="influencersLocal?.length <= 0"   type="checkbox" [(ngModel)]="masterSelected" name="select" value="m1"
                                       (change)="checkUncheckAllInfluencer()"/></th>
            <th></th>
            <th class="=no-sort" [ngClass]="{'d-none': !showAll || !(!selectedInfluencer || (selectedInfluencer && influencerTypesById[selectedInfluencer].id))}">Id</th>
            <th class="no-sort" [ngClass]="{'d-none': !showAll && getInfluencerTypeSlug(selectedInfluencer) == 'partner'}">Full Name</th>
            <th class="no-sort" [ngClass]="{'d-none': !showAll && getInfluencerTypeSlug(selectedInfluencer) !== 'partner'}">Company</th>
            <th class="no-sort">Impressions</th>
            <th class="no-sort">Referrals</th>
            <th class="no-sort">Links</th>
            <th class="no-sort">Source</th>
<!--            <th>Insert Time</th>-->
            <th>Update Time</th>
            <th class="no-sort">Skip</th>
            <th [ngClass]="{'d-none': !showAll}">Create Time</th>
            <th class="no-sort" [ngClass]="{'d-none': !showAll || !(!selectedInfluencer || (selectedInfluencer && influencerTypesById[selectedInfluencer].email))}">Email</th>
            <th class="no-sort" [ngClass]="{'d-none': !showAll || (!selectedInfluencer || (selectedInfluencer && influencerTypesById[selectedInfluencer].emailStatus))}">Email status</th>
            <th class="no-sort" [ngClass]="{'d-none': !showAll || !(!selectedInfluencer || (selectedInfluencer && influencerTypesById[selectedInfluencer].image))}">Image</th>
            <th class="no-sort" [ngClass]="{'d-none': !showAll || !(!selectedInfluencer || (selectedInfluencer && influencerTypesById[selectedInfluencer].influencer_type_id))}">Influencer type</th>
            <th class="no-sort" [ngClass]="{'d-none': !showAll || !(!selectedInfluencer || (selectedInfluencer && influencerTypesById[selectedInfluencer].project_influencer_segment_id))}">Segment</th>
            <th class="no-sort" [ngClass]="{'d-none': !showAll || !(!selectedInfluencer || (selectedInfluencer && influencerTypesById[selectedInfluencer].registration_url))}">Registration Url</th>
<!--            <th class="no-sort" [ngClass]="{'d-none': !showAll || !(!selectedInfluencer || (selectedInfluencer && influencerTypesById[selectedInfluencer].company))}">Company</th>-->
            <th class="no-sort" [ngClass]="{'d-none': !showAll || !(!selectedInfluencer || (selectedInfluencer && influencerTypesById[selectedInfluencer].title))}">Prefix</th>
            <th class="no-sort" [ngClass]="{'d-none': !showAll || !(!selectedInfluencer || (selectedInfluencer && influencerTypesById[selectedInfluencer].first_name))}">First name</th>
            <th class="no-sort" [ngClass]="{'d-none': !showAll || !(!selectedInfluencer || (selectedInfluencer && influencerTypesById[selectedInfluencer].last_name))}">Last name</th>
            <th class="no-sort" [ngClass]="{'d-none': !showAll || !(!selectedInfluencer || (selectedInfluencer && influencerTypesById[selectedInfluencer].phone))}">Phone</th>
            <th class="no-sort" [ngClass]="{'d-none': !showAll || !(!selectedInfluencer || (selectedInfluencer && influencerTypesById[selectedInfluencer].video_url))}">Video Url</th>
            <th class="no-sort" [ngClass]="{'d-none': !showAll || !(!selectedInfluencer || (selectedInfluencer && influencerTypesById[selectedInfluencer].booth_number))}">Booth Number</th>
            <th class="no-sort" [ngClass]="{'d-none': !showAll || !(!selectedInfluencer || (selectedInfluencer && influencerTypesById[selectedInfluencer].job_title))}">Job Title</th>
            <th class="no-sort" [ngClass]="{'d-none': !showAll || !(!selectedInfluencer || (selectedInfluencer && influencerTypesById[selectedInfluencer].sessions))}">Sessions</th>
            <th class="no-sort" [ngClass]="{'d-none': !showAll || !(!selectedInfluencer || (selectedInfluencer && influencerTypesById[selectedInfluencer].custom_1))}">Custom 1</th>
            <th class="no-sort" [ngClass]="{'d-none': !showAll || !(!selectedInfluencer || (selectedInfluencer && influencerTypesById[selectedInfluencer].custom_2))}">Custom 2</th>
            <th class="no-sort" [ngClass]="{'d-none': !showAll || !(!selectedInfluencer || (selectedInfluencer && influencerTypesById[selectedInfluencer].custom_3))}">Custom 3</th>
            <th class="no-sort" [ngClass]="{'d-none': !showAll || !(!selectedInfluencer || (selectedInfluencer && influencerTypesById[selectedInfluencer].custom_4))}">Custom 4</th>
            <th class="no-sort" [ngClass]="{'d-none': !showAll || !(!selectedInfluencer || (selectedInfluencer && influencerTypesById[selectedInfluencer].custom_5))}">Custom 5</th>
            <th class="no-sort" [ngClass]="{'d-none': !showAll || !(!selectedInfluencer || (selectedInfluencer && influencerTypesById[selectedInfluencer].custom_6))}">Custom 6</th>
            <th class="no-sort" [ngClass]="{'d-none': !showAll || !(!selectedInfluencer || (selectedInfluencer && influencerTypesById[selectedInfluencer].custom_7))}">Custom 7</th>
            <th class="no-sort" [ngClass]="{'d-none': !showAll || !(!selectedInfluencer || (selectedInfluencer && influencerTypesById[selectedInfluencer].custom_8))}">Custom 8</th>
            <th class="no-sort" [ngClass]="{'d-none': !showAll || !(!selectedInfluencer || (selectedInfluencer && influencerTypesById[selectedInfluencer].custom_9))}">Custom 9</th>
            <th class="no-sort" [ngClass]="{'d-none': !showAll || !(!selectedInfluencer || (selectedInfluencer && influencerTypesById[selectedInfluencer].custom_10))}">Custom 10</th>
            <th class="no-sort" [ngClass]="{'d-none': !showAll || !(!selectedInfluencer || (selectedInfluencer && influencerTypesById[selectedInfluencer].referred_by))}">Referred By</th>
            <th class="no-sort" [ngClass]="{'d-none': !showAll || !(!selectedInfluencer || (selectedInfluencer && influencerTypesById[selectedInfluencer].referred_by_campaign_id))}">Referred Campaign Id</th>
            <th class="no-sort" [ngClass]="{'d-none': !showAll || !(!selectedInfluencer || (selectedInfluencer && influencerTypesById[selectedInfluencer].referred_through_tracker_source))}">Referred Through Tracker Source</th>
            <th class="no-sort" [ngClass]="{'d-none': !showAll || !(!selectedInfluencer || (selectedInfluencer && influencerTypesById[selectedInfluencer]))}">Created By</th>
            <th class="no-sort" [ngClass]="{'d-none': !showAll || !(!selectedInfluencer || (selectedInfluencer && influencerTypesById[selectedInfluencer]))}">Updated By</th>
            <th class="no-sort"></th>
          </tr>
          </thead>
          <tbody *ngIf="this.influencersLocal?.length > 0">
          <tr *ngFor="let influencer of this.influencersLocal" [ngClass]="{'withRedBackground': isAdmin && checkReferredThroughTrackerSource(influencer?.referred_through_tracker_source)?.isRedBackground}">
            <td><input type="checkbox" value="{{influencer.id}}"  [(ngModel)]="influencer.selected" (change)="onChangeInfluencerSelection(influencer.selected, influencer.id)"/></td>
            <td>
              <ng-container *ngIf="isAdmin && checkReferredThroughTrackerSource(influencer?.referred_through_tracker_source)?.message?.length">
                <button type="button" class="info-button" [ngbPopover]="popTemplate"
                        triggers="mouseenter:mouseleave" placement="auto" [container]="'body'"
                        [popoverClass]="'info-popover'">
                  <i class="fa fa-asterisk"></i>
                </button>
                <ng-template #popTemplate>{{isAdmin && checkReferredThroughTrackerSource(influencer?.referred_through_tracker_source)?.message}}</ng-template>

<!--                <app-info-popover container="body" info="{{checkReferredThroughTrackerSource(influencer?.referred_through_tracker_source)?.message}}" ></app-info-popover>-->
              </ng-container>
            </td>
            <td [ngClass]="{'d-none': !showAll || !(!selectedInfluencer || (selectedInfluencer && influencerTypesById[selectedInfluencer].id))}">{{influencer.id}}</td>
            <td [ngClass]="{'d-none': !showAll && getInfluencerTypeSlug(selectedInfluencer) == 'partner'}">{{influencer.title}} {{influencer.name}} <app-warning-popover *ngIf="influencer.email_address_validation_status == 'invalid' && isAdmin" container="body" warning="Invalid Email"></app-warning-popover> </td>
            <td [ngClass]="{'d-none': !showAll && getInfluencerTypeSlug(selectedInfluencer) !== 'partner'}">{{influencer.company}}</td>
            <td>{{influencer.pageViews}}</td>
            <td>
              <span [ngClass]="{'referred-count': influencer.referred_count > 0}"
                    (click)="influencer.referred_count > 0 && filterReferredByInfluencer(influencer)">{{influencer.referred_count}}</span>
                <span *ngIf="influencer.referred_count > 0" (click)="influencer.referred_count > 0 && showReferralList(influencer.id, (influencer.first_name + ' ' +influencer.last_name))">
                <i class="fa fa-external-link ml-1 cursor-pointer" aria-hidden="true"></i>
              </span></td>
            <td>
              <a *ngIf="campaign?.meta && influencer.campaign_influencer_page_url" href="{{influencer?.campaign_influencer_page_url}}" target="_blank">Landing</a>
              <span *ngIf="campaign?.meta && influencer.campaign_influencer_page_url"> | </span>
              <a *ngIf="campaign?.meta && influencer.campaign_influencer_share_page_url" href="{{influencer?.campaign_influencer_share_page_url}}" target="_blank">Share</a>
              <span *ngIf="campaign?.meta && influencer.campaign_influencer_share_page_url"> | </span>
              <a href="{{influencer.public_info_update_page_url}}" target="_blank">Info Update</a>
            </td>
            <td>{{influencer.source || '-'}}</td>
<!--            <td>{{formatInsertTime(influencer.created_at)}}</td>-->
<!--            <td>{{formatInsertTime(influencer.updated_at)}}</td>-->
            <td>{{formatMoment(influencer.updated_at) | tzDate : false : 'DD-MM-YYYY hh:mm a'}}</td>
            <td class="text-capitalize">{{influencer?.skip?.split("_").join(" ")}}</td>
            <td [ngClass]="{'d-none': !showAll}">{{formatMoment(influencer.created_at) | tzDate : false : 'DD-MM-YYYY hh:mm a'}}</td>
            <td [ngClass]="{'d-none': !showAll || !(!selectedInfluencer || (selectedInfluencer && influencerTypesById[selectedInfluencer].email))}">{{influencer.email}}</td>
            <td [ngClass]="{'d-none': !showAll || (!selectedInfluencer || (selectedInfluencer && influencerTypesById[selectedInfluencer].emailStatus))}">
              {{influencer.emailStatus}} <span *ngIf="influencer.emailStatus === 'failed'">({{influencer.emailFailedReason?.split("_").join(" ")}})</span>
              <button *ngIf="influencer.emailStatus === 'failed'" type="button" class="info-button exclamation-circle" [ngbPopover]="popTemplate"
                      triggers="mouseenter:mouseleave" placement="auto"
                      [popoverClass]="'info-popover'">
                <i class="fa fa-exclamation-circle"></i>
              </button>

              <ng-template #popTemplate>{{influencer.emailFailedReasonInfo}}</ng-template>
            </td>
            <td [ngClass]="{'d-none': !showAll || !(!selectedInfluencer || (selectedInfluencer && influencerTypesById[selectedInfluencer].image))}">
              <img class="image-thumbnail" [src]="influencer.image" alt=""/>
            </td>
            <td [ngClass]="{'d-none': !showAll || !(!selectedInfluencer || (selectedInfluencer && influencerTypesById[selectedInfluencer].influencer_type_id))}">{{getInfluencerType(influencer.influencer_type_id)}}</td>
            <td [ngClass]="{'d-none': !showAll || !(!selectedInfluencer || (selectedInfluencer && influencerTypesById[selectedInfluencer].project_influencer_segment_id))}">{{getInfluencerSegment(influencer.project_influencer_segment_id)}}</td>
            <td [ngClass]="{'d-none': !showAll || !(!selectedInfluencer || (selectedInfluencer && influencerTypesById[selectedInfluencer].registration_url))}">{{influencer.registration_url}}</td>
<!--            <td [ngClass]="{'d-none': !showAll || !(!selectedInfluencer || (selectedInfluencer && influencerTypesById[selectedInfluencer].company))}">{{influencer.company}}</td>-->
            <td [ngClass]="{'d-none': !showAll || !(!selectedInfluencer || (selectedInfluencer && influencerTypesById[selectedInfluencer].title))}">{{influencer.title}}</td>
            <td [ngClass]="{'d-none': !showAll || !(!selectedInfluencer || (selectedInfluencer && influencerTypesById[selectedInfluencer].first_name))}">{{influencer.first_name}}</td>
            <td [ngClass]="{'d-none': !showAll || !(!selectedInfluencer || (selectedInfluencer && influencerTypesById[selectedInfluencer].last_name))}">{{influencer.last_name}}</td>
            <td [ngClass]="{'d-none': !showAll || !(!selectedInfluencer || (selectedInfluencer && influencerTypesById[selectedInfluencer].phone))}">{{influencer.phone}}</td>
            <td [ngClass]="{'d-none': !showAll || !(!selectedInfluencer || (selectedInfluencer && influencerTypesById[selectedInfluencer].video_url))}">{{influencer.video_url}}</td>
            <td [ngClass]="{'d-none': !showAll || !(!selectedInfluencer || (selectedInfluencer && influencerTypesById[selectedInfluencer].booth_number))}">{{influencer.booth_number}}</td>
            <td [ngClass]="{'d-none': !showAll || !(!selectedInfluencer || (selectedInfluencer && influencerTypesById[selectedInfluencer].job_title))}">{{influencer.job_title}}</td>
            <td [ngClass]="{'d-none': !showAll || !(!selectedInfluencer || (selectedInfluencer && influencerTypesById[selectedInfluencer].sessions))}">{{influencer.sessions}}</td>
            <td [ngClass]="{'d-none': !showAll || !(!selectedInfluencer || (selectedInfluencer && influencerTypesById[selectedInfluencer].custom_1))}">{{influencer.custom_1}}</td>
            <td [ngClass]="{'d-none': !showAll || !(!selectedInfluencer || (selectedInfluencer && influencerTypesById[selectedInfluencer].custom_2))}">{{influencer.custom_2}}</td>
            <td [ngClass]="{'d-none': !showAll || !(!selectedInfluencer || (selectedInfluencer && influencerTypesById[selectedInfluencer].custom_3))}">{{influencer.custom_3}}</td>
            <td [ngClass]="{'d-none': !showAll || !(!selectedInfluencer || (selectedInfluencer && influencerTypesById[selectedInfluencer].custom_4))}">{{influencer.custom_4}}</td>
            <td [ngClass]="{'d-none': !showAll || !(!selectedInfluencer || (selectedInfluencer && influencerTypesById[selectedInfluencer].custom_5))}">{{influencer.custom_5}}</td>
            <td [ngClass]="{'d-none': !showAll || !(!selectedInfluencer || (selectedInfluencer && influencerTypesById[selectedInfluencer].custom_6))}">{{influencer.custom_6}}</td>
            <td [ngClass]="{'d-none': !showAll || !(!selectedInfluencer || (selectedInfluencer && influencerTypesById[selectedInfluencer].custom_7))}">{{influencer.custom_7}}</td>
            <td [ngClass]="{'d-none': !showAll || !(!selectedInfluencer || (selectedInfluencer && influencerTypesById[selectedInfluencer].custom_8))}">{{influencer.custom_8}}</td>
            <td [ngClass]="{'d-none': !showAll || !(!selectedInfluencer || (selectedInfluencer && influencerTypesById[selectedInfluencer].custom_9))}">{{influencer.custom_9}}</td>
            <td [ngClass]="{'d-none': !showAll || !(!selectedInfluencer || (selectedInfluencer && influencerTypesById[selectedInfluencer].custom_10))}">{{influencer.custom_10}}</td>
            <td [ngClass]="{'d-none': !showAll || !(!selectedInfluencer || (selectedInfluencer && influencerTypesById[selectedInfluencer].referred_by))}">{{influencer.referred_by}}</td>
            <td [ngClass]="{'d-none': !showAll || !(!selectedInfluencer || (selectedInfluencer && influencerTypesById[selectedInfluencer].referred_by_campaign_id))}">{{influencer.referred_by_campaign_id}}</td>
            <td [ngClass]="{'d-none': !showAll || !(!selectedInfluencer || (selectedInfluencer && influencerTypesById[selectedInfluencer].referred_through_tracker_source))}">{{influencer.referred_through_tracker_source}}</td>
            <td [ngClass]="{'d-none': !showAll || !(!selectedInfluencer || (selectedInfluencer && influencerTypesById[selectedInfluencer].created_by))}">{{getCreateUpdateInfo(influencer.created_by)}}</td>
            <td [ngClass]="{'d-none': !showAll || !(!selectedInfluencer || (selectedInfluencer && influencerTypesById[selectedInfluencer].updated_by))}">{{getCreateUpdateInfo(influencer.updated_by)}}</td>
            <td [ngClass]="{'withRedBackground': isAdmin && checkReferredThroughTrackerSource(influencer?.referred_through_tracker_source)?.isRedBackground}">
              <button class="btn-icon float-right" mat-icon-button [matMenuTriggerFor]="menu"
                      aria-label="Example icon-button with a menu">
                <span class="fa fa-ellipsis-v"></span>
              </button>
              <mat-menu #menu="matMenu" class="campaign-popover">
                <a class="dropdown-item" (click)="viewInfluencer(id , influencer.influencer_type_id, influencer.id)">
                  <i class="fa fa-pencil mr-2"></i>Edit</a>
                <a class="dropdown-item" (click)="changeStatus(influencer)">
                  <i class="fa fa-trash mr-2"></i>Delete</a>
                <a class="dropdown-item" *ngIf="!influencer.opted_out_id" href="{{influencer.opt_out_panel_url}}" target="_blank">
                  <i class="fa fa-times-circle mr-2" aria-hidden="true"></i>Unsubscribe</a>
                <a class="dropdown-item" *ngIf="influencer.opted_out_id" href="{{influencer.subscribe_panel_url}}" target="_blank">
                  <i class="fa fa-check-circle mr-2"></i>Subscribe</a>
                <a class="dropdown-item" *ngIf="campaign?.email_from_name" (click)="sendTestEmails(influencer?.email)" target="_blank">
                  <i class="fa fa-paper-plane mr-2" aria-hidden="true"></i>Send Email</a>
              </mat-menu>
            </td>
          </tr>
          </tbody>
          <tbody *ngIf="influencersLocal?.length <= 0">
          <tr>
            <td colspan="{{(showAll ? (influencerTypesById ? _object.keys(influencerTypesById[selectedInfluencer] || {}).length : 21) : 0) + 6}}" class="no-data-available">No data available in table</td>
          </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</div>

<ng-template #logsTable let-modal>
  <div class="modal-header">
    <h4 class="modal-title" id="modal-title">Error Logs</h4>
    <button type="button" class="close" aria-label="Close button" aria-describedby="modal-title"
            (click)="close();modal.dismiss('cancel')">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <div *ngIf="isImportDataFilled()" class="import-data-container mt-4">
      <div class="import-data-invalid">
        <span>Your influencers contains errors, please fix them and try to delete again.</span>
      </div>
      <div class="custom-table">
        <table class="sb-table table dataTable no-footer nowrap" datatable [dtOptions]="importDataTableOptions"
               id="dt1-table">
          <thead>
          <tr>
            <th *ngFor="let fieldName of importDataTableColumns()">{{fieldName}}</th>
          </tr>
          </thead>
          <tbody>
          <tr *ngFor="let data of importData['list']">
            <td  class="delete-log" *ngFor="let field of importDataTableColumns()">{{data[field]}}</td>
          </tr>
          </tbody>
        </table>
      </div>
    </div>

  </div>
  <input type="hidden" ngbAutofocus>
  <div class="modal-footer">
    <button type="button" class="btn btn-default" (click)="close();modal.close()">Close</button>
  </div>
</ng-template>

<ng-template #csvDownload let-modal >
  <div class="modal-header">
    <h4
      class="modal-title"
      id="modal-title"
    >
      Export {{ !csvDownloadComplete ? ': Your data is being exported' : ''}}
    </h4>
    <div class="d-flex justify-content-end">
      <button
        type="button"
        aria-label="Close button"
        class="close"
        aria-describedby="modal-title"
        (click)="modal.close()"
      >
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
  </div>
  <div class="modal-body">
    <div class="text-center">
      <button class="btn btn-default" type="button" *ngIf='!csvDownloadComplete'>
        <span class="btn-wrapper--icon spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
        <span class="btn-wrapper--label">
      {{exportProgress.status === 'started' ?
          'Export: started' :
          exportProgress.status === 'in-progress' ?
            'Exporting: '  + ' ' + exportProgress.progress + '%' :
            'Export: ' + exportProgress.status}}
    </span>
      </button>
      <a class="btn btn-default" type="button" *ngIf='csvDownloadComplete' (click)="downloadExportFile()">
        <span class="btn-wrapper--label">
          {{'File Link'}}
        </span>
      </a>
    </div>
  </div>
</ng-template>
