import {Influencer, InfluencerType, ProjectInfluencerSegment, PaginatedInfluencers} from '@app/modules/shared/models';

export interface IInfluencersState {
  influencer: Influencer;
  influencers: Influencer[];
  referralsList: Influencer[];
  paginatedInfluencers: PaginatedInfluencers;
  paginatedReferralsList: PaginatedInfluencers;
  influencersProjectSegment: ProjectInfluencerSegment[];
  influencersCsvToValues: any;
  influencersImportHeaders: any;
  influencersImport: any;
  influencersTaskLogs: any;
  influencersExport: any;
  influencersImportFields: any;
  influencerTypes: InfluencerType[];
  success: string;
  error: string;
  influencerBulk: any;
}

export const initialInfluencersState: IInfluencersState = {
  influencer: null,
  influencers: [],
  referralsList: [],
  paginatedInfluencers: null,
  paginatedReferralsList: null,
  influencersProjectSegment: [],
  influencersCsvToValues: null,
  influencersImportHeaders: null,
  influencersImport: null,
  influencersTaskLogs: null,
  influencersExport: null,
  influencersImportFields: null,
  influencerTypes: [],
  success: '',
  error: '',
  influencerBulk: null
};
