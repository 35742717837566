import {Injectable} from '@angular/core';
import {BehaviorSubject, Observable} from 'rxjs';

@Injectable({providedIn: 'root'})
export class InfluencerSharedService {
  public influencerSegment: Observable<any>;
  private influencerTypeSubject: BehaviorSubject<object>;

  constructor() {
    this.influencerTypeSubject = new BehaviorSubject<object>(
      JSON.parse(localStorage.getItem('selectedSegment'))
    );

    this.influencerSegment = this.influencerTypeSubject.asObservable();
  }

  public get getSegmentDetail(): object {
    return this.influencerTypeSubject.value;
  }

  updateSegmentDetail(value: object = null) {
    localStorage.setItem('selectedSegment', JSON.stringify(value));
    this.influencerTypeSubject.next(value);
  }

  setLocalVariables(name, value) {
    localStorage.setItem(name, value);
  }

  getLocalVariables(name) {
    return localStorage.getItem(name);
  }
}
