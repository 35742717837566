import {createAction, props} from '@ngrx/store';
import {Client, PaginatedClient} from '@app/modules/shared/models';

enum EClientActions {
  ClientsListAll = '[Clients] Clients List All',
  ClientsListAllSuccess = '[Clients] Clients List All Success',
  ClientsListAllError = '[Clients] Clients List All Error',
  ClientsList = '[Clients] Clients List',
  ClientsListSuccess = '[Clients] Clients List Success',
  ClientsListError = '[Clients] Clients List Error',
  ClientDelete = '[Clients] Client Delete',
  ClientDeleteSuccess = '[Clients] Client Delete Success',
  ClientDeleteError = '[Clients] Client Delete Error',
  ClientUpdate = '[Clients] Client Update',
  ClientUpdateSuccess = '[Clients] Client Update Success',
  ClientUpdateError = '[Clients] Client Update Error',
  ClientAdd = '[Clients] Client Add',
  ClientAddSuccess = '[Clients] Client Add Success',
  ClientAddError = '[Clients] Client Add Error',
  ClientGet = '[Clients] Client Get',
  ClientGetSuccess = '[Clients] Client Get Success',
  ClientGetError = '[Clients] Client Get Error',

  ResetClientState = '[Clients] Reset Client State',
}

export const ClientsListAll = createAction(EClientActions.ClientsListAll, (params: any = {}) => params);
export const ClientsListAllSuccess = createAction(EClientActions.ClientsListAllSuccess, props<{ clients: Client[] }>());
export const ClientsListAllError = createAction(EClientActions.ClientsListAllError, props<{ error: string }>());

export const ClientsList = createAction(EClientActions.ClientsList, (params: any = {}) => params);
export const ClientsListSuccess = createAction(EClientActions.ClientsListSuccess, props<{ paginatedClients: PaginatedClient }>());
export const ClientsListError = createAction(EClientActions.ClientsListError, props<{ error: string }>());

export const ClientDelete = createAction(EClientActions.ClientDelete, props<{ clientId: number }>());
export const ClientDeleteSuccess = createAction(EClientActions.ClientDeleteSuccess);
export const ClientDeleteError = createAction(EClientActions.ClientDeleteError, props<{ error: string }>());

export const ClientUpdate = createAction(EClientActions.ClientUpdate, props<{ clientId: number, client: Client }>());
export const ClientUpdateSuccess = createAction(EClientActions.ClientUpdateSuccess);
export const ClientUpdateError = createAction(EClientActions.ClientUpdateError, props<{ error: string }>());

export const ClientAdd = createAction(EClientActions.ClientAdd, props<{ client: Client }>());
export const ClientAddSuccess = createAction(EClientActions.ClientAddSuccess);
export const ClientAddError = createAction(EClientActions.ClientAddError, props<{ error: string }>());

export const ClientGet = createAction(EClientActions.ClientGet, props<{ clientId: number }>());
export const ClientGetSuccess = createAction(EClientActions.ClientGetSuccess, props<{ client: Client }>());
export const ClientGetError = createAction(EClientActions.ClientGetError, props<{ error: string }>());

export const ResetClientState = createAction(EClientActions.ResetClientState, (params: any = {}) => params);

