import {
  AfterViewInit,
  ChangeDetectionStrategy, ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  NgModule,
  OnChanges, OnDestroy,
  OnInit,
  Output,
  SimpleChanges, TemplateRef,
  ViewChild
} from '@angular/core';
import {FontPickerDirective} from '@app/modules/fontPicker/fontPicker.directive';
import {PageDesignerComponent} from '@app/_components/page-designer/page-designer.component';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {LoaderService} from '@app/services/loader.service';
import {select, Store} from '@ngrx/store';
import {getToken, IAuthenticationState} from '@app/stores';
import {takeUntil} from 'rxjs/operators';
import {Subject} from 'rxjs';

@Component({
  selector: 'app-template-editor',
  templateUrl: './template-editor.component.html',
  styleUrls: ['./template-editor.component.css'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class TemplateEditorComponent implements OnInit, OnChanges, OnDestroy, AfterViewInit {
  @ViewChild('pageDesigner', {static: true}) pageDesigner: PageDesignerComponent;
  @Input() viewportSize = 'desktop';
  @Output() public OnTemplateTypeChange: EventEmitter<any> = new EventEmitter();
  @Output() public OnPageSettingsChanged: EventEmitter<any> = new EventEmitter();
  @Output() public OnTemplateLoaded: EventEmitter<any> = new EventEmitter(true);
  @Input() variables;
  @Input() sideBarCustomStyle;
  @Input() template;
  @Input() showTemplateSelector;
  @Input() showBlockSelector;
  @Input() templateType = '';
  @Input() hideTemplateSelection = false;
  @Input() customClasses = '';
  @Input() pageSetting: any = {
    max_width: this.templateType === 'socialShare' ? '1200' : '',
    margin_top: '',
    block_margin: '',
    block_padding: '',
    block_background: '',
    background_color: '',
    font_color: '',
    header_font_color: '',
    link_color: '',
    button_color: '',
    button_font_color: '',
    button_border_color: '',
    button_hover_color: '',
    font_family: '',
    header_font_family: '',
    custom_css: '',
  };
  @Input() rawTemplatesTypes = [];
  @Input() templates = [];
  @Input() showSizeToolbar = false;
  @Input() showLandingPageEnable = false;
  @Input() landingPageEnabled = true;
  @Output() landingPageEnabledChange: EventEmitter<boolean> = new EventEmitter<boolean>();
  public editHtml: any = {
    htmlBody: '',
    htmlEdit: false,
  };
  public defaultProperties = {
    max_width: '',
    margin_top: '0',
    block_margin: '',
    block_padding: '',
    block_background: '#ffffff',
    background_color: '#ffffff',
    font_color: '#000000',
    header_font_color: '#000000',
    link_color: '#000000',
    button_color: '#329ef7',
    button_font_color: '#ffffff',
    button_border_color: '#329ef7',
    button_hover_color: '',
    font_family: 'Arial',
    // header_font_family: 'Arial',
    header1_font_family: 'Arial',
    header2_font_family: 'Arial',
    header3_font_family: 'Arial',
    header4_font_family: 'Arial',
    header5_font_family: 'Arial',
    header6_font_family: 'Arial',
    custom_css: '',
  };
  public pageSizeTemplate = [
    {
      icon: 'fa-television',
      name: 'desktop',
      is_active: false,
      page_class: 'col-12 full-page'
    },
    {
      icon: 'fa-tablet',
      name: 'tablet',
      is_active: false,
      page_class: 'col-9 tablet'
    },
    {
      icon: 'fa-mobile',
      name: 'mobile',
      is_active: false,
      page_class: 'col-6 mobile'
    }
  ];
  unsubscriber = new Subject();
  token = null;
  @NgModule({
    imports: [FontPickerDirective]
  })
  public currentMode;
  public currentToolBar: any = {'bodyProperties': true, 'blockProperties': true, 'colorSettings': true, 'fontStyles': true, 'customCssSettings': true};
  public modes = {
    RAW_TEMPLATE_LISTING: 'RAW_TEMPLATE_LISTING',
    TOOL_BAR: 'TOOL_BAR',
  };
  public extraColors = [];

  @ViewChild('codeEditor') private codeEditor: TemplateRef<any>;
  templateLoaded = false;
  @Input() isLoading = false;
  selectedRawType: string;

  constructor(
    private modalService: NgbModal,
    private loaderService: LoaderService,
    private authenticationStore: Store<IAuthenticationState>,
    private cdr: ChangeDetectorRef
  ) {
    this.authenticationStore.pipe(select(getToken))
      .pipe(takeUntil(this.unsubscriber))
      .subscribe(newToken => {
        if (newToken) {
          this.token = newToken;
        }
      });
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.template && changes.template.currentValue) {
      this.template = changes.template.currentValue;
      this.initHTMLTemplate();
    }
    if (this.rawTemplatesTypes && changes.rawTemplatesTypes && changes.rawTemplatesTypes.currentValue) {
      if (this.rawTemplatesTypes && changes.rawTemplatesTypes.currentValue.length > 0) {
        this.selectedRawType = changes.rawTemplatesTypes.currentValue[0].id;
      }
    }
  }

  ngOnInit() {
    this.currentMode = this.modes.RAW_TEMPLATE_LISTING;
  }

  ngAfterViewInit() {
    this.initHTMLTemplate();
  }

  initHTMLTemplate() {
    if (this.template && this.template['body'] && this.template['style']) {
      this.OnTemplateLoaded.emit(false);
      this.templateLoaded = false;
      this.pageDesigner.setHtml({body: this.template['body'], styles: this.template['style']}, () => {
        this.OnTemplateLoaded.emit(true);
        this.templateLoaded = true;
      });
      const t = this.template || {};
      let properties = t.properties;
      if (properties) {
        while (typeof properties === 'string') {
          properties = JSON.parse(properties);
        }
        properties = {...properties};
        Object.entries(this.pageSetting).forEach(([key, val]) => {
          this.setDefaultProperties(key, properties);
          this.onStylesCustomizationChange(properties[key], key);
        });
      }
      if (!properties && this.templateType === 'socialShare') {
        const defaultProperties = {max_width : '1200'};
        Object.entries(this.pageSetting).forEach(([key, val]) => {
          this.setDefaultProperties(key, defaultProperties);
          this.onStylesCustomizationChange(defaultProperties[key], key);
        });
      }
      this.cdr.detectChanges();
    } else {
      setTimeout(() => {
        this.templateLoaded = true;
        this.OnTemplateLoaded.emit(true);
        this.cdr.detectChanges();
      }, 3000);
    }
  }

  setDefaultProperties(key, properties) {
    if (!properties[key]) {
      properties[key] = this.defaultProperties[key];
    }
  }

  onListIconClick() {
    this.currentMode = this.modes.RAW_TEMPLATE_LISTING;
  }

  onBrushIconClick() {
    this.currentMode = this.modes.TOOL_BAR;
  }

  onRawTemplateTypeChange(event) {
    const {options} = event.target;
    const {selectedIndex} = options;
    this.selectedRawType = options[selectedIndex].getAttribute('value');

    this.OnTemplateTypeChange.emit(this.selectedRawType);
  }

  async buildTemplateForSave() {
    const htmlParts = await this.pageDesigner.getHtmlParts();
    return {body: htmlParts.body, style: htmlParts.styles};
  }

  addBlock(item) {
    if (this.showTemplateSelector) {
      this.template = {...item};
      this.initHTMLTemplate();
    } else {
      const val = item.body.replaceAll('\\', '');
      this.pageDesigner.appendBlock(val, item.template_section_type.name);
    }
  }

  getVariablesForPageDesigner() {
    const vars = this.variables;
    if (vars) {
      const result = {};
      Object.entries(vars).forEach(([key, value]) => {
        if (value.hasOwnProperty('group') && value['group']) {
          if (!result[value['group']]) {
            result[value['group']] = [];
          }
          result[value['group']].push({
            name: value['title'],
            key: value['alias'] ? `#${value['alias']}#` : value['key'],
            valueType: value['valueType'],
          });
        } else {
          if (!result['generic']) {
            result['generic'] = [];
          }
          result['generic'].push({
            name: value['title'],
            key: value['key'],
            valueType: value['valueType'],
          });
        }
      });
      return result;
    }
  }

  onStylesCustomizationChange(value, field) {
    let colorType = false;
    switch (field) {
      case 'margin_top':
        this.pageDesigner.changeStyle('body', 'paddingTop', (value) ? value + 'px' : '');
        break;
      case 'max_width':
        this.pageDesigner.changeStyle('section.fdb-block, header, footer', 'marginRight', 'auto');
        this.pageDesigner.changeStyle('section.fdb-block, header, footer', 'marginLeft', 'auto');
        this.pageDesigner.changeStyle('section.fdb-block, header, footer', 'maxWidth', value ? value + 'px' : '');
        break;
      case 'block_margin':
        this.pageDesigner.changeStyle('section.fdb-block, header, footer', 'margin', (value) ? value + 'px' : '');
        break;
      case 'block_padding':
        this.pageDesigner.changeStyle('section.fdb-block, header, footer', 'padding', value ? value + 'px' : '', true);
        break;
      case 'block_background':
        this.pageDesigner.changeStyle('section.fdb-block, header, footer', 'backgroundColor', value);
        colorType = true;
        break;
      case 'background_color':
        this.pageDesigner.changeStyle('body', 'backgroundColor', value);
        colorType = true;
        break;
      case 'font_family':
        this.pageDesigner.changeStyle('body', 'fontFamily', value);
        this.pageDesigner.changeStyle('p', 'fontFamily', value);
        this.pageDesigner.changeStyle('li', 'fontFamily', value);
        break;
      case 'font_color':
        this.pageDesigner.changeStyle('body', 'color', value);
        this.pageDesigner.changeStyle('p', 'color', value);
        colorType = true;
        break;
      case 'header_font_color':
        this.pageDesigner.changeStyle('h1, h2, h3, h4, h5, h6', 'color', value);
        colorType = true;
        break;
      // case 'header_font_family':
      //   this.pageDesigner.changeStyle('h1, h2, h3, h4, h5, h6', 'fontFamily', value);
      //   break;
      case 'header1_font_family':
        this.pageDesigner.changeStyle('h1', 'fontFamily', value);
        break;
      case 'header2_font_family':
        this.pageDesigner.changeStyle('h2', 'fontFamily', value);
        break;
      case 'header3_font_family':
        this.pageDesigner.changeStyle('h3', 'fontFamily', value);
        break;
      case 'header4_font_family':
        this.pageDesigner.changeStyle('h4', 'fontFamily', value);
        break;
      case 'header5_font_family':
        this.pageDesigner.changeStyle('h5', 'fontFamily', value);
        break;
      case 'header6_font_family':
        this.pageDesigner.changeStyle('h6', 'fontFamily', value);
        break;
      case 'link_color':
        this.pageDesigner.changeStyle('a', 'color', value);
        colorType = true;
        break;
      case 'button_color':
        this.pageDesigner.changeStyle('button', 'backgroundColor', value);
        this.pageDesigner.changeStyle('a.btn', 'backgroundColor', value);
        this.pageDesigner.changeStyle('a.btn.btn-secondary', 'backgroundColor', value);
        colorType = true;

        // Change border
        // this.pageDesigner.changeStyle('button', 'border-color', value);
        // this.pageDesigner.changeStyle('a.btn', 'border-color', value);
        // this.pageDesigner.changeStyle('a.btn.btn-secondary', 'border-color', value);
        break;
      case 'button_font_color':
        this.pageDesigner.changeStyle('button', 'color', value);
        this.pageDesigner.changeStyle('a.btn', 'color', value);
        this.pageDesigner.changeStyle('a.btn.btn-secondary', 'color', value);
        colorType = true;
        break;
      case 'button_border_color':
        this.pageDesigner.changeStyle('button', 'border-color', value);
        this.pageDesigner.changeStyle('a.btn', 'border-color', value);
        this.pageDesigner.changeStyle('a.btn.btn-secondary', 'border-color', value);
        colorType = true;
        break;
      case 'button_hover_color':
        this.pageDesigner.changeStyle('button:hover', 'backgroundColor', value, true);
        this.pageDesigner.changeStyle('a.btn:hover', 'backgroundColor', value, true);
        this.pageDesigner.changeStyle('a.btn.btn-secondary:hover', 'backgroundColor', value, true);
        colorType = true;

        // this.pageDesigner.changeStyle('button:hover', 'border-color', value, true);
        // this.pageDesigner.changeStyle('a.btn:hover', 'border-color', value, true);
        // this.pageDesigner.changeStyle('a.btn.btn-secondary:hover', 'border-color', value, true);
        break;
      case 'button_font_family':
        this.pageDesigner.changeStyle('button', 'fontFamily', value);
        break;
      case 'custom_css':
        this.pageDesigner.customCSSStyles(value);
        break;
    }
    if (colorType) {
      this.extraColors = [...new Set([value, ...this.extraColors])].filter(x => (x && x.includes('#'))).slice(0, 14);
    }
    this.pageSetting = {
      ...this.pageSetting,
      [field]: value
    };
    this.OnPageSettingsChanged.emit(this.pageSetting);
  }

  applyHtml() {
    this.templateLoaded = false;
    this.OnTemplateLoaded.emit(false);
    this.pageDesigner.setHtml({body: this.editHtml.htmlBody, styles: ''}, () => {
      this.OnTemplateLoaded.emit(true);
      this.templateLoaded = true;
    });
    Object.entries(this.pageSetting).forEach(([key, val]) => {
      if (this.pageSetting && this.pageSetting[key]) {
        this.onStylesCustomizationChange(this.pageSetting[key], key);
      }
    });
    this.onEditHtml();
  }

  onPageSizeSelect(item) {
    this.viewportSize = item;
  }

  // Prevent lagging in textarea due to 2 way binding
  updateHtmlBody(event) {
    this.editHtml.htmlBody = event.target.value;
  }

  onEditHtml() {
    if (this.pageDesigner) {
      if (!this.editHtml.htmlEdit) {
        this.loaderService.show();
        const modalRef = this.modalService.open(this.codeEditor, {
          size: 'xl'
        });
        modalRef.result.then(result => {
          this.applyHtml();
        }, reason => {
          this.onEditHtml();
        });
        for (let i = 0; i < this.pageSizeTemplate.length; i++) {
          this.pageSizeTemplate[i].is_active = false;
        }
        this.pageDesigner.getHtmlParts().then(parts => {
          this.pageDesigner.hideToolbars();
          this.loaderService.hide();
          this.editHtml.htmlEdit = true;
          this.editHtml.htmlBody = parts.body;
        });
      } else {
        this.editHtml.htmlEdit = false;
        this.editHtml.htmlBody = '';
      }
    }
  }

  destroy() {
    this.pageDesigner.destroy();
  }

  disableEditor() {
    this.pageDesigner.disableEditor();
  }

  enableEditor() {
    this.pageDesigner.enableEditor();
  }

  ngOnDestroy() {
    this.pageDesigner.destroy();
    this.unsubscriber.next();
    this.unsubscriber.complete();
  }
}
