import {Directive, ElementRef, Input, OnChanges, SimpleChanges} from '@angular/core';

declare const $: any;

@Directive({
  selector: '[appColorPicker]'
})
export class ColorPickerDirective implements OnChanges {
  @Input() value;

  constructor(private el: ElementRef) {
    $(this.el.nativeElement).spectrum({
      type: 'text',
      // togglePaletteOnly: true,
      preferredFormat: 'hex',
      showInput: true,
      allowEmpty: true,
      containerClassName: 'color-container',
      change: (color) => {
        if (color) {
          $(this.el.nativeElement).val(color.toHexString());
          if ('createEvent' in this.el.nativeElement.ownerDocument) {
            const evt = this.el.nativeElement.ownerDocument.createEvent('HTMLEvents');
            evt.initEvent('change', false, true);
            this.el.nativeElement.dispatchEvent(evt);
          } else {
            this.el.nativeElement.fireEvent('onchange');
          }
        }
      }
    });
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.value && changes.value.currentValue) {
      if (changes.value.currentValue) {
        $(this.el.nativeElement).spectrum('set', changes.value.currentValue);
      }
    }
  }
}
