import { Injectable } from '@angular/core';

import { HttpService } from './http.service';

const BASE_PATH = '/api/v1/template';

@Injectable ({providedIn: 'root'})
export class TemplateApiService {

  constructor(private httpService: HttpService) {}

  index(params) {
    return this.httpService.get(`${BASE_PATH}`, params);
  }

  indexForCampaign(params) {
    return this.httpService.get(`${BASE_PATH}/campaign`, params);
  }

  get(id) {
    return this.httpService.get(`${BASE_PATH}/${id}`, {});
  }

  create(body, params?) {
    return this.httpService.post(BASE_PATH, body, params);
  }

  touch(body, params?) {
    return this.httpService.post(BASE_PATH + '/touch', body, params);
  }

  preview(id, email) {
    return this.httpService.post(BASE_PATH + `/${id}/preview`, {}, {email});
  }
}
