import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { Routes, RouterModule } from '@angular/router';
import { AuthGuard } from '@app/_guards';
import { NonInfluencerGuard } from '@app/_guards/non-influencer.guard';
import { ProjectAddComponent } from './project-add';
import { ProjectListComponent } from './project-list';
import { ProjectViewComponent } from './project-view';
import { AddInfluencerComponent } from './add-influencer';
import { InfluencerAddComponent } from './influencer-add';
import { InfluencerViewComponent } from './influencer-view';
import { ShareProjectComponent } from './share-project';
import {WidgetInstructionsComponent} from '@app/modules/project/widget-instrcutions/widget-instructions.component';
import {ThirdpartyPlatformsComponent} from './thirdparty-platforms-view';

const routes: Routes = [
  {
    path: '',
    children: [
      {
        path: '',
        component: ProjectListComponent,
        canActivate: [AuthGuard]
      },
      {
        path: 'projects/create',
        component: ProjectAddComponent,
        canActivate: [AuthGuard, NonInfluencerGuard]
      },
      {
        path: 'projects/:id',
        component: ProjectViewComponent,
        canActivate: [AuthGuard, NonInfluencerGuard]
      },
      {
        path: 'project/:id/add-influencer',
        component: AddInfluencerComponent,
        canActivate: [AuthGuard, NonInfluencerGuard]
      },
      {
        path: 'project/:projectId/influencer/add',
        component: InfluencerAddComponent,
        canActivate: [AuthGuard, NonInfluencerGuard]
      },
      {
        path: 'project/:projectId/influencer/edit/:influencerId',
        component: InfluencerViewComponent,
        canActivate: [AuthGuard, NonInfluencerGuard]
      },
      {
        path: 'projects/:projectId/share',
        component: ShareProjectComponent,
        canActivate: [AuthGuard]
      },
      {
        path: 'projects/:projectId/widget',
        component: WidgetInstructionsComponent,
        canActivate: [AuthGuard, NonInfluencerGuard]
      },
      {
        path: 'projects/:projectId/registration-platforms',
        component: ThirdpartyPlatformsComponent,
        canActivate: [AuthGuard, NonInfluencerGuard]
      },
    ]
  }
];

@NgModule({
  imports: [CommonModule, RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class ProjectRoutingModule { }
