import {AfterViewInit, Component, Input, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {NgbActiveModal} from '@ng-bootstrap/ng-bootstrap';
import {select, Store} from '@ngrx/store';
import {takeUntil} from 'rxjs/operators';
import {Subject} from 'rxjs';
import {ToastrService} from 'ngx-toastr';
import {DataTableDirective} from 'angular-datatables';
import * as moment from "moment";
import {SocketService} from '@app/services/socket.service';
import { getInfluencerError, getInfluencersImport, IInfluencersState } from '@app/stores';
import * as DataTables from 'datatables.net';

@Component({
  selector: 'app-import-matcher',
  templateUrl: './import-matcher.component.html',
  styleUrls: ['./import-matcher.component.css']
})
export class ImportMatcherComponent implements OnInit, OnDestroy, AfterViewInit {
  @ViewChild(DataTableDirective)
  dtElement: DataTableDirective;

  dtOptions: any = {};
  dtTrigger: Subject<any> = new Subject();
  public logs = [];
  private unsubscriber = new Subject();
  public importJobId: number;
  ajaxCallback: any;
  private datatableInstance: DataTables.Api<any>;
  pageNumber = 0;
  matchedHeaders = [];
  formatHeaders = [];
  csvFormatHeaders = [];
  formatMatchedHeaders = [];
  formatFields = [];
  copyFormatFields = [];
  influencersImportTotal = [];
  influencersImportPage = [];
  @Input() data: any;
  _moment = moment;
  public socketSubscription: any;
  pageLength = 10;
  orderBy: any = null;

  constructor(
    public modal: NgbActiveModal,
    private toastr: ToastrService,
    private influencerStore: Store<IInfluencersState>,
    private readonly socketService: SocketService
  ) {

  }

/*   subscribeWebSocket() {
    this.socketSubscription = this.socketService.sourceData.subscribe(
      (message: any) => {
        if (message?.data?.requesterType === 'influencer' && message?.data?.result) {
          const inx = this.influencerFields.findIndex(x => x.id === message.data.result.id);
          this.influencerFields = Object.assign([], this.influencerFields);
          if (inx >= 0) {
            this.influencerFields[inx].progress = message.data.result.progress;
            this.influencerFields[inx].status = message.data.result.status;
            this.influencerFields[inx].message = message.data.result.message;
          }
        }
      },
      (err) => console.error(err),
      () => console.warn('Completed!')
    );
  } */

  ngOnInit(): void {
    //this.subscribeWebSocket();

    this.formatFields = this.data.influencer_fields.map(f => {
      return {name: f, id: f};
    });
    this.copyFormatFields = [...this.formatFields];

    this.formatHeaders = this.data.influencers_import_headers.map(h => {
      return {name: h, id: h};
    });

    this.csvFormatHeaders = this.data.csv_influencers_import_headers.map(h => {
      return {name: h, id: h};
    });
    let columnsFormat = this.data.influencers_import_headers.map(h => {
      return {data: null};
    });
    this.datatableSettings(columnsFormat);


    this.matchedHeaders = [...this.data.influencers_import_headers];
    this.matchFileHeadersToFields(this.matchedHeaders, this.data.influencer_fields);
    this.formatMatchedHeaders = this.matchedHeaders.map(h => {
      return {name: h, id: h};
    });

    if (this.dtElement && this.dtElement.dtInstance) {
      this.dtElement.dtInstance.then((dtInstance: any) => {
        dtInstance.destroy();
        this.dtTrigger.next();
      });
    } else {
      this.dtTrigger.next();
    }

  }

  ngAfterViewInit() {
    this.dtTrigger.next();
  }

  matchFileHeadersToFields(headers, fields){
    let copyFields = [...fields];
    copyFields.sort().reverse;
    headers.forEach((header, index) => {
      for (let i = 0; i < copyFields.length; i++) {
        if(copyFields[i].toLowerCase().includes(header.toLowerCase()) || header.toLowerCase().includes(copyFields[i].toLowerCase())){
          headers[index] = copyFields[i];
          copyFields.splice(i,1);
          break;
        } if ( header.toLowerCase() === 'skip') {
          headers[index] = 'skip';
        } else {
          headers[index] = '';
        }
      }
    });
  }

  changeHeader(event, index){
    this.matchedHeaders[index] = event.id;
  }

  filterSelectedFields(){
    this.copyFormatFields = this.formatFields.filter(f =>
      !this.matchedHeaders.includes(f.id));
    this.copyFormatFields.unshift({
      id: 'skip',
      name: 'skip',
    });
  }

  datatableSettings(columnsArray) {
    this.dtOptions = {
      scrollCollapse: true,
      pagingType: 'full_numbers',
      responsive: false,
      ordering: false,
      processing: true,
      //pageLength: 5,
      paging: false,
      info: false,
      serverSide: true,
      scrollX: true,
      searching: false,
            drawCallback: function (setting) {
        const totalPages = this.api().page?.info()?.pages || 0;
        if (totalPages <= 1) {
          document.querySelector('.dataTables_paginate').classList.add('d-none');
        } else {
          document.querySelector('.dataTables_paginate').classList.remove('d-none');
        }
      },
      ajax: (dataTablesParameters: any, callback) => {
        //console.log('ajax: ', dataTablesParameters);
        this.dtElement.dtInstance.then((dtInstance: any) => {
          this.datatableInstance = dtInstance;
          this.ajaxCallback = callback;
          const pageLength = dataTablesParameters.length;
          this.pageNumber = (dataTablesParameters.start / pageLength);
          (this.datatableInstance as any).page.len(pageLength);
          this.pageLength = pageLength;
          this.loadPaginatedCSV();
        });
      },
      columns: columnsArray,
    };
  }

  loadPaginatedCSV(){
    this.influencersImportTotal = this.data.influencers_values;

    this.influencersImportPage = this.getOnlyFirstFiveRows(
      this.influencersImportTotal);

    if (this.ajaxCallback) {
      this.ajaxCallback({
        recordsTotal: this.influencersImportTotal.length,
        recordsFiltered: this.influencersImportTotal.length,
        data: []
      });
      setTimeout(() => {
        (this.datatableInstance as any).columns.adjust();
      }, 500);
    }
  }

  getOnlyFirstFiveRows(array){
    return array.slice(0,5);
  }

  paginateArray(array, page_size, page_number) {
    return array.slice(page_number * page_size, (page_number + 1) * page_size);
  }

  upload(){
    if(this.checkColumns()){
      this.modal.close(this.matchedHeaders)
    }
  }

  checkColumns(){
    let totalVerifiedFields = ['email', 'first_name', 'last_name'];
    this.reduceFields(this.matchedHeaders, totalVerifiedFields);

    if(totalVerifiedFields.length > 0){
      let requiredColumns = '';
      for(let col of totalVerifiedFields) requiredColumns += col + ', ';
      requiredColumns.slice(0, -1);
      this.toastr.error(
        'The next missing columns are required: ' + requiredColumns, 'Error',
        {
          timeOut: 5000,
        });
      return false;
    }else{
      return true;
    }
  }

  reduceFields(headers, fields){
    headers.forEach((header) => {
      fields.forEach((field, index) => {
        if(header === field)
        fields.splice(index, 1);
      });
    });
  }

  convertArrayToCSV(array) {
    let str = '';
    for (let i = 0; i < array.length; i++) {
      let line = '';
      for (let j in array[i]) {
        line += array[i][j] + ',';
      }
      line = line.slice(0, -1);
      str += line + '\r\n';
    }
    return str;
  }

  ngOnDestroy() {
    this.unsubscriber.next();
    this.unsubscriber.complete();
    if (this.socketSubscription) {
      this.socketSubscription.unsubscribe();
    }
  }

}
