export interface IFileUploadState {
  file: any;
  uploaded: boolean;
  url: string;
  files: any[];
  success: string;
  error: string;
  progress: number;
}

export const initialFileUploadState: IFileUploadState = {
  file: null,
  uploaded: undefined,
  url: null,
  files: [],
  success: '',
  error: '',
  progress: 0
};
