import {createAction, props} from '@ngrx/store';
import {ReportModel} from '@app/modules/shared/models';

enum EReportActions {
  Report = '[Report] Report',
  ReportSuccess = '[Report] Report Success',
  ReportError = '[Report] Report Error',
  ReportPassword = '[Report] Report Password',
  ReportPasswordSuccess = '[Report] Report Password Success',
  ReportPasswordError = '[Report] Report Password Error',

  ReportAdvocatesExport = '[Report] Report Advocates Export',
  ReportAdvocatesExportSuccess = '[Report] Report Export Advocates Success',
  ReportAdvocatesExportError = '[Report] Report Advocates ExportError',

  ResetReportState = '[Report] Reset Report State',
}

export const Report = createAction(EReportActions.Report, props<{ projectId: number, params: any }>());
export const ReportSuccess = createAction(EReportActions.ReportSuccess, props<{ report: ReportModel }>());
export const ReportError = createAction(EReportActions.ReportError, props<{ error: string }>());

export const ReportAdvocatesExport = createAction(EReportActions.ReportAdvocatesExport, props<{ projectId: number, params: any }>());
export const ReportAdvocatesExportSuccess = createAction(EReportActions.ReportAdvocatesExportSuccess, props<{ projectsReportAdvocatesExport: ReportModel }>());
export const ReportAdvocatesExportError = createAction(EReportActions.ReportAdvocatesExportError, props<{ error: string }>());

export const ReportPassword = createAction(EReportActions.ReportPassword, props<{ projectId: number, password: string }>());
export const ReportPasswordSuccess = createAction(EReportActions.ReportPasswordSuccess, props<{ reportPassword: string }>());
export const ReportPasswordError = createAction(EReportActions.ReportPasswordError, props<{ error: string }>());

export const ResetReportState = createAction(EReportActions.ResetReportState, (params: any = {}) => params);

