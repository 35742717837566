import {Component, OnInit, Input} from '@angular/core';
import {ActivatedRoute} from '@angular/router';
import {TemplateGeneratorService} from '@app/services/template-generator.service';

@Component({
  selector: 'app-template-generator-component',
  templateUrl: './template-generator.component.html',
})
export class TemplateGeneratorComponent implements OnInit {
  constructor(
    private activatedRoute: ActivatedRoute,
    private templateGeneratorService: TemplateGeneratorService
  ) {
  }

  ngOnInit() {
    this.activatedRoute.params.subscribe((params) => {
      this.templateGeneratorService.setCampaign(params.campaignId);
    });
  }
}
