import {BrowserModule} from '@angular/platform-browser';
import '@angular/compiler';
import {APP_INITIALIZER, ErrorHandler, NgModule} from '@angular/core';
import {TranslateLoader, TranslateModule} from '@ngx-translate/core';
import {TranslateHttpLoader} from '@ngx-translate/http-loader';
import {ReactiveFormsModule, FormsModule} from '@angular/forms';
import {HTTP_INTERCEPTORS, HttpClient, HttpClientModule} from '@angular/common/http';
import {DataTablesModule} from 'angular-datatables';
import {NgxMaterialTimepickerModule} from 'ngx-material-timepicker';
import {BsDatepickerModule} from 'ngx-bootstrap/datepicker';
import {MatLegacySelectModule as MatSelectModule} from '@angular/material/legacy-select';
import {CommonModule} from '@angular/common';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {ToastrModule} from 'ngx-toastr';
// import {SlickCarouselModule} from 'ngx-slick-carousel';

import {routing} from './app.routing';

import {SharedModule} from './modules/shared/shared.module';
import {ServicesModule} from './services/services.module';

import {UserModule} from './modules/user/user.module';
import {RegistrationPlatformModule} from './modules/registration-platform/registration-platform.module';
import {ProjectModule} from './modules/project/project.module';
import {InfluencerModule} from './modules/influencer/influencer.module';
import {TemplateModule} from './modules/template/template.module';
import {CampaignModule} from './modules/campaign/campaign.module';

import {AppComponent} from './app.component';
import {LoginComponent} from './core/login';
import {RegisterComponent} from './core/register';
import {ForgotPasswordComponent} from './core/forgot-password';
import {ResetPasswordComponent} from './core/reset-password';
import {TemporaryLoginComponent} from './core/temporary-login';

import {PageNotFoundComponent} from '@app/core/page-not-found';
import {HelpComponent} from '@app/core/help';
import {ConfigsService} from '@app/services/configs.service';
import {AlertComponent} from '@app/_components';
import {Error404Component} from '@app/modules/shared/error404/error404.component';
import {StatisticsModule} from '@app/modules/statistics/statistics.module';
import {TemplateGeneratorModule} from '@app/modules/template-generator/template-generator.module';

import {BsDropdownModule} from 'ngx-bootstrap/dropdown';

import {PaginationModule} from 'ngx-bootstrap/pagination';
import {TabsModule} from 'ngx-bootstrap/tabs';

// FontAwesome Regular SVG Icons
import {
  faSquare,
  faCheckCircle,
  faTimesCircle,
  faDotCircle,
  faThumbsUp,
  faComments,
  faFolderOpen,
  faTrashAlt,
  faFileImage,
  faFileArchive,
  faLifeRing,
  faCommentDots,
  faFolder,
  faKeyboard,
  faCalendarAlt,
  faEnvelope,
  faAddressCard,
  faMap,
  faObjectGroup,
  faImages,
  faUser,
  faLightbulb,
  faGem,
  faClock,
  faUserCircle,
  faQuestionCircle,
  faBuilding,
  faBell,
  faFileExcel,
  faFileAudio,
  faFileVideo,
  faFileWord,
  faFilePdf,
  faFileCode,
  faFileAlt,
  faEye,
  faChartBar
} from '@fortawesome/free-regular-svg-icons';
// FontAwesome Solid SVG Icons
import {
  faChevronRight,
  faSitemap,
  faPrint,
  faMapMarkerAlt,
  faTachometerAlt,
  faAlignCenter,
  faExternalLinkAlt,
  faShareSquare,
  faInfoCircle,
  faSync,
  faQuoteRight,
  faStarHalfAlt,
  faShapes,
  faCarBattery,
  faTable,
  faCubes,
  faPager,
  faCameraRetro,
  faBomb,
  faNetworkWired,
  faBusAlt,
  faBirthdayCake,
  faEyeDropper,
  faUnlockAlt,
  faDownload,
  faAward,
  faPlayCircle,
  faReply,
  faUpload,
  faBars,
  faEllipsisV,
  faSave,
  faSlidersH,
  faCaretRight,
  faChevronUp,
  faPlus,
  faLemon,
  faChevronLeft,
  faTimes,
  faChevronDown,
  faFilm,
  faSearch,
  faEllipsisH,
  faCog,
  faArrowsAltH,
  faPlusCircle,
  faAngleRight,
  faAngleUp,
  faAngleLeft,
  faAngleDown,
  faArrowUp,
  faArrowDown,
  faArrowRight,
  faArrowLeft,
  faStar,
  faSignOutAlt,
  faLink
} from '@fortawesome/free-solid-svg-icons';
// FontAwesome Brand SVG Icons
import {
  faFacebook,
  faTwitter,
  faLinkedin,
  faAngular,
  faVuejs,
  faReact,
  faHtml5,
  faGoogle,
  faInstagram,
  faPinterest,
  faYoutube,
  faDiscord,
  faSlack,
  faDribbble,
  faGithub
} from '@fortawesome/free-brands-svg-icons';
// Angular FontAwesome Icons
import {FontAwesomeModule, FaIconLibrary} from '@fortawesome/angular-fontawesome';

import {AccordionModule} from 'ngx-bootstrap/accordion';
import {StoreModule} from '@ngrx/store';
import {appReducers, metaReducers} from '@app/stores/app.reducers';
import {EffectsModule} from '@ngrx/effects';
import {HttpAuthInterceptService} from '@app/services/http-intercept.service';
import {appEffects} from '@app/stores/app.effects';
import {ClientModule} from '@app/modules/client/client.module';
import { SocketService } from './services/socket.service';
import { LazyLoadImageModule } from 'ng-lazyload-image';
import {MatPasswordStrengthModule} from '@angular-material-extensions/password-strength';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import { environment } from '@environments/environment';
// import * as Sentry from '@sentry/angular';
import { Router } from '@angular/router';
import { PackagesModule } from './modules/packages/packages.module';

export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http);
}

@NgModule({
    imports: [
        BsDropdownModule.forRoot(),
        BrowserModule,
        CommonModule,
        HttpClientModule,
        TranslateModule.forRoot({
            loader: {
                provide: TranslateLoader,
                useFactory: HttpLoaderFactory,
                deps: [HttpClient]
            }
        }),
        BrowserModule,
        BrowserAnimationsModule,
        ToastrModule.forRoot({
            timeOut: 3000,
            positionClass: 'toast-top-center',
            preventDuplicates: true
        }),
        NgxMaterialTimepickerModule,
        BsDatepickerModule.forRoot(),
        MatSelectModule,
        ReactiveFormsModule,
        FormsModule,
        HttpClientModule,
        routing,
        SharedModule,
        DataTablesModule,
        UserModule,
        RegistrationPlatformModule,
        StatisticsModule,
        ProjectModule,
        InfluencerModule,
        ServicesModule,
        TemplateModule,
        CampaignModule,
        TemplateGeneratorModule,
        ClientModule,
        PackagesModule,
        // SlickCarouselModule,
        FontAwesomeModule,
        PaginationModule.forRoot(),
        AccordionModule.forRoot(),
        TabsModule.forRoot(),
        StoreModule.forRoot(appReducers, {
            metaReducers,
            runtimeChecks: {
                strictStateImmutability: true,
                strictActionImmutability: true
            }
        }),
        StoreDevtoolsModule.instrument({
          maxAge: 25, // Retains last 25 states
          logOnly: environment.production, // Restrict extension to log-only mode
          autoPause: true, // Pauses recording actions and state changes when the extension window is not open
        }),
        EffectsModule.forRoot(appEffects),
        LazyLoadImageModule,
        MatPasswordStrengthModule
    ],
  declarations: [
    AppComponent,
    LoginComponent,
    RegisterComponent,
    ForgotPasswordComponent,
    ResetPasswordComponent,
    TemporaryLoginComponent,
    PageNotFoundComponent,
    HelpComponent,
    AlertComponent,
    Error404Component],

  providers: [
    ConfigsService,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: HttpAuthInterceptService,
      multi: true
    },
    /*
    {
      provide: ErrorHandler,
      useValue: Sentry.createErrorHandler({
        showDialog: false,
      }),
    },
    {
      provide: Sentry.TraceService,
      deps: [Router],
    },
    {
      provide: APP_INITIALIZER,
      useFactory: () => () => {},
      deps: [Sentry.TraceService],
      multi: true,
    },
     */
    SocketService
  ],
  bootstrap: [AppComponent]
})
export class AppModule {
  constructor(library: FaIconLibrary) {
    library.addIcons(faFacebook, faPrint, faLinkedin, faAlignCenter, faMapMarkerAlt, faTachometerAlt,
      faExternalLinkAlt, faShareSquare, faSitemap, faInfoCircle, faLifeRing, faTwitter, faQuoteRight, faStarHalfAlt,
      faSync, faShapes, faCarBattery, faTable, faCubes, faPager, faAngular, faVuejs, faReact, faHtml5, faCheckCircle,
      faTimesCircle, faBomb, faNetworkWired, faBusAlt, faBirthdayCake, faEyeDropper, faThumbsUp, faCameraRetro, faUnlockAlt,
      faDownload, faUpload, faReply, faGoogle, faFileImage, faFolderOpen, faBars, faTrashAlt, faSave, faPlayCircle, faEllipsisV,
      faEllipsisH, faSlidersH, faFileArchive, faAward, faCaretRight, faInstagram, faPinterest, faYoutube, faDiscord, faSlack, faDribbble,
      faGithub, faPlus, faFolder, faTimes, faEnvelope, faAddressCard, faMap, faCalendarAlt, faImages, faFilm, faClock, faSearch,
      faChevronRight, faChevronUp, faChevronLeft, faChevronDown, faLink, faLightbulb, faGem, faCog, faDotCircle, faArrowsAltH, faComments,
      faCommentDots, faKeyboard, faObjectGroup, faUser, faUserCircle, faQuestionCircle, faBuilding, faBell, faFileExcel, faFileAudio,
      faFileVideo, faFileWord, faFilePdf, faFileCode, faFileAlt, faEye, faChartBar, faPlusCircle, faAngleRight, faAngleUp, faAngleLeft,
      faAngleDown, faArrowUp, faArrowDown, faArrowRight, faArrowLeft, faStar, faSignOutAlt, faLemon);
  }
}
