import {Action, createReducer, on} from '@ngrx/store';
import {
  SettingsList,
  SettingsListError,
  SettingsListSuccess,
  SettingUpdate,
  SettingUpdateSuccess,
  SettingUpdateError,
  SettingDelete,
  SettingDeleteSuccess,
  SettingDeleteError,
  SettingAdd,
  SettingAddSuccess,
  SettingAddError,
  ResetSettingState,
  SettingsListAll,
  SettingsListAllSuccess,
  SettingsListAllError,
  SettingGet,
  SettingGetSuccess, SettingGetError
} from '@app/stores/settings/settings.actions';
import {initialSettingState} from '@app/stores/settings/settings.state';

const generateReducer = createReducer(initialSettingState,
  on(SettingsListAll, (state) => ({
    ...state,
    setting: null,
    settings: [],
    success: '',
    error: ''
  })),
  on(SettingsListAllSuccess, (state, {settings}) => ({
    ...state,
    setting: null,
    settings,
    success: '',
    error: ''
  })),
  on(SettingsListAllError, (state, {error}) => ({
    ...state,
    setting: null,
    settings: [],
    success: '',
    error
  })),
  on(SettingsList, (state) => ({
    ...state,
    setting: null,
    paginatedSettings: null,
    success: '',
    error: ''
  })),
  on(SettingsListSuccess, (state, {paginatedSettings}) => ({
    ...state,
    setting: null,
    paginatedSettings,
    success: '',
    error: ''
  })),
  on(SettingsListError, (state, {error}) => ({
    ...state,
    setting: null,
    paginatedSettings: null,
    success: '',
    error
  })),
  on(SettingUpdate, (state) => ({
    ...state,
    success: '',
    error: ''
  })),
  on(SettingUpdateSuccess, (state) => ({
    ...state,
    success: 'Setting updated successfully',
    error: ''
  })),
  on(SettingUpdateError, (state, {error}) => ({
    ...state,
    success: '',
    error
  })),
  on(SettingDelete, (state) => ({
    ...state,
    success: '',
    error: ''
  })),
  on(SettingDeleteSuccess, (state) => ({
    ...state,
    success: 'Setting deleted successfully',
    error: ''
  })),
  on(SettingDeleteError, (state, {error}) => ({
    ...state,
    success: '',
    error
  })),
  on(SettingAdd, (state) => ({
    ...state,
    success: '',
    error: ''
  })),
  on(SettingAddSuccess, (state) => ({
    ...state,
    success: 'Setting added successfully',
    error: ''
  })),
  on(SettingAddError, (state, {error}) => ({
    ...state,
    success: '',
    error
  })),
  on(SettingGet, (state) => ({
    ...state,
    setting: null,
    success: '',
    error: ''
  })),
  on(SettingGetSuccess, (state, {setting}) => ({
    ...state,
    setting,
    success: '',
    error: ''
  })),
  on(SettingGetError, (state, {error}) => ({
    ...state,
    setting: null,
    success: '',
    error
  })),

  on(ResetSettingState, (state, {params}) => ({
    ...state,
    ...params
  })),
);

export function settingsReducer(state = initialSettingState, action: Action) {
  return generateReducer(state, action);
}
