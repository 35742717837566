import {Injectable} from '@angular/core';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {BehaviorSubject, Observable} from 'rxjs';
import {environment} from '@environments/environment';

@Injectable({providedIn: 'root'})
export class AuthenticationService {
  private baseApiUrl = environment.apiUrl;

  constructor(private http: HttpClient) {
  }

  register(user: any) {
    return this.http.post(`${this.baseApiUrl}/api/v1/user/register`, user, {
      headers: new HttpHeaders().set('Skip-auth', 'true')
    });
  }

  loginWithId(userId: number) {
    return this.http.post(`${this.baseApiUrl}/api/v1/user/${userId}/loginWithId`, {});
  }

  resetPassword(password: string, id: string) {
    return this.http.post(`${this.baseApiUrl}/api/v1/user/resetPassword/${id}`, {password}, {
      headers: new HttpHeaders().set('Skip-auth', 'true')
    });
  }

  tokenLogin(token: string) {
    return this.http.post(`${this.baseApiUrl}/api/v1/user/refreshToken`, {token}, {
      headers: new HttpHeaders().set('Skip-auth', 'true')
    });
  }

  resetPasswordToken(token: string) {
    return this.http.post(`${this.baseApiUrl}/api/v1/user/resetPasswordTokenValidate`, {token}, {
      headers: new HttpHeaders().set('Skip-auth', 'true')
    });
  }

  login(email: string, password: string, remember: boolean): Observable<any> {
    return this.http.post(`${this.baseApiUrl}/api/v1/user/login`, {email, password, remember}, {
      headers: new HttpHeaders().set('Skip-auth', 'true')
    });
  }

  password(user: any) {
    return this.http.post(`${this.baseApiUrl}/api/v1/user/forgotPassword`, user, {
      headers: new HttpHeaders().set('Skip-auth', 'true')
    });
  }
}
