import {createFeatureSelector, createSelector} from '@ngrx/store';
import {ITrackingTypeState} from "@app/stores";

const selectTrackingType = createFeatureSelector<ITrackingTypeState>('trackingType');

export const getTrackingType = createSelector(selectTrackingType, (state: ITrackingTypeState) => state.trackingType);

export const getTrackingTypeSuccess = createSelector(selectTrackingType, (state: ITrackingTypeState) => state.success);

export const getTrackingTypeError = createSelector(selectTrackingType, (state: ITrackingTypeState) => state.error);
