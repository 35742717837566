<div class="container-fluid bg-white">
  <div class="row">
    <!-- LogIn Left Section  Start -->
    <div class="col-lg-5 col-md-12 d-flex justify-content-center">
      <div class="align-self-center w-100-small-device">
        <div class="logo text-center mt-2">
          <img src="../../assets/images/logo.png"/>
        </div>
        <div class="login-heading"><h1>Start a snöball</h1></div>
        <div class="col-12 tittle text-center line-h-120">
          <span class="d-block"> Change snöball account Password?</span>
        </div>
        <form (ngSubmit)="onSubmit()" [formGroup]="resetPasswordForm">
          <div class="card custom-card">
            <h4 class="mt-5 mb-3">Change Password</h4>
            <div class="row">
              <div class="col-md-12">
                <div class="floating-label input-group input-group-sm variable-input">
                  <input [ngClass]="{ 'is-invalid': submitted && f.password.errors }" formControlName="password"
                         required="required" id="password" class="floating-input" placeholder=" "
                         [type]="showPasswords.password ? 'text' : 'password'"/>
                  <label for="password">Password</label>
                  <div *ngIf="submitted && f.password.errors" class="bar error-brd confirm-password-error">
                    <div *ngIf="f.password.errors?.required" class="invalid-text">Password is required</div>
                    <div *ngIf="f.password.errors?.passwordRegex" class="invalid-text">Invalid Password</div>
                  </div>
                  <div class="inputs">
                    <button type="button" class="btn btn-default btn-sm ml-1 show-password"
                            (click)="showPasswords.password = !showPasswords.password">
                      <i class="fa"
                         [ngClass]="{'fa-eye': !showPasswords.password, 'fa-eye-slash': showPasswords.password}"></i>
                    </button>
                  </div>
                </div>
                <div class="mt-4">
                  <mat-password-strength #passwordComponent (onStrengthChanged)="updatePasswordStrength($event)"
                                         [password]="f.password.value">
                  </mat-password-strength>
                  <mat-password-strength-info
                    [passwordComponent]="passwordComponent">
                  </mat-password-strength-info>
                </div>
              </div>
              <div class="col-md-12">
                <div class="floating-label input-group input-group-sm variable-input">
                  <input [ngClass]="{ 'is-invalid': submitted && f.confirm_password.errors }"
                         formControlName="confirm_password" id="confirm_password"
                         required="required" class="floating-input" placeholder=" "
                         [type]="showPasswords.confirm_password ? 'text' : 'password'"/>
                  <label for="confirm_password">Confirm Password</label>
                  <div *ngIf="submitted && (f.confirm_password.errors)" class="bar error-brd confirm-password-error">
                    <div *ngIf="f.confirm_password.errors?.required" class="invalid-text">Confirm Password is required</div>
                    <div *ngIf="f.confirm_password.errors?.confirmPasswordValidator" class="invalid-text">Password does
                      not match
                    </div>
                  </div>
                  <div class="inputs">
                    <button type="button" class="btn btn-default btn-sm ml-1 show-password"
                            (click)="showPasswords.confirm_password = !showPasswords.confirm_password">
                      <i class="fa"
                         [ngClass]="{'fa-eye': !showPasswords.confirm_password, 'fa-eye-slash': showPasswords.confirm_password}"></i>
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="m-auto text-center">
            <button class="btn btn-primary blue-color login-btn mt-5 mb-2" type="submit">Save
            </button>
          </div>
        </form>
      </div>
    </div>
    <!-- LogIn Left Section End -->
    <!-- LogIn right Section  Start -->
    <div class="col-lg-7 col-md-12 blue-bg login-right-container d-flex justify-content-center">
      <div class="align-self-center  w-100-small-device">
        <div class="col-md-12">
          <h1 class="right-panel-heading m-B-64">92% Trust
            <br/> Word-of-Mouth
          </h1>
        </div>
        <div class="col-md-12">
          <p class="sub-info">
            Your speakers, exhibitors,
            <br/> and attendees can help
            <br/> promote your event.
          </p>
        </div>
        <div class="small-divice-text-center m-T116 m-B116">
          <a class="btn outline-white-brd-btn" type="submit" href="https://snoball.events/book-demo3/" target="_blank" style="text-decoration: none;">Tell me more</a>
        </div>
      </div>
    </div>
    <!-- LogIn right Section  End -->
  </div>
</div>
