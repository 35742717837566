import {AfterViewInit, Component, OnDestroy, OnInit, TemplateRef, ViewChild} from '@angular/core';
import {DataTableDirective} from 'angular-datatables';
import {BehaviorSubject, Subject} from 'rxjs';
import * as routes from '@app/routes';
import {select, Store} from '@ngrx/store';
import {
  getInfluencersProjectSegment,
  getInfluencersSyncError, getInfluencersTypes,
  getProject,
  getProjectError,
  getProjectSuccess,
  getProjectSuccessResponse,
  getRegistrationPlatformError,
  getRegistrationPlatformPaginated,
  getRegistrationPlatformSuccess,
  getSyncInfluencers,
  IAuthenticationState,
  IInfluencersState,
  InfluencersProjectSegment,
  InfluencersSync,
  InfluencersSyncStatus, InfluencersTypeList,
  IProjectState,
  IRegistrationPlatformState,
  ProjectGet,
  ProjectUpdate,
  RegistrationPlatformListPaginated,
  RegistrationPlatformUpdate,
  ResetAuthState,
  ResetRegistrationPlatformState,
} from '@app/stores';
import {ActivatedRoute, Router} from '@angular/router';
import {ToastrService} from 'ngx-toastr';
import {takeUntil} from 'rxjs/operators';
import {Constants} from '@app/consts';
import {SocketService} from '@app/services/socket.service';
import {UntypedFormArray, UntypedFormBuilder, UntypedFormGroup, Validators} from '@angular/forms';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {Observable} from 'rxjs/Observable';
import {LoaderService} from '@app/services/loader.service';
import * as moment from 'moment-timezone';
import {RegistrationPlatformService} from '@app/services/registration-platform.service';
import {CreatableSelectComponent} from '@app/_components/creatable-select';
import {TaskLogsComponent} from '@app/_components/task-logs/task-logs.component';
import {environment} from "@environments/environment";
import * as DataTables from 'datatables.net';

@Component({
  selector: 'app-thirdparty-platforms',
  templateUrl: './thirdparty-platforms.component.html',
  styleUrls: ['./thirdparty-platforms.component.css'],
  providers: [Constants]
})
export class ThirdpartyPlatformsComponent implements OnInit, OnDestroy, AfterViewInit  {
  @ViewChild(DataTableDirective)
  dtElement: DataTableDirective;
  @ViewChild('logsTable', {static: true}) logsTable: TemplateRef<any>;
  dtOptions: any = {};
  unsubscriber = new Subject();
  dtTrigger: Subject<any> = new Subject();
  ajaxCallback: any;
  private datatableInstance: DataTables.Api<any>;
  private searchDelay;
  routes = routes;
  loader$: Observable<boolean>;
  public registrationPlatformList = [];
  deleteAction = false;
  updateAction = false;
  loginWithUser = false;
  selectedClient = 'All';
  projectId: any;
  importData: Array<Object>;
  public importJobId: number;
  public socketSubscription: any;
  public importProgress: any = {
    status: 'started',
    progress: 0
  };
  public loaderImport = new BehaviorSubject<boolean>(false);
  public loaderImport$ = this.loaderImport.asObservable();
  platformName: '';
  apiIntegrationGuide: '';
  platformId: '';
  authSwapcardForm: UntypedFormGroup;
  authLineUpNinjaForm: UntypedFormGroup;
  authSessionBoardForm: UntypedFormGroup;
  authTerrapinSalesforceForm: UntypedFormGroup;
  authMapYourShowForm: UntypedFormGroup;
  authPersonifyA2ZForm: UntypedFormGroup;
  submitted = false;
  authProjectRegistrationPlatform = {};
  savePlatformName = '';
  modelRef: any;
  currentIndex = 0;
  currentPlatform: any;
  currentSyncId = 0;
  currentSyncPlatform: any;
  isSwapcardFormValid = true;
  isLienUpNinjaFormValid = true;
  isTerrapinSalesforceFormValid = true;
  isSessionBoardFormValid = true;
  isMapYourShowFormValid = true;
  project: any;
  currentModel: any;
  isWebHookAdd = false;
  updateProjectAction = false;
  segments: any = [];
  attendeeSegments: any = [];
  speakerSegments: any = [];
  partnerSegments: any = [];
  selectedSpeakerSegment: any = null;
  selectedAttendeeSegment: any = null;
  selectedPartnerSegment: any = null;

  selectedSalesForceSpeakerSegment: any = null;
  selectedSalesForceOtherAttendeeSegment: any = null;
  selectedSalesForceSponsorSegment: any = null;
  selectedSalesForceExhibitorSegment: any = null;
  selectedSalesForceGuestSegment: any = null;
  selectedMYSExhibitorSegment: any = null;
  selectedMYSSpeakerSegment: any = null;
  influencerTypes = [];

  isSegmentSelect = '';
  isSubmit = false;
  directCall = false;
  uniqueSyncString: any;
  apiIntegrationModelRef = null;
  importDataTableOptions: any = {
    ordering: false,
    processing: true,
    pagingType: 'full_numbers',
    pageLength: 10,
    paging: true,
    searching: true,
    drawCallback: function (setting) {
      const totalPages = this.api().page.info().pages;
      if (totalPages <= 1) {
        document.querySelector('.dataTables_paginate').classList.add('d-none');
      } else {
        document.querySelector('.dataTables_paginate').classList.remove('d-none');
      }
    }
  };
  @ViewChild('speakerSegment') speakerSegment: CreatableSelectComponent;
  @ViewChild('attendeeSegment') attendeeSegment: CreatableSelectComponent;
  @ViewChild('partnerSegment') partnerSegment: CreatableSelectComponent;
  handle_deletion_value = 'skip_on_snoball';
  shareSyncLinkUrl = '';
  constructor(
    private registrationPlatformStore: Store<IRegistrationPlatformState>,
    private influencersStore: Store<IInfluencersState>,
    private registrationPlatformService: RegistrationPlatformService,
    private projectStore: Store<IProjectState>,
    private authStore: Store<IAuthenticationState>,
    private router: Router,
    private toastrService: ToastrService,
    private route: ActivatedRoute,
    private loaderService: LoaderService,
    private toastr: ToastrService,
    private readonly socketService: SocketService,
    private _modalService: NgbModal,
    private formBuilder: UntypedFormBuilder,
    public constants: Constants,
  ) {
    this.socketService.resetSocketData();
    this.registrationPlatformStore.dispatch(ResetRegistrationPlatformState({params: {error: '', paginatedRegistrationPlatform: null, influencersSync: null}}));
    this.authStore.dispatch(ResetAuthState({params: {error: '', success: ''}}));
    this.route.params.subscribe(params => {
      if (params.projectId) {
        this.projectId = +params.projectId;
        this.loadProject(this.projectId);
      }
    });
  }

  subscribeStores() {
    this.projectStore.pipe(select(getProjectError))
      .pipe(takeUntil(this.unsubscriber))
      .subscribe(error => {
        if (error) {
          this.toastrService.error(error, 'Error');
        }
      });
    this.projectStore.pipe(select(getProjectSuccess))
      .pipe(takeUntil(this.unsubscriber))
      .subscribe(success => {
        if (success) {
          if (this.isWebHookAdd && this.updateProjectAction) {
            this.updateProjectAction = false;
            this.loadAllPlatforms();
          }
        }
      });
    this.influencersStore.pipe(select(getInfluencersTypes))
      .pipe(takeUntil(this.unsubscriber))
      .subscribe(influencerType => {
        this.influencerTypes = [];
        if (influencerType && influencerType.length > 0) {
          influencerType.forEach(item => {
            this.influencerTypes.push({id: item.slug, name: item.name});
          });
        }
      });
    this.projectStore.pipe(select(getProjectSuccessResponse))
      .pipe(takeUntil(this.unsubscriber))
      .subscribe(updateProject => {
        if (updateProject) {
          this.project = updateProject;
          this.loadAllPlatforms();
          this.submitted = false;
          this.currentSyncId = 0;
        }
      });
    this.influencersStore.pipe(select(getInfluencersProjectSegment))
      .pipe(takeUntil(this.unsubscriber))
      .subscribe(segments => {
        if (segments && segments.length > 0) {
          this.segments = segments;
          this.attendeeSegments = this.segments.filter(x => x.influencer_type_id === 3);
          this.speakerSegments = this.segments.filter(x => x.influencer_type_id === 5);
          this.partnerSegments = this.segments.filter(x => x.influencer_type_id === 6);
        }
      });
    this.projectStore.pipe(select(getProject))
      .pipe(takeUntil(this.unsubscriber))
      .subscribe(project => {
        if (project) {
          this.project = project;
          if (this.directCall) {
            // const reg_platform = (typeof project.registration_platforms === 'string' ? JSON.parse(project.registration_platforms) : project.registration_platforms);
            // this.getUpdateRegistrationPlatform(reg_platform, this.savePlatformName);
            this.importProgress = {
              status: 'started',
              progress: 0
            };
            this.directCall = false;
          }
          if (project.registration_platforms) {
            this.authProjectRegistrationPlatform =  {...(typeof project.registration_platforms === 'string' ? JSON.parse(project.registration_platforms) : project.registration_platforms)};
            if (this.authProjectRegistrationPlatform.hasOwnProperty(this.savePlatformName)) {
              this.authSwapcardForm.patchValue({
                auth_key: this.authProjectRegistrationPlatform[this.savePlatformName].auth_key,
                // attendee_key: this.authProjectRegistrationPlatform[this.savePlatformName].attendee_key,
                // speaker_key: this.authProjectRegistrationPlatform[this.savePlatformName].speaker_key,
                // partner_key: this.authProjectRegistrationPlatform[this.savePlatformName].partner_key,
                event_id: this.authProjectRegistrationPlatform[this.savePlatformName].event_id,
                webhook: this.authProjectRegistrationPlatform[this.savePlatformName].webhook,
                speaker_segment_id: Number(this.authProjectRegistrationPlatform[this.savePlatformName].speaker_segment_id),
                attendee_segment_id: Number(this.authProjectRegistrationPlatform[this.savePlatformName].attendee_segment_id),
                partner_segment_id: Number(this.authProjectRegistrationPlatform[this.savePlatformName].partner_segment_id),
              });
              if (this.savePlatformName && this.authProjectRegistrationPlatform[this.savePlatformName].handle_deletion){
                this.handle_deletion_value =  this.authProjectRegistrationPlatform[this.savePlatformName].handle_deletion;
              }

              if (this.savePlatformName === 'swapcard' && (this.authProjectRegistrationPlatform[this.savePlatformName]?.influencer_keys?.length === 0 || !this.authProjectRegistrationPlatform[this.savePlatformName]?.influencer_keys)) {
                this.influencerKeysFormGroup.push(this.addInfluencerKeysGroup());
              }

              if (this.savePlatformName && this.authProjectRegistrationPlatform[this.savePlatformName]?.influencer_keys?.length) {
                this.authProjectRegistrationPlatform[this.savePlatformName]?.influencer_keys.forEach(index => {
                  this.influencerKeysFormGroup.push(
                    this.formBuilder.group({
                      name: [index.name],
                      category: [index.category]
                    })
                  );
                });
              }
             /* if(this.savePlatformName === 'swapcard' && this.authProjectRegistrationPlatform[this.savePlatformName].handle_deletion){
                this.handle_deletion_value =  this.authProjectRegistrationPlatform[this.savePlatformName].handle_deletion;
              }
              if(this.savePlatformName === 'terrapin-salesforce' && this.authProjectRegistrationPlatform[this.savePlatformName].handle_deletion){
                this.handle_deletion_value =  this.authProjectRegistrationPlatform[this.savePlatformName].handle_deletion;
              }*/
              this.authLineUpNinjaForm.patchValue({
                webhook: this.authProjectRegistrationPlatform[this.savePlatformName].webhook,
                username: this.authProjectRegistrationPlatform[this.savePlatformName].username,
                password: this.authProjectRegistrationPlatform[this.savePlatformName].password,
                api_url: this.authProjectRegistrationPlatform[this.savePlatformName].api_url,
                speaker_segment_id: Number(this.authProjectRegistrationPlatform[this.savePlatformName].speaker_segment_id),
              });
              this.authTerrapinSalesforceForm.patchValue({
                project_edition_id: this.authProjectRegistrationPlatform[this.savePlatformName]?.project_edition_id,
                speaker_segment_id: Number(this.authProjectRegistrationPlatform[this.savePlatformName].speaker_segment_id),
                other_attendee_segment_id: Number(this.authProjectRegistrationPlatform[this.savePlatformName].other_attendee_segment_id),
                sponsor_segment_id: Number(this.authProjectRegistrationPlatform[this.savePlatformName].sponsor_segment_id),
                exhibitor_segment_id: Number(this.authProjectRegistrationPlatform[this.savePlatformName].exhibitor_segment_id),
                guest_segment_id: Number(this.authProjectRegistrationPlatform[this.savePlatformName].guest_segment_id),
              });

              this.authSessionBoardForm.patchValue({
                event_id: this.authProjectRegistrationPlatform[this.savePlatformName]?.event_id,
                access_token: this.authProjectRegistrationPlatform[this.savePlatformName]?.access_token,
                speaker_segment_id: Number(this.authProjectRegistrationPlatform[this.savePlatformName].speaker_segment_id),
                // attendee_segment_id: Number(this.authProjectRegistrationPlatform[this.savePlatformName].attendee_segment_id),
                partner_segment_id: Number(this.authProjectRegistrationPlatform[this.savePlatformName].partner_segment_id),
              });

              this.authMapYourShowForm.patchValue({
                show_code: this.authProjectRegistrationPlatform[this.savePlatformName]?.show_code,
                exhibitor_segment_id: Number(this.authProjectRegistrationPlatform[this.savePlatformName].exhibitor_segment_id),
                speaker_segment_id: Number(this.authProjectRegistrationPlatform[this.savePlatformName].speaker_segment_id) || null,
              });

              this.authPersonifyA2ZForm.patchValue({
                email: this.authProjectRegistrationPlatform[this.savePlatformName].email,
                event_id: this.authProjectRegistrationPlatform[this.savePlatformName].event_id,
                tenant_id: this.authProjectRegistrationPlatform[this.savePlatformName].tenant_id,
                client_id: this.authProjectRegistrationPlatform[this.savePlatformName].client_id,
                client_secret: this.authProjectRegistrationPlatform[this.savePlatformName].client_secret,
                partner_segment_id: Number(this.authProjectRegistrationPlatform[this.savePlatformName].partner_segment_id)
              });

              if (this.savePlatformName === 'terrapin-salesforce') {
                this.selectedSalesForceSpeakerSegment = this.authProjectRegistrationPlatform[this.savePlatformName].speaker_segment_id || null;
                this.selectedSalesForceOtherAttendeeSegment = this.authProjectRegistrationPlatform[this.savePlatformName].other_attendee_segment_id || null;
                this.selectedSalesForceSponsorSegment = this.authProjectRegistrationPlatform[this.savePlatformName].sponsor_segment_id || null;
                this.selectedSalesForceExhibitorSegment = this.authProjectRegistrationPlatform[this.savePlatformName].exhibitor_segment_id || null;
                this.selectedSalesForceGuestSegment = this.authProjectRegistrationPlatform[this.savePlatformName].guest_segment_id || null;
              } else if (this.savePlatformName === 'map-your-show') {
                this.selectedMYSExhibitorSegment = this.authProjectRegistrationPlatform[this.savePlatformName].exhibitor_segment_id || null;
                this.selectedMYSSpeakerSegment = this.authProjectRegistrationPlatform[this.savePlatformName].speaker_segment_id || null;
              } else {
                this.selectedAttendeeSegment = this.authProjectRegistrationPlatform[this.savePlatformName].attendee_segment_id || null;
                this.selectedSpeakerSegment = this.authProjectRegistrationPlatform[this.savePlatformName].speaker_segment_id || null;
                this.selectedPartnerSegment = this.authProjectRegistrationPlatform[this.savePlatformName].partner_segment_id || null;
              }

            }
          } else {
            this.authProjectRegistrationPlatform = {};
          }
        }
      });
    this.registrationPlatformStore.pipe(select(getSyncInfluencers))
      .pipe(takeUntil(this.unsubscriber))
      .subscribe(influencersSync => {
        if (influencersSync) {
          if (influencersSync !== true) {
            let errorMessage = '';
            if (!influencersSync.success) {
              this.importJobId = influencersSync.data?.jobId;
              if (influencersSync.error) {
                if (influencersSync.error.name === 'AppTimeoutError') {
                  errorMessage = 'Upload timeout';
                }
                if (influencersSync.error.name === 'AppError') {
                  errorMessage = influencersSync.message;
                }
                if (influencersSync.error.name === 'AppValidationError') {
                  errorMessage = influencersSync.error.orgMessage || 'Invalid Records';
                  this.importData = influencersSync.data;
                  this.showSyncLogs();
                }
              }
              if (errorMessage) {
                this.toastr.error(errorMessage, 'Error');
              }
            }
          } else {
            // this.modal.close();
          }
        }
      });
    this.registrationPlatformStore.pipe(select(getRegistrationPlatformError))
      .pipe(takeUntil(this.unsubscriber))
      .subscribe(error => {
        if (error) {
          this.toastrService.error(error, 'Error');
        }
      });

    this.registrationPlatformStore.pipe(select(getInfluencersSyncError))
      .pipe(takeUntil(this.unsubscriber))
      .subscribe(error => {
        if (error) {
          this.loaderImport.next(false);
          this.toastrService.error(error, 'Error');
        }
      });

    this.registrationPlatformStore.pipe(select(getRegistrationPlatformSuccess))
      .pipe(takeUntil(this.unsubscriber))
      .subscribe(success => {
        if (success) {
          if (this.deleteAction || this.updateAction) {
            this.deleteAction = false;
            this.updateAction = false;
            this.loadAllPlatforms();
          }
        }
      });

    this.registrationPlatformStore.pipe(select(getRegistrationPlatformPaginated))
      .pipe(takeUntil(this.unsubscriber))
      .subscribe((paginatedRegistrationPlatform: any) => {
          if (paginatedRegistrationPlatform) {
            this.registrationPlatformList = paginatedRegistrationPlatform.list;
            if (this.ajaxCallback) {
              this.ajaxCallback({
                recordsTotal: paginatedRegistrationPlatform.paging.total,
                recordsFiltered: paginatedRegistrationPlatform.paging.total,
                data: []
              });
              setTimeout(() => {
                (this.datatableInstance as any).columns.adjust();
              }, 500);
            }
            if (this.isWebHookAdd) {
              this.showPlatformJsonPopup(this.currentModel, this.currentPlatform);
              this.isWebHookAdd = false;
            }
          }
        }
      );
  }

  subscribeToSocket() {
    this.socketSubscription = this.socketService.sourceData.subscribe(
      (message: any) => {
        if (message?.data?.requesterType === 'influencer_sync' && (!this.importJobId || message?.data?.id === this.importJobId)) {
          this.loaderImport.next(true);
          this.currentSyncId = message.data.platform_id;
          this.savePlatformName = message.data.platform_name;
          //
          // Set job id
          this.importJobId = message.data.id;

          //
          // Update import progress
          this.importProgress.status = message.data?.status;
          this.importProgress.progress = message?.data?.progress;
          let reg_platform;
          // adjust loading state for finished/failed
          if (['finished', 'failed'].includes(this.importProgress.status)) {
            setTimeout(() => {
              this.loaderImport.next(false);
              // Keep status
              const status: string = this.importProgress?.status;
              reg_platform = {...(typeof this.project.registration_platforms === 'string' ? JSON.parse(this.project.registration_platforms) : this.project.registration_platforms)};
              if (status === 'failed') {
                this.syncStatus();
                this.importProgress = {
                  status: 'started',
                  progress: 0
                };
              } else if (status === 'finished' && this.savePlatformName) { // On finished(success) should close modal, clear job id and data
                this.importJobId = null;
                this.importData = null;
                this.toastr.success('Sync successful', 'Success');
                if (!this.isSubmit) {
                  this.directCall = true;
                } else {
                  // this.getUpdateRegistrationPlatform(reg_platform, this.savePlatformName);
                  this.isSubmit = false;
                }
                this.loadProject(this.projectId);
                this.importProgress = {
                  status: 'started',
                  progress: 0
                };
                this.savePlatformName = '';
              }
            }, 1500);
          }
        }
      },
      (err) => console.error(err),
      () => console.warn('Completed!')
    );
  }

  ngOnInit() {
    this.subscribeStores();
    this.datatableSettings();
    this.loader$ = this.loaderService.loader$;
    this.authSwapcardForm = this.formBuilder.group({
      auth_key: ['', Validators.required],
      influencer_keys: this.formBuilder.array([]),
      // attendee_key: [''],
      // speaker_key: [''],
      // partner_key: [''],
      webhook: [''],
      event_id: ['', Validators.required],
      attendee_segment_id: [null],
      partner_segment_id: [null],
      speaker_segment_id: [null],
      // handle_deletion: [''],
    });
    this.authLineUpNinjaForm = this.formBuilder.group({
      webhook: [''],
      api_url: ['', Validators.pattern(this.constants.urlRegex)],
      username: [''],
      password: [''],
      speaker_segment_id: [null],
      // handle_deletion: [''],
    });

    this.authTerrapinSalesforceForm = this.formBuilder.group({
      project_edition_id: ['', Validators.required],
      speaker_segment_id: [null],
      other_attendee_segment_id: [null],
      sponsor_segment_id: [null],
      exhibitor_segment_id: [null],
      guest_segment_id: [null],
    });

    this.authSessionBoardForm = this.formBuilder.group({
      event_id: ['', Validators.required],
      access_token: ['', Validators.required],
      // attendee_segment_id: [null],
      partner_segment_id: [null],
      speaker_segment_id: [null],
    });

    this.authMapYourShowForm = this.formBuilder.group({
      show_code: ['', Validators.required],
      exhibitor_segment_id: [null],
      speaker_segment_id: [null],
      // handle_deletion: [''],
    });

    this.authPersonifyA2ZForm = this.formBuilder.group({
      email: ['', Validators.required],
      event_id: ['', [Validators.required, Validators.pattern('^[0-9]+$')]],
      tenant_id: ['', Validators.required],
      client_id: ['', Validators.required],
      client_secret: ['', Validators.required],
      partner_segment_id: [null],
    });

    if (this.dtElement && this.dtElement.dtInstance) {
      this.dtElement.dtInstance.then((dtInstance: any) => {
        dtInstance.destroy();
        this.dtTrigger.next();
      });
    } else {
      this.dtTrigger.next();
    }
    this.subscribeToSocket();
    this.getInfluencerTypes();
  }

  ngAfterViewInit() {
    this.dtTrigger.next();
  }

  datatableSettings() {
    this.dtOptions = {
      scrollCollapse: true,
      pagingType: 'full_numbers',
      responsive: false,
      ordering: false,
      columnDefs: [{orderable: false, targets: 'no-sort'}],
      processing: true,
      pageLength: 10,
      paging: true,
      serverSide: true,
      scrollX: true,
      searching: true,
      scrollY: "100vh",
      fixedHeader: {
        header: true,
        footer: true
      },
      fixedColumns: {
        right: 1,
        left: 0
      },
      drawCallback: function (setting) {
        const table = this.api().table().node();
        const totalPages = this.api().page.info().pages;
        if (totalPages <= 1) {
          document.querySelector('.dataTables_paginate').classList.add('d-none');
        } else {
          document.querySelector('.dataTables_paginate').classList.remove('d-none');
        }
      },
      ajax: (dataTablesParameters: any, callback) => {
        this.dtElement.dtInstance.then((dtInstance: any) => {
          this.datatableInstance = dtInstance;
          this.ajaxCallback = callback;
          const pageLength = dataTablesParameters.length;
          const pageNumber = (dataTablesParameters.start / pageLength);
          (this.datatableInstance as any).page.len(pageLength);
          const searchBox = $('div.dataTables_filter input');
          searchBox.off('keyup.DT input.DT');
          searchBox.on('keyup', () => {
            const search: any = searchBox.val();
            clearTimeout(this.searchDelay);
            this.searchDelay = setTimeout(() => {
              if (search != null) {
                (this.datatableInstance as any).search(search).draw();
              }
            }, 1000);
          });
          this.loadAllPlatforms(pageNumber, pageLength, dataTablesParameters.search.value);
        });
      },
      columns: [
        {data: null}, {data: null}, {data: null}, {data: null}, {data: null}, {data: null},
        {data: null}, {data: null}, {data: null}, {data: null}, {data: null}, {data: null},
        {data: null}, {data: null},
      ]
    };
  }

  public loadAllPlatforms(page = 0, perPage = 10, search = '') {
    this.registrationPlatformStore.dispatch(RegistrationPlatformListPaginated({
      params: {
        options: JSON.stringify({includePagination: true, includeProjects: true, sync_support: true}),
        page: page + 1,
        perPage,
        search
      }
    }));
  }

  private loadProject(id) {
    this.projectStore.dispatch(ProjectGet({projectId: id}));
  }

  getUpdateRegistrationPlatform(platform, name) {
    platform[name] = {...platform[name], last_sync_time: new Date()};
    const params: any = {
      registration_platforms: platform,
    };
    this.projectStore.dispatch(ProjectUpdate({project: params, projectId: this.projectId}));
  }

  submitInfluencerSync(platform, index) {
    this.currentSyncId = platform.id;
    this.currentIndex = index;
    this.isSubmit = true;
    this.currentSyncPlatform = platform;
    this.savePlatformName = platform.slug;
    const data = {
      project_id: this.projectId,
      registration_platform_id:  this.currentSyncId
    };
    this.loaderImport.next(true);
    this.registrationPlatformStore.dispatch(InfluencersSync({data: data}));
  }

  onActiveChange (isChecked, swapcardModel, lineUpNinjaModel, terrapinSalesforceModel, sessionBoardModel, mapYourShowModel, personifyA2zModel, platform, index) {
    this.currentIndex = index;
    this.currentPlatform = platform;
    this.shareSyncLinkUrl = environment.frontUrl.replace('/#', '') + `/b/sync_instruction/${platform.id}`;
    const name = platform.slug;
    const project = JSON.parse(JSON.stringify(this.project));
    let reg_platform = (typeof project.registration_platforms === 'string' ? JSON.parse(project.registration_platforms) : project.registration_platforms);
    if (platform.slug === 'swapcard') {
      this.currentModel = swapcardModel;
    } else if (platform.slug === 'lineup-ninja') {
      this.currentModel = lineUpNinjaModel;
    } else if (platform.slug === 'session-board') {
      this.currentModel = sessionBoardModel;
    } else if (platform.slug === 'terrapin-salesforce' || platform.name === 'Salesforce') {
      this.currentModel = terrapinSalesforceModel;
    } else if (platform.slug === 'map-your-show') {
      this.currentModel = mapYourShowModel;
    } else if (platform.slug === 'personify-a2z') {
      this.currentModel = personifyA2zModel;
    }
    if (isChecked) {
      if ((!reg_platform || !reg_platform[name]?.hasOwnProperty('webhook') || (reg_platform[name]?.hasOwnProperty('webhook') && !reg_platform[name].webhook)) && name !== 'terrapin-salesforce' && name !== 'session-board' && name !== 'map-your-show' && name !== 'personify-a2z') {
        if (!reg_platform) { reg_platform = {}; }
        try {
          this.updateProjectAction = true;
          this.isWebHookAdd = true;
          this.registrationPlatformService.getWebHook(this.projectId, name).subscribe(response => {
            if (response['data']) {
              this.updateAction = true;
              reg_platform[name] = {
                webhook : response['data'].webhook,
              };
              const params: any = {
                registration_platforms: reg_platform,
              };
              this.projectStore.dispatch(ProjectUpdate({project: params, projectId: this.projectId}));
            }
          });
        } catch (e) {
          console.log(e);
        }
      } else {
        this.showPlatformJsonPopup(this.currentModel, platform);
      }

    } else {
      this.updateAction = true;
      reg_platform[name].active = false;
      const params: any = {
        registration_platforms:  reg_platform,
      };
      this.projectStore.dispatch(ProjectUpdate({project: params, projectId: this.projectId}));
      const platformObj = JSON.parse(JSON.stringify(platform));
      let project_ids = platformObj.project_id ? platformObj.project_id.split(',') : [];
      if (project_ids.includes(this.projectId.toString())) {
        project_ids = project_ids.filter( (item) => item !== this.projectId.toString());
        platformObj['project_id'] = project_ids.length > 0 ? project_ids.toString() : '';
        this.registrationPlatformStore.dispatch(RegistrationPlatformUpdate({registrationPlatformId: platform.id, registrationPlatform: platformObj}));
      }
      // this.loadAllPlatforms();
    }
  }
  resetSwapcardForm() {
    this.influencerKeysFormGroup.clear();
    this.authSwapcardForm.reset();
  }

  showPlatformJsonPopup(platformAuthModel, platform) {
    this.submitted = false;
    this.isSwapcardFormValid = true;
    this.isTerrapinSalesforceFormValid = true;
    this.isSegmentSelect = '';
    // this.authSwapcardForm.reset();
    this.resetSwapcardForm();
    this.platformName = platform.name;
    this.apiIntegrationGuide = platform.api_integration_guide;
    this.platformId = platform.id;
    this.savePlatformName = platform.slug;
    this.projectStore.dispatch(ProjectGet({projectId: this.projectId}));
    this.influencersStore.dispatch(InfluencersProjectSegment({projectId: this.projectId}));
    const modelRef = this._modalService.open(platformAuthModel, {
      centered: true,
      size: 'xl',
      keyboard: false,
      backdrop: 'static'
    });
    modelRef.result.then((result) => {
    }, (reason) => {
    });
  }

  openApiIntegrationGuideModel(model) {
    this.apiIntegrationModelRef = this._modalService.open(model, {
      centered: true,
      keyboard: false,
      scrollable: true,
      windowClass: 'modal-header-api-integration'
    });
    this.apiIntegrationModelRef.result.then((result) => {
    }, (reason) => {
    });
  }

  onCancelApiIntegrationModel() {
    if (this.apiIntegrationModelRef) {
      this.apiIntegrationModelRef.close();
      this.apiIntegrationModelRef = null;
    }
  }

  allObjectsHaveValues(data, allowOneNull = false) {
    if (allowOneNull) {
      const validPairs = data.filter(obj => obj.name !== null && obj.category !== null);
      const categoryOnly = data.filter(obj => obj.name === null && obj.category !== null);
      const nameOnly = data.filter(obj => obj.name !== null && obj.category === null);

      return (
        validPairs.length > 0 &&
        !categoryOnly.some(Boolean) &&
        !nameOnly.some(Boolean)
      );
    }

    return data.some(item => item.name !== null && item.category !== null);
  }


  savePlatformAuth() {
    this.submitted = true;
    this.isSwapcardFormValid = true;
    if (this.savePlatformName === 'swapcard') {
      if (this.authSwapcardForm.invalid) {
        return;
      }

      if (!this.authSwapcardForm.value.influencer_keys?.length || (this.authSwapcardForm.value.influencer_keys?.length && !this.allObjectsHaveValues(this.authSwapcardForm.value.influencer_keys, true))) {
        this.isSegmentSelect = '';
        this.isSwapcardFormValid = false;
        return;
      }
      const categoryArray = this.authSwapcardForm.value.influencer_keys?.map(item => item.category);
      if (categoryArray.includes('attendee') && !this.selectedAttendeeSegment) {
        this.isSegmentSelect = 'Please Select Attendee Segment';
        return;
      } else {
        this.authSwapcardForm.value.attendee_segment_id = this.selectedAttendeeSegment;
      }
      if (categoryArray.includes('speaker') && !this.selectedSpeakerSegment) {
        this.isSegmentSelect = 'Please Select Speaker Segment';
        return;
      } else {
        this.authSwapcardForm.value.speaker_segment_id = this.selectedSpeakerSegment;
      }
      if (categoryArray.includes('partner') && !this.selectedPartnerSegment) {
        this.isSegmentSelect = 'Please Select Partner Segment';
        return;
      } else {
        this.authSwapcardForm.value.partner_segment_id = this.selectedPartnerSegment;
      }
      this.authSwapcardForm.value.active = true;
      this.authSwapcardForm.value.integration = true;
      this.authProjectRegistrationPlatform[this.savePlatformName] = this.authSwapcardForm.value;
      this.authSwapcardForm.value.handle_deletion = this.handle_deletion_value;
    }

    if (this.savePlatformName === 'lineup-ninja') {
      if (this.authLineUpNinjaForm.invalid && this.authLineUpNinjaForm.value.api_url) {
        return;
      }
      if (this.authLineUpNinjaForm.value.api_url && (!this.authLineUpNinjaForm.value.username || !this.authLineUpNinjaForm.value.password)) {
        this.isLienUpNinjaFormValid = false;
        return;
      }
      if (!this.selectedSpeakerSegment) {
        this.isSegmentSelect = 'Please Select Speaker Segment';
        return;
      } else {
        this.authLineUpNinjaForm.value.speaker_segment_id = this.selectedSpeakerSegment;
      }
      this.authLineUpNinjaForm.value.integration = !!(this.authLineUpNinjaForm.value.api_url && this.authLineUpNinjaForm.value.username && this.authLineUpNinjaForm.value.password);
      this.authLineUpNinjaForm.value.active = true;
      this.authProjectRegistrationPlatform[this.savePlatformName] = this.authLineUpNinjaForm.value;
      this.authLineUpNinjaForm.value.handle_deletion = this.handle_deletion_value;
    }

    if (this.savePlatformName === 'terrapin-salesforce') {
      if (!this.authTerrapinSalesforceForm.value.project_edition_id) {
        this.isTerrapinSalesforceFormValid = false;
        return;
      }
      if (!this.selectedSalesForceOtherAttendeeSegment) {
        this.isSegmentSelect = 'Please Select Other Attendee Segment';
        return;
      } else {
        this.authTerrapinSalesforceForm.value.other_attendee_segment_id = this.selectedSalesForceOtherAttendeeSegment;
      }

      if (!this.selectedSalesForceSpeakerSegment) {
        this.isSegmentSelect = 'Please Select Speaker Segment';
        return;
      } else {
        this.authTerrapinSalesforceForm.value.speaker_segment_id = this.selectedSalesForceSpeakerSegment;
      }

      if (!this.selectedSalesForceGuestSegment) {
        this.isSegmentSelect = 'Please Select Guest Segment';
        return;
      } else {
        this.authTerrapinSalesforceForm.value.guest_segment_id = this.selectedSalesForceGuestSegment;
      }

      if (!this.selectedSalesForceSponsorSegment) {
        this.isSegmentSelect = 'Please Select Partner Segment';
        return;
      } else {
        this.authTerrapinSalesforceForm.value.sponsor_segment_id = this.selectedSalesForceSponsorSegment;
      }

      if (!this.selectedSalesForceExhibitorSegment) {
        this.isSegmentSelect = 'Please Select Exhibitor Segment';
        return;
      } else {
        this.authTerrapinSalesforceForm.value.exhibitor_segment_id = this.selectedSalesForceExhibitorSegment;
      }
      this.authTerrapinSalesforceForm.value.active = true;
      this.authTerrapinSalesforceForm.value.integration = true;
      this.authTerrapinSalesforceForm.value.handle_deletion = this.handle_deletion_value;
      this.authProjectRegistrationPlatform[this.savePlatformName] = this.authTerrapinSalesforceForm.value;
    }
    if (this.savePlatformName === 'session-board') {
      if (!this.authSessionBoardForm.value.event_id) {
        this.isSessionBoardFormValid = false;
        return;
      }

      if (!this.authSessionBoardForm.value.access_token) {
        this.isSessionBoardFormValid = false;
        return;
      }

      /*if (!this.selectedAttendeeSegment) {
        this.isSegmentSelect = 'Please Select Attendee Segment';
        return;
      } else {
        this.authSessionBoardForm.value.attendee_segment_id = this.selectedAttendeeSegment;
      }*/

      if (!this.selectedSpeakerSegment && !this.selectedPartnerSegment) {
        this.isSegmentSelect = 'Please Select A Segment';
        return;
      }

      this.authSessionBoardForm.value.speaker_segment_id = this.selectedSpeakerSegment || null;
      this.authSessionBoardForm.value.partner_segment_id = this.selectedPartnerSegment || null;
      this.authSessionBoardForm.value.active = true;
      this.authSessionBoardForm.value.integration = true;
      this.authProjectRegistrationPlatform[this.savePlatformName] = this.authSessionBoardForm.value;
    }

    if (this.savePlatformName === 'map-your-show') {
      if (this.authMapYourShowForm.invalid) {
        this.isMapYourShowFormValid = false;
        return;
      }
      if (!this.selectedMYSExhibitorSegment && !this.selectedMYSSpeakerSegment) {
        this.isSegmentSelect = 'Please Select A Segment';
        return;
      }
      this.authMapYourShowForm.value.exhibitor_segment_id = this.selectedMYSExhibitorSegment || null;
      this.authMapYourShowForm.value.speaker_segment_id = this.selectedMYSSpeakerSegment || null;
      this.authMapYourShowForm.value.integration = !!(this.authMapYourShowForm.value.show_code);
      this.authMapYourShowForm.value.active = true;
      this.authMapYourShowForm[this.savePlatformName] = this.authMapYourShowForm.value;
      this.authMapYourShowForm.value.handle_deletion = this.handle_deletion_value;
      this.authProjectRegistrationPlatform[this.savePlatformName] = this.authMapYourShowForm.value;
    }

    if (this.savePlatformName === 'personify-a2z') {
      if (this.authPersonifyA2ZForm.invalid) {
        return;
      }

      if (!this.selectedPartnerSegment) {
        this.isSegmentSelect = 'Please Select Partner Segment';
        return;
      } else {
        this.authPersonifyA2ZForm.value.partner_segment_id = this.selectedPartnerSegment;
      }
      this.authPersonifyA2ZForm.value.active = true;
      this.authPersonifyA2ZForm.value.integration = true;
      this.authPersonifyA2ZForm[this.savePlatformName] = this.authPersonifyA2ZForm.value;
      this.authProjectRegistrationPlatform[this.savePlatformName] = this.authPersonifyA2ZForm.value;
    }

    const params: any = {
      registration_platforms: this.authProjectRegistrationPlatform,
    };
    this.projectStore.dispatch(ProjectUpdate({project: params, projectId: this.projectId}));

    const currentPlatformList = JSON.parse(JSON.stringify(this.registrationPlatformList));
    const project_ids = currentPlatformList[this.currentIndex].project_id ? currentPlatformList[this.currentIndex].project_id.split(',') : [];
    if (!project_ids.includes(this.projectId.toString())) {
      currentPlatformList[this.currentIndex].project_id = currentPlatformList[this.currentIndex].project_id ? currentPlatformList[this.currentIndex].project_id.concat(',', this.projectId.toString()) : this.projectId.toString();
      this.registrationPlatformList = currentPlatformList;
      this.registrationPlatformStore.dispatch(RegistrationPlatformUpdate({
        registrationPlatformId: this.currentPlatform.id,
        registrationPlatform: currentPlatformList[this.currentIndex]
      }));
    }
    this._modalService.dismissAll();
    // this.authSwapcardForm.reset();
    this.resetSwapcardForm();
    this.authLineUpNinjaForm.reset();
    this.authTerrapinSalesforceForm.reset();
    this.authSessionBoardForm.reset();
    this.authMapYourShowForm.reset();
    this.authPersonifyA2ZForm.reset();
    this.isLienUpNinjaFormValid = true;
    this.isMapYourShowFormValid = true;
  }

  get s() {
    return this.authSwapcardForm.controls;
  }

  get l() {
    return this.authLineUpNinjaForm.controls;
  }

  get m() {
    return this.authMapYourShowForm.controls;
  }

  get p() {
    return this.authPersonifyA2ZForm.controls;
  }

  onCancel() {
    this._modalService.dismissAll();
    const currentPlatformList = JSON.parse(JSON.stringify(this.registrationPlatformList));
    currentPlatformList[this.currentIndex].active = false;
    this.registrationPlatformList = currentPlatformList;
  }

  formatTime(insertTime) {
    const date = new Date(insertTime);
    insertTime = moment(date, 'YYYY-MM-DD[T]HH[:]mm[:]00[.000Z]');
    if (insertTime.isValid()) {
      return insertTime.format('DD MMM, YYYY h:mm A');
    }
    return null;
  }

  onChecked(index, slug) {
    const projectRegistrationPlatform = (typeof this.project.registration_platforms === 'string' ? JSON.parse(this.project.registration_platforms) : this.project.registration_platforms);
    if (projectRegistrationPlatform && projectRegistrationPlatform.hasOwnProperty(slug)) {
      return projectRegistrationPlatform[slug].active;
    }
    return false;
  }

  isPlatformActive(platform) {
    const reg_platform = this.getRegistrationPlatform();
    if (reg_platform && reg_platform.hasOwnProperty(platform.slug)) {
      return reg_platform[platform.slug].active && reg_platform[platform.slug].integration;
    }
    return false;
  }

  getRegistrationPlatform() {
    return (typeof this.project.registration_platforms === 'string' ? JSON.parse(this.project.registration_platforms) : this.project.registration_platforms);
  }

  getLastSyncTime(slug) {
    const reg_platform = this.getRegistrationPlatform();
    if (reg_platform && reg_platform.hasOwnProperty(slug) && reg_platform[slug].last_sync_time) {
      return `Last sync - ${this.formatTime(reg_platform[slug].last_sync_time)}`;
    } else {
      return null;
    }
  }

  onClickEditPlatform(id) {
    this.router.navigateByUrl(this.routes.registrationPlatformEdit(id));
  }

  copyWebHook(url) {
    const input = document.createElement('textarea');
    input.value = url;
    document.body.appendChild(input);
    input.select();
    document.execCommand('Copy');
    this.toastr.success('Webhook is Copied');
    input.remove();
  }

  isPlatform(slug, prop) {
    if (this.authProjectRegistrationPlatform.hasOwnProperty(slug)) {
      return !!(this.authProjectRegistrationPlatform[slug].hasOwnProperty(prop) && this.authProjectRegistrationPlatform[slug][prop]);
    } else {
      return false;
    }
  }

  onSegmentSelect(event, type) {
    if (event) {
      if (type === 'attendee') {
        this.selectedAttendeeSegment = event.value;
      } else if (type === 'speaker') {
        this.selectedSpeakerSegment = event.value;
      } else if (type === 'partner') {
        this.selectedPartnerSegment = event.value;
      }
    } else {
      if (type === 'attendee') {
        this.selectedAttendeeSegment = null;
      } else if (type === 'speaker') {
        this.selectedSpeakerSegment = null;
      } else if (type === 'partner') {
        this.selectedPartnerSegment = null;
      }
    }
  }

  onSalesForceSegmentSelect(event, type) {
    if (event) {
      if (type === 'otherAttendees') {
        this.selectedSalesForceOtherAttendeeSegment = event.value;
      } else if (type === 'sponsors') {
        this.selectedSalesForceSponsorSegment = event.value;
      } else if (type === 'exhibitor') {
        this.selectedSalesForceExhibitorSegment = event.value;
      } else if (type === 'speaker') {
        this.selectedSalesForceSpeakerSegment = event.value;
      } else if (type === 'guest') {
        this.selectedSalesForceGuestSegment = event.value;
      }
    } else {
      if (type === 'otherAttendees') {
        this.selectedSalesForceOtherAttendeeSegment = null;
      } else if (type === 'sponsors') {
        this.selectedSalesForceSponsorSegment = null;
      } else if (type === 'exhibitor') {
        this.selectedSalesForceExhibitorSegment = null;
      } else if (type === 'speaker') {
        this.selectedSalesForceSpeakerSegment = null;
      } else if (type === 'guest') {
        this.selectedSalesForceGuestSegment = null;
      }
    }
  }

  onMYSSegmentSelect(event, type) {
    if (event) {
     if (type === 'exhibitor') {
        this.selectedMYSExhibitorSegment = event.value;
      } else if (type === 'speaker') {
       this.selectedMYSSpeakerSegment = event.value;
     }
    } else {
     if (type === 'exhibitor') {
        this.selectedMYSExhibitorSegment = null;
      } else if (type === 'speaker') {
        this.selectedMYSSpeakerSegment = null;
      }
    }
  }

  syncStatus() {
    this.registrationPlatformStore.dispatch(InfluencersSyncStatus({syncJobId: this.importJobId}));
  }

  isImportDataFilled() {
    return this.importData && Object.keys(this.importData).length > 0;
  }

  importDataTableColumns() {
    return this.importData['columns'];
  }

  showSyncLogs() {
    const modelRef = this._modalService.open(this.logsTable, {
      centered: false,
      size: 'xl',
      keyboard: false,
      backdrop : 'static',
    });
    modelRef.result.then((result) => {
    }, (reason) => {
    });
  }

  showPreviousSyncLogs(id) {
      const data = {
        campaign_id: '',
        influencer_type_id: '',
        project_id: this.projectId,
        action: 'sync',
        registration_platform_id: id
      };
      const modelRef: any = this._modalService.open(TaskLogsComponent, {
        centered: false,
        size: 'xl',
        keyboard: true
      });
      modelRef.componentInstance.data = data;
      modelRef.result.then((result) => {
      }, (reason) => {
      });
    }

  getInfluencerTypes() {
    this.influencersStore.dispatch(InfluencersTypeList());
  }

  get influencerKeysFormGroup() {
    return this.authSwapcardForm.get('influencer_keys') as UntypedFormArray;
  }

  addInfluencerKeysButtonClick(): void {
    if (!this.authSwapcardForm.value.influencer_keys?.length || (this.authSwapcardForm.value.influencer_keys?.length && !this.allObjectsHaveValues(this.authSwapcardForm.value.influencer_keys))) {
      this.isSegmentSelect = '';
      this.isSwapcardFormValid = false;
      return;
    }
    this.influencerKeysFormGroup.push(this.addInfluencerKeysGroup());
  }

  addInfluencerKeysGroup(): UntypedFormGroup {
    return this.formBuilder.group({
      name: [],
      category: []
    });
  }

  removeInfluencerKeysButtonClick(index: number): void {
    this.influencerKeysFormGroup.removeAt(index);
  }

  ngOnDestroy() {
    this.unsubscriber.next();
    this.unsubscriber.complete();
  }
}
