import {createFeatureSelector, createSelector} from '@ngrx/store';
import {IRegistrationPlatformState} from '@app/stores/registration-platform/registration-platform.state';
import {IInfluencersState} from '@app/stores';


const selectRegistrationPlatform = createFeatureSelector<IRegistrationPlatformState>('registrationPlatform');
export const getRegistrationPlatforms = createSelector(selectRegistrationPlatform, (state: IRegistrationPlatformState) => state.registrationPlatforms);

export const getRegistrationPlatform = createSelector(selectRegistrationPlatform, (state: IRegistrationPlatformState) => state.registrationPlatform);

export const getRegistrationPlatformPaginated = createSelector(selectRegistrationPlatform, (state: IRegistrationPlatformState) => state.paginatedRegistrationPlatform);

export const getRegistrationPlatformSuccess = createSelector(selectRegistrationPlatform, (state: IRegistrationPlatformState) => state.success);

export const getRegistrationPlatformSuccessResponse = createSelector(selectRegistrationPlatform, (state: IRegistrationPlatformState) => state.registrationPlatform);

export const getRegistrationPlatformError = createSelector(selectRegistrationPlatform, (state: IRegistrationPlatformState) => state.error);

export const getRegistrationPlatformWidgetGuideUrl = createSelector(selectRegistrationPlatform, (state: IRegistrationPlatformState) => state.widgetGuideUrl);

export const getInfluencersSync = createSelector(selectRegistrationPlatform, (state: IRegistrationPlatformState) => state.influencersSync);

export const getInfluencersSyncError = createSelector(selectRegistrationPlatform, (state: IRegistrationPlatformState) => state.error);

export const getSyncInfluencers = createSelector(selectRegistrationPlatform, (state: IRegistrationPlatformState) => state.influencersSync);

export const getRegistrationPlatformSlug = createSelector(selectRegistrationPlatform, (state: IRegistrationPlatformState) => state.slug);
