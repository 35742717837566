import {createFeatureSelector, createSelector} from '@ngrx/store';
import {IAuthenticationState} from '@app/stores';

const selectUsers = createFeatureSelector<IAuthenticationState>('authentication');

export const getToken = createSelector(selectUsers, (state: IAuthenticationState) => state.user?.token);

export const getLoggedInUser = createSelector(selectUsers, (state: IAuthenticationState) => state.user);

export const getSuccess = createSelector(selectUsers, (state: IAuthenticationState) => state.success);

export const getError = createSelector(selectUsers, (state: IAuthenticationState) => state.error);

export const getForgetPassword = createSelector(selectUsers, (state: IAuthenticationState) => state.forgetPassword);

export const getResetPassword = createSelector(selectUsers, (state: IAuthenticationState) => state.resetPassword);

export const getRegisterUser = createSelector(selectUsers, (state: IAuthenticationState) => state.registerUser);
