import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';

import { environment } from '@environments/environment';

@Injectable ({providedIn: 'root'})
export class HttpService {
  constructor(private http: HttpClient) {}

  get(path, params) {
    return this.http.get<any> (this.apiRootUrl() + path,
      {
        headers: this.headers (),
        observe: 'response',
        params
      }
    );
  }

  post(path, body, params?) {
    return this.http.post<any> (this.apiRootUrl() + path,
      body,
      {
        headers: this.headers (),
        observe: 'response',
        params
      }
    );
  }

  put(path, body, params?) {
    return this.http.put<any> (this.apiRootUrl() + path,
      body,
      {
        headers: this.headers (),
        observe: 'response',
        params
      }
    );
  }

  apiRootUrl() : String {
    return environment.apiUrl
  }

  private headers() {
    const currentuser = JSON.parse (localStorage.getItem ('currentUser'));
    if (currentuser) {
      const apiToken = currentuser.token;
      return new HttpHeaders().set(
        'Authorization',
        'Bearer ' + apiToken
      );
    } else {
      return new HttpHeaders();
    }
  }
}
