import {createFeatureSelector, createSelector} from '@ngrx/store';
import {ISegmentsState} from '@app/stores/segments/segments.state';

const selectSegment = createFeatureSelector<ISegmentsState>('segments');

export const getSegment = createSelector(selectSegment, (state: ISegmentsState) => state.segment);

export const getSegmentSuccess = createSelector(selectSegment, (state: ISegmentsState) => state.success);

export const getSegmentSuccessResponse = createSelector(selectSegment, (state: ISegmentsState) => state.segment);

export const getSegmentError = createSelector(selectSegment, (state: ISegmentsState) => state.error);

export const getSegments = createSelector(selectSegment, (state: ISegmentsState) => state.segments);

export const getSegmentsPaginated = createSelector(selectSegment, (state: ISegmentsState) => state.paginatedSegments);
