// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.production.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
    name: 'default',
    production: (process.env['ENVIRONMENT'] === 'production'),
    froalaLicenseKey: process.env['FROALA_LICENSE_KEY'],
    froalaEditorKey: process.env['FROALA_EDITOR_KEY'],
    frontUrl  : process.env['FRONT_URL'], // used for download email templates inside the mail.
    apiUrl: process.env['API_URL'],
    websocket: process.env['WEBSOCKET'],
    sentryDsn: process.env['SENTRY_DSN']
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
