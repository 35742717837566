import {AfterViewInit, Component, Input, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {Router} from '@angular/router';
import {select, Store} from '@ngrx/store';
import {
  getPaginatedWidgetAvatarTemplates,
  getTemplateError,
  getTemplateSuccess,
  ITemplateState,
  ResetTemplateState,
  WidgetAvatarTemplateCreate,
  WidgetAvatarTemplateDelete,
  WidgetAvatarTemplateGenerate,
  getWidgetAvatars,
  WidgetAvatarTemplateList,
  WidgetAvatarTemplateUpdate
} from '@app/stores';
import {takeUntil} from 'rxjs/operators';
import {Subject} from 'rxjs';
import {ToastrService} from 'ngx-toastr';
import {WidgetAvatarTemplate} from '@app/modules/shared/models';
import {DataTableDirective} from 'angular-datatables';
import {NgbActiveModal, NgbModal} from "@ng-bootstrap/ng-bootstrap";
import {UntypedFormBuilder, UntypedFormGroup, Validators} from "@angular/forms";
import Swal from "sweetalert2";

@Component({
  selector: 'app-widget-avatar-templates',
  templateUrl: './widget-avatar-templates.component.html',
  styleUrls: ['./widget-avatar-templates.component.scss'],
  providers: []
})
export class WidgetAvatarTemplatesComponent implements OnInit, OnDestroy, AfterViewInit {
  @ViewChild(DataTableDirective)
  dtElement: DataTableDirective;
  dtOptions: any = {};
  dtTrigger: Subject<any> = new Subject();
  unsubscriber = new Subject();
  @Input() data: any;
  private datatableInstance: any;
  ajaxCallback: any;
  searchDelay: any;
  widgetAvatarTemplates: WidgetAvatarTemplate[] = [];
  selectedAction = '';
  addWidgetAvatarTemplateForm: UntypedFormGroup;
  submitted: boolean = false;
  // staticImageVariations: any = [
  //   {name: 'original', url: 'https://fastly.picsum.photos/id/16/2500/1667.jpg?hmac=uAkZwYc5phCRNFTrV_prJ_0rP0EdwJaZ4ctje2bY7aE'},
  //   {name: 'silvertone', url: 'https://fastly.picsum.photos/id/15/2500/1667.jpg?hmac=Lv03D1Y3AsZ9L2tMMC1KQZekBVaQSDc1waqJ54IHvo4'},
  //   {name: 'light', url: 'https://fastly.picsum.photos/id/16/2500/1667.jpg?hmac=uAkZwYc5phCRNFTrV_prJ_0rP0EdwJaZ4ctje2bY7aE'}
  // ];
  selectedVariation = null;
  widgetAvatarTemplateId = null;
  customizeTemplateModal = null;
  imageVariations = [];
  deleteAction = false;

  constructor(
    private router: Router,
    private toastr: ToastrService,
    public modal: NgbActiveModal,
    private _modalService: NgbModal,
    private templateStore: Store<ITemplateState>,
    private formBuilder: UntypedFormBuilder,
  ) {
    this.templateStore.dispatch(ResetTemplateState({params: {error: '', paginatedWidgetAvatarTemplates: null, widgetAvatarTemplate: null}}));
  }

  ngOnInit() {
    this.datatableSettings();
    if (this.dtElement && this.dtElement.dtInstance) {
      this.dtElement.dtInstance.then((dtInstance: any) => {
        dtInstance.destroy();
        this.dtTrigger.next();
      });
    } else {
      this.dtTrigger.next();
    }
    this.subscribeStore();
    this.addWidgetAvatarTemplateForm = this.formBuilder.group({
      name: ['', Validators.required],
      prompt: ['', Validators.required],
      prompt_revised: [''],
      image_variations: [null, Validators.required]
    });
  }

  ngAfterViewInit() {
    this.dtTrigger.next();
  }

  datatableSettings() {
    this.dtOptions = {
      // scrollY: '100vh',
      scrollCollapse: true,
      pagingType: 'full_numbers',
      responsive: false,
      ordering: false,
      processing: true,
      serverSide: true,
      pageLength: 10,
      paging: true,
      scrollX: true,
      searching: true,
      // scrollY: "100vh",
      fixedHeader: {
        header: true,
        footer: true
      },
      drawCallback: function (setting) {
        const totalPages = this.api().page.info().pages;
        if (totalPages <= 1) {
          document.querySelector('.modal-dialog .dataTables_paginate').classList.add('d-none');
        } else {
          document.querySelector('.modal-dialog .dataTables_paginate').classList.remove('d-none');
        }
      },
      ajax: (dataTablesParameters: any, callback) => {
        this.dtElement.dtInstance.then((dtInstance: any) => {
          this.datatableInstance = dtInstance;
          this.ajaxCallback = callback;
          const pageLength = dataTablesParameters.length;
          const pageNumber = (dataTablesParameters.start / pageLength);
          (this.datatableInstance as any).page.len(pageLength);
          const searchBox = $('div.dataTables_filter input');
          searchBox.off('keyup.DT input.DT');
          searchBox.on('keyup', () => {
            const search: any = searchBox.val();
            clearTimeout(this.searchDelay);
            this.searchDelay = setTimeout(() => {
              if (search != null) {
                dtInstance.search(search).draw();
              }
            }, 1000);
          });
          this.loadAllWidgetAvatarTemplates(pageNumber, pageLength, dataTablesParameters.search.value);
        });
      },
      columns: [{data: null}, {data: null}, {data: null}]
    };
  }

  subscribeStore() {
    this.templateStore.pipe(select(getPaginatedWidgetAvatarTemplates))
      .pipe(takeUntil(this.unsubscriber))
      .subscribe(paginatedTemplates => {
        if (paginatedTemplates) {
          this.widgetAvatarTemplates = paginatedTemplates.list;
          if (this.ajaxCallback) {
            this.ajaxCallback({
              recordsTotal: paginatedTemplates.paging.total,
              recordsFiltered: paginatedTemplates.paging.total,
              data: []
            });
            setTimeout(() => {
              (this.datatableInstance as any).columns.adjust();
            }, 500);
          }
        }
      });

    this.templateStore.pipe(select(getTemplateError))
      .pipe(takeUntil(this.unsubscriber))
      .subscribe(error => {
        if (error) {
          this.toastr.error(error, 'Error');
        }
      });

    this.templateStore.pipe(select(getTemplateSuccess))
      .pipe(takeUntil(this.unsubscriber))
      .subscribe(success => {
        if (success) {
          if (this.selectedAction && this.submitted) {
            this.customizeTemplateModal.close();
            this.submitted = false;
            this.addWidgetAvatarTemplateForm.reset();
            this.loadAllWidgetAvatarTemplates();
          }
          if (this.deleteAction) {
            this.deleteAction = false;
            this.loadAllWidgetAvatarTemplates();
          }
        }
      });

    this.templateStore.pipe(select(getWidgetAvatars))
      .pipe(takeUntil(this.unsubscriber))
      .subscribe(avatars => {
        if (avatars) {
          this.imageVariations = [...avatars];
          if (this.imageVariations.length > 0) {
            this.selectVariation(this.imageVariations[0], 0)
          }
        }
      });
  }

  loadAllWidgetAvatarTemplates(page = 0, perPage = 10, search = '', orderBy= {'column': 'created_at', 'dir': 'desc'}) {
    this.templateStore.dispatch(WidgetAvatarTemplateList({
      params: {
        project_id: this.data.project_id,
        options: JSON.stringify({includePagination: true, orderBy}),
        page: page + 1,
        perPage,
        search
      }
    }));
  }

  get f() {
    return this.addWidgetAvatarTemplateForm.controls;
  }

  showAddEditTemplateModel(customizeTemplate, type, template = null) {
    this.submitted = false;
    this.selectedAction = type;
    if (type === 'edit') {
      this.handleEditAction(template);
    } else {
      this.handleAddAction();
    }

    const modelRef = this._modalService.open(customizeTemplate, {
      centered: false,
      size: 'md',
      keyboard: false
    });
    modelRef.result.then((result) => {
    }, (reason) => {
    });
  }

  handleEditAction(template) {
    this.widgetAvatarTemplateId = template.id;
    this.imageVariations = template?.image_variations || [];
    this.selectedVariation = this.imageVariations.find(item => item.isActive) || null;
    if (this.selectedVariation && !template.prompt_revised) {
      template.prompt_revised = this.selectedVariation.revised_prompt;
    }
    this.addWidgetAvatarTemplateForm.patchValue(template);
  }

  handleAddAction() {
    this.widgetAvatarTemplateId = null;
    this.imageVariations = [];
    this.selectedVariation = null;
    this.addWidgetAvatarTemplateForm.reset();
    // this.imageVariations = [...this.staticImageVariations];
    // if (this.imageVariations.length > 0) {
    //   if (!this.imageVariations[0].hasOwnProperty('isActive')) {
    //     this.imageVariations[0].isActive = true;
    //   }
    //   this.selectedVariation = this.imageVariations[0];
    // }
    // this.addWidgetAvatarTemplateForm.patchValue({ image_variations: this.imageVariations });
  }

  selectVariation(variation: string, index) {
    this.imageVariations = this.imageVariations.map((image, i) => ({
      ...image,
      isActive: i === index
    }));
    this.selectedVariation = this.imageVariations.find(item => item.isActive);
    const filteredImages = this.imageVariations.map(({isActive, ...rest}) => isActive ? {isActive, ...rest} : {...rest});
    this.addWidgetAvatarTemplateForm.patchValue({prompt_revised: this.selectedVariation.revised_prompt,image_variations: filteredImages})
  }

  saveWidgetTemplateForm(modal) {
    this.submitted = true;
    if (this.addWidgetAvatarTemplateForm.invalid) {
      return;
    }
    this.customizeTemplateModal = modal;
    if (this.widgetAvatarTemplateId) {
      this.templateStore.dispatch(WidgetAvatarTemplateUpdate({
        id: this.widgetAvatarTemplateId,
        body: {...this.addWidgetAvatarTemplateForm.value, project_id: this.data.project_id}
      }));
    } else {
      this.templateStore.dispatch(WidgetAvatarTemplateCreate({
        body: {...this.addWidgetAvatarTemplateForm.value, project_id: this.data.project_id}
      }));
    }
  }

  deleteWidgetTemplate(templateId: number) {
    Swal({
      title: 'Are you sure?',
      type: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Yes',
      cancelButtonText: 'No'
    }).then(result => {
      if (result.value) {
        this.deleteAction = true;
        this.templateStore.dispatch(WidgetAvatarTemplateDelete({widgetAvatarTemplateId: templateId}));
      }
    });
  }

  generateWidgetAvatarImages() {
    this.templateStore.dispatch(WidgetAvatarTemplateGenerate({body: {prompt: this.addWidgetAvatarTemplateForm.value.prompt}}));
  }

  ngOnDestroy() {
    this.unsubscriber.next();
    this.unsubscriber.complete();
  }

}
