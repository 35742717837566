import {createFeatureSelector, createSelector} from '@ngrx/store';
import {IMailVerifyState} from '@app/stores';

const selectMailVerify = createFeatureSelector<IMailVerifyState>('mailVerify');

export const getMailVerifyStatus = createSelector(selectMailVerify, (state: IMailVerifyState) => state.status);

export const getMailVerifySuccess = createSelector(selectMailVerify, (state: IMailVerifyState) => state.success);

export const getMailVerifyError = createSelector(selectMailVerify, (state: IMailVerifyState) => state.error);


export const getMailServiceProvider = createSelector(selectMailVerify, (state: IMailVerifyState) => state.serviceProviders);

export const getMailServiceProviderSuccess = createSelector(selectMailVerify, (state: IMailVerifyState) => state.success);

export const getMailServiceProviderError = createSelector(selectMailVerify, (state: IMailVerifyState) => state.error);
