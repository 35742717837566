import {Injectable} from '@angular/core';
import {Actions, createEffect, ofType} from '@ngrx/effects';
import {Store} from '@ngrx/store';
import {catchError, map, switchMap} from 'rxjs/operators';
import {of} from 'rxjs';
import {LoaderService} from '@app/services/loader.service';
import {
  CampaignGet, CampaignGetSuccess, CampaignGetError, CampaignUpdate, CampaignUpdateSuccess, CampaignUpdateError, CampaignCreate,
  CampaignCreateSuccess, CampaignCreateError, CampaignGetAll, CampaignGetAllSuccess, CampaignGetAllError, CampaignLaunch,
  CampaignLaunchSuccess, CampaignLaunchError, CampaignDelete, CampaignDeleteSuccess, CampaignDeleteError,
  CampaignCopy, CampaignCopySuccess, CampaignCopyError, CampaignGetAllPaginate, CampaignGetAllPaginateError, CampaignGetAllPaginateSuccess, CampaignLaunchScheduled, CampaignLaunchScheduledSuccess, CampaignLaunchScheduledError,
  CancelCampaignLaunch, CancelCampaignLaunchSuccess, CancelCampaignLaunchError,
  CampaignLaunchCode, CampaignLaunchCodeSuccess, CampaignLaunchCodeError,
  CampaignValidation, CampaignValidationSuccess, CampaignValidationError
} from '@app/stores/campaign/campaign.actions';
import {ICampaignState} from '@app/stores/campaign/campaign.state';
import {CampaignService} from '@app/services/campaign.service';

@Injectable()
export class CampaignEffects {
  constructor(
    private _actions$: Actions,
    private _store: Store<ICampaignState>,
    private campaignService: CampaignService,
    private loaderService: LoaderService
  ) {
  }

  campaignList$ = createEffect(() => this._actions$.pipe(
    ofType(CampaignGet),
    switchMap((action) => {
      this.loaderService.show();
      return this.campaignService.getCampaign(action.campaignId, action.params).pipe(
        map((resp: any) => {
          if (resp.success) {
            this.loaderService.hide();
            return CampaignGetSuccess({campaign: resp.data});
          }
          this.loaderService.hide(true);
          return CampaignGetError({error: this.loaderService.getErrorMessage(resp)});
        }),
        catchError(error => {
          this.loaderService.hide(true);
          return of(CampaignGetError({error: this.loaderService.getErrorMessage(error)}));
        })
      );
    })
  ));

  campaignUpdate$ = createEffect(() => this._actions$.pipe(
    ofType(CampaignUpdate),
    switchMap((action) => {
      this.loaderService.show();
      return this.campaignService.updateCampaign(action.campaign, action.campaignId).pipe(
        map((resp: any) => {
          if (resp.success) {
            this.loaderService.hide();
            return CampaignUpdateSuccess({campaign: resp.data});
          }
          this.loaderService.hide(true);
          return CampaignUpdateError({error: this.loaderService.getErrorMessage(resp)});
        }),
        catchError(error => {
          this.loaderService.hide(true);
          return of(CampaignUpdateError({error: this.loaderService.getErrorMessage(error)}));
        })
      );
    })
  ));

  campaignCreate$ = createEffect(() => this._actions$.pipe(
    ofType(CampaignCreate),
    switchMap((action) => {
      this.loaderService.show();
      return this.campaignService.addCampaign(action.campaign).pipe(
        map((resp: any) => {
          if (resp.success) {
            this.loaderService.hide();
            return CampaignCreateSuccess({campaign: resp.data});
          }
          this.loaderService.hide(true);
          return CampaignCreateError({error: this.loaderService.getErrorMessage(resp)});
        }),
        catchError(error => {
          this.loaderService.hide(true);
          return of(CampaignCreateError({error: this.loaderService.getErrorMessage(error)}));
        })
      );
    })
  ));

  campaignGetAll$ = createEffect(() => this._actions$.pipe(
    ofType(CampaignGetAll),
    switchMap((action) => {
      this.loaderService.show();
      return this.campaignService.getCampaigns(action.filter).pipe(
        map((resp: any) => {
          if (resp.success) {
            this.loaderService.hide();
            return CampaignGetAllSuccess({campaigns: resp.data});
          }
          this.loaderService.hide(true);
          return CampaignGetAllError({error: this.loaderService.getErrorMessage(resp)});
        }),
        catchError(error => {
          this.loaderService.hide(true);
          return of(CampaignGetAllError({error: this.loaderService.getErrorMessage(error)}));
        })
      );
    })
  ));

  CampaignGetAllPaginate$ = createEffect(() => this._actions$.pipe(
    ofType(CampaignGetAllPaginate),
    switchMap((action) => {
      this.loaderService.show();
      return this.campaignService.getCampaigns(action.params).pipe(
        map((resp: any) => {
          if (resp.success) {
            this.loaderService.hide();
            return CampaignGetAllPaginateSuccess({paginatedCampaigns: resp.data});
          }
          this.loaderService.hide(true);
          return CampaignGetAllPaginateError({error: this.loaderService.getErrorMessage(resp)});
        }),
        catchError(error => {
          this.loaderService.hide(true);
          return of(CampaignGetAllPaginateError({error: this.loaderService.getErrorMessage(error)}));
        })
      );
    })
  ));

  campaignLaunch$ = createEffect(() => this._actions$.pipe(
    ofType(CampaignLaunch),
    switchMap((action) => {
      this.loaderService.show();
      return this.campaignService.launch(action.campaignId, action.code, action.reLaunch).pipe(
        map((resp: any) => {
          if (resp.success) {
            this.loaderService.hide();
            return CampaignLaunchSuccess({campaignLaunch: resp.data});
          }
          this.loaderService.hide(true);
          return CampaignLaunchError({error: this.loaderService.getErrorMessage(resp)});
        }),
        catchError(error => {
          this.loaderService.hide(true);
          return of(CampaignLaunchError({error: this.loaderService.getErrorMessage(error)}));
        })
      );
    })
  ));

  campaignLaunchScheduled$ = createEffect(() => this._actions$.pipe(
    ofType(CampaignLaunchScheduled),
    switchMap((action) => {
      this.loaderService.show();
      return this.campaignService.launchScheduled(action.campaignId, action.schedule, action?.timezone, action.code).pipe(
        map((resp: any) => {
          if (resp.success) {
            this.loaderService.hide();
            return CampaignLaunchScheduledSuccess({campaignLaunch: resp.data});
          }
          this.loaderService.hide(true);
          return CampaignLaunchScheduledError({error: this.loaderService.getErrorMessage(resp)});
        }),
        catchError(error => {
          this.loaderService.hide(true);
          return of(CampaignLaunchScheduledError({error: this.loaderService.getErrorMessage(error)}));
        })
      );
    })
  ));

  campaignDelete$ = createEffect(() => this._actions$.pipe(
    ofType(CampaignDelete),
    switchMap((action) => {
      this.loaderService.show();
      return this.campaignService.deleteCampaign(action.campaignId).pipe(
        map((resp: any) => {
          if (resp.success) {
            this.loaderService.hide();
            return CampaignDeleteSuccess();
          }
          this.loaderService.hide(true);
          return CampaignDeleteError({error: this.loaderService.getErrorMessage(resp)});
        }),
        catchError(error => {
          this.loaderService.hide(true);
          return of(CampaignDeleteError({error: this.loaderService.getErrorMessage(error)}));
        })
      );
    })
  ));

  campaignGet$ = createEffect(() => this._actions$.pipe(
    ofType(CampaignCopy),
    switchMap((action) => {
      this.loaderService.show();
      return this.campaignService.copyCampaign(action.campaignId, action.body).pipe(
        map((resp: any) => {
          if (resp.success) {
            this.loaderService.hide();
            return CampaignCopySuccess();
          }
          this.loaderService.hide(true);
          return CampaignCopyError({error: this.loaderService.getErrorMessage(resp)});
        }),
        catchError(error => {
          this.loaderService.hide(true);
          return of(CampaignCopyError({error: this.loaderService.getErrorMessage(error)}));
        })
      );
    })
  ));

  cancelCampaignLaunch$ = createEffect(() => this._actions$.pipe(
    ofType(CancelCampaignLaunch),
    switchMap((action) => {
      this.loaderService.show();
      return this.campaignService.cancelCampaignLaunch(action.campaignId).pipe(
        map((resp: any) => {
          if (resp.success) {
            this.loaderService.hide();
            return CancelCampaignLaunchSuccess({campaign: resp.data});
          }
          this.loaderService.hide(true);
          return CancelCampaignLaunchError({error: this.loaderService.getErrorMessage(resp)});
        }),
        catchError(error => {
          this.loaderService.hide(true);
          return of(CancelCampaignLaunchError({error: this.loaderService.getErrorMessage(error)}));
        })
      );
    })
  ));

  campaignValidation$ = createEffect(() => this._actions$.pipe(
    ofType(CampaignValidation),
    switchMap((params) => {
      this.loaderService.show();
      return this.campaignService.launchValidation(params).pipe(
        map((resp: any) => {
          if (resp.success) {
            this.loaderService.hide();
            return CampaignValidationSuccess({campaignValidation: resp.data});
          }
          this.loaderService.hide(true);
          return CampaignValidationError({error: this.loaderService.getErrorMessage(resp)});
        }),
        catchError(error => {
          this.loaderService.hide(true);
          return of(CampaignValidationError({error: this.loaderService.getErrorMessage(error)}));
        })
      );
    })
  ));

  campaignLaunchCode$ = createEffect(() => this._actions$.pipe(
    ofType(CampaignLaunchCode),
    switchMap((action) => {
      this.loaderService.show();
      return this.campaignService.getLaunchCode(action.campaignId).pipe(
        map((resp: any) => {
          if (resp.success) {
            this.loaderService.hide();
            return CampaignLaunchCodeSuccess({campaignLaunchCode: resp.data});
          }
          this.loaderService.hide(true);
          return CampaignLaunchCodeError({error: this.loaderService.getErrorMessage(resp)});
        }),
        catchError(error => {
          this.loaderService.hide(true);
          return of(CampaignLaunchCodeError({error: this.loaderService.getErrorMessage(error)}));
        })
      );
    })
  ));
}
