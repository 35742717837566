<div class="align-items-center modal-header px-4">
  <h4 class="modal-title font-22" id="modal-title">
    {{fromEmail}} verification
  </h4>
  <div class="d-flex align-items-center justify-content-end">
    <button type="button" class="close font-22" aria-label="Close button" aria-describedby="modal-title"
            (click)="modal.dismiss('cancel')">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
</div>
<div class="modal-body pt-0 px-0 pb-0">
  <ng-container>
    <div class="px-4 py-3 status-badge">
      <span class="status-text">From Email</span>
      <span class="status-value">
        needs to be verified before you can launch your campaign.
    </span>
    </div>
    <div class="verification">
      <label class="d-flex align-items-center input-radio">
        <input type="radio" name="email_sender_verify_by" class="form-control" (ngModelChange)="error = ''"
               [(ngModel)]="emailSenderVerifyBy"
               value="domain">
        <div>Domain Verification (Recommended for Improved delivery rate).</div>
      </label>
      <p class="info pb-2">
        To send email through Snöball, we need to verify that you are using a valid
        From email address hosted at a domain you can access. This helps keep your
        campaigns out of spam folders and protects your reputation by ensuring others
        can't use your domain without permission. You only need to verify a domain one time,
        then you can send with any email address at the verified domain.
      </p>
      <label class="d-flex align-items-center input-radio">
        <input type="radio" name="email_sender_verify_by" class="form-control"
               (ngModelChange)="error = ''" [(ngModel)]="emailSenderVerifyBy" value="email">
        <div>Email Based Verification (Lower delivery rate).</div>
      </label>
      <p class="info mb-0">
        This verification method only validates one email sender and not any email at
        your domain. Some recipient mail servers may decided to consider the emails as
        spam which can reduce the delivery rate significantly.
      </p>

      <p class="text-danger mt-5" *ngIf="error">{{error}}</p>
    </div>

  </ng-container>
</div>
<div class="modal-footer px-4">
  <button class="btn btn-primary" (click)="verifyBySelected()">Next</button>
</div>
<input type="hidden" ngbAutofocus>
