import { environment } from '@environments/environment';
import { ToastrService } from "ngx-toastr";

/*export default class UploadAdapter {
  public loader;
  public uploadService;
  public toastrService;
  public url = `${environment.apiUrl}/private/aws/s3/oneTimeToken`;

  constructor(loader, uploadService, toastrService) {
    this.loader = loader;
    this.uploadService = uploadService;
    this.toastrService = toastrService;
  }

  upload() {
    return new Promise((resolve) => {
      this.loader.file.then(async (file) => {
        try {
          const upload = await this.uploadService.uploadFile(file);
          resolve({ default: upload['url'].split('?')[0] });
        } catch (err) {
          this.toastrService.error('An error occurred while uploading the image', 'Error');
        }
      }
      );
    });
  }
};*/

export default class UploadAdapter {
  private parent = this;
  public loader;
  public url = `${environment.apiUrl}/private/aws/s3/oneTimeToken`;

  constructor(loader) {
    this.loader = loader;
  }

  upload() {
    let image_data = this.loader.data;
    return new Promise((accept, reject) => {
      this.loader.file.then(
        (file) => {
          let params = {
            ct: file.type,
            urname: true,
            fname: file.name,
            dirname: 'template-images'
          };
          let req1 = new XMLHttpRequest();
          let req2 = new XMLHttpRequest();
          req1.open('POST', this.url, true);
          req1.setRequestHeader('Content-Type', 'application/json');
          req1.send(JSON.stringify(params));

          req1.onreadystatechange = function () {
            if (this.readyState === XMLHttpRequest.DONE && this.status === 200) {
              let data = JSON.parse(this.response);
              const fileUploadURL = data['url'];
              req2.open('PUT', fileUploadURL, true);
              req2.setRequestHeader('Content-Type', params.ct);
              var formData = new FormData();
              req2.send(file);
            }
          };

          req2.onreadystatechange = function (response) {
            if (this.readyState === XMLHttpRequest.DONE && this.status === 200) {
              accept({
                default: this.responseURL.split('?')[0]
              })
              alert('File successfully uploaded');
            }
          };
        }
      );
    });
  }
}
