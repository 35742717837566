import {createFeatureSelector, createSelector} from '@ngrx/store';
import {IClientState} from '@app/stores';

const selectClient = createFeatureSelector<IClientState>('clients');

export const getClient = createSelector(selectClient, (state: IClientState) => state.client);

export const getClientSuccess = createSelector(selectClient, (state: IClientState) => state.success);

export const getClientError = createSelector(selectClient, (state: IClientState) => state.error);

export const getClients = createSelector(selectClient, (state: IClientState) => state.clients);

export const getPaginatedClients = createSelector(selectClient, (state: IClientState) => state.paginatedClients);
