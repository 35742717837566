<div class="rightBody new-styles">
  <div class="row">
    <div class="offset-lg-4 offset-md-3 col-lg-4 col-md-6">

      <tabset type="tabs" class="tabs-animated tabs-animated-line centered-nav">
        <tab>
          <ng-template tabHeading>
            <span>
              <fa-icon [icon]="['fab', 'facebook']" class="font-size-xxl"></fa-icon>
            </span>
            <div class="divider"></div>
          </ng-template>
          <br />
          <div class="text-center">
            <a class="btn btn-primary btn-facebook" [href]="facebookURL()" target="_blank">
              <fa-icon [icon]="['fab', 'facebook']"></fa-icon>
              Share on Facebook
            </a>
          </div>
          <br />
          <app-social-share-preview socialMedia="Facebook" [message]="personalizedMessage" [title]="title"
            [description]="description" [image]="image" [url]="landingPageUrl">
          </app-social-share-preview>
        </tab>
        <tab>
          <ng-template tabHeading>
            <span>
              <fa-icon [icon]="['fab', 'twitter']" class="font-size-xxl"></fa-icon>
            </span>
            <div class="divider"></div>
          </ng-template>
          <br />
          <div class="text-center">
            <a class="btn btn-primary btn-twitter" [href]="twitterURL()" target="_blank">
              <fa-icon [icon]="['fab', 'twitter']"></fa-icon>
              Share on Twitter
            </a>
          </div>
          <br />
          <app-social-share-preview socialMedia="Twitter" [message]="personalizedMessage" [title]="title"
            [description]="description" [image]="image" [url]="landingPageUrl">
          </app-social-share-preview>
        </tab>
        <tab>
          <ng-template tabHeading>
            <span>
              <fa-icon [icon]="['fab', 'linkedin']" class="font-size-xxl"></fa-icon>
            </span>
            <div class="divider"></div>
          </ng-template>
          <br />
          <div class="text-center">
            <a class="btn btn-primary btn-linkedin" [href]="linkedinURL()" target="_blank">
              <fa-icon [icon]="['fab', 'linkedin']"></fa-icon>
              Share on LinkedIn
            </a>
          </div>
          <br />
          <app-social-share-preview socialMedia="LinkedIn" [message]="personalizedMessage" [title]="title"
            [description]="description" [image]="image" [url]="landingPageUrl">
          </app-social-share-preview>
        </tab>
        <tab>
          <ng-template tabHeading>
            <span>
              <fa-icon [icon]="['fab', 'whatsapp']" class="font-size-xxl"></fa-icon>
            </span>
            <div class="divider"></div>
          </ng-template>
          <br />
          <div class="text-center">
            <a class="btn btn-primary btn-whatsapp" [href]="whatsappURL()" target="_blank">
              <fa-icon [icon]="['fab', 'whatsapp']"></fa-icon>
              Share on LinkedIn
            </a>
          </div>
          <br />
          <app-social-share-preview socialMedia="Whatsapp" [message]="personalizedMessage" [title]="title"
                                    [description]="description" [image]="image" [url]="landingPageUrl">
          </app-social-share-preview>
        </tab>
        <tab>
          <ng-template tabHeading>
            <span>
              <fa-icon [icon]="['fab', 'slack']" class="font-size-xxl"></fa-icon>
            </span>
            <div class="divider"></div>
          </ng-template>
          <br />
          <div class="text-center">
            <a class="btn btn-primary btn-slack" [href]="slackURL()" target="_blank">
              <fa-icon [icon]="['fab', 'slack']"></fa-icon>
              Share on LinkedIn
            </a>
          </div>
          <br />
          <app-social-share-preview socialMedia="Slack" [message]="personalizedMessage" [title]="title"
                                    [description]="description" [image]="image" [url]="landingPageUrl">
          </app-social-share-preview>
        </tab>
        <tab>
          <ng-template tabHeading>
            <span>
              <fa-icon [icon]="['far', 'envelope']" class="font-size-xxl"></fa-icon>
            </span>
            <div class="divider"></div>
          </ng-template>
          <br />
          <div class="text-center">
            <a class="btn btn-primary btn-linkedin" [href]="emailURL()" target="_blank">
              <fa-icon [icon]="['fab', 'envelope']"></fa-icon>
              Share by Email
            </a>
          </div>
          <br />
          <app-social-share-preview socialMedia="Email" [emailTitle]="emailTitle" [emailBody]="emailBody"
            [description]="description" [image]="image" [url]="landingPageUrl">
          </app-social-share-preview>
        </tab>
      </tabset>
    </div>
  </div>
</div>
