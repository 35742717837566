import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';

@Component({
  selector: 'app-device-toolbar',
  templateUrl: './device-toolbar.component.html',
  styleUrls: ['./device-toolbar.component.css']
})
export class DeviceToolbarComponent implements OnInit {

  @Output() public openCodeEditor: EventEmitter<any> = new EventEmitter();
  @Output() public onPageSizeChange: EventEmitter<any> = new EventEmitter();
  public viewportSize: any;
  public pageSizeTemplate = [
    {
      icon: 'fa-television',
      name: 'desktop',
      is_active: false,
      page_class: 'col-12 full-page'
    },
    {
      icon: 'fa-tablet',
      name: 'tablet',
      is_active: false,
      page_class: 'col-9 tablet'
    },
    {
      icon: 'fa-mobile',
      name: 'mobile',
      is_active: false,
      page_class: 'col-6 mobile'
    }
  ];

  constructor() {
  }

  ngOnInit(): void {
    this.onPageSizeSelect(this.pageSizeTemplate[0]);
  }

  onPageSizeSelect(item) {
    for (let i = 0; i < this.pageSizeTemplate.length; i++) {
      this.pageSizeTemplate[i].is_active = (item.name === this.pageSizeTemplate[i].name);
    }
    this.viewportSize = item.name;
    this.onPageSizeChange.emit(this.viewportSize);
  }

  onEditHtml() {
    this.openCodeEditor.emit(true);
  }

}
