<div class="container-fluid pt-3 new-styles">
  <div class="rightBody">
    <div class="card card-box mb-5">
      <div class="card-header py-3">
        <div class="card-header--title font-size-lg">
          Manage Personal Access Token
        </div>
        <div class="card-header--actions">
          <button class="btn btn-sm btn-primary" type="button" role="button"
                  (click)="showAddPlatformPopup(addTokenModal)">
            <span class="btn-wrapper--icon"><i class="fa fa-plus"></i></span>
            <span class="btn-wrapper--label">Add New Access Token</span>
          </button>
        </div>
      </div>
      <div class="sticky-dropdown-custom-table p-3">
        <table width="100%" class="sb-table table dataTable no-footer nowrap" [dtTrigger]="dtTrigger" datatable
               [dtOptions]="dtOptions"
               id="dt-table">
          <thead>
          <tr>
            <th>name</th>
            <th class="no-sort">token</th>
            <th>updated date</th>
            <th class="no-sort"></th>
          </tr>
          </thead>
          <tbody *ngIf="personalAccessToken?.length > 0">
          <tr *ngFor="let token of personalAccessToken">
            <td>{{token.name}}</td>
            <td>
              <div>
                {{token.token}}
                <span class="copy-clipboard"><i (click)="copyProjectCode(token?.token)" class="fa fa-clone"></i></span>
              </div>
            </td>
            <td>{{formatMoment(token.updated_at)}}</td>
            <td>
              <button class="btn-icon float-right" mat-icon-button [matMenuTriggerFor]="menu"
                      aria-label="Example icon-button with a menu">
                <span class="fa fa-ellipsis-v"></span>
              </button>
              <mat-menu #menu="matMenu" class="campaign-popover">
                <a class="dropdown-item" (click)="showEditTokenPopup(addTokenModal,token)">
                  <i class="fa fa-pencil mr-2"></i>Edit name</a>
                <a class="dropdown-item" (click)="ResetPersonalAccessToken(token)">
                  <i class="fa fa-refresh mr-2"></i>Reset Token</a>
                <a class="dropdown-item" (click)="deletePersonalAccessToken(token.id)">
                  <i class="fa fa-trash mr-2"></i>Delete</a>
              </mat-menu>
            </td>
          </tr>

          </tbody>
          <tbody *ngIf="personalAccessToken?.length <= 0">
          <tr>
            <td colspan="8" class="no-data-available">No data available in table</td>
          </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</div>

<ng-template #addTokenModal let-modal>
  <form (ngSubmit)="saveAccessTokenFormData()" [formGroup]="addPersonalAccessTokenForm" autocomplete="off">
    <div class="modal-header">
      <h4 class="modal-title d-flex align-items-center">
        {{modalTitle}}
        <span class="share-link ml-2">
  </span>
      </h4>
      <button type="button" class="close" aria-label="Close button" aria-describedby="modal-title"
              (click)="modal.dismiss('cancel')">
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
    <div class="modal-body">
      <div class="form-wraper">

        <div class="row">
          <div class="col-md-12 mb-3">
            <label>Token Name</label>
            <div class='input-group input-group-sm'>
              <input
                class="form-control h-50"
                placeholder="What will this token be used for?"
                type="text"
                formControlName="tokenName"
                [ngClass]="{ 'is-invalid': submitted && f.tokenName.errors }">
            </div>

            <div *ngIf="submitted && f.tokenName.errors" class="bar error-brd ">
              <div *ngIf="f.tokenName.errors.required" class="invalid-text space">Token Name is required</div>
            </div>

          </div>
        </div>
      </div>
    </div>
    <div class="modal-footer">
      <div _ngcontent-c4="" class="text-right ">
        <button _ngcontent-c4="" class="btn btn-primary mr-2" type="button" (click)="modal.close()">Cancel</button>
        <button _ngcontent-c4="" class="btn btn-primary mr-2" type="submit">{{modalBtnName}}</button>
      </div>
    </div>
  </form>
</ng-template>
