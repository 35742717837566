export interface IMailVerifyState {
  status: string;
  success: string;
  error: string;
  serviceProviders: any[];
}

export const initialMailVerifyState: IMailVerifyState = {
  status: '',
  success: '',
  error: '',
  serviceProviders: []
};
