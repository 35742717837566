import {Injectable} from '@angular/core';
import {BehaviorSubject, Subject} from 'rxjs';

import {
  LANDING_PAGE_TEMPLATE_TYPE_ID,
  EMAIL_TEMPLATE_TYPE_ID,
  TEMPLATE_TYPE_IDS, SOCIAL_MEDIA_TEMPLATE_TYPE_ID
} from '@app/consts';
import {select, Store} from '@ngrx/store';
import {
  getVariableError,
  getVariables,
  IVariableState,
  VariableList,
  ITemplateState,
  getTemplate,
  getTemplateError,
  TemplateTouch,
  TemplateGet,
  getTemplates,
  TemplatePreview,
  ICampaignState,
  getCampaign,
  getCampaignError,
  CampaignGet,
  TemplateUpdate,
  TemplateCreate,
  TemplatePreviewURL,
  getTemplatePreviewUrl,
  ResetTemplateState,
  getTemplateSuccess, TemplateListAll, getWidgetPreviewUrl, TemplateWidgetPreviewURL
} from '@app/stores';
import {takeUntil} from 'rxjs/operators';
import {ToastrService} from 'ngx-toastr';

@Injectable({providedIn: 'root'})
export class TemplateGeneratorService {
  private campaignSubject = new BehaviorSubject<any>(null);
  private campaignUpdated = new BehaviorSubject<any>(null);
  private variablesSubject = new BehaviorSubject<any>(null);
  private onVariablesLoaded = this.variablesSubject.asObservable();
  public onCampaignLoaded = this.campaignSubject.asObservable();
  public onCampaignUpdated = this.campaignUpdated.asObservable();
  unsubscriber = new Subject();
  reloadTemplate = true;

  private currentTemplatesSubject = {
    [LANDING_PAGE_TEMPLATE_TYPE_ID]: new BehaviorSubject<any>(null),
    [SOCIAL_MEDIA_TEMPLATE_TYPE_ID]: new BehaviorSubject<any>(null),
    [EMAIL_TEMPLATE_TYPE_ID]: new BehaviorSubject<any>(null)
  };

  onTemplateChanged = {
    [LANDING_PAGE_TEMPLATE_TYPE_ID]:
      this.currentTemplatesSubject[LANDING_PAGE_TEMPLATE_TYPE_ID].asObservable(),
    [EMAIL_TEMPLATE_TYPE_ID]:
      this.currentTemplatesSubject[EMAIL_TEMPLATE_TYPE_ID].asObservable(),
    [SOCIAL_MEDIA_TEMPLATE_TYPE_ID]:
      this.currentTemplatesSubject[SOCIAL_MEDIA_TEMPLATE_TYPE_ID].asObservable()
  };

  private projectLandingPage;
  private campaignTemplates = {
    [EMAIL_TEMPLATE_TYPE_ID]: null
  };

  private templateTypeId = null;
  private templatesFound = false;
  private templateListOptions: any = {};
  private createOrUpdateTemplate = false;
  private previewUrl: string;
  private widgetPreviewUrl: string;
  public forceReload = false;

  constructor(
    private variableStore: Store<IVariableState>,
    private templateStore: Store<ITemplateState>,
    private campaignStore: Store<ICampaignState>,
    private toastr: ToastrService,
  ) {
    // this.subscribeStore();
  }

  public subscribeStore() {
    this.previewUrl = null;
    this.widgetPreviewUrl = null;
    this.templateListOptions = {};
    this.templateStore.dispatch(ResetTemplateState({params: {previewUrl: null, error: '', templates: [], success: '',
        widgetPreviewUrl: null, template: null}}));
    this.variableStoreSubscribe();
    this.templateStoreSubscribe();
    this.campaignStoreSubscribe();
  }

  public variableStoreSubscribe() {
    this.variableStore.pipe(select(getVariables))
      .pipe(takeUntil(this.unsubscriber))
      .subscribe(variables => {
        this.variablesSubject.next(variables);
      });
    this.variableStore.pipe(select(getVariableError))
      .pipe(takeUntil(this.unsubscriber))
      .subscribe(error => {
        if (error) {
          this.toastr.error(error, 'Error');
        }
      });
  }

  public templateStoreSubscribe() {
    this.templateStore.pipe(select(getTemplate))
      .pipe(takeUntil(this.unsubscriber))
      .subscribe(template => {
        if (template && this.reloadTemplate) {
          if (this.createOrUpdateTemplate) {
            this.projectLandingPage = template;
            this.currentTemplatesSubject[this.templateTypeId].next(template);
            this.campaignUpdated.next(template);
            this.createOrUpdateTemplate = false;
          } else {
            if (this.templateTypeId) {
              this.currentTemplatesSubject[this.templateTypeId].next(template);
              this.templateTypeId = null;
            }
            if (this.currentTemplatesSubject[template.template_type_id]) {
              this.currentTemplatesSubject[template.template_type_id].next(template);
            }
          }
        }
        this.reloadTemplate = true;
      });
    this.templateStore.pipe(select(getTemplateSuccess))
      .pipe(takeUntil(this.unsubscriber))
      .subscribe(success => {
        if (success) {
          this.templatesFound = true;
        }
      });
    this.templateStore.pipe(select(getTemplates))
      .pipe(takeUntil(this.unsubscriber))
      .subscribe(templates => {
        if (this.templatesFound) {
          this.templatesFound = false;
          if (templates && templates.length > 0) {
            let template = null;
            if (this.templateListOptions.sourceTemplateId) {
              template = templates.find(item => item.id === this.templateListOptions.sourceTemplateId);
            }
            if (!template) {
              template = templates[0];
            }
            this.currentTemplatesSubject[this.templateListOptions.templateTypeId || LANDING_PAGE_TEMPLATE_TYPE_ID].next(template);
            this.templateListOptions = {};
          } else {
            if (this.templateListOptions.createIfNotExist) {
              this.createAndLoadBlankCampaignTemplate(this.campaign(), this.campaign().segments[0].influencer_type_id,
                this.templateListOptions.templateTypeId, this.templateListOptions.sourceTemplateId);
              this.templateListOptions = {};
            }
          }
        }
      });

    this.templateStore.pipe(select(getTemplatePreviewUrl))
      .pipe(takeUntil(this.unsubscriber))
      .subscribe(previewUrl => {
        if (previewUrl) {
          if (this.previewUrl !== previewUrl.url) {
            this.previewUrl = previewUrl.url;
            window.open(this.previewUrl, '_blank');
          }
        }
      });

    this.templateStore.pipe(select(getWidgetPreviewUrl))
      .pipe(takeUntil(this.unsubscriber))
      .subscribe(widgetPreviewUrl => {
        if (widgetPreviewUrl) {
          if (this.widgetPreviewUrl !== widgetPreviewUrl.url) {
            this.widgetPreviewUrl = widgetPreviewUrl.url;
            window.open(this.widgetPreviewUrl, '_blank');
          }
        }
      });
    this.templateStore.pipe(select(getTemplateError))
      .pipe(takeUntil(this.unsubscriber))
      .subscribe(error => {
        if (error) {
          this.toastr.error(error, 'Error');
        }
      });
  }

  public campaignStoreSubscribe() {
    this.campaignStore.pipe(select(getCampaign))
      .pipe(takeUntil(this.unsubscriber))
      .subscribe(campaign => {
        if (campaign) {
          TEMPLATE_TYPE_IDS.forEach(typeId => {
            this.currentTemplatesSubject[typeId].next(null);
          });
          if (!this.campaign()) {
            this.campaignSubject.next(campaign);
          } else if (this.campaign().id !== campaign.id) {
            this.campaignSubject.next(campaign);
          } else if (this.forceReload) {
            this.campaignSubject.next(campaign);
          }
          this.forceReload = false;
        }
      });
    this.campaignStore.pipe(select(getCampaignError))
      .pipe(takeUntil(this.unsubscriber))
      .subscribe(error => {
        if (error) {
          this.toastr.error(error, 'Error');
        }
      });
  }

  public forceReloadCampaign() {
    if (this.campaign()) {
      const campaignId = this.campaign().id;
      this.campaignSubject.next(null);
      this.forceReload = true;
      this.setCampaign(campaignId);
    }
  }

  public unsubscribe() {
    this.unsubscriber.next();
    this.unsubscriber.complete();
  }

  public loadSelectedTemplate({templateId, template}) {
    this.currentTemplatesSubject[templateId].next(template);
  }

  public clearTemplate(templateId) {
    this.currentTemplatesSubject[templateId].next(null);
  }

  public createAndLoadBlankCampaignTemplate(campaign, influencerTypeId, templateTypeId, sourceTemplateId, extraParams?: any) {
    const params = {
      campaign_id: campaign.id, influencer_type_id: influencerTypeId,
      template_type_id: templateTypeId,
      ...((templateTypeId === LANDING_PAGE_TEMPLATE_TYPE_ID || templateTypeId === SOCIAL_MEDIA_TEMPLATE_TYPE_ID) ? {source_template_id: sourceTemplateId} : {}),
      ...(extraParams ? {...extraParams} : {})
    };
    this.templateTypeId = templateTypeId;
    if (extraParams) {
      this.reloadTemplate = false;
    }
    this.templateStore.dispatch(TemplateTouch({params}));
  }

  public loadVariables(params, callback) {
    this.onVariablesLoaded
      .pipe(takeUntil(this.unsubscriber))
      .subscribe(vars => {
        callback(vars);
      });
    this.variableStore.dispatch(VariableList({params}));
  }

  public setCampaign(campaignId) {
    this.campaignStore.dispatch(CampaignGet(campaignId, {options: JSON.stringify({include_segments: true, include_project: true})}));
  }

  public handlePublicTemplateSelected({templateId}) {
    console.log(`public template selected `, templateId);
    this.templateStore.dispatch(TemplateGet({templateId}));
  }

  public handleClientTemplateSelected({clientTemplateId}) {
    console.log(`client template selected `, clientTemplateId);
    this.templateStore.dispatch(TemplateGet({templateId: clientTemplateId}));
  }

  public updateTemplateInLocalState(template) {
    this.currentTemplatesSubject[template.templateTypeId].next(template);
  }

  touchTemplate(params) {
    this.createAndLoadBlankCampaignTemplate(this.campaign(), this.campaign().segments[0].influencer_type_id,
      params.templateTypeId, params.sourceTemplateId, params.extraParams);
  }

  public loadClientTemplate(params, options = {createIfNotExist: false, forceTouch: false}) {
    if (options.forceTouch) {
      this.createAndLoadBlankCampaignTemplate(this.campaign(), this.campaign().segments[0].influencer_type_id,
        params.templateTypeId, params.sourceTemplateId);
    } else {
      // if there is a template, don't reload it from backend
      // we have already loaded it
      if (this.currentTemplatesSubject[params.templateTypeId].getValue()) {
        return;
      }
      this.templateListOptions = {
        createIfNotExist: options.createIfNotExist,
        templateTypeId: params.templateTypeId,
        sourceTemplateId: Number(params.sourceTemplateId),
      };
      this.templateStore.dispatch(TemplateListAll({
        params: {
          campaign_id: this.campaign().id,
          template_type_id: params.templateTypeId
        }
      }));
    }
  }

  public saveClientTemplate({id, templateTypeId, campaignId, clientTemplate}) {
    this.saveProjectLandingPageTemplate({id, campaignId, clientTemplate, templateTypeId});
  }

  public preview(templateId) {
    this.previewUrl = null;
    this.templateStore.dispatch(TemplatePreviewURL({templateId}));
  }

  public widgetPreview(campaignId) {
    this.widgetPreviewUrl = null;
    this.templateStore.dispatch(TemplateWidgetPreviewURL({campaignId}));
  }

  public sendTestEmail(email, sampleInfluencerEmail = null, additionalContent = '') {
    const params: any = {};
    params.email = email;
    params.isInfluencerEmail = false;
    params.sampleInfluencerEmail = sampleInfluencerEmail;
    params.additionalContent = additionalContent;

    this.templateStore.dispatch(TemplatePreview({
      templateId: this.currentTemplatesSubject[EMAIL_TEMPLATE_TYPE_ID].getValue().id,
      template: params
    }));
  }

  private saveProjectLandingPageTemplate({id, campaignId, clientTemplate, templateTypeId}) {
    // this.projectLandingPage is previous template so it generates issues when we new template is created.
    /*if (!id && this.projectLandingPage) {
      id = this.projectLandingPage.id;
    }*/

    this.createOrUpdateTemplate = true;
    this.templateTypeId = templateTypeId;
    if (id) {
      this.templateStore.dispatch(TemplateUpdate({
        id,
        body: {
          campaign_id: campaignId,
          template_type_id: templateTypeId,
          influencer_type_id: this.influencerTypeId(),
          ...clientTemplate
        }
      }));
    } else {
      this.templateStore.dispatch(TemplateCreate({
        body: {
          campaign_id: campaignId,
          template_type_id: templateTypeId,
          influencer_type_id: this.influencerTypeId(),
          ...clientTemplate
        }
      }));
    }
  }

  private campaign(): any {
    return this.campaignSubject.getValue();
  }

  public setCampaignValue(campaign): any {
    this.campaignSubject.next(campaign);
  }

  public influencerTypeId(): any {
    return this.campaign().segments[0].influencer_type_id;
  }

  private projectId(): any {
    return this.campaign().project_id;
  }

  private project(): any {
    return this.campaign().project;
  }
}
