import {Injectable} from '@angular/core';
import {Actions, createEffect, ofType} from '@ngrx/effects';
import {Store} from '@ngrx/store';
import {catchError, map, switchMap} from 'rxjs/operators';
import {of} from 'rxjs';
import {LoaderService} from '@app/services/loader.service';
import {TrackingType, TrackingTypeError, TrackingTypeSuccess} from "@app/stores/tracking-type/tracking-type.actions";
import {ITrackingTypeState} from '@app/stores';
import {TrackingTypeService} from "@app/services/tracking-type.service";

@Injectable()
export class TrackingTypeEffects {
  constructor(
    private _actions$: Actions,
    private _store: Store<ITrackingTypeState>,
    private trackingTypeService: TrackingTypeService,
    private loaderService: LoaderService
  ) {
  }

  trackingType$ = createEffect(() => this._actions$.pipe(
    ofType(TrackingType),
    switchMap((action) => {
      this.loaderService.show();
      return this.trackingTypeService.getAll({projectId: action.projectId}).pipe(
        map((resp: any) => {
          if (resp.success) {
            this.loaderService.hide();
            return TrackingTypeSuccess({trackingType: resp.data});
          }
          this.loaderService.hide(true);
          return TrackingTypeError({error: this.loaderService.getErrorMessage(resp)});
        }),
        catchError(error => {
          this.loaderService.hide(true);
          return of(TrackingTypeError({error: this.loaderService.getErrorMessage(error)}));
        })
      );
    })
  ));

}
