<div class="card bg-white rounded mx-auto" style="max-width: 500px;" *ngIf="socialMedia === 'LinkedIn'">
  <div class="d-flex mb-2 p-2">
    <img class="img-fluid profile-pic" src="../../../../assets/images/avatars/avatar7.jpg"/>
    <div class="pl-2">
      <span><b>Jane Doe</b></span>
      <br/>
      <span>Chief Engagement Officer at Snöball</span>
    </div>
  </div>
  <span class="pl-2 pr-2 mb-2 pre-wrap">{{message}}</span>
  <img class="img-fluid w-100" *ngIf="contentType === 'image'" src="{{getImage()}}"/>
  <video *ngIf="contentType === 'video'"
         class="img-fluid rounded mr-3 shadow-sm"
         src="{{getImage()}}" width="100%" controls></video>
  <div class="mb-2 p-2">
    <span><b>{{title}}</b></span>
    <br/>
    <span class="facebook-secondary">{{url}}</span>
  </div>
</div>

<div class="card bg-white rounded mx-auto" style="max-width: 500px;" *ngIf="socialMedia === 'Twitter'">
  <div class="d-flex mb-2 p-2">
    <img class="img-fluid profile-pic" src="../../../../assets/images/avatars/avatar7.jpg"/>
    <div class="pl-2">
      <span>Jane Doe <i class='fa fa-certificate twitter-verified'></i></span>
      <br/>
      <h6>@janedoe</h6>
    </div>
  </div>
  <span class="pl-2 pr-2 mb-2 pre-wrap">{{message}}</span>
  <img class="img-fluid w-100" *ngIf="contentType === 'image'" src="{{getImage()}}"/>
  <video *ngIf="contentType === 'video'"
         class="img-fluid rounded mr-3 shadow-sm"
         src="{{getImage()}}" width="100%" controls></video>
  <div class="mb-2 p-2">
    <span>{{title}}</span>
    <br/>
    <span class="facebook-secondary mb-2">{{description}}</span>
  </div>
</div>

<div class="card bg-white rounded mx-auto" style="max-width: 500px;" *ngIf="socialMedia === 'Facebook'">
  <div class="d-flex mb-2 p-2">
    <img class="img-fluid profile-pic" src="../../../../assets/images/avatars/avatar7.jpg"/>
    <div class="pl-2">
      <span class="facebook-user">Jane Doe</span>
      <br/>
      <small>Just Now</small>
    </div>
  </div>
  <span class="pl-2 pr-2 mb-2 pre-wrap">{{message}}</span>
  <img class="img-fluid w-100" *ngIf="contentType === 'image'" src="{{getImage()}}"/>
  <video *ngIf="contentType === 'video'"
         class="img-fluid w-100"
         src="{{getImage()}}" width="100%" controls></video>
  <div class="mb-2 p-2">
    <span class="facebook-secondary">{{url}}</span>
    <br/>
    <span><b>{{title}}</b></span>
    <br/>
    <span class="facebook-secondary">{{description}}</span>
  </div>
</div>

<div class="card bg-white rounded mx-auto" style="max-width: 500px;" *ngIf="socialMedia === 'Whatsapp'">
  <div class="d-flex mb-2 p-2">
    <img class="img-fluid profile-pic" src="../../../../assets/images/avatars/avatar7.jpg"/>
    <div class="pl-2">
      <span>Jane Doe <i class='fa fa-certificate twitter-verified'></i></span>
      <br/>
      <h6>@janedoe</h6>
    </div>
  </div>
  <span class="pl-2 pr-2 mb-2 pre-wrap">{{message}}</span>
  <img class="img-fluid w-100" *ngIf="contentType === 'image'" src="{{getImage()}}"/>
  <video *ngIf="contentType === 'video'"
         class="img-fluid rounded mr-3 shadow-sm"
         src="{{getImage()}}" width="100%" controls></video>
  <div class="mb-2 p-2">
    <span>{{title}}</span>
    <br/>
    <span class="facebook-secondary mb-2">{{description}}</span>
  </div>
</div>

<div class="card bg-white rounded mx-auto" style="max-width: 500px;" *ngIf="socialMedia === 'Slack'">
  <div class="d-flex mb-2 p-2">
    <img class="img-fluid profile-pic" src="../../../../assets/images/avatars/avatar7.jpg"/>
    <div class="pl-2">
      <span>Jane Doe <i class='fa fa-certificate twitter-verified'></i></span>
      <br/>
      <h6>@janedoe</h6>
    </div>
  </div>
  <span class="pl-2 pr-2 mb-2 pre-wrap">{{message}}</span>
  <img class="img-fluid w-100" *ngIf="contentType === 'image'" src="{{getImage()}}"/>
  <video *ngIf="contentType === 'video'"
         class="img-fluid rounded mr-3 shadow-sm"
         src="{{getImage()}}" width="100%" controls></video>
  <div class="mb-2 p-2">
    <span>{{title}}</span>
    <br/>
    <span class="facebook-secondary mb-2">{{description}}</span>
  </div>
</div>

<div class="card bg-white rounded mx-auto" style="max-width: 500px;" *ngIf="socialMedia === 'instagram'">
  <div class="d-flex mb-2 p-2 align-items-center">
    <img class="img-fluid profile-pic" src="../../../../assets/images/avatars/avatar7.jpg"/>
    <div class="pl-2">
      <span>Jane Doe <i class='fa fa-certificate twitter-verified'></i></span>
    </div>
      <i class="fa fa-ellipsis-h ml-auto mr-1" aria-hidden="true"></i>
  </div>
  <img class="img-fluid w-100" *ngIf="contentType === 'image'" src="{{getImage()}}"/>
  <video *ngIf="contentType === 'video'"
         class="img-fluid rounded mr-3 shadow-sm"
         src="{{getImage()}}" width="100%" controls></video>
  <div class="mb-2 p-2">
   <!--    <span>{{title}}</span>-->
<!--    <br/>-->
    <strong>Jane Doe</strong> <span class="pl-2 pr-2 mb-2 pre-wrap">{{message}}</span>
    <br />
    <span class="facebook-secondary mt-2 d-block">{{description}}</span>
  </div>
</div>

<div class="card bg-white rounded mx-auto p-3 pt-2" style="max-width: 500px;" *ngIf="socialMedia === 'Email'">
  <div>
    <p class="facebook-user m-0">to: example@example.com</p>
    <p class="facebook-user m-0">Subject: {{emailTitle || title}}</p>
  </div>
  <hr/>
  <div>
    <p class="pre-wrap">{{emailBody || message}}</p>
  </div>
</div>

<div class="card bg-white rounded mx-auto" style="max-width: 500px;" *ngIf="socialMedia === 'facebook_messenger'">
  <div class="d-flex mb-2 p-2 align-items-center">
    <img class="img-fluid profile-pic" src="../../../../assets/images/avatars/avatar7.jpg"/>
    <div class="pl-2">
      <span>Jane Doe <i class='fa fa-certificate twitter-verified'></i></span>
    </div>
    <i class="fa fa-ellipsis-h ml-auto mr-1" aria-hidden="true"></i>
  </div>
  <img class="img-fluid w-100" *ngIf="contentType === 'image'" src="{{getImage()}}"/>
  <video *ngIf="contentType === 'video'"
         class="img-fluid rounded mr-3 shadow-sm"
         src="{{getImage()}}" width="100%" controls></video>
  <div class="mb-2 p-2">
   <!--    <span>{{title}}</span>-->
<!--    <br/>-->
    <strong>Jane Doe</strong> <span class="pl-2 pr-2 mb-2 pre-wrap">{{message}}</span>
    <br />
    <span class="facebook-secondary mt-2 d-block">{{description}}</span>
  </div>
</div>

<div class="card bg-white rounded mx-auto" style="max-width: 500px;" *ngIf="socialMedia === 'microsoft_teams'">
  <div class="d-flex mb-2 p-2 align-items-center">
    <img class="img-fluid profile-pic" src="../../../../assets/images/avatars/avatar7.jpg"/>
    <div class="pl-2">
      <span>Jane Doe <i class='fa fa-certificate twitter-verified'></i></span>
    </div>
    <i class="fa fa-ellipsis-h ml-auto mr-1" aria-hidden="true"></i>
  </div>
  <img class="img-fluid w-100" *ngIf="contentType === 'image'" src="{{getImage()}}"/>
  <video *ngIf="contentType === 'video'"
         class="img-fluid rounded mr-3 shadow-sm"
         src="{{getImage()}}" width="100%" controls></video>
  <div class="mb-2 p-2">
   <!--    <span>{{title}}</span>-->
<!--    <br/>-->
    <strong>Jane Doe</strong> <span class="pl-2 pr-2 mb-2 pre-wrap">{{message}}</span>
    <br />
    <span class="facebook-secondary mt-2 d-block">{{description}}</span>
  </div>
</div>

<div class="card bg-white rounded mx-auto" style="max-width: 500px;" *ngIf="socialMedia === 'sms'">
  <div class="d-flex mb-2 p-2 align-items-center">
    <img class="img-fluid profile-pic" src="../../../../assets/images/avatars/avatar7.jpg"/>
    <div class="pl-2">
      <span>Jane Doe <i class='fa fa-certificate twitter-verified'></i></span>
    </div>
    <i class="fa fa-ellipsis-h ml-auto mr-1" aria-hidden="true"></i>
  </div>
  <img class="img-fluid w-100" *ngIf="contentType === 'image'" src="{{getImage()}}"/>
  <video *ngIf="contentType === 'video'"
         class="img-fluid rounded mr-3 shadow-sm"
         src="{{getImage()}}" width="100%" controls></video>
  <div class="mb-2 p-2">
   <!--    <span>{{title}}</span>-->
<!--    <br/>-->
    <strong>Jane Doe</strong> <span class="pl-2 pr-2 mb-2 pre-wrap">{{message}}</span>
    <br />
    <span class="facebook-secondary mt-2 d-block">{{description}}</span>
  </div>
</div>

<div class="card bg-white rounded mx-auto" style="max-width: 500px;" *ngIf="socialMedia === 'zalo'">
  <div class="d-flex mb-2 p-2 align-items-center">
    <img class="img-fluid profile-pic" src="../../../../assets/images/avatars/avatar7.jpg"/>
    <div class="pl-2">
      <span>Jane Doe <i class='fa fa-certificate twitter-verified'></i></span>
    </div>
    <i class="fa fa-ellipsis-h ml-auto mr-1" aria-hidden="true"></i>
  </div>
  <img class="img-fluid w-100" *ngIf="contentType === 'image'" src="{{getImage()}}"/>
  <video *ngIf="contentType === 'video'"
         class="img-fluid rounded mr-3 shadow-sm"
         src="{{getImage()}}" width="100%" controls></video>
  <div class="mb-2 p-2">
   <!--    <span>{{title}}</span>-->
<!--    <br/>-->
    <strong>Jane Doe</strong> <span class="pl-2 pr-2 mb-2 pre-wrap">{{message}}</span>
    <br />
    <span class="facebook-secondary mt-2 d-block">{{description}}</span>
  </div>
</div>

<div class="card bg-white rounded mx-auto" style="max-width: 500px;" *ngIf="socialMedia === 'line'">
  <div class="d-flex mb-2 p-2 align-items-center">
    <img class="img-fluid profile-pic" src="../../../../assets/images/avatars/avatar7.jpg"/>
    <div class="pl-2">
      <span>Jane Doe <i class='fa fa-certificate twitter-verified'></i></span>
    </div>
    <i class="fa fa-ellipsis-h ml-auto mr-1" aria-hidden="true"></i>
  </div>
  <img class="img-fluid w-100" *ngIf="contentType === 'image'" src="{{getImage()}}"/>
  <video *ngIf="contentType === 'video'"
         class="img-fluid rounded mr-3 shadow-sm"
         src="{{getImage()}}" width="100%" controls></video>
  <div class="mb-2 p-2">
   <!--    <span>{{title}}</span>-->
<!--    <br/>-->
    <strong>Jane Doe</strong> <span class="pl-2 pr-2 mb-2 pre-wrap">{{message}}</span>
    <br />
    <span class="facebook-secondary mt-2 d-block">{{description}}</span>
  </div>
</div>

<div class="card bg-white rounded mx-auto" style="max-width: 500px;" *ngIf="socialMedia === 'wechat'">
  <div class="d-flex mb-2 p-2 align-items-center">
    <img class="img-fluid profile-pic" src="../../../../assets/images/avatars/avatar7.jpg"/>
    <div class="pl-2">
      <span>Jane Doe <i class='fa fa-certificate twitter-verified'></i></span>
    </div>
    <i class="fa fa-ellipsis-h ml-auto mr-1" aria-hidden="true"></i>
  </div>
  <img class="img-fluid w-100" *ngIf="contentType === 'image'" src="{{getImage()}}"/>
  <video *ngIf="contentType === 'video'"
         class="img-fluid rounded mr-3 shadow-sm"
         src="{{getImage()}}" width="100%" controls></video>
  <div class="mb-2 p-2">
   <!--    <span>{{title}}</span>-->
<!--    <br/>-->
    <strong>Jane Doe</strong> <span class="pl-2 pr-2 mb-2 pre-wrap">{{message}}</span>
    <br />
    <span class="facebook-secondary mt-2 d-block">{{description}}</span>
  </div>
</div>
