import {PaginatedSegments, Segment} from '@app/modules/shared/models/segment';

export interface ISegmentsState {
  segment: Segment;
  segments: Segment[];
  paginatedSegments: PaginatedSegments;
  success: string;
  error: string;
}

export const initialSegmentState: ISegmentsState = {
  segment: null,
  segments: [],
  paginatedSegments: null,
  success: '',
  error: ''
};
