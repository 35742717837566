import {Injectable} from '@angular/core';
import {HttpEvent, HttpHandler, HttpHeaders, HttpInterceptor, HttpParams, HttpRequest} from '@angular/common/http';
import {Observable} from 'rxjs/Observable';
import {select, Store} from '@ngrx/store';
import {getToken, IAuthenticationState, LogoutUser} from '@app/stores';
import {catchError, switchMap, take} from 'rxjs/operators';
import {NavigationEnd, Router} from '@angular/router';
import {throwError} from 'rxjs';
import {LoaderService} from '@app/services/loader.service';
import {ToastrService} from 'ngx-toastr';

@Injectable()
export class HttpAuthInterceptService implements HttpInterceptor {
  inflightAuthRequest = null;
  currentRoute = '/';

  constructor(
    private authentication: Store<IAuthenticationState>,
    private router: Router,
    private toastr: ToastrService,
    private loaderService: LoaderService
  ) {
    router.events.subscribe((val) => {
      if (val instanceof NavigationEnd) {
        if (!this.router.url.includes('/login')) {
          this.currentRoute = this.router.url;
        }
      }
    });
  }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    let newHeaders = new HttpHeaders();
    const hasAccept: boolean = request.headers.has('Accept');
    if (!hasAccept) {
      newHeaders = newHeaders.append('Accept', 'application/json');
    }
    const hasContentType: boolean = request.headers.has('Content-Type');
    if (!hasContentType) {
      newHeaders = newHeaders.append('Content-Type', 'application/json; charset=utf-8');
    }
    let basicAuthRequest = request.clone({headers: newHeaders});
    let hasSkipOAuth: boolean = request.headers.has('Skip-auth');
    const useTempToken: boolean = request.headers.has('Use-Temp-Token');
    if (useTempToken && localStorage.getItem('reportPassword')) {
      hasSkipOAuth = true;
      basicAuthRequest = request.clone(basicAuthRequest.clone({
        headers: basicAuthRequest.headers.append('report-pass', `Bearer ${localStorage.getItem('reportPassword')}`)
      }));
      const project_id = basicAuthRequest.params.get('project_id');

      if (project_id) {
        // Add project id into headers and remove from params
        basicAuthRequest = request.clone(basicAuthRequest.clone({
          headers: basicAuthRequest.headers.append('report-project-id', project_id)
        }));
        basicAuthRequest = basicAuthRequest.clone({
          params: basicAuthRequest.params.delete('project_id'),
        });
      }
    }
    if (!this.inflightAuthRequest) {
      this.inflightAuthRequest = this.authentication.pipe(select(getToken));
    }
    return this.inflightAuthRequest.pipe(
      take(1),
      switchMap((newToken: string) => {
        this.inflightAuthRequest = null;
        const token = newToken ? newToken : '';
        if (!hasSkipOAuth) {
          return next.handle(basicAuthRequest.clone({
            headers: basicAuthRequest.headers.append('Authorization', `Bearer ${token}`)
          }));
        } else {
          return next.handle(basicAuthRequest);
        }
      }),
      catchError(error => {
        let unauthorized = false;
        if (error && error.statusText) {
          unauthorized = !!error.statusText.match(/Unauthorized/g);
        }
        if (error.status === 401 || unauthorized) {
          this.toastr.error('Your session is expired, please refresh the page and login');
          this.loaderService.unsubscribe();
          this.authentication.dispatch(LogoutUser());
          localStorage.removeItem('reportPassword');
          localStorage.removeItem('currentUser');
          localStorage.removeItem('loginInTime');
          return this.router.navigate(['/login'], {queryParams: {returnUrl: this.currentRoute}});
        }
        return throwError(error);
      }) as any
    );
  }
}
