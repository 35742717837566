import {Action, createReducer, on} from '@ngrx/store';
import {
  Report, ReportSuccess, ReportError, ResetReportState, ReportPassword, ReportPasswordSuccess, ReportPasswordError, ReportAdvocatesExport,
  ReportAdvocatesExportSuccess,
  ReportAdvocatesExportError
} from '@app/stores/report/report.actions';
import {initialReportState} from '@app/stores/report/report.state';

const generateReducer = createReducer(initialReportState,
  on(Report, (state) => ({
    ...state,
    report: null,
    success: '',
    error: ''
  })),
  on(ReportSuccess, (state, {report}) => ({
    ...state,
    report,
    success: '',
    error: ''
  })),
  on(ReportError, (state, {error}) => ({
    ...state,
    report: null,
    success: '',
    error
  })),
  on(ReportAdvocatesExport, (state) => ({
    ...state,
    report: null,
    success: '',
    error: ''
  })),
  on(ReportAdvocatesExportSuccess, (state, {projectsReportAdvocatesExport}) => ({
    ...state,
    projectsReportAdvocatesExport,
    success: '',
    error: ''
  })),
  on(ReportAdvocatesExportError, (state, {error}) => ({
    ...state,
    report: null,
    success: '',
    error
  })),

  on(ReportPassword, (state) => ({
    ...state,
    report: null,
    reportPassword: '',
    success: '',
    error: ''
  })),
  on(ReportPasswordSuccess, (state, {reportPassword}) => ({
    ...state,
    report: null,
    reportPassword,
    success: '',
    error: ''
  })),
  on(ReportPasswordError, (state, {error}) => ({
    ...state,
    report: null,
    reportPassword: '',
    success: '',
    error
  })),

  on(ResetReportState, (state, {params}) => ({
    ...state,
    ...params
  })),
);

export function reportReducer(state = initialReportState, action: Action) {
  return generateReducer(state, action);
}
