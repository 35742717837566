import {AfterViewInit, ChangeDetectorRef, Component, ElementRef, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {
  CampaignGetAll,
  getCampaignError,
  getCampaigns,
  getProject,
  getProjectError,
  getRegistrationPlatforms,
  getRegistrationPlatform,
  getVariableError,
  getVariables,
  getWidgetCustomScriptBody,
  getWidgetIntegrationBody,
  ICampaignState,
  IProjectState,
  IRegistrationPlatformState,
  ITemplateState,
  IVariableState,
  ProjectGet,
  ProjectUpdate,
  RegistrationPlatformCreate,
  RegistrationPlatformList,
  VariableList,
  WidgetCustomScriptBody,
  WidgetIntegrationBody,
  getLoggedInUser,
  IAuthenticationState,
  getTemplateSectionAssets,
  ITemplateSectionState,
  getToken,
  TemplateSectionGetAssets,
  getRegistrationPlatformWidgetGuideUrl,
  RegistrationPlatformWidgetGuideURL,
  RegistrationPlatformDelete,
  RegistrationPlatformUpdate,
  RegistrationPlatformGet,
  getRegistrationPlatformSuccessResponse,
  getProjectSuccessResponse,
  getSignedUrlError,
  getUploadToSignedUrlError,
  getFileUploadProgress,
  getSignedUrl,
  getUploadToSignedUrl,
  IFileUploadState,
  RegistrationPlatformGetSuccess,
  UploadToSignedUrl, SignedUrl,
  getWidgetIntegrationBodyError,
  WidgetAvatarTemplateListAll,
  getWidgetAvatarTemplates
} from '@app/stores';
import {ActivatedRoute} from '@angular/router';
import {select, Store} from '@ngrx/store';
import {takeUntil} from 'rxjs/operators';
import {Subject} from 'rxjs';
import {ToastrService} from 'ngx-toastr';
import {Campaign} from '@app/modules/shared/models';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {environment} from '@environments/environment';
import {Validators, UntypedFormGroup, UntypedFormBuilder} from '@angular/forms';
import {Constants} from '@app/consts';
import {TemplateSectionService} from '@app/services/template-section.service';
import {RegistrationPlatformService} from '@app/services/registration-platform.service';
import Swal from 'sweetalert2';
import {WidgetAvatarTemplatesComponent} from '@app/modules/project/widget-avatar-templates/widget-avatar-templates.component';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
// import html = CKEDITOR.ui.dialog.html;

declare var ace: any;
declare var FroalaEditor: any;
function getCodeMirror() {
  // @ts-ignore
  return window.CodeMirror;
}

@Component({
  selector: 'app-widget-instructions',
  templateUrl: './widget-instructions.component.html',
  styleUrls: ['./widget-instructions.component.scss'],
  providers: [Constants]
})
export class WidgetInstructionsComponent implements OnInit, OnDestroy, AfterViewInit {
  aceEditorOptions: any = {maxLines: 1000};
  projectMeta: any = {};
  project: any;
  addRegistrationPlatformForm: UntypedFormGroup;
  variables = {};
  projectId;
  submitted = false;
  unsubscriber = new Subject();
  campaigns: Campaign[] = [];
  registrationPlatforms = [];
  public widgetIntegrationBody: any;
  public widgetCustomScriptBody: any;
  @ViewChild('editor') editor;
  ipLimitUrl = '';
  shareWidgetLinkUrl = '';
  widgetIntegrationUrl: SafeResourceUrl;
  widgetCustomizationCode: any = null;
  widgetCustomizationCss: any = null;
  widgetIntegrationGuide: any = null;
  currentUser: any;
  isAdmin = false;
  public froalaEditor: any;
  editorLoaded = false;
  token = null;
  isLoadEditor = false;
  platformName = '';
  projectPlatformId: number;
  selectedPlatform: any;
  isWidgetSave = false;
  isResetSelectPlatform = false;
  projectRegistrationPlatformId: number = null;
  private widgetGuideUrl: string;
  imageFile = null;
  imageSignedUrl = null;
  fileUploadProgress = 0;
  imageBinary: any;
  selectedcampaign: any;
  third_party_project_id: any;
  selectedWidgetSupport = null;
  selectedSyncSupport = null;
  widgetSupportValues = [{id: 'basic', name: 'Minimum (Clients might need help and it may not always work)'},
    {id: 'full', name: 'Full (Clients can easily add without any help and works but there are workarounds)'},
    {id: 'ultimate', name: 'Ultimate (Clients can easily add without any help and works reliably)'}];
  syncSupportValues = [{id: 'basic', name: 'Basic (Speakers Only)'}];
  defaultAvatarImage = 'https://snoball-dev.s3-accelerate.amazonaws.com/TiqzVPzCFnIRK8XPXCXLo5MsAZPgub.png';
  widgetAIAvatarTemplates = [];
  selectedWidgetAIAvatarTemplate = null;

  constructor(
    private route: ActivatedRoute,
    private formBuilder: UntypedFormBuilder,
    private projectStore: Store<IProjectState>,
    private campaignStore: Store<ICampaignState>,
    private toastr: ToastrService,
    public constants: Constants,
    private authenticationStore: Store<IAuthenticationState>,
    private templateStore: Store<ITemplateState>,
    private templateSectionService: TemplateSectionService,
    private registrationPlatformService: RegistrationPlatformService,
    private registrationPlatformStore: Store<IRegistrationPlatformState>,
    private variableStore: Store<IVariableState>,
    private templateSectionStore: Store<ITemplateSectionState>,
    private readonly cdr: ChangeDetectorRef,
    private fileUploadStore: Store<IFileUploadState>,
    private elRef: ElementRef,
    private _modalService: NgbModal,
    private sanitizer: DomSanitizer
  ) {
    ace.config.set('basePath', environment.frontUrl.replace('#', '') + 'assets/js/ace-modes');
    this.authenticationStore.pipe(select(getToken))
      .pipe(takeUntil(this.unsubscriber))
      .subscribe(newToken => {
        if (newToken) {
          this.token = newToken;
        }
      });
    this.route.params.subscribe(params => {
      if (params.projectId) {
        this.projectId = +params.projectId;
        this.loadProject(this.projectId);
      }
    });
  }

  ngOnInit(): void {
    this.subscribeToStore();
    this.campaignStore.dispatch(CampaignGetAll({filter: {project_id: this.projectId}}));
    // this.variableStore.dispatch(VariableList({params: {}}));
    this.fetchWidgetAITemplates();
    this.getAllRegistrationPlatforms();
    setTimeout(() => {
      this.setWidgetCode();
    }, 500);
    this.addRegistrationPlatformForm = this.formBuilder.group({
      name: ['', Validators.required],
      website: ['', Validators.pattern(this.constants.urlRegex)],
      image: [''],
      widget_customization_code: [''],
      widget_customization_css: [''],
      hide_default_guide: 0
    });
    this.selectedSyncSupport = null;
    this.selectedWidgetSupport = null;
  }

  subscribeToStore() {
    this.widgetGuideUrl = null;
    this.authenticationStore.pipe(select(getLoggedInUser)).subscribe(user => {
        this.currentUser = user;
        this.isAdmin = this.currentUser.role_user[0].role_id === 1;
      }
    );
    this.campaignStore.pipe(select(getCampaignError))
      .pipe(takeUntil(this.unsubscriber))
      .subscribe(error => {
        if (error) {
          this.toastr.error(error, 'Error');
        }
      });
    this.campaignStore.pipe(select(getVariableError))
      .pipe(takeUntil(this.unsubscriber))
      .subscribe(error => {
        if (error) {
          this.toastr.error(error, 'Error');
        }
      });
    this.projectStore.pipe(select(getProjectError))
      .pipe(takeUntil(this.unsubscriber))
      .subscribe(error => {
        if (error) {
          this.toastr.error(error, 'Error');
        }
      });

    this.registrationPlatformStore.pipe(select(getRegistrationPlatform))
      .pipe(takeUntil(this.unsubscriber))
      .subscribe(registrationPlatform => {
        if (registrationPlatform) {
          this.getAllRegistrationPlatforms();
          if (registrationPlatform.widget_support) {
           this.projectMeta['platform_name_with_support'] = `${registrationPlatform.name} ${registrationPlatform.widget_support}`;
           this.projectMeta['registration_platform_id'] = registrationPlatform.id;
           this.selectedPlatform = registrationPlatform.id;
           this.setWidgetCode();
         }
        }
      });

    this.variableStore.pipe(select(getVariables))
      .pipe(takeUntil(this.unsubscriber))
      .subscribe(variables => {
        if (variables) {
          const result = [];
          Object.entries(variables).forEach(([key, value]) => {
            if (value.hasOwnProperty('group') && value['group']) {
              if (!result[value['group']]) {
                result[value['group']] = [];
              }
              result[value['group']].push({
                name: value['title'].replace(value['group'].toString().toLowerCase().trim(), ''),
                key: value['alias'] ? `#${value['alias']}#` : value['key'],
                valueType: value['valueType']
              });
            } else {
              if (!result['generic']) {
                result['generic'] = [];
              }
              result['generic'].push({
                name: value['title'],
                key: value['key'],
                valueType: value['valueType']
              });
            }
          });
          this.variables = result;
        }
      });

    this.campaignStore.pipe(select(getCampaigns))
      .pipe(takeUntil(this.unsubscriber))
      .subscribe(campaigns => {
        if (campaigns && campaigns.length > 0) {
          this.campaigns = campaigns;
        }
      });

    this.registrationPlatformStore.pipe(select(getRegistrationPlatforms))
      .pipe(takeUntil(this.unsubscriber))
      .subscribe(registrationPlatforms => {
        if (registrationPlatforms && registrationPlatforms.length > 0) {
          registrationPlatforms = JSON.parse(JSON.stringify(registrationPlatforms));
          this.registrationPlatforms = registrationPlatforms.map(platform => {
            let supportName = this.widgetSupportValues.filter(item => item.id === platform['widget_support']);
            platform['platform_name_with_support'] = `${platform['name']} ${supportName?.length ? ' : ' + supportName[0]['name'] : ''}`;
            return platform;
          });
          this.setWidgetCode();
        }
      });

    this.registrationPlatformStore.pipe(select(getProjectSuccessResponse))
      .pipe(takeUntil(this.unsubscriber))
      .subscribe(updateProject => {
        if (updateProject) {
          this.project = updateProject;
          setTimeout(() => {
          if (Number(this.selectedPlatform) !== this.projectRegistrationPlatformId && this.projectRegistrationPlatformId) {
            let reg_platform: any;
            reg_platform = this.registrationPlatforms.filter((item) => item.id === this.projectRegistrationPlatformId)[0];
            reg_platform =  JSON.parse(JSON.stringify(reg_platform));
            const project_ids = reg_platform.project_id ? reg_platform.project_id.split(',') : [];
            const projectIdList = project_ids.filter((item) => item !== this.projectId.toString());
            reg_platform['project_id'] = projectIdList.length > 0 ? projectIdList.toString() : '';
            this.registrationPlatformStore.dispatch(RegistrationPlatformUpdate({
              registrationPlatformId: this.projectRegistrationPlatformId,
              registrationPlatform: reg_platform
            }));
          }
          }, 500);

          setTimeout(() => {
            if (this.selectedPlatform) {
              let reg_platform: any;
              reg_platform = this.registrationPlatforms.filter((item) => item.id === Number(this.selectedPlatform))[0];
              reg_platform =  JSON.parse(JSON.stringify(reg_platform));
              const project_ids = reg_platform.project_id ? reg_platform.project_id.split(',') : [];
              if (!project_ids.includes(this.projectId.toString())) {
                reg_platform['project_id'] = reg_platform.project_id ? reg_platform.project_id.concat(',', this.projectId.toString()) : this.projectId.toString();
                this.registrationPlatformStore.dispatch(RegistrationPlatformUpdate({
                  registrationPlatformId: Number(this.selectedPlatform),
                  registrationPlatform: reg_platform
                }));
                this.projectRegistrationPlatformId = Number(this.selectedPlatform);
                this.getAllRegistrationPlatforms();
              }
            }
          }, 2000);
        }

    });

    this.projectStore.pipe(select(getProject))
      .pipe(takeUntil(this.unsubscriber))
      .subscribe(project => {
        if (project) {
          this.project = project;
          //console.log('--------project', project);
          this.ipLimitUrl = environment.frontUrl.replace('/#', '') + `/b/project/${this.project.code}/whitelist/auto_add_ip`;
          this.shareWidgetLinkUrl = environment.frontUrl.replace('/#', '') + `/b/project/${this.project.code}/widget_integration_instruction`;
          if (this.shareWidgetLinkUrl) {
            setTimeout(() => {
              this.widgetIntegrationUrl = this.sanitizer.bypassSecurityTrustResourceUrl(this.shareWidgetLinkUrl);
              console.log('Widget Integration URL:', this.widgetIntegrationUrl);
              this.cdr.detectChanges();
            }, 500);
          }
          if (project.widget_settings) {
            this.projectMeta = (typeof project.widget_settings === 'string') ?
              {...JSON.parse(project.widget_settings)} : {...project.widget_settings};
            if(!this.projectMeta.hasOwnProperty('language')) {
              this.projectMeta['language'] = 'en-us';
            }
            this.projectMeta['registration_platform_default_custom_js'] = project.widget_settings.registration_platform_default_custom_js ? project.widget_settings.registration_platform_default_custom_js : false;
            this.projectMeta['registration_platform_default_custom_css'] = project.widget_settings.registration_platform_default_custom_css ? project.widget_settings.registration_platform_default_custom_css : false;
            this.projectPlatformId = this.projectMeta.hasOwnProperty('registration_platform_id') ? this.projectMeta['registration_platform_id'] : '';
            this.projectRegistrationPlatformId = this.projectPlatformId;
            this.selectedPlatform =  this.projectPlatformId ? this.projectPlatformId.toString() : '';
          } else {
            this.projectMeta = {
              active: false,
              // third_party_project_id: '',
              enable_linkedin_integration_before_registration: false,
              limit_access_ips: '',
              language: 'en-us',
              header_1: '',
              header_2: '',
              header_3: '',
              custom_script: '',
              custom_css: '',
              default_new_register_campaign_id: null,
              conditional_new_register_campaign_id: null,
              registration_platform_id: null,
              note: '',
              whitelist_access: false,
              auto_add_id: false,
              remote_log_disabled: false,
              registration_platform_default_custom_css: true,
              registration_platform_default_custom_js: true,
              user_agreement_checkbox_disabled: false,
              show_preload: false,
              debug: true
            };
          }
        }
      });

    this.templateStore.pipe(select(getWidgetIntegrationBody))
      .pipe(takeUntil(this.unsubscriber))
      .subscribe(widgetIntegrationBody => {
        if (widgetIntegrationBody) {
          this.widgetIntegrationBody = widgetIntegrationBody;
        }
      });

    this.templateStore.pipe(select(getWidgetIntegrationBodyError))
      .pipe(takeUntil(this.unsubscriber))
      .subscribe(error => {
        if (error) {
          this.widgetIntegrationBody = null;
          this.toastr.error(error, 'Error');
        }
      });

    this.templateStore.pipe(select(getWidgetCustomScriptBody))
      .pipe(takeUntil(this.unsubscriber))
      .subscribe(widgetCustomScriptBody => {
        if (widgetCustomScriptBody) {
          this.widgetCustomScriptBody = widgetCustomScriptBody;
        }
      });

    this.templateStore.pipe(select(getWidgetAvatarTemplates))
      .pipe(takeUntil(this.unsubscriber))
      .subscribe(widgetAvatarTemplates => {
        if (widgetAvatarTemplates && widgetAvatarTemplates.length > 0) {
          this.widgetAIAvatarTemplates = widgetAvatarTemplates.map((item) => ({
            ...item,
            selectedImageVariation: item.image_variations.find(image => image.isActive)
          }));

          this.selectedWidgetAIAvatarTemplate = (this.projectMeta.hasOwnProperty('widget_ai_avatar_template') && this.projectMeta['widget_ai_avatar_template']) ?
            this.widgetAIAvatarTemplates.find(item => item.id === this.projectMeta['widget_ai_avatar_template']) : null;
        }
      });

    this.fileStoreSubscribe();
  }

  fileStoreSubscribe() {
    this.fileUploadStore.pipe(select(getSignedUrlError))
      .pipe(takeUntil(this.unsubscriber))
      .subscribe(error => {
        if (error) {
          this.toastr.error(error, 'Error');
        }
      });
    this.fileUploadStore.pipe(select(getUploadToSignedUrlError))
      .pipe(takeUntil(this.unsubscriber))
      .subscribe(error => {
        if (error) {
          this.toastr.error(error, 'Error');
        }
      });
    this.fileUploadStore.pipe(select(getFileUploadProgress))
      .pipe(takeUntil(this.unsubscriber))
      .subscribe((data: any) => {
        if (data !== undefined && data !== null) {
          this.fileUploadProgress = data;
        }
      });
    this.fileUploadStore.pipe(select(getSignedUrl))
      .pipe(takeUntil(this.unsubscriber))
      .subscribe((data: any) => {
        if (data && data.url) {
          this.imageSignedUrl = data.url;
        }
      });
    this.fileUploadStore.pipe(select(getUploadToSignedUrl))
      .pipe(takeUntil(this.unsubscriber))
      .subscribe((data: any) => {
        if (data && this.imageSignedUrl) {
          const image = this.imageSignedUrl.split('?')[0];
          this.addRegistrationPlatformForm.patchValue({image});
          this.imageSignedUrl = null;
          this.imageFile = null;
          this.saveRegistrationPlatformData();
        }
      });
  }

  templateSectionsubscribeStores()  {
    this.templateSectionStore.pipe(select(getTemplateSectionAssets))
      .pipe(takeUntil(this.unsubscriber))
      .subscribe(templateSectionAssets => {
        if (templateSectionAssets) {
          if (Object.keys(this.variables).length > 0) {
            this.enableEditor(templateSectionAssets.assets?.css);
          } else {
            setTimeout(() => {
              this.enableEditor(templateSectionAssets.assets?.css);
            }, 1000);
          }
        }
      });
  }

  ngAfterViewInit() {
    this.editor.getEditor().setOptions({
      showLineNumbers: true,
      tabSize: 2
    });
    this.templateSectionStore.dispatch(TemplateSectionGetAssets({params: {html_mode: 'editor'}}));
    this.editor.mode = 'javascript';
    this.cdr.detectChanges();
  }

  getAllRegistrationPlatforms() {
    this.registrationPlatformStore.dispatch(RegistrationPlatformList({
      params: {
        options: JSON.stringify({widget_support: true}),
      }
    }));
  }

  get f() {
    return this.addRegistrationPlatformForm.controls;
  }

  changeCheckBox(event) {
    this.projectMeta['whitelist_access'] = event.target.checked;
  }

  updateValue(variable, textFor) {
    const value = this.projectMeta[textFor] || '';
    const varValue = variable.key;
    this.projectMeta[textFor] = `${value} ${varValue}`;
  }

  private loadProject(id) {
    this.projectStore.dispatch(ProjectGet({projectId: id}));
  }

  shareWidgetPreview() {
    this.isWidgetSave = true;
    if (!this.projectMeta['default_new_register_campaign_id']) {
      this.toastr.warning('Please select a campaign', 'Warning');
      return;
    }
    let project = JSON.parse(JSON.stringify(this.project));
    project.third_party_project_id = this.third_party_project_id;

    if (this.projectMeta['registration_platform_id']  === null || this.projectMeta['registration_platform_id']  === '') {
      delete this.projectMeta.registration_platform_id;
    } else {
      this.isResetSelectPlatform = true;
    }

    project = {
      ...project,
      widget_settings: {...this.projectMeta}
    };
    this.projectStore.dispatch(ProjectUpdate({project, projectId: this.projectId}));
    this.projectPlatformId = this.projectMeta['registration_platform_id'];
  }

  showWidgetInstructions(widgetInstructions) {
    if (this.projectMeta['active'] === true) {
      if (this.projectMeta['default_new_register_campaign_id']) {
        if ((this.projectPlatformId === Number(this.projectMeta['registration_platform_id']) || !this.projectMeta.hasOwnProperty('registration_platform_id')) && this.project.widget_settings.active === this.projectMeta['active'] && this.project.widget_settings.default_new_register_campaign_id === this.projectMeta['default_new_register_campaign_id']) {
          const modelRef = this._modalService.open(widgetInstructions, {
            centered: false,
            size: 'xl',
            keyboard: true
          });
          modelRef.result.then((result) => {
          }, (reason) => {
          });
          const params: any = {};
          params.campaign_id = this.projectMeta['default_new_register_campaign_id'];
          if (this.projectMeta['registration_platform_id']) {
            params.platform_id = this.projectMeta['registration_platform_id'];
          }
          // this.templateStore.dispatch(WidgetIntegrationBody({params}));
          if (!this.isAdmin) {
            setTimeout(() => {
              document.getElementsByClassName('platform-url')[0].remove();
            }, 500);
          }
        } else {
          this.toastr.warning('Please save your settings', 'Warning');
        }
      } else {
        this.toastr.warning('The widget is active but the settings are incorrect', 'Warning');
      }
    } else {
      this.toastr.warning('The widget is not active', 'Warning');
    }
  }

  showAddPlatformPopup(addNewPlatform) {
    this.submitted = false;
    this.isLoadEditor = false;
    this.imageBinary = null;
    this.fileUploadProgress = 0;
    this.templateSectionsubscribeStores();
    this.cdr.detectChanges();
    const modelRef = this._modalService.open(addNewPlatform, {
        centered: false,
        size: 'xl',
        keyboard: false
      });
      modelRef.result.then((result) => {
      }, (reason) => {
      });
    this.addRegistrationPlatformForm.patchValue({
      hide_default_guide: 0
    });
    this.selectedWidgetSupport = null;
    this.selectedSyncSupport = null;
  }

  onChangeCheckbox (event, slug) {
    const isChecked = event.target.checked;
    if (slug === 'hide_default_guide') {
      this.addRegistrationPlatformForm.patchValue({
        hide_default_guide: isChecked ? 1 : 0
      });
    }
    // if (slug === 'widget_support') {
    //   this.addRegistrationPlatformForm.patchValue({
    //     widget_support: isChecked ? 1 : 0
    //   });
    // }
    // if (slug === 'sync_support') {
    //   this.addRegistrationPlatformForm.patchValue({
    //     sync_support: isChecked ? 1 : 0
    //   });
    // }
  }

  saveRegistrationPlatform() {
    this.submitted = true;
    if (this.addRegistrationPlatformForm.invalid) {
      return;
    }
    this.registrationPlatformStore.dispatch(RegistrationPlatformGetSuccess({registrationPlatform: null}));
    if (this.imageSignedUrl && this.imageFile) {
      this.fileUploadStore.dispatch(UploadToSignedUrl({files: [{url: this.imageSignedUrl, fileData: this.imageFile}]}));
    } else {
      this.saveRegistrationPlatformData();
    }
  }

  setSyncSupport(e) {
    this.selectedSyncSupport = e?.value ? e.value : null;
  }
  setWidgetSupport(e) {
    this.selectedWidgetSupport = e?.value ? e.value : null;
  }

  saveRegistrationPlatformData() {
    const saveParams = this.addRegistrationPlatformForm.value;
    let htmlBody: any = this.getHtml();
    if (htmlBody === '') {
      saveParams['widget_integration_guide'] = null;
    } else {
      htmlBody = htmlBody.replaceAll(/data-id="[^"]*"/g, '');
      htmlBody = htmlBody.replaceAll(' fp-active', '');
      htmlBody = htmlBody.replaceAll('fp-active', '');
      saveParams['widget_integration_guide'] = htmlBody;
    }
    saveParams['widget_support'] = this.selectedWidgetSupport?.length ? this.selectedWidgetSupport : null;
    saveParams['sync_support'] = this.selectedSyncSupport?.length ? this.selectedSyncSupport : null;

      this.registrationPlatformStore.dispatch(RegistrationPlatformCreate({registrationPlatform: saveParams}));
      this._modalService.dismissAll();
      this.addRegistrationPlatformForm.reset();
      this.isLoadEditor = false;
  }

  showCustomScriptInstructions(modal) {
    const modelRef = this._modalService.open(modal, {
      centered: false,
      size: 'xl',
      keyboard: true
    });
    modelRef.result.then((result) => {
    }, (reason) => {
    });
    this.templateStore.dispatch(WidgetCustomScriptBody());
  }

onSelectRegistrationPlatform(event) {
    this.projectMeta['registration_platform_id'] = event ? event.value : null;
    this.selectedPlatform = event ?  this.projectMeta['registration_platform_id'].toString() : '';
    this.setWidgetCode();
  }

  setWidgetCode() {
    const registrationPlatformId = Number(this.projectMeta['registration_platform_id']);
    if (registrationPlatformId) {
      this.registrationPlatforms.map((item) => {
        if (item.id === registrationPlatformId) {
          this.widgetCustomizationCode = item.widget_customization_code;
          this.widgetCustomizationCss = item.widget_customization_css;
          this.widgetIntegrationGuide = item.widget_integration_guide;
          this.platformName = item.name;
        }
      });
    }
  }

  previewWidget() {
    if (this.projectMeta['active']) {
      window.open(`/share/join/${this.project.code}`, '_blank');
    }
  }

  copyProjectCode(code) {
    const input = document.createElement('textarea');
    input.value = code;
    document.body.appendChild(input);
    input.select();
    document.execCommand('Copy');
    this.toastr.success('Project Code is Copied');
    input.remove();
  }

  enableEditor(css: any) {
    const that = this;
    // this.templateSectionService.addCustomVariables(FroalaEditor, this.variables);
    const editorOptions = {
      key: environment.froalaEditorKey,
      iframe: true,
      iframeStyleFiles: css,
      attribution: false,
      charCounterCount: false,
      htmlUntouched: true,
      imageInsertButtons: ['imageBack', '|', 'imageUpload', 'imageByURL'],
      imageUploadURL: environment.apiUrl + '/api/v1/file',
      fileUploadURL: environment.apiUrl + '/api/v1/file',
      height: 'auto',
      heightMax: 'auto',
      heightMin: 300,
      codeMirror: getCodeMirror(),
      codeMirrorOptions: {
        indentWithTabs: true,
        lineNumbers: true,
        lineWrapping: true,
        mode: 'text/html',
        tabMode: 'indent',
        tabSize: 2
      },
      requestHeaders: {
        'Authorization': `Bearer ${this.token}`
      },
      events: {
        'initialized': function () {
          that.editorLoaded = true;
          that.changeContent('section');
        },
        'image.uploaded': function (response) {
          const jsonResponse = JSON.parse(response);
          this.image.insert(jsonResponse.data.url, false, null, this.image.get(), response);
          return false;
        },
        'file.uploaded': function (response) {
          const jsonResponse = JSON.parse(response);
          this.file.insert(jsonResponse.data.url, jsonResponse.data.file, null);
          return false;
        },
      },
      htmlAllowedEmptyTags: ['textarea', 'a', 'iframe', 'object', 'video', 'style', 'script', '.fa', '.fr-emoticon', '.fr-inner', 'path', 'line', 'hr', 'i', 'span', 'div'],
      linkInsertButtons: ['linkBack', '|', 'linkList', 'linkUrlVariables', 'linkTextVariables'],
      pluginsEnabled: ['align', 'charCounter', 'codeBeautifier', 'codeView', 'colors', 'cryptoJSPlugin', 'draggable', 'emoticons', 'entities',
        'file', 'filesManager', 'fontAwesome', 'fontFamily', 'fontSize', 'forms', 'fullscreen', 'help', 'image', 'imageManager', 'imageVariable',
        'inlineClass', 'inlineStyle', 'lineBreaker', 'lineHeight', 'link', 'lists', 'markdown', 'paragraphFormat', 'paragraphStyle',
        'print', 'quickInsert', 'quote', 'save', 'specialCharacters', 'table', 'url', 'video', 'wordPaste', 'html'],
      imageEditButtons: ['imageReplace', 'imageVariableButton', 'imageAlign', 'imageCaption', 'imageRemove', 'imageLink', 'linkOpen',
        'linkEdit', 'linkRemove', '-', 'imageDisplay', 'imageStyle', 'imageAlt', 'imageSize'],
      toolbarButtons: ['bold', 'italic', 'textColor', 'backgroundColor', 'paragraphFormat', 'align', 'emoticons', 'insertLink',
        'insertImage', 'undo', 'redo', '-', 'underline', 'subscript', 'superscript', 'fontSize', 'fontFamily', 'insertTable', 'formatOL', 'formatUL',
        'lineHeight', 'insertVideo', 'fontAwesome', 'inlineClass', 'inlineStyle', 'insertFile', 'html']
    };
    this.froalaEditor = new FroalaEditor('#editor1', editorOptions);
    if (Object.keys(this.froalaEditor).length > 0) {
      this.addCssToFroala();
    } else {
      setTimeout(() => {
        this.froalaEditor = new FroalaEditor('#editor1', editorOptions);
        this.addCssToFroala();
      }, 1000);
    }
  }

  addCssToFroala() {
    const css = this.froalaEditor.$iframe[0].contentWindow.document.createElement('style');
    css.innerHTML = this.templateSectionService.getFroalaCss();
    this.froalaEditor.$iframe[0].contentWindow.document.head.appendChild(css);
    this.isLoadEditor = true;
  }

  changeContent(type = '') {
    this.froalaEditor.html.set('');
  }

  getHtml() {
    return this.froalaEditor.html.get(true);
  }

  onclickWidgetGuide() {
    const platformId = this.projectMeta['registration_platform_id'];
    this.registrationPlatformService.previewUrl(platformId).pipe(takeUntil(this.unsubscriber)).subscribe(response => {
      if (response['success']) {
        window.open(response['data'].url, '_blank');
      }
      });
  }

  uploadLogo(fileInput) {
    fileInput.click();
  }

  onEventImageChanged(event) {
    if (event.target.files && event.target.files.length) {
      this.imageFile = event.target.files[0];
      this.fileUploadStore.dispatch(SignedUrl({fileName: this.imageFile.name, contentType: this.imageFile.type}));
      new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(this.imageFile);
        reader.onloadend = () => resolve(reader.result);
        reader.onerror = error => reject(error);
      }).then(data => {
        this.imageBinary = data;
      });
    }
  }

  showCustomizeAvatarTemplatePopup() {
    this.cdr.detectChanges();
    const data = {
      project_id: this.projectId
    };
    const modelRef: any = this._modalService.open(WidgetAvatarTemplatesComponent, {
      centered: false,
      size: 'xl',
      keyboard: true
    });
    modelRef.componentInstance.data = data;
    modelRef.result.then((result) => {
    }, (reason) => {
      this.fetchWidgetAITemplates()
    });
  }

  fetchWidgetAITemplates() {
    const orderBy = {'column': 'created_at', 'dir': 'desc'};
    let options = {orderBy};
    setTimeout(() => {
      this.templateStore.dispatch(WidgetAvatarTemplateListAll({
        params: {
          options: JSON.stringify(options),
          project_id: this.projectId,
        }
      }));
    }, 500);
  }

  selectWidgetAIAvatarTemplate(template) {
    this.projectMeta['widget_ai_avatar_template'] = template.id;
    this.selectedWidgetAIAvatarTemplate = template;
  }

  ngOnDestroy() {
    this.unsubscriber.next();
    this.unsubscriber.complete();
  }

}
