import {Injectable} from '@angular/core';
import {Actions, createEffect, ofType} from '@ngrx/effects';
import {Store} from '@ngrx/store';
import {catchError, map, switchMap} from 'rxjs/operators';
import {of} from 'rxjs';
import {LoaderService} from '@app/services/loader.service';
import {
  Report, ReportSuccess, ReportError, ReportPassword, ReportPasswordSuccess, ReportPasswordError,
  ReportAdvocatesExport,
  ReportAdvocatesExportSuccess,
  ReportAdvocatesExportError
} from '@app/stores/report/report.actions';
import {IReportState} from '@app/stores/report/report.state';
import {ImpressionService} from '@app/services/impression.service';

@Injectable()
export class ReportEffects {
  constructor(
    private _actions$: Actions,
    private _store: Store<IReportState>,
    private impressionService: ImpressionService,
    private loaderService: LoaderService
  ) {
  }

  report$ = createEffect(() => this._actions$.pipe(
    ofType(Report),
    switchMap((action) => {
      this.loaderService.show();
      return this.impressionService.report(action.projectId, action.params).pipe(
        map((resp: any) => {
          if (resp.success) {
            this.loaderService.hide();
            return ReportSuccess({report: resp.data});
          }
          this.loaderService.hide(true);
          return ReportError({error: this.loaderService.getErrorMessage(resp)});
        }),
        catchError(error => {
          this.loaderService.hide(true);
          return of(ReportError({error: this.loaderService.getErrorMessage(error)}));
        })
      );
    })
  ));

  exportAdvocatesReport$ = createEffect(() => this._actions$.pipe(
    ofType(ReportAdvocatesExport),
    switchMap((action) => {
      // this.loaderService.show();
      return this.impressionService.exportAdvocatesReport(action.projectId, action.params).pipe(
        map((resp: any) => {
          if (resp.success) {
            // this.loaderService.hide();
            return ReportAdvocatesExportSuccess({projectsReportAdvocatesExport: resp.data});
          }
          // this.loaderService.hide(true);
          return ReportAdvocatesExportError({error: this.loaderService.getErrorMessage(resp)});
        }),
        catchError(error => {
          // this.loaderService.hide(true);
          return of(ReportAdvocatesExportError({error: this.loaderService.getErrorMessage(error)}));
        })
      );
    })
  ));

  reportPassword$ = createEffect(() => this._actions$.pipe(
    ofType(ReportPassword),
    switchMap((action) => {
      this.loaderService.show();
      return this.impressionService.reportPassword(action.projectId, action.password).pipe(
        map((resp: any) => {
          if (resp.success) {
            this.loaderService.hide();
            localStorage.setItem('reportPassword', resp.data);
            return ReportPasswordSuccess({reportPassword: resp.data});
          }
          this.loaderService.hide(true);
          return ReportPasswordError({error: this.loaderService.getErrorMessage(resp)});
        }),
        catchError(error => {
          this.loaderService.hide(true);
          return of(ReportPasswordError({error: this.loaderService.getErrorMessage(error)}));
        })
      );
    })
  ));
}
