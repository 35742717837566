import {createFeatureSelector, createSelector} from '@ngrx/store';
import {ISettingsState} from '@app/stores';

const selectSetting = createFeatureSelector<ISettingsState>('settings');

export const getSetting = createSelector(selectSetting, (state: ISettingsState) => state.setting);

export const getSettingSuccess = createSelector(selectSetting, (state: ISettingsState) => state.success);

export const getSettingError = createSelector(selectSetting, (state: ISettingsState) => state.error);

export const getSettings = createSelector(selectSetting, (state: ISettingsState) => state.settings);

export const getPaginatedSettings = createSelector(selectSetting, (state: ISettingsState) => state.paginatedSettings);
