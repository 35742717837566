import {Injectable} from '@angular/core';
import {Actions, createEffect, ofType} from '@ngrx/effects';
import {Store} from '@ngrx/store';
import {catchError, map, switchMap} from 'rxjs/operators';
import {of} from 'rxjs';
import {LoaderService} from '@app/services/loader.service';
import {
  TemplateList,
  TemplateListSuccess,
  TemplateListError,
  TemplateUpdate,
  TemplateUpdateSuccess,
  TemplateUpdateError,
  TemplateTouch,
  TemplateTouchSuccess,
  TemplateTouchError,
  TemplateGet,
  TemplateGetSuccess,
  TemplateGetError,
  TemplatePreview,
  TemplatePreviewSuccess,
  TemplatePreviewError,
  TemplateUpdateStatusError,
  TemplateUpdateStatusSuccess,
  TemplateUpdateStatus,
  TemplateCreateError,
  TemplateCreate,
  TemplateCreateSuccess,
  TemplateCampaign,
  TemplateCampaignSuccess,
  TemplateCampaignError,
  TemplatePreviewURL,
  TemplatePreviewURLSuccess,
  TemplatePreviewURLError,
  TemplateDelete,
  TemplateDeleteSuccess,
  TemplateDeleteError,
  WidgetIntegrationBody,
  WidgetIntegrationBodySuccess,
  WidgetIntegrationBodyError,
  WidgetCustomScriptBody,
  WidgetCustomScriptBodySuccess,
  WidgetCustomScriptBodyError,
  TemplateUpdateThumbnail,
  TemplateUpdateThumbnailSuccess,
  TemplateUpdateThumbnailError,
  TemplateListAll,
  TemplateListAllSuccess,
  TemplateListAllError,
  TemplateGetTypes,
  TemplateGetTypesSuccess,
  TemplateGetTypesError,
  TemplateWidgetPreviewURL,
  TemplateWidgetPreviewURLSuccess,
  TemplateWidgetPreviewURLError,
  TemplateGetRevisions,
  TemplateGetRevisionsSuccess,
  TemplateGetRevisionsError,
  WidgetAvatarTemplateList,
  WidgetAvatarTemplateListSuccess,
  WidgetAvatarTemplateListError,
  WidgetAvatarTemplateCreate,
  WidgetAvatarTemplateCreateSuccess,
  WidgetAvatarTemplateCreateError,
  WidgetAvatarTemplateUpdate,
  WidgetAvatarTemplateUpdateSuccess,
  WidgetAvatarTemplateUpdateError,
  WidgetAvatarTemplateListAll,
  WidgetAvatarTemplateListAllError,
  WidgetAvatarTemplateListAllSuccess,
  WidgetAvatarTemplateDelete,
  WidgetAvatarTemplateDeleteSuccess,
  WidgetAvatarTemplateDeleteError,
  WidgetAvatarTemplateGenerate,
  WidgetAvatarTemplateGenerateError,
  WidgetAvatarTemplateGenerateSuccess
} from '@app/stores/template/template.actions';
import {ITemplateState} from '@app/stores/template/template.state';
import {TemplateService} from '@app/services/template.service';

@Injectable()
export class TemplateEffects {
  constructor(
    private _actions$: Actions,
    private _store: Store<ITemplateState>,
    private templateService: TemplateService,
    private loaderService: LoaderService
  ) {
  }

  templateListingAll$ = createEffect(() => this._actions$.pipe(
    ofType(TemplateListAll),
    switchMap((action) => {
      this.loaderService.show();
      return this.templateService.getAll(action.params).pipe(
        map((resp: any) => {
          if (resp.success) {
            this.loaderService.hide();
            return TemplateListAllSuccess({templates: resp.data});
          }
          this.loaderService.hide(true);
          return TemplateListAllError({error: this.loaderService.getErrorMessage(resp)});
        }),
        catchError(error => {
          this.loaderService.hide(true);
          return of(TemplateListAllError({error: this.loaderService.getErrorMessage(error)}));
        })
      );
    })
  ));

  templateListing$ = createEffect(() => this._actions$.pipe(
    ofType(TemplateList),
    switchMap((action) => {
      this.loaderService.show();
      return this.templateService.getAll(action.params).pipe(
        map((resp: any) => {
          if (resp.success) {
            this.loaderService.hide();
            return TemplateListSuccess({paginatedTemplates: resp.data});
          }
          this.loaderService.hide(true);
          return TemplateListError({error: this.loaderService.getErrorMessage(resp)});
        }),
        catchError(error => {
          this.loaderService.hide(true);
          return of(TemplateListError({error: this.loaderService.getErrorMessage(error)}));
        })
      );
    })
  ));

  templateUpdateStatus$ = createEffect(() => this._actions$.pipe(
    ofType(TemplateUpdateStatus),
    switchMap((action) => {
      this.loaderService.show();
      return this.templateService.updateStatus(action.id, action.status).pipe(
        map((resp: any) => {
          if (resp.success) {
            this.loaderService.hide();
            return TemplateUpdateStatusSuccess({template: resp.data});
          }
          this.loaderService.hide(true);
          return TemplateUpdateStatusError({error: this.loaderService.getErrorMessage(resp)});
        }),
        catchError(error => {
          this.loaderService.hide(true);
          return of(TemplateUpdateStatusError({error: this.loaderService.getErrorMessage(error)}));
        })
      );
    })
  ));

  templateTouch$ = createEffect(() => this._actions$.pipe(
    ofType(TemplateTouch),
    switchMap((action) => {
      this.loaderService.show();
      return this.templateService.touch(action.params).pipe(
        map((resp: any) => {
          if (resp.success) {
            this.loaderService.hide();
            return TemplateTouchSuccess({template: resp.data});
          }
          this.loaderService.hide(true);
          return TemplateTouchError({error: this.loaderService.getErrorMessage(resp)});
        }),
        catchError(error => {
          this.loaderService.hide(true);
          return of(TemplateTouchError({error: this.loaderService.getErrorMessage(error)}));
        })
      );
    })
  ));

  templateGet$ = createEffect(() => this._actions$.pipe(
    ofType(TemplateGet),
    switchMap((action) => {
      this.loaderService.show();
      return this.templateService.get(action.templateId).pipe(
        map((resp: any) => {
          if (resp.success) {
            this.loaderService.hide();
            return TemplateGetSuccess({template: resp.data});
          }
          this.loaderService.hide(true);
          return TemplateGetError({error: this.loaderService.getErrorMessage(resp)});
        }),
        catchError(error => {
          this.loaderService.hide(true);
          return of(TemplateGetError({error: this.loaderService.getErrorMessage(error)}));
        })
      );
    })
  ));

  templatePreview$ = createEffect(() => this._actions$.pipe(
    ofType(TemplatePreview),
    switchMap((action) => {
      this.loaderService.show();
      return this.templateService.preview(action.templateId, action.template).pipe(
        map((resp: any) => {
          if (resp.success) {
            this.loaderService.hide();
            return TemplatePreviewSuccess();
          }
          this.loaderService.hide(true);
          return TemplatePreviewError({error: this.loaderService.getErrorMessage(resp)});
        }),
        catchError(error => {
          this.loaderService.hide(true);
          return of(TemplatePreviewError({error: this.loaderService.getErrorMessage(error)}));
        })
      );
    })
  ));

  templateUpdate$ = createEffect(() => this._actions$.pipe(
    ofType(TemplateUpdate),
    switchMap((action) => {
      this.loaderService.show();
      return this.templateService.update(action.id, action.body).pipe(
        map((resp: any) => {
          if (resp.success) {
            this.loaderService.hide();
            return TemplateUpdateSuccess({template: resp.data});
          }
          this.loaderService.hide(true);
          return TemplateUpdateError({error: this.loaderService.getErrorMessage(resp)});
        }),
        catchError(error => {
          this.loaderService.hide(true);
          return of(TemplateUpdateError({error: this.loaderService.getErrorMessage(error)}));
        })
      );
    })
  ));

  templateCreate$ = createEffect(() => this._actions$.pipe(
    ofType(TemplateCreate),
    switchMap((action) => {
      this.loaderService.show();
      return this.templateService.create(action.body).pipe(
        map((resp: any) => {
          if (resp.success) {
            this.loaderService.hide();
            return TemplateCreateSuccess({template: resp.data});
          }
          this.loaderService.hide(true);
          return TemplateCreateError({error: this.loaderService.getErrorMessage(resp)});
        }),
        catchError(error => {
          this.loaderService.hide(true);

          //
          // Treat error
          if(error?.error?.message?.includes('notEmpty on body')) error.error.message = 'Template is empty, add some content and try again';
          return of(TemplateCreateError({error: this.loaderService.getErrorMessage(error)}));
        })
      );
    })
  ));

  templateCampaign$ = createEffect(() => this._actions$.pipe(
    ofType(TemplateCampaign),
    switchMap((action) => {
      this.loaderService.show();
      return this.templateService.indexForCampaign(action.params).pipe(
        map((resp: any) => {
          if (resp.success) {
            this.loaderService.hide();
            return TemplateCampaignSuccess({templateCampaign: resp.data});
          }
          this.loaderService.hide(true);
          return TemplateCampaignError({error: this.loaderService.getErrorMessage(resp)});
        }),
        catchError(error => {
          this.loaderService.hide(true);
          return of(TemplateCampaignError({error: this.loaderService.getErrorMessage(error)}));
        })
      );
    })
  ));

  templatePreviewUrl$ = createEffect(() => this._actions$.pipe(
    ofType(TemplatePreviewURL),
    switchMap((action) => {
      this.loaderService.show();
      return this.templateService.previewUrl(action.templateId).pipe(
        map((resp: any) => {
          if (resp.success) {
            this.loaderService.hide();
            return TemplatePreviewURLSuccess({previewUrl: resp.data});
          }
          this.loaderService.hide(true);
          return TemplatePreviewURLError({error: this.loaderService.getErrorMessage(resp)});
        }),
        catchError(error => {
          this.loaderService.hide(true);
          return of(TemplatePreviewURLError({error: this.loaderService.getErrorMessage(error)}));
        })
      );
    })
  ));

  widgetPreviewUrl$ = createEffect(() => this._actions$.pipe(
    ofType(TemplateWidgetPreviewURL),
    switchMap((action) => {
      this.loaderService.show();
      return this.templateService.widgetPreviewUrl(action.campaignId).pipe(
        map((resp: any) => {
          if (resp.success) {
            this.loaderService.hide();
            return TemplateWidgetPreviewURLSuccess({widgetPreviewUrl: resp.data});
          }
          this.loaderService.hide(true);
          return TemplateWidgetPreviewURLError({error: this.loaderService.getErrorMessage(resp)});
        }),
        catchError(error => {
          this.loaderService.hide(true);
          return of(TemplateWidgetPreviewURLError({error: this.loaderService.getErrorMessage(error)}));
        })
      );
    })
  ));

  templateDelete$ = createEffect(() => this._actions$.pipe(
    ofType(TemplateDelete),
    switchMap((action) => {
      this.loaderService.show();
      return this.templateService.deleteTemplate(action.templateId).pipe(
        map((resp: any) => {
          if (resp.success) {
            this.loaderService.hide();
            return TemplateDeleteSuccess();
          }
          this.loaderService.hide(true);
          return TemplateDeleteError({error: this.loaderService.getErrorMessage(resp)});
        }),
        catchError(error => {
          this.loaderService.hide(true);
          return of(TemplateDeleteError({error: this.loaderService.getErrorMessage(error)}));
        })
      );
    })
  ));

  widgetIntegrationBody$ = createEffect(() => this._actions$.pipe(
    ofType(WidgetIntegrationBody),
    switchMap((action) => {
      this.loaderService.show();
      return this.templateService.widgetInstructions(action.params).pipe(
        map((resp: any) => {
          if (resp.success) {
            this.loaderService.hide();
            return WidgetIntegrationBodySuccess({widgetIntegrationBody: resp.data});
          }
          this.loaderService.hide(true);
          return WidgetIntegrationBodyError({error: this.loaderService.getErrorMessage(resp)});
        }),
        catchError(error => {
          this.loaderService.hide(true);
          return of(WidgetIntegrationBodyError({error: this.loaderService.getErrorMessage(error)}));
        })
      );
    })
  ));

  widgetCustomScriptBody$ = createEffect(() => this._actions$.pipe(
    ofType(WidgetCustomScriptBody),
    switchMap((action) => {
      this.loaderService.show();
      return this.templateService.customScriptInstructions().pipe(
        map((resp: any) => {
          if (resp.success) {
            this.loaderService.hide();
            return WidgetCustomScriptBodySuccess({widgetCustomScriptBody: resp.data});
          }
          this.loaderService.hide(true);
          return WidgetCustomScriptBodyError({error: this.loaderService.getErrorMessage(resp)});
        }),
        catchError(error => {
          this.loaderService.hide(true);
          return of(WidgetCustomScriptBodyError({error: this.loaderService.getErrorMessage(error)}));
        })
      );
    })
  ));

  templateUpdateThumbnail$ = createEffect(() => this._actions$.pipe(
    ofType(TemplateUpdateThumbnail),
    switchMap((action) => {
      this.loaderService.show();
      return this.templateService.updateThumbnails().pipe(
        map((resp: any) => {
          if (resp.success) {
            this.loaderService.hide();
            return TemplateUpdateThumbnailSuccess({success: 'Thumbnails are updating'});
          }
          this.loaderService.hide(true);
          return TemplateUpdateThumbnailError({error: this.loaderService.getErrorMessage(resp)});
        }),
        catchError(error => {
          this.loaderService.hide(true);
          return of(TemplateUpdateThumbnailError({error: this.loaderService.getErrorMessage(error)}));
        })
      );
    })
  ));

  templateGetTypes$ = createEffect(() => this._actions$.pipe(
    ofType(TemplateGetTypes),
    switchMap((action) => {
      this.loaderService.show();
      return this.templateService.getTemplateTypes().pipe(
        map((resp: any) => {
          if (resp.success) {
            this.loaderService.hide();
            return TemplateGetTypesSuccess({templateTypes: resp.data});
          }
          this.loaderService.hide(true);
          return TemplateGetTypesError({error: this.loaderService.getErrorMessage(resp)});
        }),
        catchError(error => {
          this.loaderService.hide(true);
          return of(TemplateGetTypesError({error: this.loaderService.getErrorMessage(error)}));
        })
      );
    })
  ));

  templateGetRevisions$ = createEffect(() => this._actions$.pipe(
    ofType(TemplateGetRevisions),
    switchMap((action) => {
      this.loaderService.show();
      return this.templateService.getTemplateGetRevisions(action.templateId, action.params).pipe(
        map((resp: any) => {
          if (resp.success) {
            this.loaderService.hide();
            return TemplateGetRevisionsSuccess({templateRevisions: resp.data});
          }
          this.loaderService.hide(true);
          return TemplateGetRevisionsError({error: this.loaderService.getErrorMessage(resp)});
        }),
        catchError(error => {
          this.loaderService.hide(true);
          return of(TemplateGetRevisionsError({error: this.loaderService.getErrorMessage(error)}));
        })
      );
    })
  ));

  widgetAvatarTemplateListing$ = createEffect(() => this._actions$.pipe(
    ofType(WidgetAvatarTemplateList),
    switchMap((action) => {
      this.loaderService.show();
      return this.templateService.getAllWidgetAvatarTemplates(action.params).pipe(
        map((resp: any) => {
          if (resp.success) {
            this.loaderService.hide();
            return WidgetAvatarTemplateListSuccess({paginatedWidgetAvatarTemplates: resp.data});
          }
          this.loaderService.hide(true);
          return WidgetAvatarTemplateListError({error: this.loaderService.getErrorMessage(resp)});
        }),
        catchError(error => {
          this.loaderService.hide(true);
          return of(WidgetAvatarTemplateListError({error: this.loaderService.getErrorMessage(error)}));
        })
      );
    })
  ));

  widgetAvatarTemplateListingAll$ = createEffect(() => this._actions$.pipe(
    ofType(WidgetAvatarTemplateListAll),
    switchMap((action) => {
      this.loaderService.show();
      return this.templateService.getAllWidgetAvatarTemplates(action.params).pipe(
        map((resp: any) => {
          if (resp.success) {
            this.loaderService.hide();
            return WidgetAvatarTemplateListAllSuccess({widgetAvatarTemplates: resp.data});
          }
          this.loaderService.hide(true);
          return WidgetAvatarTemplateListAllError({error: this.loaderService.getErrorMessage(resp)});
        }),
        catchError(error => {
          this.loaderService.hide(true);
          return of(WidgetAvatarTemplateListAllError({error: this.loaderService.getErrorMessage(error)}));
        })
      );
    })
  ));

  widgetAvatarTemplateCreate$ = createEffect(() => this._actions$.pipe(
    ofType(WidgetAvatarTemplateCreate),
    switchMap((action) => {
      this.loaderService.show();
      return this.templateService.createWidgetAvatarTemplate(action.body).pipe(
        map((resp: any) => {
          if (resp.success) {
            this.loaderService.hide();
            return WidgetAvatarTemplateCreateSuccess({widgetAvatarTemplate: resp.data});
          }
          this.loaderService.hide(true);
          return WidgetAvatarTemplateCreateError({error: this.loaderService.getErrorMessage(resp)});
        }),
        catchError(error => {
          this.loaderService.hide(true);
          if(error?.error?.message?.includes('notEmpty on body')) error.error.message = 'Template is empty, add some content and try again';
          return of(WidgetAvatarTemplateCreateError({error: this.loaderService.getErrorMessage(error)}));
        })
      );
    })
  ));

  widgetAvatarTemplateUpdate$ = createEffect(() => this._actions$.pipe(
    ofType(WidgetAvatarTemplateUpdate),
    switchMap((action) => {
      this.loaderService.show();
      return this.templateService.updateWidgetAvatarTemplate(action.id, action.body).pipe(
        map((resp: any) => {
          if (resp.success) {
            this.loaderService.hide();
            return WidgetAvatarTemplateUpdateSuccess({widgetAvatarTemplate: resp.data});
          }
          this.loaderService.hide(true);
          return WidgetAvatarTemplateUpdateError({error: this.loaderService.getErrorMessage(resp)});
        }),
        catchError(error => {
          this.loaderService.hide(true);
          return of(WidgetAvatarTemplateUpdateError({error: this.loaderService.getErrorMessage(error)}));
        })
      );
    })
  ));

  widgetAvatarTemplateDelete$ = createEffect(() => this._actions$.pipe(
    ofType(WidgetAvatarTemplateDelete),
    switchMap((action) => {
      this.loaderService.show();
      return this.templateService.deleteWidgetAvatarTemplate(action.widgetAvatarTemplateId).pipe(
        map((resp: any) => {
          if (resp.success) {
            this.loaderService.hide();
            return WidgetAvatarTemplateDeleteSuccess();
          }
          this.loaderService.hide(true);
          return WidgetAvatarTemplateDeleteError({error: this.loaderService.getErrorMessage(resp)});
        }),
        catchError(error => {
          this.loaderService.hide(true);
          return of(WidgetAvatarTemplateDeleteError({error: this.loaderService.getErrorMessage(error)}));
        })
      );
    })
  ));

  widgetAvatarTemplateGenerate$ = createEffect(() => this._actions$.pipe(
    ofType(WidgetAvatarTemplateGenerate),
    switchMap((action) => {
      this.loaderService.show();
      return this.templateService.generateWidgetAvatars(action.body).pipe(
        map((resp: any) => {
          if (resp.success) {
            this.loaderService.hide();
            return WidgetAvatarTemplateGenerateSuccess({widgetAvatars: resp.data});
          }
          this.loaderService.hide(true);
          return WidgetAvatarTemplateGenerateError({error: this.loaderService.getErrorMessage(resp)});
        }),
        catchError(error => {
          this.loaderService.hide(true);
          return of(WidgetAvatarTemplateGenerateError({error: this.loaderService.getErrorMessage(error)}));
        })
      );
    })
  ));
}
