import {RegistrationPlatform, PaginatedRegistrationPlatform} from '@app/modules/shared/models/registration-platform';


export interface IRegistrationPlatformState {
  registrationPlatform: RegistrationPlatform;
  registrationPlatforms: RegistrationPlatform[];
  paginatedRegistrationPlatform: PaginatedRegistrationPlatform;
  success: string;
  error: string;
  widgetGuideUrl: any;
  influencersSync: any;
  slug: string;
}

export const initialRegistrationPlatformState: IRegistrationPlatformState = {
  registrationPlatform: null,
  registrationPlatforms: [],
  paginatedRegistrationPlatform: null,
  success: '',
  error: '',
  widgetGuideUrl: null,
  influencersSync: null,
  slug: '',
};
