import {Injectable} from '@angular/core';
import {Actions, createEffect, ofType} from '@ngrx/effects';
import {Store} from '@ngrx/store';
import {catchError, map, switchMap} from 'rxjs/operators';
import {of} from 'rxjs';
import {LoaderService} from '@app/services/loader.service';
import {
  ClientsList,
  ClientsListSuccess,
  ClientsListError,
  ClientDelete,
  ClientDeleteSuccess,
  ClientDeleteError,
  ClientUpdate,
  ClientUpdateSuccess,
  ClientUpdateError,
  ClientAdd,
  ClientAddSuccess,
  ClientAddError,
  ClientsListAll,
  ClientsListAllSuccess,
  ClientsListAllError, ClientGet, ClientGetSuccess, ClientGetError
} from '@app/stores/client/client.actions';
import {IClientState} from '@app/stores/client/client.state';
import {ClientService} from '@app/services/client.service';

@Injectable()
export class ClientEffects {
  constructor(
    private _actions$: Actions,
    private _store: Store<IClientState>,
    private clientService: ClientService,
    private loaderService: LoaderService
  ) {
  }

  clientsAllList$ = createEffect(() => this._actions$.pipe(
    ofType(ClientsListAll),
    switchMap((action) => {
      this.loaderService.show();
      return this.clientService.getClients(action.params).pipe(
        map((resp: any) => {
          if (resp.success) {
            this.loaderService.hide();
            return ClientsListAllSuccess({clients: resp.data});
          }
          this.loaderService.hide(true);
          return ClientsListAllError({error: this.loaderService.getErrorMessage(resp)});
        }),
        catchError(error => {
          this.loaderService.hide(true);
          return of(ClientsListAllError({error: this.loaderService.getErrorMessage(error)}));
        })
      );
    })
  ));

  clientsList$ = createEffect(() => this._actions$.pipe(
    ofType(ClientsList),
    switchMap((action) => {
      this.loaderService.show();
      return this.clientService.getClients(action.params).pipe(
        map((resp: any) => {
          if (resp.success) {
            this.loaderService.hide();
            return ClientsListSuccess({paginatedClients: resp.data});
          }
          this.loaderService.hide(true);
          return ClientsListError({error: this.loaderService.getErrorMessage(resp)});
        }),
        catchError(error => {
          this.loaderService.hide(true);
          return of(ClientsListError({error: this.loaderService.getErrorMessage(error)}));
        })
      );
    })
  ));

  clientDelete$ = createEffect(() => this._actions$.pipe(
    ofType(ClientDelete),
    switchMap((action) => {
      this.loaderService.show();
      return this.clientService.deleteClient(action.clientId).pipe(
        map((resp: any) => {
          if (resp.success) {
            this.loaderService.hide();
            return ClientDeleteSuccess();
          }
          this.loaderService.hide(true);
          return ClientDeleteError({error: this.loaderService.getErrorMessage(resp)});
        }),
        catchError(error => {
          this.loaderService.hide(true);
          return of(ClientDeleteError({error: this.loaderService.getErrorMessage(error)}));
        })
      );
    })
  ));

  clientUpdate$ = createEffect(() => this._actions$.pipe(
    ofType(ClientUpdate),
    switchMap((action) => {
      this.loaderService.show();
      return this.clientService.updateClient(action.clientId, action.client).pipe(
        map((resp: any) => {
          if (resp.success) {
            this.loaderService.hide();
            return ClientUpdateSuccess();
          }
          this.loaderService.hide(true);
          return ClientUpdateError({error: this.loaderService.getErrorMessage(resp)});
        }),
        catchError(error => {
          this.loaderService.hide(true);
          return of(ClientUpdateError({error: this.loaderService.getErrorMessage(error)}));
        })
      );
    })
  ));

  clientAdd$ = createEffect(() => this._actions$.pipe(
    ofType(ClientAdd),
    switchMap((action) => {
      this.loaderService.show();
      return this.clientService.addClient(action.client).pipe(
        map((resp: any) => {
          if (resp.success) {
            this.loaderService.hide();
            return ClientAddSuccess();
          }
          this.loaderService.hide(true);
          return ClientAddError({error: this.loaderService.getErrorMessage(resp)});
        }),
        catchError(error => {
          this.loaderService.hide(true);
          return of(ClientAddError({error: this.loaderService.getErrorMessage(error)}));
        })
      );
    })
  ));

  clientGet$ = createEffect(() => this._actions$.pipe(
    ofType(ClientGet),
    switchMap((action) => {
      this.loaderService.show();
      return this.clientService.getClient(action.clientId).pipe(
        map((resp: any) => {
          if (resp.success) {
            this.loaderService.hide();
            return ClientGetSuccess({client: resp.data});
          }
          this.loaderService.hide(true);
          return ClientGetError({error: this.loaderService.getErrorMessage(resp)});
        }),
        catchError(error => {
          this.loaderService.hide(true);
          return of(ClientGetError({error: this.loaderService.getErrorMessage(error)}));
        })
      );
    })
  ));
}
