import {createFeatureSelector, createSelector} from '@ngrx/store';
import {IReportState} from '@app/stores';

const selectReport = createFeatureSelector<IReportState>('report');

export const getReport = createSelector(selectReport, (state: IReportState) => state.report);

export const getReportPassword = createSelector(selectReport, (state: IReportState) => state.reportPassword);

export const getReportSuccess = createSelector(selectReport, (state: IReportState) => state.success);

export const getReportError = createSelector(selectReport, (state: IReportState) => state.error);
