import {Injectable} from '@angular/core';
import {Actions, createEffect, ofType} from '@ngrx/effects';
import {Store} from '@ngrx/store';
import {catchError, map, switchMap} from 'rxjs/operators';
import {of} from 'rxjs';
import {LoaderService} from '@app/services/loader.service';
import {
  PackagesListAll,
  PackagesListAllSuccess,
  PackagesListAllError,
  PackagesListPaginated,
  PackagesListPaginatedSuccess,
  PackagesListPaginatedError,
  PackagesAdd,
  PackagesAddError,
  PackagesAddSuccess,
  PackagesDelete, PackagesDeleteError,
  PackagesDeleteSuccess,
  PackagesUpdate,
  PackagesUpdateError,
  PackagesUpdateSuccess,
  PackagesGet,
  PackagesGetError,
  PackagesGetSuccess
} from '@app/stores/package/package.actions';
import {IPackageState} from '@app/stores/package/package.state';
import {PackageService} from '@app/services/package.service';

@Injectable()
export class PackageEffect {
  constructor(
    private _actions$: Actions,
    private _store: Store<IPackageState>,
    private packageService: PackageService,
    private loaderService: LoaderService
  ) {
  }

  packagesAllList$ = createEffect(() => this._actions$.pipe(
    ofType(PackagesListAll),
    switchMap((action) => {
      this.loaderService.show();
      return this.packageService.getPackages(action.params).pipe(
        map((resp: any) => {
          if (resp.success) {
            this.loaderService.hide();
            return PackagesListAllSuccess({packages: resp.data});
          }
          this.loaderService.hide(true);
          return PackagesListAllError({error: this.loaderService.getErrorMessage(resp)});
        }),
        catchError(error => {
          this.loaderService.hide(true);
          return of(PackagesListAllError({error: this.loaderService.getErrorMessage(error)}));
        })
      );
    })
  ));

  packageListingPaginated$ = createEffect(() => this._actions$.pipe(
    ofType(PackagesListPaginated),
    switchMap((action) => {
      this.loaderService.show();
      return this.packageService.getPackages(action.params).pipe(
        map((resp: any) => {
          if (resp.success) {
            this.loaderService.hide();
            return PackagesListPaginatedSuccess({paginatedPackages: resp.data});
          }
          this.loaderService.hide(true);
          return PackagesListPaginatedError({error: this.loaderService.getErrorMessage(resp)});
        }),
        catchError(error => {
          this.loaderService.hide(true);
          return of(PackagesListPaginatedError({error: this.loaderService.getErrorMessage(error)}));
        })
      );
    })
  ));

  packageAdd$ = createEffect(() => this._actions$.pipe(
    ofType(PackagesAdd),
    switchMap((action) => {
      this.loaderService.show();
      return this.packageService.create(action.body).pipe(
        map((resp: any) => {
          if (resp.success) {
            this.loaderService.hide();
            return PackagesAddSuccess();
          }
          this.loaderService.hide(true);
          return PackagesAddError({error: this.loaderService.getErrorMessage(resp)});
        }),
        catchError(error => {
          this.loaderService.hide(true);
          return of(PackagesAddError({error: this.loaderService.getErrorMessage(error)}));
        })
      );
    })
  ));

  packageUpdate$ = createEffect(() => this._actions$.pipe(
    ofType(PackagesUpdate),
    switchMap((action) => {
      this.loaderService.show();
      return this.packageService.update(action.body, action.packageId).pipe(
        map((resp: any) => {
          if (resp.success) {
            this.loaderService.hide();
            return PackagesUpdateSuccess({updatePackage: resp.data});
          }
          this.loaderService.hide(true);
          return PackagesUpdateError({error: this.loaderService.getErrorMessage(resp)});
        }),
        catchError(error => {
          this.loaderService.hide(true);
          return of(PackagesUpdateError({error: this.loaderService.getErrorMessage(error)}));
        })
      );
    })
  ));

  packageDelete$ = createEffect(() => this._actions$.pipe(
    ofType(PackagesDelete),
    switchMap((action) => {
      this.loaderService.show();
      return this.packageService.deletePackage(action.packageId).pipe(
        map((resp: any) => {
          if (resp.success) {
            this.loaderService.hide();
            return PackagesDeleteSuccess();
          }
          this.loaderService.hide(true);
          return PackagesDeleteError({error: this.loaderService.getErrorMessage(resp)});
        }),
        catchError(error => {
          this.loaderService.hide(true);
          return of(PackagesDeleteError({error: this.loaderService.getErrorMessage(error)}));
        })
      );
    })
  ));

  packageGet$ = createEffect(() => this._actions$.pipe(
    ofType(PackagesGet),
    switchMap((action) => {
      this.loaderService.show();
      return this.packageService.getById(action.packageId).pipe(
        map((resp: any) => {
          if (resp.success) {
            this.loaderService.hide();
            return PackagesGetSuccess({pkg: resp.data});
          }
          this.loaderService.hide(true);
          return PackagesGetError({error: this.loaderService.getErrorMessage(resp)});
        }),
        catchError(error => {
          this.loaderService.hide(true);
          return of(PackagesGetError({error: this.loaderService.getErrorMessage(error)}));
        })
      );
    })
  ));
}
