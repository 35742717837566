import {createAction, props} from '@ngrx/store';
import {TrackingTypeModel} from '@app/modules/shared/models';

enum ETrackingTypeActions {
  TrackingType = '[TrackingType] Tracking Type',
  TrackingTypeSuccess = '[TrackingType] Tracking Type Success',
  TrackingTypeError = '[TrackingType] Tracking Type Error',

  ResetTrackingTypeState = '[TrackingType] Tracking Type State',
}

export const TrackingType = createAction(ETrackingTypeActions.TrackingType, props<{ projectId: number }>());
export const TrackingTypeSuccess = createAction(ETrackingTypeActions.TrackingTypeSuccess, props<{ trackingType: TrackingTypeModel[] }>());
export const TrackingTypeError = createAction(ETrackingTypeActions.TrackingTypeError, props<{ error: string }>());

export const ResetTrackingTypeState = createAction(ETrackingTypeActions.ResetTrackingTypeState, (params: any = {}) => params);

