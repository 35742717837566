<div class="modal-header">
  <h4 class="modal-title" id="modal-title">Copy project</h4>
  <button type="button" class="close" aria-label="Close button" aria-describedby="modal-title"
          (click)="modal.dismiss('cancel')">
    <span aria-hidden="true">&times;</span>
  </button>
</div>
<div class="modal-body">
  <form [formGroup]="projectForm">
    <div class="row">
      <div class="col-md-12 mb-2">
        <div class="floating-label">
          <input placeholder=" "
                 [ngClass]="{ 'is-invalid': submitted && f.title.errors }"
                 class="floating-input" type="text" formControlName="title">
          <span class="highlight"></span>
          <label>Project title <span class="red">*</span></label>
          <div class="bar error-brd" *ngIf="submitted && f.title.errors">
            <div class="invalid-text" *ngIf="f.title.errors.required">Project title is required</div>
          </div>
        </div>
      </div>
      <div class="col-md-6" *ngIf="isAdmin">
        <div class="floating-label">
          <app-auto-complete-select
            [hideLabel]="true"
            [isCreatable]="false"
            [onlyBottomBorder]="false"
            [placeholder]="'Select Client'"
            [selectOptions]="clients"
            (searchValue)="handleSearchValue($event)"
            (optionSelected)="handleClientSelection($event)"
            (scrollToEnd)="handleScroll()"
          ></app-auto-complete-select>
          <span class="highlight"></span>
          <div class="bar error-brd" *ngIf="submitted && f.client_id.errors">
            <div class="invalid-text" *ngIf="f.client_id.errors.required">Client is required</div>
          </div>
        </div>
      </div>
      <div class="col-md-6 customize-email d-flex align-items-center">
        <input id="with_influencers" placeholder="" type="checkbox" formControlName="with_influencers">
        <label for="with_influencers">With influencers</label>
      </div>
    </div>
  </form>
</div>
<input type="hidden" ngbAutofocus>
<div class="modal-footer">
  <button type="button" class="btn btn-outline-secondary" (click)="modal.dismiss('cancel')">Cancel</button>
  <button type="button" class="btn btn-primary" (click)="saveProject()">Save</button>
</div>
