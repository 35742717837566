import {Injectable} from '@angular/core';
import {Actions, createEffect, ofType} from '@ngrx/effects';
import {Store} from '@ngrx/store';
import {catchError, map, switchMap} from 'rxjs/operators';
import {of} from 'rxjs';
import {PersonalAccessTokenService} from '@app/services/personal-access-token.service';
import {
  PersonalAccessTokenList,
  PersonalAccessTokenListSuccess,
  PersonalAccessTokenDelete,
  PersonalAccessTokenDeleteSuccess,
  PersonalAccessTokenDeleteError,
  PersonalAccessTokenListError,
  PersonalAccessTokenUpdate,
  PersonalAccessTokenUpdateSuccess,
  PersonalAccessTokenUpdateError,
  PersonalAccessTokenAdd,
  PersonalAccessTokenAddError,
  PersonalAccessTokenAddSuccess,
  PersonalAccessTokenListPaginated,
  PersonalAccessTokenListPaginatedSuccess, PersonalAccessTokenListPaginatedError
} from '@app/stores/personal-access-token/personal-access-token.actions';
import {IPersonalAccessTokenState} from '@app/stores/personal-access-token/personal-access-token.state';
import {SegmentService} from '@app/services/segment.service';
import {LoaderService} from "@app/services/loader.service";
import {
  PackagesAdd,
  PackagesAddError,
  PackagesAddSuccess,
  PackagesListPaginated, PackagesListPaginatedError,
  PackagesListPaginatedSuccess
} from "@app/stores";

@Injectable()
export class PersonalAccessTokenEffects {
  constructor(
    private _actions$: Actions,
    private _store: Store<IPersonalAccessTokenState>,
    private segmentService: SegmentService,
    private personalAccessTokenService: PersonalAccessTokenService,
    private loaderService: LoaderService
  ) {
  }

  personalAccessTokenAdd$ = createEffect(() => this._actions$.pipe(
    ofType(PersonalAccessTokenAdd),
    switchMap((action) => {
      this.loaderService.show();
      return this.personalAccessTokenService.create(action.body).pipe(
        map((resp: any) => {
          if (resp.success) {
            this.loaderService.hide();
            return PersonalAccessTokenAddSuccess();
          }
          this.loaderService.hide(true);
          return PersonalAccessTokenAddError({error: this.loaderService.getErrorMessage(resp)});
        }),
        catchError(error => {
          this.loaderService.hide(true);
          return of(PersonalAccessTokenAddError({error: this.loaderService.getErrorMessage(error)}));
        })
      );
    })
  ));

  personalAccessTokenList$ = createEffect(() => this._actions$.pipe(
    ofType(PersonalAccessTokenList),
    switchMap((action) => {
      this.loaderService.show();
      return this.personalAccessTokenService.getById(action.id).pipe(
        map((resp: any) => {
          if (resp.success) {
            this.loaderService.hide();
            return PersonalAccessTokenListSuccess({token: resp.data});
          }
          this.loaderService.hide(true);
          return PersonalAccessTokenListError({error: this.loaderService.getErrorMessage(resp)});
        }),
        catchError(error => {
          this.loaderService.hide(true);
          return of(PersonalAccessTokenListError({error: this.loaderService.getErrorMessage(error)}));
        })
      );
    })
  ));

  personalAccessTokenDelete$ = createEffect(() => this._actions$.pipe(
    ofType(PersonalAccessTokenDelete),
    switchMap((action) => {
      this.loaderService.show();
      return this.personalAccessTokenService.delete(action.tokenId).pipe(
        map((resp: any) => {
          if (resp.success) {
            this.loaderService.hide();
            return PersonalAccessTokenDeleteSuccess();
          }
          this.loaderService.hide(true);
          return PersonalAccessTokenDeleteError({error: this.loaderService.getErrorMessage(resp)});
        }),
        catchError(error => {
          this.loaderService.hide(true);
          return of(PersonalAccessTokenDeleteError({error: this.loaderService.getErrorMessage(error)}));
        })
      );
    })
  ));

  personalAccessTokenUpdate$ = createEffect(() => this._actions$.pipe(
    ofType(PersonalAccessTokenUpdate),
    switchMap((action) => {
      this.loaderService.show();
      return this.personalAccessTokenService.update(action.body, action.tokenId).pipe(
        map((resp: any) => {
          if (resp.success) {
            this.loaderService.hide();
            return PersonalAccessTokenUpdateSuccess({token: resp.data});
          }
          this.loaderService.hide(true);
          return PersonalAccessTokenUpdateError({error: this.loaderService.getErrorMessage(resp)});
        }),
        catchError(error => {
          this.loaderService.hide(true);
          return of(PersonalAccessTokenUpdateError({error: this.loaderService.getErrorMessage(error)}));
        })
      );
    })
  ));

  packageListingPaginated$ = createEffect(() => this._actions$.pipe(
    ofType(PersonalAccessTokenListPaginated),
    switchMap((action) => {
      this.loaderService.show();
      return this.personalAccessTokenService.getPersonalToken(action.params).pipe(
        map((resp: any) => {
          if (resp.success) {
            this.loaderService.hide();
            return PersonalAccessTokenListPaginatedSuccess({paginatedPersonalAccessToken: resp.data});
          }
          this.loaderService.hide(true);
          return PersonalAccessTokenListPaginatedError({error: this.loaderService.getErrorMessage(resp)});
        }),
        catchError(error => {
          this.loaderService.hide(true);
          return of(PersonalAccessTokenListPaginatedError({error: this.loaderService.getErrorMessage(error)}));
        })
      );
    })
  ));

}
