import { Injectable }                  from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable ({providedIn: 'root'})
export class GlobalprojectService {
    public templateName: Observable<string>;
    public backgroungUrl: Observable<string>;
    public templateId: Observable<number>;
    public projectId: Observable<number>;
    public influencerType: Observable<number>;
    private templateNameSubject: BehaviorSubject<string>;
    private backgroungUrlSubject: BehaviorSubject<string>;
    private templateIdSubject: BehaviorSubject<number>;
    private projectIdSubject: BehaviorSubject<number>;
    private influencerTypeSubject: BehaviorSubject<number>;

    constructor() {
        this.templateNameSubject = new BehaviorSubject<string> ('');
        this.templateName = this.templateNameSubject.asObservable ();
        this.backgroungUrlSubject = new BehaviorSubject<string> (null);
        this.backgroungUrl = this.backgroungUrlSubject.asObservable ();
        this.templateIdSubject = new BehaviorSubject<number> (null);
        this.templateId = this.templateIdSubject.asObservable ();
        this.projectIdSubject = new BehaviorSubject<number> (null);
        this.projectId = this.projectIdSubject.asObservable ();
        this.influencerTypeSubject = new BehaviorSubject<number> (null);
        this.influencerType = this.influencerTypeSubject.asObservable ();
    }

    public get currentTemplateName(): string {
        return this.templateNameSubject.value;
    }

    public get currentBackgroungUrl(): string {
        return this.backgroungUrlSubject.value;
    }

    public get currentTemplateId(): number {
        return this.templateIdSubject.value;
    }

    public get currentProjectId(): number {
        return this.projectIdSubject.value;
    }

    public get currentInfluencerType(): number {
        return this.influencerTypeSubject.value;
    }

    updateTemplateName(value: string = '') {
        this.templateNameSubject.next (value);
    }

    updateBackgroungUrl(value: string) {
        this.backgroungUrlSubject.next (value);
    }

    updateTemplateId(value: number = null) {
        this.templateIdSubject.next (value);
    }

    updateProjectId(value: number = null) {
        this.projectIdSubject.next (value);
    }

    updateInfluencerType(value: number = null) {
        this.influencerTypeSubject.next (value);
    }

}
