import {Action, createReducer, on} from '@ngrx/store';
import {
  SegmentsList, SegmentsListError, SegmentsListSuccess,
  SegmentsUpdate, SegmentsUpdateSuccess, SegmentsUpdateError,
  SegmentsDelete, SegmentsDeleteSuccess, SegmentsDeleteError,
  SegmentsAdd, SegmentsAddSuccess, SegmentsAddError, ResetSegmentState,
  SegmentsListPaginated, SegmentsListPaginatedError, SegmentsListPaginatedSuccess
} from '@app/stores/segments/segments.actions';
import {initialSegmentState} from '@app/stores/segments/segments.state';

const generateReducer = createReducer(initialSegmentState,
  on(SegmentsList, (state) => ({
    ...state,
    segment: null,
    segments: [],
    success: '',
    error: ''
  })),
  on(SegmentsListSuccess, (state, {segments}) => ({
    ...state,
    segment: null,
    segments,
    success: '',
    error: ''
  })),
  on(SegmentsListError, (state, {error}) => ({
    ...state,
    segment: null,
    segments: [],
    success: '',
    error
  })),
  on(SegmentsListPaginated, (state) => ({
    ...state,
    paginatedSegments: null,
    success: '',
    error: ''
  })),
  on(SegmentsListPaginatedSuccess, (state, {paginatedSegments}) => ({
    ...state,
    paginatedSegments,
    success: '',
    error: ''
  })),
  on(SegmentsListPaginatedError, (state, {error}) => ({
    ...state,
    paginatedSegments: null,
    success: '',
    error
  })),
  on(SegmentsUpdate, (state) => ({
    ...state,
    success: '',
    error: ''
  })),
  on(SegmentsUpdateSuccess, (state) => ({
    ...state,
    success: 'Segment updated successfully',
    error: ''
  })),
  on(SegmentsUpdateError, (state, {error}) => ({
    ...state,
    success: '',
    error
  })),
  on(SegmentsDelete, (state) => ({
    ...state,
    success: '',
    error: ''
  })),
  on(SegmentsDeleteSuccess, (state) => ({
    ...state,
    success: 'Segment deleted successfully',
    error: ''
  })),
  on(SegmentsDeleteError, (state, {error}) => ({
    ...state,
    success: '',
    error
  })),
  on(SegmentsAdd, (state) => ({
    ...state,
    success: '',
    error: ''
  })),
  on(SegmentsAddSuccess, (state, {segment}) => ({
    ...state,
    segment,
    success: 'Segment added successfully',
    error: ''
  })),
  on(SegmentsAddError, (state, {error}) => ({
    ...state,
    success: '',
    error
  })),

  on(ResetSegmentState, (state, {params}) => ({
    ...state,
    ...params
  })),
);

export function segmentReducer(state = initialSegmentState, action: Action) {
  return generateReducer(state, action);
}
