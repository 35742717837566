<div class="container-fluid bg-white">
    <div class="row">
        <!-- LogIn Left Section  Start -->
        <div class="col-lg-5 col-md-12 d-flex justify-content-center">
            <div class="align-self-center w-100-small-device">
                <div class="logo text-center mt-2">
                    <img src="../../../assets/images/blue-logo.png"/>
                </div>
                <div class="login-heading">
                    <h1>Start a snöball
                    </h1>
                </div>
                <div class="col-12 tittle text-center line-h-120" style="display: none">
                    <span class="d-block"> Need a snöball account?</span>
                    <a routerLink="/register">Create an account</a>
                </div>
                <!-- Forgot Password form column start -->
                <div *ngIf="submitted && !passMessage['success']" class="error-msg-fail">
                    <p> {{passMessage[ 'msg' ]}} </p>
                </div>
                <div *ngIf="submitted && passMessage['success']" class="success-msg-success">
                    <p> {{passMessage[ 'msg' ]}} </p>
                </div>
                <form (ngSubmit)="onSubmit()" [formGroup]="passwordForm">

                    <div class="card custom-card">


                        <h4 class="mt-2 mb-3">Forgot Password</h4>

                        <div class="floating-label">
                            <input [ngClass]="{ 'is-invalid': submitted && f.email.errors }" class="floating-input" formControlName="email" placeholder=" "
                                   required="required"
                                   type="text"/>
                            <span class="highlight"></span>
                            <label>{{ 'Loginpage.email' | translate }} <span class="red">*</span></label>

                            <div *ngIf="submitted && f.email.errors" class="bar error-brd">
                                <div *ngIf="f.email.errors.required"
                                     class="invalid-text">{{ 'Loginpage.email_require' | translate }}</div>
                                <div *ngIf="f.email.errors.email"
                                     class="invalid-text">{{ 'Loginpage.valid_email' | translate }}</div>
                            </div>
                        </div>

                    </div>

                    <!-- Forgot Password form column end -->

                    <div class="m-auto text-center">
                        <button class="btn btn-primary blue-color login-btn mt-5 mb-2" type="submit">Send
                        </button>
                    </div>
                </form>
            </div>

        </div>
        <!-- LogIn Left Section End -->
        <!-- LogIn right Section  Start -->
        <div class="col-lg-7 col-md-12 blue-bg login-right-container d-flex justify-content-center">
            <div class="align-self-center  w-100-small-device">
                <div class="col-md-12">
                    <h1 class="right-panel-heading m-B-64">92% Trust
                        <br/> Word-of-Mouth
                    </h1>
                </div>
                <div class="col-md-12">
                    <p class="sub-info">
                        Your speakers, exhibitors,
                        <br/> and attendees can help
                        <br/> promote your event.
                    </p>
                </div>
                <div class="small-divice-text-center m-T116 m-B116">
                  <a class="btn outline-white-brd-btn" type="submit" href="https://snoball.events/book-demo3/" target="_blank" style="text-decoration: none;">Tell me more</a>
                </div>
            </div>
        </div>
        <!-- LogIn right Section  End -->
    </div>
</div>
