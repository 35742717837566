<div class="container-fluid pt-3">
  <div class="rightBody">
    <!-- Add User Section  Start -->
    <div class="card">
      <div class="card-header py-3">
        <div class="card-header--title font-size-lg">
          {{userId ? 'Update' : 'Add'}} user
        </div>
      </div>
      <div class="card-body">
        <div class="form-wraper">
          <form (ngSubmit)="addUser()" [formGroup]="addUserForm" autocomplete="off">
            <!-- Add User form column start -->
            <div class="row">
              <div class="col-lg-6 col-md-6 col-ms-12">
                <div class="floating-label">
                  <input [ngClass]="{ 'is-invalid': submitted && f.first_name.errors }"
                         formControlName="first_name" placeholder=" "
                         required="required" class="floating-input"
                         type="text" autocomplete="off"/>
                  <label>Fist Name <span class="red">*</span>
                  </label>
                  <div *ngIf="submitted && f.first_name.errors" class="bar error-brd">
                    <div *ngIf="f.first_name.errors.required" class="invalid-text">First Name is required</div>
                  </div>

                </div>
              </div>
              <div class="col-lg-6 col-md-6 col-ms-12">
                <div class="floating-label">
                  <input [ngClass]=" { 'is-invalid': submitted && f.last_name.errors }"
                         formControlName="last_name" placeholder=" "
                         required="required" class="floating-input"
                         type="text" autocomplete="off"/>
                  <label>Last Name <span class="red">*</span>
                  </label>
                  <div *ngIf="submitted && f.last_name.errors" class="bar error-brd">
                    <div *ngIf="f.last_name.errors.required" class="invalid-text">Last Name is required</div>
                  </div>

                </div>
              </div>
              <div class="col-lg-6 col-md-6 col-ms-12" style="margin-top: 10px" *ngIf="showRoles">
                <div class="floating-label">
                  <app-creatable-select [hideLabel]="true" [isCreatable]="false" [selectedValue]="f.role.value || null"
                                        [placeholder]="'Select Role'" [selectOptions]="roles"
                                        (optionSelected)="roleSelected($event)"></app-creatable-select>
                </div>
                <div *ngIf="submitted && f.role.errors" class="bar error-brd custom-err">
                  <div *ngIf="f.role.errors.required" class="invalid-text">Role is required</div>
                </div>
              </div>
              <div class="col-lg-6 col-md-6 col-ms-12" style="margin-top: 10px" *ngIf="!isAdmin">
                <div class="floating-label">
                  <app-auto-complete-select
                    [hideLabel]="true"
                    [isCreatable]="false"
                    [onlyBottomBorder]="false"
                    [placeholder]="'Select Client'"
                    [selectOptions]="clients"
                    [isDisabled]="!isAdmin && isManager && (!isAdminUSer || user?.client_id)"
                    [selectedValue]="f.client_id.value || null"
                    (optionSelected)="clientSelected($event)"
                    (searchValue)="handleSearchValue($event)"
                    (scrollToEnd)="handleScroll()"
                  ></app-auto-complete-select>
                </div>
                <div *ngIf="submitted && f.client_id.errors" class="bar error-brd custom-err">
                  <div *ngIf="f.client_id.errors.required" class="invalid-text">Client is required</div>
                </div>
              </div>
              <div class="col-lg-6 col-md-6 col-ms-12" style="margin-top: 10px" *ngIf="!this.isAdmin">
                <div class="floating-label">
                  <app-creatable-select [hideLabel]="true" [isCreatable]="false" [labelKey]="'title'" [selectedValue]="f.project_id.value || null"
                                        [placeholder]="'Project'" [selectOptions]="projects" [isMultiple]="true"
                                        (optionSelected)="projectSelect($event)"></app-creatable-select>
                  <div *ngIf="submitted && f.project_id.errors" class="bar error-brd">
                    <div *ngIf="f.project_id.errors.required" class="invalid-text">Project is required</div>
                  </div>
                </div>
              </div>
              <div class="col-lg-6 col-md-6 col-ms-12">
                <div class="floating-label">
                  <input [ngClass]="{ 'is-invalid': submitted && f.email.errors }"
                         formControlName="email" class="floating-input"
                         required="required" placeholder=" "
                         type="text" autocomplete="off"/>
                  <label>Email <span class="red">*</span>
                  </label>
                  <div *ngIf="submitted && f.email.errors" class="bar error-brd">
                    <div *ngIf="f.email.errors.required" class="invalid-text">Email is required</div>
                    <div *ngIf="f.email.errors.email" class="invalid-text">Username must be a valid email address</div>
                  </div>
                </div>
              </div>
              <div class="col-lg-6 col-md-6 col-ms-12">
                <div class="floating-label">
                  <input [ngClass]="{ 'is-invalid': submitted && f.job_title.errors }"
                         formControlName="job_title"
                         required="required" class="floating-input" placeholder=" "
                         type="text" autocomplete="off"/>
                  <label>Job Title <span class="red">*</span>
                  </label>
                  <div class="bar"></div>
                  <div *ngIf="submitted && f.job_title.errors" class="bar error-brd">
                    <div *ngIf="f.job_title.errors.required" class="invalid-text">Job Title is required</div>
                  </div>

                </div>
              </div>
              <div class="col-lg-6 col-md-6 col-ms-12">
                <div class="floating-label">
                  <input formControlName="company" required="required" type="text" class="floating-input"
                         placeholder=" " autocomplete="off"/>
                  <label>Company <span class="red">*</span></label>
                  <div *ngIf="submitted && f.company.errors" class="bar error-brd">
                    <div *ngIf="f.company.errors.required" class="invalid-text">company is required</div>
                  </div>
                </div>
              </div>
              <div class="col-lg-6 col-md-6 col-ms-12">
                <div class="floating-label">
                  <input [ngClass]="{ 'is-invalid': submitted && f.website.errors }"
                         class="floating-input" formControlName="website" placeholder=" "
                         type="text"/>
                  <label>Website
                  </label>
                  <div *ngIf="submitted && f.website.errors" class="bar error-brd">
                    <div *ngIf="f.website.errors" class="invalid-text">Enter a valid url</div>
                  </div>
                </div>
              </div>
              <div class="col-lg-6 col-md-6 col-ms-12">
                <div class="floating-label">
                  <input [ngClass]="{ 'is-invalid': submitted && f.phone.errors }"
                         class="floating-input" formControlName="phone"
                         placeholder=" " type="text" autocomplete="off"/>
                  <label>Phone
                  </label>
                  <div *ngIf="submitted && f.phone.errors" class="bar error-brd">
                    <div *ngIf="f.phone.errors" class="invalid-text">Invalid phone number</div>
                  </div>
                </div>
              </div>
              <div class="col-lg-6 col-md-6 col-ms-12" style="margin-top: 10px">
                <div class="floating-label">
                  <ng-moment-timezone-picker class="dropdown-panel-timezone" [formControlName]="'timezone'" [customPlaceholderText]='"Time Zone"' [clearable]='true'></ng-moment-timezone-picker>
                  <div *ngIf="submitted && f.timezone.errors" class="bar error-brd">
                    <div *ngIf="f.timezone.errors.required" class="invalid-text">Timezone is required</div>
                  </div>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-lg-6 col-md-6 col-ms-12">
                <div class="floating-label input-group input-group-sm variable-input">
                  <input [ngClass]=" { 'is-invalid': submitted && f.password.errors }"
                         formControlName="password" placeholder=" "
                         class="floating-input" (change)="setValidation()"
                         [type]="showPasswords.password ? 'text' : 'password'" autocomplete="new-paassword"/>
                  <label>Password</label>
                  <div *ngIf="submitted && f.password.errors" class="bar error-brd confirm-password-error">
                    <div *ngIf="f.password.errors?.required" class="invalid-text">Password is required</div>
                    <div *ngIf="f.password.errors?.passwordRegex" class="invalid-text">Invalid Password</div>
                  </div>
                  <div class="inputs">
                    <button type="button" class="btn btn-default btn-sm ml-1" (click)="showPasswords.password = !showPasswords.password">
                      <i class="fa" [ngClass]="{'fa-eye': !showPasswords.password, 'fa-eye-slash': showPasswords.password}"></i>
                    </button>
                  </div>
                </div>
                <div class="mt-4" *ngIf="f.password.value && f.password.value.trim()">
                  <mat-password-strength #passwordComponent (onStrengthChanged)="updatePasswordStrength($event)"
                                         [password]="f.password.value">
                  </mat-password-strength>
                  <mat-password-strength-info
                    [passwordComponent]="passwordComponent">
                  </mat-password-strength-info>
                </div>
              </div>
              <div class="col-lg-6 col-md-6 col-ms-12">
                <div class="floating-label input-group input-group-sm variable-input">
                  <input [ngClass]=" { 'is-invalid': submitted && f.confirm_password.errors }"
                         formControlName="confirm_password" placeholder=" "
                         class="floating-input"
                         [type]="showPasswords.confirm_password ? 'text' : 'password'" autocomplete="new-password"/>
                  <label>Confirm password</label>
                  <div *ngIf="submitted && f.confirm_password.errors" class="bar error-brd confirm-password-error">
                    <div *ngIf="f.confirm_password.errors.required" class="invalid-text">Confirm password is required</div>
                    <div *ngIf="f.confirm_password.errors.confirmPasswordValidator" class="invalid-text">Password does not match</div>
                  </div>
                  <div class="inputs">
                    <button type="button" class="btn btn-default btn-sm ml-1" (click)="showPasswords.confirm_password = !showPasswords.confirm_password">
                      <i class="fa" [ngClass]="{'fa-eye': !showPasswords.confirm_password, 'fa-eye-slash': showPasswords.confirm_password}"></i>
                    </button>
                  </div>
                </div>
              </div>
            </div>

            <!-- <div class="row">
                <div class="col-lg-6 col-md-6 col-ms-12">
                    <div class="floating-label">
                      <select class="floating-select" formControlName="role" onclick="this.setAttribute('value', this.value);" value="">
                          <option  [value]="role.id" *ngFor="let role of roles">{{role.name}}</option>
                      </select>
                      <span class="highlight"></span>
                      <label>Role <span class="red">*</span></label>
                    </div>
                    <div class="bar error-brd" *ngIf="submitted && f.role.errors">
                        <div class="invalid-text" *ngIf="f.role.errors.required">Role is required</div>
                    </div>
                  </div>
            </div> -->
            <!--
              <div class="card floating-label card-border">
                  <div class="row">
                      <div class="card-body">
                          <div class="row">
                              <div class="col-md-6">
                                  <div class="floating-label">
                                      <input (change)="oneononeFeature = !oneononeFeature"
                                             [checked]="oneononeFeature"
                                             formControlName="subscription_oneonone"
                                             type="checkbox"/>&nbsp;Subscription
                                      for One On One Meeting Feature
                                  </div>
                              </div>
                              <div class="col-md-6">
                                  <div class="floating-label">
                                      <input (change)="videoFeature = !videoFeature" [checked]="videoFeature"
                                             formControlName="subscription_video"
                                             type="checkbox"/>&nbsp;Subscriptiopn for
                                      Video Feature
                                  </div>
                              </div>
                          </div>
                      </div>
                  </div>
              </div>
              -->

            <!-- login form column end -->

            <div _ngcontent-c4="" class="text-right mt-3">
              <button _ngcontent-c4="" class="btn btn-default mr-2" (click)="cancelClick()">Cancel</button>
              <button _ngcontent-c4="" class="btn btn-primary mr-2" type="submit">Save</button>
            </div>

          </form>
        </div>
      </div>
    </div>
    <!-- Add User Section End -->
  </div>
</div>
