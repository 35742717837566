import {initialAuthenticationState} from '@app/stores/authentication/authentication.state';
import {
  LoginError,
  LoginSuccess,
  LoginUser,
  LogoutUser,
  ForgetPassword,
  ForgetPasswordSuccess,
  ForgetPasswordError,
  ResetPassword,
  ResetPasswordSuccess,
  ResetPasswordError,
  Register,
  RegisterSuccess,
  RegisterError,
  ResetAuthState,
  LoginUserWithIdSuccess, LoginUserWithIdError
} from '@app/stores/authentication/authentication.actions';
import {Action, createReducer, on} from '@ngrx/store';

const generateReducer = createReducer(initialAuthenticationState,
  on(LoginUser, (state) => ({
    ...state,
    user: null,
    forgetPassword: null,
    resetPassword: null,
    registerUser: null,
    success: '',
    error: ''
  })),
  on(LoginSuccess, (state, {user}) => ({
    ...state,
    user,
    forgetPassword: null,
    resetPassword: null,
    registerUser: null,
    success: 'Login successful',
    error: ''
  })),
  on(LoginError, (state, {error}) => ({
    ...state,
    user: null,
    forgetPassword: null,
    resetPassword: null,
    registerUser: null,
    success: '',
    error
  })),
  on(LogoutUser, (state) => ({
    ...state,
    user: null,
    forgetPassword: null,
    resetPassword: null,
    registerUser: null,
    success: '',
    error: ''
  })),

  on(ForgetPassword, (state) => ({
    ...state,
    user: null,
    forgetPassword: null,
    resetPassword: null,
    registerUser: null,
    success: '',
    error: ''
  })),
  on(ForgetPasswordSuccess, (state, {forgetPassword}) => ({
    ...state, user: null, forgetPassword, resetPassword: null, registerUser: null,
    success: 'Temporary password sent successful', error: ''
  })),
  on(ForgetPasswordError, (state, {error}) => ({
    ...state,
    user: null,
    forgetPassword: null,
    resetPassword: null,
    registerUser: null,
    success: '',
    error
  })),

  on(ResetPassword, (state) => ({
    ...state,
    user: null,
    forgetPassword: null,
    resetPassword: null,
    registerUser: null,
    success: '',
    error: ''
  })),
  on(ResetPasswordSuccess, (state, {resetPassword}) => ({
    ...state, user: null, forgetPassword: null, registerUser: null, resetPassword,
    success: 'Password reset successful', error: ''
  })),
  on(ResetPasswordError, (state, {error}) => ({
    ...state,
    user: null,
    forgetPassword: null,
    registerUser: null,
    resetPassword: null,
    success: '',
    error
  })),

  on(Register, (state) => ({
    ...state,
    user: null,
    forgetPassword: null,
    resetPassword: null,
    registerUser: null,
    success: '',
    error: ''
  })),
  on(RegisterSuccess, (state, {register}) => ({
    ...state, user: null, forgetPassword: null, registerUser: register, resetPassword: null,
    success: 'Thank you, We will get back to you soon.', error: ''
  })),
  on(RegisterError, (state, {error}) => ({
    ...state,
    user: null,
    forgetPassword: null,
    registerUser: null,
    resetPassword: null,
    success: '',
    error
  })),
  on(LoginUserWithIdSuccess, (state, {user}) => ({
    ...state,
    user,
    forgetPassword: null,
    resetPassword: null,
    registerUser: null,
    success: 'LOGIN_WITH_ID_SUCCESSFUL',
    error: ''
  })),
  on(LoginUserWithIdError, (state, {error}) => ({
    ...state,
    user: null,
    forgetPassword: null,
    resetPassword: null,
    registerUser: null,
    success: '',
    error
  })),

  on(ResetAuthState, (state, {params}) => ({
    ...state,
    ...params
  })),
);

export function authenticationReducer(state = initialAuthenticationState, action: Action) {
  return generateReducer(state, action);
}
