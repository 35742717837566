import {createAction, props} from '@ngrx/store';
import {PaginatedTemplate, Template, TemplateType, PreviewTemplate, PaginatedWidgetAvatarTemplate, WidgetAvatarTemplate} from '@app/modules/shared/models';
import {PaginatedTemplateRevisions} from '@app/modules/shared/models/template-revision';

enum ETemplateActions {
  TemplateListAll = '[Template] Template List All',
  TemplateListAllSuccess = '[Template] Template List Success All',
  TemplateListAllError = '[Template] Template List Error All',
  TemplateList = '[Template] Template List',
  TemplateListSuccess = '[Template] Template List Success',
  TemplateListError = '[Template] Template List Error',
  TemplateUpdateStatus = '[Template] Template Update Status',
  TemplateUpdateStatusSuccess = '[Template] Template Update Status Success',
  TemplateUpdateStatusError = '[Template] Template Update Status Error',
  TemplateTouch = '[Template] Template Touch',
  TemplateTouchSuccess = '[Template] Template Touch Success',
  TemplateTouchError = '[Template] Template Touch Error',
  TemplateGet = '[Template] Template Get',
  TemplateGetSuccess = '[Template] Template Get Success',
  TemplateGetError = '[Template] Template Get Error',
  TemplatePreview = '[Template] Template Preview',
  TemplatePreviewSuccess = '[Template] Template Preview Success',
  TemplatePreviewError = '[Template] Template Preview Error',
  TemplateUpdate = '[Template] Template Update',
  TemplateUpdateSuccess = '[Template] Template Update Success',
  TemplateUpdateError = '[Template] Template Update Error',
  TemplateCreate = '[Template] Template Create',
  TemplateCreateSuccess = '[Template] Template Create Success',
  TemplateCreateError = '[Template] Template Create Error',
  TemplateCampaign = '[Template] Template Campaign',
  TemplateCampaignSuccess = '[Template] Template Campaign Success',
  TemplateCampaignError = '[Template] Template Campaign Error',
  TemplatePreviewURL = '[Template] Template Preview URL',
  TemplatePreviewURLSuccess = '[Template] Template Preview URL Success',
  TemplatePreviewURLError = '[Template] Template Preview URL Error',
  TemplateDelete = '[Template] Template Delete',
  TemplateDeleteSuccess = '[Template] Template Delete Success',
  TemplateDeleteError = '[Template] Template Delete Error',
  WidgetIntegrationBody = '[Template] Widget Integration Body',
  WidgetIntegrationBodySuccess = '[Template] Widget Integration Body Success',
  WidgetIntegrationBodyError = '[Template] Widget Integration Body Error',
  WidgetCustomScriptBody = '[Template] Widget Custom Script Body',
  WidgetCustomScriptBodySuccess = '[Template] Widget Custom Script Body Success',
  WidgetCustomScriptBodyError = '[Template] Widget Custom Script Body Error',
  TemplateUpdateThumbnail = '[Template] Template Update Thumbnail',
  TemplateUpdateThumbnailSuccess = '[Template] Template Update Thumbnail Success',
  TemplateUpdateThumbnailError = '[Template] Template Update Thumbnail Error',
  TemplateGetTypes = '[Template] Template Get Types',
  TemplateGetTypesSuccess = '[Template] Template Get Types Success',
  TemplateGetTypesError = '[Template] Template Get Types Error',
  TemplateWidgetPreviewURL = '[Template] Template Widget Preview URL',
  TemplateWidgetPreviewURLSuccess = '[Template] Template Widget Preview URL Success',
  TemplateWidgetPreviewURLError = '[Template] Template Widget Preview URL Error',
  TemplateGetRevisions = '[Template] Template Get Revisions',
  TemplateGetRevisionsSuccess = '[Template] Template Get Revisions Success',
  TemplateGetRevisionsError = '[Template] Template Get Revisions Error',
  WidgetAvatarTemplateListAll = '[Template] Widget Avatar Template List All',
  WidgetAvatarTemplateListAllSuccess = '[Template] Widget Avatar Template List All Success',
  WidgetAvatarTemplateListAllError = '[Template] Widget Avatar Template List All Error',
  WidgetAvatarTemplateList = '[Template] Widget Avatar Template List',
  WidgetAvatarTemplateListSuccess = '[Template] Widget Avatar Template List Success',
  WidgetAvatarTemplateListError = '[Template] Widget Avatar Template List Error',
  WidgetAvatarTemplateCreate = '[Template] Widget Avatar Template Create',
  WidgetAvatarTemplateCreateSuccess = '[Template] Widget Avatar Template Create Success',
  WidgetAvatarTemplateCreateError = '[Template] Widget Avatar Template Create Error',
  WidgetAvatarTemplateUpdate = '[Template] Widget Avatar Template Update',
  WidgetAvatarTemplateUpdateSuccess = '[Template] Widget Avatar Template Update Success',
  WidgetAvatarTemplateUpdateError = '[Template] Widget Avatar Template Update Error',
  WidgetAvatarTemplateDelete = '[Template] Widget Avatar Template Delete',
  WidgetAvatarTemplateDeleteSuccess = '[Template] Widget Avatar Template Delete Success',
  WidgetAvatarTemplateDeleteError = '[Template] Widget Avatar Template Delete Error',
  WidgetAvatarTemplateGenerate = '[Template] Widget Avatar Template Generate',
  WidgetAvatarTemplateGenerateSuccess = '[Template] Widget Avatar Template Generate Success',
  WidgetAvatarTemplateGenerateError = '[Template] Widget Avatar Template Generate Error',

  ResetTemplateState = '[Template] Reset Template State',
}

export const TemplateListAll = createAction(ETemplateActions.TemplateListAll, (params: any = {}) => params);
export const TemplateListAllSuccess = createAction(ETemplateActions.TemplateListAllSuccess, props<{ templates: Template[] }>());
export const TemplateListAllError = createAction(ETemplateActions.TemplateListAllError, props<{ error: string }>());

export const TemplateList = createAction(ETemplateActions.TemplateList, (params: any = {}) => params);
export const TemplateListSuccess = createAction(ETemplateActions.TemplateListSuccess, props<{ paginatedTemplates: PaginatedTemplate }>());
export const TemplateListError = createAction(ETemplateActions.TemplateListError, props<{ error: string }>());

export const TemplateUpdateStatus = createAction(ETemplateActions.TemplateUpdateStatus, props<{ id: number, status: string }>());
export const TemplateUpdateStatusSuccess = createAction(ETemplateActions.TemplateUpdateStatusSuccess, props<{ template: Template }>());
export const TemplateUpdateStatusError = createAction(ETemplateActions.TemplateUpdateStatusError, props<{ error: string }>());

export const TemplateDelete = createAction(ETemplateActions.TemplateDelete, props<{ templateId: number }>());
export const TemplateDeleteSuccess = createAction(ETemplateActions.TemplateDeleteSuccess);
export const TemplateDeleteError = createAction(ETemplateActions.TemplateDeleteError, props<{ error: string }>());

export const TemplateUpdate = createAction(ETemplateActions.TemplateUpdate, props<{ id: number, body: any }>());
export const TemplateUpdateSuccess = createAction(ETemplateActions.TemplateUpdateSuccess, props<{ template: Template }>());
export const TemplateUpdateError = createAction(ETemplateActions.TemplateUpdateError, props<{ error: string }>());

export const TemplateCreate = createAction(ETemplateActions.TemplateCreate, props<{ body: any }>());
export const TemplateCreateSuccess = createAction(ETemplateActions.TemplateCreateSuccess, props<{ template: Template }>());
export const TemplateCreateError = createAction(ETemplateActions.TemplateCreateError, props<{ error: string }>());

export const TemplateTouch = createAction(ETemplateActions.TemplateTouch, props<{ params: any }>());
export const TemplateTouchSuccess = createAction(ETemplateActions.TemplateTouchSuccess, props<{ template: Template }>());
export const TemplateTouchError = createAction(ETemplateActions.TemplateTouchError, props<{ error: string }>());

export const TemplateGet = createAction(ETemplateActions.TemplateGet, props<{ templateId: number }>());
export const TemplateGetSuccess = createAction(ETemplateActions.TemplateGetSuccess, props<{ template: Template }>());
export const TemplateGetError = createAction(ETemplateActions.TemplateGetError, props<{ error: string }>());

export const TemplatePreview = createAction(ETemplateActions.TemplatePreview, props<{ templateId: number, template: PreviewTemplate }>());
export const TemplatePreviewSuccess = createAction(ETemplateActions.TemplatePreviewSuccess);
export const TemplatePreviewError = createAction(ETemplateActions.TemplatePreviewError, props<{ error: string }>());

export const TemplateCampaign = createAction(ETemplateActions.TemplateCampaign, props<{ params: any }>());
export const TemplateCampaignSuccess = createAction(ETemplateActions.TemplateCampaignSuccess, props<{ templateCampaign: any }>());
export const TemplateCampaignError = createAction(ETemplateActions.TemplateCampaignError, props<{ error: string }>());

export const TemplatePreviewURL = createAction(ETemplateActions.TemplatePreviewURL, props<{ templateId: number }>());
export const TemplatePreviewURLSuccess = createAction(ETemplateActions.TemplatePreviewURLSuccess, props<{ previewUrl: any }>());
export const TemplatePreviewURLError = createAction(ETemplateActions.TemplatePreviewURLError, props<{ error: string }>());

export const TemplateWidgetPreviewURL = createAction(ETemplateActions.TemplateWidgetPreviewURL, props<{ campaignId: number }>());
export const TemplateWidgetPreviewURLSuccess = createAction(ETemplateActions.TemplateWidgetPreviewURLSuccess, props<{ widgetPreviewUrl: any }>());
export const TemplateWidgetPreviewURLError = createAction(ETemplateActions.TemplateWidgetPreviewURLError, props<{ error: string }>());

export const TemplateUpdateThumbnail = createAction(ETemplateActions.TemplateUpdateThumbnail);
export const TemplateUpdateThumbnailSuccess = createAction(ETemplateActions.TemplateUpdateThumbnailSuccess, props<{ success: string }>());
export const TemplateUpdateThumbnailError = createAction(ETemplateActions.TemplateUpdateThumbnailError, props<{ error: string }>());

export const TemplateGetTypes = createAction(ETemplateActions.TemplateGetTypes);
export const TemplateGetTypesSuccess = createAction(ETemplateActions.TemplateGetTypesSuccess, props<{ templateTypes: TemplateType[] }>());
export const TemplateGetTypesError = createAction(ETemplateActions.TemplateGetTypesError, props<{ error: string }>());

export const WidgetIntegrationBody = createAction(ETemplateActions.WidgetIntegrationBody, props<{ params?: any }>());
export const WidgetIntegrationBodySuccess = createAction(ETemplateActions.WidgetIntegrationBodySuccess,
  props<{ widgetIntegrationBody: any }>());
export const WidgetIntegrationBodyError = createAction(ETemplateActions.WidgetIntegrationBodyError, props<{ error: string }>());

export const WidgetCustomScriptBody = createAction(ETemplateActions.WidgetCustomScriptBody);
export const WidgetCustomScriptBodySuccess = createAction(ETemplateActions.WidgetCustomScriptBodySuccess,
  props<{ widgetCustomScriptBody: any }>());
export const WidgetCustomScriptBodyError = createAction(ETemplateActions.WidgetCustomScriptBodyError, props<{ error: string }>());

export const TemplateGetRevisions = createAction(ETemplateActions.TemplateGetRevisions, props<{ templateId: number, params?: any }>());
export const TemplateGetRevisionsSuccess = createAction(ETemplateActions.TemplateGetRevisionsSuccess,
  props<{ templateRevisions: PaginatedTemplateRevisions }>());
export const TemplateGetRevisionsError = createAction(ETemplateActions.TemplateGetRevisionsError, props<{ error: string }>());

export const WidgetAvatarTemplateList = createAction(ETemplateActions.WidgetAvatarTemplateList, (params: any = {}) => params);
export const WidgetAvatarTemplateListSuccess = createAction(ETemplateActions.WidgetAvatarTemplateListSuccess, props<{ paginatedWidgetAvatarTemplates: PaginatedWidgetAvatarTemplate }>());
export const WidgetAvatarTemplateListError = createAction(ETemplateActions.WidgetAvatarTemplateListError, props<{ error: string }>());

export const WidgetAvatarTemplateListAll = createAction(ETemplateActions.WidgetAvatarTemplateListAll, (params: any = {}) => params);
export const WidgetAvatarTemplateListAllSuccess = createAction(ETemplateActions.WidgetAvatarTemplateListAllSuccess, props<{ widgetAvatarTemplates: WidgetAvatarTemplate[] }>());
export const WidgetAvatarTemplateListAllError = createAction(ETemplateActions.WidgetAvatarTemplateListAllError, props<{ error: string }>());

export const WidgetAvatarTemplateCreate = createAction(ETemplateActions.WidgetAvatarTemplateCreate, props<{ body: any }>());
export const WidgetAvatarTemplateCreateSuccess = createAction(ETemplateActions.WidgetAvatarTemplateCreateSuccess, props<{ widgetAvatarTemplate: WidgetAvatarTemplate }>());
export const WidgetAvatarTemplateCreateError = createAction(ETemplateActions.WidgetAvatarTemplateCreateError, props<{ error: string }>());

export const WidgetAvatarTemplateUpdate = createAction(ETemplateActions.WidgetAvatarTemplateUpdate, props<{ id: number, body: any }>());
export const WidgetAvatarTemplateUpdateSuccess = createAction(ETemplateActions.WidgetAvatarTemplateUpdateSuccess, props<{ widgetAvatarTemplate: WidgetAvatarTemplate }>());
export const WidgetAvatarTemplateUpdateError = createAction(ETemplateActions.WidgetAvatarTemplateUpdateError, props<{ error: string }>());

export const WidgetAvatarTemplateDelete = createAction(ETemplateActions.WidgetAvatarTemplateDelete, props<{ widgetAvatarTemplateId: number }>());
export const WidgetAvatarTemplateDeleteSuccess = createAction(ETemplateActions.WidgetAvatarTemplateDeleteSuccess);
export const WidgetAvatarTemplateDeleteError = createAction(ETemplateActions.WidgetAvatarTemplateDeleteError, props<{ error: string }>());

export const WidgetAvatarTemplateGenerate = createAction(ETemplateActions.WidgetAvatarTemplateGenerate, props<{ body: any }>());
export const WidgetAvatarTemplateGenerateSuccess = createAction(ETemplateActions.WidgetAvatarTemplateGenerateSuccess, props<{ widgetAvatars: any }>());
export const WidgetAvatarTemplateGenerateError = createAction(ETemplateActions.WidgetAvatarTemplateGenerateError, props<{ error: string }>());

export const ResetTemplateState = createAction(ETemplateActions.ResetTemplateState, (params: any = {}) => params);

