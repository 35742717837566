import {
  Component, ElementRef, EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  OnInit, Output,
  SimpleChanges,
  ViewChild
} from '@angular/core';
import {Subject, Subscription} from 'rxjs';
import {Influencer, InfluencerType, Project, User} from '@app/modules/shared/models';
import {ActivatedRoute, Router} from '@angular/router';
import {UntypedFormArray, UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators} from '@angular/forms';
import {Constants} from '@app/consts';
import {select, Store} from '@ngrx/store';
import {
  getFileUploadProgress,
  getInfluencerError,
  getInfluencersProjectSegment,
  getInfluencersTypes,
  getInfluencerSuccess,
  getLoggedInUser,
  getProject,
  getProjectError, getSignedUrl, getSignedUrlError,
  getUploadToSignedUrl, getUploadToSignedUrlError,
  getVariableError,
  getVariables,
  IAuthenticationState,
  IFileUploadState,
  IInfluencersState,
  InfluencersAdd,
  InfluencersProjectSegment,
  InfluencersTypeList,
  IProjectState,
  IVariableState,
  ProjectGet,
  ResetFileState,
  ResetInfluencerState,
  SignedUrl,
  UploadToSignedUrl,
  VariableList
} from '@app/stores';
import {takeUntil} from 'rxjs/operators';
import {ToastrService} from 'ngx-toastr';
import {FormService} from '@app/services/form.service';
import {CreatableSelectComponent} from '@app/_components/creatable-select';

@Component({
  selector: 'app-influencer-add',
  templateUrl: './influencer-add.component.html',
  styleUrls: ['./influencer-add.component.css'],
  providers: [Constants]
})
export class InfluencerAddComponent implements OnInit, OnDestroy, OnChanges {
  @Input() hideClass = false;
  @Input() isModal = false;
  @Input() projectId: number;
  @Input() influencerType;
  @Input() public selectedSegment: any;

  @Output() closeModal: EventEmitter<any> = new EventEmitter();
  @Output() isModelClosed: EventEmitter<any> = new EventEmitter();

  @ViewChild('segmentSelect') segmentSelect: CreatableSelectComponent;

  currentUser: User;
  influencer: Influencer;
  addSocialClick = true;
  currentUserSubscription: Subscription;
  addInfluencerForm: UntypedFormGroup;
  submitted = false;
  segmentsGlobal: any = [];
  segments: any = [];
  urlRegex = '';
  loaded = false;
  imageSrc: any;
  showForm = false;
  imageProfile = '../../assets/images/blue-logo.png';
  influencerTypes: InfluencerType[] = [];
  private unsubscriber = new Subject();
  public controls: any = {};
  public variables = {};
  project: Project;
  headshotFile = null;
  headshotSignedUrl = null;
  fileUploadProgress = 0;
  skipValues: any[] = [];
  selectedSkip = null;

  campaignId;
  get f() {
    return this.addInfluencerForm.controls;
  }

  get teamsGroup() {
    return this.addInfluencerForm.get('teams') as UntypedFormArray;
  }

  get socialsGroup() {
    return this.addInfluencerForm.get('socials') as UntypedFormArray;
  }

  get sessionsGroup() {
    return this.addInfluencerForm.get('sessions') as UntypedFormArray;
  }

  constructor(
    private formBuilder: UntypedFormBuilder,
    private router: Router,
    private route: ActivatedRoute,
    private authenticationStore: Store<IAuthenticationState>,
    private influencersStore: Store<IInfluencersState>,
    private fileUploadStore: Store<IFileUploadState>,
    private variableStore: Store<IVariableState>,
    private projectStore: Store<IProjectState>,
    private toastr: ToastrService,
    private constants: Constants,
    public formService: FormService,
    private elRef: ElementRef,
  ) {
    //
    // Set audience type and segment if available
    this.skipValues = constants.skipValues;
    if(this.route.snapshot.queryParams?.selectedInfluencerType) this.influencerType = this.route.snapshot.queryParams?.selectedInfluencerType;
    if(this.route.snapshot.queryParams?.campaignId) this.campaignId = this.route.snapshot.queryParams?.campaignId;
    if(this.route.snapshot.queryParams?.selectedSegment){
      this.selectedSegment = Number(this.route.snapshot.queryParams?.selectedSegment);
    }

    this.addInfluencerForm = this.influencerFormGroup();
    this.route.params.subscribe(params => {
      if (params.projectId) {
        this.projectId = +params.projectId;
        this.projectStore.dispatch(ProjectGet({projectId: this.projectId}));
        this.influencersStore.dispatch(InfluencersProjectSegment({projectId: this.projectId}));
      }
    });
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.influencerType && changes.influencerType.currentValue) {
      this.createForm(changes.influencerType.currentValue);
    }
    if (changes.projectId && changes.projectId.currentValue) {
      this.projectStore.dispatch(ProjectGet({projectId: changes.projectId.currentValue}));
      this.influencersStore.dispatch(InfluencersProjectSegment({projectId: changes.projectId.currentValue}));
    }
  }

  subscribeStore() {
    this.authenticationStore.pipe(select(getLoggedInUser))
      .pipe(takeUntil(this.unsubscriber))
      .subscribe(user => {
          this.currentUser = user;
        }
      );

    this.influencersStore.pipe(select(getInfluencersProjectSegment))
      .pipe(takeUntil(this.unsubscriber))
      .subscribe(segments => {
        if (segments && segments.length > 0) {
          this.segmentsGlobal = segments;
          this.segments = this.segmentsGlobal;
        }
      });
    this.influencersStore.pipe(select(getInfluencerSuccess))
      .pipe(takeUntil(this.unsubscriber))
      .subscribe(success => {
          if (success) {
            if (this.submitted) {
              if (this.campaignId) {
                this.router.navigate([`/project/${this.projectId}/add-influencer`],
                  { queryParams: { campId: this.campaignId , selectedSegmentUrl: this.selectedSegment , selectedInfluencerUrl : this.influencerType } });
              } else {
                this.router.navigate([`/projects/${this.projectId}/influencers`]);
              }
            }
          }
        }
      );
    this.influencersStore.pipe(select(getInfluencerError))
      .pipe(takeUntil(this.unsubscriber))
      .subscribe(error => {
          if (error) {
            this.toastr.error(error, 'Error');
          }
        }
      );
    this.influencersStore.pipe(select(getInfluencersTypes))
      .pipe(takeUntil(this.unsubscriber))
      .subscribe(influencerTypes => {
        if (influencerTypes && influencerTypes.length > 0) {
          this.influencerTypes = influencerTypes;
          this.addSocialClick = true;
          this.createForm(this.influencerType || influencerTypes[0].id);
          this.filterSegments();
        }
      });
    this.fileStoreSubscribe();
    this.variableStoreSubscribe();

    this.projectStore.pipe(select(getProjectError))
      .pipe(takeUntil(this.unsubscriber))
      .subscribe(error => {
        if (error) {
          this.toastr.error(error, 'Error');
        }
      });
    this.projectStore.pipe(select(getProject))
      .pipe(takeUntil(this.unsubscriber))
      .subscribe(project => {
        if (project) {
          this.project = project;
        }
      });
  }

  fileStoreSubscribe() {
    this.fileUploadStore.pipe(select(getSignedUrlError))
      .pipe(takeUntil(this.unsubscriber))
      .subscribe(error => {
        if (error) {
          this.toastr.error(error, 'Error');
        }
      });
    this.fileUploadStore.pipe(select(getUploadToSignedUrlError))
      .pipe(takeUntil(this.unsubscriber))
      .subscribe(error => {
        if (error) {
          this.toastr.error(error, 'Error');
        }
      });
    this.fileUploadStore.pipe(select(getFileUploadProgress))
      .pipe(takeUntil(this.unsubscriber))
      .subscribe((data: any) => {
        if (data !== undefined && data !== null) {
          this.fileUploadProgress = data;
        }
      });
    this.fileUploadStore.pipe(select(getSignedUrl))
      .pipe(takeUntil(this.unsubscriber))
      .subscribe((data: any) => {
        if (data && data.url) {
          this.headshotSignedUrl = data.url;
        }
      });
    this.fileUploadStore.pipe(select(getUploadToSignedUrl))
      .pipe(takeUntil(this.unsubscriber))
      .subscribe((data: any) => {
        if (data && this.headshotSignedUrl) {
          const image = this.headshotSignedUrl.split('?')[0];
          this.addInfluencerForm.patchValue({image});
          this.headshotSignedUrl = null;
          this.headshotFile = null;
          this.addInfluencerData();
        }
      });
  }

  variableStoreSubscribe() {
    this.variableStore.pipe(select(getVariableError))
      .pipe(takeUntil(this.unsubscriber))
      .subscribe(error => {
        if (error) {
          this.toastr.error(error, 'Error');
        }
      });
    this.variableStore.pipe(select(getVariables))
      .pipe(takeUntil(this.unsubscriber))
      .subscribe(variables => {
        if (variables) {
          const result = [];
          Object.entries(variables).forEach(([key, value]) => {
            if (value.hasOwnProperty('group') && value['group']) {
              if (!result[value['group']]) {
                result[value['group']] = [];
              }
              result[value['group']].push({
                name: value['title'].replace(value['group'].toString().toLowerCase().trim(), ''),
                key: value['alias'] ? `#${value['alias']}#` : value['key'],
                valueType: value['valueType']
              });
            } else {
              if (!result['generic']) {
                result['generic'] = [];
              }
              result['generic'].push({
                name: value['title'],
                key: value['key'],
                valueType: value['valueType']
              });
            }
          });
          this.variables = result;
        }
      });
  }

  filterSegments(clearSelection: boolean = false) {
    this.addInfluencerForm.patchValue({project_influencer_segment_new_name: ''});
    const influencerType = this.addInfluencerForm.get('influencer_type_id').value;
    if (influencerType) {
      if (this.segmentSelect && clearSelection === true) {
        this.segmentSelect.clearSelection();
        this.addInfluencerForm.patchValue({project_influencer_segment_id: ''});
      }
      this.segments = this.segmentsGlobal.filter(
        p => p.influencer_type_id === Number(this.addInfluencerForm.get('influencer_type_id').value)
      );
    }
  }

  createForm(influencerType: number) {
    influencerType = Number(influencerType);
    const influencer = this.influencerTypes.find(item => item.id === influencerType);

    if (influencer) {
      const validators = {
        first_name: [Validators.required],
        name: [Validators.required],
        influencer_type_id: [Validators.required],
        project_influencer_segment_id: [Validators.required],
        last_name: [Validators.required],
        email: [Validators.required, Validators.email],
        registration_url: [Validators.pattern(this.constants.urlRegex)],
        video_url: [],
        skip:[],
        email_source_message: [''],
      };
      this.controls = {};

      Object.keys(this.addInfluencerForm.controls).forEach(key => {
        this.addInfluencerForm.removeControl(key);
      });
      this.showForm = false;
      this.addInfluencerForm.addControl('project_influencer_segment_new_name', new UntypedFormControl(''));
      this.addInfluencerForm.addControl('skip', new UntypedFormControl());
      // this.addInfluencerForm.addControl('facebook', new FormControl('', [Validators.pattern(this.constants.urlRegex)]));
      // this.addInfluencerForm.addControl('twitter', new FormControl('', [Validators.pattern(this.constants.urlRegex)]));
      // this.addInfluencerForm.addControl('linkedin', new FormControl('', [Validators.pattern(this.constants.urlRegex)]));
      // this.addInfluencerForm.addControl('instagram', new FormControl('', [Validators.pattern(this.constants.urlRegex)]));
      // this.addInfluencerForm.addControl('socials', new FormArray([]));
      // this.addInfluencerForm.addControl('teams', new FormArray([this.addTeamsFormGroup()]));

      const fieldsObject = influencer.fields;
      Object.keys(fieldsObject).forEach(key => {
        if (fieldsObject[key].type === 'all') {
          if (fieldsObject[key].editable) {
            this.addControls(fieldsObject, key, validators);
          }
        } else {
          if (fieldsObject[key].type.includes(influencer.slug)) {
            if (fieldsObject[key].editable) {
              this.addControls(fieldsObject, key, validators);
            }
          }
        }
      });
      this.addInfluencerForm.patchValue({influencer_type_id: influencerType, project_influencer_segment_id: this.selectedSegment});
      this.showForm = true;
    }
  }

  addControls(fieldsObject, key, validators) {
    this.controls[key] = key;
    if (key === 'sessions') {
      this.addInfluencerForm.addControl('sessions', new UntypedFormArray([this.addSessionFormGroup()]));
    } else {
      this.addInfluencerForm.addControl(key, new UntypedFormControl('', validators[key] || []));
    }
  }

  segmentSelected(event: any) {
    if (event) {
      if (event.isNew) {
        this.addInfluencerForm.patchValue({project_influencer_segment_new_name: event.value});
        this.formService.setNewOrExistingSegmentValidation(this.addInfluencerForm, 0);
      } else {
        this.addInfluencerForm.patchValue({project_influencer_segment_id: event.value});
        this.formService.setNewOrExistingSegmentValidation(this.addInfluencerForm, 1);
      }
    } else {
      this.addInfluencerForm.patchValue({project_influencer_segment_new_name: '', project_influencer_segment_id: ''});
      this.formService.setNewOrExistingSegmentValidation(this.addInfluencerForm, 0);
    }
  }

  skipSelected(event: any) {
    if (event?.value) {
      this.selectedSkip = this.skipValues.find(x => x.id === event.value || null);
      this.addInfluencerForm.patchValue({skip: this.selectedSkip.value || null});
    } else {
      this.addInfluencerForm.patchValue({skip: null });
    }
  }

  ngOnInit() {
    this.variableStore.dispatch(VariableList({params: {}}));
    this.influencersStore.dispatch(ResetInfluencerState({params: {error: '', influencerTypes: []}}));
    this.fileUploadStore.dispatch(ResetFileState({ params: { error: '', file: null } }));
    this.subscribeStore();
    this.influencersStore.dispatch(InfluencersTypeList({params: {influencer_fields: true}}));
  }

  influencerFormGroup(): UntypedFormGroup {
    return this.formBuilder.group({});
  }

  addTeamsFormGroup(): UntypedFormGroup {
    return this.formBuilder.group({
      profile_img: [''],
      first_name: [''],
      last_name: [''],
      email: [''],
      phone: [''],
      job_title: [''],
      skip: [''],
      email_source_message: [''],
    });
  }

  addSocialsFormGroup(): UntypedFormGroup {
    return this.formBuilder.group({
      social_label: [''],
      social_value: ['', [Validators.pattern(this.constants.urlRegex)]]
    });
  }

  addSessionFormGroup(): UntypedFormGroup {
    return this.formBuilder.group({
      session: [''],
    });
  }

  activeFileInput(fileInput) {
    fileInput.click();
  }

  onFileChanged(event) {
    if (event.target.files && event.target.files.length) {
      this.headshotFile = event.target.files[0];
      this.fileUploadStore.dispatch(SignedUrl({fileName: this.headshotFile.name, contentType: this.headshotFile.type}));
      new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(this.headshotFile);
        reader.onloadend = () => resolve(reader.result);
        reader.onerror = error => reject(error);
      }).then(data => {
        this.imageSrc = data;
        this.loaded = true;
      });
    }
  }

  onProfileChanged(event, index) {
    if (event.target.files && event.target.files.length) {
      new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(event.target.files[0]);
        reader.onloadend = () => resolve(reader.result);
        reader.onerror = error => reject(error);
      }).then(data => {
        this.teamsGroup.at(index).patchValue({profile_img: data});
      });
    }
  }

  addTeamsButtonClick(): void {
    this.teamsGroup.push(this.addTeamsFormGroup());
  }

  addSessionButtonClick(): void {
    this.sessionsGroup.push(this.addSessionFormGroup());
  }

  addSocialsButtonClick(): void {
    this.socialsGroup.push(this.addSocialsFormGroup());
  }

  removeTeamsButtonClick(index: number): void {
    this.teamsGroup.removeAt(index);
  }

  removeSocialsButtonClick(index: number): void {
    this.socialsGroup.removeAt(index);
  }

  removeSessionButtonClick(index: number): void {
    this.sessionsGroup.removeAt(index);
  }

  addInfluencer() {
    this.submitted = true;
    if (this.addInfluencerForm.invalid) {
      setTimeout(() => {
        this.scrollToField();
      }, 500);
      return;
    }

    if (localStorage.getItem('influencerSearchValue')) {
      localStorage.removeItem('influencerSearchValue');
    }

    if (this.headshotSignedUrl && this.headshotFile) {
      this.fileUploadStore.dispatch(UploadToSignedUrl({files: [{url: this.headshotSignedUrl, fileData: this.headshotFile}]}));
    } else {
      this.addInfluencerData();
    }
  }

  addInfluencerData() {
    const body = this.addInfluencerForm.value;
    const sessions = [];
    if (body.hasOwnProperty('sessions')) {
      body.sessions.forEach(item => {
        sessions.push(item.session);
      });
    }

    body.sessions = sessions.filter((item) => item).join('|');

    this.influencersStore.dispatch(InfluencersAdd({
      influencer: {
        project_id: this.projectId,
        ...body
      }
    }));
  }

  updateValue(variable, textFor) {
    const value = this.addInfluencerForm.get(textFor).value;
    const varValue = variable.key;
    this.addInfluencerForm.controls[textFor].setValue(`${value} ${varValue}`);
  }

  getImageType() {
    const influencersType = this.addInfluencerForm.get('influencer_type_id').value;
    const type = this.influencerTypes.find(item => item.id === influencersType);
    if (type) {
      if (type.slug === 'attendee' || type.slug === 'speaker') {
        return 'Headshot';
      } else if (type.slug === 'partner') {
        return 'Logo';
      }
    }
    return 'Logo or Headshot';
  }

  scrollToField() {
    const invalid = this.elRef.nativeElement.querySelector('.invalid-text');
    if (invalid) {
      this.toastr.warning('Some fields have error, please review', 'warning');
      const doc = this.isModal ? document.querySelector('.custom-modal .modal-dialog .modal-body') : window;
      doc.scrollBy({
        top: invalid.getBoundingClientRect().top - 150,
        left: 0,
        behavior: 'smooth'
      });
    }
  }

  ngOnDestroy() {
    this.unsubscriber.next();
    this.unsubscriber.complete();
  }
}
