<div class="container-fluid pt-3 new-styles">
  <div class="rightBody">
    <div class="card card-box mb-5">
      <div class="card-header py-3">
        <div class="card-header--title font-size-lg">
          Manage Registration Platforms
        </div>
        <div class="card-header--actions">
          <button class="btn btn-sm btn-primary" type="button" [routerLink]="['add']" role="button">
            <span class="btn-wrapper--icon"><i class="fa fa-plus"></i></span>
            <span class="btn-wrapper--label">Add New Platform</span>
          </button>
        </div>
      </div>
      <div class="d-flex flex-wrap px-4 py-3">
        <div class="client-dropdown">
          <app-creatable-select
            [hideLabel]="true" [isCreatable]="false" [onlyBottomBorder]="false"
            [placeholder]="'Widget Integration Guide'" [selectOptions]="supportTypes" valueKey="value"
            (optionSelected)="onSupportSelect($event, 'widget_integration_guide')"></app-creatable-select>
        </div>

        <div class="client-dropdown">
          <app-creatable-select
            [hideLabel]="true" [isCreatable]="false" [onlyBottomBorder]="false"
            [placeholder]="'Widget Custom Code'" [selectOptions]="supportTypes" valueKey="value"
            (optionSelected)="onSupportSelect($event, 'widget_customization_code')"></app-creatable-select>
        </div>

        <div class="client-dropdown">
          <app-creatable-select
            [hideLabel]="true" [isCreatable]="false" [onlyBottomBorder]="false"
            [placeholder]="'Widget Custom CSS'" [selectOptions]="supportTypes" valueKey="value"
            (optionSelected)="onSupportSelect($event, 'widget_customization_css')"></app-creatable-select>
        </div>
        <div class="client-dropdown">
          <app-creatable-select
            [selectedValue]="widgetFilterValues"
            [hideLabel]="true" [isCreatable]="false" [onlyBottomBorder]="false"
            [placeholder]="'Select Widget Support Values'" [selectOptions]="widgetSupportValues" valueKey="value"
            (optionSelected)="onWidgetValuesSelect($event)"></app-creatable-select>
        </div>
        <div class="client-dropdown">
          <app-creatable-select
            [selectedValue]="syncFilterValues"
            [hideLabel]="true" [isCreatable]="false" [onlyBottomBorder]="false"
            [placeholder]="'Select Sync Support Values'" [selectOptions]="syncSupportValues" valueKey="value"
            (optionSelected)="onSyncValuesSelect($event)"></app-creatable-select>
        </div>
      </div>
      <div class="sticky-dropdown-custom-table p-3">
        <table width="100%" class="sb-table table dataTable no-footer nowrap" [dtTrigger]="dtTrigger" datatable
               [dtOptions]="dtOptions"
               id="dt-table">
          <thead>
          <tr>
            <th class="no-sort project-image"></th>
            <th>Name</th>
            <th>Website</th>
            <th style="min-width: 500px">Projects</th>
            <th>Widget Integration Guide</th>
            <th>Widget Custom Code</th>
            <th>Widget Custom CSS</th>
<!--            <th>widget Customization code</th>-->
            <th></th>
          </tr>
          </thead>
          <tbody *ngIf="registrationPlatformList.length > 0">
          <tr *ngFor="let platform of registrationPlatformList">
            <td>
              <img class="image-thumbnail" *ngIf="platform.image" [src]="platform.image" alt=""/>
            </td>
            <td class="font-weight-bold">{{platform.name}}</td>
            <td class="widget_code"><a href="{{platform.website}}" target="_blank">{{platform.website}}</a></td>
            <td>
<!--              [ngClass]="{'project-list': platform.projects.length <= 1}"-->
              <span class="project-list" *ngFor="let project of platform.projects; let i = index" >
<!--                 routerLink="/{{routes.campaigns({projectId: project.id})}}-->
                <span class="cursor-pointer" (click)="navigateAssociates(project.id)">{{project.title}}</span>
                <span class="cursor-pointer" *ngIf="i !== platform.projects.length - 1"> | </span>
              </span>
            </td>
            <td class="widget_code">{{platform.widget_integration_guide?.length ? 'Yes' : ''}}</td>
            <td>{{platform.widget_customization_code?.length ? 'Yes' : ''}}</td>
            <td>{{platform.widget_customization_css?.length ? 'Yes' : ''}}</td>
<!--            <td class="widget_code">{{platform.widget_customization_code}}</td>-->

            <td>
              <button class="btn-icon float-right" mat-icon-button [matMenuTriggerFor]="menu"
                      aria-label="Example icon-button with a menu">
                <span class="fa fa-ellipsis-v"></span>
              </button>
              <mat-menu #menu="matMenu" class="campaign-popover">
                <a class="dropdown-item" [routerLink]="['edit', platform.id]">
                  <i class="fa fa-pencil mr-2"></i>Edit</a>
                <a class="dropdown-item" (click)="deleteRegistrationPlatform(platform.id)">
                  <i class="fa fa-trash mr-2"></i>Delete</a>
              </mat-menu>
            </td>
          </tr>

          </tbody>
          <tbody *ngIf="registrationPlatformList?.length <= 0">
          <tr>
            <td colspan="8" class="no-data-available">No data available in table</td>
          </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</div>
