<div class="container-fluid pt-3 mobile-p0 campaign-creation-steps">
  <div class="rightBody">
    <app-status-bar [step]="step" (SaveButtonClick)="onSaveButtonClick()"></app-status-bar>
    <div class="card card-box mb-10">
<!--      <div class="card-header py-3">-->
<!--        <div class="card-header&#45;&#45;title font-size-lg">-->
<!--          Mobilize {{influencerType()}}-->
<!--        </div>-->
<!--        <div class="card-header&#45;&#45;actions pull-right">-->
<!--        </div>-->
<!--      </div>-->
      <div class="card-body">
        <div class="mb-2">
          <br/>
          <h5>Choose and personalize {{influencerType()}}' landing pages</h5>
          <small class="text-secondary">
            Give {{influencerType()}} their own individual personalized page to promote their presence at this
            {{campaign?campaign.name:''}}
          </small>
        </div>
        <br/>
        <div class="mb-2">
          <h5>My Templates</h5>

          <div class="row">
            <!-- <div class="col-12 text-center" >
              <span class="text-secondary"><i>There are no available templates for this section.</i></span>
            </div> -->
            <div class="col-3 cursor-pointer d-flex flex-column">
              <div class="card add-template-button flex-fill d-flex flex-column justify-content-center align-items-center mb-5 p-4" (click)="blankTemplateRedirect()">
                <mat-icon>add_photo_alternate</mat-icon>
                <div>Use a Blank Template</div>
              </div>
            </div>

            <div class='col-3' *ngFor="let template of clientTemplates; let i = index">
              <div class="card mb-5">
                <div class="card-img-wrapper" [defaultImage]="'/assets/images/template-placeholder.png'" [lazyLoad]="template.thumbnail">
                  <div class="template-preview">
                    <button class="btn btn-default" (click)="showTemplateThumbnail(thumbnailPreview, template.thumbnail)">Preview</button>
                    <button class="btn btn-default" (click)="onTemplateSelect('clientTemplates', i, false)">Select</button>
                  </div>
                </div>
                <div class="card-body">
                  <div class="row">
                    <div class="col-12">
                      <div class="d-flex align-items-center">
                        <h5 class="card-title mb-0 pr-2 flex-fill">{{template?.name || 'Untitled'}}</h5>
                        <button class="btn-icon float-right" mat-icon-button [matMenuTriggerFor]="menu"
                                aria-label="Example icon-button with a menu">
                          <span class="fa fa-ellipsis-v"></span>
                        </button>
                        <mat-menu #menu="matMenu" class="campaign-popover">
                          <a class="dropdown-item" (click)="openRenameModal(template)">Rename</a>
                          <a class="dropdown-item" (click)="removeTemplate(template)">Delete</a>
                        </mat-menu>
                      </div>
                      <div><span class="text-secondary text-right pr-2">{{parseTimeAgo(template.created_at)}}</span></div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div>
          <h5>Snö Templates</h5>
          <div class="row">
            <div class="col-12 text-center text-italic" *ngIf="snoTemplates.length === 0">
              <span class="text-secondary"><i>There are no available templates for this section.</i></span>
            </div>
            <div class='col-3 cursor-pointer' *ngFor="let template of snoTemplates; let i = index">
              <div class="card mb-5">
                <div class="card-img-wrapper" [defaultImage]="'/assets/images/template-placeholder.png'" [lazyLoad]="template.thumbnail">
                  <div class="template-preview">
                    <button class="btn btn-default" *ngIf="template.thumbnail" (click)="showTemplateThumbnail(thumbnailPreview, template.thumbnail)">Preview</button>
                    <button class="btn btn-default" (click)="onTemplateSelect('snoTemplates', i, true)">Select</button>
                  </div>
                </div>
                <div class="card-body">
                  <div class="row">
                    <div class="col-12">
                      <h5 class="card-title mb-0">{{template.name}}</h5>
                      <span class="text-secondary text-right d-none">{{parseTimeAgo(template.created_at)}}</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<div class="operation-box">
  <div class="col-md-12">
    <div class="row">
      <div class="col-md-2">
        <div class="text-left">
          <button
            (click)="onBackButtonClick()"
            class="btn btn-default stopclick"
            type="button">
            Back
          </button>
        </div>
      </div>
      <div class="col-md-2 d-flex justify-content-center">
        <div class="select-page-size">
        </div>
      </div>
      <div class="col-md-8">
        <div class="text-right">
          <button
            (click)="onNextButtonClick()"
            class="btn btn-primary stopclick"
            type="button">
            Next
          </button>
        </div>
      </div>
    </div>
  </div>
</div>

<ng-template #thumbnailPreview let-modal>
  <div class="modal-body p-0">
    <img ngbAutofocus [src]="thumbnail" class="thumbnail-image"/>
  </div>
  <div class="modal-footer">
    <button type="button" class="btn btn-outline-dark" (click)="this.thumbnailModel.close('Close click')">Close</button>
  </div>
</ng-template>
