import {User} from '@app/modules/shared/models/user';
import {Project} from '@app/modules/shared/models/project';
import {ProjectInfluencerSegment} from '@app/modules/shared/models/project-influencer-segment';
import {Paging} from '@app/modules/shared/models/paging';

export class Campaign {
  id: number;
  code: string;
  project_id: number;
  project_influencer_segment_id: number;
  client_id: number;
  name: string;
  email_from_name: string;
  email_from: string;
  email_reply_to: string;
  email_source_message: string;
  email_body: string;
  email_subject: string;
  from_email_status: {};
  meta: any;
  launched_schedule_date: string;
  launched_at: string;
  launch_code: string;
  stage: string;
  created_by: number;
  status: string;
  landing_page_status: string;
  link_type: string;
  custom_cta_url: string;
  updated_at: string;
  created_at: string;
  client: User;
  project: Project;
  segments: ProjectInfluencerSegment[];
  influencer_dynamic_cta: String;
}

export class PaginatedCampaigns {
  list: Campaign[];
  paging: Paging;
}
