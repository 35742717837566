import {AfterViewInit, ChangeDetectorRef, Component, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {Subject, Subscription} from 'rxjs';
import {Router, ActivatedRoute} from '@angular/router';
import {TitleService} from '@app/services/title.service';
import {DomSanitizer} from '@angular/platform-browser';
  import Swal from 'sweetalert2';
import * as routes from '@app/routes';
import {select, Store} from '@ngrx/store';
import {Observable} from 'rxjs/Observable';
import {
  getProjectError,
  getProjectSuccess,
  getLoggedInUser,
  IAuthenticationState,
  IProjectState,
  ProjectList,
  IClientState,
  getClientError,
  getClients,
  ProjectDelete,
  ResetProjectState,
  ProjectCopy,
  getPaginatedProjects,
  ClientsListAll, getProjects, ClientsList, getPaginatedClients,
} from '@app/stores';
import {takeUntil} from 'rxjs/operators';
import {ToastrService} from 'ngx-toastr';
import {Client, Project} from '@app/modules/shared/models';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {ProjectCopyComponent} from '@app/modules/project/project-copy/project-copy.component';
import * as moment from 'moment-timezone';
import {DataTableDirective} from 'angular-datatables';
import {Constants} from '@app/consts';
import {LoaderService} from '@app/services/loader.service';
import * as DataTables from 'datatables.net';

@Component({
  selector: 'app-project-list',
  templateUrl: './project-list.component.html',
  styleUrls: ['./project-list.component.css'],
  providers: [Constants]
})
export class ProjectListComponent implements OnInit, OnDestroy, AfterViewInit {
  @ViewChild(DataTableDirective, {static: true})
  dtElement: DataTableDirective;
  // dtOptions: DataTables.Settings = {};
  dtOptions: any = {};
  loader$: Observable<boolean>;
  dtTrigger: Subject<any> = new Subject();
  routes = routes;
  currentUser: any;
  currentUserSubscription: Subscription;
  selectedClient = 'All';
  projectPlaceholder = 'All Projects';
  // selectedStatus = 'Active';
  // selectedStatusSlug = 'active';
  public projects: Project[] = [];
  projectdata: object;
  role: string;
  showAll = false;
  // showAllCompleted = false;
  // showDraftProject = false;
  planners: Client[] = [];
  title = 'Project List';
  imageDefault = '../../assets/images/project-default.png';
  searchPhrase: string;
  unsubscriber = new Subject();
  deleteAction = false;
  copyAction = false;
  totalInfluencers = 0;
  totalPageViews = 0;
  totalReferrals = 0;
  totalShares = 0;
  ajaxCallback: any;
  private datatableInstance: DataTables.Api<any>;
  private searchDelay;
  viewType = 'card';
  projectSearchValue = '';
  clientSearchValue = '';
  projectsList = [];
  allProjectList = [];
  clientPlaceholder = 'Search by Clients';
  // statusPlaceholder = 'Active';
  pageLength = 100;
  selectedProject: number = null;
  projectListFilter = {};
  isOnLoad = true;
  isAdmin = false;
  pageNumber = 0;
  perPage = 100;
  selectedStatus: any = 'all';
  clientPageNumber = 0;
  totalClients = 0;
  errorMessage = '';

  constructor(
    private activatedRoute: ActivatedRoute,
    private authenticationStore: Store<IAuthenticationState>,
    private projectStore: Store<IProjectState>,
    private clientStore: Store<IClientState>,
    private router: Router,
    public constants: Constants,
    private toastrService: ToastrService,
    private titleService: TitleService,
    public domSanitizer: DomSanitizer,
    private _modalService: NgbModal,
    private readonly cdr: ChangeDetectorRef,
    private loaderService: LoaderService,
  ) {
    this.projectStore.dispatch(ResetProjectState({params: {error: '', paginatedProjects: null}}));
      this.activatedRoute.queryParams.subscribe(async (queryParams) => {
        if (queryParams.clientId) {
          this.selectedClient = queryParams.clientId;
        } else {
          this.selectedClient = 'All';
        }
      });
    this.subscribeStores();
    this.titleService.set(this.title);
  }

  subscribeStores() {
    this.authenticationStore.pipe(select(getLoggedInUser))
      .pipe(takeUntil(this.unsubscriber))
      .subscribe(user => {
          this.currentUser = user;
        this.isAdmin = this.currentUser.role_user[0].role_id === 1;
          // Redirect user if they are influencer
          if (this.currentUser && this.currentUser.user_type === '2') {
            const firstProjectId = this.currentUser.user.influencers[0].segment.project_id;
            this.router.navigate([
              'projects',
              firstProjectId,
              'share'
            ]);
          }
        }
      );

    this.projectStore.pipe(select(getProjectError))
      .pipe(takeUntil(this.unsubscriber))
      .subscribe(error => {
        if (error) {
          this.errorMessage = 'Unable to load the page please try again';
          this.toastrService.error(error);
        }
      });

    this.clientStore.pipe(select(getClientError))
      .pipe(takeUntil(this.unsubscriber))
      .subscribe(error => {
        if (error) {
          this.toastrService.error(error);
        }
      });

    this.projectStore.pipe(select(getProjectSuccess))
      .pipe(takeUntil(this.unsubscriber))
      .subscribe(success => {
        if (success) {
          if (this.deleteAction) {
            this.deleteAction = false;
            this.loadAllProjects();
          }

          if (this.copyAction) {
            this.copyAction = false;
            this.loadAllProjects();
          }
        }
      });

    this.projectStore.pipe(select(getPaginatedProjects))
      .pipe(takeUntil(this.unsubscriber))
      .subscribe((paginatedProjects: any) => {
          if (paginatedProjects) {
            this.errorMessage = '';
            let projectsNew = false;
            if (this.projects) { projectsNew = true; }
            this.projects = paginatedProjects.list;
            if ((this.isOnLoad) && (!localStorage.getItem('projectFilterList') || projectsNew)) {
              this.allProjectList = this.projects;
              this.isOnLoad = false;
            }
            if (this.ajaxCallback) {
              this.ajaxCallback({
                recordsTotal: paginatedProjects.paging.total,
                recordsFiltered: paginatedProjects.paging.total,
                data: []
              });
              setTimeout(() => {
                (this.datatableInstance as any).columns.adjust();
              }, 500);
            }
            this.filterTotal(this.projects);
          }
        }
      );

    // this.projectStore.pipe(select(getProjects))
    //   .pipe(takeUntil(this.unsubscriber))
    //   .subscribe(projects => {
    //     if (projects) {
    //       this.allProjectList = projects;
    //     }
    //   });

    this.clientStore.pipe(select(getPaginatedClients))
      .pipe(takeUntil(this.unsubscriber))
      .subscribe(paginatedClients => {
        if (paginatedClients) {
          this.totalClients = paginatedClients.paging.total;
          this.clientPageNumber = parseInt(paginatedClients.paging.page);
          this.planners = [...this.planners, ...paginatedClients.list];
          this.toastrService = paginatedClients.trial_registration_url;
        }
      });
  }

  filterTotal(projects) {
    this.totalInfluencers = 0;
    this.totalPageViews = 0;
    this.totalReferrals = 0;
    this.totalShares = 0;

    projects.forEach(item => {
      this.totalInfluencers += item.influencers_count ? Number(this.getTotalInfluencersCount(item.influencers_count)) : 0;
      this.totalReferrals += item.referrals_count ? Number(item.referrals_count) : 0;
      this.totalPageViews += item.pageviews_count ? Number(item.pageviews_count) : 0;

      let sharesTotal = 0;
      sharesTotal = item.social_share_count ? Number(item.social_share_count) : 0;
      sharesTotal += item.registration_click_count ? Number(item.registration_click_count) : 0;

      this.totalShares += sharesTotal;
    });
  }

  getSharesTotal(project) {
    let sharesTotal = 0;
    sharesTotal = project.social_share_count ? Number(project.social_share_count) : 0;
    sharesTotal += project.registration_click_count ? Number(project.registration_click_count) : 0;

    return sharesTotal;
  }

  ngOnInit() {
    this.loader$ = this.loaderService.loader$;
    if (localStorage.getItem('projectFilterList') && JSON.parse(localStorage.getItem('projectFilterList')).projectViewType) {
      this.viewType = JSON.parse(localStorage.getItem('projectFilterList')).projectViewType;
    }
    // if (localStorage.getItem('projectFilterList') && JSON.parse(localStorage.getItem('projectFilterList')).selectedClient) {
    //   this.selectedClient = JSON.parse(localStorage.getItem('projectFilterList')).selectedClient;
    // }
    // if (localStorage.getItem('projectFilterList') && JSON.parse(localStorage.getItem('projectFilterList')).selectedClientName) {
    //   this.clientPlaceholder = JSON.parse(localStorage.getItem('projectFilterList')).selectedClientName;
    // }
    this.datatableSettings();
    if (this.dtElement && this.dtElement.dtInstance) {
      this.dtElement.dtInstance.then((dtInstance: any) => {
        dtInstance.destroy();
        this.dtTrigger.next();
      });
    } else {
      this.dtTrigger.next();
    }
    this.manageRole();
    if (this.isAdmin) {
      this.loadAllPlanners();
    }
  }

  ngAfterViewInit() {
    this.dtTrigger.next();
  }

  datatableSettings() {
    const columns = ['image', 'title', 'campaign_count', 'influencers_count', 'click_rate', 'pageviews_count', 'created_at'];
    // @ts-ignore
    this.dtOptions = {
      scrollCollapse: true,
      pagingType: 'full_numbers',
      responsive: false,
      ordering: true,
      columnDefs: [{orderable: false, targets: 'no-sort'}],
      processing: true,
      lengthMenu: [10, 50, 100, 500],
      pageLength: this.pageLength,
      paging: true,
      serverSide: true,
      scrollX: true,
      searching: true,
      dom: '<"top"fil>rt<"bottom"p><"clear">',
      // scrollY: "100vh",
      fixedHeader: {
        header: true,
        footer: true,
        headerOffset: 55 ,
      },
      language: {
        search: '',
        searchPlaceholder: 'Search Projects'
      },
      drawCallback: function (setting) {
        const totalPages = this.api().page.info().pages;
        if (totalPages <= 1) {
          document.querySelector('.dataTables_paginate').classList.add('d-none');
        } else {
          document.querySelector('.dataTables_paginate').classList.remove('d-none');
        }
      },
      ajax: (dataTablesParameters: any, callback) => {
        this.dtElement.dtInstance.then((dtInstance: any) => {
          this.datatableInstance = dtInstance;
          this.ajaxCallback = callback;
          this.pageLength = dataTablesParameters.length;
          this.pageNumber = (dataTablesParameters.start / this.pageLength);
          (this.datatableInstance as any).page.len(this.pageLength);
          const searchBox = $('div.dataTables_filter input');
          searchBox.off('keyup.DT input.DT');
          searchBox.on('keyup', () => {
            const search: any = searchBox.val();
            clearTimeout(this.searchDelay);
            this.searchDelay = setTimeout(() => {
              if (search != null) {
                (this.datatableInstance as any).search(search).draw();
              }
            }, 1000);
          });
          const orderBy = {
            ...dataTablesParameters.order[0],
            column: dataTablesParameters.order[0].column === 1 ? 'title' :'created_at'
          };
          this.projectSearchValue = dataTablesParameters.search.value;
          this.loadAllProjects(this.projectSearchValue, orderBy);
        });
      },
      columns: [
        {data: null}, {data: null}, {data: null}, {data: null},
        {data: null}, {data: null}, {data: null}, {data: null},
        {data: null}
      ],
      order : [6, 'desc']
    };
  }

  getSantizeUrl(url: string) {
    return this.domSanitizer.bypassSecurityTrustUrl(url);
  }

  formatInsertTime(insertTime, dateType = null) {
    if (dateType === 'startEndDate') {
      insertTime = moment(insertTime, 'YYYY-MM-DD HH:mm A');
    } else {
      insertTime = moment(insertTime, 'YYYY-MM-DD[T]HH[:]mm[:]00[.000Z]');
    }
    if (insertTime.isValid()) {
      return insertTime.format('DD-MM-YYYY hh:mm a');
    }
    return '-';
  }

  formatMoment(insertTime, dateType = null) {
    if (dateType === 'startEndDate') {
      insertTime = moment(insertTime, 'YYYY-MM-DD HH:mm A');
    } else {
      insertTime = moment(insertTime, 'YYYY-MM-DD[T]HH[:]mm[:]00[.000Z]');
    }
    if (insertTime.isValid()) {
      return insertTime;
    }
    return null;
  }

  showAllColumns(event) {
    this.showAll = event.target.checked;
    setTimeout(() => {
      (this.datatableInstance as any).columns.adjust();
    }, 200);
    this.loadAllProjects();
  }

  // getAllCompleted(event) {
  //   this.showAllCompleted = event.target.checked;
  //   setTimeout(() => {
  //     (this.datatableInstance as any).columns.adjust();
  //   }, 200);
  //   this.pageNumber = 0;
  //   this.loadAllProjects();
  // }

  // getDraftProject(event){
  //   this.showDraftProject = event.target.checked;
  //   setTimeout(() => {
  //     (this.datatableInstance as any).columns.adjust();
  //   }, 200);
  //   this.pageNumber = 0;
  //   this.loadAllProjects();
  // }
  manageRole() {
    switch (parseInt(this.currentUser.user_type, 10)) {
      case 1:
        this.role = 'Superadmin';
        break;
      case 2:
        this.role = 'Client';
        break;
      default:
        this.role = 'Influencer';
        break;
    }
  }

  loadAllProjects(search = '', orderBy= {'column': 'created_at', 'dir': 'desc'}, id = null) {
    let option: any = {includePagination: true,
      ...((this.selectedStatus !== 'all' && this.selectedStatus !== null) ? {project_status: this.selectedStatus} : {}),
      orderBy};
    if (this.showAll || !this.isAdmin) {
      option = {total_influencer_count: true, include_totals: true, includeEmailProcess: true, ...option};
    }
    // if (this.selectedStatus == 'show_completed_projects') {
    //   option = {show_completed_projects: true, ...option};
    // } else if (this.selectedStatus == 'show_draft_project'){
    //   option = {show_draft_project: true, ...option};
    // } else if (this.selectedStatus == 'show_active_project' && this.isAdmin) {
    //   option = {show_active_project: true, ...option};
    // }
    this.projectStore.dispatch(ProjectList({
      params: {
        options: JSON.stringify(option),
        page: this.pageNumber + 1,
        ...((this.selectedClient !== 'All' && this.selectedClient !== null) ? {client_id: this.selectedClient} : {}),
        ...(id ? {id: id} : {}),
        perPage: this.pageLength,
        search,
      }
    }));
  }

  // loadAllClients(search = '', orderBy= {'column': 'name', 'dir': 'asc'}) {
  //   this.clientStore.dispatch(ClientsListAll({
  //     params: {
  //       options: JSON.stringify({orderBy}),
  //       search,
  //     }
  //   }));
  // }

  getTotalInfluencersCount(influencersCount) {
    let totalCount = 0;
    for (const count in influencersCount) {
      if (influencersCount.hasOwnProperty(count)) {
        totalCount += Number(influencersCount[count]['total']);
      }
    }
    return totalCount;
  }

  projectList(event = null) {
    this.selectedClient = 'All';
    if (event) {
      this.selectedClient = event.value;
    } else {
      this.planners = []
      this.loadAllPlanners();
    }
    this.projectListFilter = JSON.parse(localStorage.getItem('projectFilterList')) ? JSON.parse(localStorage.getItem('projectFilterList')) : {};
    // this.projectListFilter['selectedClient'] = this.selectedClient;
    localStorage.setItem('projectFilterList', JSON.stringify(this.projectListFilter));
    this.loadAllProjects();
    }

  onChangeProjectStatus(status) {
    this.selectedStatus = status;
    this.loadAllProjects();
  }
  deleteProject(projectId: number) {
    Swal({
      title: 'Are you sure?',
      type: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Yes',
      cancelButtonText: 'No'
    }).then(result => {
      if (result.value) {
        this.deleteAction = true;
        this.projectStore.dispatch(ProjectDelete({projectId}));
      }
    });
  }

  copyProject(projectId: number) {
    this.copyAction = true;
    const modelRef: any = this._modalService.open(ProjectCopyComponent, {centered: true});
    modelRef.componentInstance.clients = this.planners;
    this.loader$ = null;
    modelRef.result.then((result) => {
      const body = {
        title: result.title,
        client_id: result.client_id,
        options: {
          with_influencers: result.with_influencers
        }
      };
      this.projectStore.dispatch(ProjectCopy({projectId, body}));
    }, (reason) => {
    });
  }

  getStatus(project) {
    const projectEnd = moment(project.end_date, 'YYYY-MM-DD').diff(moment(), 'days');
    if (!project.start_date) { return 1; }
    if (!project.end_date) { return 1; }
    return projectEnd > 0;
  }

  getActiveStatus(project) {
    const projectStart = project.start_date ? moment().diff(moment(project.start_date, 'YYYY-MM-DD'), 'days') : -1;
    return projectStart >= 0 ? 'Active' : 'Draft';
  }

  changeViewType(type) {
    this.viewType = type;
    this.projectListFilter = JSON.parse(localStorage.getItem('projectFilterList')) ? JSON.parse(localStorage.getItem('projectFilterList')) : {};
    // this.projectListFilter['selectedClient'] = this.selectedClient;
    this.projectListFilter['projectViewType'] = this.viewType;
    this.cdr.detectChanges();
    this.loadAllProjects(this.projectSearchValue , {'column': 'created_at', 'dir': 'desc'});
    localStorage.setItem('projectFilterList', JSON.stringify(this.projectListFilter));
  }

  projectListByClient(client) {
    // this.projectListFilter = JSON.parse(localStorage.getItem('projectFilterList')) ? JSON.parse(localStorage.getItem('projectFilterList')) : {};
    // if (this.projectListFilter.hasOwnProperty('selectedClient') && this.projectListFilter['selectedClient'] !== 'All') {
    //   this.selectedClient = 'All';
    //   this.clientPlaceholder = 'Search by Clients';
    // } else {
    //   this.clientPlaceholder = client.name;
    //   this.selectedClient = client.id;
    // }
    if (this.selectedClient) {
      this.clientPlaceholder = client.name;
      this.selectedClient = client.id;
    } else {
      this.selectedClient = 'All';
      this.clientPlaceholder = 'Search by Clients';
    }
    // this.projectListFilter['selectedClient'] = this.selectedClient;
    // this.projectListFilter['selectedClientName'] = this.clientPlaceholder;
    localStorage.setItem('projectFilterList', JSON.stringify(this.projectListFilter));
    this.loadAllProjects();
  }

  getReferralsRatePercentage(project) {
    if (project && project.referrals_count) {
      if (project.pageviews_count > 0) {
        return ((project.referrals_count / project.pageviews_count) * 100).toFixed(2);
      }
      return 0;
    } else {
      return 0;
    }
  }

  getOpenClickRate(project) {
    if (project && project.emailProgress) {
      if (project.emailProgress.clicked > 0) {
        return ((project.emailProgress.clicked / project.emailProgress.sent) * 100).toFixed(2);
      }
      return 0;
    } else {
      return 0;
    }
  }

  async onSearchProjectList() {
    this.selectedClient = 'All';
    this.projectListFilter = JSON.parse(localStorage.getItem('projectFilterList')) ? JSON.parse(localStorage.getItem('projectFilterList')) : {};
    // this.projectListFilter['selectedClient'] = this.selectedClient;
    localStorage.setItem('projectFilterList', JSON.stringify(this.projectListFilter));
    this.loadAllProjects(this.projectSearchValue, {'column': 'created_at', 'dir': 'desc'});
  }

  // async onSearchClientList(event) {
  //   const search = event.target.value;
  //   this.loadAllClients(search, {'column': 'name', 'dir': 'asc'});
  // }

  onClientSelect(client) {
    this.selectedClient = client.id;
    this.clientPlaceholder = client.name;
    this.projectListFilter = JSON.parse(localStorage.getItem('projectFilterList')) ? JSON.parse(localStorage.getItem('projectFilterList')) : {};
    // this.projectListFilter['selectedClient'] = this.selectedClient;
    // this.projectListFilter['selectedClientName'] = this.clientPlaceholder;
    localStorage.setItem('projectFilterList', JSON.stringify(this.projectListFilter));
    this.loadAllProjects();
    this.planners = [];
  }

  onClearClientFilter() {
    this.cdr.detectChanges();
    this.selectedClient = 'All';
    this.clientPlaceholder = 'Search by Clients';
    // this.projectListFilter = JSON.parse(localStorage.getItem('projectFilterList')) ? JSON.parse(localStorage.getItem('projectFilterList')) : {};
    // this.projectListFilter['selectedClient'] = this.selectedClient;
    // // this.projectListFilter['selectedClientName'] = this.clientPlaceholder;
    // localStorage.setItem('projectFilterList', JSON.stringify(this.projectListFilter));
    this.loadAllProjects();
    this.planners = [];
  }

  onProjectSelect(project) {
    this.cdr.detectChanges();
    // this.selectedStatus = 'All';
    // this.statusPlaceholder = 'All';
    // this.projectListFilter['selectedProjectStatus'] = this.selectedStatus;
    localStorage.setItem('projectFilterList', JSON.stringify(this.projectListFilter));
    this.selectedClient = 'All';
    // this.projectListFilter['selectedClient'] = this.selectedClient;
    localStorage.setItem('projectFilterList', JSON.stringify(this.projectListFilter));
    this.projectSearchValue = '';
    this.projectPlaceholder = project === 'all' ? 'All Projects' : project.title;
    this.projectListFilter['projectViewType'] = this.viewType;
    localStorage.setItem('projectFilterList', JSON.stringify(this.projectListFilter));
    this.loadAllProjects('', {'column': 'created_at', 'dir': 'desc'},  project.id);
    this.projectListFilter['selectedProjectId'] = project.id;
    localStorage.setItem('projectFilterList', JSON.stringify(this.projectListFilter));
  }

  handleSearchValue(searchTerm) {
    this.loader$ = null;
    this.clientSearchValue = searchTerm.term;
    this.planners = [];
    this.loadAllPlanners(searchTerm.term);
  }

  handleScroll() {
    this.loader$ = null;
    if (this.planners.length < this.totalClients) {
      this.loadAllPlanners(this.clientSearchValue, this.clientPageNumber);
    }
  }

  loadAllPlanners(search = '', page = 0, perPage = 10, orderBy= {'column': 'name', 'dir': 'asc'}, fields = ['id', 'name']) {
    this.clientPageNumber = page + 1;
    this.clientStore.dispatch(ClientsList({
      params: {
        options: JSON.stringify({includePackage: true, includePagination: true, orderBy, fields}),
        page: this.clientPageNumber,
        perPage,
        search
      }
    }));
  }

  ngOnDestroy() {
    this.unsubscriber.next();
    this.unsubscriber.complete();
  }

}
