 <div class="container-fluid pt-3 new-styles">
  <div class="rightBody">
    <div class="card card-box mb-5">
      <div class="card-header py-3">
        <div class="card-header--title trial-register font-size-lg d-flex align-items-center w-100">
          Manage Clients
          <div class='d-flex ml-4 align-items-center trail-register-input w-50'>
            <label class='d-inline'> Trial Register For New Users</label>
            <input  class="form-control" [value]='trialRegister()' disabled
                   type="text"/>
            <i class="fa fa-copy" aria-hidden="true" (click)="copyLinkToClipboard()"></i>
          </div>
        </div>
        <div class="card-header--actions">
          <button class="btn btn-primary btn-sm" type="button" [routerLink]="['/clients/add']" role="button">
            <span class="btn-wrapper--icon"><i class="fa fa-plus"></i></span>
            <span class="btn-wrapper--label">Add New Client</span>
          </button>
        </div>
      </div>
      <div class="row m-0">
        <div class="d-flex flex-wrap px-4 py-3">
          <div class="package-dropdown">
            <app-creatable-select
              [hideLabel]="true" [isCreatable]="false" [onlyBottomBorder]="false"
              [placeholder]="'Select Package'" [selectOptions]="packages"
              (optionSelected)="clientListByPackage($event)"></app-creatable-select>
          </div>
        </div>
      </div>

      <div class="sticky-dropdown-custom-table p-3">
        <table width="100%" class="sb-table table dataTable no-footer nowrap" [dtTrigger]="dtTrigger" datatable
               [dtOptions]="dtOptions" id="dt-table">
          <thead>
          <tr>
            <th class="no-sort client-logo">Logo</th>
            <th>Name</th>
            <th class="no-sort">Email</th>
            <th class="no-sort">Package</th>
            <th class="no-sort">Package Start Date</th>
            <th class="no-sort">Package End Date</th>
            <th>Created date</th>
            <th class="no-sort">Projects</th>
            <th class="no-sort"></th>
          </tr>
          </thead>
          <tbody *ngIf="clients.length > 0">
          <tr *ngFor="let client of clients">
            <td>
              <img class="logo-thumbnail" [src]="client.logo" alt=""/>
            </td>
            <td class="word-break">{{client.name}}</td>
            <td>{{client.email || ''}}</td>
            <td>{{client.package?.name || ''}}</td>
            <td>{{formatMomentDate(client.package_start_date)}}</td>
            <td>{{formatMomentDate(client.package_end_date)}}</td>
            <td>{{formatMoment(client.created_at)}}</td>
            <td class="cursor-pointer" (click)="onClickProjects(client)">
              <span>Projects</span>
            </td>
            <td>
              <button class="btn-icon float-right" mat-icon-button [matMenuTriggerFor]="menu"
                      aria-label="Example icon-button with a menu">
                <span class="fa fa-ellipsis-v"></span>
              </button>
              <mat-menu #menu="matMenu" class="campaign-popover">
                <a class="dropdown-item" [routerLink]="['/clients/edit', client.id]">
                  <i class="fa fa-pencil mr-2"></i>Edit</a>
                <a class="dropdown-item" (click)="deleteClient(client)">
                  <i class="fa fa-trash mr-2"></i>Delete</a>
              </mat-menu>
            </td>
          </tr>
          </tbody>
          <tbody *ngIf="clients?.length <= 0">
          <tr>
            <td colspan="3" class="no-data-available">No data available in table</td>
          </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</div>
