import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {RouterModule} from '@angular/router';
import * as routes from '@app/routes';
import {PageDesignerComponent} from '@app/_components/page-designer/page-designer.component';

@NgModule({
  imports: [
    CommonModule,
    RouterModule
  ],
  declarations: [
    PageDesignerComponent
  ],
  exports: [
    PageDesignerComponent
  ]
})
export class PageDesignerModule {
  routes = routes;
}
