import {Variable} from '@app/modules/shared/models';

export interface IVariableState {
  variable: Variable;
  variables: Variable[];
  success: string;
  error: string;
}

export const initialVariableState: IVariableState = {
  variable: null,
  variables: [],
  success: '',
  error: ''
};
