import {ReportModel} from '@app/modules/shared/models';

export interface IReportState {
  report: ReportModel;
  reportPassword: string;
  success: string;
  error: string;
  projectsReportAdvocatesExport: any;

}

export const initialReportState: IReportState = {
  report: null,
  reportPassword: localStorage.getItem('reportPassword') || '',
  success: '',
  error: '',
  projectsReportAdvocatesExport: null
};
