import { Component, OnInit }                   from '@angular/core';
import { InfluencerApiService } from '@app/services/api/influencer-api.service';
import { ActivatedRoute, Router } from "@angular/router";
import {select, Store} from '@ngrx/store';
import {getLoggedInUser, IAuthenticationState} from '@app/stores';

@Component ({
  selector   : 'app-share-event',
  templateUrl: './share-project.component.html',
  styleUrls  : [ './share-project.component.css' ]
})
export class ShareProjectComponent implements OnInit {
  currentUser: any;
  projectId: any;
  shareInfo: any;
  personalizedMessage: string;
  title: string;
  description: string;
  landingPageUrl: string;
  image: string;
  emailTitle: string;
  emailBody: string;

  constructor(
    private authenticationStore: Store<IAuthenticationState>,
    private influencersApiService: InfluencerApiService,
    private activatedRoute: ActivatedRoute,
  ) {
    this.authenticationStore.pipe(select(getLoggedInUser)).subscribe(user => {
        this.currentUser = user;
        this.getInfluencerInfo();
      }
    );
    this.activatedRoute.params.subscribe(params => {
      this.projectId = params.projectId;
        this.getInfluencerInfo();
    });
  }

  ngOnInit() {
  }

  getInfluencerInfo() {
    if (!this.projectId || !this.currentUser) return;
    if (this.shareInfo) return;
    const user = this.currentUser.user;
    const influencer = user.influencers.find(i => i.segment.project_id == this.projectId);
    if (!influencer) return;
    this.influencersApiService.getShareInfo(influencer.id).subscribe(response => {
      if (response.body.Success) {
        this.personalizedMessage = response.body.personalized_message;
        this.title = response.body.landing_page.meta.title;
        this.description = response.body.landing_page.meta.description;
        this.image = response.body.landing_page.meta.image;
        this.landingPageUrl = response.body.landing_page_url;
        this.emailTitle = response.body.email.email_title;
        this.emailBody = response.body.email.email_body;
      }
    });
  }

  facebookURL() {
    return `https://www.facebook.com/sharer/sharer.php?u=${this.landingPageUrl}`;
  }

  twitterURL() {
    return `https://twitter.com/intent/tweet?url=${this.landingPageUrl}&text=${this.personalizedMessage}`;
  }

  linkedinURL() {
    return `https://www.linkedin.com/sharing/share-offsite/?url=${this.landingPageUrl}`;
  }

  whatsappURL() {
    return `https://www.linkedin.com/sharing/share-offsite/?url=${this.landingPageUrl}`;
  }

  slackURL() {
    return `https://www.linkedin.com/sharing/share-offsite/?url=${this.landingPageUrl}`;
  }

  emailURL() {
    return `mailto:?subject=${this.emailTitle}&body=${this.emailBody}`;
  }
}
