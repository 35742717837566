<div class="modal-header">
  <h4 class="modal-title" id="modal-title">Match Column Labels</h4>
  <div class=" d-flex justify-content-end">
    <button type="button" aria-label="Close button" class="close" aria-describedby="modal-title"
            (click)="modal.dismiss('cancel')">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
</div>
<div class="modal-body">
  <div class="custom-table match-column-table p-3" id="custom-table-id">
    <table width="100%" class="sb-table table dataTable no-footer nowrap" datatable
          [dtTrigger]="dtTrigger" [dtOptions]="dtOptions" id="dt-table">
            <thead>

            <tr>
              <th *ngFor="let fh of csvFormatHeaders; index as i">
                  {{fh.name}}
                <i class="fa fa-arrow-down" aria-hidden="true"></i>
              </th>
            </tr>

            </thead>
            <tbody *ngIf="influencersImportPage.length > 0">
            <tr class='match-column-tr'>
              <td *ngFor="let header of formatMatchedHeaders; index as i"
                  [ngClass]="{'wrong_match': matchedHeaders[i] == '','skipped_field': matchedHeaders[i] == 'skip'}">
                <!--                 <app-creatable-select class="header-color"
                                  [isCreatable]="false"
                                  [clearable]="false"
                                  [placeholder]="'Select influencer type'"
                                  [onlyBottomBorder]="false"
                                  (optionSelected)="changeHeader($event, i)"
                                  [selectedValue]="header.id"
                                  [selectOptions]="copyFormatFields" [hideLabel]="true">
                                </app-creatable-select> -->

                <ng-select class="header-color"
                           [ngModel]="header.id" [placeholder]="'Select influencer type'"
                           [clearable]="false"
                           [items]="copyFormatFields"
                           (change)="changeHeader($event, i)"
                           (open)="filterSelectedFields()"
                           bindLabel="name"
                           bindValue="id" >
                </ng-select>

              </td>
            </tr>


            <tr *ngFor="let inf of influencersImportPage">
                <td *ngFor="let header of formatHeaders">
                  {{inf[header.id]}}
                </td>
              </tr>

            </tbody>
            <tbody *ngIf="influencersImportPage.length <= 0">
              <tr>
                <td colspan="5" class="no-data-available">No data available in table</td>
              </tr>
            </tbody>
    </table>
  </div>
</div>
<input type="hidden" ngbAutofocus>
<div class="modal-footer">
  <button class="btn btn-primary" type="button" (click)="upload()">
    Upload
  </button>
  <button class="btn btn-outline-primary" type="button" (click)="modal.dismiss('cancel')">
    Close
  </button>
</div>
