import { Component, Input, OnInit, OnDestroy } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, UntypedFormArray, Validators, FormControl } from '@angular/forms';
import { days, reportTiming, schedule} from '@app/modules/statistics/data/share-report-data'
import { ClipboardService } from 'ngx-clipboard';
import { ToastrService } from 'ngx-toastr';
import {
  getCreateOrCopySuccess,
  getProject,
  getProjectError, getProjectSuccess, getProjectSuccessResponse,
  getReportError,
  getReportPassword,
  IProjectState,
  IReportState,
  ProjectGet,
  ProjectNotificationUpdate,
  ProjectUpdate,
  ProjectUpdateSuccess,
  ReportPassword, ResetProjectState
} from '@app/stores';
import { select, Store } from '@ngrx/store';
import { takeUntil } from 'rxjs/operators';
import { BehaviorSubject, Subject } from 'rxjs';
import { Constants } from '@app/consts';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
@Component({
  selector: 'app-share-report-modal',
  templateUrl: './share-report-modal.component.html',
  styleUrls: ['./share-report-modal.component.scss'],
  providers: [Constants]
})
export class ShareReportModalComponent implements OnInit, OnDestroy {
  @Input() projectId;
  @Input() selectedProject;
  checked$ = new BehaviorSubject('false');
  checked;
  showSendEmail = false;
  isCopyLink = false;
  isDownload = false;
  isDisabled = true;
  isDailySelected = false;
  submitted = false;
  isDailySelected$ = new BehaviorSubject(false);
  isShareEnabled$ = new BehaviorSubject('false');
  isShareEnabled;
  isNotificationButtonClicked = false;
  isNotificationSet:boolean;
  isNotificationSet$ = new BehaviorSubject(false);
  notificationStatus = '';
  shareReportsForm: UntypedFormGroup;
  passwordForm: UntypedFormGroup;
  recipients: UntypedFormArray;
  timing: string;
  schedule: string;
  reportTimingOptions$= new BehaviorSubject(reportTiming);
  scheduleOptions$ = new BehaviorSubject(days);
  reportTimingOptions: any;
  scheduleOptions: any;
  formLenght$ = new BehaviorSubject(1);
  formLength: number;
  link: string;
  enableSharingToggleText: string;
  unsubscriber = new Subject();
  project;
  projectTimeZone: any;
  selectedProjectTimeZone: any = null;
  selectedSchedule: any;
  projectSchedule: any = {};

  constructor(
    private formBuilder: UntypedFormBuilder,
    private clipboardService: ClipboardService,
    private toastr: ToastrService,
    private projectStore: Store<IProjectState>,
    private reportStore: Store<IReportState>,
    public constants: Constants,
    public modal: NgbActiveModal,
  ) {
    this.projectStore.dispatch(ResetProjectState({params: {error: '', success: ''}}));
  }

  ngOnInit(): void {
    this.projectStore.dispatch(ProjectGet({projectId: this.projectId}));
    this.link = window.location.href;
    this.initiSubs();
    this.subscribeStore();
    // this.passwordForm.patchValue({password: this.project.password});
  }

  initiSubs(): void{
    this.shareReportsForm = this.formBuilder.group({
      timezone: null,
      recipients: this.formBuilder.array([ this.createRecipient() ])
    });

    this.passwordForm = this.formBuilder.group({
      password: ['', [Validators.required]]
    });

    this.isShareEnabled$.subscribe((val)=>{
      this.isShareEnabled = (val === 'true')
    })
    this.isNotificationSet$.subscribe((val)=>{
      this.isNotificationSet = val
    })

    this.checked$.subscribe((val)=>{
      this.checked = (val === 'true')
      this.setText(this.checked)
      this.isShareEnabled$.next(val);
    })

    this.reportTimingOptions$.subscribe((val)=>{
      this.reportTimingOptions = val
    })

    this.scheduleOptions$.subscribe((val)=>{
      this.scheduleOptions = val
    })

    this.isDailySelected$.subscribe((val)=>{
      this.isDailySelected = val
    })

    this.formLenght$.subscribe((val)=>{
      this.formLength = val
    })
  }

  get shareReportsFormValue(){
    return this.shareReportsForm.value;
  }

  get f() {
    return this.shareReportsForm.controls;
  }

  get getPasswordControl() {
    return this.passwordForm.controls;
  }

  get getPasswordValue() {
    return this.passwordForm.value;
  }

  setShareReportPassword() {
    this.submitted = true;

    // stop here if form is invalid
    if (this.passwordForm.invalid) {
      return;
    }

    const params = {
      password: this.getPasswordValue.password
    };
    this.projectStore.dispatch(ProjectUpdate({project: params, projectId: this.projectId}));
  }

  createRecipient(): UntypedFormGroup {
    return this.formBuilder.group({
      email: ['',[Validators.required,Validators.email]],
    });
  }

  addRecipient(): void {
    this.recipients = this.shareReportsForm.get('recipients') as UntypedFormArray;
    this.recipients.push(this.createRecipient());
  }

  removeRecipient(recipient): void {
    this.recipients = this.shareReportsForm.get('recipients') as UntypedFormArray;
    this.formLenght$.next(this.recipients.length)
    if(this.recipients.length === 1) {
      return;
    }
    this.recipients.removeAt(recipient);
  }

  get recipientsList(): UntypedFormArray {
    return this.shareReportsForm.get('recipients') as UntypedFormArray;
 }

  copyLink(): void {
    this.isCopyLink = true;
    this.showSendEmail = false;
    this.isDownload = false;
  }

  copyLinkToClipboard(): void {
    this.clipboardService.copyFromContent(this.link)
    this.toastr.success('Link is Copied');
  }

  download(): void {
    this.isDownload = true;
    this.isCopyLink = false;
    this.showSendEmail = false;
  }

  emailReportUpdate(): void {
    this.showSendEmail = true;
    this.isCopyLink = false;
    this.isDownload = false;
  }

  // toggleSharing(e) {
  //   const toggle = this.checked.toString();
  //   this.projectStore.dispatch(ProjectNotificationUpdate({project: {'notification_enabled': toggle}, projectId: this.projectId}));
  // }

  timingSelected(event): void {
    if (event) {
      this.isDisabled = false;
      this.timing = event?.value ? event?.value : this.timing;
      (this.timing === 'Daily') ? this.isDailySelected$.next(true) : this.isDailySelected$.next(false);
    } else {
      this.isDisabled = true;
      this.isDailySelected$.next(false);
    }

    this.schedule = '';
    if (event && event.value === 'Daily') {
      this.scheduleOptions$.next(Array.from({length: 24}, (_, i) => {
        if(i>9){
          return `${i}:00`;
        }else{
          return `0${i}:00`;
        }
      }))
      this.shareReportsForm.patchValue({timezone: this.selectedProjectTimeZone});
    }

    if (event && event.value === 'Weekly') {
      this.scheduleOptions$.next(days)
    }
    if (event && event.value === 'Monthly') {
      const date = new Date();
      let numOfDays = new Date(date.getFullYear(), date.getMonth(), 0).getDate();
      this.scheduleOptions$.next(Array.from({length: numOfDays}, (_, i) => `${i + 1}`))
    }
  }

  scheduleSelected(event): void {
    if (event) {
      this.schedule = event.value;
      this.selectedSchedule = event?.value ? event?.value : this.selectedSchedule;
    }
  }

  setNotification(): void {
    this.isNotificationButtonClicked = true;
    this.notificationStatus = 'SET';
    if(this.shareReportsForm.get('recipients').invalid) {
      this.toastr.error('Please enter valid email address', 'Error');
      return;
    }
    if (!this.timing || !this.schedule) {
      this.toastr.error('Please fill the timing and schedule fields', 'Error');
      return;
    }
    const recipients = this.shareReportsForm.get('recipients').value.map((val) => {
      if (val.email) {
        return val.email;
      } else {
        return;
      }
    });

    if (recipients.length < 1) {
      this.toastr.error('Please add recipients', 'Error');
      return;
    }

    let mSchedule;
    if (this.timing === 'Weekly') {
      mSchedule = days.indexOf(this.schedule);
      this.shareReportsForm.patchValue({timezone: this.selectedProjectTimeZone});
    }
    if (this.timing === 'Monthly') {
      mSchedule = parseInt(this.schedule, 10);
      this.shareReportsForm.patchValue({timezone: this.selectedProjectTimeZone});
    }
    if (this.timing === 'Daily') {
      mSchedule = parseInt(this.schedule.substring(0, 2), 10);
      if (!this.shareReportsFormValue.timezone) {
        this.toastr.error('Please select timezone', 'Error');
        return;
      }
    }
    const settings = {
        'report_timing': this.timing,
        'report_schedule': mSchedule,
        'timezone': this.shareReportsForm.get('timezone').value?.nameValue || this.selectedProjectTimeZone,
        'set_notification': true,
        recipients
    };
    this.projectStore.dispatch(ResetProjectState({params: {error: '', success: ''}}));
    this.projectStore.dispatch(ProjectNotificationUpdate({project: {'notification_setting': settings}, projectId: this.projectId}));
  }

  stopNotification(): void {
    this.isNotificationButtonClicked = true;
    this.notificationStatus = 'STOP';
    // const settings = this.projectSchedule ? Object.assign(this.projectSchedule) : {};
    const {recipients, report_timing, timezone, report_schedule } = this.projectSchedule;
    const settings = {
      recipients,
      report_timing,
      timezone,
      report_schedule,
      set_notification: false
    };
    this.projectStore.dispatch(ResetProjectState({params: {error: '', success: ''}}));
    this.projectStore.dispatch(ProjectNotificationUpdate({project: {'notification_setting': settings}, projectId: this.projectId}));
    // this.shareReportsForm.reset();
    // this.timing = null;
    // this.isDisabled = true;
    // this.selectedSchedule = null;
  }

  setText(isChecked): void {
    this.enableSharingToggleText = !isChecked? 'Enable Sharing' : 'Disable Sharing'
  }

  subscribeStore(): void {
    this.projectStore.pipe(select(getProjectError))
    .pipe(takeUntil(this.unsubscriber))
    .subscribe(error => {
      if (error) {
        this.toastr.error(error, 'Error');
      }
    });

    // this.projectStore.pipe(select(getProjectSuccess))
    //   .pipe(takeUntil(this.unsubscriber))
    //   .subscribe(success => {
    //     if (success && !this.isNotificationSet && this.isNotificationButtonClicked) {
    //       this.toastr.success('Report Notification Schedule Set Successfully!');
    //       this.isNotificationButtonClicked = false;
    //     }
    //     if (success && this.isNotificationSet && this.isNotificationButtonClicked) {
    //       this.toastr.success('Report Notification Schedule Stop Successfully!');
    //       this.isNotificationButtonClicked = false;
    //     }
    //   });

    this.projectStore.pipe(select(getProject))
    .pipe(takeUntil(this.unsubscriber))
    .subscribe(project => {
      if (project) {
        this.project = project;
        this.projectSchedule = (typeof this.project.notification_setting === 'string') ? JSON.parse(this.project.notification_setting) : this.project.notification_setting;
        if (this.projectSchedule) {
          if (this.projectSchedule.hasOwnProperty('report_timing')) {
            this.timing = this.projectSchedule.report_timing;
            this.isDisabled = false;
          }
        }
        this.passwordForm.patchValue({password: this.project.password});
        this.checked$.next(project?.notification_enabled);
        if(!project?.notification_enabled) {
          this.showSendEmail = false;
          this.isCopyLink = false;
          this.isDownload = false;
        }
        if (this.project?.timezone) {
          this.projectTimeZone = this.project?.timezone;
          this.selectedProjectTimeZone = this.projectTimeZone;
        }
        if(this.project?.notification_setting){
          this.setFormFieldValues(this.projectSchedule?.timezone, this.projectSchedule?.recipients, this.projectSchedule?.report_timing, this.projectSchedule?.report_schedule)
          this.isNotificationSet$.next(this.projectSchedule?.set_notification);
        }
        else{
          this.shareReportsForm.patchValue({timezone: this.selectedProjectTimeZone});
        }
      }

    });

    this.projectStore.pipe(select(getCreateOrCopySuccess))
      .pipe(takeUntil(this.unsubscriber))
      .subscribe(success => {
        if (success) {
          const toggleState = this.checked === true ? 'true' : 'false';
          this.checked$.next(toggleState);
          if (this.notificationStatus === 'SET') this.isNotificationSet$.next(true);
          if (this.notificationStatus === 'STOP' || this.notificationStatus === '') this.isNotificationSet$.next(false);
        }
      });

    this.projectStore.pipe(select(getProjectSuccessResponse))
      .pipe(takeUntil(this.unsubscriber))
      .subscribe(updateProject => {
        if (updateProject && (this.notificationStatus === 'SET' || this.notificationStatus === 'STOP')) {
          this.project = updateProject;
          this.projectSchedule = this.project.notification_setting;
          if (this.project?.notification_setting){
            this.setFormFieldValues(this.projectSchedule?.timezone, this.projectSchedule?.recipients, this.projectSchedule?.report_timing, this.projectSchedule?.report_schedule)
            this.isNotificationSet$.next(this.project?.notification_setting.set_notification);
          }
          else {
            this.shareReportsForm.patchValue({timezone: this.selectedProjectTimeZone});
          }
          if (updateProject && this.isNotificationSet && this.isNotificationButtonClicked) {
            this.toastr.success('Report Notification Schedule Set Successfully!');
            this.isNotificationButtonClicked = false;
          }
          if (updateProject && !this.isNotificationSet && this.isNotificationButtonClicked) {
            this.toastr.success('Report Notification Schedule Stop Successfully!');
            this.isNotificationButtonClicked = false;
          }
        }
      });

      this.reportStore.pipe(select(getReportError))
      .pipe(takeUntil(this.unsubscriber))
      .subscribe(error => {
        if (error) {
          this.toastr.error(error, 'Error');
        }
      });

      // this.projectStore.pipe(select(ProjectUpdateSuccess))
      // .pipe(takeUntil(this.unsubscriber))
      // .subscribe(res => {
      //   if (res && this.submitted) {
      //   }
      // });
  }

  ngOnDestroy() {
    this.submitted = false;
  }

  setFormFieldValues(timezone, recipients, timing, scheduleIndex) {
    if (!this.projectSchedule) {
      this.shareReportsForm.reset();
      this.timing = null;
      this.selectedSchedule = null;
      return;
    }
    this.timing = timing;
    if (timing) {
      this.isDisabled = false;
      if (timing === 'Daily') {
        this.scheduleOptions$.next(Array.from({length: 24}, (_, i) => {
          if (i > 9) {
            return `${i}:00`;
          } else {
            return `0${i}:00`;
          }
        }));
        scheduleIndex = parseInt(scheduleIndex.substring(0, 2), 10);
        this.schedule = schedule[scheduleIndex];
        this.isDailySelected$.next(true);
        if (!timezone) {
          this.shareReportsForm.patchValue({timezone: this.selectedProjectTimeZone});
        } else {
          this.shareReportsForm.patchValue({timezone});
        }
        this.scheduleOptions = this.scheduleOptions.map((x, i) => {
          return {
            'value': i.toString(),
            'name': x
          };
        });
        this.selectedSchedule = this.scheduleOptions[scheduleIndex];
      }

      if (timing === 'Weekly') {
        this.scheduleOptions$.next(days);
        this.schedule = days[scheduleIndex];
        this.scheduleOptions = this.scheduleOptions.map((x, i) => {
          return {
            'value': i.toString(),
            'name': x
          };
        });
        this.selectedSchedule = this.scheduleOptions[scheduleIndex];
      }
      if (timing === 'Monthly') {
        const date = new Date();
        let numOfDays = new Date(date.getFullYear(), date.getMonth(), 0).getDate();
        this.scheduleOptions$.next(Array.from({length: numOfDays}, (_, i) => `${i + 1}`));
        this.schedule = scheduleIndex;
        this.scheduleOptions = this.scheduleOptions.map((x, i) => {
          return {
            'value': i.toString(),
            'name': x
          };
        });
        this.selectedSchedule = this.scheduleOptions[scheduleIndex - 1];
      }
    }
    let recipientsArrayOfObjects = recipients.map((val) => {
      return {email: val};
    });
    recipientsArrayOfObjects.forEach(element => {
      this.createRecipient();
    });

    const recipient = this.shareReportsForm.get('recipients') as UntypedFormArray;
    // empty form array
    while (recipient.length) {
      recipient.removeAt(0);
    }
    this.shareReportsForm.patchValue(recipientsArrayOfObjects);
    // add form array values in a loop
    recipientsArrayOfObjects.forEach(staff => recipient.push(this.formBuilder.group(staff)));
  }
}
