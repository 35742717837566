<div class="modal-header">
  <h4 class="modal-title" id="modal-title">Manage Segments</h4>
  <button type="button" class="close" aria-label="Close button" aria-describedby="modal-title"
          (click)="modal.dismiss('cancel')">
    <span aria-hidden="true">&times;</span>
  </button>
</div>
<div class="modal-body">
  <div class="d-flex flex-wrap px-4 py-3">
    <div class="client-dropdown">
      <app-creatable-select
        [hideLabel]="true" [isCreatable]="false" [onlyBottomBorder]="false" [selectedValue]="selectedInfluencer"
        [placeholder]="'Select influencer type'" [selectOptions]="influencerTypes"
        (optionSelected)="filterSegmentByInfluencerType($event ? $event.value : 'all')"></app-creatable-select>
    </div>
    <div class='d-flex align-items-center ml-auto'>
      <button class="btn btn-primary btn-sm float-right" type="button" (click)="addSegment()" role="button">
        <span class="btn-wrapper--icon"><i class="fa fa-plus"></i></span>
        <span class="btn-wrapper--label">Add New Segment</span>
      </button>
    </div>
  </div>
  <div class="custom-table p-3">
    <table width="100%" class="sb-table table dataTable no-footer nowrap" [dtTrigger]="dtTrigger" datatable
           [dtOptions]="dtOptions" id="dt-table">
      <thead>
      <tr>
        <th>Name</th>
        <th>Influencer Type</th>
        <th></th>
      </tr>
      </thead>
      <tbody *ngIf="segments?.length > 0">
      <tr *ngFor="let segment of segments">
        <td class="segment-name" (click)="selectSegment(segment)">{{segment.name}}</td>
        <td>{{segment.influencer_type?.name}}</td>
        <td>
          <button type="button" class="btn-icon float-right" placement="bottom-right"
                  [autoClose]="true" [ngbPopover]="popTemplate">
            <span class="fa fa-ellipsis-v"></span>
          </button>
          <ng-template #popTemplate>
            <a 
              class="dropdown-item" 
              (click)="openRenameModal(segment)">
              Rename
            </a>
            <a class="dropdown-item" (click)="editSegment(segment)">Edit</a>
            <a class="dropdown-item" (click)="deleteSegment(segment)">Delete</a>
            <a class="dropdown-item" (click)="openImportInfluencersModal(segment)">Import Influencers</a>
          </ng-template>
        </td>
      </tr>
      </tbody>
      <tbody *ngIf="segments?.length <= 0">
      <tr class="no-data-available-custom">
        <td colspan="6">No data available in table</td>
      </tr>
      </tbody>
    </table>
  </div>
</div>
<input type="hidden" ngbAutofocus>
<div class="modal-footer">
  <button type="button" class="btn btn-default" (click)="modal.close()">Close</button>
</div>
