import {
  Component,
  Input,
  OnInit,
  Output,
  ViewEncapsulation,
  EventEmitter, ViewChild, OnChanges, SimpleChanges
} from '@angular/core';
import {NgSelectComponent} from '@ng-select/ng-select';

@Component({
  selector: 'app-auto-complete-select',
  templateUrl: './auto-complete-select.component.html',
  encapsulation: ViewEncapsulation.None,
  styleUrls: ['./auto-complete-select.component.css']
})
export class AutoCompleteSelectComponent {
  @Input() selectOptions = [];
  @Input() selectedValue;
  @Input() labelKey = 'name';
  @Input() valueKey = 'id';
  @Output() optionSelected = new EventEmitter();
  @Output() searchValue = new EventEmitter<string>();
  @Input() isMultiple = false;
  @Input() placeholder = 'Select or Create Segment';
  @Input() labelText = 'Select or Create Segment';
  @Input() addTagText = 'Add Segment';
  @Input() hideLabel = false;
  @Input() onlyBottomBorder = true;
  @Input() isCreatable = true;
  @Input() hideInfo = false;
  @Input() isDisabled = false;
  @Input() clearable = true;
  @Input() containerClass = '';
  @Input() customTemplate;
  @Output() scrollToEnd = new EventEmitter<any>();

  @ViewChild('select') child: NgSelectComponent ;

  constructor() {
    this.compareWith = this.compareWith.bind(this);
  }

  clearSelection() {
    this.child.handleClearClick();
  }

  ngOnInit(): void {
  }

  onSelected(event: any) {
    let option = null;
    if (event) {
      if (this.isMultiple) {
        option = {value: event.map(x => x[this.valueKey]), isNew: !!event.isNew, name: event.name};
      } else {
        // option = {value: event[this.valueKey], isNew: !!event.isNew};
        option = {value: typeof event === 'object' ? event[this.valueKey] : event, isNew: !!event.isNew, name: event.name};
      }
    }
    this.optionSelected.emit(option);
  }

  addTagFn(name) {
    return {name: name, [this.valueKey]: name, isNew: true};
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.selectedValue && changes.selectedValue.currentValue) {
      this.selectedValue = changes.selectedValue.currentValue;
    }
    if (changes.selectOptions && changes.selectOptions.currentValue) {
      this.selectOptions = changes.selectOptions.currentValue;
    }
  }

  compareWith(item, selected): boolean {
    if(typeof item === 'object') return item[this.valueKey].toString() === (selected || '').toString();

    return;
  }

  onClear() {
    this.onSearch('')
  }

  onClose() {
    this.onSearch('')
  }

  onSearch(searchTerm: string) {
    this.searchValue.emit(searchTerm);
  }

  onScroll(): void {
    this.scrollToEnd.emit(true);
  }

  ngOnDestroy() {
    this.selectOptions = [];
  }
}
