import {Component, Input, OnInit} from '@angular/core';
import {NgbActiveModal, NgbModal} from "@ng-bootstrap/ng-bootstrap";

@Component({
  selector: 'app-verification',
  templateUrl: './verification-by.component.html',
  styleUrls: ['./verification-by.component.scss']
})
export class VerificationByComponent implements OnInit {

  @Input() fromEmail;
  @Input() preSelect;
  emailSenderVerifyBy;
  error;

  constructor(
    private _modalService: NgbModal,
    public modal: NgbActiveModal,
  ) {
  }

  ngOnInit(): void {
    if (this.preSelect) {
      this.emailSenderVerifyBy = this.preSelect;
    }
  }

  verifyBySelected() {
    if (!this.emailSenderVerifyBy) {
      this.error = 'Please select a method to verify email';
      return;
    }

    this.modal.close(this.emailSenderVerifyBy);
  }
}
