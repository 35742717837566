import {Injectable} from '@angular/core';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {environment} from '@environments/environment';

@Injectable({providedIn: 'root'})
export class ImpressionService {
  private baseApiUrl = environment.apiUrl;
  private reportUrl = this.baseApiUrl + '/api/v1/project';

  constructor(private http: HttpClient) {
  }

  report(projectId: number, params: any = {}) {
    return this.http.get(`${this.reportUrl}/${projectId}/report`, {params, headers: new HttpHeaders().set('Use-Temp-Token', 'true')});
  }

  exportAdvocatesReport(projectId: number, params: any = {}) {
    return this.http.get(`${this.reportUrl}/report/advocates_export/${projectId}`, {params, headers: new HttpHeaders().set('Use-Temp-Token', 'true')});
  }

  reportPassword(projectId: number, password: string) {
    return this.http.post(`${this.reportUrl}/${projectId}/report/validate_password`, {password}, {
      headers: new HttpHeaders().set('Skip-auth', 'true')
    });
  }
}
