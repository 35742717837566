<div class="container-fluid pt-3">
    <div class="rightBody">
        <!-- Add User Section  Start -->
        <div class="card">
            <div class="card-header py-3">
              <div class="card-header--title font-size-lg">
                Profile
              </div>
            </div>
            <div class="card-body p-0">
                <div *ngIf="submitted && !editUserMessage['Success']" class="error-msg-fail">
                    <p> {{editUserMessage[ 'msg' ]}} </p>
                </div>

                <div *ngIf="submitted && editUserMessage['Success']" class="success-msg-success card custom-card mt-4">
                    <p> {{editUserMessage[ 'msg' ]}} </p>
                </div>

                <div class="form-wraper">
                    <div class="col-md-12">
                        <a (click)="activeForm()" class="edit-btn edit-btn-dev pointer" href="javascript:void(0)"
                           id="editForm">
                            <i class="fa fa-pencil"></i>
                        </a>
                    </div>
                    <form (ngSubmit)="editUser()" [formGroup]="editUserForm" class="needs-validation allEdit"
                          id="basicinfo">
                        <!-- Add User form column start -->
                        <div class="row">
                            <div class="col-lg-6 col-md-6 col-ms-12 right-padding">
                                <div class="floating-label">
                                    <input [ngClass]="{ 'is-invalid': submitted && f.first_name.errors }" class="floating-input" formControlName="first_name"
                                           id="first_name"
                                           required="required"
                                           type="text"/>
                                    <label for="first_name">Fist Name <span class="red">*</span>
                                    </label>

                                    <div *ngIf="submitted && f.first_name.errors" class="error-msg">
                                        <div *ngIf="f.first_name.errors.required">First Name is required</div>
                                    </div>

                                </div>
                            </div>
                            <div class="col-lg-6 col-md-6 col-ms-12 right-padding">
                                <div class="floating-label">
                                    <input [ngClass]=" { 'is-invalid': submitted && f.last_name.errors }" class="floating-input" formControlName="last_name" id="last_name"
                                           required="required"
                                           type="text"/>
                                    <label for="last_name">Last Name <span class="red">*</span>
                                    </label>

                                    <div *ngIf="submitted && f.last_name.errors" class="error-msg">
                                        <div *ngIf="f.last_name.errors.required">Last Name is required</div>
                                    </div>

                                </div>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-lg-6 col-md-6 col-ms-12 right-padding">
                                <div class="floating-label">
                                    <input class="floating-input" formControlName="company" id="company" required="required"
                                           type="text"/>
                                    <label for="company">Company <span class="red">*</span>
                                    </label>

                                    <div *ngIf="submitted && f.company.errors" class="error-msg">
                                        <div *ngIf="f.company.errors.required">company is required</div>
                                    </div>

                                </div>
                            </div>
                            <div class="col-lg-6 col-md-6 col-ms-12 right-padding">
                                <div class="floating-label">
                                    <input [ngClass]="{ 'is-invalid': submitted && f.job_title.errors }" class="floating-input" formControlName="job_title" id="job_title"
                                           required="required"
                                           type="text"/>
                                    <label for="job_title">Job Title <span class="red">*</span>
                                    </label>

                                    <div *ngIf="submitted && f.job_title.errors" class="error-msg">
                                        <div *ngIf="f.job_title.errors.required">Job Title is required</div>
                                    </div>

                                </div>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-lg-6 col-md-6 col-ms-12 right-padding">
                                <div class="floating-label">
                                    <input [ngClass]="{ 'is-invalid': submitted && f.email.errors }" class="floating-input" formControlName="email"
                                           required="required"
                                           type="text"/>
                                    <label for="email">Email <span class="red">*</span>
                                    </label>
                                    <div class="bar"></div>
                                    <div *ngIf="submitted && f.email.errors" class="error-msg">
                                        <div *ngIf="f.email.errors.required">Email is required</div>
                                        <div *ngIf="f.email.errors.email">Username must be a valid email address</div>
                                    </div>
                                </div>
                            </div>
                            <div class="col-lg-6 col-md-6 col-ms-12 right-padding">
                                <div class="floating-label">
                                    <input [ngClass]="{ 'is-invalid': submitted && f.phone.errors }" class="floating-input" formControlName="phone" id="phone"
                                           placeholder=" "
                                           type="text"/>
                                    <label for="phone">Phone
                                    </label>
                                    <div class="bar">
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-lg-6 col-md-6 col-ms-12 right-padding">
                                <div class="floating-label">
                                    <input [ngClass]="{ 'is-invalid': submitted && f.website.errors }" class="floating-input" formControlName="website" placeholder=" "
                                           type="text"/>
                                    <label for="website">Website
                                    </label>
                                    <div class="bar">
                                    </div>
                                </div>
                            </div>
                            <div class="col-lg-6 col-md-6 col-ms-12 right-padding">
                                <div class="floating-label">
                                    <input [ngClass]="{ 'is-invalid': submitted && f.project.errors }" class="floating-input" formControlName="project" placeholder=" "
                                           type="text"/>
                                    <label for="project">Project
                                    </label>
                                    <div class="bar">
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div *ngIf="updatedUser.user_type == 1; else noadmin" class="row">
                            <div class="col-lg-6 col-md-6 col-ms-12">
                                <div class="floating-label">
                                    <select class="floating-select" formControlName="role"
                                        onchange="this.setAttribute('value', this.value);" required="required">
                                        <option *ngFor="let role of roles" [value]="role.id">{{role.name}}</option>
                                    </select>
                                    <span class="highlight"></span>
                                    <label>Role <span class="red">*</span></label>
                                </div>
                                <div *ngIf="submitted && f.role.errors" class="bar error-brd">
                                    <div *ngIf="f.role.errors.required" class="invalid-text">Role is required</div>
                                </div>
                            </div>
                        </div>
                        <ng-template #noadmin>
                            <input formControlName="role" type="hidden">
                        </ng-template>
                        <div *ngIf="updatedUser.user_type != 1" class="card floating-label card-border mb-5">
                            <div class="row">
                                <div class="card-body">
                                    <div class="row">
                                        <div class="col-md-6">
                                            <div class="floating-label">
                                                <input (change)="oneononeFeature = !oneononeFeature" [checked]="oneononeFeature"
                                                       formControlName="subscription_oneonone"
                                                       type="checkbox"/>&nbsp;Subscription
                                                for One On One Meeting
                                                Feature
                                            </div>
                                        </div>
                                        <div class="col-md-6">
                                            <div class="floating-label">
                                                <input (change)="videoFeature = !videoFeature" [checked]="videoFeature"
                                                       formControlName="subscription_video"
                                                       type="checkbox"/>&nbsp;Subscriptiopn
                                                for Video Feature
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <!-- login form column end -->
                        <div _ngcontent-c4="" class="text-right mt-3">
                            <button _ngcontent-c4="" class="blue-btn mr-2" routerLink="/">Cancel</button>
                            <button _ngcontent-c4="" class="blue-btn mr-2" type="submit">Save</button>
                        </div>
                    </form>
                </div>
            </div>
        </div>
        <!-- Add User Section End -->
    </div>
</div>
