import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {RouterModule, Routes} from '@angular/router';
import {ReportComponent} from '@app/modules/statistics/report/report.component';

const routes: Routes = [
  {
    path: '',
    children: [
      {
        path: 'projects/:projectId/statistics',
        component: ReportComponent
      }
    ]
  }
];

@NgModule({
  imports: [
    CommonModule,
    RouterModule.forChild(routes)
  ],
  exports: [
    RouterModule
  ]
})
export class StatisticsRoutingModule {

}
