import {Injectable} from '@angular/core';
import {HttpClient, HttpHeaders, HttpParams} from '@angular/common/http';
import {environment} from '@environments/environment';

@Injectable({
  providedIn: 'root'
})
export class RegistrationPlatformService {
  private baseApiUrl = environment.apiUrl;
  private registrationPlatformUrl = this.baseApiUrl + '/api/v1/registration_platform';

  constructor(private http: HttpClient) {
  }
  getById(id: number) {
    return this.http.get(`${this.registrationPlatformUrl}/${id}`);
  }

  getAllRegistrationPlatforms(params: any = {}) {
    return this.http.get(`${this.registrationPlatformUrl}`, {params});
  }

  update(id: number, item) {
    return this.http.put(`${this.registrationPlatformUrl}/${id}`, item);
  }

  create(item) {
    return this.http.post(`${this.registrationPlatformUrl}`, item);
  }

  deleteRegistrationPlatform(Id) {
    return this.http.delete(`${this.registrationPlatformUrl}/${Id}`);
  }

  previewUrl(id) {
    return this.http.post(`${this.registrationPlatformUrl}/${id}/preview`, {});
  }

  syncInfluencers(params) {
    let headers = new HttpHeaders();
    headers = headers.append('Content-Type', 'multipart/form-data');
    return this.http.post(`${this.registrationPlatformUrl}/sync`, params, {headers});
  }

  syncInfluencerStatus(syncJobId: number) {
    return this.http.get(`${this.registrationPlatformUrl}/sync/status/${syncJobId}`);
  }

  getWebHook(project_id, platform) {
    return this.http.get(`${this.registrationPlatformUrl}/webhook/${project_id}/${platform}`);
  }

  validateProjectSlug(slug) {
    return this.http.post(`${this.registrationPlatformUrl}/validate_slug`, {slug});
  }
}
