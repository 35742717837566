import {Component, OnInit} from '@angular/core';
import {NgbActiveModal} from '@ng-bootstrap/ng-bootstrap';
import {Client} from '@app/modules/shared/models';
import {UntypedFormBuilder, UntypedFormGroup, Validators} from '@angular/forms';
import {
  ClientsList,
  getClientError,
  getLoggedInUser,
  getPaginatedClients,
  IAuthenticationState,
  IClientState
} from '@app/stores';
import {select, Store} from '@ngrx/store';
import {takeUntil} from 'rxjs/operators';
import {Subject, Subscription} from 'rxjs';
import {ToastrService} from "ngx-toastr";
import {Observable} from "rxjs/Observable";
import {LoaderService} from "@app/services/loader.service";

@Component({
  selector: 'app-project-copy',
  templateUrl: './project-copy.component.html',
  styleUrls: ['./project-copy.component.scss']
})
export class ProjectCopyComponent implements OnInit {

  public clients: Client[] = [];
  public projectForm: UntypedFormGroup;
  submitted = false;
  unsubscriber = new Subject();
  currentUser: any;
  isAdmin = false;
  loader$: Observable<boolean>;
  clientSearchValue = '';
  clientPageNumber = 0;
  totalClients = 0;

  constructor(
    public modal: NgbActiveModal,
    public fb: UntypedFormBuilder,
    private authenticationStore: Store<IAuthenticationState>,
    private clientStore: Store<IClientState>,
    private toastrService: ToastrService,
    private loaderService: LoaderService,
  ) {
    this.subscribeStore();
  }

  get f() {
    return this.projectForm.controls;
  }

  subscribeStore() {
    this.authenticationStore.pipe(select(getLoggedInUser))
      .pipe(takeUntil(this.unsubscriber))
      .subscribe(user => {
          if (user) {
            this.currentUser = user;
            this.isAdmin = this.currentUser.role_user[0].role_id === 1;
          }
        }
      );

    this.clientStore.pipe(select(getClientError))
      .pipe(takeUntil(this.unsubscriber))
      .subscribe(error => {
        if (error) {
          this.toastrService.error(error);
        }
      });

    this.clientStore.pipe(select(getPaginatedClients))
      .pipe(takeUntil(this.unsubscriber))
      .subscribe(paginatedClients => {
        if (paginatedClients) {
          this.clients = [...this.clients, ...paginatedClients.list];
          this.clientPageNumber = parseInt(paginatedClients.paging.page);
          this.totalClients = paginatedClients.paging.total;
        }
      });
  }

  ngOnInit(): void {
    this.loader$ = this.loaderService.loader$;
    this.projectForm = this.fb.group({
      title: ['', [Validators.required]],
      client_id: ['', this.isAdmin ? [Validators.required] : []],
      with_influencers: [false],
    });
  }

  saveProject() {
    this.submitted = true;
    if (this.projectForm.invalid) {
      return;
    }
    if (!this.isAdmin) {
      this.projectForm.value.client_id = this.currentUser.client_id;
    }
    this.modal.close(this.projectForm.value);
  }

  handleSearchValue(searchTerm) {
    this.loader$ = null;
    this.clientSearchValue = searchTerm.term;
    this.clients = [];
    this.loadAllPlanners(searchTerm.term);
  }

  handleScroll() {
    this.loader$ = null;
    if (this.clients.length < this.totalClients) {
      this.loadAllPlanners(this.clientSearchValue, this.clientPageNumber);
    }
  }

  loadAllPlanners(search = '', page = 0, perPage = 10, orderBy= {'column': 'name', 'dir': 'asc'}, fields = ['id', 'name']) {
    this.clientPageNumber = page + 1;
    this.clientStore.dispatch(ClientsList({
      params: {
        options: JSON.stringify({includePackage: true, includePagination: true, orderBy, fields}),
        page: this.clientPageNumber,
        perPage,
        search
      }
    }));
  }

  handleClientSelection(selectedClient: any) {
    if (selectedClient) {
      this.projectForm.get('client_id')?.setValue(selectedClient.value)
    } else {
      this.loadAllPlanners();
      this.projectForm.get('client_id')?.setValue(null);
    }
  }
}
