import {User} from '@app/modules/shared/models';

export interface IAuthenticationState {
  user: User;
  forgetPassword: any;
  resetPassword: any;
  registerUser: any;
  success: string;
  error: string;
}

export const initialAuthenticationState: IAuthenticationState = {
  user: JSON.parse(localStorage.getItem('currentUser')) || null,
  forgetPassword: null,
  resetPassword: null,
  registerUser: null,
  success: '',
  error: ''
};
