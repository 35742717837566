import {Injectable} from '@angular/core';
import {Actions, createEffect, ofType} from '@ngrx/effects';
import {Store} from '@ngrx/store';
import {catchError, map, switchMap} from 'rxjs/operators';
import {of} from 'rxjs';
import {LoaderService} from '@app/services/loader.service';
import {
  TemplateSectionList,
  TemplateSectionListSuccess,
  TemplateSectionListError,
  TemplateSectionTypeList,
  TemplateSectionTypeListSuccess,
  TemplateSectionTypeListError,
  TemplateSectionDelete,
  TemplateSectionDeleteSuccess,
  TemplateSectionDeleteError,
  TemplateSectionGet,
  TemplateSectionGetSuccess,
  TemplateSectionGetError,
  TemplateSectionUpdate,
  TemplateSectionUpdateSuccess,
  TemplateSectionUpdateError,
  TemplateSectionCreate,
  TemplateSectionCreateSuccess,
  TemplateSectionCreateError,
  TemplateSectionListAll,
  TemplateSectionListAllSuccess,
  TemplateSectionListAllError,
  TemplateSectionUpdateThumbnail,
  TemplateSectionUpdateThumbnailSuccess,
  TemplateSectionUpdateThumbnailError,
  TemplateSectionGetAssets,
  TemplateSectionGetAssetsSuccess,
  TemplateSectionGetAssetsError
} from '@app/stores/template-section/template-section.actions';
import {ITemplateSectionState} from '@app/stores/template-section/template-section.state';
import {TemplateSectionService} from '@app/services/template-section.service';

@Injectable()
export class TemplateSectionEffects {
  constructor(
    private _actions$: Actions,
    private _store: Store<ITemplateSectionState>,
    private templateSectionService: TemplateSectionService,
    private loaderService: LoaderService
  ) {
  }

  templateSectionListAll$ = createEffect(() => this._actions$.pipe(
    ofType(TemplateSectionListAll),
    switchMap((action) => {
      this.loaderService.show();
      return this.templateSectionService.list(action.params).pipe(
        map((resp: any) => {
          if (resp.success) {
            this.loaderService.hide();
            return TemplateSectionListAllSuccess({templateSections: resp.data});
          }
          this.loaderService.hide(true);
          return TemplateSectionListAllError({error: this.loaderService.getErrorMessage(resp)});
        }),
        catchError(error => {
          this.loaderService.hide(true);
          return of(TemplateSectionListAllError({error: this.loaderService.getErrorMessage(error)}));
        })
      );
    })
  ));

  templateSectionList$ = createEffect(() => this._actions$.pipe(
    ofType(TemplateSectionList),
    switchMap((action) => {
      this.loaderService.show();
      return this.templateSectionService.list(action.params).pipe(
        map((resp: any) => {
          if (resp.success) {
            this.loaderService.hide();
            return TemplateSectionListSuccess({paginatedTemplateSection: resp.data});
          }
          this.loaderService.hide(true);
          return TemplateSectionListError({error: this.loaderService.getErrorMessage(resp)});
        }),
        catchError(error => {
          this.loaderService.hide(true);
          return of(TemplateSectionListError({error: this.loaderService.getErrorMessage(error)}));
        })
      );
    })
  ));

  templateSectionTypeList$ = createEffect(() => this._actions$.pipe(
    ofType(TemplateSectionTypeList),
    switchMap((action) => {
      this.loaderService.show();
      return this.templateSectionService.listSectionTypes(action.params).pipe(
        map((resp: any) => {
          if (resp.success) {
            this.loaderService.hide();
            return TemplateSectionTypeListSuccess({templateSectionTypes: resp.data});
          }
          this.loaderService.hide(true);
          return TemplateSectionTypeListError({error: this.loaderService.getErrorMessage(resp)});
        }),
        catchError(error => {
          this.loaderService.hide(true);
          return of(TemplateSectionTypeListError({error: this.loaderService.getErrorMessage(error)}));
        })
      );
    })
  ));

  templateSectionDelete$ = createEffect(() => this._actions$.pipe(
    ofType(TemplateSectionDelete),
    switchMap((action) => {
      this.loaderService.show();
      return this.templateSectionService.templateSectionDelete(action.templateSectionId).pipe(
        map((resp: any) => {
          if (resp.success) {
            this.loaderService.hide();
            return TemplateSectionDeleteSuccess();
          }
          this.loaderService.hide(true);
          return TemplateSectionDeleteError({error: this.loaderService.getErrorMessage(resp)});
        }),
        catchError(error => {
          this.loaderService.hide(true);
          return of(TemplateSectionDeleteError({error: this.loaderService.getErrorMessage(error)}));
        })
      );
    })
  ));

  templateSectionGet$ = createEffect(() => this._actions$.pipe(
    ofType(TemplateSectionGet),
    switchMap((action) => {
      this.loaderService.show();
      return this.templateSectionService.getSection(action.templateSectionId, action.params).pipe(
        map((resp: any) => {
          if (resp.success) {
            this.loaderService.hide();
            return TemplateSectionGetSuccess({templateSection: resp.data});
          }
          this.loaderService.hide(true);
          return TemplateSectionGetError({error: this.loaderService.getErrorMessage(resp)});
        }),
        catchError(error => {
          this.loaderService.hide(true);
          return of(TemplateSectionGetError({error: this.loaderService.getErrorMessage(error)}));
        })
      );
    })
  ));

  templateSectionUpdate$ = createEffect(() => this._actions$.pipe(
    ofType(TemplateSectionUpdate),
    switchMap((action) => {
      this.loaderService.show();
      return this.templateSectionService.updateSection(action.templateSectionId, action.body).pipe(
        map((resp: any) => {
          if (resp.success) {
            this.loaderService.hide();
            return TemplateSectionUpdateSuccess({templateSection: resp.data});
          }
          this.loaderService.hide(true);
          return TemplateSectionUpdateError({error: this.loaderService.getErrorMessage(resp)});
        }),
        catchError(error => {
          this.loaderService.hide(true);
          return of(TemplateSectionUpdateError({error: this.loaderService.getErrorMessage(error)}));
        })
      );
    })
  ));

  templateSectionCreate$ = createEffect(() => this._actions$.pipe(
    ofType(TemplateSectionCreate),
    switchMap((action) => {
      this.loaderService.show();
      return this.templateSectionService.createSection(action.body).pipe(
        map((resp: any) => {
          if (resp.success) {
            this.loaderService.hide();
            return TemplateSectionCreateSuccess({templateSection: resp.data});
          }
          this.loaderService.hide(true);
          return TemplateSectionCreateError({error: this.loaderService.getErrorMessage(resp)});
        }),
        catchError(error => {
          this.loaderService.hide(true);
          return of(TemplateSectionCreateError({error: this.loaderService.getErrorMessage(error)}));
        })
      );
    })
  ));

  templateSectionUpdateThumbnail = createEffect(() => this._actions$.pipe(
    ofType(TemplateSectionUpdateThumbnail),
    switchMap((action) => {
      this.loaderService.show();
      return this.templateSectionService.updateThumbnails().pipe(
        map((resp: any) => {
          if (resp.success) {
            this.loaderService.hide();
            return TemplateSectionUpdateThumbnailSuccess({success: 'Thumbnails are updating'});
          }
          this.loaderService.hide(true);
          return TemplateSectionUpdateThumbnailError({error: this.loaderService.getErrorMessage(resp)});
        }),
        catchError(error => {
          this.loaderService.hide(true);
          return of(TemplateSectionUpdateThumbnailError({error: this.loaderService.getErrorMessage(error)}));
        })
      );
    })
  ));

  templateSectionAssets = createEffect(() => this._actions$.pipe(
    ofType(TemplateSectionGetAssets),
    switchMap((action) => {
      this.loaderService.show();
      return this.templateSectionService.getAssets(action.params).pipe(
        map((resp: any) => {
          if (resp.success) {
            this.loaderService.hide();
            return TemplateSectionGetAssetsSuccess({templateSectionAssets: resp.data});
          }
          this.loaderService.hide(true);
          return TemplateSectionGetAssetsError({error: this.loaderService.getErrorMessage(resp)});
        }),
        catchError(error => {
          this.loaderService.hide(true);
          return of(TemplateSectionGetAssetsError({error: this.loaderService.getErrorMessage(error)}));
        })
      );
    })
  ));
}
