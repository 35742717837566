import {createAction, props} from '@ngrx/store';
import {RegistrationPlatform, PaginatedRegistrationPlatform} from '@app/modules/shared/models/registration-platform';

enum ERegistrationPlatformActions {
  RegistrationPlatformList = '[RegistrationPlatform] RegistrationPlatform List',
  RegistrationPlatformListSuccess = '[RegistrationPlatform] RegistrationPlatform List Success',
  RegistrationPlatformListError = '[RegistrationPlatform] RegistrationPlatform List Error',
  RegistrationPlatformUpdate = '[RegistrationPlatform] RegistrationPlatform Update',
  RegistrationPlatformUpdateSuccess = '[RegistrationPlatform] RegistrationPlatform Update Success',
  RegistrationPlatformUpdateError = '[RegistrationPlatform] RegistrationPlatform Update Error',
  RegistrationPlatformCreate = '[RegistrationPlatform] RegistrationPlatform Create',
  RegistrationPlatformCreateSuccess = '[RegistrationPlatform] RegistrationPlatform Create Success',
  RegistrationPlatformCreateError = '[RegistrationPlatform] RegistrationPlatform Create Error',
  RegistrationPlatformDelete = '[RegistrationPlatform] RegistrationPlatform Delete',
  RegistrationPlatformDeleteSuccess = '[RegistrationPlatform] RegistrationPlatform Delete Success',
  RegistrationPlatformDeleteError = '[RegistrationPlatform] RegistrationPlatform Delete Error',
  RegistrationPlatformListPaginated = '[RegistrationPlatform] RegistrationPlatform List Paginated',
  RegistrationPlatformListPaginatedSuccess = '[RegistrationPlatform] RegistrationPlatform List Paginated Success',
  RegistrationPlatformListPaginatedError = '[RegistrationPlatform] RegistrationPlatform List Paginated Error',
  RegistrationPlatformGet = '[RegistrationPlatform] RegistrationPlatform Get',
  RegistrationPlatformGetSuccess = '[RegistrationPlatform] RegistrationPlatform Get Success',
  RegistrationPlatformGetError = '[RegistrationPlatform] RegistrationPlatform Get Error',
  RegistrationPlatformWidgetGuideURL = '[RegistrationPlatform] RegistrationPlatform Widget Guide URL',
  RegistrationPlatformWidgetGuideURLSuccess = '[RegistrationPlatform] RegistrationPlatform Widget Guide Success',
  RegistrationPlatformWidgetGuideURLError = '[RegistrationPlatform] RegistrationPlatform Widget Guide Error',
  InfluencersSync = '[Influencers] Influencers Sync',
  InfluencersSyncSuccess = '[Influencers] Influencers Sync Success',
  InfluencersSyncError = '[Influencers] Influencers Sync Error',
  InfluencersSyncStatus = '[Influencers] Influencers Sync Status',
  InfluencersSyncStatusSuccess = '[Influencers] Influencers Sync Status Success',
  InfluencersSyncStatusError = '[Influencers] Influencers Sync Status Error',
  RegistrationPlatformValidateSlug = '[RegistrationPlatform] RegistrationPlatform Validate Slug',
  RegistrationPlatformValidateSlugSuccess = '[RegistrationPlatform] RegistrationPlatform Validate Slug Success',
  RegistrationPlatformValidateSlugError = '[RegistrationPlatform] RegistrationPlatform Validate Slug Error',
  ResetRegistrationPlatformState = '[RegistrationPlatform] Reset RegistrationPlatform State',
}

export const RegistrationPlatformList = createAction(ERegistrationPlatformActions.RegistrationPlatformList, (params: any = {}) => params);
export const RegistrationPlatformListSuccess = createAction(ERegistrationPlatformActions.RegistrationPlatformListSuccess, props<{ registrationPlatforms: RegistrationPlatform[] }>());
export const RegistrationPlatformListError = createAction(ERegistrationPlatformActions.RegistrationPlatformListError, props<{ error: string }>());

export const RegistrationPlatformListPaginated = createAction(ERegistrationPlatformActions.RegistrationPlatformListPaginated, (params: any = {}) => params);
export const RegistrationPlatformListPaginatedSuccess = createAction(ERegistrationPlatformActions.RegistrationPlatformListPaginatedSuccess, props<{ paginatedRegistrationPlatform: PaginatedRegistrationPlatform }>());
export const RegistrationPlatformListPaginatedError = createAction(ERegistrationPlatformActions.RegistrationPlatformListPaginatedError, props<{ error: string }>());

export const RegistrationPlatformUpdate = createAction(ERegistrationPlatformActions.RegistrationPlatformUpdate, props<{ registrationPlatformId: number, registrationPlatform: RegistrationPlatform }>());
export const RegistrationPlatformUpdateSuccess = createAction(ERegistrationPlatformActions.RegistrationPlatformUpdateSuccess);
export const RegistrationPlatformUpdateError = createAction(ERegistrationPlatformActions.RegistrationPlatformUpdateError, props<{ error: string }>());

export const RegistrationPlatformCreate = createAction(ERegistrationPlatformActions.RegistrationPlatformCreate, props<{ registrationPlatform: RegistrationPlatform }>());
export const RegistrationPlatformCreateSuccess = createAction(ERegistrationPlatformActions.RegistrationPlatformCreateSuccess, props<{ registrationPlatform: RegistrationPlatform }>());
export const RegistrationPlatformCreateError = createAction(ERegistrationPlatformActions.RegistrationPlatformCreateError, props<{ error: string }>());

export const RegistrationPlatformDelete = createAction(ERegistrationPlatformActions.RegistrationPlatformDelete, props<{ registrationPlatformId: number }>());
export const RegistrationPlatformDeleteSuccess = createAction(ERegistrationPlatformActions.RegistrationPlatformDeleteSuccess);
export const RegistrationPlatformDeleteError = createAction(ERegistrationPlatformActions.RegistrationPlatformDeleteError, props<{ error: string }>());

export const RegistrationPlatformGet = createAction(ERegistrationPlatformActions.RegistrationPlatformGet, props<{ registrationPlatformId: number }>());
export const RegistrationPlatformGetSuccess = createAction(ERegistrationPlatformActions.RegistrationPlatformGetSuccess, props<{ registrationPlatform: RegistrationPlatform }>());
export const RegistrationPlatformGetError = createAction(ERegistrationPlatformActions.RegistrationPlatformGetError, props<{ error: string }>());

export const RegistrationPlatformWidgetGuideURL = createAction(ERegistrationPlatformActions.RegistrationPlatformWidgetGuideURL, props<{ platformId: number }>());
export const RegistrationPlatformWidgetGuideURLSuccess = createAction(ERegistrationPlatformActions.RegistrationPlatformWidgetGuideURLSuccess, props<{ widgetGuideUrl: any }>());
export const RegistrationPlatformWidgetGuideURLError = createAction(ERegistrationPlatformActions.RegistrationPlatformWidgetGuideURLError, props<{ error: string }>());

export const InfluencersSync = createAction(ERegistrationPlatformActions.InfluencersSync, props<{ data: any }>());
export const InfluencersSyncSuccess = createAction(ERegistrationPlatformActions.InfluencersSyncSuccess, props<{ influencersSync: any }>());
export const InfluencersSyncError = createAction(ERegistrationPlatformActions.InfluencersSyncError, props<{ error: string }>());

export const InfluencersSyncStatus = createAction(ERegistrationPlatformActions.InfluencersSyncStatus, props<{ syncJobId: number }>());
export const InfluencersSyncStatusSuccess = createAction(ERegistrationPlatformActions.InfluencersSyncStatusSuccess,
  props<{ influencersSync: any }>());
export const InfluencersSyncStatusError = createAction(ERegistrationPlatformActions.InfluencersSyncStatusError, props<{ error: string }>());

export const RegistrationPlatformValidateSlug = createAction(ERegistrationPlatformActions.RegistrationPlatformValidateSlug, props<{ slug: string }>());
export const RegistrationPlatformValidateSlugSuccess = createAction(ERegistrationPlatformActions.RegistrationPlatformValidateSlugSuccess, props<{ slug: string }>());
export const RegistrationPlatformValidateSlugError = createAction(ERegistrationPlatformActions.RegistrationPlatformValidateSlugError, props<{ error: string }>());

export const ResetRegistrationPlatformState = createAction(ERegistrationPlatformActions.ResetRegistrationPlatformState, (params: any = {}) => params);



