import {AfterViewInit, Component, Input, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {NgbActiveModal} from '@ng-bootstrap/ng-bootstrap';
import {
  getInfluencerError,
  getInfluencersTaskLogs,
  IInfluencersState, InfluencersTaskLogs,
} from '@app/stores';
import {select, Store} from '@ngrx/store';
import {takeUntil} from 'rxjs/operators';
import {Subject} from 'rxjs';
import {ToastrService} from 'ngx-toastr';
import {DataTableDirective} from 'angular-datatables';
import * as moment from 'moment-timezone';
import {SocketService} from '@app/services/socket.service';
import * as DataTables from 'datatables.net';

@Component({
  selector: 'app-task-logs',
  templateUrl: './task-logs.component.html',
  styleUrls: ['./task-logs.component.css']
})
export class TaskLogsComponent implements OnInit, OnDestroy, AfterViewInit {
  @ViewChild(DataTableDirective)
  dtElement: DataTableDirective;

  dtOptions: any = {};
  dtTrigger: Subject<any> = new Subject();
  public logs = [];
  private unsubscriber = new Subject();
  public importJobId: number;
  ajaxCallback: any;
  private datatableInstance: DataTables.Api<any>;
  pageNumber = 0;
  influencersTaskLogs = [];
  @Input() data: any;
  _moment = moment;
  public socketSubscription: any;
  pageLength = 10;
  orderBy: any = null;

  constructor(
    public modal: NgbActiveModal,
    private influencerStore: Store<IInfluencersState>,
    private toastr: ToastrService,
    private readonly socketService: SocketService
  ) {
  }

  subscribeWebSocket() {
    this.socketSubscription = this.socketService.sourceData.subscribe(
      (message: any) => {
        if (message?.data?.requesterType === 'influencer' && message?.data?.result) {
          const inx = this.influencersTaskLogs.findIndex(x => x.id === message.data.result.id);
          this.influencersTaskLogs = Object.assign([], this.influencersTaskLogs);
          if (inx >= 0) {
            this.influencersTaskLogs[inx].progress = message.data.result.progress;
            this.influencersTaskLogs[inx].status = message.data.result.status;
            this.influencersTaskLogs[inx].message = message.data.result.message;
          }
        }
      },
      (err) => console.error(err),
      () => console.warn('Completed!')
    );
  }

  subscribeStores() {
    this.influencerStore.pipe(select(getInfluencersTaskLogs))
      .pipe(takeUntil(this.unsubscriber))
      .subscribe(influencersTaskLogs => {
        if (influencersTaskLogs) {
          // TODO:- Pagination To add
          this.influencersTaskLogs = (influencersTaskLogs.list || []).map(x => Object.assign({}, x));
          if (this.ajaxCallback) {
            this.ajaxCallback({
              recordsTotal: influencersTaskLogs.paging.total,
              recordsFiltered: influencersTaskLogs.paging.total,
              data: []
            });
            setTimeout(() => {
              (this.datatableInstance as any).columns.adjust();
            }, 500);
          }
        }
      });

    this.influencerStore.pipe(select(getInfluencerError))
      .pipe(takeUntil(this.unsubscriber))
      .subscribe(error => {
        if (error) {
          this.toastr.error(error, 'Error');
        }
      });
  }

  ngOnInit(): void {
    this.subscribeWebSocket();
    this.datatableSettings();
    if (this.dtElement && this.dtElement.dtInstance) {
      this.dtElement.dtInstance.then((dtInstance: any) => {
        dtInstance.destroy();
        this.dtTrigger.next();
      });
    } else {
      this.dtTrigger.next();
    }
    this.subscribeStores();
  }

  ngAfterViewInit() {
    this.dtTrigger.next();
  }

  datatableSettings() {
    this.dtOptions = {
      scrollCollapse: true,
      pagingType: 'full_numbers',
      responsive: false,
      ordering: false,
      processing: true,
      pageLength: 10,
      paging: true,
      serverSide: true,
      scrollX: true,
      scrollY: "60vh",
      fixedHeader: {
        header: true,
        footer: true
      },
      searching: false,
      drawCallback: function (setting) {
        const totalPages = this.api().page?.info()?.pages || 0;
        if (totalPages <= 1) {
          document.querySelector('.dataTables_paginate').classList.add('d-none');
        } else {
          document.querySelector('.dataTables_paginate').classList.remove('d-none');
        }
      },
      ajax: (dataTablesParameters: any, callback) => {
        this.dtElement.dtInstance.then((dtInstance: any) => {
          this.datatableInstance = dtInstance;
          this.ajaxCallback = callback;
          const pageLength = dataTablesParameters.length;
          this.pageNumber = (dataTablesParameters.start / pageLength);
          (this.datatableInstance as any).page.len(pageLength);
          const orderBy = {
            ...dataTablesParameters.order[0],
            column: 'created_at'
          };
          this.orderBy = orderBy;
          this.pageLength = pageLength;
          this.loadLogs(pageLength, orderBy);
        });
      },
      columns: [
        {data: null}, {data: null}, {data: null}, {data: null}
      ],
      order: [[1, 'desc']],
    };
  }

  refreshList() {
    this.loadLogs(this.pageLength, this.orderBy);
  }

  loadLogs(perPage = 10, orderBy = {'column': 'created_at', 'dir': 'desc'}) {
    this.influencerStore.dispatch(InfluencersTaskLogs({
      params: {
        options: JSON.stringify({includePagination: true, orderBy}),
        page: this.pageNumber + 1,
        perPage,
        campaign_id: this.data.campaign_id,
        project_id: this.data.project_id,
        influencer_type_id: this.data.influencer_type_id,
        action: this.data.action ? this.data.action : 'import',
        registration_platform_id: this.data.registration_platform_id || ''
      }
    }));
  }

  formatMoment(insertTime) {
    insertTime = moment(insertTime, 'YYYY-MM-DD[T]HH[:]mm[:]00[.000Z]');
    if (insertTime.isValid()) {
      return insertTime;
    }
    return null;
  }

  ngOnDestroy() {
    this.unsubscriber.next();
    this.unsubscriber.complete();
    if (this.socketSubscription) {
      this.socketSubscription.unsubscribe();
    }
  }

}
