<div class="align-items-center modal-header px-4">
  <h4 class="modal-title font-22" id="modal-title">
    <span class="font-weight-bold">{{emailSenderVerifyBy === 'domain' ? 'Domain' : 'Email'}}</span> Verification
  </h4>
  <div class="d-flex align-items-center justify-content-end">
    <a [href]="shareWidgetLinkUrl" target="_blank"
       *ngIf="emailSenderVerifyBy === 'domain' && fromEmailVerified === 'unverified' && !statusLoading"
       class="share-link"><i class="fa fa-share mr-1"></i><u>Share this</u></a>
    <button type="button" class="close font-22" aria-label="Close button" aria-describedby="modal-title"
            (click)="modal.close(emailSenderServiceProviderSlug)">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
</div>
<div class="modal-body pt-0 px-0 pb-0">
  <ng-container>
    <div class="verification text-center pb-5" *ngIf="statusLoading">
      <div class="lds-ellipsis">
        <div></div>
        <div></div>
        <div></div>
        <div></div>
      </div>
    </div>
    <div class="verification" *ngIf="!(loader$ | async) && !statusLoading">
      <div class="verification-complete text-center pb-3" *ngIf="fromEmailVerified === 'verified'">
        <div class="verification-header">Congratulations!</div>
        <div class="verification-body">
          <p class="verification-text">You are all set to get your campaign going.</p>
          <p class="verification-text">Happy Snoballing!</p>
        </div>
      </div>
      <ng-container *ngIf="emailSenderVerifyBy === 'email'">
        <div class="verification-not-complete text-center pb-3" *ngIf="fromEmailVerified === 'unverified'">
          <div class="verification-body">
            <p class="verification-text">A verification email has been sent to</p>
            <p class="verification-text"><span>{{fromEmail}}</span>. Please check your inbox</p>
            <button class="btn btn-outline-primary mt-3 resend-button" (click)="sendVerification(true)">
              Resend Verification
            </button>
          </div>
        </div>
      </ng-container>
      <ng-container *ngIf="emailSenderVerifyBy === 'domain'">
        <div *ngIf="fromEmailVerified === 'unverified'" class="verification-body">
            <iframe rameborder="0" class="domain-verification-iframe"
                    style="border: none; width: 100%;"
                    [src]="verificationUrl"
            ></iframe>

          <div class="verification-text-domain" *ngIf="selectedProvider" style="padding-top: 10px;">
            {{selectedProvider.title}} : <span *ngFor="let item of selectedProvider.items; let firstElement = first;" class="font-weight-bold">{{!firstElement ? ', ' : ''}}<a target="_blank" [href]="item.instructionUrl">{{item.description}}</a></span>
          </div>
        </div>
      </ng-container>
    </div>
  </ng-container>
</div>
<div class="justify-content-start modal-footer px-4"
     *ngIf="emailSenderVerifyBy === 'email'">
  <div class="footer-header">
    Need to send with another email?
  </div>
  <div class="footer-text">
    Domain verification is only needed once and lets you send campaign from any other email with same domain name.
    <a class="cursor-pointer" (click)="changeVerificationType('domain')">Click to activate domain verification</a>
  </div>
</div>
<div class="justify-content-start modal-footer px-4"
     *ngIf="emailSenderVerifyBy === 'domain' && fromEmailVerified !== 'verified'" style="display: none">
  <div class="footer-text">
    <a class="cursor-pointer" (click)="changeVerificationType('email')">Click to activate email based verification</a>
  </div>
</div>
<input type="hidden" ngbAutofocus>
