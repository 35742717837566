import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RenameTitleModalComponent } from './rename-title-modal.component';
import { ReactiveFormsModule } from '@angular/forms';

@NgModule({
  declarations: [RenameTitleModalComponent],
  imports: [CommonModule, ReactiveFormsModule],
})
export class RenameTitleModalModule {}
