<div class="modal-header">
  <h4 class="modal-title" id="modal-title">Manage Avatar Widget Templates</h4>
  <div class="d-flex justify-content-end">
    <button type="button" aria-label="Close button" class="close" aria-describedby="modal-title"
            (click)="modal.dismiss('cancel')">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
</div>
<div class="modal-body">
  <div class="d-flex flex-wrap px-3">
    <div class="ml-auto">
      <button class="btn btn-primary btn-sm float-right" type="button" role="button" (click)="showAddEditTemplateModel(customizeTemplate, 'add')" >
        <span class="btn-wrapper--icon"><i class="fa fa-plus"></i></span>
        <span class="btn-wrapper--label">Add New</span>
      </button>
    </div>
  </div>
  <div class="custom-table p-3">
    <table width="100%" class="sb-table table dataTable no-footer nowrap" [dtTrigger]="dtTrigger" datatable
           [dtOptions]="dtOptions"
           id="dt-table">
      <thead>
      <tr>
        <th class="w-25">Name</th>
        <th>Image</th>
        <th></th>
      </tr>
      </thead>
      <tbody *ngIf="widgetAvatarTemplates.length > 0">
      <tr *ngFor="let template of widgetAvatarTemplates">
        <td class="w-25 text-wrap">{{ template.name}}</td>
        <td>
          <div class="image-variations">
            <div *ngFor="let variation of template.image_variations;">
              <div class="thumbnail">
                <img class="img-fluid w-100" [src]="variation.url" alt="{{ variation.name }}">
              </div>
            </div>
          </div>
        </td>
        <td>
          <button type="button" class="btn-icon float-right" placement="bottom-right"
                  [autoClose]="true" [ngbPopover]="popTemplate">
            <span class="fa fa-ellipsis-v"></span>
          </button>
          <ng-template #popTemplate>
            <a class="dropdown-item d-flex align-items-center pointer" (click)="showAddEditTemplateModel(customizeTemplate,'edit', template)">
              <i class="fa fa-pencil mr-2" aria-hidden="true"></i>Edit
            </a>
            <a class="dropdown-item d-flex align-items-center pointer" (click)="deleteWidgetTemplate(template.id)">
              <i class="fa fa-trash mr-2" aria-hidden="true"></i>Delete
            </a>
          </ng-template>
        </td>
      </tr>
      </tbody>
      <tbody *ngIf="widgetAvatarTemplates?.length <= 0">
      <tr>
        <td colspan="3" class="no-data-available">No data available in table</td>
      </tr>
      </tbody>
    </table>
  </div>
</div>
<div class="modal-footer">
  <button class="btn btn-primary" type="button" (click)="modal.dismiss('cancel')">
    Close
  </button>
</div>

<ng-template #customizeTemplate let-modal>
  <div class="modal-header">
    <h4 class="modal-title text-capitalize">
      {{selectedAction}}
    </h4>
    <button type="button" class="close" aria-label="Close button" aria-describedby="modal-title"
            (click)="modal.dismiss('cancel')">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="form-wraper">
    <form (ngSubmit)="saveWidgetTemplateForm(modal)" [formGroup]="addWidgetAvatarTemplateForm" autocomplete="off">
      <div class="modal-body mb-5">
        <div class="row align-items-center">
          <div class="col-12">
            <div class="floating-label">
              <input formControlName="name" placeholder=""
                     required class="floating-input"
                     type="text" autocomplete="off"/>
              <label>Name <span class="red">*</span></label>
              <div *ngIf="submitted && f.name.errors" class="bar error-brd">
                <div *ngIf="f.name.errors.required" class="invalid-text">Name is required</div>
              </div>
            </div>
          </div>
          <div class="col-lg-12 col-md-12 col-ms-12">
            <label class="label-text-css">Prompt <span class="red">*</span></label>
            <div class="form-group mb-0">
              <div>Continue this prompt with your desired details. You don't need to add any facial details like gender, age, hair style, etc.</div>
              <div><strong>Generate a portrait avatar image with the following details: </strong></div>
              <textarea class="form-control border-left-0 border-top-0 border-right-0" formControlName="prompt" rows="10" style="padding-left: 10px;padding-right: 10px;" required placeholder="Ultra realistic, pilot, distinctive features of a pilot's uniform such as an emblematic pilot's cap, aviation headphones, and a flight suit"></textarea>
            </div>
            <div *ngIf="submitted && f.prompt.errors" class="bar error-brd">
              <div *ngIf="f.prompt.errors.required" class="invalid-text prompt-error-text">Prompt is required</div>
            </div>
          </div>
          <div class="col-12 text-center mt-3">
            <button class="btn btn-primary btn-md" type="button" role="button" (click)="generateWidgetAvatarImages()">
              <span class="btn-wrapper--label">Generate</span>
            </button>
          </div>
          <div class="col-12">
            <div *ngIf="submitted && f.prompt.value && f.image_variations.errors" class="bar error-brd">
              <div *ngIf="f.image_variations.errors.required" class="invalid-text prompt-error-text">Please generate the image. Avatar Variation is required</div>
            </div>
          </div>
          <div class="mt-2 col-12">
            <div class="row justify-content-center">
              <div class="col-12">
                <div class="avatar-preview mb-3" *ngIf="selectedVariation">
                  <img class="w-100 img-fluid" [src]="selectedVariation?.url" alt="{{ selectedVariation?.name }}">
                </div>
              </div>
            </div>
            <label *ngIf="imageVariations.length > 0">Variations</label>
            <div  *ngIf="imageVariations.length > 0" class="image-variations">
              <div *ngFor="let variation of imageVariations; let i = index;">
                <div class="thumbnail" [ngClass]="{'active': variation.isActive}" (click)="selectVariation(variation, i)">
                  <img class="img-fluid w-100" [src]="variation.url" alt="{{ variation.name }}">
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="modal-footer">
        <button class="btn btn-default mr-2" type="button" (click)="modal.close()">Cancel</button>
        <button class="btn btn-primary mr-2" type="submit">Save</button>
      </div>
    </form>
  </div>
  <input type="hidden" ngbAutofocus>
</ng-template>
