import {createFeatureSelector, createSelector} from '@ngrx/store';
import {IProjectState} from '@app/stores';

const selectProject = createFeatureSelector<IProjectState>('project');

export const getProject = createSelector(selectProject, (state: IProjectState) => state.project);

export const getProjectSuccess = createSelector(selectProject, (state: IProjectState) => state.success);

export const getProjectSuccessResponse = createSelector(selectProject, (state: IProjectState) => state.updateProject);

export const getProjectError = createSelector(selectProject, (state: IProjectState) => state.error);

export const getProjects = createSelector(selectProject, (state: IProjectState) => state.projects);

export const getProjectPlanners = createSelector(selectProject, (state: IProjectState) => state.projectPlanners);

export const getPaginatedProjects = createSelector(selectProject, (state: IProjectState) => state.paginatedProjects);

export const getProjectIndustries = createSelector(selectProject, (state: IProjectState) => state.industries);

export const getProjectSlug = createSelector(selectProject, (state: IProjectState) => state.slug);

export const getCreateOrCopySuccess = createSelector(selectProject, (state: IProjectState) => state.createOrCopySuccess);

// export const getNotificationSuccessResponse = createSelector(selectProject, (state: IProjectState) => state.createOrCopySuccess);
