import {Injectable} from '@angular/core';
import { timeout } from 'rxjs/operators';
import {
  HttpHeaders,
  HttpClient, HttpParams
} from '@angular/common/http';

import {environment} from '@environments/environment';

@Injectable({providedIn: 'root'})
export class TemplateService {
  private baseApiUrl = environment.apiUrl;
  private templateUrl = this.baseApiUrl + '/api/v1/template';
  private templateTypeUrl = this.baseApiUrl + '/api/v1/template_type';
  private widgetAvatarTemplateUrl = this.baseApiUrl + '/api/v1/widget_avatar_template';
  private shareUrl = this.baseApiUrl + '/api/v1/share';
  private apiUrl = this.baseApiUrl + '/api/v1';

  constructor(private http: HttpClient) {
  }

  getAll(params?: any) {
    return this.http.get(`${this.templateUrl}`, {params});
  }

  touch(body: any) {
    return this.http.post(`${this.templateUrl}/touch`, body);
  }

  get(id: number) {
    return this.http.get(`${this.templateUrl}/${id}`);
  }

  preview(id: number, item) {
    return this.http.post(`${this.templateUrl}/${id}/preview`, item);
  }

  previewUrl(id: number) {
    return this.http.post(`${this.templateUrl}/${id}/preview`, {});
  }

  widgetPreviewUrl(id: number) {
    return this.http.post(`${this.shareUrl}/${id}/preview`, {});
  }

  updateStatus(id: number, status: string) {
    return this.http.put(`${this.templateUrl}/${id}`, {status: status});
  }

  updateAll(id: number, params: any) {
    return this.http.put(`${this.templateUrl}/${id}`, params);
  }

  deleteTemplate(id: number) {
    return this.http.delete(`${this.templateUrl}/${id}`);
  }

  update(id: number, body: any) {
    return this.http.put(`${this.templateUrl}/${id}`, body);
  }

  create(body: any) {
    return this.http.post(`${this.templateUrl}`, body);
  }

  indexForCampaign(params) {
    return this.http.get(`${this.templateUrl}/campaign`, {params});
  }

  widgetInstructions(params?: any) {
    return this.http.get(`${this.shareUrl}/integration_instruction`, {params});
  }

  customScriptInstructions() {
    return this.http.get(`${this.shareUrl}/custom_script_instruction`);
  }

  updateThumbnails() {
    return this.http.get(`${this.templateUrl}/update_all_thumbnail`);
  }

  getTemplateTypes() {
    return this.http.get(`${this.templateTypeUrl}`);
  }

  getTemplateGetRevisions(templateId, params?: any) {
    return this.http.get(`${this.templateUrl}/${templateId}/revisions`, {params});
  }

  getAllWidgetAvatarTemplates(params?: any) {
    return this.http.get(`${this.widgetAvatarTemplateUrl}`, {params});
  }

  createWidgetAvatarTemplate(body: any) {
    return this.http.post(`${this.widgetAvatarTemplateUrl}`, body);
  }

  updateWidgetAvatarTemplate(id: number, body: any) {
    return this.http.put(`${this.widgetAvatarTemplateUrl}/${id}`, body);
  }

  deleteWidgetAvatarTemplate(id: number) {
    return this.http.delete(`${this.widgetAvatarTemplateUrl}/${id}`);
  }

  generateWidgetAvatars(body: any) {
    return this.http.post(`${this.apiUrl}/ai/generate_widget_avatar`, body);
  }
}
