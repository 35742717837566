<div class="modal-header">
  <h4 class="modal-title" id="modal-title">{{category === 'template_section' ? 'Section' : 'Template'}} Revisions</h4>
  <button type="button" class="close" aria-label="Close button" aria-describedby="modal-title"
          (click)="modal.dismiss('cancel')">
    <span aria-hidden="true">&times;</span>
  </button>
</div>
<div class="modal-body">
  <div class="d-flex justify-content-end align-items-center" *ngIf="canReset">
    <button type="button" class="btn-icon btn-sm btn btn-default"
            popoverTitle="Are you sure you want to reset your template to original version?"
            placement="bottom-right" [autoClose]="true" [ngbPopover]="popTemplate">
      Reset
    </button>
    <ng-template #popTemplate>
      <div class="d-flex justify-content-center align-items-center p-1">
        <button type="button" class="btn-icon btn-sm btn btn-default">
          Cancel
        </button>
        <button type="button" class="btn-icon btn-sm btn btn-primary" (click)="resetTemplate()">
          Reset
        </button>
      </div>
    </ng-template>
  </div>
  <div class="custom-table p-3">
    <table width="100%" class="sb-table table dataTable no-footer nowrap" datatable
           [dtTrigger]="dtTrigger"
           [dtOptions]="dtOptions"
           id="dt-table">
      <thead>
      <tr>
        <th>Revision Date</th>
        <th>Created By</th>
        <th></th>
      </tr>
      </thead>
      <tbody *ngIf="templateRevisions?.length > 0">
      <tr *ngFor="let revision of templateRevisions">
<!--        <td>{{_moment(revision.created_at, 'YYYY-MM-DD[T]HH[:]mm[:]ss[.000Z]').format('DD MMM, YYYY hh:mm:ss A')}}</td>-->
        <td>{{_moment(revision.created_at, 'YYYY-MM-DD[T]HH[:]mm[:]ss[.000Z]') | tzDate : false : 'DD MMM, YYYY hh:mm:ss A'}}</td>
        <td>{{revision.user ? (revision.user.first_name + ' ' + revision.user.last_name) : ''}}</td>
        <td>
          <button type="button" class="btn-icon float-right btn btn-primary btn-sm"
                  popoverTitle="Are you sure you want to restore this version?"
                  placement="bottom-right" [autoClose]="true" [ngbPopover]="popTemplate">
            Restore
          </button>
          <ng-template #popTemplate>
            <div class="d-flex justify-content-center align-items-center p-1">
              <button type="button" class="btn-icon btn-sm btn btn-default">
                Cancel
              </button>
              <button type="button" class="btn-icon btn-sm btn btn-primary" (click)="restoreVersion(revision)">
                Restore
              </button>
            </div>
          </ng-template>
        </td>
      </tr>
      </tbody>
      <tbody *ngIf="templateRevisions?.length <= 0">
      <tr>
        <td colspan="6" class="no-data-available">No data available in table</td>
      </tr>
      </tbody>
    </table>
  </div>
</div>
<input type="hidden" ngbAutofocus>
