<div class="modal-header">
  <h4 class="modal-title" id="modal-title">{{isUpdate ? 'Update' : 'Add'}} segment</h4>
  <button type="button" class="close" aria-label="Close button" aria-describedby="modal-title"
          (click)="modal.dismiss('cancel')">
    <span aria-hidden="true">&times;</span>
  </button>
</div>
<div class="modal-body">
  <form [formGroup]="segmentForm">
    <div class="row">
      <div class="col-md-12">
        <div class="floating-label">
          <input class="floating-input" placeholder=" " type="text" formControlName="name"/>
          <span class="highlight"></span>
          <label>Segment Name</label>
          <div *ngIf="submitted && f.name.errors" class="bar error-brd">
            <div *ngIf="f.name.errors.required" class="invalid-text">Segment name is required</div>
          </div>
        </div>
      </div>
      <div class="col-md-12">
        <div class="client-dropdown mt-1">
          <app-creatable-select
            [isCreatable]="false" [hideInfo]="true"
            [labelText]="'Select influencer type'" [selectedValue]="f['influencer_type_id'].value"
            [placeholder]="'Select influencer type'" [selectOptions]="influencerTypes"
            (optionSelected)="selectInfluencerType($event)"></app-creatable-select>
          <div *ngIf="submitted && f.influencer_type_id.errors" class="bar error-brd custom-error">
            <div *ngIf="f.influencer_type_id.errors.required" class="invalid-text">Influencer Type is required</div>
          </div>
        </div>
      </div>
    </div>
  </form>
</div>
<input type="hidden" ngbAutofocus>
<div class="modal-footer">
  <button type="button" class="btn btn-outline-secondary" (click)="modal.dismiss('cancel')">Cancel</button>
  <button type="button" class="btn btn-primary"
          (click)="saveSegment()">{{isUpdate ? 'Update' : 'Save'}}</button>
</div>
