<div class="modal-header">
  <h4 class="modal-title" id="modal-title">{{influencerName}}'s Referral list</h4>
  <button type="button" class="close" aria-label="Close button" aria-describedby="modal-title"
          (click)="modal.dismiss('cancel')">
    <span aria-hidden="true">&times;</span>
  </button>
</div>
<div class="modal-body">
  <div class="d-flex flex-wrap px-4 py-3">
    <div class='customize-email d-flex align-items-center ml-auto'>
      <div class="mr-2">
        <input id="show_all" placeholder="" [checked]="showAll"
               (change)="showAllColumns($event)" type="checkbox">
        <label for="show_all">Display all columns</label>
      </div>
    </div>
  </div>
  <div class="custom-table">
    <table width="100%" class="sb-table table dataTable no-footer nowrap" [dtTrigger]="dtTrigger" datatable
           [dtOptions]="dtOptions"
           id="referral-table">
      <thead>
      <tr>
        <th>Name</th>
        <th>Email</th>
        <th>Influencer type</th>
        <th>Segment</th>
        <th>Registration Url</th>
        <th>Company</th>
        <th [ngClass]="{'d-none': !showAll}">First name</th>
        <th [ngClass]="{'d-none': !showAll}">Last name</th>
        <th [ngClass]="{'d-none': !showAll}">Phone</th>
        <th [ngClass]="{'d-none': !showAll}">Video Url</th>
        <th [ngClass]="{'d-none': !showAll}">Booth Number</th>
        <th [ngClass]="{'d-none': !showAll}">Job Title</th>
        <th [ngClass]="{'d-none': !showAll}">Sessions</th>
        <th [ngClass]="{'d-none': !showAll}">Custom 1</th>
        <th [ngClass]="{'d-none': !showAll}">Custom 2</th>
        <th [ngClass]="{'d-none': !showAll}">Custom 3</th>
        <th [ngClass]="{'d-none': !showAll}">Custom 4</th>
        <th [ngClass]="{'d-none': !showAll}">Custom 5</th>
        <th [ngClass]="{'d-none': !showAll}">Custom 6</th>
        <th [ngClass]="{'d-none': !showAll}">Custom 7</th>
        <th [ngClass]="{'d-none': !showAll}">Custom 8</th>
        <th [ngClass]="{'d-none': !showAll}">Custom 9</th>
        <th [ngClass]="{'d-none': !showAll}">Custom 10</th>
      </tr>
      </thead>
      <tbody *ngIf="influencersList?.length > 0">
      <tr *ngFor="let influencer of influencersList">
        <td>{{influencer.name}}</td>
        <td>{{influencer.email}}</td>
        <td>{{getInfluencerType(influencer.influencer_type_id)}}</td>
        <td>{{getInfluencerSegment(influencer.project_influencer_segment_id)}}</td>
        <td>{{influencer.registration_url}}</td>
        <td>{{influencer.company}}</td>
        <td [ngClass]="{'d-none': !showAll}">{{influencer.first_name}}</td>
        <td [ngClass]="{'d-none': !showAll}">{{influencer.last_name}}</td>
        <td [ngClass]="{'d-none': !showAll}">{{influencer.phone}}</td>
        <td [ngClass]="{'d-none': !showAll}">{{influencer.video_url}}</td>
        <td [ngClass]="{'d-none': !showAll}">{{influencer.booth_number}}</td>
        <td [ngClass]="{'d-none': !showAll}">{{influencer.job_title}}</td>
        <td [ngClass]="{'d-none': !showAll}">{{influencer.sessions}}</td>
        <td [ngClass]="{'d-none': !showAll}">{{influencer.custom_1}}</td>
        <td [ngClass]="{'d-none': !showAll}">{{influencer.custom_2}}</td>
        <td [ngClass]="{'d-none': !showAll}">{{influencer.custom_3}}</td>
        <td [ngClass]="{'d-none': !showAll}">{{influencer.custom_4}}</td>
        <td [ngClass]="{'d-none': !showAll}">{{influencer.custom_5}}</td>
        <td [ngClass]="{'d-none': !showAll}">{{influencer.custom_6}}</td>
        <td [ngClass]="{'d-none': !showAll}">{{influencer.custom_7}}</td>
        <td [ngClass]="{'d-none': !showAll}">{{influencer.custom_8}}</td>
        <td [ngClass]="{'d-none': !showAll}">{{influencer.custom_9}}</td>
        <td [ngClass]="{'d-none': !showAll}">{{influencer.custom_10}}</td>
      </tr>
      </tbody>
      <tbody *ngIf="influencersList?.length <= 0">
      <tr class="no-data-available-custom">
        <td colspan="6">No data available in table</td>
      </tr>
      </tbody>
    </table>
  </div>
</div>
<input type="hidden" ngbAutofocus>
<div class="modal-footer">
  <button type="button" class="btn btn-default" (click)="modal.close()">Close</button>
</div>
