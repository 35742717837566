import {Injectable} from '@angular/core';
import {environment} from '@environments/environment';
import {HttpClient} from '@angular/common/http';
import {Segment} from '@app/modules/shared/models/segment';

@Injectable({providedIn: 'root'})
export class SegmentService {
  private baseApiUrl = environment.apiUrl;
  private segmentUrl = this.baseApiUrl + '/api/v1/segment';

  constructor(private http: HttpClient) {
  }

  getSegments(params: any = {}) {
    return this.http.get(`${this.segmentUrl}`, {params});
  }

  deleteSegment(SegmentId: number) {
    return this.http.delete(`${this.segmentUrl}/${SegmentId}`);
  }

  updateSegment(SegmentId: number, segment: Segment) {
    return this.http.put(`${this.segmentUrl}/${SegmentId}`, segment);
  }

  addSegment(segment: Segment) {
    return this.http.post(`${this.segmentUrl}`, segment);
  }
}
