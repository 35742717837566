import {Injectable} from '@angular/core';
import {Actions, createEffect, ofType} from '@ngrx/effects';
import {Store} from '@ngrx/store';
import {catchError, map, switchMap} from 'rxjs/operators';
import {of} from 'rxjs';
import {LoaderService} from '@app/services/loader.service';
import {
  InfluencersAdd,
  InfluencersAddError,
  InfluencersAddSuccess,
  InfluencersCsvToValues,
  InfluencersCsvToValuesError,
  InfluencersCsvToValuesSuccess,
  InfluencersImportHeaders,
  InfluencersImportHeadersError,
  InfluencersImportHeadersSuccess,
  InfluencersImport,
  InfluencersImportError,
  InfluencersImportSuccess,
  InfluencersList,
  InfluencersListByProject,
  InfluencersListByProjectError,
  InfluencersListByProjectSuccess,
  InfluencersListError,
  InfluencersListSuccess,
  InfluencersProjectSegment,
  InfluencersProjectSegmentError,
  InfluencersProjectSegmentSuccess,
  InfluencersSegmentUpdate,
  InfluencersSegmentUpdateError,
  InfluencersSegmentUpdateSuccess,
  InfluencersValidateImport,
  InfluencersValidateImportError,
  InfluencersValidateImportSuccess,
  InfluencersUpdate,
  InfluencersUpdateSuccess,
  InfluencersUpdateError,
  InfluencersGet,
  InfluencersGetSuccess,
  InfluencersGetError,
  InfluencersTypeList,
  InfluencersTypeListSuccess,
  InfluencersTypeListError,
  InfluencersDelete,
  InfluencersDeleteSuccess,
  InfluencersDeleteError,
  InfluencersImportStatus,
  InfluencersImportStatusSuccess,
  InfluencersImportStatusError,
  InfluencersExport,
  InfluencersExportSuccess,
  InfluencersExportError,
  InfluencersReferralsList,
  InfluencersReferralsListSuccess,
  InfluencersReferralsListError,
  InfluencersListByProjectPaginate,
  InfluencersListByProjectPaginateSuccess,
  InfluencersListByProjectPaginateError,
  InfluencersReferralsListPaginated,
  InfluencersReferralsListPaginatedSuccess,
  InfluencersReferralsListPaginatedError,
  InfluencersTaskLogs,
  InfluencersTaskLogsSuccess,
  InfluencersTaskLogsError,
  InfluencersBulk,
  InfluencersBulkSuccess,
  InfluencersBulkError,
  InfluencersBulkStatus,
  InfluencersBulkStatusSuccess,
  InfluencersBulkStatusError,
  InfluencersImportFields,
  InfluencersImportFieldsSuccess, InfluencersImportFieldsError
} from '@app/stores/influencers/influencers.actions';
import {IInfluencersState} from '@app/stores/influencers/influencers.state';
import {InfluencerService} from '@app/services/influencer.service';

@Injectable()
export class InfluencersEffects {
  constructor(
    private _actions$: Actions,
    private _store: Store<IInfluencersState>,
    private influencerService: InfluencerService,
    private loaderService: LoaderService
  ) {
  }

  influencerList$ = createEffect(() => this._actions$.pipe(
    ofType(InfluencersList),
    switchMap((action) => {
      this.loaderService.show();
      return this.influencerService.getAll().pipe(
        map((resp: any) => {
          if (resp.success) {
            this.loaderService.hide();
            return InfluencersListSuccess({influencers: resp.data});
          }
          this.loaderService.hide(true);
          return InfluencersListError({error: this.loaderService.getErrorMessage(resp)});
        }),
        catchError(error => {
          this.loaderService.hide(true);
          return of(InfluencersListError({error: this.loaderService.getErrorMessage(error)}));
        })
      );
    })
  ));

  influencerListByProject$ = createEffect(() => this._actions$.pipe(
    ofType(InfluencersListByProject),
    switchMap((action) => {
      this.loaderService.show();
      return this.influencerService.getAll({
        project_id: action.projectId, campaign_id: action.campaignId,
        ...(action.options ? {options: action.options} : {})
      }).pipe(
        map((resp: any) => {
          if (resp.success) {
            this.loaderService.hide();
            return InfluencersListByProjectSuccess({influencers: resp.data});
          }
          this.loaderService.hide(true);
          return InfluencersListByProjectError({error: this.loaderService.getErrorMessage(resp)});
        }),
        catchError(error => {
          this.loaderService.hide(true);
          return of(InfluencersListByProjectError({error: this.loaderService.getErrorMessage(error)}));
        })
      );
    })
  ));

  influencerListByProjectPaginate$ = createEffect(() => this._actions$.pipe(
    ofType(InfluencersListByProjectPaginate),
    switchMap((action) => {
      this.loaderService.show();
      return this.influencerService.getAll(action.params).pipe(
        map((resp: any) => {
          if (resp.success) {
            this.loaderService.hide();
            return InfluencersListByProjectPaginateSuccess({paginatedInfluencers: resp.data});
          }
          this.loaderService.hide(true);
          return InfluencersListByProjectPaginateError({error: this.loaderService.getErrorMessage(resp)});
        }),
        catchError(error => {
          this.loaderService.hide(true);
          return of(InfluencersListByProjectPaginateError({error: this.loaderService.getErrorMessage(error)}));
        })
      );
    })
  ));

  influencersProjectSegment$ = createEffect(() => this._actions$.pipe(
    ofType(InfluencersProjectSegment),
    switchMap((action) => {
      this.loaderService.show();
      return this.influencerService.getSegments({project_id: action.projectId}).pipe(
        map((resp: any) => {
          if (resp.success) {
            this.loaderService.hide();
            return InfluencersProjectSegmentSuccess({segments: resp.data});
          }
          this.loaderService.hide(true);
          return InfluencersProjectSegmentError({error: this.loaderService.getErrorMessage(resp)});
        }),
        catchError(error => {
          this.loaderService.hide(true);
          return of(InfluencersProjectSegmentError({error: this.loaderService.getErrorMessage(error)}));
        })
      );
    })
  ));

  influencersProjectSegmentUpdate$ = createEffect(() => this._actions$.pipe(
    ofType(InfluencersSegmentUpdate),
    switchMap((action) => {
      this.loaderService.show();
      return this.influencerService.updateSegment(action.oldGroup, action.oldName, action.newName).pipe(
        map((resp: any) => {
          if (resp.success) {
            this.loaderService.hide();
            return InfluencersSegmentUpdateSuccess();
          }
          this.loaderService.hide(true);
          return InfluencersSegmentUpdateError({error: this.loaderService.getErrorMessage(resp)});
        }),
        catchError(error => {
          this.loaderService.hide(true);
          return of(InfluencersSegmentUpdateError({error: this.loaderService.getErrorMessage(error)}));
        })
      );
    })
  ));

  influencersValidateImport$ = createEffect(() => this._actions$.pipe(
    ofType(InfluencersValidateImport),
    switchMap((action) => {
      this.loaderService.show();
      return this.influencerService.validateInfluencers(action.file).pipe(
        map((resp: any) => {
          if (resp.success) {
            this.loaderService.hide();
            return InfluencersValidateImportSuccess({influencersImport: resp.data});
          }
          this.loaderService.hide(true);
          return InfluencersValidateImportError({error: this.loaderService.getErrorMessage(resp)});
        }),
        catchError(error => {
          this.loaderService.hide(true);
          return of(InfluencersValidateImportError({error: this.loaderService.getErrorMessage(error)}));
        })
      );
    })
  ));

  influencersCsvToValues$ = createEffect(() => this._actions$.pipe(
    ofType(InfluencersCsvToValues),
    switchMap((action) => {
      this.loaderService.show();
      return this.influencerService.csvToValuesInfluencers(action.file).pipe(
        map((resp: any) => {
          if (resp.success) {
            this.loaderService.hide();
            return InfluencersCsvToValuesSuccess({influencersCsvToValues: resp.data});
          }
          this.loaderService.hide(true);
          return InfluencersCsvToValuesError({error: this.loaderService.getErrorMessage(resp)});
        }),
        catchError((error: any) => {
          console.log(error);
          this.loaderService.hide(true);
          return of(InfluencersCsvToValuesSuccess({influencersCsvToValues: error.error}));
        })
      );
    })
  ));


  influencersImportHeaders$ = createEffect(() => this._actions$.pipe(
    ofType(InfluencersImportHeaders),
    switchMap((action) => {
      this.loaderService.show();
      return this.influencerService.importHeadersInfluencers(action.data).pipe(
        map((resp: any) => {
          if (resp.success) {
            this.loaderService.hide();
            return InfluencersImportHeadersSuccess({influencersImportHeaders: resp.data});
          }
          this.loaderService.hide(true);
          return InfluencersImportHeadersError({error: this.loaderService.getErrorMessage(resp)});
        }),
        catchError((error: any) => {
          this.loaderService.hide(true);
          return of(InfluencersImportHeadersError({error: error?.error?.message}));
        })
      );
    })
  ));

  influencersImport$ = createEffect(() => this._actions$.pipe(
    ofType(InfluencersImport),
    switchMap((action) => {
      this.loaderService.show();
      return this.influencerService.importInfluencers(action.file).pipe(
        map((resp: any) => {
          if (resp.success) {
            this.loaderService.hide();
            return InfluencersImportSuccess({influencersImport: resp.data});
          }
          this.loaderService.hide(true);
          return InfluencersImportError({error: this.loaderService.getErrorMessage(resp)});
        }),
        catchError((error: any) => {
          console.log(error);
          this.loaderService.hide(true);
          // return of(InfluencersImportSuccess({influencersImport: error.error}));
          return of(InfluencersImportError({error: this.loaderService.getErrorMessage(error)}));
        })
      );
    })
  ));

  influencersAdd$ = createEffect(() => this._actions$.pipe(
    ofType(InfluencersAdd),
    switchMap((action) => {
      this.loaderService.show();
      return this.influencerService.addInfluencer(action.influencer).pipe(
        map((resp: any) => {
          if (resp.success) {
            this.loaderService.hide();
            return InfluencersAddSuccess();
          }
          this.loaderService.hide(true);
          return InfluencersAddError({error: this.loaderService.getErrorMessage(resp)});
        }),
        catchError(error => {
          this.loaderService.hide(true);
          return of(InfluencersAddError({error: this.loaderService.getErrorMessage(error)}));
        })
      );
    })
  ));

  influencersUpdate$ = createEffect(() => this._actions$.pipe(
    ofType(InfluencersUpdate),
    switchMap((action) => {
      this.loaderService.show();
      return this.influencerService.updateInfluencer(action.influencer, action.influencerId).pipe(
        map((resp: any) => {
          if (resp.success) {
            this.loaderService.hide();
            return InfluencersUpdateSuccess({influencer: resp.data});
          }
          this.loaderService.hide(true);
          return InfluencersUpdateError({error: this.loaderService.getErrorMessage(resp)});
        }),
        catchError(error => {
          this.loaderService.hide(true);
          return of(InfluencersUpdateError({error: this.loaderService.getErrorMessage(error)}));
        })
      );
    })
  ));

  influencersGet$ = createEffect(() => this._actions$.pipe(
    ofType(InfluencersGet),
    switchMap((action) => {
      this.loaderService.show();
      return this.influencerService.getById(action.influencerId).pipe(
        map((resp: any) => {
          if (resp.success) {
            this.loaderService.hide();
            return InfluencersGetSuccess({influencer: resp.data});
          }
          this.loaderService.hide(true);
          return InfluencersGetError({error: this.loaderService.getErrorMessage(resp)});
        }),
        catchError(error => {
          this.loaderService.hide(true);
          return of(InfluencersGetError({error: this.loaderService.getErrorMessage(error)}));
        })
      );
    })
  ));

  influencersTypeList$ = createEffect(() => this._actions$.pipe(
    ofType(InfluencersTypeList),
    switchMap((action) => {
      this.loaderService.show();
      return this.influencerService.getInfluencerType(action.params).pipe(
        map((resp: any) => {
          if (resp.success) {
            this.loaderService.hide();
            return InfluencersTypeListSuccess({influencerTypes: resp.data});
          }
          this.loaderService.hide(true);
          return InfluencersTypeListError({error: this.loaderService.getErrorMessage(resp)});
        }),
        catchError(error => {
          this.loaderService.hide(true);
          return of(InfluencersTypeListError({error: this.loaderService.getErrorMessage(error)}));
        })
      );
    })
  ));

  influencersDelete$ = createEffect(() => this._actions$.pipe(
    ofType(InfluencersDelete),
    switchMap((action) => {
      this.loaderService.show();
      return this.influencerService.deleteInfluencer(action.influencerId).pipe(
        map((resp: any) => {
          if (resp.success) {
            this.loaderService.hide();
            return InfluencersDeleteSuccess();
          }
          this.loaderService.hide(true);
          return InfluencersDeleteError({error: this.loaderService.getErrorMessage(resp)});
        }),
        catchError(error => {
          this.loaderService.hide(true);
          return of(InfluencersDeleteError({error: this.loaderService.getErrorMessage(error)}));
        })
      );
    })
  ));

  influencersImportStatus$ = createEffect(() => this._actions$.pipe(
    ofType(InfluencersImportStatus),
    switchMap((action) => {
      this.loaderService.show();
      return this.influencerService.importInfluencersStatus(action.importJobId).pipe(
        map((resp: any) => {
          if (resp.success) {
            this.loaderService.hide();
            return InfluencersImportStatusSuccess({influencersImport: resp.data});
          }
          this.loaderService.hide(true);
          return InfluencersImportStatusError({error: this.loaderService.getErrorMessage(resp)});
        }),
        catchError((error: any) => {
          console.log(error);
          this.loaderService.hide(true);
          return of(InfluencersImportStatusSuccess({influencersImport: error.error}));
        })
      );
    })
  ));

  influencersExport$ = createEffect(() => this._actions$.pipe(
    ofType(InfluencersExport),
    switchMap((action) => {
      this.loaderService.show();
      return this.influencerService.exportInfluencers(action.data).pipe(
        map((resp: any) => {
          if (resp.success) {
            this.loaderService.hide();
            return InfluencersExportSuccess({influencersExport: resp.data});
          }
          this.loaderService.hide(true);
          return InfluencersExportError({error: this.loaderService.getErrorMessage(resp)});
        }),
        catchError((error: any) => {
          this.loaderService.hide(true);
          return of(InfluencersExportError({error: this.loaderService.getErrorMessage(error)}));
        })
      );
    })
  ));

  influencersReferralsList$ = createEffect(() => this._actions$.pipe(
    ofType(InfluencersReferralsList),
    switchMap((action) => {
      this.loaderService.show();
      return this.influencerService.getInfluencerReferralsList(action.influencerId).pipe(
        map((resp: any) => {
          if (resp.success) {
            this.loaderService.hide();
            return InfluencersReferralsListSuccess({referralsList: resp.data});
          }
          this.loaderService.hide(true);
          return InfluencersReferralsListError({error: this.loaderService.getErrorMessage(resp)});
        }),
        catchError((error: any) => {
          this.loaderService.hide(true);
          return of(InfluencersReferralsListError({error: this.loaderService.getErrorMessage(error)}));
        })
      );
    })
  ));

  influencersReferralsListPaginated$ = createEffect(() => this._actions$.pipe(
    ofType(InfluencersReferralsListPaginated),
    switchMap((action) => {
      this.loaderService.show();
      return this.influencerService.getInfluencerReferralsList(action.influencerId, action.params).pipe(
        map((resp: any) => {
          if (resp.success) {
            this.loaderService.hide();
            return InfluencersReferralsListPaginatedSuccess({paginatedReferralsList: resp.data});
          }
          this.loaderService.hide(true);
          return InfluencersReferralsListPaginatedError({error: this.loaderService.getErrorMessage(resp)});
        }),
        catchError((error: any) => {
          this.loaderService.hide(true);
          return of(InfluencersReferralsListPaginatedError({error: this.loaderService.getErrorMessage(error)}));
        })
      );
    })
  ));

  influencersTaskLogs$ = createEffect(() => this._actions$.pipe(
    ofType(InfluencersTaskLogs),
    switchMap((action) => {
      this.loaderService.show();
      return this.influencerService.taskInfluencersLogs(action.params).pipe(
        map((resp: any) => {
          if (resp.success) {
            this.loaderService.hide();
            return InfluencersTaskLogsSuccess({influencersTaskLogs: resp.data});
          }
          this.loaderService.hide(true);
          return InfluencersTaskLogsError({error: this.loaderService.getErrorMessage(resp)});
        }),
        catchError((error: any) => {
          console.log(error);
          this.loaderService.hide(true);
          return of(InfluencersTaskLogsError({error: error.error}));
        })
      );
    })
  ));

  influencersBulk$ = createEffect(() => this._actions$.pipe(
    ofType(InfluencersBulk),
    switchMap((action) => {
      this.loaderService.show();
      return this.influencerService.bulkInfluencerOperations(action.data).pipe(
        map((resp: any) => {
          if (resp.success) {
            this.loaderService.hide();
            return InfluencersBulkSuccess({influencerBulk: resp.data});
          }
          this.loaderService.hide(true);
          return InfluencersBulkError({error: this.loaderService.getErrorMessage(resp)});
        }),
        catchError((error: any) => {
          console.log(error);
          this.loaderService.hide(true);
          return of(InfluencersBulkSuccess({influencerBulk: error.error}));
        })
      );
    })
  ));

  influencersBulkStatus$ = createEffect(() => this._actions$.pipe(
    ofType(InfluencersBulkStatus),
    switchMap((action) => {
      this.loaderService.show();
      return this.influencerService.bulkInfluencersStatus(action.importJobId).pipe(
        map((resp: any) => {
          if (resp.success) {
            this.loaderService.hide();
            return InfluencersBulkStatusSuccess({influencerBulk: resp.data});
          }
          this.loaderService.hide(true);
          return InfluencersBulkStatusError({error: this.loaderService.getErrorMessage(resp)});
        }),
        catchError((error: any) => {
          console.log(error);
          this.loaderService.hide(true);
          return of(InfluencersBulkStatusSuccess({influencerBulk: error.error}));
        })
      );
    })
  ));

  influencersImportFields$ = createEffect(() => this._actions$.pipe(
    ofType(InfluencersImportFields),
    switchMap((action) => {
      this.loaderService.show();
      return this.influencerService.importInfluencersFields(action.influencerTypeId).pipe(
        map((resp: any) => {
          if (resp.success) {
            this.loaderService.hide();
            return InfluencersImportFieldsSuccess({influencersImportFields: resp.data});
          }
          this.loaderService.hide(true);
          return InfluencersImportFieldsError({error: this.loaderService.getErrorMessage(resp)});
        }),
        catchError((error: any) => {
          console.log(error);
          this.loaderService.hide(true);
          return of(InfluencersImportFieldsError({error: error.error}));
        })
      );
    })
  ));

}
