import {createAction, props} from '@ngrx/store';
import {PaginatedUser, User} from '@app/modules/shared/models';

enum EUserActions {
  UsersList = '[Users] Users List',
  UsersListSuccess = '[Users] Users List Success',
  UsersListError = '[Users] Users List Error',
  UsersListPaginated = '[Users] Users List Paginated',
  UsersListPaginatedSuccess = '[Users] Users List Paginated Success',
  UsersListPaginatedError = '[Users] Users List Paginated Error',
  UsersAdd = '[Users] Users Add',
  UsersAddSuccess = '[Users] Users Add Success',
  UsersAddError = '[Users] Users Add Error',
  UsersUpdate = '[Users] Users Update',
  UsersUpdateSuccess = '[Users] Users Update Success',
  UsersUpdateError = '[Users] Users Update Error',
  UsersDelete = '[Users] Users Delete',
  UsersDeleteSuccess = '[Users] Users Delete Success',
  UsersDeleteError = '[Users] Users Delete Error',
  UsersGet = '[Users] Users Get',
  UsersGetSuccess = '[Users] Users Get Success',
  UsersGetError = '[Users] Users Get Error',
  UsersRoles = '[Users] Users Roles',
  UsersRolesSuccess = '[Users] Users Roles Success',
  UsersRolesError = '[Users] Users Roles Error',

  UserLoginInfoMail = '[Users] Send Users login Info Mail',
  UserLoginInfoMailSuccess = '[Users] Send Users login Info Mail Success',
  UserLoginInfoMailError = '[Users] Send Users login Info Mail Error',

  ResetUserState = '[Users] Reset Users State',
}

export const UsersList = createAction(EUserActions.UsersList, (params: any = {}) => params);
export const UsersListSuccess = createAction(EUserActions.UsersListSuccess, props<{ users: User[] }>());
export const UsersListError = createAction(EUserActions.UsersListError, props<{ error: string }>());

export const UsersListPaginated = createAction(EUserActions.UsersListPaginated, (params: any = {}) => params);
export const UsersListPaginatedSuccess = createAction(EUserActions.UsersListPaginatedSuccess, props<{ paginatedUsers: PaginatedUser }>());
export const UsersListPaginatedError = createAction(EUserActions.UsersListPaginatedError, props<{ error: string }>());

export const UsersAdd = createAction(EUserActions.UsersAdd, props<{ body: any }>());
export const UsersAddSuccess = createAction(EUserActions.UsersAddSuccess);
export const UsersAddError = createAction(EUserActions.UsersAddError, props<{ error: string }>());

export const UsersUpdate = createAction(EUserActions.UsersUpdate, props<{ userId: number, body: any }>());
export const UsersUpdateSuccess = createAction(EUserActions.UsersUpdateSuccess, props<{ updateUser: User }>());
export const UsersUpdateError = createAction(EUserActions.UsersUpdateError, props<{ error: string }>());

export const UsersDelete = createAction(EUserActions.UsersDelete, props<{ userId: number }>());
export const UsersDeleteSuccess = createAction(EUserActions.UsersDeleteSuccess);
export const UsersDeleteError = createAction(EUserActions.UsersDeleteError, props<{ error: string }>());

export const UsersGet = createAction(EUserActions.UsersGet, props<{ userId: number }>());
export const UsersGetSuccess = createAction(EUserActions.UsersGetSuccess, props<{ user: User }>());
export const UsersGetError = createAction(EUserActions.UsersGetError, props<{ error: string }>());

export const UsersRoles = createAction(EUserActions.UsersRoles);
export const UsersRolesSuccess = createAction(EUserActions.UsersRolesSuccess, props<{ roles: any }>());
export const UsersRolesError = createAction(EUserActions.UsersRolesError, props<{ error: string }>());

export const UserLoginInfoMail = createAction(EUserActions.UserLoginInfoMail, props<{ userId: number }>())
export const UserLoginInfoMailSuccess = createAction(EUserActions.UserLoginInfoMailSuccess)
export const UserLoginInfoMailError = createAction(EUserActions.UserLoginInfoMailError, props<{ error: string }>())

export const ResetUserState = createAction(EUserActions.ResetUserState, (params: any = {}) => params);

