import {Injectable} from '@angular/core';
import {environment} from '@environments/environment';
import {HttpClient} from '@angular/common/http';

@Injectable({providedIn: 'root'})
export class VariableService {
  private baseApiUrl = environment.apiUrl;
  private variableUrl = this.baseApiUrl + '/api/v1/variable';

  constructor(private http: HttpClient) {
  }

  list(params) {
    return this.http.get(`${this.variableUrl}`, {params});
  }
}
