import {PaginatedTemplateSection, TemplateSection, TemplateSectionType} from '@app/modules/shared/models';

export interface ITemplateSectionState {
  templateSection: TemplateSection;
  templateSectionAssets: any;
  templateSections: TemplateSection[];
  templateSectionTypes: TemplateSectionType[];
  paginatedTemplateSection: PaginatedTemplateSection;
  success: string;
  error: string;
}

export const initialTemplateSectionState: ITemplateSectionState = {
  templateSection: null,
  templateSections: [],
  templateSectionAssets: null,
  templateSectionTypes: [],
  paginatedTemplateSection: null,
  success: '',
  error: ''
};
