import {createFeatureSelector, createSelector} from '@ngrx/store';
import {CampaignValidation, ICampaignState, IInfluencersState} from '@app/stores';

const selectCampaign = createFeatureSelector<ICampaignState>('campaign');

export const getCampaign = createSelector(selectCampaign, (state: ICampaignState) => state.campaign);

export const getCampaignSuccess = createSelector(selectCampaign, (state: ICampaignState) => state.success);

export const getCampaignError = createSelector(selectCampaign, (state: ICampaignState) => state.error);

export const getCampaigns = createSelector(selectCampaign, (state: ICampaignState) => state.campaigns);

export const getCampaignUpdate = createSelector(selectCampaign, (state: ICampaignState) => state.campaignUpdate);

export const getCampaignAdd = createSelector(selectCampaign, (state: ICampaignState) => state.campaignAdd);

export const getCampaignLaunch = createSelector(selectCampaign, (state: ICampaignState) => state.campaignLaunch);

export const getCampaignValidation = createSelector(selectCampaign, (state: ICampaignState) => state.campaignValidation);

export const getPaginatedCampaigns = createSelector(selectCampaign, (state: ICampaignState) => state.paginatedCampaigns);

export const getCampaignLaunchCode = createSelector(selectCampaign, (state: ICampaignState) => state.campaignLaunchCode);

export const getCancelCampaignLaunch = createSelector(selectCampaign, (state: ICampaignState) => state.campaign);

export const getCancelCampaignLaunchError = createSelector(selectCampaign, (state: ICampaignState) => state.error);
