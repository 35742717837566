import { NgModule } from '@angular/core';
import { CommonModule, DatePipe } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgxMaterialTimepickerModule } from 'ngx-material-timepicker';
import { BsDatepickerModule } from 'ngx-bootstrap/datepicker';
import { PopoverModule } from 'ngx-bootstrap/popover';
import { SharedModule } from '../shared/shared.module';
import { MatLegacyMenuModule as MatMenuModule } from '@angular/material/legacy-menu';

import { CampaignRoutingModule } from './campaign-routing.module';
import { CampaignsComponent } from './campaigns/campaigns.component';
import { CreateCampaignComponent } from '@app/modules/campaign/create-campaign/create-campaign.component';
import { StatusBarModule } from '@app/modules/status-bar/status-bar.module';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';

import { PaginationModule } from 'ngx-bootstrap/pagination';

import { CampaignFilterPipe } from './campaigns/campaign-filter-pipe';
import { CampaignCopyComponent } from './campaign-copy/campaign-copy.component';
import {DataTablesModule} from 'angular-datatables';
import {NgbPopoverModule} from '@ng-bootstrap/ng-bootstrap';
import { RenameTitleModalModule } from '../rename-title-modal/rename-title-modal.module';

@NgModule ({
    declarations: [
        CampaignsComponent,
        CreateCampaignComponent,
        CampaignFilterPipe,
        CampaignCopyComponent
    ],
  imports: [
    CommonModule,
    CampaignRoutingModule,
    SharedModule,
    NgxMaterialTimepickerModule,
    BsDatepickerModule,
    FormsModule,
    ReactiveFormsModule,
    StatusBarModule,
    FontAwesomeModule,
    PopoverModule.forRoot(),
    PaginationModule.forRoot(),
    DataTablesModule,
    NgbPopoverModule,
    RenameTitleModalModule,
    MatMenuModule
  ],
    providers   : [ DatePipe ]
})
export class CampaignModule {
}
