import {Client, PaginatedClient} from '@app/modules/shared/models';

export interface IClientState {
  client: Client;
  clients: Client[];
  paginatedClients: PaginatedClient;
  success: string;
  error: string;
}

export const initialClientState: IClientState = {
  client: null,
  clients: [],
  paginatedClients: null,
  success: '',
  error: ''
};
