import {Action, createReducer, on} from '@ngrx/store';
import {
  PackagesListAll,
  PackagesListAllSuccess,
  PackagesListAllError,
  ResetPackageState, PackagesListPaginated, PackagesListPaginatedSuccess, PackagesListPaginatedError,
  PackagesAdd,
  PackagesAddError,
  PackagesAddSuccess,
  PackagesDelete, PackagesDeleteError,
  PackagesDeleteSuccess,
  PackagesUpdate,
  PackagesUpdateError,
  PackagesUpdateSuccess,
  PackagesGet, PackagesGetError, PackagesGetSuccess
} from '@app/stores/package/package.actions';
import {initialPackageState} from '@app/stores/package/package.state';

const generateReducer = createReducer(initialPackageState,
  on(PackagesListAll, (state) => ({
    ...state,
    pkg: null,
    packages: [],
    success: '',
    error: ''
  })),
  on(PackagesListAllSuccess, (state, {packages}) => ({
    ...state,
    pkg: null,
    packages,
    success: '',
    error: ''
  })),
  on(PackagesListAllError, (state, {error}) => ({
    ...state,
    pkg: null,
    packages: [],
    success: '',
    error
  })),

  on(PackagesListPaginated, (state) => ({
    ...state,
    pkg: null,
    paginatedPackages: null,
    success: '',
    error: ''
  })),
  on(PackagesListPaginatedSuccess, (state, {paginatedPackages}) => ({
    ...state,
    pkg: null,
    paginatedPackages,
    success: '',
    error: ''
  })),
  on(PackagesListPaginatedError, (state, {error}) => ({
    ...state,
    pkg: null,
    paginatedPackages: null,
    success: '',
    error
  })),

  on(PackagesAdd, (state) => ({
    ...state,
    success: '',
    error: '',
    updatePackage: null,
  })),
  on(PackagesAddSuccess, (state) => ({
    ...state,
    success: 'Package added successfully',
    error: ''
  })),
  on(PackagesAddError, (state, {error}) => ({
    ...state,
    success: '',
    updatePackage: null,
    error
  })),

  on(PackagesUpdate, (state) => ({
    ...state,
    success: '',
    error: '',
    updatePackage: null,
  })),
  on(PackagesUpdateSuccess, (state, {updatePackage}) => ({
    ...state,
    success: 'Package updated successfully',
    error: '',
    updatePackage
  })),
  on(PackagesUpdateError, (state, {error}) => ({
    ...state,
    success: '',
    error,
    updatePackage: null,
  })),

  on(PackagesDelete, (state) => ({
    ...state,
    success: '',
    error: '',
    updatePackage: null,
  })),
  on(PackagesDeleteSuccess, (state) => ({
    ...state,
    success: 'Package deleted successfully',
    error: ''
  })),
  on(PackagesDeleteError, (state, {error}) => ({
    ...state,
    success: '',
    error
  })),

  on(PackagesGet, (state) => ({
    ...state,
    pkg: null,
    updatePackage: null,
    success: '',
    error: ''
  })),
  on(PackagesGetSuccess, (state, {pkg}) => ({
    ...state,
    pkg,
    updatePackage: null,
    success: '',
    error: ''
  })),
  on(PackagesGetError, (state, {error}) => ({
    ...state,
    pkg: null,
    updatePackage: null,
    success: '',
    error
  })),

  on(ResetPackageState, (state, {params}) => ({
    ...state,
    ...params
  })),
);

export function packageReducer(state = initialPackageState, action: Action) {
  return generateReducer(state, action);
}
