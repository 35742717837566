<div class="container-fluid pt-3 mobile-p0 campaign-creation-steps">
  <div class="rightBody">
    <div class="row" *ngIf="campaignId || audienceType">

      <div class="col-12">
        <app-status-bar [loading]="loader$ | async" [step]="step" [skipLanding]="['direct'].includes(linkType)" (SaveButtonClick)="onSaveButtonClick()">
        </app-status-bar>
      </div>
      <div class="col-4 d-flex" style="justify-content: flex-end;">

        <img width="100px" height="100px" src="../../../../assets/images/project-default.png">
      </div>
      <div class="col-6 mx-auto create-campaign ">
      <form (ngSubmit)="onSubmit(false)" [formGroup]="campaignForm"
        class="needs-validation" enctype="multipart/form-data" novalidate>
<!--        <h4 class="font-weight-bold">{{campaignId ? 'Edit' : 'Create'}} Campaign</h4>-->
        <h3 class="mt-4">Let's start a campaign</h3>
        <!--<span class="header-text">Consider incentivizing your influencers while co-marketing with you. It's a win-win. <br> <b> Now
            name your campaign, don't worry, you can edit it later.</b></span>-->
        <div class="campaign-name">
          <input formControlName="id" type="hidden"/>
          <div class="d-flex justify-content-between">
            <label class="blue-msg">Campaign Name</label>
            <span class="d-flex align-items-end"
                  *ngIf="submitted && f.name?.errors?.required ">
            <small class="required-label mb-2">Campaign name is required*</small>
          </span>
          </div>
          <input class="w-100 px-2" formControlName="name" required type="text"/>


          <!--
          <label>From Email</label>
          <input class="w-100 px-2" formControlName="email" required type="text" />
          <div class="text-right" *ngIf="f.submitted && f.form.controls.email.errors && f.form.controls.email.errors.required">
            <small class="required-label">From email is required</small>
          </div>
          <label>Email Subject</label>
          <input class="w-100 px-2" formControlName="subject_line" required type="text" />
          <div class="text-right" *ngIf="f.submitted && f.form.controls.subject_line.errors && f.form.controls.subject_line.errors.required">
            <small class="required-label">Email subject is required</small>
          </div>
          -->
        </div>

        <div class="campaign-name mt-2">
          <div class="d-flex justify-content-between">
            <div class="d-flex align-items-baseline">
              <label class="blue-msg">Call to Action (CTA) URL</label>
            </div>

          </div>
          <div class="w-100">
            <div class="ml-3">
              <div class="d-flex align-items-baseline justify-content-between">
                <label class="d-flex align-items-center mt-2">
                  Campaign Default CTA
                  <app-info-popover info="Default CTA is set in Project Details and is applied globally to all campaigns.
                You have the option to add a Custom CTA per campaign which would override the Default CTA"></app-info-popover>
                </label>
                <span class="d-flex align-items-end"
                      *ngIf="submitted && f.custom_cta_url.errors && f.custom_cta_url.errors.invalidUrl && !f.custom_cta_url.errors.required">
        <small class="required-label">Enter a valid CTA URL</small>
      </span>
                <span class="d-flex align-items-end"
                      *ngIf="submitted && f.custom_cta_url.errors && f.custom_cta_url.errors.required">
            <small class="required-label">Campaign Default CTA is required*</small>
          </span>
              </div>
              <div class="d-flex">
                <div class="w-100">
                  <input class="w-100 px-2" formControlName="custom_cta_url" type="text"
                         placeholder="{{currentProject?.website_url}}"/>
                </div>
              </div>
            </div>
          </div>
          <div class="w-100">
            <div class="ml-3">
              <div class="d-flex align-items-baseline">
                <label class="mt-2">Unique CTA by Influencer</label>
                <app-info-popover info="To use a unique CTA per influencer,  you can use Snöball custom variables fields.Ex: 'Unique URL' in Custom 1, you select the variable option and add influencerCustom1# field here."></app-info-popover>
              </div>
              <div class="d-flex">
                <div class="w-100">
                  <div class="floating-label input-group input-group-sm variable-input m-0">
                    <input class="w-100 px-2"  formControlName="influencer_dynamic_cta"
                           [ngClass]="{ 'is-invalid': submitted && f.influencer_dynamic_cta.errors }"  placeholder="Leave empty to use Campaign Default CTA" type="text"/>
                    <div class="inputs">
                      <app-variables
                        [variables]="variables"
                        (onVariableClick)="updateValue($event, 'influencer_dynamic_cta')"
                      ></app-variables>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </form>
        <div class="d-flex align-items-baseline">
          <label class="blue-msg">
            Campaign Destination
            <app-info-popover info="Where would you want to direct users to?"></app-info-popover>
          </label>
        </div>
        <div class="w-100">
          <div>
            <div class="d-flex align-items-baseline url-type">
              <label class="d-flex align-items-center mt-1">
                <input class="mr-2 " type="radio" value="direct" [(ngModel)]="linkType"> Direct Link
              </label>
              <app-info-popover info="Skip Snöball Landing Page and send users directly to your website"></app-info-popover>
            </div>
            <div class="ml-3 d-flex" *ngIf="linkType === 'direct'">
            </div>
          </div>
          <div>
            <div class="d-flex align-items-center url-type">
              <label class="d-flex align-items-center mt-1">
                <input *ngIf="linkType !== 'custom'" class="mr-2" type="radio" value="project" [(ngModel)]="linkType">
                <input *ngIf="linkType === 'custom'" class="mr-2" type="radio" value="custom" [(ngModel)]="linkType">
                Snöball Campaign Customized Landing Page
              </label>
              <app-info-popover class="custom-popoup" info="Choose from a library of conversion optimized landing pages or build out your own to maximize referrals and campaign results"></app-info-popover>
            </div>
            <ng-template #addCTA>
              <button (click)="addCustomCTA(addCustomCTAModal)" class="btn btn-sm w-100" type="button" role="button">
                <span class="btn-wrapper--label">Add Custom CTA URL</span>
              </button>
            </ng-template>
          </div>
        </div>
      </div>
      <div class="col-2">

      </div>


    </div>
    <div class="" *ngIf="!campaignId && !audienceType">
      <div class="mt-5 mb-5 text-center">
        <h2>
          Let's start a campaign!<br />
          Who do you want to mobilize to grow your event?
        </h2>
        <span class="text-secondary">Select an influencer type to start a campaign. Guess what? You can add multiple
          segments within the same category later.</span>
      </div>
      <div class="row pt-5 selection-container" style="max-width: 1200px; margin-left: auto; margin-right: auto">
        <div class="col-xl-4" *ngFor="let type of influencerTypes">
          <div class="card card-box p-4 mb-5 selection">
            <div class="text-center">
              <div class="avatar-icon-wrapper m-0">
                <div class="d-inline-flex justify-content-center p-0 avatar-icon-wrapper m-0 d-90">
                  <img class="invert"
                    src="../../../../assets/images/{{type.slug === 'partner' ? 'partners' : 'speakers'}}-white.png"
                    width="114" height="116" />
                  <img class="normal"
                    src="../../../../assets/images/{{type.slug === 'partner' ? 'partners' : 'speakers'}}.png"
                    width="114" height="116" />
                </div>
              </div>
              <h4 class="font-weight-bold mt-4">Your {{type.name}}</h4>
              <p class="mb-0 font-size-lg description">{{type.description}}</p>
              <div class="pt-4">
                <button class="btn btn-pill mx-1" type="button"
                  routerLink="/{{routes.createCampaignForAudienceType({projectId: projectId}, {audienceType: type.id})}}"
                  role="button">
                  <span class="btn-wrapper--label"> Choose </span>
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<div class="operation-box" *ngIf="campaignId || audienceType">
  <div class="col-md-12">
    <div class="row">
      <div class="col-md-2">
        <div class="text-left">
        </div>
      </div>
      <div class="col-md-2 d-flex justify-content-center">
        <div class="select-page-size">
        </div>
      </div>
      <div class="col-md-8">
        <div class="text-right">
          <button (click)="handleClickNext()" [disabled]="loader$ | async" class="btn btn-primary next-btn"
            type="submit">
            <span><i class="fa fa-spinner fa-spin mr-2" *ngIf="loader$ | async"></i>Next</span>
          </button>
        </div>
      </div>
    </div>
  </div>
</div>

<ng-template #addCustomCTAModal let-modal>
  <div class="modal-header">
    <h4 class="modal-title" id="modal-title">Add Custom CTA URL</h4>
    <button type="button" class="close" aria-label="Close button" aria-describedby="modal-title"
            (click)="modal.dismiss('cancel')">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <div class="floating-label">
      <input placeholder=" " class="floating-input" type="text" autocomplete="off" [(ngModel)]="customUrl"/>
      <label>CTA URL</label>
    </div>
    <label class="text-danger" *ngIf="customUrl && !isValidURL(customUrl)">Please enter valid URL</label>
  </div>
  <div class="modal-footer">
    <button type="button" class="btn btn-default" (click)="modal.close()">Close</button>
    <button type="button" class="btn btn-primary" [disabled]="!customUrl || !isValidURL(customUrl)" (click)="addCustomCTAOption(customUrl); modal.close()">Add</button>
  </div>
</ng-template>
