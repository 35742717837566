<div class="container-fluid pt-3 campaign-creation-steps">
  <div class="rightBody">
    <app-status-bar [loading]="saveCampaignLoading" [step]="step" [skipLanding]="campaign?.landing_page_status === 'skipped'" (SaveButtonClick)="validateSave(true)">
    </app-status-bar>
    <div toastContainer></div>
    <div class="app-influencer-list">
      <app-influencer-list (sendTestEmailsEmit)="sendTestEmails($event)" [selectedInfluencer]="selectedInfluencer"   [selectedSegment]="selectedSegment" (changeSelectedInfluencer)="changeSelectedInfluencer($event)"   (changeSelectedSegment)="changeSelectedSegment($event)"></app-influencer-list>
    </div>

  </div>
</div>
<div class="operation-box">
  <div class="col-md-12">
    <div class="row">
      <div class="col-md-2">
        <div class="text-left">
          <button (click)="handleClickBack()" class="btn btn-default stopclick" type="button">
            Back
          </button>
        </div>
      </div>
      <div class="col-md-8 d-flex justify-content-center">
        <!-- <div class="select-page-size">
        </div> -->
        <button [disabled]="saveCampaignLoading" class="mob-btn">
        <span><i class="fa fa-spinner fa-spin mr-2" *ngIf="saveCampaignLoading"></i>
          Mobilizing {{influencerCount}} Influencers
        </span>
      </button>
      </div>
      <div class="col-md-2">
        <div class="text-right">
          <button class="btn btn-primary mr-2" (click)="validateSave()" type="button">
            Next
          </button>
        </div>
      </div>
    </div>
  </div>
</div>

<ng-template let-modal #influencerAdd>
  <div class="modal-body">
    <div class="overflow-hidden">
      <app-influencer-add
        [projectId]="this.id"
        [hideClass]="true" [influencerType]="selectedInfluencer" [selectedSegment]="selectedSegment" [isModal]="true" (isModelClosed)="closeInfluencer()"
        (closeModal)=" modal.close()"></app-influencer-add>
    </div>
  </div>
</ng-template>
<ng-template let-modal #influencerEdit>
  <div class="modal-body">
    <div class="overflow-hidden">
      <app-influencer-view
        [hideClass]="true" [projectId]="this.id"
        [influencerId]="editInfluencerId"
        [isModal]="true"
        (closeModal)="modal.close()">
      </app-influencer-view>
    </div>
  </div>
</ng-template>

<ng-template #logsTable let-modal>
  <div class="modal-header">
    <h4 class="modal-title" id="modal-title">Error Logs</h4>
    <button type="button" class="close" aria-label="Close button" aria-describedby="modal-title"
            (click)="close();modal.dismiss('cancel')">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <div *ngIf="isImportDataFilled()" class="import-data-container mt-4">
      <div class="import-data-invalid">
        <span>Your influencers contains errors, please fix them and try to delete again.</span>
      </div>
      <div class="custom-table">
        <table class="sb-table table dataTable no-footer nowrap" datatable [dtOptions]="importDataTableOptions"
               id="dt1-table">
          <thead>
          <tr>
            <th *ngFor="let fieldName of importDataTableColumns()">{{fieldName}}</th>
          </tr>
          </thead>
          <tbody>
          <tr *ngFor="let data of importData['list']">
            <td class="delete-log" *ngFor="let field of importDataTableColumns()">{{data[field]}}</td>
          </tr>
          </tbody>
        </table>
      </div>
    </div>

  </div>
  <input type="hidden" ngbAutofocus>
  <div class="modal-footer">
    <button type="button" class="btn btn-default" (click)="close();modal.close()">Close</button>
  </div>
</ng-template>


<ng-template #csvDownload let-modal >
  <div class="modal-header">
    <h4
      class="modal-title"
      id="modal-title"
    >
      Export {{ !csvDownloadComplete ? ': Your data is being exported' : ''}}
    </h4>
    <div class="d-flex justify-content-end">
      <button
        type="button"
        aria-label="Close button"
        class="close"
        aria-describedby="modal-title"
        (click)="modal.close()"
      >
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
  </div>
  <div class="modal-body">
    <div class="text-center">
      <button class="btn btn-default" type="button" *ngIf='!csvDownloadComplete'>
        <span class="btn-wrapper--icon spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
        <span class="btn-wrapper--label">
      {{exportProgress.status === 'started' ?
          'Export: started' :
          exportProgress.status === 'in-progress' ?
            'Exporting: '  + ' ' + exportProgress.progress + '%' :
              'Export: ' + exportProgress.status}}
    </span>
      </button>
      <a class="btn btn-default" type="button" *ngIf='csvDownloadComplete' (click)="downloadExportFile()">
        <span class="btn-wrapper--label">
          {{'File Link'}}
    </span>
      </a>

    </div>
  </div>
</ng-template>

