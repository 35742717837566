const qs = require('qs');

export function withParams(path, params = null): string {
  return params ? `${path}?${qs.stringify(params, { arrayFormat: 'brackets' })}` : path;
}

// Events
// We should change the root to /project eventually
export function projects(params?): string    { return withParams(`projects`, params); }
export function clients(params?): string    { return withParams(`clients`, params); }
export function project(id, params?): string { return withParams(`projects/${id}`, params); }
export function createProject(params?): string  { return withParams(`projects/create`, params); }
export function shareProject(id, params?): string { return withParams(`projects/${id}/share`, params); }

// Campaigns
export function campaigns({projectId}, params?): string  {
  return withParams(`projects/${projectId}/campaigns`, params);
}

export function campaign({projectId, campaignId}, params?): string  {
  return withParams(`projects/${projectId}/campaigns/${campaignId}`, params);
}

export function createCampaign({projectId}, params?): string  {
  return withParams(`projects/${projectId}/campaigns/create`, params);
}

export function createCampaignForAudienceType({projectId}, {audienceType}, params?): string  {
  return withParams(`projects/${projectId}/campaigns/create/${audienceType}`, params);
}

// ClientTemplate
export function campaignSelectTemplate({projectId, campaignId}, params?): string  {
  return withParams(`projects/${projectId}/campaigns/${campaignId}/select`, params);
}

export function campaignTemplates({projectId, campaignId}, params?): string  {
  return withParams(`projects/${projectId}/campaigns/${campaignId}/templates`, params);
}

export function campaignPreview({projectId, campaignId}, params?): string  {
  return withParams(`projects/${projectId}/campaigns/${campaignId}/preview`, params);
}

export function campaignShare({ projectId, campaignId }, params?): string {
  return withParams(`projects/${projectId}/campaigns/${campaignId}/share`, params);
}

export function campaignTemplate({projectId, campaignId}, params?): string  {
  return withParams(`${campaignTemplates({projectId, campaignId})}`, params);
}

export function editCampaignTemplate({projectId, campaignId}, params?): string  {
  return withParams(`${campaignTemplate({projectId, campaignId})}/edit`, params);
}

// launch
export function launchCampaign({projectId, campaignId}, params?): string  {
  return withParams(`projects/${projectId}/campaigns/${campaignId}/launch`, params);
}

// Influencers
export function influencers({projectId}, params?): string {
  return withParams(`projects/${projectId}/influencers`, params);
}

export function addInfluencers({ projectId }, params?) {
  return withParams(`project/${projectId}/add-influencer`, params);
}

// Users
export function users(params?): string {
  return withParams(`users`, params);
}

// Settings
export function settings(params?): string {
  return withParams(`settings`, params);
}

// statistics
export function statistics({projectId}, params?): string {
  return withParams(`projects/${projectId}/statistics`, params);
}

// Widget
export function widget({projectId}, params?): string {
  return withParams(`projects/${projectId}/widget`, params);
}

// Thirdparty Platforms
export function thirdpartyPlatforms({projectId}, params?): string {
  return withParams(`projects/${projectId}/registration-platforms`, params);
}

// Registration Platform
export function registrationPlatformEdit(id?): string {
  return withParams(`settings/registration-platform/edit/${id}`);
}

export function statisticsForCampaign({projectId}, {campaignId}, params?): string {
  return withParams(`projects/${projectId}/statistics`, params);
}
