<div class="modal-header">
  <h4 class="modal-title" id="modal-title">Task Logs</h4>
<!--  <h4 class="modal-title" id="modal-title">{{data.action | titlecase}} Logs</h4>-->
  <div class=" d-flex justify-content-end">
    <button type="button" aria-label="Close button" class="close" aria-describedby="modal-title"
            (click)="modal.dismiss('cancel')">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
</div>
<div class="modal-body task-logs-table">
  <div class="custom-table p-3">
    <table width="100%" class="sb-table table dataTable no-footer nowrap" datatable
           [dtTrigger]="dtTrigger"
           [dtOptions]="dtOptions"
           id="dt-table">
      <thead>
      <tr>
        <th>Log Date</th>
        <th>Progress</th>
        <th>Status</th>
        <th>Message</th>
      </tr>
      </thead>
      <tbody *ngIf="influencersTaskLogs.length > 0">
      <tr *ngFor="let log of influencersTaskLogs">
        <td>{{formatMoment(log.created_at) | tzDate : false : 'DD-MM-YYYY hh:mm a'}}</td>
        <td>{{log.progress}}</td>
        <td>{{log.status}}</td>
        <td>{{log.message}}</td>
      </tr>
      </tbody>
      <tbody *ngIf="influencersTaskLogs.length <= 0">
      <tr>
        <td colspan="5" class="no-data-available">No data available in table</td>
      </tr>
      </tbody>
    </table>
  </div>
</div>
<input type="hidden" ngbAutofocus>
<div class="modal-footer">
  <button class="btn btn-primary" type="button" (click)="refreshList()">
    Refresh
  </button>
  <button class="btn btn-primary" type="button" (click)="modal.dismiss('cancel')">
    Close
  </button>
</div>
