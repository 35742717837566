import {Injectable} from '@angular/core';
import {environment} from '@environments/environment';
import {HttpClient} from '@angular/common/http';

@Injectable({providedIn: 'root'})
export class TemplateSectionService {
  private baseApiUrl = environment.apiUrl;
  private templateSectionUrl = this.baseApiUrl + '/api/v1/template_section';
  private templateSectionTypeUrl = this.baseApiUrl + '/api/v1/template_section_type';

  constructor(private http: HttpClient) {
  }

  list(params) {
    return this.http.get(`${this.templateSectionUrl}`, {params});
  }

  listSectionTypes(params) {
    return this.http.get(`${this.templateSectionTypeUrl}`, {params});
  }

  templateSectionDelete(templateSectionId: number) {
    return this.http.delete(`${this.templateSectionUrl}/${templateSectionId}`);
  }

  getSection(templateSectionId: number, params?: any) {
    return this.http.get(`${this.templateSectionUrl}/${templateSectionId}`, {params});
  }

  updateSection(templateSectionId: number, body: any) {
    return this.http.put(`${this.templateSectionUrl}/${templateSectionId}`, body);
  }

  createSection(body: any) {
    return this.http.post(`${this.templateSectionUrl}`, body);
  }

  updateThumbnails() {
    return this.http.get(`${this.templateSectionUrl}/update_all_thumbnail`);
  }

  getAssets(params?: any) {
    return this.http.get(`${this.templateSectionUrl}/build`, {params});
  }

  getFroalaCss() {
    return `body {
          overflow: hidden;
        }
        .fp-no-block {
          display: none;
          text-align: center;
          color: #000000;
          cursor: default;
          background-color: #DEDEDE;
        }
        body.no-block .fp-no-block {
          display: block;
        }

        .fp-active {
          display: block !important;
        }

        .fr-box.fr-inline .fr-wrapper .fr-element.fr-view ul.navbar-nav {
          display: flex !important;
        }

        .fr-box.fr-inline .fr-wrapper .fr-element.fr-view ul.navbar-nav {
          display: flex !important;
        }

        .fr-toolbar p {
          color: #222222 !important;
          font-family: Arial, Helvetica, sans-serif !important;
        }

        .fr-toolbar h1 {
          color: #222222 !important;
          font-family: Arial, Helvetica, sans-serif !important;
        }
        .fr-toolbar h2 {
          color: #222222 !important;
          font-family: Arial, Helvetica, sans-serif !important;
        }
        .fr-toolbar h3 {
          color: #222222 !important;
          font-family: Arial, Helvetica, sans-serif !important;
        }
        .fr-toolbar h4 {
          color: #222222 !important;
          font-family: Arial, Helvetica, sans-serif !important;
        }
        .fr-toolbar h5 {
          color: #222222 !important;
          font-family: Arial, Helvetica, sans-serif !important;
        }
        .fr-toolbar h6 {
          color: #222222 !important;
          font-family: Arial, Helvetica, sans-serif !important;
        }

        button.btn, a.btn {
          word-break: break-word;
          white-space: break-spaces;
          width: fit-content;
        }
        section, header, footer {
          margin-right: auto;
          margin-left: auto;
        }`;
  }

  addCustomVariables(FroalaEditor, variables) {
    const that = this;
    FroalaEditor.DefineIcon('variable', {NAME: 'users', template: 'font_awesome'});
    FroalaEditor.RegisterCommand('variable', {
      title: 'Variables',
      icon: 'variable',
      type: 'dropdown',
      focus: false,
      undo: false,
      refreshAfterCallback: true,
      // options: variables,
      html: function () {
        return that.getVariableHtml('variable', variables);
      },
      callback: function (cmd, val) {
        this.html.insert(val);
      },
      // Callback on refresh.
      refresh: function ($btn) {
        // console.log('do refresh');
      },
      // Callback on dropdown show.
      refreshOnShow: function ($btn, $dropdown) {
        // console.log('do refresh when show');
      }
    });

    FroalaEditor.RegisterCommand('linkUrlVariables', {
      title: 'URL Variables',
      icon: 'imageLink',
      type: 'dropdown',
      focus: false,
      undo: false,
      refreshAfterCallback: false,
      html: function () {
        return that.getVariableHtml('linkUrlVariables', variables, 'url');
      },
      callback: function (cmd, val) {
        const $popup = this.popups.get('link.insert');
        const urlInput = $popup.find(`#fr-link-insert-layer-url-${this.id}`);
        if (urlInput && urlInput[0]) {
          // let value = urlInput[0].value;
          // console.log(value)
          // urlInput[0].value = `${value} ${val}`;
          urlInput[0].value = `${val}`;
          urlInput[0].focus();
        }
      },
      plugin: 'link'
    });

    FroalaEditor.RegisterCommand('linkTextVariables', {
      title: 'Text Variables',
      icon: 'moreText',
      type: 'dropdown',
      focus: false,
      undo: false,
      refreshAfterCallback: false,
      html: function () {
        return that.getVariableHtml('linkTextVariables', variables, 'text');
      },
      callback: function (cmd, val) {
        const $popup = this.popups.get('link.insert');
        const textInput = $popup.find(`#fr-link-insert-layer-text-${this.id}`);
        if (textInput && textInput[0]) {
          // let value = textInput[0].value;
          // console.log(value)
          // textInput[0].value = `${value} ${val}`;
          textInput[0].value = `${val}`;
          textInput[0].focus();
        }
      },
      plugin: 'link'
    });

    this.customImageVariableOptions(FroalaEditor, variables);
  }

  getVariableHtml(command, variables, filter = '') {
    let html = '<ul class="fr-dropdown-list" role="presentation">';
    Object.keys(variables).forEach(groups => {
      let variableList = variables[groups].filter(item => !item.valueType.includes('ignore'));
      if (filter) {
        variableList = variables[groups].filter(item => (item.valueType.includes('all') || item.valueType.includes(filter))
          && !item.valueType.includes('ignore'));
      }
      if (variableList.length > 0) {
        html += '<li role="presentation">';
        html += '<a class="option-group" style="font-weight: bolder;cursor: default;text-transform: capitalize;pointer-events: none;" role="option-group" title="' + groups + '">' + groups + '</a>';
        html += '</li>';
        variableList.forEach(item => {
          let showName = item.name.toString().toLowerCase().trim();
          showName = showName.replace(groups.toString().toLowerCase().trim(), '');
          html += '<li role="presentation">';
          html += '<a style="padding: 0 30px; text-transform: capitalize;" class="fr-command" tabindex="-1" ' +
            'role="option" data-cmd="' + command + '" data-param1="' + item.key + '" title="' + item.name + '">' + showName + '</a>';
          html += '</li>';
        });
      }
    });
    return html += '</ul>';
  }

  customImageVariableOptions(FroalaEditor , variables) {
    // const that = this;
    const customPopupName = 'imageVariable.popup';
    const customPlugin = 'imageVariable';

    // Define popup template.
    Object.assign(FroalaEditor.POPUP_TEMPLATES, {
      [customPopupName]: '[_BUTTONS_][_CUSTOM_LAYER_]'
    });

    // Define popup buttons.
    Object.assign(FroalaEditor.DEFAULTS, {
      popupButtons: ['popupClose', '-']
    });

    // The custom popup is defined inside a plugin (new or existing).
    FroalaEditor.PLUGINS[customPlugin] = function (editor) {
      let imageSrcOptions = {
        'none': [{name: 'None', key: '', valueType: ['text', 'all']}],
      };
      if (variables) {
        imageSrcOptions = {
          'none': [{name: 'None', key: '', valueType: ['text', 'all']}],
          ...variables
        };
      }

      // Create custom popup.
      function initPopup() {
        // Load popup template.
        let template = FroalaEditor.POPUP_TEMPLATES[customPopupName];
        if (typeof template === 'function') {
          template = template.apply(editor);
        }

        // Popup buttons.
        let popup_buttons = '';

        // Create the list of buttons.
        if (editor.opts.popupButtons.length > 1) {
          popup_buttons += '<div class="fr-buttons fr-tabs">';
          popup_buttons += editor.button.buildList(editor.opts.popupButtons);
          popup_buttons += '</div>';
        }

        // Define Popup Template
        let dropdownButton = '<button id="imageSrcVariable-' + editor.id + '" type="button" tabindex="-1" role="button" data-cmd="imageSrcVariable" aria-controls="dropdown-menu-imageSrcVariable-' + editor.id + '" data-blur-event-set="true" aria-expanded="false" aria-haspopup="true" class="fr-command fr-btn fr-dropdown imageSrcVariable" style="display: flex;align-items: center;" data-title="Image Source">';
        dropdownButton += editor.icon.create('insertImage');
        dropdownButton += '<span class="d-none" style="margin-left: -5px"></span>';
        dropdownButton += '</button>';

        let dropdownHtml = '<div id="dropdown-menu-imageSrcVariable-' + editor.id + '" class="fr-dropdown-menu" role="listbox" aria-labelledby="imageSrcVariable-' + editor.id + '" aria-hidden="true">';
        dropdownHtml += '<div class="fr-dropdown-wrapper" role="presentation"><div class="fr-dropdown-content" role="presentation">';
        dropdownHtml += '<ul class="fr-dropdown-list" role="presentation">';

        Object.keys(imageSrcOptions).forEach(groups => {
          const variableList = imageSrcOptions[groups].filter(item => (item.valueType.includes('all') || item.valueType.includes('image'))
            && !item.valueType.includes('ignore'));
          if (variableList.length > 0) {
            if (groups !== 'none') {
              dropdownHtml += '<li>';
              dropdownHtml += '<a style="font-weight: bolder;cursor: default;text-transform: capitalize;pointer-events: none"' +
                ' title="' + groups + '">' + groups + '</a>';
              dropdownHtml += '</li>';
            }
            const style = groups !== 'none' ? 'padding: 0 30px; text-transform: capitalize;' : 'text-transform: capitalize;';
            variableList.forEach(item => {
              let showName = item.name.toString().toLowerCase().trim();
              if (groups !== 'none') {
                showName = showName.replace(groups.toString().toLowerCase().trim(), '');
              }
              dropdownHtml += '<li role="presentation">';
              dropdownHtml += '<a style="' + style + '" class="fr-command" tabindex="-1" role="option" data-cmd="imageSrcVariable" data-param1="' + item.key + '" title="' + item.name + '">' + showName + '</a>';
              dropdownHtml += '</li>';
            });
          }
        });

        dropdownHtml += '</ul></div></div></div>';

        let checkBoxHtml = '<span style="text-align: left"><div class="fr-checkbox-line fr-autoplay-margin imageSrcVariableCheck">';
        checkBoxHtml += '<span class="fr-checkbox"><input id="imageSrcVariableCheck-' + editor.id + '" data-cmd="imageSrcVariableCheck" data-param1="true" class="fr-command" type="checkbox" dir="auto" value/><span><svg version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="10" height="10" viewBox="0 0 32 32"><path d="M27 4l-15 15-7-7-5 5 12 12 20-20z" fill="#FFF"></path></svg></span></span>';
        checkBoxHtml += '<label style="line-height: 1;white-space: break-spaces;word-break: break-word;"' +
          ' id="imageSrcVariableCheckLabel-' + editor.id + '" data-cmd="imageSrcVariableCheck" data-param1="true"' +
          ' class="fr-command">Show the uploaded image when the selected dynamic image is not available</label></div></span>';

        const html = '<div class="fr-image-variable-layer fr-layer fr-active fr-buttons m-0 w-auto">' +
          '<div class="d-flex align-items-center">'.concat(dropdownButton).concat(dropdownHtml).concat(checkBoxHtml, '</div>').concat('</div>');
        // Load popup template.
        template = {
          buttons: popup_buttons,
          custom_layer: html,
        };

        // Define custom popup template dropdown command
        FroalaEditor.RegisterCommand('imageSrcVariable', {
          undo: false,
          focus: false,
          callback: function (cmd, val) {
            let img = null;
            if (this.el instanceof HTMLImageElement) {
              img = this.el;
            } else {
              img = this.el.querySelector('img');
            }
            const button = this.$sc.find(`#imageSrcVariable-${this.id}`);
            if (button && button[0]) {
              const span = button[0].querySelector('span');
              if (val.trim()) {
                img.setAttribute('data-src-variable', val);
                let newOptions = [];
                Object.keys(imageSrcOptions).forEach(group => {
                  newOptions = [...newOptions, ...imageSrcOptions[group]];
                });
                const option = newOptions.find(item => item.key === val);
                if (option) {
                  span.innerHTML = option.name;
                  span.classList.remove('d-none');
                }
              } else {
                img.removeAttribute('data-src-variable');
                span.innerHTML = '';
                span.classList.add('d-none');
              }
            }
          }
        });

        // Define custom popup template checkbox command
        FroalaEditor.RegisterCommand('imageSrcVariableCheck', {
          undo: false,
          focus: false,
          callback: function (cmd, val) {
            let img = null;
            if (this.el instanceof HTMLImageElement) {
              img = this.el;
            } else {
              img = this.el.querySelector('img');
            }
            if (img.getAttribute('data-src-visibility')) {
              img.removeAttribute('data-src-visibility');
            } else {
              img.setAttribute('data-src-visibility', val);
            }
          }
        });

        // Create popup.
        return editor.popups.create(customPopupName, template);
      }

      // Set default value according to attributes
      function checkElementForData(instance, popup) {
        let img = null;
        if (instance.el instanceof HTMLImageElement) {
          img = instance.el;
        } else {
          img = instance.el.querySelector('img');
        }
        let value = img.getAttribute('data-src-visibility');
        const checkboxVariable = popup.find(`#imageSrcVariableCheck-${instance.id}`);
        if (checkboxVariable && checkboxVariable[0]) {
          checkboxVariable[0].checked = !!value;
        }

        value = img.getAttribute('data-src-variable');
        const button = popup.find(`#imageSrcVariable-${instance.id}`);
        if (button && button[0]) {
          const span = button[0].querySelector('span');
          if (value) {
            let newOptions = [];
            Object.keys(imageSrcOptions).forEach(group => {
              newOptions = [...newOptions, ...imageSrcOptions[group]];
            });
            const option = newOptions.find(item => item.key === value);
            if (option) {
              span.innerHTML = option.name;
              span.classList.remove('d-none');
            }
          } else {
            span.innerHTML = '';
            span.classList.add('d-none');
          }
        }
      }

      // Show the popup
      function showPopup() {
        let $popup = editor.popups.get(customPopupName);
        if (!$popup) {
          $popup = initPopup();
        }

        editor.popups.onShow(customPopupName, function () {
          checkElementForData(this, $popup);
        });

        editor.popups.setContainer(customPopupName, editor.$sc);
        const $btn = editor.$sc.find('.fr-active .fr-command[data-cmd="imageVariableButton"]');
        const left = $btn.offset().left;
        const top = $btn.offset().top - (editor.opts.toolbarBottom ? 10 : ($btn.outerHeight() - 10));
        editor.popups.show(customPopupName, left, top, $btn.outerHeight());
      }

      // Hide the custom popup.
      function hidePopup() {
        editor.popups.hide(customPopupName);
      }

      // Methods visible outside the plugin.
      return {
        showPopup: showPopup,
        hidePopup: hidePopup
      };
    };

    // Define custom popup close button icon and command.
    FroalaEditor.RegisterCommand('popupClose', {
      title: 'Close',
      icon: 'imageBack',
      undo: false,
      focus: false,
      callback: function () {
        this.image.back();
      }
    });

    // Define an icon and command for the button that opens the custom popup.
    FroalaEditor.DefineIcon('imageVariableButton', {NAME: 'cog', template: 'font_awesome'});
    FroalaEditor.RegisterCommand('imageVariableButton', {
      title: 'Use Variable',
      icon: 'imageVariableButton',
      undo: false,
      focus: false,
      popup: true,
      plugin: 'imageVariable',
      callback: function () {
        if (!this.popups.isVisible(customPopupName)) {
          this[customPlugin].showPopup();
        } else {
          if (this.$el.find('.fr-marker')) {
            this.events.disableBlur();
            this.selection.restore();
          }
          this[customPlugin].hidePopup();
        }
      }
    });
  }

}
