import {AfterViewInit, Component, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {Router, ActivatedRoute} from '@angular/router';
import Swal from 'sweetalert2';
import {select, Store} from '@ngrx/store';
import {
  getInfluencerError,
  getInfluencersTypes, getPaginatedTemplates,
  getTemplateError,
  getTemplateSuccess, getTemplateTypes, IInfluencersState, InfluencersTypeList,
  ITemplateState, ResetTemplateState, TemplateDelete, TemplateGetTypes,
  TemplateList, TemplateSectionUpdate, TemplateUpdate, TemplateUpdateThumbnail,
} from '@app/stores';
import {takeUntil} from 'rxjs/operators';
import {Subject, Subscription} from 'rxjs';
import {ToastrService} from 'ngx-toastr';
import {InfluencerType, Template, TemplateType} from '@app/modules/shared/models';
import {DataTableDirective} from 'angular-datatables';
import {Constants, LANDING_PAGE_TEMPLATE_TYPE_ID} from '@app/consts';
import {SocketService} from '@app/services/socket.service';
import * as _ from 'lodash';

@Component({
  selector: 'app-templates',
  templateUrl: './templates.component.html',
  styleUrls: ['./templates.component.css'],
  providers: [Constants]
})
export class TemplatesComponent implements OnInit, OnDestroy, AfterViewInit {
  @ViewChild(DataTableDirective)
  dtElement: DataTableDirective;
  dtOptions: any = {};
  templateData: Template[] = [];
  templateStatus: object;
  unsubscriber = new Subject();
  dtTrigger: Subject<any> = new Subject();
  deleteAction = false;
  templateTypes: TemplateType[] = [];

  selectedTemplateType = LANDING_PAGE_TEMPLATE_TYPE_ID;
  selectedTemplateVisibility: any = null;
  selectedInfluencerType = null;
  influencerTypes: InfluencerType[] = [];
  ajaxCallback: any;
  searchDelay: any;
  updateThumbnail = false;
  subscription: Subscription;
  paginatedTemplatesData: any;
  templateFilterList: any = {};
  pageNumber = 0;
  savePageNumber = 0;
  isCalled = false;
  isOnload = true;

  constructor(
    private router: Router,
    private toastr: ToastrService,
    private templateStore: Store<ITemplateState>,
    private influencersStore: Store<IInfluencersState>,
    private socketService: SocketService,
    private activatedRoute: ActivatedRoute,
    public constants: Constants,
  ) {
    this.activatedRoute.queryParams.subscribe(async (queryParams) => {
      if (queryParams.templateType) this.selectedTemplateType = Number(queryParams.templateType);
      if (queryParams.visibility) this.selectedTemplateVisibility = queryParams.visibility;
      if (queryParams.influencerType) this.selectedInfluencerType = Number(queryParams.influencerType);
      if (queryParams.pageNumber) this.pageNumber = Number(queryParams.pageNumber);
    });
    this.influencersStore.dispatch(ResetTemplateState({params: {error: '', paginatedTemplates: null, templateTypes: []}}));
    this.subscribeStore();
    this.subscription = this.socketService.sourceData
      .subscribe((message: any) => {
          if (message?.data?.requesterId) {
            const temp = _.cloneDeep(this.templateData);
            temp.map(element => {
              if (element.id === message.data.requesterId && message.data?.requesterType === 'template') {
                element.thumbnail = message.data.result.thumbnail;
              }
              return element;
            });
            this.templateData = [...temp];
            if (this.ajaxCallback) {
              this.ajaxCallback({
                recordsTotal: this.paginatedTemplatesData.paging.total,
                recordsFiltered: this.paginatedTemplatesData.paging.total,
                data: []
              });
            }
          }
        },
        (err) => console.error(err),
        () => console.warn('Completed!')
      );
  }

  ngOnInit() {
    this.isCalled = true;
    this.isOnload = true;
    this.influencersStore.dispatch(InfluencersTypeList({params: {}}));
    this.templateStore.dispatch(TemplateGetTypes());
    this.datatableSettings();
    if (this.dtElement && this.dtElement.dtInstance) {
      this.dtElement.dtInstance.then((dtInstance: any) => {
        dtInstance.destroy();
        this.dtTrigger.next();
      });
    } else {
      this.dtTrigger.next();
    }
  }

  ngAfterViewInit() {
    this.dtTrigger.next();
  }

  datatableSettings() {
    this.dtOptions = {
      // scrollY: '100vh',
      scrollCollapse: true,
      pagingType: 'full_numbers',
      responsive: false,
      ordering: false,
      processing: true,
      serverSide: true,
      pageLength: 10,
      paging: true,
      scrollX: true,
      searching: true,
      // scrollY: "100vh",
      fixedHeader: {
        header: true,
        footer: true,
        headerOffset: 55 ,
      },
      fixedColumns: {
        right: 1,
        left: 0
      },
      initComplete: () => {
        this.dtElement.dtInstance.then((dtInstance: any) => {
          dtInstance.page(this.pageNumber || 0).draw('page');
        });
      },
      drawCallback: function (setting) {
        const totalPages = this.api().page.info().pages;
        if (totalPages <= 1) {
          document.querySelector('.dataTables_paginate').classList.add('d-none');
        } else {
          document.querySelector('.dataTables_paginate').classList.remove('d-none');
        }
      },
      ajax: (dataTablesParameters: any, callback) => {
        this.dtElement.dtInstance.then((dtInstance: any) => {
          this.ajaxCallback = callback;
          const pageLength = dataTablesParameters.length;
          if (this.isOnload) {
            this.pageNumber = this.pageNumber !== 0 ? this.pageNumber : (dataTablesParameters.start / pageLength);
            this.isOnload = false;
          } else {
            this.pageNumber = (dataTablesParameters.start / pageLength);
          }
          this.savePageNumber = this.pageNumber;
          dtInstance.page.len(pageLength);
          const searchBox = $('div.dataTables_filter input');
          searchBox.off('keyup.DT input.DT');
          searchBox.on('keyup', () => {
            const search: any = searchBox.val();
            clearTimeout(this.searchDelay);
            this.searchDelay = setTimeout(() => {
              if (search != null) {
                dtInstance.search(search).draw();
              }
            }, 1000);
          });
          this.loadAllTempaltes(pageLength, dataTablesParameters.search.value, 'initialCall');
        });
      },
      columns: [{data: null}, {data: null}, {data: null}, {data: null}, {data: null}]
    };
  }

  subscribeStore() {
    this.templateStore.pipe(select(getPaginatedTemplates))
      .pipe(takeUntil(this.unsubscriber))
      .subscribe(paginatedTemplates => {
        if (paginatedTemplates && this.isCalled) {
          this.paginatedTemplatesData = paginatedTemplates;
          this.templateData = paginatedTemplates.list;
          if (this.ajaxCallback) {
            this.ajaxCallback({
              recordsTotal: paginatedTemplates.paging.total,
              recordsFiltered: paginatedTemplates.paging.total,
              data: []
            });
          }
          this.isCalled = false;
        }
      });
    this.templateStore.pipe(select(getTemplateTypes))
      .pipe(takeUntil(this.unsubscriber))
      .subscribe(templateTypes => {
        if (templateTypes && templateTypes.length > 0) {
          this.templateTypes = templateTypes;
          this.selectedTemplateType = this.templateTypes.find(item => item.slug === 'landing_page').id;
        }
      });

    this.templateStore.pipe(select(getTemplateError))
      .pipe(takeUntil(this.unsubscriber))
      .subscribe(error => {
        if (error) {
          this.toastr.error(error, 'Error');
        }
      });

    this.templateStore.pipe(select(getTemplateSuccess))
      .pipe(takeUntil(this.unsubscriber))
      .subscribe(success => {
        if (success) {
          if (this.deleteAction) {
            this.deleteAction = false;
            this.isCalled = false;
            this.pageNumber = 0;
            this.loadAllTempaltes();
            this.dtElement.dtInstance.then((dtInstance: any) => {
              dtInstance.page(this.pageNumber || 0).draw('page');
            });
          }
          if (this.updateThumbnail) {
            this.updateThumbnail = false;
            this.toastr.success(success, 'Success');
          }
        }
      });
    this.influencersStore.pipe(select(getInfluencerError))
      .pipe(takeUntil(this.unsubscriber))
      .subscribe(error => {
          if (error) {
            this.toastr.error(error, 'Error');
          }
        }
      );
    this.influencersStore.pipe(select(getInfluencersTypes))
      .pipe(takeUntil(this.unsubscriber))
      .subscribe(influencerTypes => {
        if (influencerTypes && influencerTypes.length > 0) {
          this.influencerTypes = influencerTypes;
        }
      });
  }

  public deleteTemplate(id) {
    Swal({
      title: 'Are you sure?',
      type: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Yes',
      cancelButtonText: 'No'
    }).then(result => {
      if (result.value) {
        this.deleteAction = true;
        this.templateStore.dispatch(TemplateDelete({templateId: id}));
      }
    });
  }


  loadAllTempaltes(perPage = 10, search = '', type = null) {
    let params;
    params = { ...this.activatedRoute.snapshot.queryParams };
    if (this.selectedTemplateType) {
      params.templateType = this.selectedTemplateType;
    } else {
      delete params.templateType;
    }
    if (this.selectedTemplateVisibility) {
      params.visibility = this.selectedTemplateVisibility;
    } else {
      delete params.visibility;
    }
    if (this.selectedInfluencerType) {
      params.influencerType = this.selectedInfluencerType;
    } else {
      delete params.influencerType;
    }
    if (this.pageNumber !== 0) {
      params.pageNumber = this.pageNumber;
    } else {
      delete params.pageNumber;
    }
    if (type !== 'initialCall') {
      this.pageNumber = 0;
    }
    this.router.navigate([], { queryParams: params });
    this.templateStore.dispatch(TemplateList({
      params: {
        ...(this.selectedTemplateType ? {template_type_id: this.selectedTemplateType} : {}),
        include_template_type: true,
        campaign_id: null,
        client_id: null,
        project_id: null,
        ...(this.selectedInfluencerType ? {influencer_type_id: this.selectedInfluencerType} : {}),
        ...(this.selectedTemplateVisibility ? {visibility: this.selectedTemplateVisibility} : {}),
        options: JSON.stringify({includePagination: true}),
        page: this.pageNumber + 1,
        perPage,
        search
      }
    }));

    if (!this.isCalled) {
      this.pageNumber = 0;
      this.isCalled = true;
    }
  }

  updateAllThumbnails() {
    this.updateThumbnail = true;
    this.templateStore.dispatch(TemplateUpdateThumbnail());
  }

  changeVisibility(template) {
    if (template) {
      this.templateStore.dispatch(TemplateUpdate({
        id: template.id,
        body: {visibility: !template.visibility}
      }));
    }
  }

  onAddEditTemplate(type, id = null) {
    let route;
    if(type === 'add') route = `templates/add`;
    if(type === 'edit') route = `templates/edit`;
    if (id) route = route + `/${id}`;
    const queryParams: any = {};
    if (this.selectedTemplateType) queryParams.templateType = this.selectedTemplateType;
    if (this.selectedInfluencerType) queryParams.influencerType = this.selectedInfluencerType;
    if (this.selectedTemplateVisibility) queryParams.visibility = this.selectedTemplateVisibility;
    if (this.savePageNumber !== 0) queryParams.pageNumber = this.savePageNumber;
    this.router.navigate([route], {
      queryParams: queryParams,
    });
  }

  ngOnDestroy() {
    // this.dtTrigger.unsubscribe();
    this.unsubscriber.next();
    this.unsubscriber.complete();
    this.subscription.unsubscribe();
  }

}
