import {createAction, props} from '@ngrx/store';
import {PaginatedTemplateSection, TemplateSection, TemplateSectionType} from '@app/modules/shared/models';

enum ETemplateSectionActions {
  TemplateSectionListAll = '[TemplateSection] Template Section List All',
  TemplateSectionListAllSuccess = '[TemplateSection] Template Section List All Success',
  TemplateSectionListAllError = '[TemplateSection] Template Section List All Error',
  TemplateSectionList = '[TemplateSection] Template Section List',
  TemplateSectionListSuccess = '[TemplateSection] Template Section List Success',
  TemplateSectionListError = '[TemplateSection] Template Section List Error',
  TemplateSectionTypeList = '[TemplateSection] Template Section Type List',
  TemplateSectionTypeListSuccess = '[TemplateSection] Template Section Type List Success',
  TemplateSectionTypeListError = '[TemplateSection] Template Section Type List Error',
  TemplateSectionDelete = '[TemplateSection] Template Section Delete',
  TemplateSectionDeleteSuccess = '[TemplateSection] Template Section Delete Success',
  TemplateSectionDeleteError = '[TemplateSection] Template Section Delete Error',
  TemplateSectionGet = '[TemplateSection] Template Section Get',
  TemplateSectionGetSuccess = '[TemplateSection] Template Section Get Success',
  TemplateSectionGetError = '[TemplateSection] Template Section Get Error',
  TemplateSectionCreate = '[TemplateSection] Template Section Create',
  TemplateSectionCreateSuccess = '[TemplateSection] Template Section Create Success',
  TemplateSectionCreateError = '[TemplateSection] Template Section Create Error',
  TemplateSectionUpdate = '[TemplateSection] Template Section Update',
  TemplateSectionUpdateSuccess = '[TemplateSection] Template Section Update Success',
  TemplateSectionUpdateError = '[TemplateSection] Template Section Update Error',
  TemplateSectionUpdateThumbnail = '[TemplateSection] Template Section Update Thumbnail',
  TemplateSectionUpdateThumbnailSuccess = '[TemplateSection] Template Section Update Thumbnail Success',
  TemplateSectionUpdateThumbnailError = '[TemplateSection] Template Section Update Thumbnail Error',
  TemplateSectionGetAssets = '[TemplateSection] Template Section Get Assets',
  TemplateSectionGetAssetsSuccess = '[TemplateSection] Template Section Get Assets Success',
  TemplateSectionGetAssetsError = '[TemplateSection] Template Section Get Assets Error',

  ResetTemplateSectionState = '[TemplateSection] Template Section Type List Error',
}

export const TemplateSectionListAll = createAction(ETemplateSectionActions.TemplateSectionListAll, props<{ params: any }>());
export const TemplateSectionListAllSuccess = createAction(ETemplateSectionActions.TemplateSectionListAllSuccess,
  props<{ templateSections: TemplateSection[] }>());
export const TemplateSectionListAllError = createAction(ETemplateSectionActions.TemplateSectionListAllError, props<{ error: string }>());

export const TemplateSectionList = createAction(ETemplateSectionActions.TemplateSectionList, props<{ params: any }>());
export const TemplateSectionListSuccess = createAction(ETemplateSectionActions.TemplateSectionListSuccess,
  props<{ paginatedTemplateSection: PaginatedTemplateSection }>());
export const TemplateSectionListError = createAction(ETemplateSectionActions.TemplateSectionListError, props<{ error: string }>());

export const TemplateSectionTypeList = createAction(ETemplateSectionActions.TemplateSectionTypeList, props<{ params: any }>());
export const TemplateSectionTypeListSuccess = createAction(ETemplateSectionActions.TemplateSectionTypeListSuccess,
  props<{ templateSectionTypes: TemplateSectionType[] }>());
export const TemplateSectionTypeListError = createAction(ETemplateSectionActions.TemplateSectionTypeListError, props<{ error: string }>());

export const TemplateSectionDelete = createAction(ETemplateSectionActions.TemplateSectionDelete, props<{ templateSectionId: number }>());
export const TemplateSectionDeleteSuccess = createAction(ETemplateSectionActions.TemplateSectionDeleteSuccess);
export const TemplateSectionDeleteError = createAction(ETemplateSectionActions.TemplateSectionDeleteError, props<{ error: string }>());

export const TemplateSectionGet = createAction(ETemplateSectionActions.TemplateSectionGet, props<{ templateSectionId: number, params?: any }>());
export const TemplateSectionGetSuccess = createAction(ETemplateSectionActions.TemplateSectionGetSuccess,
  props<{ templateSection: TemplateSection }>());
export const TemplateSectionGetError = createAction(ETemplateSectionActions.TemplateSectionGetError, props<{ error: string }>());

export const TemplateSectionCreate = createAction(ETemplateSectionActions.TemplateSectionCreate, props<{ body: any }>());
export const TemplateSectionCreateSuccess = createAction(ETemplateSectionActions.TemplateSectionCreateSuccess,
  props<{ templateSection: TemplateSection }>());
export const TemplateSectionCreateError = createAction(ETemplateSectionActions.TemplateSectionCreateError, props<{ error: string }>());

export const TemplateSectionUpdate = createAction(ETemplateSectionActions.TemplateSectionUpdate,
  props<{ templateSectionId: number, body: any }>());
export const TemplateSectionUpdateSuccess = createAction(ETemplateSectionActions.TemplateSectionUpdateSuccess,
  props<{ templateSection: TemplateSection }>());
export const TemplateSectionUpdateError = createAction(ETemplateSectionActions.TemplateSectionUpdateError, props<{ error: string }>());

export const TemplateSectionUpdateThumbnail = createAction(ETemplateSectionActions.TemplateSectionUpdateThumbnail);
export const TemplateSectionUpdateThumbnailSuccess = createAction(ETemplateSectionActions.TemplateSectionUpdateThumbnailSuccess,
  props<{ success: string }>());
export const TemplateSectionUpdateThumbnailError = createAction(ETemplateSectionActions.TemplateSectionUpdateThumbnailError,
  props<{ error: string }>());

export const TemplateSectionGetAssets = createAction(ETemplateSectionActions.TemplateSectionGetAssets, (params: any = {}) => params);
export const TemplateSectionGetAssetsSuccess = createAction(ETemplateSectionActions.TemplateSectionGetAssetsSuccess,
  props<{ templateSectionAssets: any }>());
export const TemplateSectionGetAssetsError = createAction(ETemplateSectionActions.TemplateSectionGetAssetsError,
  props<{ error: string }>());

export const ResetTemplateSectionState = createAction(ETemplateSectionActions.ResetTemplateSectionState, (params: any = {}) => params);

