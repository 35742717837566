import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, timer } from 'rxjs';
import * as moment from 'moment-timezone';

function getTopBar() {
  // @ts-ignore
  return topbar;
}

@Injectable({ providedIn: 'root' })
export class LoaderService {
  private loaderSubject: BehaviorSubject<boolean>;
  public loader$: Observable<boolean>;

  // @TODO: find a better solution for this
  public showNavbarSubject: BehaviorSubject<boolean> = new BehaviorSubject(true);
  public showNavbar$: Observable<boolean> = this.showNavbarSubject.asObservable();

  // Logout Timer
  public logoutSubject: BehaviorSubject<boolean>;
  public logout$: Observable<boolean>;

  public logoutSubscription: any;
  private config: any = {
    autoRun: true,
    barThickness: 3,
    shadowBlur: 10,
    shadowColor: 'rgba(0,   0,   0,   .6)',
  };

  constructor() {
    this.loaderSubject = new BehaviorSubject<boolean>(false);
    this.loader$ = this.loaderSubject.asObservable();

    // Logout Timer
    this.logoutSubject = new BehaviorSubject<boolean>(false);
    this.logout$ = this.logoutSubject.asObservable();
    this.startTimer();
  }

  startTimer() {
    this.logoutSubscription = timer(0, 1000).subscribe((seconds) => {
      let loginInTime: any = localStorage.getItem('loginInTime');
      if (loginInTime) {
        loginInTime = moment(loginInTime);
        const JWTTokenExpireTime = 10800 - 3600;
        const logoutTime = loginInTime.add(JWTTokenExpireTime, 'seconds');
        const timeRemaining = logoutTime.diff(moment()) / 1000;
        if (timeRemaining < 0) {
          // localStorage.removeItem('loginInTime');
          this.logoutSubject.next(true);
          this.logoutSubscription.unsubscribe();
        }
      } else {
        this.unsubscribe();
      }
    });
  }

  unsubscribe() {
    if (this.logoutSubscription) {
      this.logoutSubscription.unsubscribe();
    }
  }

  setConfig(config) {
    getTopBar().config(config);
  }

  show() {
    this.loaderSubject.next(true);
    this.config = {
      ...this.config,
      barColors: {
        '0': '#4BB7E3',
        '.25': '#4BB7E3',
        '.50': '#4BB7E3',
        '.75': '#4BB7E3',
        '1.0': '#4BB7E3',
      },
    };
    this.setConfig(this.config);
    getTopBar().show();
  }

  setProgress(progress: number) {
    getTopBar().progress(progress);
  }

  hide(requestFailed = false) {
    this.loaderSubject.next(false);
    if (requestFailed) {
      this.config = {
        ...this.config,
        barColors: {
          '0': '#dc3545',
          '.25': '#dc3545',
          '.50': '#dc3545',
          '.75': '#dc3545',
          '1.0': '#dc3545',
        },
      };
      this.setConfig(this.config);
    }
    getTopBar().hide();
  }

  getErrorMessage(error: any, def: string = 'Something went wrong') {
    if (error.error) {
      if (error.error.msg) {
        def = error.error.msg;
      } else if (error.error.message) {
        def = error.error.message;
      } else if (error.error.err) {
        if (error.error.err.message) {
          def = error.error.err.message;
        }
      }
    } else if (error.msg) {
      def = error.msg;
    } else if (error.message) {
      def = error.message;
    }
    if (def === 'jwt expired') {
      def = '';
    }
    return def;
  }
}
