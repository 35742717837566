import {PaginatedUser, User} from '@app/modules/shared/models';

export interface IUsersState {
  user: User;
  updateUser: User;
  roles: any;
  users: User[];
  paginatedUsers: PaginatedUser;
  success: string;
  error: string;
}

export const initialUsersState: IUsersState = {
  user: null,
  updateUser: null,
  users: [],
  roles: [],
  paginatedUsers: null,
  success: '',
  error: ''
};
