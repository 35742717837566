import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {ReactiveFormsModule, FormsModule} from '@angular/forms';
import {DataTablesModule} from 'angular-datatables';
import {ProjectRoutingModule} from './project-routing.module';
import {NgxMaterialTimepickerModule} from 'ngx-material-timepicker';
import {BsDatepickerModule} from 'ngx-bootstrap/datepicker';

import {SharedModule} from '../shared/shared.module';
import { MatLegacyMenuModule as MatMenuModule } from '@angular/material/legacy-menu';

import {ProjectListComponent} from '@app/modules/project/project-list';
import {ProjectAddComponent} from '@app/modules/project/project-add';
import {ProjectViewComponent} from '@app/modules/project/project-view';
import {AddInfluencerComponent} from '@app/modules/project/add-influencer';
import {InfluencerAddComponent} from '@app/modules/project/influencer-add';
import {InfluencerViewComponent} from '@app/modules/project/influencer-view';
import {ImportInfluencerComponent} from './import-influencer';
import {ShareProjectComponent} from './share-project';
import {AwsService} from '@app/services/aws.service';
import {ToastContainerModule} from 'ngx-toastr';
import {TabsModule} from 'ngx-bootstrap/tabs';

import {StatusBarModule} from '@app/modules/status-bar/status-bar.module';
import {AccordionModule} from 'ngx-bootstrap/accordion';
import {FontAwesomeModule} from '@fortawesome/angular-fontawesome';
import {PaginationModule} from 'ngx-bootstrap/pagination';
import {PopoverModule} from 'ngx-bootstrap/popover';
import {DragDropModule} from '@angular/cdk/drag-drop';
import {ColorPickerModule} from 'ngx-color-picker';
import {FontPickerModule} from '@app/modules/fontPicker/fontPicker.module';
import {InfluencerModule} from '@app/modules/influencer/influencer.module';
import {ProjectCopyComponent} from './project-copy/project-copy.component';
import {NgbPopoverModule} from '@ng-bootstrap/ng-bootstrap';
import {WidgetInstructionsComponent} from './widget-instrcutions/widget-instructions.component';
import { MatIconModule } from '@angular/material/icon';
import {MatLegacyTooltipModule as MatTooltipModule} from '@angular/material/legacy-tooltip';
import {AceEditorModule} from 'ng2-ace-editor';
import {ThirdpartyPlatformsComponent} from '@app/modules/project/thirdparty-platforms-view';
import { MomentTimezonePickerModule } from 'moment-timezone-picker'
import {WidgetAvatarTemplatesComponent} from "@app/modules/project/widget-avatar-templates/widget-avatar-templates.component";

@NgModule({
  declarations: [
    ProjectListComponent,
    ProjectAddComponent,
    InfluencerViewComponent,
    ImportInfluencerComponent,
    ThirdpartyPlatformsComponent,
    ProjectViewComponent,
    AddInfluencerComponent,
    InfluencerAddComponent,
    ShareProjectComponent,
    ProjectCopyComponent,
    WidgetInstructionsComponent,
    WidgetAvatarTemplatesComponent
  ],
  providers: [AwsService],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    ProjectRoutingModule,
    DataTablesModule,
    NgxMaterialTimepickerModule,
    BsDatepickerModule,
    SharedModule,
    ToastContainerModule,
    StatusBarModule,
    FontAwesomeModule,
    TabsModule,
    AccordionModule.forRoot(),
    PaginationModule.forRoot(),
    DragDropModule,
    MatMenuModule,
    ColorPickerModule,
    FontPickerModule,
    InfluencerModule,
    NgbPopoverModule,
    PopoverModule.forRoot(),
    MatIconModule,
    MatTooltipModule,
    AceEditorModule,
    MomentTimezonePickerModule
  ]
})
export class ProjectModule {
}
