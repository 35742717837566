export const versionInfo = (() => {
  try {
    // tslint:disable-next-line:no-var-requires
    const versionInfo = require('./git-version.json');
    return versionInfo;
  } catch {
    // In dev the file might not exist:
    return {"build_date": "", "build_number": "1.0.0"};
  }
})();
