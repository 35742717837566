import { Injectable } from '@angular/core';
import { UntypedFormGroup, Validators, ValidationErrors } from '@angular/forms';
@Injectable({
  providedIn: 'root'
})
export class FormService {
  constructor() {}

  setNewOrExistingSegmentValidation(
    segmentForm: UntypedFormGroup,
    isExisting: number): void {
    if (isExisting === 0) {
      segmentForm.get('project_influencer_segment_id').clearValidators();
      segmentForm.get('project_influencer_segment_new_name').setValidators(Validators.required);
    } else {
      segmentForm.get('project_influencer_segment_id').setValidators(Validators.required);
      segmentForm.get('project_influencer_segment_new_name').clearValidators();
    }
    segmentForm.get('project_influencer_segment_id').updateValueAndValidity();
    segmentForm.get('project_influencer_segment_new_name').updateValueAndValidity();
  }
}
