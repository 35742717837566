<div class="container-fluid mobile-view pt-3 new-styles">
  <div class="right-body">
    <!--    <div class="row">-->
    <!--      <div class="col-xl-3 col-md-3 col-sm-3">-->
    <!--        <div class="card card-box card-box-border-bottom border-info text-left mb-5 p-4">-->
    <!--          <div class="d-flex align-items-center">-->
    <!--            <div class="d-70 rounded-circle bg-neutral-first text-white mr-3 text-center">-->
    <!--              <li class="fa fa-users" style="font-size: 2rem; color: #4ab8e3; margin-top: 1.2rem;"></li>-->
    <!--            </div>-->
    <!--            <div class="flex-grow-1">-->
    <!--              <div class="font-size-xxl font-weight-bold">{{totalInfluencers}}</div>-->
    <!--              <div class="text-black-50 pb-1 font-weight-bold">influencers</div>-->
    <!--            </div>-->
    <!--          </div>-->
    <!--        </div>-->
    <!--      </div>-->
    <!--      <div class="col-xl-3 col-md-3 col-sm-3">-->
    <!--        <div class="card card-box card-box-border-bottom border-info text-left mb-5 p-4">-->
    <!--          <div class="d-flex align-items-center">-->
    <!--            <div class="d-70 rounded-circle bg-neutral-first text-white mr-3 text-center">-->
    <!--              <li class="fa fa-line-chart" style="font-size: 2rem; color: #4ab8e3; margin-top: 1.2rem;"></li>-->
    <!--            </div>-->
    <!--            <div class="flex-grow-1">-->
    <!--              <div class="font-size-xxl font-weight-bold ">{{totalPageViews}}</div>-->
    <!--              <div class="text-black-50 pb-1 font-weight-bold">page views</div>-->
    <!--            </div>-->
    <!--          </div>-->
    <!--        </div>-->
    <!--      </div>-->
    <!--      <div class="col-xl-3 col-md-3 col-sm-3">-->
    <!--        <div class="card card-box card-box-border-bottom border-info text-left mb-5 p-4">-->
    <!--          <div class="d-flex align-items-center">-->
    <!--            <div class="d-70 rounded-circle bg-neutral-first text-white mr-3 text-center">-->
    <!--              <li class="fa fa-share" style="font-size: 2rem; color: #4ab8e3; margin-top: 1.2rem;"></li>-->
    <!--            </div>-->
    <!--            <div class="flex-grow-1">-->
    <!--              <div class="font-size-xxl font-weight-bold ">{{totalShares}}</div>-->
    <!--              <div class="text-black-50 pb-1 font-weight-bold">shares</div>-->
    <!--            </div>-->
    <!--          </div>-->
    <!--        </div>-->
    <!--      </div>-->
    <!--      <div class="col-xl-3 col-md-3 col-sm-3">-->
    <!--        <div class="card card-box card-box-border-bottom border-info text-left mb-5 p-4">-->
    <!--          <div class="d-flex align-items-center">-->
    <!--            <div class="d-70 rounded-circle bg-neutral-first text-first mr-3 text-center">-->
    <!--              <li class="fa fa-dollar" style="font-size: 2rem; color: #4ab8e3; margin-top: 1.2rem;"></li>-->
    <!--            </div>-->
    <!--            <div class="flex-grow-1">-->
    <!--              <div class="font-size-xxl font-weight-bold ">{{totalReferrals}}</div>-->
    <!--              <div class="text-black-50 pb-1 font-weight-bold">conversions</div>-->
    <!--            </div>-->
    <!--          </div>-->
    <!--        </div>-->
    <!--      </div>-->
    <!--    </div>-->
    <div *ngIf="errorMessage?.length" class="show-error">
      <h2>{{errorMessage}}</h2>
    </div>
    <div [hidden]="!allProjectList?.length && !isOnLoad"
         class="test-{{allProjectList?.length}} card card-box mb-5 my-project border-0 shadow-none">
      <div class="card-header py-3 px-0">
        <div class="card-header--title font-size-lg">
          My Snöballed Projects
        </div>
        <div class="card-header--actions start-project">
          <button class="btn btn-sm btn-primary pull-right" type="button"
                  routerLink="/{{routes.createProject()}}" role="button">
            <span class="btn-wrapper--icon"><i class="fa fa-plus"></i></span>
            <span class="btn-wrapper--label">Start Project</span>
          </button>
        </div>
      </div>
      <div class="d-flex flex-wrap justify-content-between project-name-dropdown">
        <div class="client-dropdown-position">
          <div class="client-dropdown d-flex align-items-center" *ngIf="isAdmin">
            <app-auto-complete-select
              [hideLabel]="true"
              [isCreatable]="false"
              [onlyBottomBorder]="false"
              [placeholder]="'Select Client'"
              [selectOptions]="planners"
              [selectedValue]="selectedClient !== 'All' ? selectedClient : null"
              (optionSelected)="projectList($event)"
              (searchValue)="handleSearchValue($event)"
              (scrollToEnd)="handleScroll()"
            ></app-auto-complete-select>
          </div>
        </div>
        <div class='customize-email d-flex align-items-center ml-auto'>
          <div>
            <input id="customize_columns" placeholder="" [checked]="showAll" (change)="showAllColumns($event)"
                   type="checkbox">
            <label for="customize_columns">Display Project Stats</label>
          </div>
          <a class="filters ml-3" [ngClass]="{'active-status': selectedStatus === 'all'}" (click)="onChangeProjectStatus('all')">
            All
          </a>
          <a class="filters" [ngClass]="{'active-status': selectedStatus === 'active'}" (click)="onChangeProjectStatus('active')">
            Active
          </a>
          <a class="filters" [ngClass]="{'active-status': selectedStatus === 'draft'}" (click)="onChangeProjectStatus('draft')">
            Draft
          </a>
          <a class="filter-last" [ngClass]="{'active-status': selectedStatus === 'completed'}" (click)="onChangeProjectStatus('completed')">
            Completed
          </a>
          <button type="button" [ngClass]="{'active-btn': viewType === 'card', 'inactive-btn': viewType !== 'card'}" class="btn btn-default btn-sm ml-3 list-button" (click)="changeViewType('card')">
            <i class="fa fa-th-large mr-1"></i>
            Card
          </button>
          <button type="button" [ngClass]="{'active-btn': viewType === 'list', 'inactive-btn': viewType !== 'list'}" class="btn btn-default btn-sm card-button" (click)="changeViewType('list')">
            <i class="fa fa-th-list mr-1"></i>
            List
          </button>
        </div>
      </div>

      <div class="custom-table px-0" [ngClass]="{'project-list-table pt-0': viewType === 'card'}">
        <table width="100%" class="sb-table table dataTable no-footer nowrap" datatable
               [dtTrigger]="dtTrigger"
               [dtOptions]="dtOptions"
               id="dt-table">
          <thead>
          <tr [ngClass]="{'d-none': viewType !== 'list'}">
            <th class="no-sort project-image"></th>
            <th><span>NAME</span></th>
            <th class="no-sort">CAMPAIGNS</th>
            <th class="no-sort" [ngClass]="{'d-none': !showAll}">INFLUENCERS</th>
            <th class="no-sort" [ngClass]="{'d-none': !showAll}">OPEN/CLICK RATE</th>
            <th class="no-sort" [ngClass]="{'d-none': !showAll}">IMPRESSIONS</th>
            <th class="no-sort" [ngClass]="{'d-none': !showAll}">CONVERSIONS</th>
            <th class="no-sort d-none">CREATED DATE</th>
            <th class="no-sort"></th>
          </tr>
          </thead>
          <tbody *ngIf="!(loader$ | async) && projects?.length > 0">
          <ng-container *ngIf="viewType === 'list'">
            <tr *ngFor="let project of projects">
              <td class="cursor-pointer">
                <img class="image-thumbnail" *ngIf="project.image" [src]="project.image" alt=""/>
              </td>
              <td class="font-weight-bold">
                <div class="d-flex align-items-start">
                  <ng-container *ngIf="project.notes">
                    <button type="button" class="info-button position-absolute" [ngbPopover]="notesTemplate"
                            triggers="mouseenter:mouseleave" placement="auto" container="body"
                            [popoverClass]="'info-popover'">
                      <i class="fa fa-file-text-o" aria-hidden="true"></i>
                    </button>

                    <ng-template #notesTemplate>{{project.notes}}</ng-template>
                  </ng-container>
                  <div>
                    <span class="cursor-pointer"
                          routerLink="/{{routes.campaigns({projectId: project.id})}}">{{project.title}}</span>
                    <span class="d-block client-container">
                  <small class="mr-1"
                         title="Project Status">{{getStatus(project) ? getActiveStatus(project) : 'Completed'}}</small>
                  <small>-</small>
                  <small class="mr-1 ml-1" title="Project Code">{{project.code}}</small>
                  <small>-</small>
                  <small title="Client Name" class="ml-1  cursor-pointer"
                         (click)='projectListByClient(project.client)'>{{project.client.name}}</small>
                </span>
                  </div>
                </div>
              </td>
              <td routerLink="/{{routes.campaigns({projectId: project.id})}}" class="cursor-pointer">
                {{project.campaign_count}}
              </td>
              <td [ngClass]="{'d-none': !showAll}">{{project.total_influencer_count}}</td>
              <td [ngClass]="{'d-none': !showAll}">{{project.emailProgress?.opened}}/ <span class="click-rate">{{getOpenClickRate(project)}}%</span></td>
              <td [ngClass]="{'d-none': !showAll}">{{project.pageviews_count ? project.pageviews_count : '-'}}</td>
              <td [ngClass]="{'d-none': !showAll}">{{project.referrals_count}}/<span> {{getReferralsRatePercentage(project)}}%</span></td>
              <td class="d-none">{{project.created_at}}</td>
              <td>
                <button class="btn-icon float-right" mat-icon-button [matMenuTriggerFor]="menu"
                        aria-label="Example icon-button with a menu">
                  <span class="fa fa-ellipsis-v"></span>
                </button>
                <mat-menu #menu="matMenu" class="campaign-popover">
                  <a class="dropdown-item d-flex align-items-center"
                     routerLink="/{{routes.createCampaign({projectId: project.id})}}">
                    <i class="fa fa-plus mr-2" aria-hidden="true"></i>
                    Start a new campaign
                  </a>
                  <a class="dropdown-item d-flex align-items-center" [routerLink]="['/projects', project.id]">
                    <i class="fa fa-pencil mr-2"></i>
                    Edit
                  </a>
                  <a class="dropdown-item d-flex align-items-center"
                     routerLink="/{{routes.campaigns({projectId: project.id})}}">
                    <i class="fa fa-list mr-2" aria-hidden="true"></i>
                    Manage Campaigns
                  </a>
                  <a class="dropdown-item d-flex align-items-center"
                     routerLink="/{{routes.statistics({projectId: project.id})}}">
                    <i class="fa fa-eye mr-2"></i>
                    View Results
                  </a>
                  <a class="dropdown-item d-flex align-items-center" (click)="copyProject(project.id)">
                    <i class="fa fa-clipboard mr-2"></i>
                    Duplicate
                  </a>
                  <a class="dropdown-item d-flex align-items-center" (click)="deleteProject(project.id)">
                    <i class="fa fa-trash mr-2"></i>
                    Delete
                  </a>
                </mat-menu>
              </td>
            </tr>
          </ng-container>
          <ng-container *ngIf="!(loader$ | async) && viewType === 'card'">
            <tr *ngFor="let project of projects" class="project-card-view">
              <td class="font-weight-bold project-client-name">
                <div class="d-flex align-items-center justify-content-between project-client-details">
                  <div class="d-flex align-items-center project-client-name-details">
                    <div class="img-wrapper mr-3 cursor-pointer" [ngClass]="{'image-cover' : !project.image}">
                      <img class="image-thumbnail img-fluid" *ngIf="project.image" [src]="project.image" alt=""/>
                    </div>
                    <div class="name">
                      <ng-container *ngIf="project.notes">
                        <button type="button" class="info-button position-absolute" [ngbPopover]="notesTemplate"
                                triggers="mouseenter:mouseleave" placement="auto" container="body"
                                [popoverClass]="'info-popover'">
                          <i class="fa fa-file-text-o" aria-hidden="true"></i>
                        </button>

                        <ng-template #notesTemplate>{{project.notes}}</ng-template>
                      </ng-container>
                      <div class="project-name-details">
                        <span class="cursor-pointer"
                              routerLink="/{{routes.campaigns({projectId: project.id})}}">{{project.title}}</span>
                        <span class="d-block">
                        <small class="mr-1">{{getStatus(project) ? getActiveStatus(project) : 'Completed'}}</small>
                        <small>-</small>
                        <small class="mr-1 ml-1">{{project.code}}</small>
                        <small>-</small>
                        <small class="cursor-pointer ml-1"
                               (click)='projectListByClient(project.client)'>{{project.client.name}}</small>
                      </span>
                      </div>
                    </div>
                  </div>
                  <div>
                    <button class="btn-icon float-right" mat-icon-button [matMenuTriggerFor]="menu" aria-label="Example icon-button with a menu">
                      <span class="fa fa-ellipsis-v"></span>
                    </button>
                    <mat-menu #menu="matMenu" class="campaign-popover">
                      <a class="dropdown-item d-flex align-items-center"
                         routerLink="/{{routes.createCampaign({projectId: project.id})}}">
                        <i class="fa fa-plus mr-2" aria-hidden="true"></i>
                        Start a new campaign
                      </a>
                      <a class="dropdown-item d-flex align-items-center" [routerLink]="['/projects', project.id]">
                        <i class="fa fa-pencil mr-2"></i>
                        Edit
                      </a>
                      <a class="dropdown-item d-flex align-items-center"
                         routerLink="/{{routes.campaigns({projectId: project.id})}}">
                        <i class="fa fa-list mr-2" aria-hidden="true"></i>
                        Manage Campaigns
                      </a>
                      <a class="dropdown-item d-flex align-items-center"
                         routerLink="/{{routes.statistics({projectId: project.id})}}">
                        <i class="fa fa-eye mr-2"></i>
                        View Results
                      </a>
                      <a class="dropdown-item d-flex align-items-center" (click)="copyProject(project.id)">
                        <i class="fa fa-clipboard mr-2"></i>
                        Duplicate
                      </a>
                      <a class="dropdown-item d-flex align-items-center" (click)="deleteProject(project.id)">
                        <i class="fa fa-trash mr-2"></i>
                        Delete
                      </a>
                    </mat-menu>
                  </div>
                </div>
              </td>
              <td class="project-status">
                <div class="campaign" routerLink="/{{routes.campaigns({projectId: project.id})}}">
                  {{project.campaign_count}} campaigns
                </div>
                <div>
                  <div class="card-header--actions">
                    <button class="btn btn-primary btn-sm"
                            routerLink="/{{routes.createCampaign({projectId: project.id})}}">
                      <span class="btn-wrapper--icon"><i class="fa fa-plus"></i></span>
                      <span class="btn-wrapper--label">Start Campaign</span>
                    </button>
                  </div>
                </div>
              </td>
              <td  [ngClass]="{'d-none': !showAll && isAdmin}" class="project-social-details">
                <div class="project-social-bg">
                  <div class="d-flex align-items-center">
                    <div class="rounded-circle bg-neutral-first text-white mr-3 text-center">
                      <li class="fa fa-users" style="color: #4ab8e3;"></li>
                    </div>
                    <div class="">
                      <div
                        class="font-size-xxl font-weight-bold social-count">{{project.total_influencer_count}}</div>
                      <div class="pb-1 social-name">Influencers</div>
                    </div>
                  </div>
                </div>
                <div class="project-social-bg">
                  <div class="d-flex align-items-center">
                    <div class="rounded-circle bg-neutral-first text-white mr-3 text-center">
                      <li class="fa fa-envelope" style="color: #4ab8e3;"></li>
                    </div>
                    <div class="">
                      <div class="font-size-xxl font-weight-bold social-count">{{project.emailProgress?.opened}}/ <span
                        class="click-rate">{{getOpenClickRate(project)}}%</span></div>
                      <div class="pb-1 social-name">Open/Click Rate</div>
                    </div>
                  </div>
                </div>
                <div class="project-social-bg">
                  <div class="d-flex align-items-center">
                    <div class="rounded-circle bg-neutral-first text-white mr-3 text-center">
                      <li class="fa fa-eye" style="color: #4ab8e3;"></li>
                    </div>
                    <div class="">
                      <div
                        class="font-size-xxl font-weight-bold social-count">{{project.pageviews_count ? project.pageviews_count : '0'}}</div>
                      <div class="pb-1 social-name">Impressions</div>
                    </div>
                  </div>
                </div>
                <div class="project-social-bg">
                  <div class="d-flex align-items-center">
                    <div class="rounded-circle bg-neutral-first text-white mr-3 text-center">
                      <li class="fa fa-usd" style="color: #4ab8e3;"></li>
                    </div>
                    <div class="">
                      <div class="font-size-xxl font-weight-bold social-count">{{getReferralsRatePercentage(project)}}%
                      </div>
                      <div class="pb-1 social-name">Conversion</div>
                    </div>
                  </div>
                </div>
              </td>
            </tr>
          </ng-container>
          </tbody>
          <tbody *ngIf="projects?.length <= 0">
          <tr [ngClass]="{'no-data-card-view': viewType === 'card' && projects?.length <= 0}">
            <td colspan="9" class="no-data-available">No data available in table</td>
          </tr>
          </tbody>
        </table>
      </div>
    </div>
    <ng-container *ngIf="!(loader$ | async) && !allProjectList?.length && !errorMessage?.length && !isOnLoad">
      <div class="no-data">
        <div class="container h-100">
          <div class="row h-100 align-items-center">
            <div class="col-12">
              <div class="no-data-bg text-center">
                <div class="content">
                  <h3 class="title">
                    Welcome to Snöball!
                  </h3>
                  <div class="img-wrapper">
                    <img src="/assets/images/my-project-no-content.svg" class="img-fluid" alt="no-content-images">
                  </div>
                  <p class="details">
                    Snöball makes it easy to scale influencer marketing to grow your events. Mobilize your attendees,
                    speakers, sponsors and exhibitors to social-proof your event. We’ll help you get started.
                  </p>
                  <a href="#" class="new-project-btn" routerLink="/{{routes.createProject()}}">
                    <i class="fa fa-plus"></i>
                    Start a new project
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </ng-container>
  </div>
</div>

