import {Action, createReducer, on} from '@ngrx/store';
import {
  ClientsList,
  ClientsListError,
  ClientsListSuccess,
  ClientUpdate,
  ClientUpdateSuccess,
  ClientUpdateError,
  ClientDelete,
  ClientDeleteSuccess,
  ClientDeleteError,
  ClientAdd,
  ClientAddSuccess,
  ClientAddError,
  ResetClientState,
  ClientsListAll,
  ClientsListAllSuccess,
  ClientsListAllError,
  ClientGet,
  ClientGetSuccess, ClientGetError
} from '@app/stores/client/client.actions';
import {initialClientState} from '@app/stores/client/client.state';

const generateReducer = createReducer(initialClientState,
  on(ClientsListAll, (state) => ({
    ...state,
    client: null,
    clients: [],
    success: '',
    error: ''
  })),
  on(ClientsListAllSuccess, (state, {clients}) => ({
    ...state,
    client: null,
    clients,
    success: '',
    error: ''
  })),
  on(ClientsListAllError, (state, {error}) => ({
    ...state,
    client: null,
    clients: [],
    success: '',
    error
  })),
  on(ClientsList, (state) => ({
    ...state,
    client: null,
    paginatedClients: null,
    success: '',
    error: ''
  })),
  on(ClientsListSuccess, (state, {paginatedClients}) => ({
    ...state,
    client: null,
    paginatedClients,
    success: '',
    error: ''
  })),
  on(ClientsListError, (state, {error}) => ({
    ...state,
    client: null,
    paginatedClients: null,
    success: '',
    error
  })),
  on(ClientUpdate, (state) => ({
    ...state,
    success: '',
    error: ''
  })),
  on(ClientUpdateSuccess, (state) => ({
    ...state,
    success: 'Client updated successfully',
    error: ''
  })),
  on(ClientUpdateError, (state, {error}) => ({
    ...state,
    success: '',
    error
  })),
  on(ClientDelete, (state) => ({
    ...state,
    success: '',
    error: ''
  })),
  on(ClientDeleteSuccess, (state) => ({
    ...state,
    success: 'Client deleted successfully',
    error: ''
  })),
  on(ClientDeleteError, (state, {error}) => ({
    ...state,
    success: '',
    error
  })),
  on(ClientAdd, (state) => ({
    ...state,
    success: '',
    error: ''
  })),
  on(ClientAddSuccess, (state) => ({
    ...state,
    success: 'Client added successfully',
    error: ''
  })),
  on(ClientAddError, (state, {error}) => ({
    ...state,
    success: '',
    error
  })),
  on(ClientGet, (state) => ({
    ...state,
    client: null,
    success: '',
    error: ''
  })),
  on(ClientGetSuccess, (state, {client}) => ({
    ...state,
    client,
    success: '',
    error: ''
  })),
  on(ClientGetError, (state, {error}) => ({
    ...state,
    client: null,
    success: '',
    error
  })),

  on(ResetClientState, (state, {params}) => ({
    ...state,
    ...params
  })),
);

export function clientReducer(state = initialClientState, action: Action) {
  return generateReducer(state, action);
}
