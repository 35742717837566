<div class="container-fluid pt-3">
  <div class="rightBody">
    <!-- Add User Section  Start -->
    <div class="card">
      <div class="card-header py-3">
        <div class="card-header--title font-size-lg">
          {{clientId ? 'Update' : 'Add'}} client
        </div>
      </div>
      <div class="card-body">
        <div class="form-wraper">
          <form (ngSubmit)="saveClient()" [formGroup]="addClientForm">
            <!-- Add User form column start -->
            <div class="row">
              <div class="col-lg-4 col-md-4 col-sm-12 text-center d-flex justify-content-center align-items-center">
                <div class="image-event">
                  <img [src]="imageSrc" *ngIf="imageSrc" class="img-fluid d-block" id="event-logo-preview"/>
                  <button (click)="activeFileInput(fileInput)"
                          [disabled]="submitted && fileUploadProgress > 0 && fileUploadProgress < 100"
                          class="btn btn-default" id="logoUploaderBtn"
                          type="button">
                    <i class="fa fa-upload"></i>
                    {{fileUploadProgress ? fileUploadProgress + '%' : ''}} {{imageSrc ? 'Replace' : 'Upload'}} Logo
                  </button>
                  <app-info-popover
                    info="Acceptable formats are jpeg and png. (Min  800x800px, max 2048x2048px)">
                  </app-info-popover>
                  <input #fileInput (change)="onFileChanged($event)" accept="image/*" class="file-input"
                         id="eventLogo" type="file">
                </div>
              </div>
              <div class="col-md-8 col-md-8 col-sm-12">
                <div class="row">
                  <div class="col-lg-6 col-md-6 col-ms-12" [ngClass]="{'col-lg-12 col-md-6 col-ms-12': clientId !== null}">
                    <div class="floating-label">
                      <input [ngClass]="{ 'is-invalid': submitted && f.name.errors }"
                             formControlName="name" id="name" placeholder=" "
                             required="required" class="floating-input"
                             type="text"/>
                      <label for="name">Name <span class="red">*</span>
                      </label>
                      <div *ngIf="submitted && f.name.errors" class="bar error-brd">
                        <div *ngIf="f.name.errors.required" class="invalid-text">Name is required</div>
                      </div>

                    </div>
                  </div>
                  <div class="col-lg-6 col-md-6 col-ms-12" *ngIf="!clientId">
                    <div class="floating-label input-group input-group-sm variable-input">
                      <input [ngClass]=" { 'is-invalid': submitted && f.password.errors }"
                             formControlName="password" id="password" placeholder=" "
                             required="required" class="floating-input"
                             type="text"/>
                      <label for="password">Password <span class="red">*</span>
                      </label>
                      <div *ngIf="submitted && f.password.errors" class="bar error-brd">
                        <div *ngIf="f.password.errors.required" class="invalid-text">Password is required</div>
                      </div>
                      <button type="button" class="btn btn-default btn-sm p-0" title="Generate Password"
                              (click)="generateRandomPassword()">
                        <i class="fa fa-refresh"></i>
                      </button>
                    </div>
                  </div>
                </div>

                <div class="row">
                  <div class="col-lg-6 col-md-6 col-ms-12">
                    <div class="floating-label">
                      <input [ngClass]="{ 'is-invalid': submitted && f.email.errors }"
                             formControlName="email" id="email" class="floating-input"
                             required="required" placeholder=" "
                             type="text"/>
                      <label for="email">Email <span class="red">*</span>
                      </label>
                      <div *ngIf="submitted && f.email.errors" class="bar error-brd">
                        <div *ngIf="f.email.errors.required" class="invalid-text">Email is required</div>
                        <div *ngIf="f.email.errors.email" class="invalid-text">Enter valid email address</div>
                      </div>
                    </div>
                  </div>
                  <div class="col-lg-6 col-md-6 col-ms-12 py-2">
                    <div class="floating-label">
                      <input [ngClass]="{ 'is-invalid': submitted && f.website.errors }"
                             formControlName="website" id="website" class="floating-input"
                             placeholder=" "
                             type="text"/>
                      <label for="website">Website <span class="red">*</span>
                      </label>
                      <div *ngIf="submitted && f.website.errors" class="bar error-brd">
                        <div *ngIf="f.website.errors.pattern" class="invalid-text">Enter valid URL</div>
                        <div *ngIf="f.website.errors.required" class="invalid-text">Website is required</div>
                      </div>
                    </div>

                  </div>
                </div>

                <div class="row">
                  <div class="col-lg-6 col-md-6 col-ms-12">
                    <div class="floating-label">
                      <app-creatable-select
                        id="package"
                        [selectedValue]="f.package_id.value"
                        [hideLabel]="true" [onlyBottomBorder]="true" [isCreatable]="false"
                        [placeholder]="'Select a Package'" [selectOptions]="packages"
                        (optionSelected)="packageSelected($event)"></app-creatable-select>
                    </div>
                  </div>
                  <div class="col-lg-6 col-md-6 col-ms-12">
                    <div class="row">
                      <div class="col-lg-6 col-md-6 col-ms-12">
                        <div class="calendar align-self-center">
                          <div class="form-group datePicker">
                            <input
                              [minDate]="min"
                              bsDatepicker
                              class="form-control"
                              formControlName="package_start_date"
                              placeholder="Package Start Date"
                              type="text"
                            />
                            <span class="date-ic">
                              <i class=" fa fa-calendar"
                              ></i>
                            </span>
                            <div
                              *ngIf="submitted && f.package_start_date.errors"
                              class="bar error-brd"
                            >
                              <div
                                *ngIf="f.package_start_date.errors.required"
                                class="invalid-text"
                              >
                                Package Start Date is required
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="col-lg-6 col-md-6 col-ms-12">
                        <div class="calendar">
                          <div class="form-group datePicker">
                            <input
                              [minDate]="max"
                              bsDatepicker
                              class="form-control"
                              formControlName="package_end_date"
                              placeholder="Package End Date"
                              type="text"
                            />
                            <span class="date-ic">
                              <i class="fa fa-calendar"
                              ></i>
                            </span>
                            <div
                              *ngIf="submitted && f.package_end_date.errors"
                              class="bar error-brd"
                            >
                              <div
                                *ngIf="f.package_end_date.errors.required"
                                class="invalid-text"
                              >
                                Package End Date is required
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div class="row">
                  <div class="col-md-12">
                    <div class="floating-label input-group input-group-sm info-input">
                      <input formControlName="login_whitelisted_ips" id="login_whitelisted_ips" placeholder=" " class="floating-input" type="text"/>
                      <label for="login_whitelisted_ips">White Listed Ips</label>
                      <app-info-popover
                        info="i.e. IP1,IP2,IP3,IP4, ...">
                      </app-info-popover>
                    </div>
                  </div>
                </div>


                <div class="row">
                  <div class="col-md-12 my-1">
                    <div class="action-check-box align-items-center">
                      <input formControlName="require_google_utm_for_cta_links" id="require_google_utm_for_cta_links" type="checkbox" class="styled-checkbox" >
                      <label for="require_google_utm_for_cta_links" class="ml-2">Require Google UTM for CTA links</label>
                      <app-info-popover
                        info="It will automatically add a UTM link to your CTA links.">
                      </app-info-popover>
                    </div>
                  </div>
                </div>

                <div class="row">
                  <div class="col-md-12">
                    <div class="floating-label input-group input-group-sm info-input">
                      <input formControlName="google_utm_params" id="google_utm_params" placeholder=" " class="floating-input" type="text"/>
                      <label for="login_whitelisted_ips">Google UTM Params</label>
                    </div>
                  </div>
                </div>

                <div class="row">
                  <div class="col-md-12">
                    <div class="floating-label">
                  <textarea [ngClass]="{ 'is-invalid': submitted && f.comment.errors }"
                            class="floating-input"
                            formControlName="comment" id="comment" style="height: 150px"></textarea>
                      <span class="highlight"></span>
                      <label>Comment</label>
                    </div>
                  </div>
                </div>


              </div>
            </div>
            <div _ngcontent-c4="" class="text-right mt-3">
              <button _ngcontent-c4="" class="btn btn-default mr-2" routerLink="/clients">Cancel</button>
              <button _ngcontent-c4="" class="btn btn-primary mr-2" type="submit">Save</button>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</div>
