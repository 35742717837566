<div class="container-fluid pt-3 mobile-p0 campaign-creation-steps">
  <div class="rightBody">
    <app-status-bar [customStyle]="{'margin-left': '19rem'}" [loading]="(loader$ | async) || !isTemplateLoaded" [step]="step"
                    (SaveButtonClick)="onSaveButtonClick()"></app-status-bar>
    <app-template-editor
      [template]="template" [viewportSize]="viewportSize"
      (OnTemplateLoaded)="templateLoaded($event)"
      [isLoading]="(loader$ | async)"
      [showLandingPageEnable]="false"
      [(landingPageEnabled)]="landingPageEnabled"
      (OnPageSettingsChanged)="onStylesCustomizationChange($event)"
      #templateEditor [pageSetting]="stylesForm.value" [variables]="getVariables()"
      (OnTemplateTypeChange)="fetchRawTemplatesById($event)" [templateType]="'landing'" [showTemplateSelector]="false" [showBlockSelector]="true"
      [rawTemplatesTypes]="rawTemplatesTypes" [templates]="templates"></app-template-editor>
  </div>
</div>

<div class="operation-box">
  <div class="col-md-12">
    <div class="row align-items-center">
      <div class="col-md-6 p-0">
        <div class="text-left">
          <button [disabled]="(loader$ | async) || !isTemplateLoaded" (click)="onBackButtonClick()" class="btn btn-default btn-sm" type="button">
            Back
          </button>


          <div class="btn-group dropup">
            <button type="button"
                    [disabled]="(loader$ | async) || !isTemplateLoaded"
                    class="btn btn-default btn-sm dropdown-toggle"
                    data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
              Template
            </button>
            <div class="dropdown-menu border-rad-10x">
              <button [disabled]="(loader$ | async) || !isTemplateLoaded" (click)="onBackButtonClick(true)" class="dropdown-item" type="button">
                Change template
              </button>
              <button [disabled]="(loader$ | async) || !isTemplateLoaded || !this.template?.id" *ngIf="templateTypeId == 1"
                      class="dropdown-item"
                      (click)="saveAsNewTemplateModel(saveAsTemplate, 'client')">
                Save as new template
              </button>
              <button *ngIf="this.isAdmin && templateTypeId == 1" [disabled]="(loader$ | async) || !isTemplateLoaded || !this.template?.id"
                      class="dropdown-item"
                      (click)="saveAsNewTemplateModel(saveAsTemplate, 'public')">
                Save as new public template
              </button>
            </div>
          </div>

          <button [disabled]="(loader$ | async) || !isTemplateLoaded" *ngIf="templateTypeId == 1"
                  class="btn btn-default btn-sm"
                  (click)="showTemplateRevisions()">
            Undo All
          </button>
        </div>
      </div>
      <div class="col-md-3 d-flex p-0">
        <app-device-toolbar
          (onPageSizeChange)="onPageSizeSelect($event)"
          (openCodeEditor)="onEditHtml()"></app-device-toolbar>
      </div>
      <div class="col-md-3 p-0">
        <div class="text-right">
          <button [disabled]="(loader$ | async) || !isTemplateLoaded" *ngIf="templateTypeId == 1"
                  class="btn btn-default btn-sm mr-2"
                  (click)="onPreviewButtonClick()">
            <span><i class="fa fa-spinner fa-spin mr-2" *ngIf="(loader$ | async) || !isTemplateLoaded"></i>Preview</span>
          </button>
          <button [disabled]="(loader$ | async) || !isTemplateLoaded" (click)="onNextButtonClick()"
                  class="btn btn-primary btn-sm">
            <span><i class="fa fa-spinner fa-spin mr-2" *ngIf="(loader$ | async) || !isTemplateLoaded"></i>Next</span>
          </button>
        </div>
      </div>
    </div>
  </div>
</div>

<ng-template #saveAsTemplate let-modal>
  <div class="modal-header">
    <h4 class="modal-title" id="modal-title">Save template as</h4>
    <button type="button" class="close" aria-label="Close button" aria-describedby="modal-title"
            (click)="modal.dismiss('cancel')">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <form [formGroup]="saveTemplateForm">
      <div class="row">
        <div class="col-md-12">
          <div class='input-group input-group-sm'>
            <input class="form-control" formControlName="saveAsTemplateName" placeholder="Template Name" [style]="{background: 'none'}"
                   type="text" [ngClass]="{ 'is-invalid': saveAsTemplateNameSubmit && !saveAsTemplateName }">
          </div>
          <div class="bar error-brd" *ngIf="!saveAsTemplateName && saveAsTemplateNameSubmit">
            <div class="invalid-text">Enter template name</div>
          </div>
        </div>
      </div>

      <div class="row my-2">
        <div class="col-md-12">
          <div class="d-flex align-items-center">
            <small>Influencer Type</small>
          </div>
          <div class='input-group input-group-sm'>
            <select class="floating-select" formControlName="influencer_type_id" (ngModelChange)='changingInfluencerType($event)'>
              <option [value]='influencerType.id'
                      *ngFor="let influencerType of influencerTypes">{{influencerType.name}}</option>
            </select>
          </div>
        </div>
      </div>
    </form>
  </div>
  <input type="hidden" ngbAutofocus>
  <div class="modal-footer">
    <button type="button" class="btn btn-default" (click)="modal.close()">Close</button>
    <button type="button" class="btn btn-primary" (click)="saveAsNewTemplate(modal)">Submit</button>
  </div>
</ng-template>
