import {createFeatureSelector, createSelector} from '@ngrx/store';
import {IInfluencersState, IProjectState} from '@app/stores';

const selectInfluencer = createFeatureSelector<IInfluencersState>('influencer');

export const getInfluencer = createSelector(selectInfluencer, (state: IInfluencersState) => state.influencer);

export const getInfluencerSuccess = createSelector(selectInfluencer, (state: IInfluencersState) => state.success);

export const getInfluencerError = createSelector(selectInfluencer, (state: IInfluencersState) => state.error);

export const getInfluencers = createSelector(selectInfluencer, (state: IInfluencersState) => state.influencers);

export const getInfluencersProjectSegment = createSelector(selectInfluencer, (state: IInfluencersState) => state.influencersProjectSegment);

export const getInfluencersCsvToValues = createSelector(selectInfluencer, (state: IInfluencersState) => state.influencersCsvToValues);

export const getInfluencersImportHeaders = createSelector(selectInfluencer, (state: IInfluencersState) => state.influencersImportHeaders);

export const getInfluencersImportHeadersError = createSelector(selectInfluencer, (state: IInfluencersState) => state.error);

export const getInfluencersImport = createSelector(selectInfluencer, (state: IInfluencersState) => state.influencersImport);

export const getInfluencersImportError = createSelector(selectInfluencer, (state: IInfluencersState) => state.error);

export const getInfluencersExport = createSelector(selectInfluencer, (state: IInfluencersState) => state.influencersExport);

export const getInfluencersTypes = createSelector(selectInfluencer, (state: IInfluencersState) => state.influencerTypes);

export const getInfluencersReferralsList = createSelector(selectInfluencer, (state: IInfluencersState) => state.referralsList);

export const getInfluencersReferralsListPaginated = createSelector(selectInfluencer, (state: IInfluencersState) =>
  state.paginatedReferralsList);

export const getPaginatedInfluencers = createSelector(selectInfluencer, (state: IInfluencersState) => state.paginatedInfluencers);

export const getInfluencersTaskLogs = createSelector(selectInfluencer, (state: IInfluencersState) => state.influencersTaskLogs);

export const getBulkInfluencers = createSelector(selectInfluencer, (state: IInfluencersState) => state.influencerBulk);

export const getInfluencersImportFields = createSelector(selectInfluencer, (state: IInfluencersState) => state.influencersImportFields);
