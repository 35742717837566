import {Injectable} from '@angular/core';
import {Actions, createEffect, ofType} from '@ngrx/effects';
import {Store} from '@ngrx/store';
import {catchError, map, switchMap} from 'rxjs/operators';
import {of} from 'rxjs';
import {LoaderService} from '@app/services/loader.service';
import {
  VariableList, VariableListSuccess, VariableListError
} from '@app/stores/variable/variable.actions';
import {IVariableState} from '@app/stores/variable/variable.state';
import {VariableService} from '@app/services/variable.service';

@Injectable()
export class VariableEffects {
  constructor(
    private _actions$: Actions,
    private _store: Store<IVariableState>,
    private variableService: VariableService,
    private loaderService: LoaderService
  ) {
  }

  variableListing$ = createEffect(() => this._actions$.pipe(
    ofType(VariableList),
    switchMap((action) => {
      this.loaderService.show();
      return this.variableService.list(action.params).pipe(
        map((resp: any) => {
          if (resp.success) {
            this.loaderService.hide();
            return VariableListSuccess({variables: resp.data});
          }
          this.loaderService.hide(true);
          return VariableListError({error: this.loaderService.getErrorMessage(resp)});
        }),
        catchError(error => {
          this.loaderService.hide(true);
          return of(VariableListError({error: this.loaderService.getErrorMessage(error)}));
        })
      );
    })
  ));
}
