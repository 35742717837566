import {Package, PaginatedPackage, User} from '@app/modules/shared/models';

export interface IPackageState {
  pkg: Package;
  packages: Package[];
  updatePackage: Package;
  paginatedPackages: PaginatedPackage;
  success: string;
  error: string;
}

export const initialPackageState: IPackageState = {
  pkg: null,
  paginatedPackages: null,
  updatePackage: null,
  packages: [],
  success: '',
  error: ''
};
