import { Injectable } from '@angular/core';

@Injectable ({
    providedIn: 'root'
})
export class ConfigsService {
    INFLUENCERS_TYPE = [
        {
            id  : 3,
            type: 'Attendee'
        },
        {
            id  : 4,
            type: 'Exhibitor'
        },
        {
            id  : 5,
            type: 'Speaker'
        },
        {
            id  : 6,
            type: 'Sponsor'
        }
    ];
    
    constructor() {
    }
}
