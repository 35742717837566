import {
  Component,
  Input,
  OnInit,
  Output,
  ViewEncapsulation,
  EventEmitter, ViewChild, OnChanges, SimpleChanges, ChangeDetectorRef
} from '@angular/core';

@Component({
  selector: 'app-color-picker',
  templateUrl: './color-picker.component.html',
  encapsulation: ViewEncapsulation.None,
  styleUrls: ['./color-picker.component.css']
})
export class ColorPickerComponent implements OnInit {

  @Input() ngModel = '';
  @Input() id = '';
  @Input() ngClass = {};
  @Input() class = '';
  @Input() value = '';
  @Input() pickerClass = '';
  @Output() ngModelChange = new EventEmitter();
  @Output() change = new EventEmitter();
  // @Input() extraColors = ['#0a7df0', '#e38f3f', '#e14940', '#fedb1c'];
  @Input() extraColors = [];
  openPicker = false;
  inputHex = '';
  defaultColors = ['#1ABC9C', '#54ACD2', '#2C82C9', '#9365B8', '#475577', '#CCCCCC', '#41A85F',
    '#00A885', '#3D8EB9', '#2969B0', '#553982', '#28324E', '#000000', '#F7DA64',
    '#FBA026', '#EB6B56', '#E25041', '#A38F84', '#EFEFEF', '#FFFFFF', '#FAC51C',
    '#F37934', '#D14841', '#B8312F', '#7C706B', '#D1D5D8',
  ];

  preventClick = false;
  initialized = false;

  constructor(private cdr: ChangeDetectorRef) {
    this.closePicker = this.closePicker.bind(this);
    this.openColorPicker = this.openColorPicker.bind(this);
  }

  ngOnInit(): void {
    this.inputHex = '';
    this.ngModel = this.value;
    this.extraColors = this.extraColors || [];
  }

  closePicker(): void {
    if (this.openPicker && !this.preventClick) {
      this.openPicker = false;
      this.initialized = false;

      this.cdr.detectChanges();
      document.onclick = () => {};
    }
  }

  setPreventClick(bool): void {
    this.preventClick = bool;
  }

  customColor(): void {
    if (this.inputHex && this.inputHex.trim() && this.inputHex.includes('#')) {
      this.setColor(this.inputHex.trim());
      this.cdr.detectChanges();
    }
  }

  setColor(color) {
    this.ngModel = '#' + color.replace('#', '');
    this.value = '#' + color.replace('#', '');
    this.change.emit({target: {value: color}});
    this.ngModelChange.emit(this.ngModel);
    this.openPicker = false;
  }

  getFontColor(): string {
    let hexcolor = this.ngModel || this.value || '#ffffff';
    hexcolor = hexcolor.replace('#', '');
    const r = parseInt(hexcolor.substr(0, 2), 16);
    const g = parseInt(hexcolor.substr(2, 2), 16);
    const b = parseInt(hexcolor.substr(4, 2), 16);
    const yiq = ((r * 299) + (g * 587) + (b * 114)) / 1000;
    return (yiq >= 128) ? '#000000' : '#ffffff';
  }

  openColorPicker(): void {
    this.openPicker = true;
    setTimeout(() => {
      if (!this.initialized) {
        document.onclick = this.closePicker;
        this.initialized = true;
      }
    });
  }
}
