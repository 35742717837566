import {createAction, props} from '@ngrx/store';
import {Campaign, PaginatedCampaigns} from '@app/modules/shared/models';

enum ECampaignActions {
  CampaignGet = '[Campaign] Campaign Get',
  CampaignGetSuccess = '[Campaign] Campaign Get Success',
  CampaignGetError = '[Campaign] Campaign Get Error',
  CampaignUpdate = '[Campaign] Campaign Update',
  CampaignUpdateSuccess = '[Campaign] Campaign Update Success',
  CampaignUpdateError = '[Campaign] Campaign Update Error',
  CampaignCreate = '[Campaign] Campaign Create',
  CampaignCreateSuccess = '[Campaign] Campaign Create Success',
  CampaignCreateError = '[Campaign] Campaign Create Error',
  CampaignGetAll = '[Campaign] Campaign Get All',
  CampaignGetAllSuccess = '[Campaign] Campaign Get All Success',
  CampaignGetAllError = '[Campaign] Campaign Get All Error',
  CampaignLaunch = '[Campaign] Campaign Launch',
  CampaignLaunchSuccess = '[Campaign] Campaign Launch Success',
  CampaignLaunchError = '[Campaign] Campaign Launch Error',
  CampaignLaunchScheduled = '[Campaign] Campaign Launch Scheduled',
  CampaignLaunchScheduledSuccess = '[Campaign] Campaign Scheduled Launch Success',
  CampaignLaunchScheduledError = '[Campaign] Campaign Scheduled Launch Error',
  CampaignDelete = '[Campaign] Campaign Delete',
  CampaignDeleteSuccess = '[Campaign] Campaign Delete Success',
  CampaignDeleteError = '[Campaign] Campaign Delete Error',
  CampaignCopy = '[Campaign] Campaign Copy',
  CampaignCopySuccess = '[Campaign] Campaign Copy Success',
  CampaignCopyError = '[Campaign] Campaign Copy Error',
  CampaignGetAllPaginate = '[Campaign] Campaign Paginate Get All',
  CampaignGetAllPaginateSuccess = '[Campaign] Campaign Paginate Get All Success',
  CampaignGetAllPaginateError = '[Campaign] Campaign Paginate Get All Error',
  CancelCampaignLaunch = '[Campaign] Cancel Campaign Launch',
  CancelCampaignLaunchSuccess = '[Campaign] Cancel Campaign Launch Success',
  CancelCampaignLaunchError = '[Campaign] Cancel Campaign Launch Error',
  CampaignLaunchCode = '[Campaign] Campaign Launch Code',
  CampaignLaunchCodeSuccess = '[Campaign] Campaign Launch Code Success',
  CampaignLaunchCodeError = '[Campaign] Campaign Launch Code Error',
  CampaignValidation = '[Campaign] Campaign Validation',
  CampaignValidationSuccess = '[Campaign] Campaign Validation Success',
  CampaignValidationError = '[Campaign] Campaign Validation Error',
  ResetCampaignState = '[Campaign] Reset Campaign State',
}

export const CampaignGet = createAction(ECampaignActions.CampaignGet, (campaignId: any, params: any = {}) => ({campaignId, params}));
export const CampaignGetSuccess = createAction(ECampaignActions.CampaignGetSuccess, props<{ campaign: Campaign }>());
export const CampaignGetError = createAction(ECampaignActions.CampaignGetError, props<{ error: string }>());

export const CampaignUpdate = createAction(ECampaignActions.CampaignUpdate, props<{ campaign: any, campaignId: number }>());
export const CampaignUpdateSuccess = createAction(ECampaignActions.CampaignUpdateSuccess, props<{ campaign: any }>());
export const CampaignUpdateError = createAction(ECampaignActions.CampaignUpdateError, props<{ error: string }>());

export const CampaignCreate = createAction(ECampaignActions.CampaignCreate, props<{ campaign: any }>());
export const CampaignCreateSuccess = createAction(ECampaignActions.CampaignCreateSuccess, props<{ campaign: any }>());
export const CampaignCreateError = createAction(ECampaignActions.CampaignCreateError, props<{ error: string }>());

export const CampaignGetAll = createAction(ECampaignActions.CampaignGetAll, props<{ filter: any }>());
export const CampaignGetAllSuccess = createAction(ECampaignActions.CampaignGetAllSuccess, props<{ campaigns: Campaign[] }>());
export const CampaignGetAllError = createAction(ECampaignActions.CampaignGetAllError, props<{ error: string }>());

export const CampaignGetAllPaginate = createAction(ECampaignActions.CampaignGetAllPaginate, (params: any = {}) => params);
export const CampaignGetAllPaginateSuccess = createAction(ECampaignActions.CampaignGetAllPaginateSuccess, props<{ paginatedCampaigns: PaginatedCampaigns }>());
export const CampaignGetAllPaginateError = createAction(ECampaignActions.CampaignGetAllPaginateError, props<{ error: string }>());

export const CampaignLaunch = createAction(ECampaignActions.CampaignLaunch, (params: any = {}) => params);
export const CampaignLaunchSuccess = createAction(ECampaignActions.CampaignLaunchSuccess, props<{ campaignLaunch: Campaign }>());
export const CampaignLaunchError = createAction(ECampaignActions.CampaignLaunchError, props<{ error: string }>());

export const CampaignLaunchScheduled = createAction(ECampaignActions.CampaignLaunchScheduled, props<{ campaignId: number, schedule: string, timezone: string, code: string }>());
export const CampaignLaunchScheduledSuccess = createAction(ECampaignActions.CampaignLaunchScheduledSuccess, props<{ campaignLaunch: Campaign }>());
export const CampaignLaunchScheduledError = createAction(ECampaignActions.CampaignLaunchScheduledError, props<{ error: string }>());

export const CampaignDelete = createAction(ECampaignActions.CampaignDelete, props<{ campaignId: number }>());
export const CampaignDeleteSuccess = createAction(ECampaignActions.CampaignDeleteSuccess);
export const CampaignDeleteError = createAction(ECampaignActions.CampaignDeleteError, props<{ error: string }>());

export const CampaignCopy = createAction(ECampaignActions.CampaignCopy, props<{ campaignId: number, body: any }>());
export const CampaignCopySuccess = createAction(ECampaignActions.CampaignCopySuccess);
export const CampaignCopyError = createAction(ECampaignActions.CampaignCopyError, props<{ error: string }>());

export const CancelCampaignLaunch = createAction(ECampaignActions.CancelCampaignLaunch, props<{ campaignId: number }>());
export const CancelCampaignLaunchSuccess = createAction(ECampaignActions.CampaignCopySuccess, props<{campaign: any}>());
export const CancelCampaignLaunchError = createAction(ECampaignActions.CampaignCopyError, props<{ error: string }>());

export const CampaignLaunchCode = createAction(ECampaignActions.CampaignLaunchCode, props<{ campaignId: number }>());
export const CampaignLaunchCodeSuccess = createAction(ECampaignActions.CampaignLaunchCodeSuccess, props<{campaignLaunchCode: any}>());
export const CampaignLaunchCodeError = createAction(ECampaignActions.CampaignLaunchCodeError, props<{ error: string }>());

export const CampaignValidation = createAction(ECampaignActions.CampaignValidation, (params: any = {}) => params);
export const CampaignValidationSuccess = createAction(ECampaignActions.CampaignValidationSuccess, props<{campaignValidation: any}>());
export const CampaignValidationError = createAction(ECampaignActions.CampaignValidationError, props<{ error: string }>());

export const ResetCampaignState = createAction(ECampaignActions.ResetCampaignState, (params: any = {}) => params);

