<div class="usualBody">
  <div class="container-fluid pt-3 mobile-p0">
    <div class="row">
      <div class="col-md-12 px-2 mb-4">
        <div class="header">
          <div class="d-flex align-items-end justify-content-between header-title">
            <div>
              <h2 class="mb-0 title">{{report?.name}} SNÖBOARD</h2>
              <p style="color: black" *ngIf="isAdmin">From {{projectStartDate}} To {{projectEndDate}}</p>
              <p>Keep on eye on your campaign's performance and start new ones.</p>
            </div>
            <div>
              <button class="btn-border mx-md-2" *ngIf="isAdmin" (click)="trackingAccuracy()">
                <i class="fa fa-tags fa-rotate-90" aria-hidden="true"></i>
                Conversion Tracking Accuracy
              </button>
              <button class="btn-border" (click)="shareReport()">
                <i class="fa fa-link fa-rotate-90" aria-hidden="true"></i>
                Share Report
              </button>
            </div>
          </div>
          <div *ngIf="report?.lastSyncTime">
            Last Sync {{getLastSyncFormattedDate(report?.lastSyncTime)}}
          </div>

          <!--          <div class="client-dropdown mr-1">-->
          <!--            <app-creatable-select-->
          <!--              [selectedValue]="mode"-->
          <!--              [hideLabel]="true" [onlyBottomBorder]="false" [isCreatable]="false"-->
          <!--              [placeholder]="'Select a Data source'" [selectOptions]="modes"-->
          <!--              (optionSelected)="modeSelected($event)"></app-creatable-select>-->
          <!--          </div>-->
          <!--          <div *ngIf="false" class="client-dropdown">-->
          <!--            <app-creatable-select-->
          <!--              [selectedValue]="selectedSegment"-->
          <!--              [hideLabel]="true" [onlyBottomBorder]="false" [isCreatable]="false"-->
          <!--              [placeholder]="'Filter by segment'" [selectOptions]="segments"-->
          <!--              (optionSelected)="segmentSelected($event)"></app-creatable-select>-->
          <!--          </div>-->
        </div>
        <div class="select-date-campaign d-flex align-items-start justify-content-between flex-wrap pt-2">
          <div class="form-group choose-capaign mb-0">
            <label>Choose a campaign</label>
            <div class="client-dropdown mr-1">
              <app-creatable-select
                      class="form-select"
                      [selectedValue]="campaignId"
                      [hideLabel]="true" [onlyBottomBorder]="false" [isCreatable]="false"
                      [placeholder]="'Select a Campaign'" [selectOptions]="campaigns"
                      (optionSelected)="campaignSelected($event)"></app-creatable-select>
            </div>
          </div>
          <div class="form-group date-range mb-0">
            <label>
              Date Range
            </label>
            <div class="d-flex align-items-center ">
              <div class="client-dropdown mr-1 has-floating-clear-btn">
                <input #startDateElement class="form-control" readonly bsDatepicker
                       (bsValueChange)="setDate($event, 'startDate')"
                       [bsValue]="getDate(startDate)"
                       [bsConfig]="{ isAnimated: true, adaptivePosition: true }"
                       placeholder="Select Start Date" style="height: 37px;background-color: #fff;"/>

                <span class="ng-clear-wrapper" title="Clear all" (click)="setDate(null, 'startDate', true)"
                      *ngIf="startDate"><span aria-hidden="true" class="ng-clear">×</span></span>
              </div>
              <div class="client-dropdown has-floating-clear-btn">
                <input #endDateElement class="form-control" readonly bsDatepicker
                       (bsValueChange)="setDate($event, 'endDate')"
                       [bsValue]="getDate(endDate)"
                       [bsConfig]="{ isAnimated: true, adaptivePosition: true }"
                       placeholder="Select End Date" style="height: 37px;background-color: #fff;"/>
                <span class="ng-clear-wrapper" title="Clear all" (click)="setDate(null, 'endDate', true)"
                      *ngIf="endDate"><span aria-hidden="true" class="ng-clear">×</span></span>
              </div>
<!--              <input type="text" placeholder="Start Date" bsDatepicker class="form-control mr-2">-->
<!--              <input type="text" placeholder="End Date" bsDatepicker class="form-control">-->
            </div>
            <div class="chose-data-source mt-3">
              <div class="d-flex align-items-center justify-content-end">
                <p class="mb-0 mr-2">
                  Choose Data Source
                </p>
                <div class="action-check-box d-flex align-items-center">
                  <input type="checkbox" class="styled-checkbox" [checked]="mode === 'live'" (click)="modeSelected({value: mode === 'live' ? 'test' : 'live'})">
                  <label class="ml-2">Live</label>
                </div>
                <div class="action-check-box d-flex align-items-center ml-2">
                  <input type="checkbox" class="styled-checkbox" [checked]="mode === 'test'" (click)="modeSelected({value: mode === 'test' ? 'live' : 'test'})">
                  <label class="ml-2">Test</label>
                  <app-info-popover info="During the campaign creation, all state are collected as test data to not be mixed with real data"></app-info-popover>
                </div>
              </div>
            </div>
          </div>

        </div>
      </div>
      <div class="col-md-12 px-2 mb-4">
        <div class="filter d-flex align-items-center flex-wrap">
          <h3>
            Filter By Segments:
          </h3>
          <div class="btn-action cursor-pointer"
               [ngClass]="{'selected-btn-action': selectedChk['all']}"
               (click)="changeChk('all', {target: {checked: !selectedChk['all']}})">
            All
          </div>
          <div class="btn-action cursor-pointer"
               [ngClass]="{'selected-btn-action': selectedChk[item.id]}" *ngFor="let item of (this.segments || [])"
               (click)="changeChk(item.id, {target: {checked: !selectedChk[item.id]}})">
            {{item.name}}
          </div>
        </div>
        <div class="filter d-flex align-items-center flex-wrap" style='border-top: none;'>
          <h3>
            Filter By Influencer Type:
          </h3>
          <div class="btn-action cursor-pointer"
               [ngClass]="{'selected-btn-action': selectedInfluencerTypes['all']}"
               (click)="changeInfluencerType('all', {target: {checked: !selectedInfluencerTypes['all']}})">
            All
          </div>
          <div class="btn-action cursor-pointer"
               [ngClass]="{'selected-btn-action': selectedInfluencerTypes[item.id]}" *ngFor="let item of (this.influencerTypes || [])"
               (click)="changeInfluencerType(item.id, {target: {checked: !selectedInfluencerTypes[item.id]}})">
            {{item.name}}
          </div>
        </div>
      </div>
      <div class="col-md-12 px-2">
        <div class="data-header">
          <div class="row justify-content-center mx-n2">
            <div class="col-lg col-sm-4 col-6 px-1">
              <div class="card card-box data-details mb-3 px-1 py-3">
                <div class="icon">
                  <i class="fa fa-eye"></i>
                </div>
                <div class="title">Advocates</div>
                <div class="count">{{reportTotals?.influencers}}</div>
              </div>
            </div>
            <div class="col-lg col-sm-4 col-6 px-1">
              <div class="card card-box data-details mb-3 px-1 py-3">
                <div class="icon">
                  <i class="fa fa-inbox"></i>
                </div>
                <div class="title">Email Engagement</div>
                <div class="count simple">{{reportTotals?.emailsOpenRate.opened}}</div>
                <div class="count hover" title="Percentage of people who opened the sent emails">{{reportTotals?.emailsOpenRate.opened}}/<span class="font-percent">{{getEmailOpenRatePercentage(reportTotals)}}%</span></div>
              </div>
            </div>
            <div class="col-lg col-sm-4 col-6 px-1">
              <div class="card card-box data-details mb-3 px-1 py-3">
                <div class="icon">
                  <i class="fa fa-share-alt"></i>
                </div>
                <div class="title">Social Shares</div>
                <div class="count">{{reportTotals?.socialShares}}</div>
              </div>
            </div>
            <div class="col-lg col-sm-4 col-6 px-1" [ngClass]="{'d-none': (reportTotals && (!reportTotals.socialShares || reportTotals?.socialShares <= 0))}">
              <div class="card card-box data-details mb-3 px-1 py-3">
                <div class="icon">
                  <i class="fa fa-eye"></i>
                </div>
                <div class="title">Landing Page Views</div>
                <div class="count">{{reportTotals?.pageViews}}</div>
              </div>
            </div>
            <div class="col-lg col-sm-4 col-6 px-1">
              <div class="card card-box data-details mb-3 px-1 py-3">
                <div class="icon">
                  <img src='./assets/Icons/referral-report.svg' height='15px' width='15px' alt='' style='margin-bottom: 13px'>
                </div>
                <div class="title">Referral Call to Action</div>
                <div class="count simple">{{reportTotals?.registrationClicks}}</div>
                <div class="count hover">{{reportTotals?.registrationClicks}}/<span class="font-percent">{{getCTARatePercentage(reportTotals)}}%</span></div>
              </div>
            </div>
            <div class="col-lg col-sm-4 col-6 px-1">
              <div class="card card-box data-details mb-3 px-1 py-3">
                <div class="icon simple">
                  <img src='./assets/Icons/conversion-report.svg' alt='' height='21px' width='21px' style='margin-bottom: 7px'>
                </div>
                <div class="title simple">Conversions</div>
                <div class="icon hover">
                  <i class="fas fa-hand-holding-usd"></i>
                </div>
                <div class="count simple">{{reportTotals?.referrals?.all}}</div>
                <span class="count simple font-percent">{{getReferralsRatePercentage(reportTotals)}}%</span>
                <div class="title hover">ROI</div>
                <div class="count hover">${{reportTotals?.registerAveragePrice >= 0 ? reportTotals?.registerAveragePrice * reportTotals?.referrals?.all : 0}}</div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="col-xl-12 mt-4 px-2" [hidden]="!referrals || !referrals?.length">
        <div class="card card-box snoball-effect">
          <div class="card-header d-flex align-items-center justify-content-between bg-white">
            <div class="effect-title">
              <i class="fa fa-snowflake-o" style="margin-right: 0.5rem;"></i>
              Snöball Effect
            </div>
            <div class="card-header--actions" id="filter_buttons">
              <button (click)="zoomIn()"
                      class="btn btn-sm mr-1 btn btn-effect">
                <i class="fa fa-search-plus"></i>
              </button>
              <button (click)="zoomOut()"
                      class="btn btn-sm mr-1 btn btn-effect">
                <i class="fa fa-search-minus"></i>
              </button>
            </div>
          </div>
          <div class="card-body" id="connectionHolder">
            <div id="mynetwork"></div>
            <div *ngIf="!referrals || referrals.length === 0" id="noData">
              <div class="no-data-msg">No Data Available</div>
            </div>
            <div id="loadingBar">
              <div class="outerBorder">
                <div id="text">0%</div>
                <div id="border">
                  <div id="bar"></div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="px-1" [ngClass]="{'col-md-12': showAllColumns, 'col-lg-6': !showAllColumns}" id="influencer-list">
        <div class="card card-box advocates mt-2">
<!--          <div class="card-header d-flex align-items-center justify-content-between referral-id-card-header" [ngClass]="{'has-referral-id': !!referralIds}">-->
          <div class="card-header d-flex flex-wrap align-items-center justify-content-between referral-id-card-header">
            <div class="card-header--title">
              <div class="advocates-title">
                <i class="fa fa-users" style="margin-right: 0.5rem; font-size: 14px"></i>
                <span *ngIf="!selectedFromList">Advocates</span>
                <span *ngIf="selectedFromList">{{selectedFromList.name}}</span>
                <span class="cursor-pointer" *ngIf="selectedFromList" style="color: #00A8EC;font-size: 12px;margin-left: 10px;" (click)="showAllInfluencers();">{{'< ' + 'Back'}}</span>
              </div>
              <!--<div style="cursor: pointer;" (click)="showAllInfluencers()" *ngIf="referralIds">
                <div>
                  <i class="fa fa-arrow-circle-o-left"
                     style="font-size: 2rem;margin-right: 0.5rem; vertical-align: bottom;"
                     *ngIf="referralIds"></i>
                  <span *ngIf="referralIds"><span
                          class="lighter-text">Referrals by</span> {{referralBy || ''}}</span>
                </div>
                <div class="font-size-xs lighter-text" style="padding-left: 37px; margin-top: -5px;">
                  click to clear influencer
                </div>
              </div>-->
            </div>

          </div>
          <div class="card-body p-2">
<!--            <div class="search mb-2">-->
<!--              <div class="form-group mb-0 col-md-6 p-0 ml-auto">-->
<!--                <input type="text" class="form-control" placeholder="Search name, email, company, etc">-->
<!--                <i class="fa fa-search"></i>-->
<!--              </div>-->
<!--            </div>-->
            <div class="advocates-content d-flex align-items-center justify-content-end">
<!--              <p class="mb-0 w-75">-->
<!--                Click on each influencer name to see referral and additional info as well as see them in the Snoball Effect graph-->
<!--              </p>-->
              <div class="advocates-check-box d-flex align-items-center">
                <div class="form-group d-flex align-items-center mb-0">
                  <input type='checkbox' [checked]="showAllColumns" (change)="showColumns($event)">
                  <label>Advanced</label>
                </div>
                <div class="ml-4" >
                  <img class="cursor-pointer ml-3 mr-1 w-25" mat-icon-button [matMenuTriggerFor]="exportMenu"
                    src="../../assets/images/import-normal.png"> <label>Export</label>
                  <mat-menu #exportMenu="matMenu" class="campaign-popover">
                    <a class="dropdown-item" (click)="exportAdvocatesData('csv')">
                      <i class="fa fa-file-o mr-2"></i>CSV</a>
                    <a class="dropdown-item" (click)="exportAdvocatesData('xlsx')">
                      <i class="fa fa-file-excel-o mr-2"></i>Excel (xlsx)</a>
                  </mat-menu>
                </div>
              </div>
            </div>
            <div *ngIf="selectedFromList" class="row">
              <div class="col-sm-12">
                <div class="report-table-details">
                  <div class="d-flex p-0">
                    <h3 class="mb-0">
                      Details
                    </h3>
                    <span class="ng-clear-wrapper close-icon cursor-pointer" title="Close" (click)="showAllInfluencers(); selectedFromList = null"><span aria-hidden="true" class="ng-clear">×</span></span>
                  </div>
                  <div class="d-flex"><h6>Segment</h6><p>{{selectedFromList?.segment}}</p></div>
                  <div class="d-flex"><h6>Email</h6><p>{{selectedFromList?.email}}</p></div>
                  <div class="d-flex"><h6>Added</h6><p>{{_moment(selectedFromList?.created_at).format('YYYY/MM/DD HH:mm')}}</p></div>
                  <div class="d-flex"><h6>Source</h6><p>{{selectedFromList?.source}}</p></div>
                  <div class="d-flex"><h6>Referral Tracker</h6><p *ngIf="isAdmin">{{selectedFromList?.referredThroughTrackerSource}}</p></div>
                  <div class="d-flex"><h6>Referred By</h6><p *ngIf="selectedFromList?.influencer?.referred">{{selectedFromList?.influencer.referred.name}}{{selectedFromList?.referredByMedia ? (' via ' + selectedFromList?.referredByMedia) : ''}}</p></div>
                </div>
              </div>
            </div>
            <div class="row advocate-table" [hidden]='isTableHide'>
              <div class="col-sm-12">
                <div class="custom-table mt-2" [ngClass]="{'open-selected': !!selectedFromList && !showAllColumns}">
                  <table width="100%" class="sb-table table dataTable no-footer nowrap" datatable
                         [dtTrigger]="dtTrigger"
                         [dtOptions]="dtOptions" id="dt-table">
                    <thead>
                    <tr>
                      <th><span>Name</span></th>
                      <th><span>Conversion</span></th>
                      <th><span>Landing Page Views</span></th>
                      <th><span>CTA Clicks</span></th>
                      <th><span>Social Shares</span></th>
                      <th [ngClass]="{'d-none': !showAllColumns}"><span>Company</span></th>
                      <th [ngClass]="{'d-none': !showAllColumns}"><span>Segment</span></th>
                      <th [ngClass]="{'d-none': !showAllColumns || !(!influencerTypeId || (influencerTypeId && influencerTypesById[influencerTypeId].updated_at))}"><span>Email Opened</span></th>
                      <th [ngClass]="{'d-none': !showAllColumns || !(!influencerTypeId || (influencerTypeId && influencerTypesById[influencerTypeId].updated_at))}"><span>Email Clicked</span></th>

                      <th [ngClass]="{'d-none': !showAllColumns || !(!influencerTypeId || (influencerTypeId && influencerTypesById[influencerTypeId].updated_at))}"><span>Linkedin Share</span></th>
                      <th [ngClass]="{'d-none': !showAllColumns || !(!influencerTypeId || (influencerTypeId && influencerTypesById[influencerTypeId].updated_at))}"><span>Twitter Share</span></th>
                      <th [ngClass]="{'d-none': !showAllColumns || !(!influencerTypeId || (influencerTypeId && influencerTypesById[influencerTypeId].updated_at))}"><span>Email Share</span></th>

                      <th [ngClass]="{'d-none': !showAllColumns || !(!influencerTypeId || (influencerTypeId && influencerTypesById[influencerTypeId].updated_at))}"><span>Facebook Share</span></th>
                      <th [ngClass]="{'d-none': !showAllColumns || !(!influencerTypeId || (influencerTypeId && influencerTypesById[influencerTypeId].updated_at))}"><span>Facebook Messenger Share</span></th>
                      <th [ngClass]="{'d-none': !showAllColumns || !(!influencerTypeId || (influencerTypeId && influencerTypesById[influencerTypeId].updated_at))}"><span>Instagram Share</span></th>

                      <th [ngClass]="{'d-none': !showAllColumns || !(!influencerTypeId || (influencerTypeId && influencerTypesById[influencerTypeId].updated_at))}"><span>Slack Share</span></th>
                      <th [ngClass]="{'d-none': !showAllColumns || !(!influencerTypeId || (influencerTypeId && influencerTypesById[influencerTypeId].updated_at))}"><span>Whatsapp Share</span></th>
                      <th [ngClass]="{'d-none': !showAllColumns || !(!influencerTypeId || (influencerTypeId && influencerTypesById[influencerTypeId].updated_at))}"><span>Line Share</span></th>

                      <th [ngClass]="{'d-none': !showAllColumns || !(!influencerTypeId || (influencerTypeId && influencerTypesById[influencerTypeId].updated_at))}"><span>Microsoft_teams Share</span></th>
                      <th [ngClass]="{'d-none': !showAllColumns || !(!influencerTypeId || (influencerTypeId && influencerTypesById[influencerTypeId].updated_at))}"><span>SMS Share</span></th>
                      <th [ngClass]="{'d-none': !showAllColumns || !(!influencerTypeId || (influencerTypeId && influencerTypesById[influencerTypeId].updated_at))}"><span>Zalo Share</span></th>

                      <th [ngClass]="{'d-none': !showAllColumns || !(!influencerTypeId || (influencerTypeId && influencerTypesById[influencerTypeId].source))}"><span>Source</span></th>
                      <th [ngClass]="{'d-none': !showAllColumns || !(!influencerTypeId || (influencerTypeId && influencerTypesById[influencerTypeId].updated_at))}"><span>Referral Tracker</span></th>
                      <!--                              <th>Insert Time</th>-->
                      <th [ngClass]="{'d-none': !showAllColumns || !(!influencerTypeId || (influencerTypeId && influencerTypesById[influencerTypeId].created_at))}"><span>Create Time</span></th>
                      <th [ngClass]="{'d-none': !showAllColumns || !(!influencerTypeId || (influencerTypeId && influencerTypesById[influencerTypeId].updated_at))}"><span>Update Time</span></th>
                      <th [ngClass]="{'d-none': !showAllColumns || !(!influencerTypeId || (influencerTypeId && influencerTypesById[influencerTypeId].typeName))}"><span>Influencer Type</span></th>
                      <th [ngClass]="{'d-none': !showAllColumns || !(!influencerTypeId || (influencerTypeId && influencerTypesById[influencerTypeId].email))}"><span>Email</span></th>
                      <th [ngClass]="{'d-none': !showAllColumns || !(!influencerTypeId || (influencerTypeId && influencerTypesById[influencerTypeId].booth_number))}"><span>Booth Number</span></th>
                      <!--<th-->
                      <!--  [ngClass]="{'d-none': !showAllColumns || !(!influencerTypeId || (influencerTypeId && influencerTypesById[influencerTypeId].company))}">-->
                      <!--  Company-->
                      <!--</th>-->
                      <th [ngClass]="{'d-none': !showAllColumns || !(!influencerTypeId || (influencerTypeId && influencerTypesById[influencerTypeId].first_name))}"><span>First Name</span></th>
                      <th [ngClass]="{'d-none': !showAllColumns || !(!influencerTypeId || (influencerTypeId && influencerTypesById[influencerTypeId].last_name))}"><span>Last Name</span></th>
                      <th [ngClass]="{'d-none': !showAllColumns || !(!influencerTypeId || (influencerTypeId && influencerTypesById[influencerTypeId].job_title))}"><span>Job Title</span></th>
                      <th [ngClass]="{'d-none': !showAllColumns || !(!influencerTypeId || (influencerTypeId && influencerTypesById[influencerTypeId].phone))}"><span>Phone</span></th>
                      <th [ngClass]="{'d-none': !showAllColumns || !(!influencerTypeId || (influencerTypeId && influencerTypesById[influencerTypeId].registration_url))}"><span>Registration url</span></th>
                      <th [ngClass]="{'d-none': !showAllColumns || !(!influencerTypeId || (influencerTypeId && influencerTypesById[influencerTypeId].sessions))}"><span>Sessions</span></th>
                      <th [ngClass]="{'d-none': !showAllColumns || !(!influencerTypeId || (influencerTypeId && influencerTypesById[influencerTypeId].slug))}"><span>Slug</span></th>
                      <th [ngClass]="{'d-none': !showAllColumns || !(!influencerTypeId || (influencerTypeId && influencerTypesById[influencerTypeId].video_url))}"><span>Video url</span></th>
                      <th [ngClass]="{'d-none': !showAllColumns || !(!influencerTypeId || (influencerTypeId && influencerTypesById[influencerTypeId].custom_1))}"><span>Custom 1</span></th>
                      <th [ngClass]="{'d-none': !showAllColumns || !(!influencerTypeId || (influencerTypeId && influencerTypesById[influencerTypeId].custom_2))}"><span>Custom 2</span></th>
                      <th [ngClass]="{'d-none': !showAllColumns || !(!influencerTypeId || (influencerTypeId && influencerTypesById[influencerTypeId].custom_3))}"><span>Custom 3</span></th>
                      <th [ngClass]="{'d-none': !showAllColumns || !(!influencerTypeId || (influencerTypeId && influencerTypesById[influencerTypeId].custom_4))}"><span>Custom 4</span></th>
                      <th [ngClass]="{'d-none': !showAllColumns || !(!influencerTypeId || (influencerTypeId && influencerTypesById[influencerTypeId].custom_5))}"><span>Custom 5</span></th>
                      <th [ngClass]="{'d-none': !showAllColumns || !(!influencerTypeId || (influencerTypeId && influencerTypesById[influencerTypeId].custom_6))}"><span>Custom 6</span></th>
                      <th [ngClass]="{'d-none': !showAllColumns || !(!influencerTypeId || (influencerTypeId && influencerTypesById[influencerTypeId].custom_7))}"><span>Custom 7</span></th>
                      <th [ngClass]="{'d-none': !showAllColumns || !(!influencerTypeId || (influencerTypeId && influencerTypesById[influencerTypeId].custom_8))}"><span>Custom 8</span></th>
                      <th [ngClass]="{'d-none': !showAllColumns || !(!influencerTypeId || (influencerTypeId && influencerTypesById[influencerTypeId].custom_9))}"><span>Custom 9</span></th>
                      <th [ngClass]="{'d-none': !showAllColumns || !(!influencerTypeId || (influencerTypeId && influencerTypesById[influencerTypeId].custom_10))}"><span>Custom 10</span></th>
                    </tr>
                    </thead>
                    <tbody *ngIf="influencersList.length > 0">
                    <tr *ngFor="let influencer of influencersList" (click)="showData(influencer);selectedFromList = influencer;hideTableState()">
                      <td>
                        <p class="name m-0">{{influencer.name}}</p>
                        {{influencer?.company}}
                      </td>
                      <td>
                        <ng-container *ngIf="influencer.referredCount !== 0">
<!--                          <a class="text-decoration: underline !important;" href="javascript:void(0)" (click)="showData(influencer)">{{influencer.referredCount}}</a>-->
                          <a class="text-decoration: underline !important;" href="javascript:void(0)">{{influencer.referredCount}}</a>
                        </ng-container>
                        <ng-container *ngIf="!influencer.referredCount || influencer.referredCount === 0">{{influencer.referredCount}}</ng-container>
                      </td>
                      <td>{{influencer.pageViews}}</td>
                      <td>{{influencer.registrationClicks}}</td>
                      <td>{{influencer.socialShare}}</td>
                      <td [ngClass]="{'d-none': !showAllColumns}">{{influencer.company}}</td>
                      <td [ngClass]="{'d-none': !showAllColumns}">{{influencer.segment}}</td>
                      <td [ngClass]="{'d-none': !showAllColumns || !(!influencerTypeId || (influencerTypeId && influencerTypesById[influencerTypeId].updated_at))}">{{influencer.emailOpenStatus > 0 || influencer.emailClickStatus > 0 ? 'Yes' : 'No'}}</td>
                      <td [ngClass]="{'d-none': !showAllColumns || !(!influencerTypeId || (influencerTypeId && influencerTypesById[influencerTypeId].updated_at))}">{{influencer.emailClickStatus > 0 ? 'Yes' : 'No'}}</td>

                      <td [ngClass]="{'d-none': !showAllColumns || !(!influencerTypeId || (influencerTypeId && influencerTypesById[influencerTypeId].updated_at))}">{{getSocialCount(influencer.id , 'linkedin')}}</td>
                      <td [ngClass]="{'d-none': !showAllColumns || !(!influencerTypeId || (influencerTypeId && influencerTypesById[influencerTypeId].updated_at))}">{{getSocialCount(influencer.id , 'twitter')}}</td>
                      <td [ngClass]="{'d-none': !showAllColumns || !(!influencerTypeId || (influencerTypeId && influencerTypesById[influencerTypeId].updated_at))}">{{getSocialCount(influencer.id , 'email')}}</td>

                      <td [ngClass]="{'d-none': !showAllColumns || !(!influencerTypeId || (influencerTypeId && influencerTypesById[influencerTypeId].updated_at))}">{{getSocialCount(influencer.id , 'facebook')}}</td>
                      <td [ngClass]="{'d-none': !showAllColumns || !(!influencerTypeId || (influencerTypeId && influencerTypesById[influencerTypeId].updated_at))}">{{getSocialCount(influencer.id , 'facebook_messenger')}}</td>
                      <td [ngClass]="{'d-none': !showAllColumns || !(!influencerTypeId || (influencerTypeId && influencerTypesById[influencerTypeId].updated_at))}">{{getSocialCount(influencer.id , 'instagram')}}</td>

                      <td [ngClass]="{'d-none': !showAllColumns || !(!influencerTypeId || (influencerTypeId && influencerTypesById[influencerTypeId].updated_at))}">{{getSocialCount(influencer.id , 'slack')}}</td>
                      <td [ngClass]="{'d-none': !showAllColumns || !(!influencerTypeId || (influencerTypeId && influencerTypesById[influencerTypeId].updated_at))}">{{getSocialCount(influencer.id , 'whatsapp')}}</td>
                      <td [ngClass]="{'d-none': !showAllColumns || !(!influencerTypeId || (influencerTypeId && influencerTypesById[influencerTypeId].updated_at))}">{{getSocialCount(influencer.id , 'line')}}</td>

                      <td [ngClass]="{'d-none': !showAllColumns || !(!influencerTypeId || (influencerTypeId && influencerTypesById[influencerTypeId].updated_at))}">{{getSocialCount(influencer.id , 'microsoft_teams')}}</td>
                      <td [ngClass]="{'d-none': !showAllColumns || !(!influencerTypeId || (influencerTypeId && influencerTypesById[influencerTypeId].updated_at))}">{{getSocialCount(influencer.id , 'sms')}}</td>
                      <td [ngClass]="{'d-none': !showAllColumns || !(!influencerTypeId || (influencerTypeId && influencerTypesById[influencerTypeId].updated_at))}">{{getSocialCount(influencer.id , 'zalo')}}</td>

                      <td [ngClass]="{'d-none': !showAllColumns || !(!influencerTypeId || (influencerTypeId && influencerTypesById[influencerTypeId].updated_at))}">{{influencer.source || '-'}}</td>
                      <td [ngClass]="{'d-none': !showAllColumns || !(!influencerTypeId || (influencerTypeId && influencerTypesById[influencerTypeId].updated_at))}">{{influencer.referredThroughTrackerSource || '-'}}</td>
                      <!--<td>{{formatInsertTime(influencer.created_at)}}</td>-->
                      <!--<td [ngClass]="{'d-none': !showAllColumns || !(!influencerTypeId || (influencerTypeId && influencerTypesById[influencerTypeId].updated_at))}">{{formatInsertTime(influencer.updated_at)}}</td>-->
                      <td class='no-sort' [ngClass]="{'d-none': !showAllColumns || !(!influencerTypeId || (influencerTypeId && influencerTypesById[influencerTypeId].created_at))}">{{formatMoment(influencer.created_at) | tzDate : false : 'DD-MM-YYYY hh:mm a'}}</td>
                      <td class='no-sort' [ngClass]="{'d-none': !showAllColumns || !(!influencerTypeId || (influencerTypeId && influencerTypesById[influencerTypeId].updated_at))}">{{formatMoment(influencer.updated_at) | tzDate : false : 'DD-MM-YYYY hh:mm a'}}</td>
                      <td [ngClass]="{'d-none': !showAllColumns || !(!influencerTypeId || (influencerTypeId && influencerTypesById[influencerTypeId].typeName))}">{{influencer.typeName}}</td>
                      <td [ngClass]="{'d-none': !showAllColumns || !(!influencerTypeId || (influencerTypeId && influencerTypesById[influencerTypeId].email))}">{{influencer.email}}</td>
                      <td [ngClass]="{'d-none': !showAllColumns || !(!influencerTypeId || (influencerTypeId && influencerTypesById[influencerTypeId].booth_number))}">{{influencer.booth_number}}</td>
                      <!--<td-->
                      <!--  [ngClass]="{'d-none': !showAllColumns || !(!influencerTypeId || (influencerTypeId && influencerTypesById[influencerTypeId].company))}">{{influencer.company}}</td>-->
                      <td [ngClass]="{'d-none': !showAllColumns || !(!influencerTypeId || (influencerTypeId && influencerTypesById[influencerTypeId].first_name))}">{{influencer.first_name}}</td>
                      <td [ngClass]="{'d-none': !showAllColumns || !(!influencerTypeId || (influencerTypeId && influencerTypesById[influencerTypeId].last_name))}">{{influencer.last_name}}</td>
                      <td [ngClass]="{'d-none': !showAllColumns || !(!influencerTypeId || (influencerTypeId && influencerTypesById[influencerTypeId].job_title))}">{{influencer.job_title}}</td>
                      <td [ngClass]="{'d-none': !showAllColumns || !(!influencerTypeId || (influencerTypeId && influencerTypesById[influencerTypeId].phone))}">{{influencer.phone}}</td>
                      <td [ngClass]="{'d-none': !showAllColumns || !(!influencerTypeId || (influencerTypeId && influencerTypesById[influencerTypeId].registration_url))}">{{influencer.registration_url}}</td>
                      <td [ngClass]="{'d-none': !showAllColumns || !(!influencerTypeId || (influencerTypeId && influencerTypesById[influencerTypeId].sessions))}">{{influencer.sessions}}</td>
                      <td [ngClass]="{'d-none': !showAllColumns || !(!influencerTypeId || (influencerTypeId && influencerTypesById[influencerTypeId].slug))}">{{influencer.slug}}</td>
                      <td [ngClass]="{'d-none': !showAllColumns || !(!influencerTypeId || (influencerTypeId && influencerTypesById[influencerTypeId].video_url))}">{{influencer.video_url}}</td>
                      <td [ngClass]="{'d-none': !showAllColumns || !(!influencerTypeId || (influencerTypeId && influencerTypesById[influencerTypeId].custom_1))}">{{influencer.custom_1}}</td>
                      <td [ngClass]="{'d-none': !showAllColumns || !(!influencerTypeId || (influencerTypeId && influencerTypesById[influencerTypeId].custom_2))}">{{influencer.custom_2}}</td>
                      <td [ngClass]="{'d-none': !showAllColumns || !(!influencerTypeId || (influencerTypeId && influencerTypesById[influencerTypeId].custom_3))}">{{influencer.custom_3}}</td>
                      <td [ngClass]="{'d-none': !showAllColumns || !(!influencerTypeId || (influencerTypeId && influencerTypesById[influencerTypeId].custom_4))}">{{influencer.custom_4}}</td>
                      <td [ngClass]="{'d-none': !showAllColumns || !(!influencerTypeId || (influencerTypeId && influencerTypesById[influencerTypeId].custom_5))}">{{influencer.custom_5}}</td>
                      <td [ngClass]="{'d-none': !showAllColumns || !(!influencerTypeId || (influencerTypeId && influencerTypesById[influencerTypeId].custom_6))}">{{influencer.custom_6}}</td>
                      <td [ngClass]="{'d-none': !showAllColumns || !(!influencerTypeId || (influencerTypeId && influencerTypesById[influencerTypeId].custom_7))}">{{influencer.custom_7}}</td>
                      <td [ngClass]="{'d-none': !showAllColumns || !(!influencerTypeId || (influencerTypeId && influencerTypesById[influencerTypeId].custom_8))}">{{influencer.custom_8}}</td>
                      <td [ngClass]="{'d-none': !showAllColumns || !(!influencerTypeId || (influencerTypeId && influencerTypesById[influencerTypeId].custom_9))}">{{influencer.custom_9}}</td>
                      <td [ngClass]="{'d-none': !showAllColumns || !(!influencerTypeId || (influencerTypeId && influencerTypesById[influencerTypeId].custom_10))}">{{influencer.custom_10}}</td>
                    </tr>
                    </tbody>
                    <tbody *ngIf="influencersList.length <= 0">
                    <tr>
                      <td colspan="{{(showAllColumns ? (influencerTypesById ? _object.keys(influencerTypesById[influencerTypeId] || {}).length : 23) : 0) + 9}}">
                        No data found
                      </td>
                    </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="col-lg-6 px-1">
        <div class="card card-box advocates  mt-2" [ngClass]="{'height-unset': hideDiv.socialShare}">
          <div class="card-header d-flex align-items-center justify-content-between referral-id-card-header">
            <div class="card-header--title card-header-containers">
              <div class="advocates-title">
                <i class="fa fa-share-alt" style="margin-right: 0.5rem;"></i>
                <span>Social Shares</span>
              </div>
              <div class="advocates-title cursor-pointer" (click)="hideDiv.socialShare = !hideDiv.socialShare">
                <i class="fa fa-minus" style="margin-right: 0.5rem;" *ngIf='!hideDiv.socialShare'></i>
                <i class="fa fa-plus" style="margin-right: 0.5rem;" *ngIf='hideDiv.socialShare'></i>
              </div>
            </div>
          </div>
          <div class="card-body align-items-center p-2 test-{{hideDiv.socialShare}}" [ngClass]="{'d-flex': !hideDiv.socialShare}" [hidden]='hideDiv.socialShare'>
            <div class="col-12">
              <div class="social-share-chart legend-center">
                <div id="piechart">
                  <apx-chart
                          [chart]="piechart.chart"
                          [labels]="piechart.labels"
                          [dataLabels]="{
                              enable: true,
                              formatter: chartLabelFormatter,
                              textAnchor: 'middle',
                              style: {
                              fontSize: '9px',
                              fontFamily: 'Helvetica'
                              },
                              dropShadow: {
                              enabled: false
                              }
                          }"
                          [series]="piechart.series"
                          [colors]="piechart.colors"
                          [noData]="{text: 'No data available.'}"
                          [stroke]="{
                            width: 0
                          }"
                          [legend]="{
                            markers: {
                              width: 7,
                              height: 7,
                              offsetX: -10
                            },
                           fontWeight: 600,
                           fontSize: '12px'
                          }"
                          [responsive]="[
                            {
                              breakpoint: 1000,
                              options: {
                              legend: {
                                position: 'top',
                                 itemMargin: {
                                   horizontal: 10,
                                   vertical: 5
                                  },
                                  markers: {
                                  offsetX: -5
                                  }
                                },
                                 chart: {
                                  offsetY: 25
                                }
                              }
                            }
                          ]"
                  ></apx-chart>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="col-lg-6 px-1">
        <div class="card card-box advocates mt-2" [ngClass]="{'height-unset': hideDiv.conversions}">
          <div class="card-header d-flex align-items-center justify-content-between referral-id-card-header">
            <div class="card-header--title card-header-containers">
              <div class="advocates-title">
                <i class="fa fa-eye" style="margin-right: 0.5rem;"></i>
                <span>Conversions</span>
              </div>
              <div class="advocates-title cursor-pointer" (click)="hideDiv.conversions = !hideDiv.conversions">
                <i class="fa fa-minus" style="margin-right: 0.5rem;" *ngIf='!hideDiv.conversions'></i>
                <i class="fa fa-plus" style="margin-right: 0.5rem;" *ngIf='hideDiv.conversions'></i>
              </div>
            </div>
          </div>
          <div class="card-body d-flex align-items-center p-2" [ngClass]="{'d-flex': !hideDiv.conversions}" [hidden]='hideDiv.conversions'>
            <div class="card-body" *ngIf="reportTotals">
              <apx-chart
                [chart]="{type: 'line',height: '450'}"
                [dataLabels]="conversionsGraph.dataLabels"
                [xaxis]="conversionsGraph.xaxis"
                [yaxis]="conversionsGraph.yaxis"
                [tooltip]="conversionsGraph.tooltip"
                [series]="conversionsGraph.series"
                [noData]="{text: 'No data available.'}"
                [stroke]="{curve: 'smooth', show: true, width: 2}"
                [responsive]='[
                {
                  breakpoint: 1440,
                  options: {
                    plotOptions: {
                      bar: {

                      }
                    },
                    legend: {
                      position: "bottom",
                      itemMargin: {
                       horizontal: 10,
                       vertical: 10
                      }
                    }
                  }
                }
              ]'
              ></apx-chart>

            </div>
          </div>
        </div>
      </div>
      <div class="col-lg-6 px-1">
        <div class="card card-box advocates mt-2" [ngClass]="{'height-unset': hideDiv.emailEngagement}">
          <div class="card-header d-flex align-items-center justify-content-between referral-id-card-header">
            <div class="card-header--title card-header-containers">
              <div class="advocates-title">
                <i class="fa fa-inbox" style="margin-right: 0.5rem;"></i>
                <span>Email Engagement</span>
              </div>
              <div class="advocates-title cursor-pointer" (click)="hideDiv.emailEngagement = !hideDiv.emailEngagement">
                <i class="fa fa-minus" style="margin-right: 0.5rem;" *ngIf='!hideDiv.emailEngagement'></i>
                <i class="fa fa-plus" style="margin-right: 0.5rem;" *ngIf='hideDiv.emailEngagement'></i>
              </div>
            </div>
          </div>
          <div class="card-body align-items-center p-2" [ngClass]="{'d-flex': !hideDiv.emailEngagement}" [hidden]='hideDiv.emailEngagement'>
            <div class="col-12">
              <div class="social-share-chart legend-center">
                  <div id="radialBarChart">
                    <apx-chart
                            [chart]="{type: 'radialBar', height: '450' }"
                            [labels]="radialBar.labels"
                            [series]="radialBar.series"
                            [colors]="radialBar.colors"
                            [legend]="radialBar.legend"
                            [stroke]="radialBar.stroke"
                            [plotOptions]="radialBar.plotOptions"
                            [noData]="{text: 'No data available.'}"
                            [responsive]="radialBar.responsive"
                    ></apx-chart>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="col-12 px-2">
        <div class="card card-box advocates mt-2">
          <div class="card-header d-flex align-items-center bg-white">
            <div class="card-header--title card-header-containers">
              <div class="advocates-title">
                <i class="fa fa-eye" style="margin-right: 0.5rem;"></i>
                <span>Landing Page Views</span>
              </div>
              <div class="advocates-title cursor-pointer" (click)="hideDiv.landingPageViews = !hideDiv.landingPageViews">
                <i class="fa fa-minus" style="margin-right: 0.5rem;" *ngIf='!hideDiv.landingPageViews'></i>
                <i class="fa fa-plus" style="margin-right: 0.5rem;" *ngIf='hideDiv.landingPageViews'></i>
              </div>
            </div>
          </div>
          <div class="card-body h-auto" *ngIf="reportTotals" [hidden]='hideDiv.landingPageViews'>
            <apx-chart
                    [chart]="{type: 'line',height: '450'}"
                    [dataLabels]="{enabled: false}"
                    [xaxis]="{
                      type: 'category',
                      title: {text: 'Dates',style: {
                        cssClass: 'report-barchart-title'
                      }},
                      label: {show: true},
                      tickAmount: 15,
                      categories: dates,
                      axisTicks: {
                          show: true,
                          borderType: 'solid',
                          color: '#78909C',
                          height: 6,
                          offsetX: 0,
                          offsetY: 0
                        }
                      }"
                    [yaxis]="{
                        type: '',
                        show: true,
                        showAlways: true,
                        title: {
                          text: 'Page Views'
                        }
                      }"
                    [tooltip]="{enabled: true, shared: true}"
                    [series]="series"
                    [noData]="{text: 'No data available.'}"
                    [stroke]="{curve: 'smooth', show: true, width: 2}"
                    [responsive]='[
                    {
                      breakpoint: 1440,
                      options: {
                        plotOptions: {
                          bar: {

                          }
                        },
                        legend: {
                          position: "bottom",
                          itemMargin: {
                           horizontal: 10,
                           vertical: 10
                          }
                        }
                      }
                    }
                  ]'
            ></apx-chart>

          </div>
        </div>
      </div>
      <div class="col-xl-12 px-2" *ngIf="false">
        <div class="card card-box advocates mt-2">
          <div class="card-header d-flex align-items-center bg-white">
            <div class="card-header--title">
              <div class="advocates-title">
                <i class="fa fa-share-alt" style="margin-right: 0.5rem;"></i>
                <span>Some Social Shares</span>
              </div>
            </div>
          </div>
          <div class="card-body">
            <div class="advocates-check-box d-flex align-items-center">
              <div class="form-group d-flex align-items-center m-0">
                <input type='checkbox'>
                <label>All</label>
              </div>
              <div class="form-group d-flex align-items-center mb-0">
                <input type='checkbox'>
                <label>Twitter</label>
              </div>
              <div class="form-group d-flex align-items-center mb-0">
                <input type='checkbox'>
                <label>facebook</label>
              </div>
            </div>
            <div class="img-wrapper social-share-holder">
              <img src="./assets/images/dummy-social-shares.png" class="img-fluid"/>
            </div>
          </div>
        </div>
      </div>
      <div class="col-xl-12 px-2" [hidden]="!selectedProject?.report_analysis">
        <div class="card card-box advocates mt-2">
          <div class="card-header d-flex align-items-center bg-white">
            <div class="card-header--title card-header-containers">
              <div class="advocates-title">
                <i class="fa fa-file" style="margin-right: 0.5rem;"></i>
                <span>Summary/Recommendations</span>
              </div>
              <div class="advocates-title cursor-pointer" (click)="hideDiv.summary = !hideDiv.summary">
                <i class="fa fa-minus" style="margin-right: 0.5rem;" *ngIf='!hideDiv.summary'></i>
                <i class="fa fa-plus" style="margin-right: 0.5rem;" *ngIf='hideDiv.summary'></i>
              </div>
            </div>
          </div>
          <div class="card-body" [hidden]='hideDiv.summary'>
            <div class="summary-report">
              <iframe #iframe style="border: none; width: 100%; height: auto"></iframe>
<!--              <p>-->
<!--&lt;!&ndash;                This campaign has shown fair engagement.&ndash;&gt;-->
<!--                {{selectedProject?.report_analysis}}-->
<!--              </p>-->
              <a href="#" class="talk-btn" *ngIf='false'>
                <i class="fa fa-comment" aria-hidden="true"></i>
                Talk to us about this recommendation
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!--    <div class="row">-->

    <!--      <div class="col-12 pl-4 pr-4 mt-3">-->
    <!--        <div class="card card-box p-4 mb-5">-->
    <!--          <div *ngIf="false" class="card-header bg-white p-0" style="border-bottom: none">-->
    <!--            <div class="card-header&#45;&#45;title font-size-xxl d-50 mb-4">-->
    <!--              <i class="" style="margin-right: 0.5rem;"></i>-->
    <!--            </div>-->
    <!--            <div class="card-header&#45;&#45;actions mb-4">-->
    <!--              <a class="btn btn-sm mr-1 btn btn-primary"-->
    <!--                 [routerLink]="['/projects', projectId, 'campaigns', 'create']">Start a Campaign</a>-->
    <!--            </div>-->
    <!--          </div>-->
    <!--          <div class="card-body p-0">-->
    <!--            <div class="row">-->
    <!--              <div class="col-xl-12">-->
    <!--                <div class="card card-box mb-5">-->
    <!--                  <div class="card-header d-flex align-items-center bg-white">-->
    <!--                    <div class="card-header&#45;&#45;title font-size-xxl py-2">-->
    <!--                      <i class="fa fa-envelope-open"-->
    <!--                         style="margin-right: 0.5rem;"> </i>-->
    <!--                      Ask Email-->
    <!--                    </div>-->
    <!--                  </div>-->
    <!--                  <div class="card-body p-2">-->
    <!--                    <div class="row" *ngIf="reportTotals">-->
    <!--                      <div-->
    <!--                        class="col-xl-{{reportTotals.bySegments.length < 4 ? (12 / reportTotals.bySegments.length) : 3}}"-->
    <!--                        *ngFor="let segment of reportTotals.bySegments; let i = index">-->
    <!--                        <div-->
    <!--                          [style]="{borderBottomColor: typeColor[i]}"-->
    <!--                          class="card card-box card-box-border-bottom card-shadow-info mb-1">-->
    <!--                          <div class="d-flex justify-content-between align-items-center">-->
    <!--                            <div class="p-3">-->
    <!--                              <div class="text-uppercase font-weight-bold pb-2 font-size-sm">{{segment.name}}</div>-->
    <!--                              <h3 class="font-weight-bold display-5 mb-0 text-black">-->
    <!--                                <span>{{segment.total}}</span></h3>-->
    <!--                            </div>-->
    <!--                            <div class="pr-3">-->
    <!--                              <ngx-gauge append="%" aria-readonly="true"-->
    <!--                                         label="Open"-->
    <!--                                         backgroundColor="{{typeColor[i]}}15" cap="round"-->
    <!--                                         class="mt-1 img-fluid ngx-gauge-meter"-->
    <!--                                         foregroundColor="{{typeColor[i]}}" max="100"-->
    <!--                                         min="0" role="slider" size="120" thick="6" type="full"-->
    <!--                                         value="{{getEmailOpenRatePercentage(segment)}}" aria-valuemin="0"-->
    <!--                                         aria-valuemax="100" aria-valuenow="{{getEmailOpenRatePercentage(segment)}}"-->
    <!--                                         aria-label="" style="width: 120px; height: 120px;">-->
    <!--                                <div class="reading-block" style="font-size: 11px; line-height: 60px;"><u-->
    <!--                                  class="reading-affix">-->
    <!--                                </u>{{getEmailOpenRatePercentage(segment)}}<u class="reading-affix"></u></div>-->
    <!--                                <div class="reading-label" style="font-size: 4.61538px; line-height: 83.0769px;"></div>-->
    <!--                                <canvas width="60" height="60"></canvas>-->
    <!--                              </ngx-gauge>-->
    <!--                              <ngx-gauge *ngIf="getEmailClickRatePercentage(segment)" append="%" aria-readonly="true"-->
    <!--                                         label="Click"-->
    <!--                                         backgroundColor="{{typeColor[i]}}15" cap="round"-->
    <!--                                         class="mt-1 img-fluid ngx-gauge-meter"-->
    <!--                                         foregroundColor="{{typeColor[i]}}" max="100"-->
    <!--                                         min="0" role="slider" size="120" thick="6" type="full"-->
    <!--                                         value="{{getEmailClickRatePercentage(segment)}}" aria-valuemin="0"-->
    <!--                                         aria-valuemax="100" aria-valuenow="{{getEmailClickRatePercentage(segment)}}"-->
    <!--                                         aria-label="" style="width: 120px; height: 120px;">-->
    <!--                                <div class="reading-block" style="font-size: 11px; line-height: 60px;"><u-->
    <!--                                  class="reading-affix">-->
    <!--                                </u>{{getEmailOpenRatePercentage(segment)}}<u class="reading-affix"></u></div>-->
    <!--                                <div class="reading-label" style="font-size: 4.61538px; line-height: 83.0769px;"></div>-->
    <!--                                <canvas width="60" height="60"></canvas>-->
    <!--                              </ngx-gauge>-->
    <!--                            </div>-->
    <!--                          </div>-->
    <!--                        </div>-->
    <!--                      </div>-->
    <!--                      <div class="col-xl-12" *ngIf="reportTotals.bySegments.length <= 0">-->
    <!--                        <div class="card card-box mb-5 shadow-none">-->
    <!--                          <div class="d-flex justify-content-between align-items-center">-->
    <!--                            <div class="p-3">-->
    <!--                              <div class="text-uppercase font-weight-bold pb-2 font-size-sm">No Data found</div>-->
    <!--                            </div>-->
    <!--                          </div>-->
    <!--                        </div>-->
    <!--                      </div>-->
    <!--                    </div>-->
    <!--                  </div>-->
    <!--                </div>-->
    <!--              </div>-->
    <!--              <div class="col-xl-12">-->
    <!--                <div class="card card-box mb-5">-->
    <!--                  <div class="card-header d-flex align-items-center referral-id-card-header"-->
    <!--                       [ngClass]="{'has-referral-id': !!this.referralIds}">-->
    <!--                    <div class="card-header&#45;&#45;title font-size-xxl py-2">-->
    <!--                      <div *ngIf="!this.referralIds">-->
    <!--                        <i class="fa fa-users" style="margin-right: 0.5rem;"></i>-->
    <!--                        <span>Top Influencers</span>-->
    <!--                      </div>-->
    <!--                      <div style="cursor: pointer;" (click)="showAllInfluencers()" *ngIf="this.referralIds">-->
    <!--                        <div>-->
    <!--                          <i class="fa fa-arrow-circle-o-left"-->
    <!--                             style="font-size: 2rem;margin-right: 0.5rem; vertical-align: bottom;"-->
    <!--                             *ngIf="this.referralIds"></i>-->
    <!--                          <span *ngIf="this.referralIds"><span-->
    <!--                            class="lighter-text">Referrals by</span> {{referralBy || ''}}</span>-->
    <!--                        </div>-->
    <!--                        <div class="font-size-xs lighter-text" style="padding-left: 37px; margin-top: -5px;">-->
    <!--                          click to clear influencer-->
    <!--                        </div>-->
    <!--                      </div>-->
    <!--                    </div>-->
    <!--                  </div>-->
    <!--                  <div class="card-body p-2">-->
    <!--                    <div class="row">-->
    <!--                      <div class="col-sm-12">-->
    <!--                        <div-->
    <!--                          class='col-md-12 mb-3 mx-auto customize-email d-flex align-items-center justify-content-between'>-->
    <!--                          <div class="ml-auto d-flex align-items-center">-->
    <!--                            <div class="mr-2">-->
    <!--                              <input id="customize_columns" placeholder="" [checked]="showAllColumns"-->
    <!--                                     (change)="showColumns($event)" type="checkbox">-->
    <!--                              <label for="customize_columns">Display all columns</label>-->
    <!--                            </div>-->
    <!--                            <button class="btn btn-sm btn-default" (click)="exportData()">Export</button>-->
    <!--                          </div>-->
    <!--                        </div>-->
    <!--                        <div class="custom-table">-->
    <!--                          <table width="100%" class="sb-table table dataTable no-footer nowrap" datatable-->
    <!--                                 [dtTrigger]="dtTrigger"-->
    <!--                                 [dtOptions]="dtOptions" id="dt-table">-->
    <!--                            <thead>-->
    <!--                            <tr>-->
    <!--                              <th>Full Name</th>-->
    <!--                              <th>Company</th>-->
    <!--                              <th>Segment</th>-->
    <!--                              <th>Shares</th>-->
    <!--                              <th>Page Views</th>-->
    <!--                              <th>Registration Referrals</th>-->
    <!--                              <th>Reg.Links Click</th>-->
    <!--                              <th [ngClass]="{'d-none': !showAllColumns || !(!influencerTypeId || (influencerTypeId && influencerTypesById[influencerTypeId].updated_at))}">Email Opened</th>-->
    <!--                              <th [ngClass]="{'d-none': !showAllColumns || !(!influencerTypeId || (influencerTypeId && influencerTypesById[influencerTypeId].updated_at))}">Email Clicked</th>-->
    <!--                              <th [ngClass]="{'d-none': !showAllColumns || !(!influencerTypeId || (influencerTypeId && influencerTypesById[influencerTypeId].source))}">Source</th>-->
    <!--                              <th [ngClass]="{'d-none': !showAllColumns || !(!influencerTypeId || (influencerTypeId && influencerTypesById[influencerTypeId].updated_at))}">Referral Tracker</th>-->
    <!--&lt;!&ndash;                              <th>Insert Time</th>&ndash;&gt;-->
    <!--                              <th [ngClass]="{'d-none': !showAllColumns || !(!influencerTypeId || (influencerTypeId && influencerTypesById[influencerTypeId].updated_at))}">Update Time</th>-->
    <!--                              <th-->
    <!--                                [ngClass]="{'d-none': !showAllColumns || !(!influencerTypeId || (influencerTypeId && influencerTypesById[influencerTypeId].typeName))}">-->
    <!--                                Influencer Type-->
    <!--                              </th>-->
    <!--                              <th-->
    <!--                                [ngClass]="{'d-none': !showAllColumns || !(!influencerTypeId || (influencerTypeId && influencerTypesById[influencerTypeId].email))}">-->
    <!--                                Email-->
    <!--                              </th>-->
    <!--                              <th-->
    <!--                                [ngClass]="{'d-none': !showAllColumns || !(!influencerTypeId || (influencerTypeId && influencerTypesById[influencerTypeId].booth_number))}">-->
    <!--                                Booth Number-->
    <!--                              </th>-->
    <!--&lt;!&ndash;                              <th&ndash;&gt;-->
    <!--&lt;!&ndash;                                [ngClass]="{'d-none': !showAllColumns || !(!influencerTypeId || (influencerTypeId && influencerTypesById[influencerTypeId].company))}">&ndash;&gt;-->
    <!--&lt;!&ndash;                                Company&ndash;&gt;-->
    <!--&lt;!&ndash;                              </th>&ndash;&gt;-->
    <!--                              <th-->
    <!--                                [ngClass]="{'d-none': !showAllColumns || !(!influencerTypeId || (influencerTypeId && influencerTypesById[influencerTypeId].first_name))}">-->
    <!--                                First Name-->
    <!--                              </th>-->
    <!--                              <th-->
    <!--                                [ngClass]="{'d-none': !showAllColumns || !(!influencerTypeId || (influencerTypeId && influencerTypesById[influencerTypeId].last_name))}">-->
    <!--                                Last Name-->
    <!--                              </th>-->
    <!--                              <th-->
    <!--                                [ngClass]="{'d-none': !showAllColumns || !(!influencerTypeId || (influencerTypeId && influencerTypesById[influencerTypeId].job_title))}">-->
    <!--                                Job Title-->
    <!--                              </th>-->
    <!--                              <th-->
    <!--                                [ngClass]="{'d-none': !showAllColumns || !(!influencerTypeId || (influencerTypeId && influencerTypesById[influencerTypeId].phone))}">-->
    <!--                                Phone-->
    <!--                              </th>-->
    <!--                              <th-->
    <!--                                [ngClass]="{'d-none': !showAllColumns || !(!influencerTypeId || (influencerTypeId && influencerTypesById[influencerTypeId].registration_url))}">-->
    <!--                                Registration url-->
    <!--                              </th>-->
    <!--                              <th-->
    <!--                                [ngClass]="{'d-none': !showAllColumns || !(!influencerTypeId || (influencerTypeId && influencerTypesById[influencerTypeId].sessions))}">-->
    <!--                                Sessions-->
    <!--                              </th>-->
    <!--                              <th-->
    <!--                                [ngClass]="{'d-none': !showAllColumns || !(!influencerTypeId || (influencerTypeId && influencerTypesById[influencerTypeId].slug))}">-->
    <!--                                Slug-->
    <!--                              </th>-->
    <!--                              <th-->
    <!--                                [ngClass]="{'d-none': !showAllColumns || !(!influencerTypeId || (influencerTypeId && influencerTypesById[influencerTypeId].video_url))}">-->
    <!--                                Video url-->
    <!--                              </th>-->
    <!--                              <th-->
    <!--                                [ngClass]="{'d-none': !showAllColumns || !(!influencerTypeId || (influencerTypeId && influencerTypesById[influencerTypeId].custom_1))}">-->
    <!--                                Custom 1-->
    <!--                              </th>-->
    <!--                              <th-->
    <!--                                [ngClass]="{'d-none': !showAllColumns || !(!influencerTypeId || (influencerTypeId && influencerTypesById[influencerTypeId].custom_2))}">-->
    <!--                                Custom 2-->
    <!--                              </th>-->
    <!--                              <th-->
    <!--                                [ngClass]="{'d-none': !showAllColumns || !(!influencerTypeId || (influencerTypeId && influencerTypesById[influencerTypeId].custom_3))}">-->
    <!--                                Custom 3-->
    <!--                              </th>-->
    <!--                              <th-->
    <!--                                [ngClass]="{'d-none': !showAllColumns || !(!influencerTypeId || (influencerTypeId && influencerTypesById[influencerTypeId].custom_4))}">-->
    <!--                                Custom 4-->
    <!--                              </th>-->
    <!--                              <th-->
    <!--                                [ngClass]="{'d-none': !showAllColumns || !(!influencerTypeId || (influencerTypeId && influencerTypesById[influencerTypeId].custom_5))}">-->
    <!--                                Custom 5-->
    <!--                              </th>-->
    <!--                              <th-->
    <!--                                [ngClass]="{'d-none': !showAllColumns || !(!influencerTypeId || (influencerTypeId && influencerTypesById[influencerTypeId].custom_6))}">-->
    <!--                                Custom 6-->
    <!--                              </th>-->
    <!--                              <th-->
    <!--                                [ngClass]="{'d-none': !showAllColumns || !(!influencerTypeId || (influencerTypeId && influencerTypesById[influencerTypeId].custom_7))}">-->
    <!--                                Custom 7-->
    <!--                              </th>-->
    <!--                              <th-->
    <!--                                [ngClass]="{'d-none': !showAllColumns || !(!influencerTypeId || (influencerTypeId && influencerTypesById[influencerTypeId].custom_8))}">-->
    <!--                                Custom 8-->
    <!--                              </th>-->
    <!--                              <th-->
    <!--                                [ngClass]="{'d-none': !showAllColumns || !(!influencerTypeId || (influencerTypeId && influencerTypesById[influencerTypeId].custom_9))}">-->
    <!--                                Custom 9-->
    <!--                              </th>-->
    <!--                              <th-->
    <!--                                [ngClass]="{'d-none': !showAllColumns || !(!influencerTypeId || (influencerTypeId && influencerTypesById[influencerTypeId].custom_10))}">-->
    <!--                                Custom 10-->
    <!--                              </th>-->
    <!--                            </tr>-->
    <!--                            </thead>-->
    <!--                            <tbody *ngIf="influencersList.length > 0">-->
    <!--                            <tr *ngFor="let influencer of influencersList">-->
    <!--                              <td>{{influencer.name}}</td>-->
    <!--                              <td>{{influencer.company}}</td>-->
    <!--                              <td>{{influencer.segment}}</td>-->
    <!--                              <td>{{influencer.socialShare}}</td>-->
    <!--                              <td>{{influencer.pageViews}}</td>-->
    <!--                              <td>-->
    <!--                                <ng-container *ngIf="influencer.referredCount !== 0">-->
    <!--                                  <a class="text-decoration: underline !important;" href="javascript:void(0)"-->
    <!--                                     (click)="showData(influencer)">{{influencer.referredCount}}</a>-->
    <!--                                </ng-container>-->
    <!--                                <ng-container-->
    <!--                                  *ngIf="!influencer.referredCount || influencer.referredCount === 0">{{influencer.referredCount}}</ng-container>-->
    <!--                              </td>-->
    <!--                              <td>{{influencer.registrationClicks}}</td>-->
    <!--                              <td [ngClass]="{'d-none': !showAllColumns || !(!influencerTypeId || (influencerTypeId && influencerTypesById[influencerTypeId].updated_at))}">{{influencer.emailOpenStatus > 0 ? 'Yes' : 'No'}}</td>-->
    <!--                              <td [ngClass]="{'d-none': !showAllColumns || !(!influencerTypeId || (influencerTypeId && influencerTypesById[influencerTypeId].updated_at))}">{{influencer.emailClickStatus > 0 ? 'Yes' : 'No'}}</td>-->
    <!--                              <td [ngClass]="{'d-none': !showAllColumns || !(!influencerTypeId || (influencerTypeId && influencerTypesById[influencerTypeId].updated_at))}">{{influencer.source || '-'}}</td>-->
    <!--                              <td [ngClass]="{'d-none': !showAllColumns || !(!influencerTypeId || (influencerTypeId && influencerTypesById[influencerTypeId].updated_at))}">{{influencer.referredThroughTrackerSource || '-'}}</td>-->
    <!--&lt;!&ndash;                              <td>{{formatInsertTime(influencer.created_at)}}</td>&ndash;&gt;-->
    <!--&lt;!&ndash;                              <td [ngClass]="{'d-none': !showAllColumns || !(!influencerTypeId || (influencerTypeId && influencerTypesById[influencerTypeId].updated_at))}">{{formatInsertTime(influencer.updated_at)}}</td>&ndash;&gt;-->
    <!--                              <td [ngClass]="{'d-none': !showAllColumns || !(!influencerTypeId || (influencerTypeId && influencerTypesById[influencerTypeId].updated_at))}">{{formatMoment(influencer.updated_at) | tzDate : false : 'DD-MM-YYYY hh:mm a'}}</td>-->
    <!--                              <td-->
    <!--                                [ngClass]="{'d-none': !showAllColumns || !(!influencerTypeId || (influencerTypeId && influencerTypesById[influencerTypeId].typeName))}">{{influencer.typeName}}</td>-->
    <!--                              <td-->
    <!--                                [ngClass]="{'d-none': !showAllColumns || !(!influencerTypeId || (influencerTypeId && influencerTypesById[influencerTypeId].email))}">{{influencer.email}}</td>-->
    <!--                              <td-->
    <!--                                [ngClass]="{'d-none': !showAllColumns || !(!influencerTypeId || (influencerTypeId && influencerTypesById[influencerTypeId].booth_number))}">{{influencer.booth_number}}</td>-->
    <!--&lt;!&ndash;                              <td&ndash;&gt;-->
    <!--&lt;!&ndash;                                [ngClass]="{'d-none': !showAllColumns || !(!influencerTypeId || (influencerTypeId && influencerTypesById[influencerTypeId].company))}">{{influencer.company}}</td>&ndash;&gt;-->
    <!--                              <td-->
    <!--                                [ngClass]="{'d-none': !showAllColumns || !(!influencerTypeId || (influencerTypeId && influencerTypesById[influencerTypeId].first_name))}">{{influencer.first_name}}</td>-->
    <!--                              <td-->
    <!--                                [ngClass]="{'d-none': !showAllColumns || !(!influencerTypeId || (influencerTypeId && influencerTypesById[influencerTypeId].last_name))}">{{influencer.last_name}}</td>-->
    <!--                              <td-->
    <!--                                [ngClass]="{'d-none': !showAllColumns || !(!influencerTypeId || (influencerTypeId && influencerTypesById[influencerTypeId].job_title))}">{{influencer.job_title}}</td>-->
    <!--                              <td-->
    <!--                                [ngClass]="{'d-none': !showAllColumns || !(!influencerTypeId || (influencerTypeId && influencerTypesById[influencerTypeId].phone))}">{{influencer.phone}}</td>-->
    <!--                              <td-->
    <!--                                [ngClass]="{'d-none': !showAllColumns || !(!influencerTypeId || (influencerTypeId && influencerTypesById[influencerTypeId].registration_url))}">{{influencer.registration_url}}</td>-->
    <!--                              <td-->
    <!--                                [ngClass]="{'d-none': !showAllColumns || !(!influencerTypeId || (influencerTypeId && influencerTypesById[influencerTypeId].sessions))}">{{influencer.sessions}}</td>-->
    <!--                              <td-->
    <!--                                [ngClass]="{'d-none': !showAllColumns || !(!influencerTypeId || (influencerTypeId && influencerTypesById[influencerTypeId].slug))}">{{influencer.slug}}</td>-->
    <!--                              <td-->
    <!--                                [ngClass]="{'d-none': !showAllColumns || !(!influencerTypeId || (influencerTypeId && influencerTypesById[influencerTypeId].video_url))}">{{influencer.video_url}}</td>-->
    <!--                              <td-->
    <!--                                [ngClass]="{'d-none': !showAllColumns || !(!influencerTypeId || (influencerTypeId && influencerTypesById[influencerTypeId].custom_1))}">{{influencer.custom_1}}</td>-->
    <!--                              <td-->
    <!--                                [ngClass]="{'d-none': !showAllColumns || !(!influencerTypeId || (influencerTypeId && influencerTypesById[influencerTypeId].custom_2))}">{{influencer.custom_2}}</td>-->
    <!--                              <td-->
    <!--                                [ngClass]="{'d-none': !showAllColumns || !(!influencerTypeId || (influencerTypeId && influencerTypesById[influencerTypeId].custom_3))}">{{influencer.custom_3}}</td>-->
    <!--                              <td-->
    <!--                                [ngClass]="{'d-none': !showAllColumns || !(!influencerTypeId || (influencerTypeId && influencerTypesById[influencerTypeId].custom_4))}">{{influencer.custom_4}}</td>-->
    <!--                              <td-->
    <!--                                [ngClass]="{'d-none': !showAllColumns || !(!influencerTypeId || (influencerTypeId && influencerTypesById[influencerTypeId].custom_5))}">{{influencer.custom_5}}</td>-->
    <!--                              <td-->
    <!--                                [ngClass]="{'d-none': !showAllColumns || !(!influencerTypeId || (influencerTypeId && influencerTypesById[influencerTypeId].custom_6))}">{{influencer.custom_6}}</td>-->
    <!--                              <td-->
    <!--                                [ngClass]="{'d-none': !showAllColumns || !(!influencerTypeId || (influencerTypeId && influencerTypesById[influencerTypeId].custom_7))}">{{influencer.custom_7}}</td>-->
    <!--                              <td-->
    <!--                                [ngClass]="{'d-none': !showAllColumns || !(!influencerTypeId || (influencerTypeId && influencerTypesById[influencerTypeId].custom_8))}">{{influencer.custom_8}}</td>-->
    <!--                              <td-->
    <!--                                [ngClass]="{'d-none': !showAllColumns || !(!influencerTypeId || (influencerTypeId && influencerTypesById[influencerTypeId].custom_9))}">{{influencer.custom_9}}</td>-->
    <!--                              <td-->
    <!--                                [ngClass]="{'d-none': !showAllColumns || !(!influencerTypeId || (influencerTypeId && influencerTypesById[influencerTypeId].custom_10))}">{{influencer.custom_10}}</td>-->
    <!--                            </tr>-->
    <!--                            </tbody>-->
    <!--                            <tbody *ngIf="influencersList.length <= 0">-->
    <!--                            <tr>-->
    <!--                              <td-->
    <!--                                colspan="{{(showAllColumns ? (influencerTypesById ? _object.keys(influencerTypesById[influencerTypeId] || {}).length : 22) : 0) + 9}}">-->
    <!--                                No data found-->
    <!--                              </td>-->
    <!--                            </tr>-->
    <!--                            </tbody>-->
    <!--                          </table>-->
    <!--                        </div>-->
    <!--                      </div>-->
    <!--                    </div>-->
    <!--                  </div>-->
    <!--                </div>-->
    <!--              </div>-->
    <!--              <div class="col-xl-12">-->
    <!--                <div class="card card-box mb-5">-->
    <!--                  <div class="card-header chart-card d-flex align-items-center bg-white">-->
    <!--                    <div class="card-header&#45;&#45;title font-size-xxl py-2"><i class="fa fa-bar-chart"-->
    <!--                                                                          style="font-size: 2rem;margin-right: 0.5rem;"></i>-->
    <!--                      Page Views-->
    <!--                    </div>-->
    <!--                  </div>-->
    <!--                  <div class="card-body" *ngIf="reportTotals">-->
    <!--                    <apx-chart-->
    <!--                      [chart]="{type: 'area',height: '350'}"-->
    <!--                      [dataLabels]="{enabled: false}"-->
    <!--                      [xaxis]="{-->
    <!--                      type: 'category',-->
    <!--                      title: {text: 'Dates'},-->
    <!--                      label: {show: true},-->
    <!--                      tickAmount: 15,-->
    <!--                      categories: dates,-->
    <!--                      axisTicks: {-->
    <!--                          show: true,-->
    <!--                          borderType: 'solid',-->
    <!--                          color: '#78909C',-->
    <!--                          height: 6,-->
    <!--                          offsetX: 0,-->
    <!--                          offsetY: 0-->
    <!--                        }-->
    <!--                      }"-->
    <!--                      [yaxis]="{-->
    <!--                        type: '',-->
    <!--                        show: true,-->
    <!--                        showAlways: true,-->
    <!--                        title: {-->
    <!--                          text: 'Page Views'-->
    <!--                        }-->
    <!--                      }"-->
    <!--                      [tooltip]="{enabled: true, shared: true}"-->
    <!--                      [series]="series"-->
    <!--                      [noData]="{text: 'No data available.'}"-->
    <!--                      [stroke]="{curve: 'smooth', show: true, width: 2}"-->
    <!--                    ></apx-chart>-->

    <!--                  </div>-->
    <!--                </div>-->
    <!--              </div>-->
    <!--              <div class="col-xl-12">-->
    <!--                <div class="card card-box mb-5">-->
    <!--                  <div class="card-header d-flex align-items-center bg-white">-->
    <!--                    <div class="card-header&#45;&#45;title font-size-xxl py-2">-->
    <!--                      <i class="fa fa-share-square-o"-->
    <!--                         style="margin-right: 0.5rem;"> </i>-->
    <!--                      Social Share-->
    <!--                    </div>-->
    <!--                  </div>-->
    <!--                  <div class="card-body p-2">-->
    <!--                    <div id="piechart">-->
    <!--                      <apx-chart-->
    <!--                        [chart]="{type: 'pie',height: '350'}"-->
    <!--                        [labels]="piechart.labels"-->
    <!--                        [dataLabels]="{-->
    <!--                            enable: true,-->
    <!--                            formatter: chartLabelFormatter,-->
    <!--                            textAnchor: 'end'-->

    <!--                        }"-->
    <!--                        [series]="piechart.series"-->
    <!--                        [colors]="piechart.colors"-->
    <!--                        [noData]="{text: 'No data available.'}"-->
    <!--                      ></apx-chart>-->
    <!--                    </div>-->
    <!--                  </div>-->
    <!--                </div>-->
    <!--              </div>-->
    <!--            </div>-->
    <!--          </div>-->
    <!--        </div>-->
    <!--      </div>-->
    <!--    </div>-->
  </div>
</div>
<ng-template #passwordForm let-modal>
  <div class="modal-header">
    <h4 class="modal-title" id="modal-title">Enter password to view report</h4>
  </div>
  <div class="modal-body">
    <div class="row">
      <div class="col-md-12">
        <div class="floating-label">
          <input class="floating-input" [(ngModel)]="reportPassword" (ngModelChange)="reportPasswordError = ''"
                 placeholder=" " type="password"/>
          <span class="highlight"></span>
          <label>Password</label>
          <div *ngIf="reportPasswordError" class="bar error-brd">
            <div class="invalid-text">{{reportPasswordError}}</div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <input type="hidden" ngbAutofocus>
  <div class="modal-footer">
    <button type="button" class="btn btn-default" (click)="validatePassword(modal)">Confirm</button>
  </div>
</ng-template>


<ng-template #loadReport let-modal >
  <div class="modal-body">
    <div class="text-center pb-3">
      <div class="lds-ellipsis align-items-center justify-content-center" style='right: 20px'>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
      </div>
      <div class='pt-2'>
          <h3>please wait</h3>
      </div>
    </div>
  </div>
</ng-template>
<ng-template #csvDownload let-modal >
  <div class="modal-header">
    <h4
      class="modal-title"
      id="modal-title"
    >
      Export {{ !csvDownloadComplete ? ': Your data is being exported' : ''}}
    </h4>
    <div class="d-flex justify-content-end">
      <button
        type="button"
        aria-label="Close button"
        class="close"
        aria-describedby="modal-title"
        (click)="modal.close()"
      >
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
  </div>
  <div class="modal-body">
    <div class="text-center">
      <button class="btn btn-default" type="button" *ngIf='!csvDownloadComplete'>
        <span class="btn-wrapper--icon spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
        <span class="btn-wrapper--label">
      {{exportProgress.status === 'started' ?
          'Export: started' :
          exportProgress.status === 'in-progress' ?
            'Exporting: '  + ' ' + exportProgress.progress + '%' :
            'Export: ' + exportProgress.status}}
    </span>
      </button>
      <a class="btn btn-default" type="button" *ngIf='csvDownloadComplete' (click)="downloadExportFile()">
        <span class="btn-wrapper--label">
          {{'File Link'}}
    </span>
      </a>

    </div>
  </div>
</ng-template>
