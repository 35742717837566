import {Campaign, PaginatedCampaigns} from '@app/modules/shared/models';
import {CampaignLaunchCode, CampaignValidation} from '@app/stores';

export interface ICampaignState {
  campaign: Campaign;
  campaignLaunchCode: Campaign;
  campaigns: Campaign[];
  paginatedCampaigns: PaginatedCampaigns;
  campaignUpdate: any;
  campaignLaunch: any;
  campaignValidation: any;
  campaignAdd: any;
  success: string;
  error: string;
}

export const initialCampaignState: ICampaignState = {
  campaign: null,
  campaignLaunchCode: null,
  campaignUpdate: null,
  campaignAdd: null,
  paginatedCampaigns: null,
  campaignLaunch: null,
  campaignValidation: null,
  campaigns: [],
  success: '',
  error: ''
};
