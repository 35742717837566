import {createFeatureSelector, createSelector} from '@ngrx/store';
import {ITemplateSectionState} from '@app/stores';

const selectTemplateSection = createFeatureSelector<ITemplateSectionState>('templateSection');

export const getTemplateSection = createSelector(selectTemplateSection, (state: ITemplateSectionState) => state.templateSection);

export const getTemplateSectionSuccess = createSelector(selectTemplateSection, (state: ITemplateSectionState) => state.success);

export const getTemplateSectionError = createSelector(selectTemplateSection, (state: ITemplateSectionState) => state.error);

export const getTemplateSections = createSelector(selectTemplateSection, (state: ITemplateSectionState) => state.templateSections);

export const getPaginatedTemplateSections = createSelector(selectTemplateSection, (state: ITemplateSectionState) =>
  state.paginatedTemplateSection);

export const getTemplateSectionTypes = createSelector(selectTemplateSection, (state: ITemplateSectionState) => state.templateSectionTypes);
export const getTemplateSectionAssets = createSelector(selectTemplateSection, (state: ITemplateSectionState) =>
  state.templateSectionAssets);
