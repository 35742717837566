import {Injectable} from '@angular/core';
import {
  HttpClient
} from '@angular/common/http';
import {environment} from '@environments/environment';

@Injectable({providedIn: 'root'})
export class TrackingTypeService {
  private baseApiUrl = environment.apiUrl;
  private trackingTypeUrl = this.baseApiUrl + '/api/v1/tracking_type';

  constructor(private http: HttpClient) {
  }

  getAll(params: any = {}) {
    return this.http.get(`${this.trackingTypeUrl}`, {params});
  }
}
