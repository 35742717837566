<div [ngClass]="{'container-fluid pt-3' : !hideClass}">
  <div [ngClass]="{'rightBody': !hideClass}">
    <form [formGroup]="addInfluencerForm" *ngIf="showForm" (ngSubmit)="addInfluencer()" class="needs-validation"
          enctype="multipart/form-data" novalidate>
      <div class="card" [ngClass]="{'no-background' : isModal, 'shadow-none': isModal}">
        <div class="card-header py-3" [ngClass]="{'no-background' : isModal}">
          <div class="card-header--title font-size-lg">
            Add Influencer <i class="fa fa-close float-right cursor-pointer" *ngIf="isModal"
                              (click)="closeModal.emit(true); isModelClosed.emit(true)"></i>
          </div>
        </div>
        <div class="card-body p-0">
          <div class="card card-inline">
            <div class="card-body p-0">
              <div class="form-wraper">
                <div class="row mb-3">
                  <div class="col-lg-4 col-md-4 col-sm-12 text-center d-flex justify-content-center align-items-center">
                    <div class="image-event">
                      <img [src]="imageSrc" *ngIf="imageSrc" class="img-fluid d-block" id="event-logo-preview" style="max-width: 400px;"/>
                      <button (click)="activeFileInput(fileInput)" [disabled]="submitted && fileUploadProgress > 0 && fileUploadProgress < 100" class="btn btn-default" id="logoUploaderBtn"
                              type="button">
                        <i class="fa fa-upload"></i>
                        {{fileUploadProgress ? fileUploadProgress + '%' : ''}} {{imageSrc ? 'Replace' : 'Upload'}} {{getImageType()}}
                      </button>
                      <app-info-popover
                        info="Acceptable formats are jpeg and png. (Min  800x800px, max 2048x2048px)">
                      </app-info-popover>
                      <input #fileInput (change)="onFileChanged($event)" accept="image/*" class="file-input"
                             id="eventLogo" type="file">
                    </div>
                  </div>
                  <div class="col-md-8 col-md-8 col-sm-12">
                    <div class="row">
                      <div class="col-lg-6 col-md-6 col-ms-12">
                        <div class="floating-label">
                          <select class="floating-select" formControlName="influencer_type_id"
                                  (change)="createForm($event.target.value);filterSegments(true)">
                            <option [value]='influencerType.id'
                                    *ngFor="let influencerType of influencerTypes">{{influencerType.name}}</option>
                          </select>
                          <span class="highlight"></span>
                          <label>Select Influencer Type :</label>
                        </div>
                      </div>
                      <div class="col-lg-6 col-md-6 col-ms-12">
                        <app-creatable-select #segmentSelect placeholder="Select or enter a new segment's name" [selectOptions]="segments"
                                              (optionSelected)="segmentSelected($event)" [selectedValue]="selectedSegment"></app-creatable-select>
                        <div class="bar error-brd custom-error"
                             *ngIf="submitted && f.project_influencer_segment_new_name.errors">
                          <div class="invalid-text" *ngIf="f.project_influencer_segment_new_name.errors.required">
                            Segment is required
                          </div>
                        </div>
                        <div class="bar error-brd custom-error"
                             *ngIf="submitted && f.project_influencer_segment_id.errors">
                          <div class="invalid-text" *ngIf="f.project_influencer_segment_id.errors.required">Segment is
                            required
                          </div>
                        </div>
                      </div>

                      <div class="col-lg-6 col-md-6 col-ms-12" *ngIf="controls['name']">
                        <div class="floating-label">
                          <input class="floating-input" formControlName="name"
                                 [ngClass]="{ 'is-invalid': submitted && f.name.errors }"
                                 type="text" placeholder=" ">
                          <span class="highlight"></span>
                          <label>Name
                            <span class="red">*</span>
                          </label>
                          <div class="bar error-brd" *ngIf="submitted && f.name.errors">
                            <div class="invalid-text" *ngIf="f.name.errors.required">Name is required</div>
                          </div>
                        </div>
                      </div>
                      <div class="col-lg-6 col-md-6 col-ms-12" *ngIf="controls['title']">
                        <div class="floating-label">
                          <input class="floating-input" formControlName="title"
                                 [ngClass]="{ 'is-invalid': submitted && f.title.errors }"
                                 type="text" placeholder=" ">
                          <span class="highlight"></span>
                          <label>Prefix</label>
                        </div>
                      </div>

                      <div class="col-lg-6 col-md-6 col-ms-12" *ngIf="controls['first_name']">
                        <div class="floating-label">
                          <input class="floating-input" formControlName="first_name"
                                 [ngClass]="{ 'is-invalid': submitted && f.first_name.errors }"
                                 type="text" placeholder=" ">
                          <span class="highlight"></span>
                          <label>First Name
                            <span class="red">*</span>
                          </label>
                          <div class="bar error-brd" *ngIf="submitted && f.first_name.errors">
                            <div class="invalid-text" *ngIf="f.first_name.errors.required">First Name is required</div>
                          </div>
                        </div>
                      </div>
                      <div class="col-lg-6 col-md-6 col-ms-12" *ngIf="controls['last_name']">
                        <div class="floating-label">
                          <input class="floating-input" formControlName="last_name"
                                 [ngClass]="{ 'is-invalid': submitted && f.last_name.errors }"
                                 type="text" placeholder=" ">
                          <span class="highlight"></span>
                          <label>Last Name
                            <span class="red">*</span>
                          </label>
                          <div class="bar error-brd" *ngIf="submitted && f.last_name.errors">
                            <div class="invalid-text" *ngIf="f.last_name.errors.required">Last Name is required</div>
                          </div>
                        </div>
                      </div>
                      <div class="col-lg-6 col-md-6 col-ms-12" *ngIf="controls['email']">
                        <div class="floating-label">
                          <input class="floating-input" formControlName="email"
                                 [ngClass]="{ 'is-invalid': submitted && f.email.errors }"
                                 type="text" placeholder=" ">
                          <span class="highlight"></span>
                          <label>Email
                            <span class="red">*</span>
                          </label>
                          <div class="bar error-brd" *ngIf="submitted && f.email.errors">
                            <div class="invalid-text" *ngIf="f.email.errors.required">Email is required</div>
                            <div class="invalid-text" *ngIf="f.email.errors.email">Invalid email address</div>
                          </div>
                        </div>
                      </div>
                      <div class="col-lg-6 col-md-6 col-ms-12" *ngIf="controls['company']">
                        <div class="floating-label">
                          <input class="floating-input" formControlName="company"
                                 [ngClass]="{ 'is-invalid': submitted && f.company.errors }"
                                 type="text" placeholder=" ">
                          <span class="highlight"></span>
                          <label>Company Name</label>
                          <div class="bar error-brd" *ngIf="submitted && f.company.errors">
                            <div class="invalid-text" *ngIf="f.company.errors.required">Company is required</div>
                          </div>
                        </div>
                      </div>
                      <div class="col-lg-6 col-md-6 col-ms-12" *ngIf="controls['registration_url']">
                        <div class="floating-label input-group input-group-sm info-input">
                          <input class="floating-input" type="text" placeholder=" " formControlName="registration_url"
                                 [ngClass]="{ 'is-invalid': submitted && f.registration_url.errors }">
                          <span class="highlight"></span>
                          <label>Call To Action Url</label>
                          <div class="bar error-brd" *ngIf="submitted && f.registration_url.errors">
                            <div class="invalid-text" *ngIf="f.registration_url.errors">Please enter valid url.</div>
                          </div>
                          <app-info-popover
                            info="This could be your registration link or downloadable form link">
                          </app-info-popover>
                        </div>
                      </div>

                      <div class="col-lg-6 col-md-6 col-ms-12" *ngIf="controls['job_title']">
                        <div class="floating-label">
                          <input class="floating-input" formControlName="job_title"
                                 [ngClass]="{ 'is-invalid': submitted && f.job_title.errors }"
                                 type="text" placeholder=" ">
                          <span class="highlight"></span>
                          <label>Job Title</label>
                        </div>
                      </div>
                      <div class="col-lg-6 col-md-6 col-ms-12" *ngIf="controls['phone']">
                        <div class="floating-label">
                          <input class="floating-input" formControlName="phone"
                                 [ngClass]="{ 'is-invalid': submitted && f.phone.errors }"
                                 type="text" placeholder=" ">
                          <span class="highlight"></span>
                          <label>Phone</label>
                          <div class="bar error-brd" *ngIf="submitted && f.phone.errors">
                            <div class="invalid-text" *ngIf="f.phone.errors">Please enter valid phone number.</div>
                          </div>
                        </div>
                      </div>
                      <div class="col-lg-6 col-md-6 col-ms-12" *ngIf="controls['video_url']">
                        <div class="floating-label input-group input-group-sm info-input">
                          <input class="floating-input" formControlName="video_url"
                                 [ngClass]="{ 'is-invalid': submitted && f.video_url.errors }"
                                 type="text" placeholder=" ">
                          <span class="highlight"></span>
                          <label>Video
                          </label>
                          <div class="bar error-brd" *ngIf="submitted && f.video_url.errors">
                            <div class="invalid-text" *ngIf="f.video_url.errors">Please enter valid url.</div>
                          </div>
                          <app-info-popover
                            info="Insert URL or Embed code">
                          </app-info-popover>
                        </div>
                      </div>
                      <div class="col-lg-6 col-md-6 col-ms-12" >
                        <div class="floating-label">
                          <app-creatable-select
                            class="floating-input"
                            [selectedValue]="selectedSkip?.id" [valueKey]="'id'" [labelKey]="'title'"
                            [clearable]="true"
                            [hideLabel]="true" [onlyBottomBorder]="false" [isCreatable]="false"
                            [placeholder]="'Select a Skip'" [selectOptions]="skipValues"
                            (optionSelected)="skipSelected($event)"></app-creatable-select>
                          <label>Select Skip Type :</label>
                        </div>
                      </div>
                      <div class="floating-label input-group input-group-sm info-input" *ngIf="controls['email_source_message']">
                        <div class="floating-label">
                          <input class="floating-input" type="text" placeholder="You're receiving this email because you are part of our community." formControlName="email_source_message"
                                 [ngClass]="{ 'is-invalid': submitted && f.email_source_message.errors }">
                          <span class="highlight"></span>
                          <label>Email Source Message</label>
                          <app-info-popover
                            info="This will be displayed to your audience in the emails they receive. If not set the either the campaign or the project message will be used. Example: You signed up to our newsletter in our previous event">
                          </app-info-popover>
                        </div>
                      </div>
                      <div class="col-lg-6 col-md-6 col-ms-12" *ngIf="controls['booth_number']">
                        <div class="floating-label">
                          <input class="floating-input" type="text" placeholder=" " formControlName="booth_number"
                                 [ngClass]="{ 'is-invalid': submitted && f.booth_number.errors }">
                          <span class="highlight"></span>
                          <label>Booth#</label>
                        </div>
                      </div>
                      <div class="col-md-12" *ngIf="controls['sessions']">
                        <div *ngFor="let session of sessionsGroup.controls; let i = index"
                             formArrayName="sessions">
                          <div [formGroupName]="i" class="row">
                            <div class="col-md-10">
                              <div class="floating-label">
                                <input class="floating-input" formControlName="session"
                                       [ngClass]="{ 'is-invalid': submitted && sessionsGroup.controls?.session?.errors }"
                                       type="text" placeholder=" ">
                                <span class="highlight"></span>
                                <label>Sessions</label>
                                <div class="bar error-brd" *ngIf="submitted && sessionsGroup.controls?.session?.errors">
                                  <div class="invalid-text" *ngIf="sessionsGroup.controls?.session?.errors?.required">
                                    Sessions is required
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div class="col-md-2 d-flex align-items-center justify-content-center">
                              <a (click)="removeSessionButtonClick(i)" class="cursor-pointer"
                                 *ngIf="(sessionsGroup.controls.length > 1 && i >= 0)">
                                <i class="fa fa-times-circle plus-btn"></i>
                              </a>
                              <a (click)="addSessionButtonClick()"
                                 *ngIf="sessionsGroup.controls.length - 1 === i" class="cursor-pointer">
                                <i class="fa fa-plus-circle plus-btn"></i>
                              </a>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div class="row" *ngIf="controls['description']">
                  <div class="col-md-12">
                    <div class="form-group">
                      <textarea class="form-control" formControlName="description"
                                placeholder="Description" rows="3"></textarea>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <!--          SOCIALS-->
          <!--          <div class="card card-inline d-none">-->
          <!--            <div class="card-header py-3">-->
          <!--              <div class="card-header&#45;&#45;title font-size-lg">-->
          <!--                Social Media Information-->
          <!--              </div>-->
          <!--            </div>-->
          <!--            <div class="card-body form-padding">-->
          <!--              <div class="form-wraper">-->
          <!--                <div class="row">-->
          <!--                  <div class="col-md-6">-->
          <!--                    <div class="floating-label">-->
          <!--                      <input class="floating-input" formControlName="facebook" type="text" placeholder=" "-->
          <!--                             [ngClass]="{ 'is-invalid': submitted && f.facebook.errors }">-->
          <!--                      <span class="highlight"></span>-->
          <!--                      <label>Facebook</label>-->
          <!--                      <div class="bar error-brd" *ngIf="submitted && f.facebook.errors">-->
          <!--                        <div class="invalid-text" *ngIf="f.facebook.errors">Please enter valid url.</div>-->
          <!--                      </div>-->
          <!--                    </div>-->
          <!--                  </div>-->
          <!--                  <div class="col-md-6">-->
          <!--                    <div class="floating-label">-->
          <!--                      <input class="floating-input" formControlName="twitter" type="text" placeholder=" "-->
          <!--                             [ngClass]="{ 'is-invalid': submitted && f.twitter.errors }">-->
          <!--                      <span class="highlight"></span>-->
          <!--                      <label>Twitter</label>-->
          <!--                      <div class="bar error-brd" *ngIf="submitted && f.twitter.errors">-->
          <!--                        <div class="invalid-text" *ngIf="f.twitter.errors">Please enter valid url.</div>-->
          <!--                      </div>-->
          <!--                    </div>-->
          <!--                  </div>-->
          <!--                </div>-->
          <!--                <div class="row">-->
          <!--                  <div class="col-md-6">-->
          <!--                    <div class="floating-label">-->
          <!--                      <input class="floating-input" formControlName="linkedin" type="text" placeholder=" "-->
          <!--                             [ngClass]="{ 'is-invalid': submitted && f.linkedin.errors }">-->
          <!--                      <span class="highlight"></span>-->
          <!--                      <label>LinkedIn</label>-->
          <!--                      <div class="bar error-brd" *ngIf="submitted && f.linkedin.errors">-->
          <!--                        <div class="invalid-text" *ngIf="f.linkedin.errors">Please enter valid url.</div>-->
          <!--                      </div>-->
          <!--                    </div>-->
          <!--                  </div>-->
          <!--                  <div class="col-md-6">-->
          <!--                    <div class="floating-label">-->
          <!--                      <input class="floating-input" formControlName="instagram" type="text" placeholder=" "-->
          <!--                             [ngClass]="{ 'is-invalid': submitted && f.instagram.errors }">-->
          <!--                      <span class="highlight"></span>-->
          <!--                      <label>Instagram</label>-->
          <!--                      <div class="bar error-brd" *ngIf="submitted && f.instagram.errors">-->
          <!--                        <div class="invalid-text" *ngIf="f.instagram.errors">Please enter valid url.</div>-->
          <!--                      </div>-->
          <!--                    </div>-->
          <!--                  </div>-->
          <!--                </div>-->
          <!--                <div formArrayName="socials" *ngFor="let socials of socialsGroup.controls; let i = index">-->
          <!--                  <div [formGroupName]="i">-->

          <!--                    <div class="row">-->
          <!--                      <div class="col-lg-6 col-md-6 col-ms-12">-->
          <!--                        <div class="floating-label">-->
          <!--                          <input class="floating-input" type="text" id="{{'name' + i}}" formControlName="social_label"-->
          <!--                                 placeholder=" "/>-->
          <!--                          <span class="highlight"></span>-->
          <!--                          <label for="{{'name' + i}}">Name</label>-->
          <!--                        </div>-->
          <!--                      </div>-->

          <!--                      <div class="col-lg-6 col-md-6 col-ms-12">-->
          <!--                        <div class="floating-label">-->
          <!--                          <input class="floating-input" type="text" id="{{'url' + i}}" formControlName="social_value"-->
          <!--                                 placeholder=" "/>-->
          <!--                          <span class="highlight"></span>-->
          <!--                          <label for="{{'url' + i}}">URL</label>-->
          <!--                          <a (click)="removeSocialsButtonClick(i)" class="pointer"-->
          <!--                             *ngIf="!(socialsGroup.controls.length-1 === i)">-->
          <!--                            <i class="fa fa-times-circle remove-btn"></i>-->
          <!--                          </a>-->
          <!--                        </div>-->
          <!--                      </div>-->
          <!--                    </div>-->
          <!--                  </div>-->
          <!--                </div>-->

          <!--                <div class="row">-->
          <!--                  <div class="col-lg-12 col-md-12 col-ms-12">-->
          <!--                    <a (click)="addSocialsButtonClick()" class="pointer">-->
          <!--                      <i class="fa fa-plus-circle add-btn mr-3"></i>-->
          <!--                    </a>-->
          <!--                  </div>-->
          <!--                </div>-->

          <!--              </div>-->
          <!--            </div>-->
          <!--          </div>-->
          <!--          TEAMS-->
          <!--          <div class="card card-inline d-none">-->
          <!--            <div class="card-header py-3">-->
          <!--              <div class="card-header&#45;&#45;title font-size-lg">-->
          <!--                Teams-->
          <!--              </div>-->
          <!--            </div>-->
          <!--            <div class="card-body form-padding">-->
          <!--              <div class="form-wraper">-->
          <!--                <div formArrayName="teams" *ngFor="let teams of teamsGroup.controls; let i = index">-->
          <!--                  <div [formGroupName]="i">-->
          <!--                    <div class="row mb-3">-->
          <!--                      <div class="col-md-3 text-center">-->
          <!--                        <div class="profile-logo">-->
          <!--                          <img [src]="teams.get('profile_img').value || imageProfile"/>-->
          <!--                          <a href="" class="upload-img">-->
          <!--                            <button _ngcontent-c4="" (click)="activeFileInput(fileInputSocial);"-->
          <!--                                    class="outline-blue-btn upload-btn"-->
          <!--                                    type="button">Upload-->
          <!--                            </button>-->
          <!--                            <input type="file" class="file-input" (change)="onProfileChanged($event, i)"-->
          <!--                                   #fileInputSocial>-->
          <!--                          </a>-->
          <!--                        </div>-->
          <!--                      </div>-->
          <!--                      <div class="col-md-9">-->
          <!--                        <div class="company-block">-->
          <!--                          <div class="row">-->
          <!--                            <div class="company-block"></div>-->
          <!--                            <div class="col-md-6">-->
          <!--                              <div class="floating-label">-->
          <!--                                <input class="floating-input" type="text" formControlName="first_name" placeholder=" ">-->
          <!--                                <span class="highlight"></span>-->
          <!--                                <label>First Name-->
          <!--                                  &lt;!&ndash; <span class="red">*</span> &ndash;&gt;-->
          <!--                                </label>-->
          <!--                              </div>-->
          <!--                            </div>-->
          <!--                            <div class="col-md-6">-->
          <!--                              <div class="floating-label">-->
          <!--                                <input class="floating-input" type="text" formControlName="last_name" placeholder=" ">-->
          <!--                                <span class="highlight"></span>-->
          <!--                                <label>Last Name-->
          <!--                                  &lt;!&ndash; <span class="red">*</span> &ndash;&gt;-->
          <!--                                </label>-->
          <!--                              </div>-->
          <!--                            </div>-->
          <!--                            <div class="col-md-6">-->
          <!--                              <div class="floating-label">-->
          <!--                                <input class="floating-input" type="text" formControlName="job_title" placeholder=" ">-->
          <!--                                <span class="highlight"></span>-->
          <!--                                <label>Job Title-->
          <!--                                  &lt;!&ndash; <span class="red">*</span> &ndash;&gt;-->
          <!--                                </label>-->
          <!--                              </div>-->
          <!--                            </div>-->
          <!--                            <div class="col-md-6">-->
          <!--                              <div class="floating-label">-->
          <!--                                <input class="floating-input" type="text" formControlName="email" placeholder=" ">-->
          <!--                                <span class="highlight"></span>-->
          <!--                                <label>Email-->
          <!--                                  &lt;!&ndash; <span class="red">*</span> &ndash;&gt;-->
          <!--                                </label>-->
          <!--                                <a (click)="addTeamsButtonClick()" class="pointer"-->
          <!--                                   *ngIf="teamsGroup.controls.length-1 === i">-->
          <!--                                  <i class="fa fa-plus-circle add-btn"></i>-->
          <!--                                </a>-->
          <!--                                <a (click)="removeTeamsButtonClick(i)" class="pointer"-->
          <!--                                   *ngIf="!(teamsGroup.controls.length-1 === i)">-->
          <!--                                  <i class="fa fa-times-circle remove-btn"></i>-->
          <!--                                </a>-->
          <!--                              </div>-->
          <!--                            </div>-->
          <!--                          </div>-->
          <!--                        </div>-->
          <!--                      </div>-->
          <!--                    </div>-->

          <!--                  </div>-->

          <!--                </div>-->
          <!--              </div>-->
          <!--            </div>-->
          <!--          </div>-->

          <!--          CUSTOM VARIABLES-->
          <div class="card card-inline">
            <div class="card-header py-3">
              <div class="card-header--title font-size-lg">
                Custom Variables
                <app-info-popover
                  info="Custom variables can be used to easily customize certain aspects of the campaign pages, campaign emails, etc">
                </app-info-popover>
              </div>
            </div>
            <div class="card-body form-padding p-0">
              <div class="form-wraper">
                <div class="row">
                  <div class="col-lg-6 col-md-6 col-ms-12">
                    <div class="floating-label input-group input-group-sm variable-input">
                      <input class="floating-input" formControlName="custom_1"
                             [ngClass]="{ 'is-invalid': submitted && f.custom_1.errors }"
                             type="text" placeholder=" ">
                      <span class="highlight"></span>
                      <label>Custom 1</label>
                      <div class="bar error-brd" *ngIf="submitted && f.custom_1.errors">
                        <div class="invalid-text" *ngIf="f.custom_1.errors">Enter valid value</div>
                      </div>
<!--                      <app-variables-->
<!--                        [variables]="variables"-->
<!--                        (onVariableClick)="updateValue($event, 'custom_1')"-->
<!--                      ></app-variables>-->
<!--                      <app-file-browser-->
<!--                        [clientId]="project?.client_id"-->
<!--                        [projectId]="project?.id">-->
<!--                      </app-file-browser>-->
                    </div>
                  </div>
                  <div class="col-lg-6 col-md-6 col-ms-12">
                    <div class="floating-label input-group input-group-sm variable-input">
                      <input class="floating-input" formControlName="custom_2"
                             [ngClass]="{ 'is-invalid': submitted && f.custom_2.errors }"
                             type="text" placeholder=" ">
                      <span class="highlight"></span>
                      <label>Custom 2</label>
                      <div class="bar error-brd" *ngIf="submitted && f.custom_2.errors">
                        <div class="invalid-text" *ngIf="f.custom_2.errors">Enter valid value</div>
                      </div>
<!--                      <app-variables-->
<!--                        [variables]="variables"-->
<!--                        (onVariableClick)="updateValue($event, 'custom_2')"-->
<!--                      ></app-variables>-->
<!--                      <app-file-browser-->
<!--                        [clientId]="project?.client_id"-->
<!--                        [projectId]="project?.id">-->
<!--                      </app-file-browser>-->
                    </div>
                  </div>
                  <div class="col-lg-6 col-md-6 col-ms-12">
                    <div class="floating-label input-group input-group-sm variable-input">
                      <input class="floating-input" formControlName="custom_3"
                             [ngClass]="{ 'is-invalid': submitted && f.custom_3.errors }"
                             type="text" placeholder=" ">
                      <span class="highlight"></span>
                      <label>Custom 3</label>
                      <div class="bar error-brd" *ngIf="submitted && f.custom_3.errors">
                        <div class="invalid-text" *ngIf="f.custom_3.errors">Enter valid value</div>
                      </div>
<!--                      <app-variables-->
<!--                        [variables]="variables"-->
<!--                        (onVariableClick)="updateValue($event, 'custom_3')"-->
<!--                      ></app-variables>-->
<!--                      <app-file-browser-->
<!--                        [clientId]="project?.client_id"-->
<!--                        [projectId]="project?.id">-->
<!--                      </app-file-browser>-->
                    </div>
                  </div>
                  <div class="col-lg-6 col-md-6 col-ms-12">
                    <div class="floating-label input-group input-group-sm variable-input">
                      <input class="floating-input" formControlName="custom_4"
                             [ngClass]="{ 'is-invalid': submitted && f.custom_4.errors }"
                             type="text" placeholder=" ">
                      <span class="highlight"></span>
                      <label>Custom 4</label>
                      <div class="bar error-brd" *ngIf="submitted && f.custom_4.errors">
                        <div class="invalid-text" *ngIf="f.custom_4.errors">Enter valid value</div>
                      </div>
<!--                      <app-variables-->
<!--                        [variables]="variables"-->
<!--                        (onVariableClick)="updateValue($event, 'custom_4')"-->
<!--                      ></app-variables>-->
<!--                      <app-file-browser-->
<!--                        [clientId]="project?.client_id"-->
<!--                        [projectId]="project?.id">-->
<!--                      </app-file-browser>-->
                    </div>
                  </div>
                  <div class="col-lg-6 col-md-6 col-ms-12">
                    <div class="floating-label input-group input-group-sm variable-input">
                      <input class="floating-input" formControlName="custom_5"
                             [ngClass]="{ 'is-invalid': submitted && f.custom_5.errors }"
                             type="text" placeholder=" ">
                      <span class="highlight"></span>
                      <label>Custom 5</label>
                      <div class="bar error-brd" *ngIf="submitted && f.custom_5.errors">
                        <div class="invalid-text" *ngIf="f.custom_5.errors">Enter valid value</div>
                      </div>
<!--                      <app-variables-->
<!--                        [variables]="variables"-->
<!--                        (onVariableClick)="updateValue($event, 'custom_5')"-->
<!--                      ></app-variables>-->
<!--                      <app-file-browser-->
<!--                        [clientId]="project?.client_id"-->
<!--                        [projectId]="project?.id">-->
<!--                      </app-file-browser>-->
                    </div>
                  </div>
                  <div class="col-lg-6 col-md-6 col-ms-12">
                    <div class="floating-label input-group input-group-sm variable-input">
                      <input class="floating-input" formControlName="custom_6"
                             [ngClass]="{ 'is-invalid': submitted && f.custom_6.errors }"
                             type="text" placeholder=" ">
                      <span class="highlight"></span>
                      <label>Custom 6</label>
                      <div class="bar error-brd" *ngIf="submitted && f.custom_6.errors">
                        <div class="invalid-text" *ngIf="f.custom_6.errors">Enter valid value</div>
                      </div>
<!--                      <app-variables-->
<!--                        [variables]="variables"-->
<!--                        (onVariableClick)="updateValue($event, 'custom_6')"-->
<!--                      ></app-variables>-->
<!--                      <app-file-browser-->
<!--                        [clientId]="project?.client_id"-->
<!--                        [projectId]="project?.id">-->
<!--                      </app-file-browser>-->
                    </div>
                  </div>
                  <div class="col-lg-6 col-md-6 col-ms-12">
                    <div class="floating-label input-group input-group-sm variable-input">
                      <input class="floating-input" formControlName="custom_7"
                             [ngClass]="{ 'is-invalid': submitted && f.custom_7.errors }"
                             type="text" placeholder=" ">
                      <span class="highlight"></span>
                      <label>Custom 7</label>
                      <div class="bar error-brd" *ngIf="submitted && f.custom_7.errors">
                        <div class="invalid-text" *ngIf="f.custom_7.errors">Enter valid value</div>
                      </div>
<!--                      <app-variables-->
<!--                        [variables]="variables"-->
<!--                        (onVariableClick)="updateValue($event, 'custom_7')"-->
<!--                      ></app-variables>-->
<!--                      <app-file-browser-->
<!--                        [clientId]="project?.client_id"-->
<!--                        [projectId]="project?.id">-->
<!--                      </app-file-browser>-->
                    </div>
                  </div>
                  <div class="col-lg-6 col-md-6 col-ms-12">
                    <div class="floating-label input-group input-group-sm variable-input">
                      <input class="floating-input" formControlName="custom_8"
                             [ngClass]="{ 'is-invalid': submitted && f.custom_8.errors }"
                             type="text" placeholder=" ">
                      <span class="highlight"></span>
                      <label>Custom 8</label>
                      <div class="bar error-brd" *ngIf="submitted && f.custom_8.errors">
                        <div class="invalid-text" *ngIf="f.custom_8.errors">Enter valid value</div>
                      </div>
<!--                      <app-variables-->
<!--                        [variables]="variables"-->
<!--                        (onVariableClick)="updateValue($event, 'custom_8')"-->
<!--                      ></app-variables>-->
<!--                      <app-file-browser-->
<!--                        [clientId]="project?.client_id"-->
<!--                        [projectId]="project?.id">-->
<!--                      </app-file-browser>-->
                    </div>
                  </div>
                  <div class="col-lg-6 col-md-6 col-ms-12">
                    <div class="floating-label input-group input-group-sm variable-input">
                      <input class="floating-input" formControlName="custom_9"
                             [ngClass]="{ 'is-invalid': submitted && f.custom_9.errors }"
                             type="text" placeholder=" ">
                      <span class="highlight"></span>
                      <label>Custom 9</label>
                      <div class="bar error-brd" *ngIf="submitted && f.custom_9.errors">
                        <div class="invalid-text" *ngIf="f.custom_9.errors">Enter valid value</div>
                      </div>
<!--                      <app-variables-->
<!--                        [variables]="variables"-->
<!--                        (onVariableClick)="updateValue($event, 'custom_9')"-->
<!--                      ></app-variables>-->
<!--                      <app-file-browser-->
<!--                        [clientId]="project?.client_id"-->
<!--                        [projectId]="project?.id">-->
<!--                      </app-file-browser>-->
                    </div>
                  </div>
                  <div class="col-lg-6 col-md-6 col-ms-12">
                    <div class="floating-label input-group input-group-sm variable-input">
                      <input class="floating-input" formControlName="custom_10"
                             [ngClass]="{ 'is-invalid': submitted && f.custom_10.errors }"
                             type="text" placeholder=" ">
                      <span class="highlight"></span>
                      <label>Custom 10</label>
                      <div class="bar error-brd" *ngIf="submitted && f.custom_10.errors">
                        <div class="invalid-text" *ngIf="f.custom_10.errors">Enter valid value</div>
                      </div>
<!--                      <app-variables-->
<!--                        [variables]="variables"-->
<!--                        (onVariableClick)="updateValue($event, 'custom_10')"-->
<!--                      ></app-variables>-->
<!--                      <app-file-browser-->
<!--                        [clientId]="project?.client_id"-->
<!--                        [projectId]="project?.id">-->
<!--                      </app-file-browser>-->
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div _ngcontent-c4="" class="text-right mt-3 mb-3">
            <button _ngcontent-c4="" class="blue-btn mr-2" type="submit">
              Save
            </button>
          </div>
        </div>
      </div>
    </form>
  </div>
</div>
