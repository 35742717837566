<div *ngIf="isShowStatusBar" class="app-page-title mb-5" [style]="customStyle">
  <div class="card pt-3 pb-3" style="z-index: 0;">
    <div class="row align-items-center">
      <div class="col-3">
        <div class="campaign-name">
          <h3 class="campaign-title">{{statusBarHeader()}}</h3>
        </div>
        <div class="ml-2">
          <app-campaign-status *ngIf="campaign" [campaign]="campaign" [projectId]="campaign.project_id"></app-campaign-status>
        </div>
      </div>
      <div [ngClass]="{'col-6': showUndoButton, 'col-7': !showUndoButton}">
        <div class="progressbar-container">
          <ul class="progressbar">
            <li *ngFor="let item of mapClassNames()" [ngClass]="item.class">
              <a routerLink="{{item.link(step.params)}}" [queryParams]="item.query(step.params)" [ngClass]="item.class">
                {{item.title}}
              </a>
            </li>
          </ul>
        </div>
      </div>
      <div [ngClass]="{'col-3': showUndoButton, 'col-2': !showUndoButton}">
        <div class="save-button-container" [ngClass]="{'d-flex': showUndoButton}">
          <button *ngIf="showUndoButton" [disabled]="disableUndoButton" (click)="runUndoButtonClick()" class="btn btn-default btn-sm">
            <span><i class="fa fa-spinner fa-spin mr-2 d-block" *ngIf="disableUndoButton"></i>Undo</span>
          </button>
          <button *ngIf="SaveButtonClick != null" [disabled]="loading" (click)="runSaveButtonClick()" class="btn btn-default btn-sm">
            <span><i class="fa fa-spinner fa-spin mr-2 d-block" *ngIf="loading"></i>{{isLaunched?'Close':'Save & Exit'}}</span>
          </button>
        </div>
      </div>
    </div>
  </div>
</div>
