<h1 mat-dialog-title>Resize the image</h1>
<div mat-dialog-content>
    <div class="crop-image-container">
        <angular-cropper
            #angularCropper
            [cropperOptions]="cropConfig"
            [imageUrl]="file"
        ></angular-cropper>
    </div>

    <!--CROPPER CONTROLS-->
    <div class="crop-controls flex flex-wrap justify-center items-center">
        <button
            mat-button
            (click)="setLandscape()"
            class="mat-icon-button"
            aria-label="Landscape picture"
            mdTooltip="Landscape picture"
            [hidden]="!cropConfig?.landscape"
        >
            <mat-icon>crop_landscape</mat-icon>
        </button>

        <button
            mat-button
            (click)="setPortrait()"
            class="mat-icon-button"
            aria-label="Portrait picture"
            mdTooltip="Portrait picture"
            [hidden]="!cropConfig?.portrait"
        >
            <mat-icon>crop_portrait</mat-icon>
        </button>

        <button
            mat-button
            (click)="zoomIn()"
            class="mat-icon-button"
            aria-label="Aumentar zoom"
            mdTooltip="Aumentar zoom"
        >
            <mat-icon>zoom_in</mat-icon>
        </button>

        <button
            mat-button
            (click)="zoomOut()"
            class="mat-icon-button"
            aria-label="Diminuir zoom"
            mdTooltip="Diminuir zoom"
        >
            <mat-icon>zoom_out</mat-icon>
        </button>

        <button
            mat-button
            (click)="goLeft()"
            class="mat-icon-button"
            aria-label="Mover para esquerda"
            mdTooltip="Mover para esquerda"
        >
            <mat-icon>keyboard_arrow_left</mat-icon>
        </button>

        <button
            mat-button
            (click)="goRight()"
            class="mat-icon-button"
            aria-label="Mover para direita"
            mdTooltip="Mover para direita"
        >
            <mat-icon>keyboard_arrow_right</mat-icon>
        </button>

        <button
            mat-button
            (click)="goUp()"
            class="mat-icon-button"
            aria-label="Mover para cima"
            mdTooltip="Mover para cima"
        >
            <mat-icon>keyboard_arrow_up</mat-icon>
        </button>

        <button
            mat-button
            (click)="goDown()"
            class="mat-icon-button"
            aria-label="Mover para baixo"
            mdTooltip="Mover para baixo"
        >
            <mat-icon>keyboard_arrow_down</mat-icon>
        </button>

        <button
            mat-button
            (click)="reset()"
            class="mat-icon-button mat-primary"
            aria-label="Restaurar imagem original"
            mdTooltip="Restaurar imagem original"
        >
            <mat-icon>cached</mat-icon>
        </button>
    </div>
</div>

<div class="complete-btn flex">
    <button
        class="mat-btn-custom"
        mat-flat-button
        color="primary"
        (click)="finish()"
    >
        Done
    </button>
</div>