import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ImageCropperDialogComponent } from './image-cropper-dialog.component';
import { AngularCropperjsModule } from 'angular-cropperjs';
import { MatIconModule } from '@angular/material/icon';
import { MatLegacyButtonModule as MatButtonModule } from '@angular/material/legacy-button';
import { MatLegacyDialogModule as MatDialogModule } from '@angular/material/legacy-dialog';

@NgModule({
    declarations: [ImageCropperDialogComponent],
    imports: [CommonModule, MatIconModule, MatButtonModule, MatDialogModule, AngularCropperjsModule],
    exports: [ImageCropperDialogComponent]
})
export class ImageCropperDialogModule {}
