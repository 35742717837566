import {createFeatureSelector, createSelector} from '@ngrx/store';
import {IUsersState} from '@app/stores';

const selectUser = createFeatureSelector<IUsersState>('users');

export const getUser = createSelector(selectUser, (state: IUsersState) => state.user);

export const getUserSuccess = createSelector(selectUser, (state: IUsersState) => state.success);

export const getUpdateUser = createSelector(selectUser, (state: IUsersState) => state.updateUser);

export const getUserError = createSelector(selectUser, (state: IUsersState) => state.error);

export const getUsers = createSelector(selectUser, (state: IUsersState) => state.users);

export const getUsersPaginated = createSelector(selectUser, (state: IUsersState) => state.paginatedUsers);

export const getUsersRoles = createSelector(selectUser, (state: IUsersState) => state.roles);

export const getUserLoginInfoMail = createSelector(selectUser, (state: IUsersState) => state.success);
