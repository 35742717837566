import {createAction, props} from '@ngrx/store';
import {User} from '@app/modules/shared/models';

enum EAuthenticationActions {
  LoginUser = '[Authentication] Login User',
  LoginSuccess = '[Authentication] Login Success',
  LoginError = '[Authentication] Login Error',
  LogoutUser = '[Authentication] Logout User',
  TokenLogin = '[Authentication] Token Login',
  ResetPasswordToken = '[Authentication] Reset Password Token',
  FORGOT_PASSWORD = '[Authentication] Forget Password',
  FORGOT_PASSWORD_SUCCESS = '[Authentication] Forget Password Success',
  FORGOT_PASSWORD_ERROR = '[Authentication] Forget Password Error',
  RESET_PASSWORD = '[Authentication] Reset Password',
  RESET_PASSWORD_SUCCESS = '[Authentication] Reset Password Success',
  RESET_PASSWORD_ERROR = '[Authentication] Reset Password Error',
  REGISTER = '[Authentication] Register',
  REGISTER_SUCCESS = '[Authentication] Register Success',
  REGISTER_ERROR = '[Authentication] Register Error',
  LoginUserWithId = '[Authentication] Login User With Id',
  LoginUserWithIdSuccess = '[Authentication] Login User With Id Success',
  LoginUserWithIdError = '[Authentication] Login User With Id Error',

  ResetAuthState = '[Authentication] Reset Auth State',
}

export const LoginUser = createAction(EAuthenticationActions.LoginUser, props<{ credentials: any }>());
export const LoginSuccess = createAction(EAuthenticationActions.LoginSuccess, props<{ user: User }>());
export const LoginError = createAction(EAuthenticationActions.LoginError, props<{ error: string }>());
export const LogoutUser = createAction(EAuthenticationActions.LogoutUser);
export const TokenLogin = createAction(EAuthenticationActions.TokenLogin, props<{ token: string }>());
export const ResetPasswordToken = createAction(EAuthenticationActions.ResetPasswordToken, props<{ token: string }>());

export const ForgetPassword = createAction(EAuthenticationActions.FORGOT_PASSWORD, props<{ email: string }>());
export const ForgetPasswordSuccess = createAction(EAuthenticationActions.FORGOT_PASSWORD_SUCCESS, props<{ forgetPassword: any }>());
export const ForgetPasswordError = createAction(EAuthenticationActions.FORGOT_PASSWORD_ERROR, props<{ error: string }>());

export const ResetPassword = createAction(EAuthenticationActions.RESET_PASSWORD, props<{ password: string, userId: string }>());
export const ResetPasswordSuccess = createAction(EAuthenticationActions.RESET_PASSWORD_SUCCESS, props<{ resetPassword: any }>());
export const ResetPasswordError = createAction(EAuthenticationActions.RESET_PASSWORD_ERROR, props<{ error: string }>());

export const Register = createAction(EAuthenticationActions.REGISTER, props<{ user: User }>());
export const RegisterSuccess = createAction(EAuthenticationActions.REGISTER_SUCCESS, props<{ register: any }>());
export const RegisterError = createAction(EAuthenticationActions.REGISTER_ERROR, props<{ error: string }>());

export const LoginUserWithId = createAction(EAuthenticationActions.LoginUserWithId, props<{ userId: number }>());
export const LoginUserWithIdSuccess = createAction(EAuthenticationActions.LoginUserWithIdSuccess, props<{ user: User }>());
export const LoginUserWithIdError = createAction(EAuthenticationActions.LoginUserWithIdError, props<{ error: string }>());

export const ResetAuthState = createAction(EAuthenticationActions.ResetAuthState, (params: any = {}) => params);
