<div class="modal-header">
  <h4 class="modal-title" id="modal-title">Copy campaign</h4>
  <button type="button" class="close" aria-label="Close button" aria-describedby="modal-title"
          (click)="modal.dismiss('cancel')">
    <span aria-hidden="true">&times;</span>
  </button>
</div>
<div class="modal-body">
  <form [formGroup]="campaignForm">
    <div class="row">
      <div class="col-md-12 mb-2">
        <div class="floating-label">
          <input placeholder=" "
                 [ngClass]="{ 'is-invalid': submitted && f.name.errors }"
                 class="floating-input" type="text" formControlName="name">
          <span class="highlight"></span>
          <label>Campaign name <span class="red">*</span></label>
          <div class="bar error-brd" *ngIf="submitted && f.name.errors">
            <div class="invalid-text" *ngIf="f.name.errors.required">Campaing name is required</div>
          </div>
        </div>
      </div>
      <div class="col-md-12 mb-2">
        <div class="floating-label">

          <app-auto-complete-select
            [hideLabel]="true"
            [isCreatable]="false"
            [onlyBottomBorder]="false"
            [placeholder]="'Select Project'"
            [selectOptions]="projects"
            [labelKey] = "'title'"
            (searchValue)="handleSearchValue($event)"
            (optionSelected)="handleProjectSelection($event)"
            [(selectedValue)]="projectId"
          ></app-auto-complete-select>
          <span class="highlight"></span>
          <div class="bar error-brd" *ngIf="submitted && f.project_id.errors">
            <div class="invalid-text" *ngIf="f.project_id.errors.required">Project is required</div>
          </div>
        </div>
      </div>
      <div class="col-md-12 customize-email d-flex align-items-center">
        <input id="with_influencers" placeholder="" type="checkbox" formControlName="with_influencers">
        <label for="with_influencers">Same influencer type and segment</label>
      </div>
    </div>
  </form>
</div>
<input type="hidden" ngbAutofocus>
<div class="modal-footer">
  <button type="button" class="btn btn-outline-secondary" (click)="modal.dismiss('cancel')">Cancel</button>
  <button type="button" class="btn btn-primary" (click)="saveProject()">Save</button>
</div>
