import {Component, OnInit} from '@angular/core';
import {Segment} from '@app/modules/shared/models/segment';
import {NgbActiveModal} from '@ng-bootstrap/ng-bootstrap';
import {InfluencerType} from '@app/modules/shared/models';
import {UntypedFormBuilder, UntypedFormGroup, ValidationErrors, Validators} from '@angular/forms';

@Component({
  selector: 'app-segment-add',
  templateUrl: './segment-add.component.html',
  styleUrls: ['./segment-add.component.css']
})
export class SegmentAddComponent implements OnInit {

  public segment: Segment;
  public influencerTypes: InfluencerType[] = [];
  public isUpdate = false;
  submitted = false;
  segmentForm: UntypedFormGroup;
  selectedInfluencer;

  constructor(
    public modal: NgbActiveModal,
    private formBuilder: UntypedFormBuilder
  ) {
  }

  ngOnInit(): void {
    this.createSegmentForm();
    this.segmentForm.reset();
    if (this.isUpdate) {
      this.segmentForm.controls['influencer_type_id'].setValue(this.segment.influencer_type_id);
      this.segmentForm.controls['name'].setValue(this.segment.name);
    } else {
      this.segmentForm.controls['influencer_type_id'].setValue(this.selectedInfluencer);
    }
  }

  createSegmentForm() {
    this.segmentForm = this.formBuilder.group({
      influencer_type_id: ['', Validators.required],
      name: ['', Validators.required]
    });
  }

  get f() {
    return this.segmentForm.controls;
  }

  selectInfluencerType(event) {
    this.segment.influencer_type_id = null;
    this.segmentForm.controls['influencer_type_id'].setValue(null);
    if (event) {
      this.segment.influencer_type_id = event.value;
      this.segmentForm.controls['influencer_type_id'].setValue(event.value);
    }
  }

  saveSegment() {
    this.submitted = true;
    if (this.segmentForm.invalid) {
      return;
    }
    this.modal.close(this.segmentForm.value);
  }
}
