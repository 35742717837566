import {createAction, props} from '@ngrx/store';
import {Influencer, InfluencerType, PaginatedInfluencers, ProjectInfluencerSegment} from '@app/modules/shared/models';

enum EInfluencerActions {
  InfluencersList = '[Influencers] Influencers List',
  InfluencersListSuccess = '[Influencers] Influencers List Success',
  InfluencersListError = '[Influencers] Influencers List Error',
  InfluencersListByProject = '[Influencers] Influencers List By Project',
  InfluencersListByProjectSuccess = '[Influencers] Influencers List By Project Success',
  InfluencersListByProjectError = '[Influencers] Influencers List By Project Error',
  InfluencersProjectSegment = '[Influencers] Influencers Project Segment',
  InfluencersProjectSegmentSuccess = '[Influencers] Influencers Project Segment Success',
  InfluencersProjectSegmentError = '[Influencers] Influencers Project Segment Error',
  InfluencersSegmentUpdate = '[Influencers] Influencers Segment Update',
  InfluencersSegmentUpdateSuccess = '[Influencers] Influencers Segment Update Success',
  InfluencersSegmentUpdateError = '[Influencers] Influencers Segment Update Error',
  InfluencersValidateImport = '[Influencers] Influencers Validate Import',
  InfluencersValidateImportSuccess = '[Influencers] Influencers Validate Import Success',
  InfluencersValidateImportError = '[Influencers] Influencers Validate Import Error',
  InfluencersCsvToValues = '[Influencers] Influencers Csv To Values',
  InfluencersCsvToValuesSuccess = '[Influencers] Influencers Csv To Values Success',
  InfluencersCsvToValuesError = '[Influencers] Influencers Csv To Values Error',
  InfluencersImportHeaders = '[Influencers] Influencers Import Headers',
  InfluencersImportHeadersSuccess = '[Influencers] Influencers Import Headers Success',
  InfluencersImportHeadersError = '[Influencers] Influencers Import Headers Error',
  InfluencersImport = '[Influencers] Influencers Import',
  InfluencersImportSuccess = '[Influencers] Influencers Import Success',
  InfluencersImportError = '[Influencers] Influencers Import Error',
  InfluencersUpdate = '[Influencers] Influencers Update',
  InfluencersUpdateSuccess = '[Influencers] Influencers Update Success',
  InfluencersUpdateError = '[Influencers] Influencers Update Error',
  InfluencersAdd = '[Influencers] Influencers Add',
  InfluencersAddSuccess = '[Influencers] Influencers Add Success',
  InfluencersAddError = '[Influencers] Influencers Add Error',
  InfluencersGet = '[Influencers] Influencers Get',
  InfluencersGetSuccess = '[Influencers] Influencers Get Success',
  InfluencersGetError = '[Influencers] Influencers Get Error',
  InfluencersTypeList = '[Influencers] Influencers Type List',
  InfluencersTypeListSuccess = '[Influencers] Influencers Type List Success',
  InfluencersTypeListError = '[Influencers] Influencers Type List Error',
  InfluencersDelete = '[Influencers] Influencers Delete',
  InfluencersDeleteSuccess = '[Influencers] Influencers Delete Success',
  InfluencersDeleteError = '[Influencers] Influencers Delete Error',
  InfluencersImportStatus = '[Influencers] Influencers Import Status',
  InfluencersImportStatusSuccess = '[Influencers] Influencers Import Status Success',
  InfluencersImportStatusError = '[Influencers] Influencers Import Status Error',
  InfluencersExport = '[Influencers] Influencers Export',
  InfluencersExportSuccess = '[Influencers] Influencers Export Success',
  InfluencersExportError = '[Influencers] Influencers Export Error',
  InfluencersReferralsList = '[Influencers] Influencers Referrals List',
  InfluencersReferralsListSuccess = '[Influencers] Influencers Referrals List Success',
  InfluencersReferralsListError = '[Influencers] Influencers Referrals List Error',
  InfluencersReferralsListPaginated = '[Influencers] Influencers Referrals List Paginated',
  InfluencersReferralsListPaginatedSuccess = '[Influencers] Influencers Referrals List Paginated Success',
  InfluencersReferralsListPaginatedError = '[Influencers] Influencers Referrals List Paginated Error',
  InfluencersListByProjectPaginate = '[Influencers] Paginate Influencers List By Project',
  InfluencersListByProjectPaginateSuccess = '[Influencers] Paginate Influencers List By Project Success',
  InfluencersListByProjectPaginateError = '[Influencers] Paginate Influencers List By Project Error',
  InfluencersTaskLogs = '[Influencers] Influencers Task Logs',
  InfluencersTaskLogsSuccess = '[Influencers] Influencers Task Logs Success',
  InfluencersTaskLogsError = '[Influencers] Influencers Task Logs Error',
  InfluencersBulk = '[Influencers] Influencers Bulk',
  InfluencersBulkSuccess = '[Influencers] Influencers Bulk Success',
  InfluencersBulkError = '[Influencers] Influencers Bulk Error',
  InfluencersBulkStatus = '[Influencers] Influencers Bulk Status',
  InfluencersBulkStatusSuccess = '[Influencers] Influencers Bulk Status Success',
  InfluencersBulkStatusError = '[Influencers] Influencers Bulk Status Error',
  InfluencersImportFields = '[Influencers] Influencers Import Fields',
  InfluencersImportFieldsSuccess = '[Influencers] Influencers Import Fields Success',
  InfluencersImportFieldsError = '[Influencers] Influencers Import Fields Error',

  ResetInfluencerState = '[Influencers] Reset Influencers State',
}

export const InfluencersList = createAction(EInfluencerActions.InfluencersList);
export const InfluencersListSuccess = createAction(EInfluencerActions.InfluencersListSuccess, props<{ influencers: Influencer[] }>());
export const InfluencersListError = createAction(EInfluencerActions.InfluencersListError, props<{ error: string }>());

export const InfluencersListByProject = createAction(EInfluencerActions.InfluencersListByProject,
  props<{ projectId: number, campaignId?: number, options?: any }>());
export const InfluencersListByProjectSuccess = createAction(EInfluencerActions.InfluencersListByProjectSuccess,
  props<{ influencers: Influencer[] }>());
export const InfluencersListByProjectError = createAction(EInfluencerActions.InfluencersListByProjectError, props<{ error: string }>());

export const InfluencersListByProjectPaginate = createAction(EInfluencerActions.InfluencersListByProjectPaginate,
  (params: any = {}) => params);
export const InfluencersListByProjectPaginateSuccess = createAction(EInfluencerActions.InfluencersListByProjectPaginateSuccess,
  props<{ paginatedInfluencers: PaginatedInfluencers }>());
export const InfluencersListByProjectPaginateError = createAction(EInfluencerActions.InfluencersListByProjectPaginateError,
  props<{ error: string }>());

export const InfluencersProjectSegment = createAction(EInfluencerActions.InfluencersProjectSegment, props<{ projectId: number }>());
export const InfluencersProjectSegmentSuccess = createAction(EInfluencerActions.InfluencersProjectSegmentSuccess,
  props<{ segments: ProjectInfluencerSegment[] }>());
export const InfluencersProjectSegmentError = createAction(EInfluencerActions.InfluencersProjectSegmentError, props<{ error: string }>());

export const InfluencersSegmentUpdate = createAction(EInfluencerActions.InfluencersSegmentUpdate,
  props<{ oldGroup: any, oldName: any, newName: any }>());
export const InfluencersSegmentUpdateSuccess = createAction(EInfluencerActions.InfluencersSegmentUpdateSuccess);
export const InfluencersSegmentUpdateError = createAction(EInfluencerActions.InfluencersSegmentUpdateError, props<{ error: string }>());

export const InfluencersValidateImport = createAction(EInfluencerActions.InfluencersValidateImport, props<{ file: any }>());
export const InfluencersValidateImportSuccess = createAction(EInfluencerActions.InfluencersValidateImportSuccess,
  props<{ influencersImport: any }>());
export const InfluencersValidateImportError = createAction(EInfluencerActions.InfluencersValidateImportError, props<{ error: string }>());

export const InfluencersCsvToValues = createAction(EInfluencerActions.InfluencersCsvToValues, props<{ file: any }>());
export const InfluencersCsvToValuesSuccess = createAction(EInfluencerActions.InfluencersCsvToValuesSuccess, props<{ influencersCsvToValues: any }>());
export const InfluencersCsvToValuesError = createAction(EInfluencerActions.InfluencersCsvToValuesError, props<{ error: string }>());

export const InfluencersImportHeaders = createAction(EInfluencerActions.InfluencersImportHeaders, props<{ data: any }>());
export const InfluencersImportHeadersSuccess = createAction(EInfluencerActions.InfluencersImportHeadersSuccess, props<{ influencersImportHeaders: any }>());
export const InfluencersImportHeadersError = createAction(EInfluencerActions.InfluencersImportHeadersError, props<{ error: string }>());

export const InfluencersImport = createAction(EInfluencerActions.InfluencersImport, props<{ file: any }>());
export const InfluencersImportSuccess = createAction(EInfluencerActions.InfluencersImportSuccess, props<{ influencersImport: any }>());
export const InfluencersImportError = createAction(EInfluencerActions.InfluencersImportError, props<{ error: string }>());

export const InfluencersUpdate = createAction(EInfluencerActions.InfluencersUpdate,
  props<{ influencerId: number, influencer: Influencer }>());
export const InfluencersUpdateSuccess = createAction(EInfluencerActions.InfluencersUpdateSuccess, props<{ influencer: Influencer }>());
export const InfluencersUpdateError = createAction(EInfluencerActions.InfluencersUpdateError, props<{ error: string }>());

export const InfluencersAdd = createAction(EInfluencerActions.InfluencersAdd, props<{ influencer: Influencer }>());
export const InfluencersAddSuccess = createAction(EInfluencerActions.InfluencersAddSuccess);
export const InfluencersAddError = createAction(EInfluencerActions.InfluencersAddError, props<{ error: string }>());

export const InfluencersGet = createAction(EInfluencerActions.InfluencersGet, props<{ influencerId: number }>());
export const InfluencersGetSuccess = createAction(EInfluencerActions.InfluencersGetSuccess, props<{ influencer: Influencer }>());
export const InfluencersGetError = createAction(EInfluencerActions.InfluencersGetError, props<{ error: string }>());

export const InfluencersTypeList = createAction(EInfluencerActions.InfluencersTypeList, (params: any = {}) => params);
export const InfluencersTypeListSuccess = createAction(EInfluencerActions.InfluencersTypeListSuccess,
  props<{ influencerTypes: InfluencerType[] }>());
export const InfluencersTypeListError = createAction(EInfluencerActions.InfluencersTypeListError, props<{ error: string }>());

export const InfluencersDelete = createAction(EInfluencerActions.InfluencersDelete, props<{ influencerId: number }>());
export const InfluencersDeleteSuccess = createAction(EInfluencerActions.InfluencersDeleteSuccess);
export const InfluencersDeleteError = createAction(EInfluencerActions.InfluencersDeleteError, props<{ error: string }>());

export const InfluencersImportStatus = createAction(EInfluencerActions.InfluencersImportStatus, props<{ importJobId: number }>());
export const InfluencersImportStatusSuccess = createAction(EInfluencerActions.InfluencersImportStatusSuccess,
  props<{ influencersImport: any }>());
export const InfluencersImportStatusError = createAction(EInfluencerActions.InfluencersImportStatusError, props<{ error: string }>());

export const InfluencersBulkStatus = createAction(EInfluencerActions.InfluencersBulkStatus, props<{ importJobId: number }>());
export const InfluencersBulkStatusSuccess = createAction(EInfluencerActions.InfluencersBulkStatusSuccess,
  props<{ influencerBulk: any }>());
export const InfluencersBulkStatusError = createAction(EInfluencerActions.InfluencersBulkStatusError, props<{ error: string }>());

export const InfluencersExport = createAction(EInfluencerActions.InfluencersExport, props<{ data: any }>());
export const InfluencersExportSuccess = createAction(EInfluencerActions.InfluencersExportSuccess, props<{ influencersExport: any }>());
export const InfluencersExportError = createAction(EInfluencerActions.InfluencersExportError, props<{ error: string }>());

export const InfluencersReferralsList = createAction(EInfluencerActions.InfluencersReferralsList, props<{ influencerId: number }>());
export const InfluencersReferralsListSuccess = createAction(EInfluencerActions.InfluencersReferralsListSuccess,
  props<{ referralsList: Influencer[] }>());
export const InfluencersReferralsListError = createAction(EInfluencerActions.InfluencersReferralsListError, props<{ error: string }>());

export const InfluencersReferralsListPaginated = createAction(EInfluencerActions.InfluencersReferralsListPaginated, props<{
  influencerId: number,
  params?: any
}>());
export const InfluencersReferralsListPaginatedSuccess = createAction(EInfluencerActions.InfluencersReferralsListPaginatedSuccess,
  props<{ paginatedReferralsList: PaginatedInfluencers }>());
export const InfluencersReferralsListPaginatedError = createAction(EInfluencerActions.InfluencersReferralsListPaginatedError, props<{ error: string }>());

export const InfluencersTaskLogs = createAction(EInfluencerActions.InfluencersTaskLogs, (params: any = {}) => params);
export const InfluencersTaskLogsSuccess = createAction(EInfluencerActions.InfluencersTaskLogsSuccess, props<{ influencersTaskLogs: any }>());
export const InfluencersTaskLogsError = createAction(EInfluencerActions.InfluencersTaskLogsError, props<{ error: string }>());

export const InfluencersBulk = createAction(EInfluencerActions.InfluencersBulk, props<{ data: any }>());
export const InfluencersBulkSuccess = createAction(EInfluencerActions.InfluencersBulkSuccess, props<{ influencerBulk: any }>());
export const InfluencersBulkError = createAction(EInfluencerActions.InfluencersBulkError, props<{ error: string }>());

export const InfluencersImportFields = createAction(EInfluencerActions.InfluencersImportFields, props<{ influencerTypeId: number }>());
export const InfluencersImportFieldsSuccess = createAction(EInfluencerActions.InfluencersImportFieldsSuccess, props<{ influencersImportFields: any }>());
export const InfluencersImportFieldsError = createAction(EInfluencerActions.InfluencersImportFieldsError, props<{ error: string }>());

export const ResetInfluencerState = createAction(EInfluencerActions.ResetInfluencerState, (params: any = {}) => params);
