import {Injectable} from '@angular/core';
import {Actions, createEffect, ofType} from '@ngrx/effects';
import {Store} from '@ngrx/store';
import {catchError, map, switchMap} from 'rxjs/operators';
import {of} from 'rxjs';
import {LoaderService} from '@app/services/loader.service';
import {
  SettingsList,
  SettingsListSuccess,
  SettingsListError,
  SettingDelete,
  SettingDeleteSuccess,
  SettingDeleteError,
  SettingUpdate,
  SettingUpdateSuccess,
  SettingUpdateError,
  SettingAdd,
  SettingAddSuccess,
  SettingAddError,
  SettingsListAll,
  SettingsListAllSuccess,
  SettingsListAllError,
  SettingGet,
  SettingGetSuccess,
  SettingGetError,
  SettingCacheClear,
  SettingCacheClearSuccess,
  SettingCacheClearError
} from '@app/stores/settings/settings.actions';
import {ISettingsState} from '@app/stores/settings/settings.state';
import {SettingsService} from '@app/services/settings.service';

@Injectable()
export class SettingsEffects {
  constructor(
    private _actions$: Actions,
    private _store: Store<ISettingsState>,
    private settingsService: SettingsService,
    private loaderService: LoaderService
  ) {
  }

  settingsAllList$ = createEffect(() => this._actions$.pipe(
    ofType(SettingsListAll),
    switchMap((action) => {
      this.loaderService.show();
      return this.settingsService.getSettings(action.params).pipe(
        map((resp: any) => {
          if (resp.success) {
            this.loaderService.hide();
            return SettingsListAllSuccess({settings: resp.data});
          }
          this.loaderService.hide(true);
          return SettingsListAllError({error: this.loaderService.getErrorMessage(resp)});
        }),
        catchError(error => {
          this.loaderService.hide(true);
          return of(SettingsListAllError({error: this.loaderService.getErrorMessage(error)}));
        })
      );
    })
  ));

  settingsList$ = createEffect(() => this._actions$.pipe(
    ofType(SettingsList),
    switchMap((action) => {
      this.loaderService.show();
      return this.settingsService.getSettings(action.params).pipe(
        map((resp: any) => {
          if (resp.success) {
            this.loaderService.hide();
            return SettingsListSuccess({paginatedSettings: resp.data});
          }
          this.loaderService.hide(true);
          return SettingsListError({error: this.loaderService.getErrorMessage(resp)});
        }),
        catchError(error => {
          this.loaderService.hide(true);
          return of(SettingsListError({error: this.loaderService.getErrorMessage(error)}));
        })
      );
    })
  ));

  settingDelete$ = createEffect(() => this._actions$.pipe(
    ofType(SettingDelete),
    switchMap((action) => {
      this.loaderService.show();
      return this.settingsService.deleteSetting(action.settingId).pipe(
        map((resp: any) => {
          if (resp.success) {
            this.loaderService.hide();
            return SettingDeleteSuccess();
          }
          this.loaderService.hide(true);
          return SettingDeleteError({error: this.loaderService.getErrorMessage(resp)});
        }),
        catchError(error => {
          this.loaderService.hide(true);
          return of(SettingDeleteError({error: this.loaderService.getErrorMessage(error)}));
        })
      );
    })
  ));

  settingUpdate$ = createEffect(() => this._actions$.pipe(
    ofType(SettingUpdate),
    switchMap((action) => {
      this.loaderService.show();
      return this.settingsService.updateSetting(action.settingId, action.setting).pipe(
        map((resp: any) => {
          if (resp.success) {
            this.loaderService.hide();
            return SettingUpdateSuccess();
          }
          this.loaderService.hide(true);
          return SettingUpdateError({error: this.loaderService.getErrorMessage(resp)});
        }),
        catchError(error => {
          this.loaderService.hide(true);
          return of(SettingUpdateError({error: this.loaderService.getErrorMessage(error)}));
        })
      );
    })
  ));

  settingAdd$ = createEffect(() => this._actions$.pipe(
    ofType(SettingAdd),
    switchMap((action) => {
      this.loaderService.show();
      return this.settingsService.addSetting(action.setting).pipe(
        map((resp: any) => {
          if (resp.success) {
            this.loaderService.hide();
            return SettingAddSuccess();
          }
          this.loaderService.hide(true);
          return SettingAddError({error: this.loaderService.getErrorMessage(resp)});
        }),
        catchError(error => {
          this.loaderService.hide(true);
          return of(SettingAddError({error: this.loaderService.getErrorMessage(error)}));
        })
      );
    })
  ));

  settingGet$ = createEffect(() => this._actions$.pipe(
    ofType(SettingGet),
    switchMap((action) => {
      this.loaderService.show();
      return this.settingsService.getSetting(action.settingId).pipe(
        map((resp: any) => {
          if (resp.success) {
            this.loaderService.hide();
            return SettingGetSuccess({setting: resp.data});
          }
          this.loaderService.hide(true);
          return SettingGetError({error: this.loaderService.getErrorMessage(resp)});
        }),
        catchError(error => {
          this.loaderService.hide(true);
          return of(SettingGetError({error: this.loaderService.getErrorMessage(error)}));
        })
      );
    })
  ));

  settingClearCache$ = createEffect(() => this._actions$.pipe(
    ofType(SettingCacheClear),
    switchMap((action) => {
      this.loaderService.show();
      return this.settingsService.clearCache(action.pattern).pipe(
        map((resp: any) => {
          if (resp.success) {
            this.loaderService.hide();
            return SettingCacheClearSuccess();
          }
          this.loaderService.hide(true);
          return SettingCacheClearError({error: this.loaderService.getErrorMessage(resp)});
        }),
        catchError(error => {
          this.loaderService.hide(true);
          return of(SettingCacheClearError({error: this.loaderService.getErrorMessage(error)}));
        })
      );
    })
  ));
}
