import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { LaunchConfirmationModalComponent } from './launch-confirmation-modal.component';
import {SharedModule} from '../shared/shared.module';

@NgModule({
  declarations: [LaunchConfirmationModalComponent],
    imports: [CommonModule, SharedModule],
})
export class LaunchConfirmationModalModule {}
