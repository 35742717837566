import {Component, Input, OnDestroy, OnInit} from '@angular/core';
import {NgbActiveModal, NgbModal} from "@ng-bootstrap/ng-bootstrap";
import {environment} from "@environments/environment";
import {ToastrService} from "ngx-toastr";
import {LoaderService} from "@app/services/loader.service";
import {MailVerifyService} from "@app/services/mail-verify.service";
import {Observable} from "rxjs/Observable";
import {select, Store} from "@ngrx/store";
import {getMailServiceProvider, IInfluencersState, IMailVerifyState, MailServiceProvider} from "@app/stores";
import {takeUntil} from "rxjs/operators";
import {Subject} from "rxjs";
import {Constants} from '@app/consts';
import {DomSanitizer,SafeResourceUrl,} from '@angular/platform-browser';

@Component({
  selector: 'app-verification',
  templateUrl: './verification.component.html',
  styleUrls: ['./verification.component.scss'],
  providers: [Constants]
})
export class VerificationComponent implements OnInit, OnDestroy {
  @Input() fromEmailVerified;
  @Input() emailSenderVerifyBy;
  @Input() fromEmail;
  @Input() tokens = [];
  @Input() emailSenderServiceProviderSlug;
  @Input() mailServiceProviders: any = [];
  shareWidgetLinkUrl = '';
  @Input() campaignCode;
  @Input() currentUser;
  @Input() mode;
  loader$: Observable<boolean>;
  unsubscriber = new Subject();
  domainProviderInstructions: any[] = [];
  selectedProvider = null;
  statusLoading = false;
  mxVerification: any;
  txtVerification: any;
  publicDomain: any = null;
  defaultDomain: any = null;
  records: any = {};
  urlWithIframe: any;
  verificationUrl: SafeResourceUrl;
  constructor(
    private _modalService: NgbModal,
    public modal: NgbActiveModal,
    private readonly constants: Constants,
    private toastr: ToastrService,
    private loaderService: LoaderService,
    private mailVerifyService: MailVerifyService,
    private influencerStore: Store<IInfluencersState>,
    private mailVerifyStore: Store<IMailVerifyState>,
    private sanitizer:DomSanitizer
  ) {
    this.domainProviderInstructions = this.constants.domainProviderInstruction;
    this.influencerStore.pipe(select(getMailServiceProvider))
      .pipe(takeUntil(this.unsubscriber))
      .subscribe(data => {
        if (data && data.length > 0) {
          // this.loaderService.hide();
          this.mailServiceProviders = data;
        }
      });
  }

  ngOnInit(): void {
    this.loader$ = this.loaderService.loader$;
    this.shareWidgetLinkUrl = environment.frontUrl.replace('/#', '') + `/b/campaign/${this.campaignCode}/domain_verify_instruction`;
    this.urlWithIframe = this.shareWidgetLinkUrl;
    if (this.mode) {
      this.urlWithIframe += '?mode=' + this.mode;
    }
    this.verificationUrl = this.sanitizer.bypassSecurityTrustResourceUrl(this.urlWithIframe);
    if (this.mailServiceProviders.length <= 0) {
      this.loaderService.show();
      this.mailVerifyStore.dispatch(MailServiceProvider({params: {}}));
    }

    this.selectMailProvider();
  }

  getDomain(identity: string) {
    const splitEmail = identity.split('@');
    return splitEmail[1];
  }

  async sendVerification(resend = false) {
    const verify_by = this.emailSenderVerifyBy;
    const service_provider = this.emailSenderServiceProviderSlug;
    setTimeout(() => { this.loaderService.show(); this.statusLoading = true; });
    try {

      const response: any = await this.mailVerifyService.verifySenderStatus({
        identity: this.fromEmail,
        userId: this.currentUser.id,
        campaignCode: this.campaignCode,
        ...(verify_by ? {verify_by} : {}),
        ...(service_provider ? {service_provider} : {}),
        ...(resend ? {resend} : {}),
      }).toPromise();
      if (response?.success !== true) throw 'Failed to validate "From Email", try again';
      const data = response.data;

      if (verify_by === 'email' && data?.identity?.verification_status === 'unverified' && data.mailSent && resend) {
        this.toastr.success('Domain Verification Email has been sent', 'Success');
      }

      const status: 'verified' | 'unverified' = data?.identity?.verification_status === 'verified' ? 'verified' : 'unverified';
      if (status === 'verified' || status === 'unverified') {
        this.fromEmailVerified = status;
      }

      // if (data?.tokens?.length && data?.identity?.identity && verify_by !== 'email') {
      //   this.tokens = data?.tokens;
      // }
      //
      // if (data?.mx && verify_by !== 'email' && data?.identity?.identity){
      //   this.mxVerification = data?.mx;
      // }
      //
      // if (data?.txt && verify_by !== 'email' && data?.identity?.identity){
      //   this.txtVerification = data?.txt;
      // }
      //
      // if (data?.identity.public_domain && verify_by !== 'email') {
      //   this.publicDomain = data?.identity.public_domain;
      // }
      //
      // if (data?.identity.default_domain && verify_by !== 'email') {
      //   this.defaultDomain = data?.identity.default_domain;
      // }

      setTimeout(() => { this.loaderService.hide(); this.statusLoading = false; });
    } catch (error) {
      this.toastr.error(this.loaderService.getErrorMessage(error), 'Error');
      setTimeout(() => { this.loaderService.hide(); this.statusLoading = false; });
    }
  }

  changeVerificationType(str) {
    this.modal.close({change: str});
  }

  async selectMailProvider(ev = null) {
    if (ev && ev.value) {
      this.emailSenderServiceProviderSlug = ev.value;
    }
    await this.sendVerification();
  }

  selectDomainProvider(ev = null) {
    this.selectedProvider = this.domainProviderInstructions.find(x => x.id === ev.value);
  }

  // copyToClipboard(text) {
  //   const input = document.createElement('textarea');
  //   input.value = text;
  //   document.body.appendChild(input);
  //   input.select();
  //   document.execCommand('Copy');
  //   this.toastr.success('Copied to clipboard!');
  //   input.remove();
  // }

  ngOnDestroy() {
    this.unsubscriber.next();
    this.unsubscriber.complete();
  }

}
