import {PaginatedSettings, Settings} from '@app/modules/shared/models/settings';

export interface ISettingsState {
  setting: Settings;
  settings: Settings[];
  paginatedSettings: PaginatedSettings;
  success: string;
  error: string;
}

export const initialSettingState: ISettingsState = {
  setting: null,
  settings: [],
  paginatedSettings: null,
  success: '',
  error: ''
};
