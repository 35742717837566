import {Component, Input, OnChanges, OnDestroy, OnInit, SimpleChanges} from '@angular/core';
import {SocketService} from '@app/services/socket.service';
import {Subscription} from 'rxjs';
import * as routes from '@app/routes';
import * as moment from 'moment-timezone';
import {ToastrService} from 'ngx-toastr';
import {smartDateFormat} from "@app/consts";

@Component({
  selector: 'app-campaign-status',
  templateUrl: './campaign-status.component.html',
  styleUrls: ['./campaign-status.component.css']
})
export class CampaignStatusComponent implements OnInit, OnDestroy, OnChanges {

  @Input() campaign: any = null;
  @Input() layoutType = 'list';
  @Input() projectId = null;
  @Input() currentUser = null;
  @Input() subscribeSocket = true;
  subscription: Subscription = new Subscription();
  routes = routes;
  _moment = moment;

  constructor(private readonly socketService: SocketService,
              private toastr: ToastrService) { }

  ngOnInit(): void {
    if (this.subscribeSocket) {
      this.subscribeToSocket();
    }
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  subscribeToSocket() {
    this.subscription = this.socketService.sourceData.subscribe((message: any) => {
        if (this.campaign && message?.data?.requesterId === this.campaign.id && message?.data?.requesterType === 'campaign') {
          this.campaign = Object.assign({}, this.campaign);
          this.campaign.progress = message.data.progress;
          this.campaign.status = message.data.status;

          if (message.data.progress >= 100) {
            delete this.campaign.progress;
            delete this.campaign.status_message;
            this.campaign.status = ['launching', 'draft'].includes(this.campaign.status) ? this.campaign.status : 'launched';
          }

          if (message.data.result) {
            if (message.data.result.campaignStatus === 'launch_failed') {
              delete this.campaign.progress;
              this.campaign.status = message.data.result.campaignStatus;
              this.campaign.status_message = message.data.result.statusMessage;
              this.toastr.error(message.data.result.statusMessage, 'Error');
            }
            if (message.data.result.hasOwnProperty('attemptsMade')) {
              this.campaign.attemptsMade = message.data.result.attemptsMade;
            }
            if (message.data.result.campaignStatus === 'launched') {
              if (message.data.result.launched_at) {
                this.campaign.launched_at = message.data.result.launched_at;
              }
              if (message.data.result.redirect) {
                window.open(message.data.result.redirect, '_blank');
              }
            }
          }
        }
      },
      (err) => console.error(err),
      () => console.warn('Completed!')
    );
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.campaign) {
      this.campaign = changes.campaign.currentValue;
    }
    if (changes.currentUser) {
      this.currentUser = changes.currentUser.currentValue;
    }
  }

  getCampaignTZDate(date) {
    return smartDateFormat(date, null, null, 'fullDateTimeWithTimeZone', this.campaign.timezone);
  }

  getStatusMessage(campaign) {
    if (this.currentUser && this.currentUser.user_type === '1' && campaign.attemptsMade > 1) {
      return `${campaign.status_message}, ${campaign.attemptsMade}/5 failed attempts. Auto retry every 15 minutes.`;
    } else {
      return `${campaign.status_message}`;
    }
  }

}
