import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import {AuthGuard} from '@app/_guards';
import {NonInfluencerGuard} from '@app/_guards/non-influencer.guard';
import {RegistrationPlatformListComponent} from '@app/modules/registration-platform/registration-platform-list/registration-platform-list.component';
import {RegistrationPlatformAddComponent} from '@app/modules/registration-platform/registration-platform-add/registration-platform-add.component';


const routes: Routes = [
  {
    path: '',
    children: [
      {
        path: 'settings/registration-platform',
        component: RegistrationPlatformListComponent,
        canActivate: [AuthGuard, NonInfluencerGuard]
      },
      {
        path: 'settings/registration-platform/add',
        component: RegistrationPlatformAddComponent,
        canActivate: [AuthGuard, NonInfluencerGuard]
      },
      {
        path: 'settings/registration-platform/edit/:id',
        component: RegistrationPlatformAddComponent,
        canActivate: [AuthGuard, NonInfluencerGuard]
      },
    ]
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class RegistrationPlatformRoutingModule { }
