import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {RouterModule, Routes} from '@angular/router';
import {ClientListComponent} from '@app/modules/client/client-list/client-list.component';
import {AuthGuard} from '@app/_guards';
import {ClientAddComponent} from '@app/modules/client/client-add/client-add.component';

const routes: Routes = [
  {
    path: '',
    children: [
      {
        path: '',
        component: ClientListComponent,
        canActivate: [AuthGuard]
      },
      {
        path: 'add',
        component: ClientAddComponent,
        canActivate: [AuthGuard]
      },
      {
        path: 'edit/:id',
        component: ClientAddComponent,
        canActivate: [AuthGuard]
      }
    ]
  }
];

@NgModule({
  imports: [CommonModule, RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class ClientRoutingModule {
}
