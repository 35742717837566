import {Component, Input, OnInit, ViewEncapsulation} from '@angular/core';

@Component({
  selector: 'app-warning-popover',
  templateUrl: './warning-popover.component.html',
  styleUrls: ['./warning-popover.component.css'],
  encapsulation: ViewEncapsulation.None
})
export class WarningPopoverComponent implements OnInit {

  @Input() warning;
  @Input() container;

  constructor() {
  }

  ngOnInit(): void {
  }

}
