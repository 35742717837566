import {createAction, props} from '@ngrx/store';

enum EUserActions {
  FileUpload = '[Files] File Upload',
  FileUploadSuccess = '[Files] File Upload Success',
  FileUploadError = '[Files] File Upload Error',

  SignedUrl = '[Files] Signed Url',
  SignedUrlSuccess = '[Files] Signed Url Success',
  SignedUrlError = '[Files] Signed Url Error',

  UploadToSignedUrl = '[Files] Upload To Signed Url',
  UploadToSignedUrlSuccess = '[Files] Upload To Signed Url Success',
  UploadToSignedUrlError = '[Files] Upload To Signed Url Error',

  ResetFileState = '[Files] Reset File State',
}

export const FileUpload = createAction(EUserActions.FileUpload, props<{ file: any, projectId: any, clientId: any }>());
export const FileUploadSuccess = createAction(EUserActions.FileUploadSuccess, props<{ file: any }>());
export const FileUploadError = createAction(EUserActions.FileUploadError, props<{ error: string }>());

export const SignedUrl = createAction(EUserActions.SignedUrl, props<{ fileName: string, contentType: string }>());
export const SignedUrlSuccess = createAction(EUserActions.SignedUrlSuccess, props<{ file: string, url: string }>());
export const SignedUrlError = createAction(EUserActions.SignedUrlError, props<{ error: string }>());

export const UploadToSignedUrl = createAction(EUserActions.UploadToSignedUrl, props<{ files: any[] }>());
export const UploadToSignedUrlSuccess = createAction(EUserActions.UploadToSignedUrlSuccess, props<{ uploaded: boolean }>());
export const UploadToSignedUrlError = createAction(EUserActions.UploadToSignedUrlError, props<{ error: string }>());

export const UploadToSignedUrlProgress = createAction(EUserActions.UploadToSignedUrlSuccess, props<{ progress: number }>());

export const ResetFileState = createAction(EUserActions.ResetFileState, (params: any = {}) => params);

