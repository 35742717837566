import {Action, createReducer, on} from '@ngrx/store';
import {
  RegistrationPlatformList,
  RegistrationPlatformListError,
  RegistrationPlatformListSuccess,
  RegistrationPlatformListPaginatedError,
  RegistrationPlatformListPaginatedSuccess,
  RegistrationPlatformListPaginated,
  RegistrationPlatformUpdate,
  RegistrationPlatformUpdateSuccess,
  RegistrationPlatformUpdateError,
  RegistrationPlatformCreate,
  RegistrationPlatformCreateSuccess,
  RegistrationPlatformCreateError,
  RegistrationPlatformDelete,
  RegistrationPlatformGet,
  RegistrationPlatformGetSuccess,
  RegistrationPlatformGetError,
  RegistrationPlatformDeleteSuccess,
  RegistrationPlatformDeleteError,
  RegistrationPlatformWidgetGuideURL,
  RegistrationPlatformWidgetGuideURLSuccess,
  RegistrationPlatformWidgetGuideURLError,
  ResetRegistrationPlatformState,
  InfluencersSync,
  InfluencersSyncError,
  InfluencersSyncSuccess,
  InfluencersSyncStatus,
  InfluencersSyncStatusError,
  InfluencersSyncStatusSuccess,
  RegistrationPlatformValidateSlugError, RegistrationPlatformValidateSlugSuccess, RegistrationPlatformValidateSlug
} from '@app/stores/registration-platform/registration-platform.actions';
import {initialRegistrationPlatformState} from '@app/stores/registration-platform/registration-platform.state';
import {ProjectValidateSlug, ProjectValidateSlugError, ProjectValidateSlugSuccess} from '@app/stores';

const generateReducer = createReducer(initialRegistrationPlatformState,
  on(RegistrationPlatformList, (state) => ({
    ...state,
    registrationPlatform: null,
    registrationPlatforms: [],
    success: '',
    error: '',
  })),
  on(RegistrationPlatformListSuccess, (state, {registrationPlatforms}) => ({
    ...state,
    registrationPlatform: null,
    registrationPlatforms,
    success: '',
    error: '',
  })),
  on(RegistrationPlatformListError, (state, {error}) => ({
    ...state,
    registrationPlatform: null,
    registrationPlatforms: [],
    success: '',
    error,
  })),
  on(RegistrationPlatformListPaginated, (state) => ({
    ...state,
    paginatedRegistrationPlatform: null,
    success: '',
    error: ''
  })),
  on(RegistrationPlatformListPaginatedSuccess, (state, {paginatedRegistrationPlatform}) => ({
    ...state,
    paginatedRegistrationPlatform,
    success: '',
    error: ''
  })),
  on(RegistrationPlatformListPaginatedError, (state, {error}) => ({
    ...state,
    paginatedRegistrationPlatform: null,
    success: '',
    error
  })),
  on(RegistrationPlatformUpdate, (state) => ({
    ...state,
    success: '',
    error: '',
  })),
  on(RegistrationPlatformUpdateSuccess, (state) => ({
    ...state,
    success: 'Registration Platform Updated successfully',
    error: '',
  })),
  on(RegistrationPlatformUpdateError, (state, {error}) => ({
    ...state,
    success: '',
    error,
  })),
  on(RegistrationPlatformCreate, (state) => ({
    ...state,
    success: '',
    registrationPlatform: null,
    error: '',
  })),
  on(RegistrationPlatformCreateSuccess, (state, {registrationPlatform}) => ({
    ...state,
    success: '',
    registrationPlatform,
    error: '',
  })),
  on(RegistrationPlatformCreateError, (state, {error}) => ({
    ...state,
    success: '',
    registrationPlatform: null,
    error,
  })),

  on(RegistrationPlatformDelete, (state) => ({
    ...state,
    success: '',
    error: '',
  })),
  on(RegistrationPlatformDeleteSuccess, (state) => ({
    ...state,
    success: 'Registration Platform deleted successfully',
    error: '',
  })),
  on(RegistrationPlatformDeleteError, (state, {error}) => ({
    ...state,
    success: '',
    error,
  })),
  on(RegistrationPlatformGet, (state) => ({
    ...state,
    registrationPlatform: null,
    success: '',
    error: ''
  })),
  on(RegistrationPlatformGetSuccess, (state, {registrationPlatform}) => ({
    ...state,
    registrationPlatform,
    success: '',
    error: ''
  })),
  on(RegistrationPlatformGetError, (state, {error}) => ({
    ...state,
    registrationPlatform: null,
    success: '',
    error
  })),
  on(RegistrationPlatformWidgetGuideURL, (state) => ({
    ...state,
    widgetGuideUrl: null,
    success: '',
    error: ''
  })),
  on(RegistrationPlatformWidgetGuideURLSuccess, (state, {widgetGuideUrl}) => ({
    ...state,
    widgetGuideUrl,
    success: '',
    error: ''
  })),
  on(RegistrationPlatformWidgetGuideURLError, (state, {error}) => ({
    ...state,
    widgetGuideUrl: null,
    success: '',
    error
  })),
  on(InfluencersSync, (state) => ({
    ...state,
    InfluencersSync: null,
    success: '',
    error: ''
  })),
  on(InfluencersSyncSuccess, (state, {influencersSync}) => ({
    ...state,
    influencersSync,
    success: '',
    error: ''
  })),
  on(InfluencersSyncError, (state, {error}) => ({
    ...state,
    InfluencersSync: null,
    success: '',
    error
  })),
  on(InfluencersSyncStatus, (state) => ({
    ...state,
    influencersSync: null,
    success: '',
    error: ''
  })),
  on(InfluencersSyncStatusSuccess, (state, {influencersSync}) => ({
    ...state,
    influencersSync,
    success: '',
    error: ''
  })),
  on(InfluencersSyncStatusError, (state, {error}) => ({
    ...state,
    influencersSync: null,
    success: '',
    error
  })),
  on(RegistrationPlatformValidateSlug, (state) => ({
    ...state,
    success: '',
    slug: '',
    error: '',
  })),
  on(RegistrationPlatformValidateSlugSuccess, (state, {slug}) => ({
    ...state,
    success: '',
    slug,
    error: '',
  })),
  on(RegistrationPlatformValidateSlugError, (state, {error}) => ({
    ...state,
    success: '',
    slug: '',
    error,
  })),
  on(ResetRegistrationPlatformState, (state, {params}) => ({
    ...state,
    ...params
  })),
);

export function registrationPlatformReducer(state = initialRegistrationPlatformState, action: Action) {
  return generateReducer(state, action);
}
