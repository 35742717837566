import { Injectable } from '@angular/core';

import { HttpService } from './http.service';

const BASE_PATH = '/api/v1/influencer';

@Injectable ({providedIn: 'root'})
export class InfluencerApiService {

  constructor(private httpService: HttpService) {}

  /*
   * Not used yet, uncomment to use
  index(params) {
    return this.httpService.get(`${BASE_PATH}`, params);
  }

  get(id) {
    return this.httpService.get(`${BASE_PATH}/${id}`, {});
  }

  create(body, params?) {
    return this.httpService.post(BASE_PATH, body, params);
  }

  update(id, body, params?) {
    return this.httpService.put(BASE_PATH + `/${id}`, body, params);
  }
  */
  getInfluencerCount(params) {
    return this.httpService.get(`${BASE_PATH}/count`, params);
  }

  getShareInfo(id, params?) {
    return this.httpService.get(`${BASE_PATH}/${id}/share_info`, params);
  }
}
