import { Directive, ElementRef, EventEmitter, Output } from '@angular/core';

declare const $: any;

@Directive({
  selector: '[appFontPicker]'
})
export class FontPickerDirective {
  @Output() public Select: EventEmitter<any> = new EventEmitter();
  constructor(private el: ElementRef) {
    // https://av01d.github.io/fontpicker-jquery-plugin/index.html
    this.el.nativeElement.addEventListener('click', () => {
      $(this.el.nativeElement).fontpicker({
        variants: false,
        showClear: true,
        onSelect: (obj) => {
          $(this.el.nativeElement).val(obj.fontFamily); // fontSpec
          if ('createEvent' in this.el.nativeElement.ownerDocument ) {
            const evt = this.el.nativeElement.ownerDocument.createEvent('HTMLEvents');
            evt.initEvent('change', false, true);
            this.el.nativeElement.dispatchEvent(evt);
          }  else {
            this.el.nativeElement.fireEvent('onchange');
          }
        },
      });
      const clearIcon = $(this.el.nativeElement).parent().find('.fp-clear');
      if (clearIcon) {
        clearIcon.on('click', () => {
          if ('createEvent' in this.el.nativeElement.ownerDocument ) {
            const evt = this.el.nativeElement.ownerDocument.createEvent('HTMLEvents');
            evt.initEvent('change', false, true);
            this.el.nativeElement.dispatchEvent(evt);
          }  else {
            this.el.nativeElement.fireEvent('onchange');
          }
        });
      }
    });
  }

  onRawTemplateSelect(item): void {
    this.Select.emit({item});
  }
}
