import {Component, Input, OnInit, OnDestroy, OnChanges, SimpleChanges, Output, EventEmitter} from '@angular/core';
import {environment} from '@environments/environment';
import {select, Store} from '@ngrx/store';
import {getToken, IAuthenticationState} from '@app/stores';
import {takeUntil} from 'rxjs/operators';
import {Subject} from 'rxjs';
import {ToastrService} from 'ngx-toastr';

function getCKFinder() {
  // @ts-ignore
  return CKFinder;
}

@Component({
  selector: 'app-file-browser',
  templateUrl: './file-browser.component.html',
  styleUrls: ['./file-browser.component.css']
})
export class FileBrowserComponent implements OnInit, OnDestroy, OnChanges {

  unsubscriber = new Subject();
  token = null;
  @Input() clientId: number;
  @Input() iconOnly = true;
  @Input() projectId: number;
  @Input() backendPath: string = environment.apiUrl + '/api/v1/file/ckfinder';
  basePath = environment.frontUrl.replace('#', '') + 'assets/js/ckfinder';

  constructor(
    private authenticationStore: Store<IAuthenticationState>,
    private toastr: ToastrService,
  ) {
  }

  ngOnInit(): void {
    this.authenticationStore.pipe(select(getToken))
      .pipe(takeUntil(this.unsubscriber))
      .subscribe(newToken => {
        if (newToken) {
          this.token = newToken;
        }
      });
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.clientId && changes.clientId.currentValue) {
      this.clientId = changes.clientId.currentValue;
    }

    if (changes.projectId && changes.projectId.currentValue) {
      this.projectId = changes.projectId.currentValue;
    }
  }

  openFileBrowser() {
    const that = this;
    const clientId = this.clientId;
    const projectId = this.projectId;
    let pass = 'Authorization,clientId';
    if (projectId) {
      pass += ',projectId';
    }
    getCKFinder().basePath = this.basePath;
    getCKFinder().modal({
      language: 'en',
      startupFolderExpanded: false,
      fileIconsSizes: '128',
      removeModules: 'CreateFolder,DeleteFolder,FilesMoveCopy,RenameFolder,RenameFile,FocusManager,EditImage',
      connectorPath: this.backendPath,
      width: 800,
      height: 800,
      // chooseFiles: true,
      resizeImages: false,
      jquery: '//code.jquery.com/jquery-2.1.0.js',
      jqueryMobile: '//code.jquery.com/mobile/1.4.5/jquery.mobile-1.4.5.min.js',
      jqueryMobileIconsCSS: '//code.jquery.com/mobile/1.4.5/jquery.mobile.icons-1.4.5.min.css',
      jqueryMobileStructureCSS: '//code.jquery.com/mobile/1.4.5/jquery.mobile.structure-1.4.5.min.css',
      pass,
      Authorization: `Bearer ${this.token}`,
      clientId,
      ...(projectId ? {projectId} : {}),
      onInit: (finder) => {
        finder.on('files:choose', function (evt) {
          // const file = evt.data.files.first();
          const file = evt.data.first();
          that.copyFileUrl(file.getUrl());
          finder.request('closePopup');
          // that.fileUrl = file.getUrl();
          // that.fileSelected.emit(file.getUrl());
        });
        finder.on('toolbar:reset:Main:file', function (evt) {
          evt.data.toolbar.push({
            icon: 'ckf-file-copy',
            type: 'button',
            priority: 100,
            label: 'Copy',
            action: function () {
              const files = evt.finder.request('files:getSelected');
              evt.finder.fire('files:choose', files);
            }
          });
        });
      }
    });
  }

  copyFileUrl(text) {
    const selBox = document.createElement('textarea');
    selBox.style.position = 'fixed';
    selBox.style.left = '0';
    selBox.style.top = '0';
    selBox.style.opacity = '0';
    selBox.innerText = text;
    document.body.appendChild(selBox);
    selBox.focus();
    selBox.select();
    document.execCommand('copy');
    document.body.removeChild(selBox);
    this.toastr.success('File URL copied', 'Copied');
  }

  ngOnDestroy() {
    this.unsubscriber.next();
    this.unsubscriber.complete();
  }

}
