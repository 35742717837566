import {Injectable} from '@angular/core';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {environment} from '@environments/environment';

@Injectable({
  providedIn: 'root'
})
export class ProjectService {
  private baseApiUrl = environment.apiUrl;
  private projectUrl = this.baseApiUrl + '/api/v1/project';
  private projectNotificationUrl = this.baseApiUrl + '/api/v1/notification/settings';
  private projectIndustryUrl = this.baseApiUrl + '/api/v1/project_industry';

  constructor(private http: HttpClient) {
  }

  getAllProjects(params: any = {}) {
    return this.http.get(`${this.projectUrl}`, {params,  headers: new HttpHeaders().set('Use-Temp-Token', 'true')});
  }

  update(item, id: number) {
    return this.http.put(`${this.projectUrl}/${id}`, item);
  }

  getIndustries() {
    return this.http.get(`${this.projectIndustryUrl}`);
  }

  getById(id: number) {
    return this.http.get(`${this.projectUrl}/${id}`);
  }

  validateProjectSlug(slug) {
    return this.http.post(`${this.projectUrl}/validate_slug`, {slug});
  }

  create(item) {
    return this.http.post(`${this.projectUrl}`, item);
  }

  deleteProject(projectId) {
    return this.http.delete(`${this.projectUrl}/${projectId}`);
  }

  projectCopy(projectId: number, body: any) {
    return this.http.post(`${this.projectUrl}/${projectId}/copy`, body);
  }

  projectsReportExport(params: any = {}) {
    return this.http.post(`${this.projectUrl}/report/export`, params);
  }

  notificationUpdate(item, id: number) {
    return this.http.put(`${this.projectNotificationUrl}?id=${id}`, item);
  }

  ProjectPlanners(projectId: number) {
    return this.http.get(`${this.projectUrl}/${projectId}/planner`);
  }
}
