<div class="modal-header">
  <h4 class="modal-title" id="modal-title">Import Your influencers</h4>
  <div class=" d-flex justify-content-end">
    <div class="dropdown mr-2">
      <button aria-expanded="false" aria-haspopup="true" class="dropdown-toggle btn btn-outline-primary btn-sm"
        data-offset="10,0" data-toggle="dropdown" id="dropdownMenuOffset">Download Template
      </button>
      <div aria-labelledby="dropdownMenuOffset" class="dropdown-menu dropDown-btn">
        <a class="dropdown-item" *ngFor="let influencerType of influencerTypes" target="_blank"
          href="{{downloadTemplatePath}}{{influencerType.id}}">
          {{influencerType.name}}
        </a>
      </div>
    </div>
    <button type="button" aria-label="Close button" class="close" aria-describedby="modal-title" (click)="modal.dismiss('cancel')">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
</div>
<div class="modal-body">
  <form [formGroup]="importInfluencerForm">
    <div class="row">
      <div class="col-md-6 col-sm-6">
        <div class="client-dropdown">
          <app-creatable-select [isCreatable]="false" [onlyBottomBorder]="false" [hideInfo]="true"
            [labelText]="'Select influencer type'" [selectedValue]="selectedInfluencer"
            [placeholder]="'Select influencer type'" [selectOptions]="influencerTypes"
            (optionSelected)="filterInfluencers($event)"></app-creatable-select>
          <div *ngIf="submitted && f.influencer_type.errors" class="bar error-brd custom-error">
            <div *ngIf="f.influencer_type.errors.required" class="invalid-text">Influencer Type is required</div>
          </div>
        </div>
      </div>
      <div class="col-md-6 col-sm-6">
        <app-creatable-select #influencerSegment placeholder="Select or enter a new segment's name" [onlyBottomBorder]="false"
          [selectOptions]="filteredSegments" (optionSelected)="segmentSelected($event)" [isDisabled]="preSelectedSegmentId !== null && hasFixedSegmentId === true" [selectedValue]="preSelectedSegmentId"></app-creatable-select>
        <div class="bar error-brd custom-error"
          *ngIf="submitted && importInfluencerForm.errors && importInfluencerForm.errors.isSegmentMissing">
          <div class="invalid-text">Segment is required</div>
        </div>
<!--        <div class="bar error-brd custom-error"-->
<!--          *ngIf="submitted && importInfluencerForm.errors && importInfluencerForm.errors.isSegmentMissing">-->
<!--          <div class="invalid-text">Segment is required</div>-->
<!--        </div>-->
      </div>
    </div>
    <div class="row mt-4">
      <!--<div class="col-md-6 col-sm-6" *ngIf="showCampaign">
        <div class="client-dropdown">
          <app-creatable-select [isCreatable]="false" [onlyBottomBorder]="false" [hideInfo]="true"
            [labelText]="'Select Campaign'" [selectedValue]="campId" [placeholder]="'Select Campaign'"
            [selectOptions]="allCampaigns" (optionSelected)="setCampaign($event)"></app-creatable-select>
          <div *ngIf="submitted && f.campaign_id.errors" class="bar error-brd custom-error">
            <div *ngIf="f.campaign_id.errors.required" class="invalid-text">Campaign is required</div>
          </div>
        </div>
      </div>-->
      <div class="col-md-3 d-flex align-items-center">
        <input id="override" placeholder="" type="checkbox" formControlName="overwrite_existing" value="true">
        <label for="override" class="ml-2 mb-0">Update Existing</label>
      </div>
      <div class="col-md-3 d-flex align-items-center">
        <input id="ignore" placeholder="" type="checkbox" formControlName="ignore_errors" value="true">
        <label for="ignore" class="ml-2 mb-0">Ignore Errors</label>
      </div>
    </div>
    <div class="row mt-4">
      <div class="col-md-6">
        <div class="custom-file csvWrap">
          <input accept=".csv, text/csv" (change)="onInfluencersFileSelect($event)" (click)="onInfluencersFileClick()"
            class="custom-file-input" id="customFile" type="file">
          <label class="custom-file-label" for="customFile">
            <span>Influencers .CSV {{ influencersFile ? ': ' + influencersFile.name : '' }}</span>
          </label>
          <div *ngIf="submitted && importInfluencerForm.errors && importInfluencerForm.errors.isInfluencersFileInvalid"
            class="bar error-brd invalid-text">
            Please add a csv file containing the influencers
          </div>
        </div>
      </div>
      <div class="col-md-6">
        <div class="custom-file csvWrap">
          <input accept=".zip, application/zip" (change)="onHeadshotsFileSelect($event)" class="custom-file-input"
            id="customFile2" type="file">
          <label class="custom-file-label" for="customFile2">
            <span>Upload Headshots/Logos .zip file {{ uploadFile ? ': ' + uploadFile.name : '' }}</span>
          </label>
          <div *ngIf="submitted && importInfluencerForm.errors && importInfluencerForm.errors.isHeadshotFileInvalid"
            class="bar error-brd invalid-text">
            Please add a zip file containing the files used in the import
          </div>
        </div>
      </div>
    </div>
    <div *ngIf="isImportDataFilled()" class="import-data-container mt-4">
      <div class="">
        <div *ngIf="isImportDataFilled()" class="import-data-invalid">
          <span>Your influencers file contains errors, please fix them and try to upload again.</span>
        </div>
        <div class="custom-table">
          <div class="custom-table-1">
            <div class="custom-table-2">
              <table class="sb-table table dataTable no-footer nowrap" datatable [dtOptions]="importDataTableOptions"
              id="dt-table">
              <thead>
              <tr>
                <th *ngFor="let fieldName of importDataTableColumns()">{{fieldName}}</th>
              </tr>
              </thead>
              <tbody>
              <tr *ngFor="let data of importData['list']">
                <td *ngFor="let field of importDataTableColumns()">{{data[field]}}</td>
              </tr>
              </tbody>
              </table>
            </div>
          </div>

        </div>
      </div>
    </div>
  </form>
</div>
<input type="hidden" ngbAutofocus>
<div class="modal-footer">
  <button class="btn btn-default" (click)="showPreviousImports()" type="button">Task Logs</button>
  <button *ngIf="(loader$ | async) || (loaderImport$ | async)" class="btn btn-default" type="button" [disabled]="(loader$ | async) || (loaderImport$ | async)">
    <span class="btn-wrapper--icon spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
    <span class="btn-wrapper--label">
      {{importProgress.status === 'started' ?
          'Import: started' :
          importProgress.status === 'in-progress' ?
            'Importing: '  + ' ' + importProgress.stage + ' ' + importProgress.progress + '%' + ' remains ' + importProgress.remainingTimeFormatted + ' passed ' + importProgress.elapsedTimeFormatted :
            importProgress.status === 'uploading' ?
              'Uploading: ' + importProgress.progress + '%' :
              'Import: ' + importProgress.status}}
    </span>
  </button>
  <button *ngIf="!(loader$ | async) && !(loaderImport$ | async)" class="btn btn-primary" (click)="onImportInfluencersSubmit()" type="button">Upload
  </button>
  <button *ngIf="!(loader$ | async) && !(loaderImport$ | async) && importJobId" class="btn btn-primary" type="button" (click)="importStatus()">
    Retry
  </button>
</div>
