import {createAction, props} from '@ngrx/store';
import {PaginatedSegments, Segment} from '@app/modules/shared/models/segment';

enum ESegmentsAction {
  SegmentsList = '[Segments] Segments List',
  SegmentsListSuccess = '[Segments] Segments List Success',
  SegmentsListError = '[Segments] Segments List Error',
  SegmentsDelete = '[Segments] Segments Delete',
  SegmentsDeleteSuccess = '[Segments] Segments Delete Success',
  SegmentsDeleteError = '[Segments] Segments Delete Error',
  SegmentsUpdate = '[Segments] Segments Update',
  SegmentsUpdateSuccess = '[Segments] Segments Update Success',
  SegmentsUpdateError = '[Segments] Segments Update Error',
  SegmentsAdd = '[Segments] Segments Add',
  SegmentsAddSuccess = '[Segments] Segments Add Success',
  SegmentsAddError = '[Segments] Segments Add Error',
  SegmentsListPaginated = '[Segments] Segments List Paginated',
  SegmentsListPaginatedSuccess = '[Segments] Segments List Paginated Success',
  SegmentsListPaginatedError = '[Segments] Segments List Paginated Error',

  ResetSegmentState = '[Segments] Reset Segment State',
}

export const SegmentsList = createAction(ESegmentsAction.SegmentsList, (params: any = {}) => params);
export const SegmentsListSuccess = createAction(ESegmentsAction.SegmentsListSuccess, props<{ segments: Segment[] }>());
export const SegmentsListError = createAction(ESegmentsAction.SegmentsListError, props<{ error: string }>());

export const SegmentsListPaginated = createAction(ESegmentsAction.SegmentsListPaginated, (params: any = {}) => params);
export const SegmentsListPaginatedSuccess = createAction(ESegmentsAction.SegmentsListPaginatedSuccess,
  props<{ paginatedSegments: PaginatedSegments }>());
export const SegmentsListPaginatedError = createAction(ESegmentsAction.SegmentsListPaginatedError, props<{ error: string }>());

export const SegmentsDelete = createAction(ESegmentsAction.SegmentsDelete, props<{ segmentId: number }>());
export const SegmentsDeleteSuccess = createAction(ESegmentsAction.SegmentsDeleteSuccess);
export const SegmentsDeleteError = createAction(ESegmentsAction.SegmentsDeleteError, props<{ error: string }>());

export const SegmentsUpdate = createAction(ESegmentsAction.SegmentsUpdate, props<{ segmentId: number, segment: Segment }>());
export const SegmentsUpdateSuccess = createAction(ESegmentsAction.SegmentsUpdateSuccess);
export const SegmentsUpdateError = createAction(ESegmentsAction.SegmentsUpdateError, props<{ error: string }>());

export const SegmentsAdd = createAction(ESegmentsAction.SegmentsAdd, props<{ segment: Segment }>());
export const SegmentsAddSuccess = createAction(ESegmentsAction.SegmentsAddSuccess, props<{ segment: Segment }>());
export const SegmentsAddError = createAction(ESegmentsAction.SegmentsAddError, props<{ error: string }>());

export const ResetSegmentState = createAction(ESegmentsAction.ResetSegmentState, (params: any = {}) => params);
