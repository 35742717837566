import {Injectable} from '@angular/core';
import {environment} from '@environments/environment';
import {HttpClient} from '@angular/common/http';

@Injectable({providedIn: 'root'})
export class PersonalAccessTokenService {
  private baseApiUrl = environment.apiUrl;
  private packageUrl = this.baseApiUrl + '/api/v1/user';

  constructor(private http: HttpClient) {
  }

  getById(id: number) {
    return this.http.get(`${this.packageUrl}/personal_access_token/${id}`);
  }
  getPersonalToken(params: any = {}) {
    return this.http.get(`${this.packageUrl}/personal_access_token`, {params});
  }
  delete(tokenId: number) {
    return this.http.delete(`${this.packageUrl}/personal_access_token/${tokenId}`);
  }

  update(item, id: number) {
    return this.http.put(`${this.packageUrl}/personal_access_token/${id}`, item);
  }

  create(item) {
    return this.http.post(`${this.packageUrl}/personal_access_token`, item);
  }
}
