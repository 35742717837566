import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { Routes, RouterModule } from '@angular/router';

import { UserAddComponent } from './user-add';
import { UserListComponent } from './user-list';
import { ProfileComponent } from './profile';

import { AuthGuard } from '@app/_guards';
import { NonInfluencerGuard } from '@app/_guards/non-influencer.guard';
import {PersonalAccessTokenComponent} from "@app/modules/user/personal-access-token/personal-access-token.component";

const routes: Routes = [
  {
    path: '',
    children: [
      {
        path: '',
        component: UserListComponent,
        canActivate: [AuthGuard, NonInfluencerGuard]
      },
      {
        path: 'add',
        component: UserAddComponent,
        canActivate: [AuthGuard, NonInfluencerGuard]
      },
      {
        path: 'profile/:id',
        component: UserAddComponent,
        canActivate: [AuthGuard, NonInfluencerGuard]
      },
      {
        path: 'profile',
        component: ProfileComponent,
        canActivate: [AuthGuard ]
      },
      {
        path: 'integration',
        component: PersonalAccessTokenComponent,
        canActivate: [AuthGuard ]
      }
    ]
  }
];

@NgModule({
  imports: [CommonModule, RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class UserRoutingModule {
}
