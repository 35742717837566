import {Action, createReducer, on} from '@ngrx/store';
import {
  UsersList,
  UsersListSuccess,
  UsersListError,
  ResetUserState,
  UsersListPaginated,
  UsersListPaginatedSuccess,
  UsersListPaginatedError,
  UsersAdd,
  UsersAddSuccess,
  UsersAddError,
  UsersUpdate,
  UsersUpdateSuccess,
  UsersUpdateError,
  UsersDelete,
  UsersDeleteSuccess,
  UsersDeleteError,
  UsersGet,
  UsersGetSuccess, UsersGetError, UsersRoles, UsersRolesError, UsersRolesSuccess,
  UserLoginInfoMailError, UserLoginInfoMailSuccess, UserLoginInfoMail
} from '@app/stores/users/users.actions';
import {initialUsersState} from '@app/stores/users/users.state';

const generateReducer = createReducer(initialUsersState,
  on(UsersList, (state) => ({
    ...state,
    user: null,
    updateUser: null,
    users: [],
    success: '',
    error: ''
  })),
  on(UsersListSuccess, (state, {users}) => ({
    ...state,
    user: null,
    updateUser: null,
    users,
    success: '',
    error: ''
  })),
  on(UsersListError, (state, {error}) => ({
    ...state,
    user: null,
    updateUser: null,
    users: [],
    success: '',
    error
  })),

  on(UsersListPaginated, (state) => ({
    ...state,
    paginatedUsers: null,
    updateUser: null,
    success: '',
    error: ''
  })),
  on(UsersListPaginatedSuccess, (state, {paginatedUsers}) => ({
    ...state,
    paginatedUsers,
    updateUser: null,
    success: '',
    error: ''
  })),
  on(UsersListPaginatedError, (state, {error}) => ({
    ...state,
    paginatedUsers: null,
    updateUser: null,
    success: '',
    error
  })),

  on(UsersAdd, (state) => ({
    ...state,
    success: '',
    error: '',
    updateUser: null,
  })),
  on(UsersAddSuccess, (state) => ({
    ...state,
    success: 'User added successfully',
    error: ''
  })),
  on(UsersAddError, (state, {error}) => ({
    ...state,
    success: '',
    updateUser: null,
    error
  })),

  on(UsersUpdate, (state) => ({
    ...state,
    success: '',
    error: '',
    updateUser: null,
  })),
  on(UsersUpdateSuccess, (state, {updateUser}) => ({
    ...state,
    success: 'User updated successfully',
    error: '',
    updateUser
  })),
  on(UsersUpdateError, (state, {error}) => ({
    ...state,
    success: '',
    error,
    updateUser: null,
  })),

  on(UsersDelete, (state) => ({
    ...state,
    success: '',
    error: '',
    updateUser: null,
  })),
  on(UsersDeleteSuccess, (state) => ({
    ...state,
    success: 'User deleted successfully',
    error: ''
  })),
  on(UsersDeleteError, (state, {error}) => ({
    ...state,
    success: '',
    error
  })),

  on(UsersGet, (state) => ({
    ...state,
    user: null,
    updateUser: null,
    success: '',
    error: ''
  })),
  on(UsersGetSuccess, (state, {user}) => ({
    ...state,
    user,
    updateUser: null,
    success: '',
    error: ''
  })),
  on(UsersGetError, (state, {error}) => ({
    ...state,
    user: null,
    updateUser: null,
    success: '',
    error
  })),

  on(UsersRoles, (state) => ({
    ...state,
    roles: [],
    success: '',
    error: ''
  })),
  on(UsersRolesSuccess, (state, {roles}) => ({
    ...state,
    roles,
    success: '',
    error: ''
  })),
  on(UsersRolesError, (state, {error}) => ({
    ...state,
    roles: [],
    success: '',
    error
  })),

  on(ResetUserState, (state, {params}) => ({
    ...state,
    ...params
  })),

  on(UserLoginInfoMail, (state) => ({
    ...state,
    user: null,
    success: '',
    error: ''
  })),
  on(UserLoginInfoMailSuccess, (state) => ({
    ...state,
    success: 'Email sent successfully',
    error: ''
  })),
  on(UserLoginInfoMailError, (state, {error}) => ({
    ...state,
    user: null,
    success: '',
    error
  })),
);

export function usersReducer(state = initialUsersState, action: Action) {
  return generateReducer(state, action);
}
