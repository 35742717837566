import {createFeatureSelector, createSelector} from '@ngrx/store';
import {IPackageState} from '@app/stores';

const selectPackage = createFeatureSelector<IPackageState>('packages');

export const getPackagesListAll = createSelector(selectPackage, (state: IPackageState) => state.packages);

export const getPackagesListAllError = createSelector(selectPackage, (state: IPackageState) => state.error);

export const getPackagesPaginated = createSelector(selectPackage, (state: IPackageState) => state.paginatedPackages);

export const getPackage = createSelector(selectPackage, (state: IPackageState) => state.pkg);

export const getPackageSuccess = createSelector(selectPackage, (state: IPackageState) => state.success);

export const getUpdatePackage = createSelector(selectPackage, (state: IPackageState) => state.updatePackage);

export const getPackageError = createSelector(selectPackage, (state: IPackageState) => state.error);
