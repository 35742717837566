import {Injectable} from '@angular/core';
import {environment} from '@environments/environment';
import {HttpClient} from '@angular/common/http';
import {Client} from '@app/modules/shared/models';

@Injectable({providedIn: 'root'})
export class ClientService {
  private baseApiUrl = environment.apiUrl;
  private clientUrl = this.baseApiUrl + '/api/v1/client';

  constructor(private http: HttpClient) {
  }

  getClients(params: any = {}) {
    return this.http.get(`${this.clientUrl}`, {params});
  }

  getClient(id: number) {
    return this.http.get(`${this.clientUrl}/${id}`);
  }

  deleteClient(clientId: number) {
    return this.http.delete(`${this.clientUrl}/${clientId}`);
  }

  updateClient(clientId: number, client: Client) {
    return this.http.put(`${this.clientUrl}/${clientId}`, client);
  }

  addClient(client: Client) {
    return this.http.post(`${this.clientUrl}`, client);
  }
}
