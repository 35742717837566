import {AfterViewInit, Component, Input, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {NgbActiveModal, NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {UntypedFormBuilder, UntypedFormGroup, ValidationErrors, Validators} from '@angular/forms';
import {
  CampaignGetAll,
  getCampaignError,
  getCampaigns,
  getFileUploadProgress,
  getInfluencerError,
  getInfluencersImport,
  getInfluencersImportFields,
  getInfluencersImportHeaders,
  getInfluencersImportHeadersError,
  getSegmentError,
  getSegmentSuccessResponse,
  getUploadToSignedUrl,
  ICampaignState,
  IFileUploadState,
  IInfluencersState,
  InfluencersImport, getInfluencersImportError,
  InfluencersImportHeaders,
  InfluencersImportStatus,
  ISegmentsState,
  ResetInfluencerState,
  SegmentsAdd,
  UploadToSignedUrl
} from '@app/stores';
import {select, Store} from '@ngrx/store';
import {takeUntil} from 'rxjs/operators';
import {BehaviorSubject, Subject} from 'rxjs';
import {Observable} from 'rxjs/Observable';
import {ToastrService} from 'ngx-toastr';
import {LoaderService} from '@app/services/loader.service';
import {CreatableSelectComponent} from '@app/_components/creatable-select';
import {environment} from '@environments/environment';
import {SocketService} from '@app/services/socket.service';
import {AwsService} from '@app/services/aws.service';
import {TaskLogsComponent} from '@app/_components/task-logs/task-logs.component';
import {SegmentAddComponent} from '@app/modules/segment/segment-add/segment-add.component';
import {Segment} from '@app/modules/shared/models/segment';
import {ImportMatcherComponent} from '../import_matcher/import-matcher.component';

@Component({
  selector: 'app-influencers-import',
  templateUrl: './influencers-import.component.html',
  styleUrls: ['./influencers-import.component.css']
})
export class InfluencersImportComponent implements OnInit, OnDestroy, AfterViewInit {

  importDataTableOptions: any = {
    ordering: false,
    processing: true,
    pagingType: 'full_numbers',
    pageLength: 10,
    scrollCollapse: true,
    scrollX: true,
    paging: true,
    searching: true,
    drawCallback: function (setting) {
      // document.querySelector('.custom-table').classList.remove('blur');
      const totalPages = this.api().page.info().pages;
      if (totalPages <= 1) {
        document.querySelector('.dataTables_paginate').classList.add('d-none');
      } else {
        document.querySelector('.dataTables_paginate').classList.remove('d-none');
      }
    }
  };
  importInfluencerForm: UntypedFormGroup;
  submitted = false;
  @Input() campId = '';
  @Input() influencerTypes = [];
  @Input() segments = [];
  @Input() projectId = [];
  @Input() selectedInfluencer;
  filteredSegments = [];
  influencersFile: File;
  influencersValues = [];
  uploadFile: File;
  importData: Array<Object>;
  private unsubscriber = new Subject();
  public importJobId: number;
  loader$: Observable<boolean>;
  allCampaigns: any = [];
  @ViewChild('influencerSegment', {static: true}) influencerSegment: CreatableSelectComponent;
  showCampaign = false;
  downloadTemplatePath = `${environment.apiUrl}/api/v1/influencer/import/template_download/`;
  fileResponse: any[] = [];
  createdSegments = [];
  influencersImportHeaders = [];
  influencerFields = [];
  uniqueImportString: any;

  public socketSubscription: any;
  public importProgress: any = {
    status: 'started',
    stage: '',
    remainingTimeFormatted: '',
    elapsedTimeFormatted: '',
    progress: 0
  };
  public loaderImport = new BehaviorSubject<boolean>(false);
  public loaderImport$ = this.loaderImport.asObservable();

  //
  // This property will lock segment select list when assigned
  public preSelectedSegmentId: any = null;
  public hasFixedSegmentId = false;

  constructor(
    public modal: NgbActiveModal,
    private formBuilder: UntypedFormBuilder,
    private influencerStore: Store<IInfluencersState>,
    private toastr: ToastrService,
    private loaderService: LoaderService,
    private campaignStore: Store<ICampaignState>,
    private segmentStore: Store<ISegmentsState>,
    private fileUploadStore: Store<IFileUploadState>,
    private readonly socketService: SocketService,
    private awsService: AwsService,
    private _modalService: NgbModal,
  ) {
    this.socketService.resetSocketData();
  }

  subscribeStores() {

    this.influencerStore.pipe(select(getInfluencersImportFields))
      .pipe(takeUntil(this.unsubscriber))
      .subscribe(fields => {
        if (fields) {
          this.influencerFields = Object.keys(fields);
        }
      });


    this.influencerStore.pipe(select(getInfluencersImportHeaders))
      .pipe(takeUntil(this.unsubscriber))
      .subscribe(result => {
        if (result) {
          this.influencerFields = Object.keys(result.templateFields);
          this.influencersImportHeaders = result.fileHeaders;
          this.influencersValues = result.influencers;
          this.showImportMatcher();
        }

      });

    this.influencerStore.pipe(select(getInfluencersImportHeadersError))
      .pipe(takeUntil(this.unsubscriber))
      .subscribe(error => {
        console.error(error);
        this.loaderImport.next(false);
      });

    this.influencerStore.pipe(select(getInfluencersImport))
      .pipe(takeUntil(this.unsubscriber))
      .subscribe(influencerImport => {
        if (influencerImport) {
          if (influencerImport !== true) {
            let errorMessage = '';
            if (!influencerImport.success) {
              this.importJobId = influencerImport.data?.jobId;
              if (influencerImport.error) {
                if (influencerImport.error.name === 'AppTimeoutError') {
                  errorMessage = 'Upload timeout';
                }
                if (influencerImport.error.name === 'AppError') {
                  errorMessage = influencerImport.message;
                }
                if (influencerImport.error.name === 'AppValidationError') {
                  errorMessage = influencerImport.error.orgMessage || 'Invalid Records';
                  this.importData = influencerImport.data;
                }
              }
              if (errorMessage) {
                this.toastr.error(errorMessage, 'Error');
              }
            }
          } else {
            // this.modal.close();
          }
        }
      });

    this.influencerStore.pipe(select(getInfluencersImportError))
      .pipe(takeUntil(this.unsubscriber))
      .subscribe(error => {
        this.loaderImport.next(false);
      });

    this.influencerStore.pipe(select(getInfluencerError))
      .pipe(takeUntil(this.unsubscriber))
      .subscribe(error => {
        if (error) {
          this.toastr.error(error, 'Error');
        }
      });

    this.campaignStore.pipe(select(getCampaignError))
      .pipe(takeUntil(this.unsubscriber))
      .subscribe(error => {
        if (error) {
          this.toastr.error(error, 'Error');
        }
      });

    this.campaignStore.pipe(select(getCampaigns))
      .pipe(takeUntil(this.unsubscriber))
      .subscribe(campaigns => {
        if (campaigns && campaigns.length > 0) {
          this.allCampaigns = campaigns;
        }
      });

    this.fileUploadStore.pipe(select(getFileUploadProgress))
      .pipe(takeUntil(this.unsubscriber))
      .subscribe((data: any) => {
        if (data !== undefined && data !== null) {
          this.importProgress = {
            status: 'uploading',
            progress: data
          };
          if (data >= 100) {
            this.importProgress = {
              status: 'started',
              progress: 0
            };
          }
        }
      });
    this.fileUploadStore.pipe(select(getUploadToSignedUrl))
      .pipe(takeUntil(this.unsubscriber))
      .subscribe((data: any) => {
        if (data && this.fileResponse && this.fileResponse.length > 0) {
          this.submitInfluencerData();
        }
      });

    this.segmentStore.pipe(select(getSegmentError))
      .pipe(takeUntil(this.unsubscriber))
      .subscribe(error => {
        if (error) {
          this.toastr.error(error, 'Error');
        }
      });

    this.segmentStore.pipe(select(getSegmentSuccessResponse))
      .pipe(takeUntil(this.unsubscriber))
      .subscribe(segment => {
        if (segment) {
          this.importInfluencerForm.patchValue({segment_id: segment.id});
          this.createdSegments = [...this.createdSegments, {name: segment.name, id: segment.id}];
        }
      });
  }

  get f() {
    return this.importInfluencerForm.controls;
  }

  subscribeToSocket() {
    this.socketSubscription = this.socketService.sourceData.subscribe(
      (message: any) => {
        if (message?.data?.requesterType === 'influencer' && (!this.importJobId || message?.data?.id === this.importJobId) && message?.data?.uniqueImportString === this.uniqueImportString) {

          //
          // Set job id
          this.importJobId = message.data.id;

          //
          // Update import progress
          this.importProgress.status = message.data?.status;
          this.importProgress.progress = message?.data?.progress;
          this.importProgress.stage = message?.data?.stage;
          this.importProgress.remainingTimeFormatted = message?.data?.remainingTimeFormatted;
          this.importProgress.elapsedTimeFormatted = message?.data?.elapsedTimeFormatted;
          // adjust loading state for finished/failed
          if (['finished', 'failed'].includes(this.importProgress.status)) {
            setTimeout(() => {
              this.loaderImport.next(false);
              // Keep status
              const status: string = this.importProgress?.status;
              // Restart progress upon failed
              if (status === 'failed') {
                this.importStatus();
                this.importProgress = {
                  status: 'started',
                  progress: 0
                };
              } else { // On finished(success) should close modal, clear job id and data
                this.importJobId = null;
                if (status !== 'started') {
                  this.modal.close({selectedSegment: this.importInfluencerForm.get('segment_id').value});
                  this.toastr.success('Import successful', 'Success');
                }
              }

            }, 1500);
          }
        }

      },
      (err) => console.error(err),
      () => console.warn('Completed!')
    );
  }

  ngOnInit(): void {
    this.loader$ = this.loaderService.loader$;
    this.createImportInfluencerForm();

    this.importJobId = null;
    this.importData = null;
    this.influencerStore.dispatch(ResetInfluencerState({params: {error: '', influencersImport: null, influencersImportHeaders: null}}));
    this.importInfluencerForm.reset();
    if (!this.campId) {
      this.getAllCampaigns();
      this.showCampaign = true;
    }
    this.subscribeStores();
    // Subscribe to socket
    // it will handle import progress
    this.subscribeToSocket();
  }

  ngAfterViewInit() {
    if (this.selectedInfluencer) {
      setTimeout(() => {
        this.filterInfluencers({value: this.selectedInfluencer});
      }, 1000);
    }
    this.importInfluencerForm.controls['project_id'].setValue(this.projectId);
    this.setCampaign({value: this.campId});

    //
    // Set segment if available
    if (this.preSelectedSegmentId !== null) {
      this.importInfluencerForm.patchValue({segment_id: this.preSelectedSegmentId});
    }
  }

  setCampaign(event) {
    if (event) {
      this.campId = event.value;
      this.importInfluencerForm.controls['campaign_id'].setValue(this.campId);
    }
  }

  getAllCampaigns() {
    this.campaignStore.dispatch(CampaignGetAll({filter: {project_id: this.projectId}}));
  }

  createImportInfluencerForm() {
    this.importInfluencerForm = this.formBuilder.group({
      influencer_type: ['', Validators.required],
      // campaign_id: ['', Validators.required],
      campaign_id: [''],
      segment: [''],
      segment_id: [''],
      project_id: ['', Validators.required],
      influencers: [''],
      uploadFile: [''],
      overwrite_existing: [''],
      ignore_errors: [''],
    }, {
      validator: (group: UntypedFormGroup) => {
        const {segment, segment_id, uploadFile, influencers} = group.value;
        const errors = {};

        if (!segment && !segment_id) {
          errors['isSegmentMissing'] = true;
        }
        if (uploadFile && !uploadFile.name.endsWith('.zip')) {
          errors['isHeadshotFileInvalid'] = true;
        }
        if (!influencers || !influencers.name.endsWith('.csv')) {
          errors['isInfluencersFileInvalid'] = true;
        }

        return errors as ValidationErrors;
      }
    });
  }

  async onImportInfluencersSubmit() {
    this.uniqueImportString = (new Date().getTime()).toString(36);
    this.importData = null;
    this.submitted = true;
    if (this.importInfluencerForm.invalid) {
      return;
    }

    const {value} = this.importInfluencerForm;

    this.fileResponse = await Promise.all([
      this.getSignedUrlFromFile(value.influencers),
      ...(value.uploadFile ? [this.getSignedUrlFromFile(value.uploadFile)] : [])
    ]);

    this.fileUploadStore.dispatch(UploadToSignedUrl({files: this.fileResponse}));
  }

  submitInfluencerData() {

    const {value} = this.importInfluencerForm;

    const data = {
      ...(value.segment_id ? {
        project_influencer_segment_id: value.segment_id.toString(),
      } : {
        project_influencer_segment_new_name: value.segment.toString(),
      }),
      ...(value.campaign_id ? {campaign_id: value.campaign_id} : {}),
      ignore_errors: value.ignore_errors,
      overwrite_existing: value.overwrite_existing,
      influencer_type_id: value.influencer_type,
      project_id: this.projectId,
      uniqueImportString: this.uniqueImportString,

      influencers: this.fileResponse[0].url.split('?')[0],
      ...(this.fileResponse[1] ? {files: this.fileResponse[1].url.split('?')[0]} : {})
    };

    this.loaderImport.next(true);
    this.influencerStore.dispatch(InfluencersImportHeaders({data}));
  }

  submitMatchedData(matchedHeaders) {
    const {value} = this.importInfluencerForm;
    const data = {
      ...(value.segment_id ? {
        project_influencer_segment_id: value.segment_id.toString(),
      } : {
        project_influencer_segment_new_name: value.segment.toString(),
      }),
      ...(value.campaign_id ? {campaign_id: value.campaign_id} : {}),
      ignore_errors: value.ignore_errors,
      overwrite_existing: value.overwrite_existing,
      influencer_type_id: value.influencer_type,
      project_id: this.projectId,
      matched_headers: matchedHeaders,
      uniqueImportString: this.uniqueImportString,
      influencers: this.fileResponse[0].url.split('?')[0],
      ...(this.fileResponse[1] ? {files: this.fileResponse[1].url.split('?')[0]} : {})
    };

    this.loaderImport.next(true);
    this.influencerStore.dispatch(InfluencersImport({file: data}));
  }

  filterInfluencers(event) {
    this.f['influencer_type'].setValue(null);
    this.filteredSegments = [];

    //
    // Clear if there isn't a pre selected segment
    if (this.preSelectedSegmentId === null && this.hasFixedSegmentId === false) {
      this.influencerSegment.clearSelection();
    }

    if (event) {
      this.f['influencer_type'].setValue(Number(event.value));
      this.filteredSegments = this.segments.filter(item => item.influencer_type_id === Number(event.value));
      this.selectedInfluencer = event.value;
    }
  }

  segmentSelected(event: any) {
    if (event) {
      if (event.isNew) {
        if (this.createdSegments.length > 0) {
          this.createdSegments.map(item => {
            if (item.name !== event.name) {
              const segment = new Segment();
              segment.influencer_type_id = this.selectedInfluencer;
              segment.name = event.name;
              this.segmentStore.dispatch(SegmentsAdd({segment: {...segment, project_id: Number(this.projectId)}}));
            } else {
              this.importInfluencerForm.patchValue({segment_id: item.id});
            }
          });
        } else {
          const segment = new Segment();
          segment.influencer_type_id = this.selectedInfluencer;
          segment.name = event.name;
          this.segmentStore.dispatch(SegmentsAdd({segment: {...segment, project_id: Number(this.projectId)}}));
        }
      } else {
        this.importInfluencerForm.patchValue({segment_id: event.value});
      }
    } else {
      this.importInfluencerForm.patchValue({segment: ''});
      this.importInfluencerForm.patchValue({segment_id: ''});
    }
  }

  onInfluencersFileSelect(event) {
    const {target: {files}} = event;
    const file = files ? files[0] : undefined;

    this.importInfluencerForm.get('influencers').setValue(file);
    this.influencersFile = file;

  }

  onInfluencersFileClick() {
    this.importData = [];
  }

  onHeadshotsFileSelect(event) {
    const {target: {files}} = event;
    const file = files ? files[0] : undefined;

    this.importInfluencerForm.get('uploadFile').setValue(file);
    this.uploadFile = file;
    this.importData = [];
  }

  isImportDataFilled() {
    return this.importData && Object.keys(this.importData).length > 0;
  }

  importDataTableColumns() {
    return this.importData['columns'];
  }

  importStatus() {
    this.influencerStore.dispatch(InfluencersImportStatus({importJobId: this.importJobId}));
  }

  showPreviousImports() {
    const data = {
      campaign_id: this.campId,
      influencer_type_id: this.selectedInfluencer || '',
      project_id: this.projectId,
      action: 'import'
    };
    const modelRef: any = this._modalService.open(TaskLogsComponent, {
      centered: false,
      size: 'xl',
      keyboard: true
    });
    modelRef.componentInstance.data = data;
    modelRef.result.then((result) => {
    }, (reason) => {
    });
  }
  mappingDataWithPreviousHeader(data, headers) {
    return data.map(item => {
      const transformedItem = {};
      const keys = Object.keys(item);
      for (const oldKey of keys) {
        const index = keys.indexOf(oldKey);
        const newKey = index !== -1 ? headers[index] : oldKey;
        transformedItem[newKey] = item[oldKey];
      }
      return transformedItem;
    });
  }

  showImportMatcher() {
    const {value} = this.importInfluencerForm;
    if (this.influencersFile) {
      const data = {
        influencer_fields: this.influencerFields,
        influencers_values: this.influencersValues,
        influencers_import_headers: this.influencersImportHeaders,
        csv_influencers_import_headers: this.influencersImportHeaders
      };
      let checkPreviousHeader: any = localStorage.getItem('matchedHeaders');
      if (checkPreviousHeader !== null) {
        checkPreviousHeader = typeof checkPreviousHeader === 'string' ? JSON.parse(checkPreviousHeader) : checkPreviousHeader;

        if (checkPreviousHeader?.influencer_type_id && checkPreviousHeader?.influencer_type_id === value.influencer_type && checkPreviousHeader?.project_id === value.project_id) {
          if (JSON.stringify(checkPreviousHeader?.originalCSVHeaders) === JSON.stringify(this.influencersImportHeaders)) {
            data['influencers_import_headers'] = checkPreviousHeader?.matchedHeaders;
            data.influencers_values = this.mappingDataWithPreviousHeader(data.influencers_values, checkPreviousHeader?.matchedHeaders);
          }
          }
        }

      const modelRef: any = this._modalService.open(ImportMatcherComponent, {
        centered: false,
        size: 'xl',
        keyboard: true
      });
      modelRef.componentInstance.data = data;
      modelRef.result.then((matchedHeaders) => {if (matchedHeaders) {
          const dataToSave = {
            matchedHeaders,
            campaign_id: value.campaign_id,
            project_id: this.projectId,
            influencer_type_id: value.influencer_type,
            originalCSVHeaders: this.influencersImportHeaders
          };
          localStorage.setItem('matchedHeaders', JSON.stringify(dataToSave));

          const finalHeaders = matchedHeaders.map(x => {
            if (x === 'skip') {
              return '';
            } else {
              return x;
            }
          });
          this.submitMatchedData(finalHeaders);
        }
      }, (reason) => {
        if (reason === 'cancel') {
          this.loaderImport.next(false);
        }
      });
    }
  }


  getSignedUrlFromFile(file) {
    return new Promise((resolve, reject) => this.awsService.getAwsSignedUrl(file.name, file.type).subscribe((resp: any) => {
      resolve({
        url: resp.data.url,
        fileData: file
      });
    }, reject));
  }

  ngOnDestroy() {
    this.unsubscriber.next();
    this.unsubscriber.complete();
    this.socketSubscription.unsubscribe();
  }

}
