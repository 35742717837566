import {Injectable} from '@angular/core';
import {Actions, createEffect, ofType} from '@ngrx/effects';
import {Store} from '@ngrx/store';
import {catchError, map, switchMap} from 'rxjs/operators';
import {of} from 'rxjs';
import {LoaderService} from '@app/services/loader.service';
import {
  MailVerifySender, MailVerifySenderSuccess, MailVerifySenderError, MailVerifySenderStatus, MailVerifySenderStatusSuccess,
  MailVerifySenderStatusError,
  MailServiceProvider, MailServiceProviderSuccess, MailServiceProviderError
} from '@app/stores/mail-verify/mail-verify.actions';
import {IMailVerifyState} from '@app/stores/mail-verify/mail-verify.state';
import {MailVerifyService} from '@app/services/mail-verify.service';

@Injectable()
export class MailVerifyEffects {
  constructor(
    private _actions$: Actions,
    private _store: Store<IMailVerifyState>,
    private mailVerifyService: MailVerifyService,
    private loaderService: LoaderService
  ) {
  }

  mailVerifySenderStatus$ = createEffect(() => this._actions$.pipe(
    ofType(MailVerifySenderStatus),
    switchMap((action) => {
      this.loaderService.show();
      return this.mailVerifyService.verifySenderStatus(action.params).pipe(
        map((resp: any) => {
          if (resp.success) {
            this.loaderService.hide();
            return MailVerifySenderStatusSuccess({status: resp.data});
          }
          this.loaderService.hide(true);
          return MailVerifySenderStatusError({error: this.loaderService.getErrorMessage(resp)});
        }),
        catchError(error => {
          this.loaderService.hide(true);
          return of(MailVerifySenderStatusError({error: this.loaderService.getErrorMessage(error)}));
        })
      );
    })
  ));

  mailVerifySender$ = createEffect(() => this._actions$.pipe(
    ofType(MailVerifySender),
    switchMap((action) => {
      this.loaderService.show();
      return this.mailVerifyService.verifySender(action.params).pipe(
        map((resp: any) => {
          if (resp.success) {
            this.loaderService.hide();
            return MailVerifySenderSuccess({status: resp.data});
          }
          this.loaderService.hide(true);
          return MailVerifySenderError({error: this.loaderService.getErrorMessage(resp)});
        }),
        catchError(error => {
          this.loaderService.hide(true);
          return of(MailVerifySenderError({error: this.loaderService.getErrorMessage(error)}));
        })
      );
    })
  ));

  mailServiceProvider$ = createEffect(() => this._actions$.pipe(
    ofType(MailServiceProvider),
    switchMap((action) => {
      this.loaderService.show();
      return this.mailVerifyService.getMailServiceProvider(action.params).pipe(
        map((resp: any) => {
          if (resp.success) {
            this.loaderService.hide();
            return MailServiceProviderSuccess({serviceProviders: resp.data});
          }
          this.loaderService.hide(true);
          return MailServiceProviderError({error: this.loaderService.getErrorMessage(resp)});
        }),
        catchError(error => {
          this.loaderService.hide(true);
          return of(MailServiceProviderError({error: this.loaderService.getErrorMessage(error)}));
        })
      );
    })
  ));
}
