import {Injectable} from '@angular/core';
import {environment} from '@environments/environment';
import {HttpClient, HttpHeaders} from '@angular/common/http';

@Injectable({
  providedIn: 'root',
})
export class AwsService {
  private baseApiUrl = environment.apiUrl;
  private fileUrl = this.baseApiUrl + '/api/v1/file';

  constructor(private http: HttpClient) {
  }

  uploadFile(file, projectId: any = null, clientId: any = null, token: string | null = null) {
    // return new Promise((resolve, reject) => {
    //   this.signedURL(file).subscribe((signed) => {
    //     resolve(signed);
    //     // const headers = new HttpHeaders({'Content-Type': file.type, 'Skip-auth': 'true'});
    //     // const options = {headers, reportProgress: true};
    //     // this.http.put(signed['url'], file, options).subscribe(() => resolve(signed), (err) => reject(err));
    //   }, (err) => reject(err));
    // });

    //
    // Get file url
    let fileUrl: string = this.fileUrl;
    // Extend url with token if available
    if (token !== null) {
      fileUrl += `/token/${token}`;
    }

    const formData = new FormData();
    formData.append('file', file);
    if (projectId) {
      formData.append('projectId', projectId);
    }
    if (clientId) {
      formData.append('clientId', clientId);
    }
    const headers = new HttpHeaders({'Content-Type': 'multipart/form-data'});
    return this.http.post(`${fileUrl}`, formData, {headers});
  }

  getAwsSignedUrl(fileName, contentType, tag = null) {
    const fileUrl = `${this.fileUrl}/get-signed-url`;
    return this.http.get(`${fileUrl}`, {params: {fileName, contentType, tag}});
  }

  uploadFileToSignedUrl(url, fileData) {
    return this.http.put(url, fileData, {
      headers: new HttpHeaders().set('Skip-auth', 'true').set('Content-Type', fileData.type),
      reportProgress: true, observe: 'events'
    });
  }
}
