<app-template-styles *ngIf="template" [template]="template"></app-template-styles>
<div class="container-fluid pt-3 mobile-p0">
  <div class="rightBody">
    <div class="row">
      <div class="mx-auto {{selected_page_size['page_class']}}">
        <div *ngIf="blocks.length" style="margin-bottom: 5rem;" [ngClass]="{'template-wrapper': true, 'mobile': selected_page_size.name === 'mobile'}">
          <div (mouseenter)="showOptions(true, item)" (mouseleave)="showOptions(false, item)"
            *ngFor="let item of blocks; let i = index" class="example-box">
            <div *ngIf="item['operation_show']" class="options text-right">
              <button (click)="openModal(content, item)" class="btn btn-secondary">
                <i class="fa fa-pencil"></i>
              </button>
              <button (click)="removeSectionFromTemplate(item)" class="btn btn-secondary">
                <i class="fa fa-trash"></i>
              </button>
              <button (click)="moveDownItemInTemplate(item)" *ngIf="i < blocks.length-1" class="btn btn-secondary">
                <i class="fa fa-angle-down"></i>
              </button>
              <button (click)="moveUpItemInTemplate(item)" *ngIf="i > 0" class="btn btn-secondary">
                <i class="fa fa-angle-up"></i>
              </button>
            </div>
            <div class="w-100">
              <div [innerHTML]="item['body'] | sanitizeHtml:'html'"></div>
            </div>
          </div>
        </div>
      </div>
      <div *ngIf="blocks && blocks.length">
        <div class="bg-white mt-3 operation-box d-flex justify-content-between">
          <div>
            <button (click)="back()" class="btn btn-secondary mr-3">
              <span class='fa fa-chevron-left'></span>
            </button>
            <button (click)="save()" class="btn btn-success mr-3">
              <span>Save</span>
            </button>
            <a *ngIf="template_type_id == 1" class="btn btn-primary mr-3"
              (click)="preview()">
              <span>Preview</span>
            </a>
            <a *ngIf="template_type_id == 2" class="btn btn-primary mr-3"
              (click)="sendTestEmail()">
              <span>Send Test Email</span>
            </a>
          </div>
          <div class="select-page-size">
            <button (click)="selectPageSize(item)" *ngFor="let item of page_size_template"
              [ngClass]="{'active-size': item.is_active}" class="btn" style="outline: none; box-shadow: none;">
              <i class="fa {{item.icon}}"></i>
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>


<div class="modal fade" id="buttonChanger">
  <div class="modal-dialog">
    <div class="modal-content">

      <!-- Modal Header -->
      <div class="modal-header">
        <h4 class="modal-title">Changing button style</h4>
        <button class="close" data-dismiss="modal" type="button">×</button>
      </div>

      <!-- Modal body -->
      <div class="modal-body">
        <button [(colorPicker)]="color" [cpPosition]="'bottom'" class="btn btn-info w-100 h-100">
          <span>Select Background Color</span>
        </button>
      </div>

      <div class="modal-body">
        <button [(colorPicker)]="FontColor" [cpPosition]="'bottom'" class="btn btn-info w-100 h-100">
          <span>Select Font Color</span>
        </button>
      </div>


      <div class="modal-body">
        <label class="w-100">Link</label>
        <select class="btn btn-info w-100 h-100" [formControl]="buttonLinkType"
          (change)="onButtonLinkTypeChange($event)" (load)="onButtonLinkTypeChange($event)">
          <option value="#RegistrationLink#">Registration Link</option>
          <option value="custom">Custom Link</option>
        </select>
        <br /><br />
        <input [formControl]="buttonLink" class="input w-100" type="text">
      </div>

      <!-- Modal footer -->
      <div class="modal-footer">
        <button (click)="saveButtonStyle(color, FontColor, buttonLink.value)" class="btn btn-outline-dark"
          type="button">Save
        </button>
        <button id="buttonChangerClose" class="btn btn-danger" data-dismiss="modal" type="button">
          Close
        </button>
      </div>

    </div>
  </div>
</div>

<ng-template #content let-c="close" let-d="dismiss">
  <div class="modal-header">
    <h4 class="modal-title" id="modal-basic-title">
      Select Background For This Section
    </h4>
    <button (click)="d('Cross click')" aria-label="Close" class="close" type="button">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <div class="row">
      <div class="col-6">
        <button [(colorPicker)]="color" [cpPosition]="'bottom'" class="btn btn-outline-info w-100 h-100">
          <span>Select Background Color</span>
        </button>
      </div>
      <div class="col-6">
        <input (change)="selectBackgroundImage($event)" accept=".png, .jpg, .jpeg" class="input-file" id="file"
          name="file" type="file">
        <label class="w-100 h-100 m-0 btn btn-outline-info" for="file" style="cursor: pointer;">
          <span>Choose a file</span>
        </label>
      </div>
      <div *ngIf="is_uploaded_image" class="col-12 py-3 uploaded-image text-center">
        <div *ngIf="uploaded_image.length === 0">
          <span>Please wait until your image to upload</span>
          <div class="loading-box">
            <div class="spinner mx-auto my-3"></div>
          </div>
        </div>
        <div *ngIf="uploaded_image && uploaded_image.length">
          <img [src]="uploaded_image" alt="" class="w-100 h-100">
        </div>
      </div>
    </div>
  </div>
  <div class="modal-footer">
    <button (click)="customizeItem(selected_item)" class="btn btn-outline-dark" type="button">Save
    </button>
  </div>
</ng-template>
