import {Injectable} from '@angular/core';
import {environment} from '@environments/environment';
import {HttpClient} from '@angular/common/http';
import {Settings} from '@app/modules/shared/models';

@Injectable({providedIn: 'root'})
export class SettingsService {
  private baseApiUrl = environment.apiUrl;
  private settingUrl = this.baseApiUrl + '/api/v1/settings';
  private cacheUrl = this.baseApiUrl + '/api/v1/cache';

  constructor(private http: HttpClient) {
  }

  getSettings(params: any = {}) {
    return this.http.get(`${this.settingUrl}`, {params});
  }

  getSetting(id: number) {
    return this.http.get(`${this.settingUrl}/${id}`);
  }

  deleteSetting(settingId: number) {
    return this.http.delete(`${this.settingUrl}/${settingId}`);
  }

  updateSetting(settingId: number, setting: Settings) {
    return this.http.put(`${this.settingUrl}/${settingId}`, setting);
  }

  addSetting(setting: Settings) {
    return this.http.post(`${this.settingUrl}`, setting);
  }

  clearCache(pattern: string) {
    return this.http.post(`${this.cacheUrl}/clear`, {pattern});
  }
}
