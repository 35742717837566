<div class="container-fluid pt-3">
  <div class="rightBody">
    <!-- Add User Section  Start -->
    <div class="card">
      <div class="card-header py-3">
        <div class="card-header--title font-size-lg">
          {{platformId ? 'Update' : 'Add'}} Registration Platform
        </div>
      </div>
      <div class="card-body">
        <div class="form-wraper">
          <form (ngSubmit)="saveRegistrationPlatform()" [formGroup]="addRegistrationPlatformForm" autocomplete="off">
            <div class="row align-items-center">
              <div class="col-lg-4 ">
                <div class="floating-label floating-code-label registration-img">
                  <img [src]="imageBinary" *ngIf="imageBinary" class="img-fluid d-block m-auto"
                       id="event-logo-preview"/>
                  <div class='d-flex justify-content-center align-items-center'>
                    <button (click)="uploadLogo(fileInput)" [disabled]="submitted && fileUploadProgress > 0 && fileUploadProgress < 100" class="btn btn-default" id="logoUploaderBtn" type="button">
                      <li class="fa fa-upload"></li>
                      {{fileUploadProgress ? fileUploadProgress + '%' : ''}} {{imageBinary ? 'Replace' : 'Upload'}} Logo
                    </button>
                    <app-info-popover
                      info="Acceptable formats are jpeg and png. (Min  800x800px, max 2048x2048px)">
                    </app-info-popover>
                    <input #fileInput (change)="onEventImageChanged($event);" accept="image/*"
                           class="file-input" id="projectLogo" type="file">
                  </div>
                </div>
              </div>
              <div class="col-lg-8 col-md-12 col-ms-12">
                <div class="row">
                  <div class="col-md-6">
                    <div class="floating-label">
                      <input  (blur)="!this.platformId && generateSlug();!this.platformId && validateSlug();" formControlName="name" placeholder=" "
                             required="required" class="floating-input"
                             type="text" autocomplete="off"/>
                      <label>Name <span class="red">*</span>
                      </label>
                      <div *ngIf="submitted && f.name.errors" class="bar error-brd">
                        <div *ngIf="f.name.errors.required" class="invalid-text">Platform name is required</div>
                      </div>

                    </div>

                  </div>
                  <div *ngIf="!this.platformId" class="col-lg-6">
                    <div class="floating-label input-group input-group-sm info-input">
                      <input
                        (blur)="validateSlug()"
                        [ngClass]="{ 'is-invalid': submitted && f.slug.errors }"
                        class="floating-input slugInput"
                        formControlName="slug"
                        placeholder=" "
                        required="required"
                        type="text"
                      />
                      <span class="highlight"></span>
                      <label
                      >Platform's Slug
                        <span class="red">*</span>
                      </label>
                      <div
                        *ngIf="submitted && f.slug.errors"
                        class="bar error-brd"
                      >
                        <div
                          *ngIf="f.slug.errors.required"
                          class="invalid-text"
                        >
                          Platform's Slug is required
                        </div>
                      </div>
                      <app-info-popover
                        info="This is a unique key for this Platform.">
                      </app-info-popover>
                    </div>
                  </div>

                  <div class="col-md-6">
                    <div class="floating-label">
                      <input formControlName="website" placeholder=" "
                             required="required" class="floating-input"
                             type="text" autocomplete="off"/>
                      <label>Website <span class="red">*</span>
                      </label>
                      <div *ngIf="submitted && f.website.errors" class="bar error-brd">
                        <div *ngIf="f.website.errors.required" class="invalid-text">Website name is required</div>
                        <div *ngIf="f.website.errors !== null && !f.website.errors.required" class="invalid-text">Please enter valid Website name</div>
                      </div>
                    </div>
                  </div>

                  <div class="col-md-6">
                    <app-creatable-select
                      [hideLabel]="true" [isCreatable]="false" [onlyBottomBorder]="false"
                      [placeholder]="'Select Widget Support'" [selectOptions]="widgetSupportValues"
                      [selectedValue]="selectedWidgetSupport"
                      (optionSelected)="setWidgetSupport($event)"
                      ></app-creatable-select>
<!--                    <label class="widget-integration-css">Widget Support</label>-->
                  </div>
                  <div class="col-md-6">
                    <app-creatable-select
                      [hideLabel]="true" [isCreatable]="false" [onlyBottomBorder]="false"
                      [selectedValue]="selectedSyncSupport"
                      [placeholder]="'Select Sync Support'" [selectOptions]="syncSupportValues"
                      (optionSelected)="setSyncSupport($event)"
                    ></app-creatable-select>
<!--                    <label class="widget-integration-css">Sync Support</label>-->
                  </div>
                </div>
              </div>

              <div class="col-lg-12 col-md-12 col-ms-12">
                <div class="floating-label floating-code-label">
                      <textarea  formControlName="settings" class="form-control floating-input"
                                 placeholder=" " type="text" rows="7">
                      </textarea>
                  <label>Settings</label>
                </div>
              </div>
              <div *ngIf="!isLoadEditor" class="col-lg-12 col-md-12 col-ms-12 spinner-container">
                <i class="fa fa-spinner fa-spin"></i>
              </div>
              <div [ngClass]="{'d-none': !isLoadEditor}" class="col-lg-12 col-md-12 col-ms-12">
                  <label class="widget-integration-css">Widget Integration Guide</label>
                 <textarea cols="80" id="editor1" name="editor"></textarea>
              </div>

              <div *ngIf="!isApiGuideLoadEditor" class="col-lg-12 col-md-12 col-ms-12 spinner-container">
                <i class="fa fa-spinner fa-spin"></i>
              </div>
              <div [ngClass]="{'d-none': !isApiGuideLoadEditor}" class="col-lg-12 col-md-12 col-ms-12 mt-2">
                <label class="widget-integration-css">Api Integration Guide</label>
                <textarea cols="80" id="editor2" name="apiEditor"></textarea>
              </div>
            </div>

            <div class="col-lg-12 col-md-12 col-ms-12 p-0 mt-3">
              <input formControlName="hide_default_guide" placeholder="" type="checkbox" class='mr-2' (change)="onChangeCheckbox($event, 'hide_default_guide')">
              <label class="widget-integration-css">Hide Default Guide</label>
            </div>
            <div class="col-lg-12 col-md-12 col-ms-12 p-0">
              <div class="floating-label floating-code-label">
                      <textarea  formControlName="widget_customization_code" class="form-control floating-input"
                                placeholder=" " type="text" rows="7">
                      </textarea>
                <label>Widget Customization Code</label>
              </div>
            </div>

            <div class="col-lg-12 col-md-12 col-ms-12 p-0">
              <div class="floating-label floating-code-label">
                      <textarea  formControlName="widget_customization_css" class="form-control floating-input"
                                placeholder=" " type="text" rows="7">
                      </textarea>
                <label>Widget Customization CSS</label>
              </div>
            </div>
            <div _ngcontent-c4="" class="text-right mt-3">
              <button _ngcontent-c4="" class="btn btn-default mr-2" (click)="cancelClick()">Cancel</button>
              <button _ngcontent-c4="" class="btn btn-primary mr-2" type="submit">Save</button>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</div>
