import {Component, OnDestroy, OnInit} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {TemplateGeneratorService} from '@app/services/template-generator.service';
import * as moment from 'moment-timezone';
import * as routes from '@app/routes';

import {LANDING_PAGE_TEMPLATE_TYPE_ID, INFLUENCER_TYPES} from '@app/consts';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {select, Store} from '@ngrx/store';
import {
  getInfluencersTypes,
  getTemplateCampaign,
  IInfluencersState,
  InfluencersTypeList,
  ITemplateState, ResetTemplateState,
  TemplateCampaign
} from '@app/stores';
import {takeUntil} from 'rxjs/operators';
import {Subject, Subscription} from 'rxjs';
import {InfluencerType} from '@app/modules/shared/models';
import * as _ from 'lodash';
import {SocketService} from '@app/services/socket.service';
import { RenameTitleModalComponent } from '@app/modules/rename-title-modal/rename-title-modal.component';
import { TemplateService } from '@app/services/template.service';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-select-template',
  templateUrl: './select-template.component.html',
  styleUrls: ['./select-template.component.scss'],
})
export class SelectTemplateComponent implements OnInit, OnDestroy {
  public step;
  public campaign;
  public segment;
  public snoTemplates = [];
  public clientTemplates = [];
  public campaignTemplate: any = {};
  public currentClientTemplate;
  public thumbnail: string;
  public thumbnailModel: any;
  unsubscriber = new Subject();
  subscription: Subscription;
  private influencerTypes: InfluencerType[] = [];

  constructor(
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private templateStore: Store<ITemplateState>,
    private influencerStore: Store<IInfluencersState>,
    private templateGeneratorService: TemplateGeneratorService,
    private modalService: NgbModal,
    private socketService: SocketService,
    private readonly templateService: TemplateService
  ) {
    this.templateStore.dispatch(ResetTemplateState({params: {templateCampaign: null}}));
    this.subscribeStore();
    this.subscription = this.socketService.sourceData
      .subscribe((message: any) => {
          if (message?.data?.requesterId) {
            let temp = _.cloneDeep(this.clientTemplates);
            temp.map(element => {
              if (element.id === message.data.requesterId && message.data?.requesterType === 'template') {
                element.thumbnail = message.data.result.thumbnail;
              }
              return element;
            });
            this.clientTemplates = [...temp];

            temp = _.cloneDeep(this.snoTemplates);
            temp.map(element => {
              if (element.id === message.data.requesterId && message.data?.requesterType === 'template') {
                element.thumbnail = message.data.result.thumbnail;
              }
              return element;
            });
            this.snoTemplates = [...temp];
          }
        },
        (err) => console.error(err),
        () => console.warn('Completed!')
      );
  }

  subscribeStore() {
    this.templateStore.pipe(select(getTemplateCampaign))
      .pipe(takeUntil(this.unsubscriber))
      .subscribe(templateCampaign => {
        if (templateCampaign) {
          this.clientTemplates = templateCampaign.templates;
          this.snoTemplates = templateCampaign.publicTemplates;
          this.campaignTemplate = templateCampaign.campaignTemplate;
          if (this.campaignTemplate && !this.activatedRoute.snapshot.queryParams?.select) {
            this.onNextButtonClick(this.campaignTemplate);
            return;
          }
          // if (this.campaignTemplate) {
          //   this.clientTemplates = [
          //     this.campaignTemplate,
          //     ...this.clientTemplates
          //   ];
          // }
        }
      });
    this.influencerStore.pipe(select(getInfluencersTypes))
      .pipe(takeUntil(this.unsubscriber))
      .subscribe(influencerTypes => {
        if (influencerTypes && influencerTypes.length > 0) {
          this.influencerTypes = influencerTypes;
        }
      });
  }

  ngOnInit() {
    this.templateGeneratorService.forceReloadCampaign();
    this.templateGeneratorService.subscribeStore();
    this.templateGeneratorService.clearTemplate(LANDING_PAGE_TEMPLATE_TYPE_ID);
    this.templateGeneratorService.onCampaignLoaded
      .pipe(takeUntil(this.unsubscriber))
      .subscribe(campaign => {
        if (!campaign) {
          return;
        }

        this.campaign = campaign;

        if (!campaign.segments.length) {
          this.router.navigateByUrl(routes.addInfluencers({projectId: this.campaign.project_id}, {campId: this.campaign.id}));
          return;
        }

        this.segment = campaign.segments[0];
        this.setStatusBar();

        const params = {
          campaign_id: this.campaign.id,
          influencer_type_id: this.segment.influencer_type_id,
          template_type_id: LANDING_PAGE_TEMPLATE_TYPE_ID
        };
        this.templateStore.dispatch(TemplateCampaign({params}));
        this.influencerStore.dispatch(InfluencersTypeList());
      });
  }

  setStatusBar() {
    this.step = {
      title: 'Content',
      params: {
        projectId: this.campaign.project_id,
        campaignId: this.campaign.id,
        campaignName: this.campaign.name
      }
    };
  }

  onTemplateSelect(type, index, force) {
    const template = this[type][index];
    if (template.campaign_id !== this.campaign.id) {
      force = true;
    }
    if (force) {
      this.templateGeneratorService.touchTemplate({templateTypeId: LANDING_PAGE_TEMPLATE_TYPE_ID, sourceTemplateId: template.id});
    }

    this.onNextButtonClick(template);
  }

  onSaveButtonClick() {
    this.router.navigateByUrl('/' + routes.campaigns({projectId: this.campaign.project_id}));
  }

  parseTimeAgo(timeStamp) {
    return moment(timeStamp).fromNow();
  }

  influencerType() {
    if (!this.segment) {
      return;
    }
    const influencerType = this.influencerTypes.find(item => item.id === Number(this.segment.influencer_type_id));
    if (influencerType) {
      return influencerType.title.toLowerCase();
    }
    return '';
  }

  onBackButtonClick() {
    this.router.navigateByUrl(routes.addInfluencers({projectId: this.campaign.project_id}, {campId: this.campaign.id}));
  }

  onNextButtonClick(template: any = null) {
    let params: any = {blank: true};
    if (template) {
      params = {sourceTemplateId: template.id};
    } else {
      if (this.campaignTemplate) {
        params = {sourceTemplateId: this.campaignTemplate.id};
      }
    }
    this.router.navigateByUrl(routes.campaignTemplates({projectId: this.campaign.project_id, campaignId: this.campaign.id}, params));
  }

  blankTemplateRedirect() {
    const params: any = {blank: true};
    this.router.navigateByUrl(routes.campaignTemplates({projectId: this.campaign.project_id, campaignId: this.campaign.id}, params));
  }

  showTemplateThumbnail(content, thumbnail: string) {
    if (thumbnail) {
      this.thumbnailModel = this.modalService.open(content, {size: 'xl', centered: true});
      this.thumbnail = thumbnail;
    }
  }

  ngOnDestroy() {
    this.unsubscriber.next();
    this.unsubscriber.complete();
    this.templateGeneratorService.unsubscribe();
    this.subscription.unsubscribe();
  }

  openRenameModal(template: any) {
    //
    // Validate template
    if (!template?.id) return;

    //
    // Open modal
    const modelRef = this.modalService.open(RenameTitleModalComponent, {
      centered: false,
      size: 'sm',
      keyboard: true,
    });

    //
    // Add data to modal
    modelRef.componentInstance.title = 'Template';
    modelRef.componentInstance.value = template.name;

    //
    // On close modal event
    // should validate and update the template
    modelRef.result.then(async (result: any = {}) => {
      //
      // Validate result
      if (!result?.value) return;
      // update database and reload
      await this.templateService.updateAll(template.id, { name: result.value }).toPromise();
      this.updateTemplates();
    });
  }

  async removeTemplate(template: any) {
    const confirm: any = await Swal({
      title: 'Delete Template?',
      type: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Yes',
      cancelButtonText: 'No'
    });

    //
    // Validate
    if(!confirm.value) return;

    //
    // Delete
    await this.templateService.deleteTemplate(template.id).toPromise();
    // update templates
    this.updateTemplates();
  }

  updateTemplates() {
    this.templateStore.dispatch(
      TemplateCampaign({
        params: {
          campaign_id: this.campaign.id,
          influencer_type_id: this.segment.influencer_type_id,
          template_type_id: LANDING_PAGE_TEMPLATE_TYPE_ID,
        }
      })
    );
  }
}
