import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-social-share-preview',
  templateUrl: 'social-share-preview.component.html',
  styleUrls: ['./social-share-preview.component.scss']
})

export class SocialSharePreview implements OnInit {
  @Input() socialMedia: any;
  @Input() title: any;
  @Input() image: any;
  @Input() contentType: any;
  @Input() description: any;
  @Input() message: any;
  @Input() emailTitle: any;
  @Input() emailBody: any;
  @Input() url: any;

  ngOnInit() { }

  getImage() {
    return this.image || '../../../assets/images/missing.png';
  }
}
