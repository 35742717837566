import {Component, OnInit} from '@angular/core';
import {NgbActiveModal} from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-campaign-influencer-view',
  templateUrl: './influencer-view.component.html',
  styleUrls: ['./influencer-view.component.css']
})
export class InfluencerViewComponent implements OnInit {
  projectId: number;
  influencerId: number;

  constructor(
    private _modal: NgbActiveModal
  ) {
  }

  ngOnInit() {
  }

  closeModal() {
    this._modal.close(true);
  }
}
