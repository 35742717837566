import {Injectable} from '@angular/core';
import {
  HttpClient
} from '@angular/common/http';

import {environment} from '@environments/environment';

@Injectable({providedIn: 'root'})
export class UserService {

  private baseApiUrl = environment.apiUrl;
  private userUrl = this.baseApiUrl + '/api/v1/user';

  constructor(private http: HttpClient) {
  }

  getAll(params?: any) {
    return this.http.get(`${this.userUrl}`, {params});
  }

  getById(id: number) {
    return this.http.get(`${this.userUrl}/${id}`);
  }

  getRoles() {
    return this.http.get(`${this.userUrl}/roles`);
  }

  addUser(body: any) {
    return this.http.post(`${this.userUrl}`, body);
  }

  editUser(body: any, id: number) {
    return this.http.put(`${this.userUrl}/${id}`, body);
  }

  deleteUser(id: number) {
    return this.http.delete(`${this.userUrl}/${id}`);
  }

  UserLoginInfoMail(id: number) {
    return this.http.get(`${this.userUrl}/${id}/sendLoginInfoMail`);
  }
}
