import {NgModule} from '@angular/core';
import {Routes, RouterModule} from '@angular/router';
import {TemplatesComponent} from './templates/templates.component';
import {CreateTemplateComponent} from '@app/modules/template/create-template/create-template.component';


const routes: Routes = [
  {
    path: '',
    children: [
      {
        path: '',
        component: TemplatesComponent
      },
      {
        path: 'add',
        component: CreateTemplateComponent
      },
      {
        path: 'edit/:id',
        component: CreateTemplateComponent
      },
    ]
  }];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class TemplateRoutingModule {
}
