import { NgModule }           from '@angular/core';
import { StatusBarComponent } from '@app/modules/status-bar/status-bar.component';
import { CommonModule }       from '@angular/common';
import { RouterModule }       from '@angular/router';

import * as routes from '@app/routes';
import {SharedModule} from '@app/modules/shared/shared.module';

@NgModule ({
    imports: [
        CommonModule,
        RouterModule,
        SharedModule
    ],
  declarations: [
    StatusBarComponent
  ],
  exports     : [
    StatusBarComponent
  ]
})
export class StatusBarModule {
  routes = routes;
}
