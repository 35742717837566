import { Injectable } from "@angular/core";
import { BehaviorSubject, Observable } from "rxjs";

@Injectable({ providedIn: "root" })

export class SharedService {
  private backgroundContainerSubject: BehaviorSubject<boolean>;
  private imageContainerSubject: BehaviorSubject<boolean>;
  private editorSubject: BehaviorSubject<string>;
  private editorContentSubject: BehaviorSubject<string>;
  private adminTemplateSubject: BehaviorSubject<boolean>;
  private backgrounIdSubject: BehaviorSubject<string>;
  private fontIdSubject: BehaviorSubject<string>;
  private updateTemplateSubject: BehaviorSubject<boolean>;

  public backgroundContainer: Observable<boolean>;
  public imageContainer: Observable<boolean>;
  public editorCode: Observable<string>;
  public editorContent: Observable<string>;
  public isAdminTemplate: Observable<boolean>;
  public backgrounId: Observable<string>;
  public fontId: Observable<string>;
  public isUpdateTemplate: Observable<boolean>;


  constructor() {
    this.backgroundContainerSubject = new BehaviorSubject<boolean>(false);
    this.imageContainerSubject = new BehaviorSubject<boolean>(false);
    this.editorSubject = new BehaviorSubject<string>(null);
    this.editorContentSubject = new BehaviorSubject<string>(null);
    this.adminTemplateSubject = new BehaviorSubject<boolean>(false);
    this.backgrounIdSubject = new BehaviorSubject<string>(null);
    this.fontIdSubject = new BehaviorSubject<string>(null);
    this.updateTemplateSubject = new BehaviorSubject<boolean>(false);


    this.backgroundContainer = this.backgroundContainerSubject.asObservable();
    this.imageContainer = this.imageContainerSubject.asObservable();
    this.editorCode = this.editorSubject.asObservable();
    this.editorContent = this.editorContentSubject.asObservable();
    this.isAdminTemplate = this.adminTemplateSubject.asObservable();
    this.backgrounId = this.backgrounIdSubject.asObservable();
    this.fontId = this.fontIdSubject.asObservable();
    this.isUpdateTemplate = this.updateTemplateSubject.asObservable();
  }

  public get getEditorCode(): string {
    return this.editorSubject.value;
  }

  public get getBackgroundContainer(): object {
    return this.backgroundContainerSubject;
  }

  public get getImageContainer(): boolean {
    return this.imageContainerSubject.value;
  }

  public get getEditorContent(): string {
    return this.editorSubject.value;
  }

  public get getTemplateValue(): boolean {
    return this.adminTemplateSubject.value;
  }

  public get getBackgrounId(): string {
    return this.backgrounIdSubject.value;
  }

  public get getFontId(): string {
    return this.fontIdSubject.value;
  }

  public get influencerHeaderValue(): boolean {
    return this.updateTemplateSubject.value;
  }

  setEditorCode(code: string) {
    this.editorSubject.next(code);
  }

  updatebackgroundContainer(value: boolean) {
    this.backgroundContainerSubject.next(value);
  }

  updateImageContainer(value: boolean) {
    this.imageContainerSubject.next(value);
  }

  setEditorContent(code: string) {
    this.editorContentSubject.next(code);
  }

  updateAdminTemplate(value: boolean = false) {
    this.adminTemplateSubject.next(value);
  }

  setBackgroundId(value: string) {
    this.backgrounIdSubject.next(value);
  }

  setFontId(value: string) {
    this.fontIdSubject.next(value);
  }

  updateInfluencerHeader(value: boolean = false) {
    this.updateTemplateSubject.next(value);
  }




}
