import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {ClientListComponent} from './client-list/client-list.component';
import {ClientAddComponent} from './client-add/client-add.component';
import {ClientRoutingModule} from '@app/modules/client/client-routing.module';
import {DataTablesModule} from 'angular-datatables';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {NgbPopoverModule} from '@ng-bootstrap/ng-bootstrap';
import {SharedModule} from '@app/modules/shared/shared.module';
import {BsDatepickerModule} from 'ngx-bootstrap/datepicker';
import { MatLegacyMenuModule as MatMenuModule } from '@angular/material/legacy-menu';
import { PopoverModule } from 'ngx-bootstrap/popover';


@NgModule({
  declarations: [ClientListComponent, ClientAddComponent],
  imports: [
    CommonModule,
    ClientRoutingModule,
    DataTablesModule,
    FormsModule,
    NgbPopoverModule,
    ReactiveFormsModule,
    SharedModule,
    BsDatepickerModule,
    MatMenuModule,
    PopoverModule.forRoot(),
  ]
})
export class ClientModule {
}
