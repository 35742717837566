import {Injectable} from '@angular/core';
import {Actions, createEffect, ofType} from '@ngrx/effects';
import {Store} from '@ngrx/store';
import {catchError, map, switchMap} from 'rxjs/operators';
import {of} from 'rxjs';
import {LoaderService} from '@app/services/loader.service';
import {
  FileUploadError, FileUploadSuccess, FileUpload,
  SignedUrlError, SignedUrlSuccess, SignedUrl,
  UploadToSignedUrlError, UploadToSignedUrlSuccess, UploadToSignedUrl, UploadToSignedUrlProgress
} from '@app/stores/file-upload/file-upload.actions';
import {IFileUploadState} from '@app/stores/file-upload/file-upload.state';
import {AwsService} from '@app/services/aws.service';
import {Observable} from 'rxjs/Observable';
import {HttpEventType} from '@angular/common/http';

@Injectable()
export class FileUploadEffects {
  constructor(
    private _actions$: Actions,
    private _store: Store<IFileUploadState>,
    private awsService: AwsService,
    private loaderService: LoaderService
  ) {
  }

  fileUpload$ = createEffect(() => this._actions$.pipe(
    ofType(FileUpload),
    switchMap((action) => {
      this.loaderService.show();
      return this.awsService.uploadFile(action.file, action.projectId, action.clientId).pipe(
        map((resp: any) => {
          if (resp.success) {
            this.loaderService.hide();
            return FileUploadSuccess({file: resp.data});
          }
          this.loaderService.hide(true);
          return FileUploadError({error: this.loaderService.getErrorMessage(resp)});
        }),
        catchError(error => {
          this.loaderService.hide(true);
          return of(FileUploadError({error: this.loaderService.getErrorMessage(error)}));
        })
      );
    })
  ));

  signedUrl$ = createEffect(() => this._actions$.pipe(
    ofType(SignedUrl),
    switchMap((action) => {
      this.loaderService.show();
      return this.awsService.getAwsSignedUrl(action.fileName, action.contentType).pipe(
        map((resp: any) => {
          if (resp.success) {
            this.loaderService.hide();
            return SignedUrlSuccess({url: resp.data.url, file: resp.data.file});
          }
          this.loaderService.hide(true);
          return SignedUrlError({error: this.loaderService.getErrorMessage(resp)});
        }),
        catchError(error => {
          this.loaderService.hide(true);
          return of(SignedUrlError({error: this.loaderService.getErrorMessage(error)}));
        })
      );
    })
  ));

  uploadToSignedUrl$ = createEffect(() => this._actions$.pipe(
    ofType(UploadToSignedUrl),
    switchMap((action) => {
      this.loaderService.show();
      return (new Observable(subscriber => {
        let totalPercent = 0;
        const callApis = async () => {
          for (let inx = 0; inx < action.files.length; inx++) {
            await new Promise((resolve, reject) => {
              this.awsService.uploadFileToSignedUrl(action.files[inx].url, action.files[inx].fileData).subscribe((resp) => {
                if (resp.type === HttpEventType.UploadProgress) {
                  const percentDone = (Math.round(100 * resp.loaded / resp.total) / action.files.length);
                  this._store.dispatch(UploadToSignedUrlProgress({progress: percentDone + totalPercent}));
                }
                if (resp.type === HttpEventType.Response) {
                  totalPercent = (100 / action.files.length) * (inx + 1);
                  resolve(resp);
                }
              }, reject);
            });
          }
        };
        callApis().then(() => {
          subscriber.next(true);
          subscriber.complete();
        }).catch(e => {
          subscriber.error(e);
          subscriber.complete();
        });
      })).pipe(
        map((resp: any) => {
          this.loaderService.hide();
          return UploadToSignedUrlSuccess({uploaded: true});
        }),
        catchError(error => {
          this.loaderService.hide(true);
          return of(UploadToSignedUrlError({error: this.loaderService.getErrorMessage(error)}));
        })
      );
    })
  ));
}
