import {Action, createReducer, on} from '@ngrx/store';
import {
  ResetTrackingTypeState,
  TrackingType,
  TrackingTypeError,
  TrackingTypeSuccess
} from "@app/stores/tracking-type/tracking-type.actions";
import {initialTrackingTypeState} from "@app/stores/tracking-type/tracking-type.state";

const generateReducer = createReducer(initialTrackingTypeState,
  on(TrackingType, (state) => ({
    ...state,
    trackingType: [],
    success: '',
    error: ''
  })),
  on(TrackingTypeSuccess, (state, {trackingType}) => ({
    ...state,
    trackingType,
    success: '',
    error: ''
  })),
  on(TrackingTypeError, (state, {error}) => ({
    ...state,
    trackingType: [],
    success: '',
    error
  })),

  on(ResetTrackingTypeState, (state, {params}) => ({
    ...state,
    ...params
  })),
);

export function trackingTypeReducer(state = initialTrackingTypeState, action: Action) {
  return generateReducer(state, action);
}
