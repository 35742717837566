import {Injectable} from '@angular/core';
import {Actions, createEffect, ofType} from '@ngrx/effects';
import {Store} from '@ngrx/store';
import {catchError, map, mergeMap, switchMap} from 'rxjs/operators';
import {of} from 'rxjs';
import {LoaderService} from '@app/services/loader.service';
import {
  RegistrationPlatformList,
  RegistrationPlatformListError,
  RegistrationPlatformListSuccess,
  RegistrationPlatformUpdate,
  RegistrationPlatformUpdateSuccess,
  RegistrationPlatformUpdateError,
  RegistrationPlatformGet,
  RegistrationPlatformGetSuccess,
  RegistrationPlatformGetError,
  RegistrationPlatformCreate,
  RegistrationPlatformCreateSuccess,
  RegistrationPlatformCreateError,
  RegistrationPlatformDelete,
  RegistrationPlatformListPaginated,
  RegistrationPlatformListPaginatedSuccess,
  RegistrationPlatformListPaginatedError,
  RegistrationPlatformDeleteSuccess,
  RegistrationPlatformDeleteError,
  RegistrationPlatformWidgetGuideURL,
  RegistrationPlatformWidgetGuideURLSuccess,
  RegistrationPlatformWidgetGuideURLError,
  InfluencersSync,
  InfluencersSyncError,
  InfluencersSyncSuccess,
  ResetRegistrationPlatformState,
  InfluencersSyncStatus,
  InfluencersSyncStatusSuccess,
  InfluencersSyncStatusError,
  RegistrationPlatformValidateSlugError, RegistrationPlatformValidateSlug, RegistrationPlatformValidateSlugSuccess
} from '@app/stores/registration-platform/registration-platform.actions';
import {IRegistrationPlatformState} from '@app/stores/registration-platform/registration-platform.state';
import {RegistrationPlatformService} from '@app/services/registration-platform.service';

@Injectable()
export class RegistrationPlatformEffects {
  constructor(
    private _actions$: Actions,
    private _store: Store<IRegistrationPlatformState>,
    private registrationPlatformService: RegistrationPlatformService,
    private loaderService: LoaderService
  ) {
  }

  registrationPlatformCreate$ = createEffect(() => this._actions$.pipe(
    ofType(RegistrationPlatformCreate),
    switchMap((action) => {
      this.loaderService.show();
      return this.registrationPlatformService.create(action.registrationPlatform).pipe(
        map((resp: any) => {
          if (resp.success) {
            this.loaderService.hide();
            return RegistrationPlatformCreateSuccess({registrationPlatform: resp.data});
          }
          this.loaderService.hide(true);
          return RegistrationPlatformCreateError({error: this.loaderService.getErrorMessage(resp)});
        }),
        catchError(error => {
          this.loaderService.hide(true);
          return of(RegistrationPlatformCreateError({error: this.loaderService.getErrorMessage(error)}));
        })
      );
    })
  ));

  registrationPlatformListing$ = createEffect(() => this._actions$.pipe(
    ofType(RegistrationPlatformList),
    mergeMap((action) => {
      this.loaderService.show();
      return this.registrationPlatformService.getAllRegistrationPlatforms(action.params).pipe(
        map((resp: any) => {
          if (resp.success) {
            this.loaderService.hide();
            return RegistrationPlatformListSuccess({registrationPlatforms: resp.data});
          }
          this.loaderService.hide(true);
          return RegistrationPlatformListError({error: this.loaderService.getErrorMessage(resp)});
        }),
        catchError(error => {
          this.loaderService.hide(true);
          return of(RegistrationPlatformListError({error: this.loaderService.getErrorMessage(error)}));
        })
      );
    })
  ));

  registrationPlatformListPaginated$ = createEffect(() => this._actions$.pipe(
    ofType(RegistrationPlatformListPaginated),
    switchMap((action) => {
      this.loaderService.show();
      return this.registrationPlatformService.getAllRegistrationPlatforms(action.params).pipe(
        map((resp: any) => {
          if (resp.success) {
            this.loaderService.hide();
            return RegistrationPlatformListPaginatedSuccess({paginatedRegistrationPlatform: resp.data});
          }
          this.loaderService.hide(true);
          return RegistrationPlatformListPaginatedError({error: this.loaderService.getErrorMessage(resp)});
        }),
        catchError(error => {
          this.loaderService.hide(true);
          return of(RegistrationPlatformListPaginatedError({error: this.loaderService.getErrorMessage(error)}));
        })
      );
    })
  ));

  registrationPlatformDelete$ = createEffect(() => this._actions$.pipe(
    ofType(RegistrationPlatformDelete),
    switchMap((action) => {
      this.loaderService.show();
      return this.registrationPlatformService.deleteRegistrationPlatform(action.registrationPlatformId).pipe(
        map((resp: any) => {
          if (resp.success) {
            this.loaderService.hide();
            return RegistrationPlatformDeleteSuccess();
          }
          this.loaderService.hide(true);
          return RegistrationPlatformDeleteError({error: this.loaderService.getErrorMessage(resp)});
        }),
        catchError(error => {
          this.loaderService.hide(true);
          return of(RegistrationPlatformDeleteError({error: this.loaderService.getErrorMessage(error)}));
        })
      );
    })
  ));

  registrationPlatformUpdate$ = createEffect(() => this._actions$.pipe(
    ofType(RegistrationPlatformUpdate),
    switchMap((action) => {
      this.loaderService.show();
      return this.registrationPlatformService.update(action.registrationPlatformId, action.registrationPlatform).pipe(
        map((resp: any) => {
          if (resp.success) {
            this.loaderService.hide();
            return RegistrationPlatformUpdateSuccess();
          }
          this.loaderService.hide(true);
          return RegistrationPlatformUpdateError({error: this.loaderService.getErrorMessage(resp)});
        }),
        catchError(error => {
          this.loaderService.hide(true);
          return of(RegistrationPlatformUpdateError({error: this.loaderService.getErrorMessage(error)}));
        })
      );
    })
  ));

  registrationPlatformGet$ = createEffect(() => this._actions$.pipe(
    ofType(RegistrationPlatformGet),
    switchMap((action) => {
      this.loaderService.show();
      return this.registrationPlatformService.getById(action.registrationPlatformId).pipe(
        map((resp: any) => {
          if (resp.success) {
            this.loaderService.hide();
            return RegistrationPlatformGetSuccess({registrationPlatform: resp.data});
          }
          this.loaderService.hide(true);
          return RegistrationPlatformGetError({error: this.loaderService.getErrorMessage(resp)});
        }),
        catchError(error => {
          this.loaderService.hide(true);
          return of(RegistrationPlatformGetError({error: this.loaderService.getErrorMessage(error)}));
        })
      );
    })
  ));

  registrationPlatformWidgetGuideUrl$ = createEffect(() => this._actions$.pipe(
    ofType(RegistrationPlatformWidgetGuideURL),
    switchMap((action) => {
      this.loaderService.show();
      return this.registrationPlatformService.previewUrl(action.platformId).pipe(
        map((resp: any) => {
          if (resp.success) {
            this.loaderService.hide();
            return RegistrationPlatformWidgetGuideURLSuccess({widgetGuideUrl: resp.data});
          }
          this.loaderService.hide(true);
          return RegistrationPlatformWidgetGuideURLError({error: this.loaderService.getErrorMessage(resp)});
        }),
        catchError(error => {
          this.loaderService.hide(true);
          return of(RegistrationPlatformWidgetGuideURLError({error: this.loaderService.getErrorMessage(error)}));
        })
      );
    })
  ));

  influencersSync$ = createEffect(() => this._actions$.pipe(
    ofType(InfluencersSync),
    switchMap((action) => {
      this.loaderService.show();
      return this.registrationPlatformService.syncInfluencers(action.data).pipe(
        map((resp: any) => {
          if (resp.success) {
            this.loaderService.hide();
            return InfluencersSyncSuccess({influencersSync: resp.data});
          }
          this.loaderService.hide(true);
          return InfluencersSyncError({error: this.loaderService.getErrorMessage(resp)});
        }),
        catchError((error: any) => {
          console.log(error);
          this.loaderService.hide(true);
          return of(InfluencersSyncSuccess({influencersSync: error.error}));
          // return of(InfluencersImportError({error: this.loaderService.getErrorMessage(error)}));
        })
      );
    })
  ));

  influencersSyncStatus$ = createEffect(() => this._actions$.pipe(
    ofType(InfluencersSyncStatus),
    switchMap((action) => {
      this.loaderService.show();
      return this.registrationPlatformService.syncInfluencerStatus(action.syncJobId).pipe(
        map((resp: any) => {
          if (resp.success) {
            this.loaderService.hide();
            return InfluencersSyncStatusSuccess({influencersSync: resp.data});
          }
          this.loaderService.hide(true);
          return InfluencersSyncStatusError({error: this.loaderService.getErrorMessage(resp)});
        }),
        catchError((error: any) => {
          console.log(error);
          this.loaderService.hide(true);
          return of(InfluencersSyncStatusSuccess({influencersSync: error.error}));
        })
      );
    })
  ));

  registrationPlatformValidateSlug$ = createEffect(() => this._actions$.pipe(
    ofType(RegistrationPlatformValidateSlug),
    switchMap((action) => {
      this.loaderService.show();
      return this.registrationPlatformService.validateProjectSlug(action.slug).pipe(
        map((resp: any) => {
          if (resp.success) {
            this.loaderService.hide();
            return RegistrationPlatformValidateSlugSuccess({slug: resp.data});
          }
          this.loaderService.hide(true);
          return RegistrationPlatformValidateSlugError({error: this.loaderService.getErrorMessage(resp)});
        }),
        catchError(error => {
          this.loaderService.hide(true);
          return of(RegistrationPlatformValidateSlugError({error: this.loaderService.getErrorMessage(error)}));
        })
      );
    })
  ));

}

