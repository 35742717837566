import { Pipe, PipeTransform } from '@angular/core';
import {smartDateFormat} from '@app/consts';

@Pipe({
  name: 'tzDate'
})
export class TzDatePipe implements PipeTransform {
  private currentUser = JSON.parse(localStorage.getItem('currentUser'));

  transform(timeStamp: string | any, toUTC: boolean = false, toFormat: string = 'DD MMM h:mm A') {
    if (!timeStamp) {
      return '-';
    }
    let m;
    if (toUTC) {
      m = this.toUTCMomentObject(timeStamp);
    } else {
      m = this.toLocalMomentObject(timeStamp);
    }
    return m.isValid() ? m.format(toFormat) : '-';
  }

  toLocalMomentObject(timeStamp) {
    return smartDateFormat(timeStamp, null, null, null, this.currentUser?.timezone, true );
  }

  toUTCMomentObject(timeStamp) {
    return smartDateFormat(timeStamp, null, null, 'Etc/UTC', this.currentUser?.timezone, true );
  }
}
