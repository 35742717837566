import {Component, OnInit} from '@angular/core';
import {NgbActiveModal} from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-campaign-influencer-add',
  templateUrl: './influencer-add.component.html',
  styleUrls: ['./influencer-add.component.css']
})
export class InfluencerAddComponent implements OnInit {

  audienceType: string;
  projectId: number;

  constructor(
    private _modal: NgbActiveModal
  ) {
  }

  ngOnInit() {
  }

  closeModal() {
    this._modal.close(true);
  }
}
