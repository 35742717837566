import {BaseModel} from '@app/modules/shared/models/_base-model';
import {InfluencerType} from '@app/modules/shared/models/influencer-type';
import {Paging} from '@app/modules/shared/models/paging';

export class Segment extends BaseModel {
  id: number;
  name: string;
  project_id: number;
  influencer_type_id: number;
  influencer_type: InfluencerType;
}

export class PaginatedSegments {
  list: Segment[];
  paging: Paging;
}
